import React from "react";
import {
  Typography,
  Modal,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Theme from "theme";
import useStyles from "./stylesVerDetalle";

const VerDetalleCustomModal = ({ open, handleClose, cliente }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose} className={classes.paper}>
      <Grid
        container
        direction="row"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
          overflowX: "hidden",
          width: "70%",
          backgroundColor: "#ffffff",
          minHeight: "70%",
        }}
      >
        <Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignContent="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <Typography variant="h5" style={{ color: "#ffffff" }}>
              Ver Detalle Cliente
            </Typography>
          </Grid>
          <Grid
            container
            item
            spacing={4}
            direction="row"
            justifyContent="flex-start"
            alignContent="left"
            style={{ padding: "1.5rem" }}
          >
            <Grid
              container
              item
              spacing={4}
              direction="row"
              justifyContent="flex-start"
              alignContent="left"
            >
              <Grid item style={{ minWidth: "12rem" }}>
                <Typography className={classes.typographyContent1}>
                  Tipo de Cliente:
                </Typography>
                <Typography className={classes.typographyContent2}>
                  {cliente?.tipo_de_cliente.toUpperCase() ?? "-"}
                </Typography>
              </Grid>

              <Grid item style={{ minWidth: "12rem" }}>
                <Typography className={classes.typographyContent1}>
                  {cliente?.tipo_de_cliente === "Empresa"
                    ? "Empresa Activa?:"
                    : "Cliente Activo?:"}
                </Typography>
                <Typography className={classes.typographyContent2}>
                  {cliente?.estado === "activo" ? "SI" : "NO"}{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ minWidth: "12rem" }}>
              <Typography className={classes.typographyContent1}>
                Nombre:
              </Typography>
              <Typography className={classes.typographyContent2}>
                {cliente?.nombre.toUpperCase() ?? "-"}{" "}
              </Typography>
            </Grid>
            <Grid item style={{ minWidth: "12rem" }}>
              <Typography className={classes.typographyContent1}>
                Dirección:
              </Typography>
              <Typography className={classes.typographyContent2}>
                {cliente?.direccion.toUpperCase() ?? "-"}
              </Typography>
            </Grid>

            <Grid item style={{ minWidth: "12rem" }}>
              <Typography className={classes.typographyContent1}>
                Ciudad:
              </Typography>
              <Typography className={classes.typographyContent2}>
                {cliente?.ciudad.toUpperCase() ?? "-"}{" "}
                {cliente?.provincia.toUpperCase() ?? "-"}
              </Typography>
            </Grid>
            <Grid item style={{ minWidth: "12rem" }}>
              <Typography className={classes.typographyContent1}>
                {cliente?.tipo_de_cliente === "Empresa" ? "CUIT:" : "DNI:"}
              </Typography>
              <Typography className={classes.typographyContent2}>
                {cliente?.cuil_dni ?? "-"}
              </Typography>
            </Grid>
            <Grid item style={{ minWidth: "12rem" }}>
              <Typography className={classes.typographyContent1}>
                Condición IVA:
              </Typography>
              <Typography className={classes.typographyContent2}>
                {cliente?.condicion_iva.toUpperCase() ?? "-"}
              </Typography>
            </Grid>
            <Grid
              container
              item
              spacing={4}
              direction="row"
              alignContent="left"
              style={{ paddingBottom: "1rem" }}
            >
              <Grid item style={{ minWidth: "12rem" }}>
                <Typography className={classes.typographyContent1}>
                  Teléfono:
                </Typography>
                <Typography className={classes.typographyContent2}>
                  {cliente?.num_telefono ?? "-"}
                </Typography>
              </Grid>

              <Grid item style={{ minWidth: "12rem" }}>
                <Typography className={classes.typographyContent1}>
                  Email:
                </Typography>
                <Typography className={classes.typographyContent2}>
                  {cliente?.email ?? "-"}
                </Typography>
              </Grid>
              <Grid item style={{ minWidth: "12rem" }}>
                <Typography className={classes.typographyContent1}>
                  Recibe Notificaciones:
                </Typography>
                <Typography className={classes.typographyContent2}>
                  {cliente?.notificacion ? "SI" : "NO"}
                </Typography>
              </Grid>
            </Grid>
            {cliente?.tipo_de_cliente === "Empresa" && (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  className={classes.headerTable}
                  style={{ marginLeft: "2rem", marginRight: "2rem" }}
                >
                  <Typography className={classes.headerTableTittle}>
                    RESPONSABLES
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  style={{
                    backgroundColor: "#F0F0F0",
                    paddingBottom: "1rem",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    marginLeft: "2rem",
                    marginRight: "2rem",
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              //width: "25%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Teléfono
                          </TableCell>
                          <TableCell>Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cliente?.responsable?.length > 0 ? (
                          cliente?.responsable?.map((responsable) => (
                            <TableRow>
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  whiteSpace: "nowrap",
                                }}
                                className={classes.typographyContent1}
                              >
                                {responsable?.nombre.toUpperCase() ?? "-"}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  whiteSpace: "nowrap",
                                }}
                                className={classes.typographyContent1}
                              >
                                {responsable?.num_telefono ?? "-"}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  whiteSpace: "nowrap",
                                }}
                                className={classes.typographyContent1}
                              >
                                {responsable?.email ?? "-"}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <Grid item style={{ margin: "0.5rem" }}>
                            <Typography>No tiene Responsables</Typography>
                          </Grid>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          style={{ padding: "1rem" }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            Volver
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default VerDetalleCustomModal;
