import { useQuery } from "react-query";
import facturacionServices from "services/facturacion.services";
import ordenesService from "services/ordenes.service";

//--- FUNCION PARA TRAER ESTADO DE CUENTA DEL CLIENTE  ---//
const obtenerOrdenesPendientes = async ({ idCliente, debouncedSearch }) => {
  const { data } = await facturacionServices.getEstadoCuentaFacturasById(idCliente, debouncedSearch);
  return data;
};

//--- FUNCION PARA TRAER ESTADO DE CUENTA DEL CLIENTE  ---//
const obtenerOrdenesPorId = async ({ idOrden }) => {
  const { data } = await ordenesService.verOrden(idOrden);
  return data;
};

export function useOrdenesPendientes({ idCliente, debouncedSearch }) {
  return useQuery(["obtenerOrdenesPendientes", idCliente, debouncedSearch], () => obtenerOrdenesPendientes({ idCliente, debouncedSearch }), {
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}

export function useOrdenesById({ idOrden, onSuccess }) {
  return useQuery(["obtenerOrdenesPorId", idOrden], () => obtenerOrdenesPorId({ idOrden }),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    });
}

//--- FUNCION PARA TRAER LAS ORDENES DE UN CLIENTE ---//
const obtenerOrdenesPorClienteId = async ({ idCliente }) => {
  const { data } = await ordenesService.listarOrdenesPorCliente(idCliente);
  return data;
};

export function useOrdenesByClientId({ idCliente, onSuccess }) {
  return useQuery(["obtenerOrdenesPorClienteId", idCliente], () => obtenerOrdenesPorClienteId({ idCliente }),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: idCliente ? true : false,
    });
}

