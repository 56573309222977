import { createTheme } from "@material-ui/core/styles";

export const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: "#001176",
    },
    secondary: {
      main: "#FFCE76",
    },
    backgroundBlack: {
      main: "#5F5C5E",
    },
    success: {
      main: "#1C8269",
    },
    error: {
      main: "#D1001F",
    },
    tonalOffset: 0.2,
  },
  paletteCustom: {
    backgroundBlack: {
      main: "#AEDF00",
    },
    backgroundGrey: {
      main: "#B5B2B2",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#616161",
      },
      arrow: {
        color: "#616161",
      },
    },
  },
});

export default Theme;
