import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@material-ui/lab/Pagination";
import { NotificationContext } from "context/notification/NotificationContext";
import moment from "moment";
import Protected from "protected";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Theme from "theme";
import useStyles from "./styles";

// SERVICES
import UsuarioService from "services/usuario.service";

// COMPONENT
import CustomSnackbar from "components/Layout/CustomSnackbars";
import SearchBar from "components/Layout/searchBar/SearchBar";
import ModalAnularRemito from "components/remitos/modalAnularRemito";
import AsignarFacturaModal from "../../../components/asignarFacturaModal/AsignarFacturaModal.js";
import TotalizadorModal from "./totalizadores/totalizadorModal";
import { useDebounce } from "hook/useDebounce";
import RootContainer from "layout/rootContainer";
import { shallow } from "zustand/shallow";
import { useFormulas } from "../hook/useFormulas";
import { useRemitosByClient } from "../hook/useRemitos";
import {
  useObra,
  useOrderBy,
  useRemitoActions,
  useRemitoStore,
  useTextSelected,
  useRef,
} from "../store";

//ICONS
import { Delete } from "@material-ui/icons";
import Create from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Stack } from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
//constants
const SORT_TYPE = Object.freeze({
  SORT_DATE: Symbol(),
  SORT_INVOICE: Symbol(),
  SORT_QUANTITY: Symbol(),
  SORT_CONSTRUCTION: Symbol(),
});

const RemitosPorCliente = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isNotificationsEnabled } = useContext(NotificationContext);

  const { cliente } = history?.location?.state;
  const remCheck = useRemitoStore((state) => state.remCheck);
  const remitosIdsAExcluir = useRemitoStore(
    (state) => state.remitosIdsAExcluir,
  );

  //Defino estado para los filtros
  const [factura, setFactura] = useState(false);
  const [totalizador, setTotalizador] = useState(false);
  const [remitoSeleccionado, setRemitoSeleccionado] = useState();
  const [modalAnularRemitoVisibility, setModalAnularRemitoVisibility] =
    useState(false);
  // const [verificando, setVerificando] = useState(true);
  const [verificando, setVerificando] = useState(false);
  // const [acceso, setAcceso] = useState(false);
  const [acceso, setAcceso] = useState(true);

  // ESTADOS PARA NAVEGACION ENTRE PAGINAS
  const [actual, setActual] = useState();
  const [total, setTotal] = useState();
  // DECLARACION DE ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });
  // state manager with zustand
  const filtro = useRemitoStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      todo: state.todo,
      estado: state.estado,
      condicion: state.condicion,
      excedente: state.excedente,
      dias: state.dias,
      formula: state.formula,
      ordremi: state.ordremi,
      ordfecha: state.ordfecha,
      ordmts: state.ordmts,
      ordobra: state.ordobra,
      page: state.page,
      checkTodosLosRemitos: state.checkTodosLosRemitos,
    }),
    shallow,
  );
  const selectTexto = useTextSelected();
  const searchText = useObra();
  const orderBy = useOrderBy();
  const ref = useRef();
  const minDate = filtro?.fecha_desde;
  const maxDate = filtro?.fecha_hasta;

  //Actions with zustand
  const {
    setFechaDesde,
    setFechaHasta,
    setObras,
    setRemCheck,
    addRemCheck,
    clearRemitosIdsAExcluir,
    setRemitosIdsAExcluir,
    clearRemCheck,
    setCondicion,
    setEstado,
    setExcedente,
    setFormula,
    setTodo,
    setDias,
    setSelectTexto,
    setCheckTodosLosRemitos,
    resetPage,
    setOrdFecha,
    setOrdRemi,
    setOrdmts,
    setOrdObras,
    toggleSort,
    reset,
    setRef,
    setPage,
  } = useRemitoActions();

  // useDebounce hook for input text
  const debouncedValue = useDebounce(searchText, 1500);

  // Array de Ids de Remitos excluidos por página
  const [arrayDeIdsExcluidos, setArrayDeIdsExcluidos] = useState([]);

  const onSuccess = (data) => {
    // Seteo los datos del paginado
    setFiltrado(data);
    setActual(data?.actual);
    setTotal(data?.total_paginas);

    // Actualizo array de Excluidos por página
    // Filtro los remitos de la página: Con Negros Activados valido presupuesto y resumen_id, sino resumen_id
    const filteredIds = data?.results
      .filter((item) =>
        isNotificationsEnabled
          ? item.resumen_id !== null
          : !item.presupuesto || item.resumen_id !== null,
      )
      .map((item) => item.id);
    console.log("*** ----> filtrados en la data", filteredIds);

    // Remitos excluidos de la página actual, más remitos ya seleccionados previamente
    setArrayDeIdsExcluidos([
      ...arrayDeIdsExcluidos,
      ...filteredIds.filter((id) => id && !arrayDeIdsExcluidos.includes(id)),
    ]);
  };

  const [filtrado, setFiltrado] = useState([]);
  // QUERY PARA OBTENER REMITOS DE CLIENTES
  const { data, isLoading, isError, refetch } = useRemitosByClient({
    filtro,
    debouncedValue,
    isNotificationsEnabled,
    clientId: cliente.id,
    onSuccess,
  });

  // get all formulas
  const {
    data: formulas,
    isLoading: isLoadingFormulas,
    isError: isErrorFormulas,
  } = useFormulas();

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // VERIFICAR ACCESOS A MODULO
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await UsuarioService.checkAccess({ modulo: "remitos" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "remitos" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "remitos" });
    }
  };

  // FUNCION MOSTRAR COLOR DE FONDO DE LA LINEA(REMITO)
  const getRowBackgroundColor = (remito) => {
    if (remito?.presupuesto) {
      return "#E26E6E";
    } else if (
      moment(remito.fecha) < moment().subtract(90, "days") &&
      remito.condicion === "A Cobrar"
    ) {
      return "#FBEF94";
    } else if (remito.excedente) {
      return "#E4F4CB";
    }
    // Default color if none of the conditions match
    return "";
  };

  // Funcion para renderizar Referencias de colores
  const renderColorsReferences = (color, text) => (
    <Stack direction="row" justifyContent="flex-start" alignContent={"center"}>
      <Grid item>
        <FiberManualRecordRoundedIcon sx={{ color }} />
      </Grid>
      <Grid item style={{ marginRight: "0.5rem" }}>
        <Typography variant="caption">{text}</Typography>
      </Grid>
    </Stack>
  );

  // FUNCION CONTROL FILTROS MOSTRAR POR
  const mostrarPor = async (search) => {
    switch (search) {
      case "Sin Factura":
        setEstado("Sin Factura");
        break;
      case "A Facturar":
        setEstado("A Facturar");
        break;
      case "Facturado":
        setEstado("Facturado");
        break;
      case "Excedente":
        setExcedente(true);
        break;
      case "Pagado":
        setCondicion("Pagado");
        break;
      case "A Cobrar":
        setCondicion("A Cobrar");
        break;
      case "Mayor90":
        setDias(moment().format("YYYY-MM-DD"));
        break;
      case "Todos":
        setTodo("Todos");
        break;
      case formulas?.map((item) => item.nombre === filtro).nombre:
        setFormula(filtro);
        break;
      default:
        setFormula(search);
    }
    resetPage();
  };

  const filterByDate = () => {
    setFechaDesde(minDate);
    setFechaHasta(maxDate);
    resetPage();
    clearRemCheck();
  };

  // FUNCION DE BUSCADOR POR OBRA
  const handleSearch = async (text) => {
    setObras(text);
    //!check??
    resetPage();
  };

  // FUNCION PARA SELECCIONAR/DESELECCIONAR TODOS LOS REMITOS
  const handleCheckedAllRemito = (e) => {
    let idsPage = [];
    let filteredRemCheck = [];
    if (e.target.checked) {
      filtrado.results.map((remito) => {
        if (remito?.id && !remito?.resumen_id) {
          addRemCheck({
            remito_id: remito.id,
            fecha: remito.fecha,
            nro_remito: remito.nro_remito,
            tipo: remito.tipo,
            cantidad_mc: remito.cantidad_mc,
            obra: remito.obra,
            estado: remito.estado,
            condicion: remito.condicion,
            presupuesto: remito.presupuesto,
            checked: true,
          });
        }
      });
    } else {
      // Obtengo ids de la pagina
      idsPage = filtrado.results.map((obj) => obj.id);
      // Quito remitos según ids del state de remCheck
      filteredRemCheck = remCheck.filter(
        (obj) => !idsPage.includes(obj.remito_id),
      );
      setRemCheck(filteredRemCheck);
    }
    refetch();
  };

  //FUNCION PARA SELECCIONAR REMITOS (CHECKED UNO A UNO)
  const handleChecked = (remito, checked) => {
    if (filtro?.checkTodosLosRemitos) {
      if (checked) {
        // Si no estaba chekeado, tildo y quito de mi array de excluidos
        setArrayDeIdsExcluidos(
          arrayDeIdsExcluidos?.filter((id) => id !== remito?.id),
        );
        setRemitosIdsAExcluir(
          remitosIdsAExcluir?.filter((id) => id !== remito?.id),
        );
      } else {
        // Si estaba checkeado, destildo y agrego en mi array de excluidos
        setArrayDeIdsExcluidos([...arrayDeIdsExcluidos, remito?.id]);
        setRemitosIdsAExcluir([...remitosIdsAExcluir, remito?.id]);
      }
    } else {
      checked
        ? addRemCheck({
            remito_id: remito.id,
            fecha: remito.fecha,
            nro_remito: remito.nro_remito,
            tipo: remito.tipo,
            cantidad_mc: remito.cantidad_mc,
            obra: remito.obra,
            estado: remito.estado,
            condicion: remito.condicion,
            presupuesto: remito.presupuesto,
            checked: true,
          })
        : setRemCheck(remCheck.filter((rem) => rem.remito_id !== remito.id));
      refetch();
    }
  };

  /* Funcion Check todas las paginas */
  // const [checkTodosLosRemitos, setCheckTodosLosRemitos] = useState(false)
  const handleCheckedTodosLosRemitos = (e, o) => {
    if (o) {
      // checkear
      setCheckTodosLosRemitos(o);
    } else {
      // Al destildar quito de mi array de excluidos, aquellos que excluí en página y limpio remitosIdsAExcluir
      setArrayDeIdsExcluidos(
        arrayDeIdsExcluidos?.filter((id) => !remitosIdsAExcluir.includes(id)),
      );
      setRemitosIdsAExcluir([]);
      // False checkTodosLosRemitos
      setCheckTodosLosRemitos(o);
    }
  };

  const checkLinea = (remito) => {
    console.log(
      "*****",
      filtro.checkTodosLosRemitos,
      remCheck,
      arrayDeIdsExcluidos,
      remCheck.some((e) => e.remito_id === remito.id),
    );
    if (filtro.checkTodosLosRemitos) {
      // console.log("** arrayDeIdsExcluidos.includes(id)", arrayDeIdsExcluidos.includes(id))
      // verifico si id, está excluido por pagina(los que tienen resumen), o por los seleccionados previamente
      return ![...arrayDeIdsExcluidos, ...remitosIdsAExcluir].includes(
        remito.id,
      );
    } else {
      return (
        (!isNotificationsEnabled && remito?.presupuesto === false) ||
        remito?.resumen_id !== null
          ? []
          : remCheck
      ).some((e) => e.remito_id === remito.id);

      // ((!isNotificationsEnabled &&
      //   remito?.presupuesto ===
      //   false) ||
      // remito?.resumen_id !== null) ? return  true : return false
    }
  };
  /*  */

  const verificarObjetosEnArray = () => {
    if (filtro.checkTodosLosRemitos) {
      // Si todo el rango está seleccionado
      return true;
    } else {
      // Convertimos el array remCheck a un objeto con claves iguales a remito_id para facilitar la búsqueda
      const remCheckMap = remCheck.reduce((map, obj) => {
        map[obj.remito_id] = true;
        return map;
      }, {});

      // Verificamos si todos los objetos en el array array están en remCheckMap
      for (const obj of filtrado?.results?.filter(
        (unRemito) => unRemito?.resumen_id === null,
      )) {
        if (!remCheckMap[obj.id]) {
          return false;
        }
      }
      return true;
    }
  };

  const handleModalTotalizador = () => {
    setTotalizador(!totalizador);
  };

  const handleModalFacturacion = () => {
    setFactura(!factura);
  };

  // ABRIR MODAL ANULAR REMITO
  const anularRemito = (remito) => {
    setRemitoSeleccionado(remito);
    setModalAnularRemitoVisibility(true);
  };

  // CERRAR MODAL ANULAR REMITO
  const handleCloseModalAnularRemito = () => {
    setModalAnularRemitoVisibility(false);
  };

  // TO EDIT REMITO
  const editarRemito = (remito) => {
    history.push("/remitos/crear-remito", {
      remito,
      edicion: true,
    });
  };

  const sortBy = (criteria) => {
    const sortType = orderBy ? "asc" : "desc";
    switch (criteria) {
      case SORT_TYPE.SORT_DATE:
        setOrdFecha(sortType);
        break;
      case SORT_TYPE.SORT_INVOICE:
        setOrdRemi(sortType);
        break;
      case SORT_TYPE.SORT_QUANTITY:
        setOrdmts(sortType);
        break;
      case SORT_TYPE.SORT_CONSTRUCTION:
        setOrdObras(sortType);
        break;
      default:
        break;
    }
    toggleSort();
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  useEffect(() => {
    console.log("ref", ref);
    if (ref !== "remitos-cliente") {
      reset();
      setTimeout(() => {
        setRef("remitos-cliente");
      }, 200);
      setObras("");
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{
              marginTop: "15rem",
            }}
          >
            <CircularProgress
              style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            {acceso && (
              <>
                <Grid
                  style={{
                    paddingTop: "1rem",
                    width: "95%",
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    item
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                      <Grid>
                        <Typography className={classes.remitoHeaderItemTitle}>
                          CUIL-CUIT :{" "}
                          <span className={classes.remitoHeaderItem}>
                            {cliente?.cuil_dni ?? "-"}
                          </span>
                        </Typography>
                      </Grid>

                      <Grid>
                        <Typography className={classes.remitoHeaderItemTitle}>
                          Nombre:{" "}
                          <span className={classes.remitoHeaderItem}>
                            {cliente?.nombre ?? "-"}
                          </span>
                        </Typography>
                      </Grid>

                      <Grid>
                        <Typography className={classes.remitoHeaderItemTitle}>
                          Dirección:{" "}
                          <span className={classes.remitoHeaderItem}>
                            {cliente
                              ? `${cliente.direccion} ${cliente.ciudad} ${cliente.provincia}`
                              : "-"}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={filtrado?.results?.length > 0 ? false : true}
                        style={{
                          //backgroundColor: filtrado.length ? "#001176" : "grey",
                          textTransform: "capitalize",

                          color: "white",
                          maxHeight: "2rem",
                        }}
                        onClick={() => handleModalTotalizador()}
                      >
                        Totalizadores
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#001176",
                          // backgroundColor:
                          //   remCheck.length < 1 ? "grey" : "#001176",
                          textTransform: "capitalize",
                          maxHeight: "2rem",
                          margin: "0.5rem 1rem",
                          color: "#FFFFFF",
                        }}
                        /**
                         * ! Verificar Disabled
                         */
                        // disabled={remCheck.length < 1 ? true : false}
                        onClick={() =>
                          history.push(
                            `/remitos/remitos-por-cliente/visualizar-resumen/${cliente.id}`,
                            {
                              dataCliente: cliente,
                              dataRemitos: filtro?.checkTodosLosRemitos
                                ? remitosIdsAExcluir
                                : remCheck,
                              checkTodosLosRemitos:
                                filtro?.checkTodosLosRemitos,
                              filtro: { ...filtro, obra: searchText },
                            },
                          )
                        }
                      >
                        Crear Resumen
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    item
                    direction="row"
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <Grid
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "0.5rem" }}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Desde"
                          required
                          focused
                          defaultValue={minDate && minDate}
                          onChange={(e) =>
                            setFechaDesde(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            max: maxDate,
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Hasta"
                          required
                          focused
                          defaultValue={maxDate && maxDate}
                          onChange={(e) =>
                            setFechaHasta(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            min: filtro?.fecha_desde,
                            max: moment().format("YYYY-MM-DD"),
                          }}
                        />
                      </Grid>
                      <Grid item style={{ marginRight: "0.5rem" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => filterByDate()}
                          style={{
                            textTransform: "capitalize",
                            maxHeight: "2rem",
                          }}
                        >
                          Filtrar
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent={"flex-end"}
                      alignContent="flex-start"
                      alignItems="center"
                      style={{ marginTop: "0.5rem" }}
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <Box>
                          <FormControl style={{ width: "190px" }}>
                            <Select
                              size="small"
                              variant="outlined"
                              value={selectTexto}
                              displayEmpty
                              renderValue={
                                selectTexto !== ""
                                  ? undefined
                                  : () => (
                                      <Placeholder>Mostrar por:</Placeholder>
                                    )
                              }
                              onChange={(e) => {
                                mostrarPor(e.target.value);
                                setSelectTexto(e.target.value);
                              }}
                            >
                              <MenuItem value={"Todos"}>Todos</MenuItem>
                              <MenuItem value={"Mayor90"}>
                                A cobrar mayor a 90 días
                              </MenuItem>
                              <MenuItem value={"Pagado"}>Pagados</MenuItem>
                              <MenuItem value={"A Cobrar"}>A cobrar</MenuItem>
                              <MenuItem value={"Sin Factura"}>
                                Sin Factura
                              </MenuItem>
                              <MenuItem value={"A Facturar"}>
                                A Facturar
                              </MenuItem>
                              <MenuItem value={"Facturado"}>Facturado</MenuItem>
                              <MenuItem value={"Excedente"}>
                                Excedentes
                              </MenuItem>
                              {formulas?.length > 0 &&
                                formulas?.map((item) => (
                                  <MenuItem value={item.id} key={item.id}>
                                    {item.nombre}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item>
                        <SearchBar
                          placeholder="Buscar por Obra"
                          defaultValue={
                            ref && ref === "remitos-cliente" ? searchText : ""
                          }
                          value={searchText}
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ width: "95%" }}
                >
                  <Grid item>
                    <Typography variant="body2">
                      Seleccionar Todos Los Remitos del Rango:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Checkbox
                      color="primary"
                      disabled={isLoading}
                      //checked={checkedTodosLosRemitos()}
                      checked={filtro?.checkTodosLosRemitos}
                      onChange={(e, o) => handleCheckedTodosLosRemitos(e, o)}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{
                    maxWidth: "95%",
                    marginTop: "1rem",
                    marginBottom: "5rem",
                  }}
                >
                  <Grid item xs={12} sm={8} md={6}>
                    <Stack sx={{ flexDirection: { xs: "column", lg: "row" } }}>
                      {renderColorsReferences("#E4F4CB", "Remitos Excedentes")}
                      {renderColorsReferences(
                        "#FBEF94",
                        "Remitos A Cobrar Mayores a 30 días",
                      )}
                      {!isNotificationsEnabled &&
                        renderColorsReferences(
                          "#E26E6E",
                          "Remitos Presupuesto",
                        )}
                    </Stack>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    className={classes.headerTable}
                  >
                    <Typography
                      variant="h5"
                      className={classes.headerTableTittle}
                    >
                      Lista de Remitos
                    </Typography>
                  </Grid>

                  {isLoading ? (
                    <Grid container justifyContent="center">
                      <Grid>
                        <CircularProgress
                          size="1.5rem"
                          style={{ margin: "2rem" }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <TableContainer
                        component={Paper}
                        className={classes.tableContainer}
                      >
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow className="tableRow">
                              <TableCell>
                                {filtrado?.results?.length > 0 && (
                                  <Checkbox
                                    color="primary"
                                    checked={verificarObjetosEnArray()}
                                    onChange={(e, o) =>
                                      handleCheckedAllRemito(e)
                                    }
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  color: "rgba(67, 59, 59, 0.87)",
                                }}
                              >
                                Fecha
                                <IconButton
                                  onClick={() => sortBy(SORT_TYPE.SORT_DATE)}
                                >
                                  {/* <CompareArrowsIcon
                                    style={{
                                      width: "1rem",
                                      transform: "rotate(90deg)",
                                    }}
                                  /> */}
                                  <ArrowDropUpIcon
                                    fontSize="small"
                                    style={{
                                      transform:
                                        filtro.ordfecha === "desc"
                                          ? "rotate(180deg)"
                                          : "",
                                    }}
                                  />
                                </IconButton>
                              </TableCell>

                              <TableCell
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  color: "rgba(67, 59, 59, 0.87)",
                                  minWidth: "9rem",
                                  padding: 0,
                                }}
                              >
                                N° de Remito
                                <IconButton
                                  onClick={() => sortBy(SORT_TYPE.SORT_INVOICE)}
                                >
                                  <ArrowDropUpIcon
                                    fontSize="small"
                                    style={{
                                      transform:
                                        filtro.ordremi === "desc"
                                          ? "rotate(180deg)"
                                          : "",
                                    }}
                                  />
                                  {/* <CompareArrowsIcon
                                    style={{
                                      width: "1rem",
                                      transform: "rotate(90deg)",
                                    }}
                                  /> */}
                                </IconButton>
                              </TableCell>

                              <TableCell
                                style={{ paddingLeft: "0.5rem" }}
                                className={classes.tableCellHeaders}
                              >
                                Tipo H
                              </TableCell>

                              <TableCell
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  color: "rgba(67, 59, 59, 0.87)",
                                  minWidth: "8rem",
                                  padding: 0,
                                }}
                              >
                                Mts3
                                <IconButton
                                  onClick={() =>
                                    sortBy(SORT_TYPE.SORT_QUANTITY)
                                  }
                                >
                                  <ArrowDropUpIcon
                                    fontSize="small"
                                    style={{
                                      transform:
                                        filtro.ordmts === "desc"
                                          ? "rotate(180deg)"
                                          : "",
                                    }}
                                  />
                                  {/* <CompareArrowsIcon
                                    style={{
                                      width: "1rem",
                                      transform: "rotate(90deg)",
                                    }}
                                  /> */}
                                </IconButton>
                              </TableCell>

                              <TableCell
                                style={{
                                  paddingLeft: "0.5rem",
                                  cursor: "pointer",
                                }}
                                className={classes.tableCellHeaders}
                                // onClick={() => sortBy(SORT_TYPE.SORT_CONSTRUCTION)}
                              >
                                Obra
                                <IconButton
                                  onClick={() =>
                                    sortBy(SORT_TYPE.SORT_CONSTRUCTION)
                                  }
                                >
                                  <ArrowDropUpIcon
                                    fontSize="small"
                                    style={{
                                      transform:
                                        filtro.ordobra === "desc"
                                          ? "rotate(180deg)"
                                          : "",
                                    }}
                                  />
                                </IconButton>
                              </TableCell>

                              <TableCell
                                style={{ paddingLeft: "0.5rem" }}
                                className={classes.tableCellHeaders}
                              >
                                Estado
                              </TableCell>

                              <TableCell className={classes.tableCellHeaders}>
                                Condición
                              </TableCell>

                              <TableCell className={classes.tableCellHeaders}>
                                N° Factura
                              </TableCell>

                              <TableCell className={classes.tableCellHeaders}>
                                N° Resumen
                              </TableCell>

                              <TableCell
                                align="center"
                                className={classes.tableCellHeaders}
                              >
                                Acciones
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {filtrado?.results?.length > 0 &&
                            filtrado?.results
                              .filter((item) => !item.Total_Formula)
                              .map((remito, index) => (
                                <TableBody key={remito.id}>
                                  <TableRow
                                    hover
                                    style={{
                                      backgroundColor:
                                        getRowBackgroundColor(remito),
                                    }}
                                  >
                                    <TableCell align="center">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name={`${remito.id}`}
                                            color="primary"
                                            value={remito.id}
                                            onChange={(e, o) =>
                                              handleChecked(remito, o)
                                            }
                                            // checked={remCheck.some(
                                            //   (e) => e.remito_id === remito.id,
                                            // )}
                                            checked={checkLinea(remito)}
                                            disabled={
                                              (!isNotificationsEnabled &&
                                                remito?.presupuesto ===
                                                  false) ||
                                              remito?.resumen_id !== null
                                            }
                                          />
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "16px",
                                        whiteSpace: "nowrap",
                                        textAlign: "left",
                                        minWidth: "8rem",
                                      }}
                                    >
                                      {moment(remito?.fecha).format(
                                        "DD-MM-YYYY",
                                      ) ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.nro_remito ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.tipo ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.cantidad_mc ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.obra?.toUpperCase() ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.estado ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.condicion ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.nro_factura ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.resumen_id ?? "-"}
                                    </TableCell>

                                    <TableCell>
                                      <Grid
                                        container
                                        item
                                        direction="row"
                                        justifyContent="center"
                                        style={{
                                          whiteSpace: "nowrap",
                                          minWidth: "8rem",
                                        }}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            history.push(
                                              `/remitos/visualizar/${remito?.id}/`,
                                              {
                                                presupuesto:
                                                  remito?.presupuesto,
                                              },
                                            )
                                          }
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",
                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <VisibilityIcon
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => anularRemito(remito)}
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",

                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <Delete
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => editarRemito(remito)}
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",
                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <Create
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              ))}
                        </Table>
                      </TableContainer>
                      {filtrado.results?.length > 1 ? (
                        <Grid container justifyContent="center">
                          <Grid style={{ marginTop: "1rem" }}>
                            <Pagination
                              count={total}
                              color="primary"
                              onChange={(event, value) => setPage(value)}
                              page={filtro?.page}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          justifyContent="center"
                          style={{ margin: "0.5rem" }}
                        >
                          <Typography variant="subtitle1">
                            No se encontraron remitos con estas caracteristicas
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>

                <ModalAnularRemito
                  open={modalAnularRemitoVisibility}
                  handleClose={handleCloseModalAnularRemito}
                  remitoSeleccionado={remitoSeleccionado}
                />
              </>
            )}
          </>
        )}

        {factura && (
          <AsignarFacturaModal
            open={handleModalFacturacion}
            handleClose={handleModalFacturacion}
          />
        )}
        {totalizador && (
          <TotalizadorModal
            open={handleModalTotalizador}
            handleClose={handleModalTotalizador}
            remitos={filtrado.results}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};
export default RemitosPorCliente;
