export const headersTableEstadoCuenta = [
  {
    name: "N° Orden de Compra",
  },
  {
    name: "Fecha",
  },
  {
    name: "Anticipada",
  },
  {
    name: "Resúmenes Asociados",
  },
];
