import moment from "moment";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const defaultState = {
  //estados de resguardo general
  fecha_desde: moment().format("YYYY-MM-01"),
  fecha_hasta: moment().format("YYYY-MM-DD"),
  search: "",
  textSelected: "",
  todo: "",
  estado: "",
  condicion: "",
  dias: "",
  formula: "",
  obra: "",
  tipo: "",
  sort: "",

  //campos de ordenamiento
  ordremi: "",
  ordfecha: "",
  ordmts: "",
  ordobra: "",
  ordcondi: "",
  ordnumfac: "",
  ordmonto: "",
  ordingreso: "",
  ordtrans: "",
  ordcant: "",
  ordcomb: "",

  //para resguardar paginado
  page: 1,

  //para ordenamiento
  order_by: true,

  //para resguardar checks
  remCheck: [],
};

export const useStore = create(
  devtools((set) => ({
    ...defaultState,
    // actions
    actions: {
      //acciones para atributos que se resguardan
      setFechaDesde: (value) =>
        set((state) => ({ fecha_desde: value, page: 1 })),
      setFechaHasta: (value) =>
        set((state) => ({ fecha_hasta: value, page: 1 })),
      setTodo: (value) =>
        set((state) => ({
          todo: value,
          estado: "",
          condicion: "",
          dias: "",
          formula: "",
          page: 1,
        })),
      setCondicion: (value) =>
        set((state) => ({
          condicion: value,
          estado: "",
          dias: "",
          formula: "",
          todo: "",
          page: 1,
        })),
      setObras: (value) => set((state) => ({ obra: value })),
      setEstado: (value) =>
        set((state) => ({
          estado: value,
          condicion: "",
          dias: "",
          formula: "",
          todo: "",
          page: 1,
        })),
      setDias: (value) =>
        set((state) => ({
          dias: value,
          estado: "",
          condicion: "",
          formula: "",
          todo: "",
          page: 1,
        })),
      setFormula: (value) =>
        set((state) => ({
          formula: value,
          estado: "",
          condicion: "",
          dias: "",
          todo: "",
          page: 1,
        })),
      setTipo: (value) =>
        set((state) => ({
          tipo: value,
          sort: "",
          page: 1,
        })),
      setSort: (value) =>
        set((state) => ({
          sort: value,
        })),

      //acciones para los checks
      setRemCheck: (value) => set(() => ({ remCheck: value })),
      clearRemCheck: (value) => set(() => ({ remCheck: [] })),
      addRemCheck: (value) =>
        set((state) => ({ remCheck: [...state.remCheck, value] })),

      // acciones para search
      setSelectTexto: (value) => set(() => ({ textSelected: value })),
      setSearch: (value) => set((state) => ({ search: value, page: 1 })),

      // acciones para paginado
      increasePage: () => set((state) => ({ page: state.page + 1 })),
      decreasePage: () => set((state) => ({ page: state.page - 1 })),
      setPage: (value) => set((state) => ({ page: value })),
      resetPage: (value) => set(() => ({ page: 1 })),

      // acciones para ordenamientos
      setOrdFecha: (value) =>
        set(() => ({
          ordfecha: value,
          ordremi: "",
          ordmts: "",
          ordobra: "",
          ordcondi: "",
          ordmonto: "",
          ordnumfac: "",
          ordingreso: "",
          ordtrans: "",
          ordcant: "",
          ordcomb: "",
        })),
      setOrdRemi: (value) =>
        set(() => ({
          ordremi: value,
          ordfecha: "",
          ordmts: "",
          ordobra: "",
          ordingreso: "",
          ordtrans: "",
          ordcant: "",
          ordcomb: "",
        })),
      setOrdmts: (value) =>
        set(() => ({
          ordmts: value,
          ordfecha: "",
          ordremi: "",
          ordobra: "",
        })),
      setOrdObras: (value) =>
        set(() => ({
          ordobra: value,
          ordmts: "",
          ordfecha: "",
          ordremi: "",
        })),
      setOrdNumFac: (value) =>
        set(() => ({
          ordfecha: "",
          ordcondi: "",
          ordmonto: "",
          ordnumfac: value,
          ordingreso: "",
          ordtrans: "",
          ordcant: "",
          ordcomb: "",
        })),
      setOrdMonto: (value) =>
        set(() => ({
          ordfecha: "",
          ordcondi: "",
          ordmonto: value,
          ordnumfac: "",
          ordingreso: "",
          ordtrans: "",
          ordcant: "",
          ordcomb: "",
        })),
      setOrdCondi: (value) =>
        set(() => ({
          ordfecha: "",
          ordcondi: value,
          ordmonto: "",
          ordnumfac: "",
        })),
      setOrdIngreso: (value) =>
        set(() => ({
          ordfecha: "",
          ordremi: "",
          ordnumfac: "",
          ordingreso: value,
          ordtrans: "",
          ordcant: "",
          ordcomb: "",
        })),
      setOrdTrans: (value) =>
        set(() => ({
          ordfecha: "",
          ordremi: "",
          ordnumfac: "",
          ordingreso: "",
          ordtrans: value,
          ordcant: "",
          ordcomb: "",
        })),
      setOrdCant: (value) =>
        set(() => ({
          ordfecha: "",
          ordremi: "",
          ordnumfac: "",
          ordingreso: "",
          ordtrans: "",
          ordcant: value,
          ordcomb: "",
        })),
      setOrdComb: (value) =>
        set(() => ({
          ordfecha: "",
          ordremi: "",
          ordnumfac: "",
          ordingreso: "",
          ordtrans: "",
          ordcant: "",
          ordcomb: value,
        })),
      toggleSort: (value) => set((state) => ({ order_by: !state.order_by })),

      // reset al estado inicial
      reset: () => set((state) => defaultState),
    },
  })),
);

// unique selector for each state
// export const useEstado = () => useStore((state) => state.estado);
// export const useCondicion = () => useStore((state) => state.condicion);
// export const useFormula = () => useStore((state) => state.formula);
// export const useDias = () => useStore((state) => state.dias);
export const useObra = () => useStore((state) => state.obra);
export const useSearch = () => useStore((state) => state.search);
export const useOrderBy = () => useStore((state) => state.order_by);
export const useTextSelected = () => useStore((state) => state.textSelected);
export const useSearchNotesOrClients = () => useStore((state) => state.search);
export const useNoteTipeSelected = () =>
  useStore((state) => state.textSelected);

//  ▶ one selector for all our actions
export const useActions = () => useStore((state) => state.actions);
