export const ProvidersSubtypes = {
  transportistasSubtypes: [
    { nombre: "Cemento", value: "Cemento" },
    { nombre: "Áridos", value: "Áridos" },
    { nombre: "Generales", value: "Generales" },
  ],
  materialesSubtypes: [
    { nombre: "Canteras", value: "Canteras" },
    { nombre: "Areneras", value: "Areneras" },
    { nombre: "Cementera", value: "Cementera" },
  ],
  combustiblesSubtypes: [{ nombre: "Combustibles", value: "Combustibles" }],
};
