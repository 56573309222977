import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Controller} from "react-hook-form";

export default function NewCheckbox({
  name,
  control,
  rules,
  defaultValue,
  label,
  labelPlacement,
  onChange,
  ...props
}) {
  const onChangeFunction = (e, value) => {
    if (onChange) {
      onChange(e, value);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({field: {onChange, value}}) => (
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              id={name}
              checked={value ?? false}
              onChange={(e, value) => {
                onChange(value);
                onChangeFunction(e, value);
              }}
              {...props}
            />
          }
          label={label}
          labelPlacement={labelPlacement ?? "end"}
        />
      )}
    />
  );
}
