import axios from "axios";

let API_URL = process.env.REACT_APP_HOST
  ? process.env.REACT_APP_HOST
  : `http://localhost`;

let API_PORT = process.env.REACT_APP_PORT ? process.env.REACT_APP_PORT : `8000`;

let baseURL = process.env.REACT_APP_HOST
  ? process.env.REACT_APP_HOST
  : `${API_URL}:${API_PORT}`;

const instance = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

/* eslint-disable */
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  //checket token expired
  if (error.response.status === 511) {
    localStorage.setItem("logeado", false)
    localStorage.setItem("token", "")
    window.location.href = '/';
  }
  return Promise.reject(error);
});
/* eslint-enable */

export default instance;
