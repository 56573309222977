//HOOK
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useGetDetailsNoteById } from "../hook";

//MATERIAL-UI
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";

//ICONS
import PrintIcon from "@material-ui/icons/Print";

//COMPONENTS
import DetallePago from "./detallePago";

//SERVICES
import facturacionServices from "services/facturacion.services";

//VARIOS
import Protected from "protected";
import useStyles from "./styles";
import Logo from "../../../assets/img/LogoAyupi.png";
import { ReactComponent as FacturaA } from "../../../assets/svg/factura_a.svg";
import { ReactComponent as FacturaB } from "../../../assets/svg/factura_b.svg";
import { ReactComponent as FacturaC } from "../../../assets/svg/factura_c.svg";
import moment from "moment";


const VisualizarFactura = ({ setOpenVisualizarFactura, ordenDetails, facturaId }) => {
  const classes = useStyles();
  // Para identificar los componentes a imprimir
  const componentRef = useRef();
  // Trae los datos de la pantalla anterior
  const history = useHistory();
  // Estado para ejecutar la acción de imprimir
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //=============================== ESTADOS ===================================
  // Estado para verificar si las facturas estan cargando
  const [isLoadingFactura, setIsLoadingFactura] = useState(false);
  // Estado que contiene los datos de la factura
  const [dataFactura, setDataFactura] = useState();
  // Estado que contiene las lineas de Factura o Notas
  const [remitos, setRemitos] = useState([]);
  // Estado para descargar un archivo
  const [URLArchivo, setURLArchivo] = useState(null);
  // Estado para verificar si el modal esta abierto o cerrado
  const [openDetallePago, setOpenDetallePago] = useState(false);
  // Estado para poner dimensión a la pantalla

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  //=============================== FIN ESTADOS ===================================

  //============================= FUNCIONES =======================================
  // Función para generar link del archivo y crear el archivo
  const generarURLBuffer = (buffer) => {
    const file = new File([buffer], "factura.pdf", { type: "application/pdf" });
    const url = URL.createObjectURL(file);
    return url;
  };

  // Función para calcular el monto total del remito
  const calcularTotal = () => {
    let total = 0;
    remitos?.forEach((remito) => {
      if (remito?.subtotal !== undefined) {
        total += remito?.subtotal;
      }
    });
    if (total === 0) {
      return null;
    }
    return total;
  };

  //********************************** END POINTS *********************************
  // Función para obtener la info del pdf
  const obtenerPDF = async () => {
    try {
      const { data, status } = await facturacionServices.obtenerPDF(facturaId);
      if (status > 199 && status < 300) {
        setURLArchivo(generarURLBuffer(data));
      }
    } catch (error) {
      return error;
    }
  };

  // Función para obtener los datos de una factura
  const obtenerUnaFactura = async () => {
    setIsLoadingFactura(true);
    try {
      const res = await facturacionServices.verFactura(facturaId);
      if (res.status > 199 && res.status < 300) {
        setDataFactura(res.data);
        setRemitos(res.data.linea_factura);
      }
      setIsLoadingFactura(false);
    } catch (error) {
      setIsLoadingFactura(false);
      return error;
    }
  };

  const onSuccess = (data) => {
    // Seteo las lineas de la nota
    setRemitos(data?.lineas_nota);
  };

  // Query obtener detalle orden
  const {
    isLoading: isLoadingNota,
    refetch
  } = useGetDetailsNoteById({
    idNota: ordenDetails?.id,
    onSuccess,
  });

  // Función para obtener los datos del resumen
  const VerResumen = async (resumen) => {
    try {
      const res = await facturacionServices.planillaResumen(resumen);
      if (res.status > 199 && res.status < 300) {
        history.push(`/remitos/remitos-por-cliente/resumen/${resumen}`);
      }
    } catch (error) {
      return error;
    }
  };
  //********************************** FIN END POINTS *******************************

  // Función para poner dimensión a la pantalla
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };
  //============================ FIN FUNCIONES ================================

  //============================= USEEFFECT ===================================
  // Función que se ejecutará cada vez que nuestro componente se renderice
  useEffect(() => {
    // Llamo a función para obtener la Factura
    obtenerUnaFactura();
    obtenerPDF();
    // Llamo a función para obtener Nota
    if (ordenDetails?.id) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);
  //================================ FIN USEEFFECT =============================

  return (
    <Protected>
      <>
        {!isLoadingFactura && !isLoadingNota ? (
          <Grid
            style={{
              paddingTop: "1rem",
              width: "95%",
              height: "100%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              item
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#000000",
                    paddingLeft: "1rem",
                  }}
                >
                  {ordenDetails?.id ? `Ver Nota ${ordenDetails?.tipo}` : `Visualizar Factura`}
                </Typography>
              </Grid>
              <Grid style={{ marginRight: "5rem" }}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    margin: "0.5rem 0.5rem 0.5rem 0",
                  }}
                  onClick={() => setOpenVisualizarFactura(false)}
                >
                  Volver
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    textTransform: "capitalize",
                    margin: "0.5rem 0",
                  }}
                  startIcon={<PrintIcon />}
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
                {!ordenDetails?.id &&
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      marginLeft: "0.5rem",
                    }}
                    onClick={() => setOpenDetallePago(true)}
                  >
                    Detalle de Pago
                  </Button>
                }
              </Grid>
            </Grid>
            <Grid
              style={{
                maxWidth: "95%",
                backgroundColor: "#ffffff",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginBottom: "6rem",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                className={classes.headerTable}
              >
                <Typography variant="h5" className={classes.headerTableTittle}>
                  {ordenDetails?.id ? `Nota de ${ordenDetails?.tipo}` : `Factura N° ${dataFactura?.num_factura ?? "-"}`}
                </Typography>
              </Grid>
              <Grid ref={componentRef}>
                <style type="text/css" media="print">
                  {`@page { 
                    margin: 1rem 0 !important; 
                    size: A4;
                  }
                  ::-webkit-scrollbar,
                  ::-webkit-scrollbar {
                    display: none;
                  }
                  ::-webkit-scrollbar-track,
                  ::-webkit-scrollbar-thumb {
                    display: none;
                  }
                  scrollbar-width: none;
                  -ms-overflow-style: none;`}
                </style>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid xl={4} lg={4} md={4} sm={4} xs={4}>
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          height: 80,
                          marginLeft: "2.5rem",
                          marginTop: "1rem",
                        }}
                        alt="Logo"
                        src={Logo}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xl={8}
                    lg={8}
                    md={8}
                    sm={8}
                    xs={8}
                    style={{
                      maxWidth: "50%",
                      marginTop: "1rem",
                    }}
                  >
                    {dataFactura?.tipo_factura === "A" ? (
                      <FacturaA />
                    ) : dataFactura?.tipo_factura === "B" ? (
                      <FacturaB />
                    ) : (
                      <FacturaC />
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid
                    container
                    xl={5}
                    lg={5}
                    md={5}
                    sm={5}
                    xs={5}
                    style={{ paddingLeft: "2.5rem" }}
                  >
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Razón Social:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        Ayupí Hormigones S.R.L
                      </Typography>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Condición IVA:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        Responsable Inscripto
                      </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Domicilio:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        Ruta Nac N°16 Km 18,5 Resistencia Chaco
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    style={{ paddingLeft: "1rem" }}
                  >
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        C.U.I.T:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        23-31313131-9
                      </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Ingresos Brutos:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        23-31313131-9
                      </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Inicio Actividades:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        01/01/2000
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={4}
                    style={{ paddingLeft: "0.5rem" }}
                  >
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        {ordenDetails?.id ? `Nota de ${ordenDetails?.tipo} N°:` : `N° de Factura:`}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {ordenDetails?.id ? `${ordenDetails?.id ?? "-"}` : `${dataFactura?.num_factura ?? "-"}`}
                      </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Fecha Emisión:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {ordenDetails?.id ? `${ordenDetails?.creado ?? "--/--/--"}` : `${moment(dataFactura?.fecha_emision).format(
                          "DD/MM/YYYY",
                        )}`}
                      </Typography>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        {ordenDetails?.id ? `Factura Asociada` : `Fecha Vto. Pago:`}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {ordenDetails?.id ? `${ordenDetails?.num_factura ?? "-"}` : `${moment(dataFactura?.fecha_venc_pago).format(
                          "DD/MM/YYYY",
                        )}`}

                      </Typography>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Punto de Venta:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {dataFactura?.punto_venta ?? "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  style={{ marginTop: "3rem" }}
                >
                  <Grid
                    xl={7}
                    lg={7}
                    md={7}
                    sm={7}
                    xs={7}
                    style={{ paddingLeft: "2.5rem" }}
                  >
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Cliente:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {dataFactura?.cliente?.nombre ?? "-"}
                      </Typography>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        CUIT/CUIL:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {dataFactura?.cliente?.cuil_dni ?? "-"}
                      </Typography>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Domicilio:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.54)",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {dataFactura
                          ? `${dataFactura?.cliente?.direccion} ${dataFactura?.cliente?.ciudad} ${dataFactura?.cliente?.provincia}`
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid xl={5} lg={5} md={5} sm={5} xs={5}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Resumen:
                        {!dataFactura?.resumen && " -"}
                      </Typography>
                      {dataFactura?.resumen && (
                        <Link
                          style={{
                            fontWeight: 700,
                            marginLeft: "0.5rem",
                            fontSize: "17px",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          onClick={() => VerResumen(dataFactura?.resumen)}
                        >
                          {dataFactura?.resumen}
                        </Link>
                      )}
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Orden de Compra:
                        {!dataFactura?.orden_compra?.id && " -"}
                      </Typography>
                      {dataFactura?.orden_compra?.id && (
                        <Link
                          style={{
                            fontWeight: 700,
                            marginLeft: "0.5rem",
                            fontSize: "17px",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          onClick={() =>
                            history.push(
                              `/ordenes/ver-orden/${dataFactura?.orden_compra?.id}/`,
                            )
                          }
                        >
                          {dataFactura?.orden_compra?.num_orden}
                        </Link>
                      )}
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                          marginRight: "0.5rem",
                        }}
                      >
                        Factura Electrónica Afip:
                        {!dataFactura?.documento && " -"}
                      </Typography>
                      {dataFactura?.documento !== null && (
                        <Link
                          href={URLArchivo}
                          variant="body2"
                          underline="hover"
                          target="_blank"
                          rel="noopener"
                        >
                          {dataFactura?.documento}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  style={{ padding: "1.5rem 2.5rem 0rem 2.5rem" }}
                  className={classes.generalContainer}
                >
                  <TableContainer
                    className={classes.tableContainer}
                    style={{ overflow: "auto" }}
                  >
                    <Table className={classes.table} size="small">
                      <TableHead>
                        <TableRow
                          className={classes.tableRow}
                          style={{
                            background: "#CCCFE4",
                            height: "2.5rem",
                          }}
                        >
                          <TableCell
                            width="12%"
                            className={classes.tableCellHeaders}
                          >
                            Producto/Servicio
                          </TableCell>

                          <TableCell
                            width="5%"
                            className={classes.tableCellHeaders}
                          >
                            Cantidad M³
                          </TableCell>

                          <TableCell
                            width="15%"
                            className={classes.tableCellHeaders}
                          >
                            Precio Unitario
                          </TableCell>

                          <TableCell
                            width="15%"
                            className={classes.tableCellHeaders}
                          >
                            Bonificación
                          </TableCell>

                          <TableCell
                            width="15%"
                            className={classes.tableCellHeaders}
                          >
                            Importe
                          </TableCell>

                          {dataFactura?.tipo_factura === "A" && (
                            <TableCell
                              width="8%"
                              className={classes.tableCellHeaders}
                            >
                              IVA
                            </TableCell>
                          )}

                          <TableCell
                            width="15%"
                            className={classes.tableCellHeaders}
                          >
                            SubTotal
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {remitos?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell className={classes.tableCell}>
                              {item?.tipo}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {item?.cant_metros?.toLocaleString("es-AR") ??
                                "-"}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {item?.precio_unitario?.toLocaleString("es-AR", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "ARS",
                              }) ?? "-"}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {item?.bonif?.toLocaleString("es-AR", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "ARS",
                              }) ?? "-"}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {item?.importe?.toLocaleString("es-AR", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "ARS",
                              }) ?? "-"}
                            </TableCell>

                            {dataFactura?.tipo_factura === "A" && (
                              <TableCell className={classes.tableCell}>
                                {item?.iva + "%" ?? "-"}
                              </TableCell>
                            )}

                            <TableCell className={classes.tableCell}>
                              {item?.subtotal?.toLocaleString("es-AR", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "ARS",
                              }) ?? "-"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    container
                    justifyContent="flex-end"
                    style={{ marginTop: "1rem", paddingBottom: "2rem" }}
                  >
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {`Total $ 
                      ${ordenDetails?.id ? ordenDetails?.monto :
                          calcularTotal()?.toLocaleString("es-AR", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        }`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DetallePago
              open={openDetallePago}
              setOpen={setOpenDetallePago}
              factura={dataFactura}
            />
          </Grid>
        ) : (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingTop: "14rem",
            }}
          >
            <CircularProgress />
          </Grid>
        )}
      </>
    </Protected>
  );
};

export default VisualizarFactura;
