/* eslint-disable no-sequences */
import { React, useEffect, useState, useContext } from "react";
import Protected from "protected";
import {
  Grid,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  TextField,
  MenuItem,
  CircularProgress,
  withStyles,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Create, Delete } from "@material-ui/icons";
import FormulaService from "services/formula.service";
import RootContainer from "layout/rootContainer";
import ContainerCustom from "components/comtainerCustom";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ConfirmDialog from "components/ConfirmDialog";
import Theme from "theme";
import UsuarioService from "services/usuario.service";
import CreateEditMaterialModal from "components/formulas/createEditMaterialModal";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { LogIn } from "context/LogInContext";

// eslint-disable-next-line no-unused-vars
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
  },
}))(MenuItem);

const ListaMateriales = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { logged } = useContext(LogIn);
  // ESTADOS
  const [acceso, setAcceso] = useState(false);
  const [verificando, setVerificando] = useState(true);
  const [materiales, setMateriales] = useState([]);
  const [materialSeleccionado, setMaterialSeleccionado] = useState();
  const [filtrado, setFiltrado] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [modalCreateEditMaterial, setModalCreateEditMaterial] = useState(false);

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // Funcion Cerrar SnackBar
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Función para traer lista de Materiales
  const obtenerMateriales = async () => {
    try {
      const res = await FormulaService.getMateriales();
      return res.data;
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: "Error al obtener Materiales",
      });
      return error;
    }
  };

  // Funcion para Eliminar Material
  const borrarMaterial = async (id) => {
    try {
      const res = await FormulaService.deleteMaterial(id);
      if (res.status > 199 && res.status < 299) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Material Borrado exitosamente",
        });
        queryClient.invalidateQueries("Materiales");
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: "Error al Borrar material",
        });
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "info",
        message:
          error?.response?.data?.msj ?? "Error de conexión Borrar material",
      });
    }
  };

  // Verificar Acceso a modulo Formula
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await UsuarioService.checkAccess({ modulo: "formulas" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "formulas" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "formulas" });
    }
  };

  // Funcion Abrir Modal Creacion y Edicion de Materiales
  const crearMaterial = () => {
    setModalCreateEditMaterial(true);
  };

  // Cerrar Modal Creacion y Edicion de Materiales
  const handleCloseModalCreateEditMaterial = () => {
    setModalCreateEditMaterial(false);
    setMaterialSeleccionado("");
  };

  // eslint-disable-next-line no-unused-vars
  const { data: ArrayFormulas, isLoading } = useQuery(
    "Materiales",
    obtenerMateriales,
    {
      onSuccess: (data) => {
        setMateriales(data);
        setFiltrado(data);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    }
  );

  const handleBusqueda = async (text) => {
    let search = text.toUpperCase();
    let result = [];
    if (text.length > 0) {
      result = filtrado.filter((formula) => formula.nombre.includes(search));
      setFiltrado(result);
    } else {
      setFiltrado(materiales);
    }
  };

  useEffect(() => {
    handleBusqueda(busqueda);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busqueda]);

  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Protected>
      <RootContainer>
        <ContainerCustom>
          {verificando ? (
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              style={{
                marginTop: "15rem",
              }}
            >
              <CircularProgress
                style={{
                  color: Theme.palette.primary.main,
                  marginRight: "1rem",
                }}
              />
              <Typography style={{ color: "#000000" }}>
                Verificando permisos
              </Typography>
            </Grid>
          ) : (
            <>
              {acceso && (
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignContent="center"
                  style={{
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    marginTop: "1rem",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <TextField
                      size="small"
                      id="outlined-full-width"
                      label="Buscar por Nombre"
                      variant="outlined"
                      value={busqueda}
                      onChange={(e) => {
                        setBusqueda(e.target.value);
                      }}
                      style={{
                        margin: "0.8rem 1rem 0 0",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={
                                busqueda
                                  ? () => {
                                      setBusqueda("");
                                      handleBusqueda();
                                    }
                                  : null
                              }
                            >
                              <SearchOutlinedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          color: Theme.palette.primary.main,
                          textTransform: "capitalize",
                          marginTop: "1rem",
                          marginRight: "1rem",
                        }}
                        onClick={() => history.push("/formulas")}
                      >
                        VOLVER
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          color: "#ffffff",
                          backgroundColor: Theme.palette.primary.main,
                          textTransform: "capitalize",
                          marginTop: "1rem",
                        }}
                        onClick={() => (
                          setMaterialSeleccionado(""), crearMaterial()
                        )}
                      >
                        CREAR MATERIAL
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    style={{
                      backgroundColor: "#ffffff",
                      marginBottom: "4rem",
                      marginTop: "1rem",
                      boxShadow: "0px 5px 5px rgba(0, 0, 0, .2)",
                      borderRadius: 10,
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      style={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        backgroundColor: "#001176",
                        height: "3rem",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          marginTop: "1rem",
                          height: "3rem",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Materiales
                      </Typography>
                    </Grid>

                    {isLoading ? (
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ width: "5vw", height: "150px" }}
                      >
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <TableContainer
                        style={{ maxHeight: "60vh", overflow: "auto" }}
                      >
                        <Table stickyHeader size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ textAlign: "left" }}>
                                Nombre
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                Tipo
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                Unidad de Medida
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                Descripción
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Acciones
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(!materiales || materiales?.length <= 0) && (
                              <TableRow>
                                <Typography
                                  style={{ margin: "1rem", width: "14rem" }}
                                >
                                  No se encontraron Materiales
                                </Typography>
                              </TableRow>
                            )}
                            {materiales &&
                              filtrado?.length > 0 &&
                              filtrado?.map((item) => (
                                <TableRow hover key={item.id}>
                                  <TableCell style={{ textAlign: "left" }}>
                                    {item?.nombre.toUpperCase() ?? " - "}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "left" }}>
                                    {item?.tipo.toUpperCase() ?? " - "}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "left" }}>
                                    {item?.unidad.toUpperCase() ?? " - "}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "left" }}>
                                    {item?.descripcion !== ""
                                      ? item?.descripcion.toUpperCase()
                                      : " - "}
                                  </TableCell>
                                  <TableCell>
                                    <Grid
                                      container
                                      item
                                      direction="row"
                                      justifyContent="center"
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "6rem",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => (
                                          setMaterialSeleccionado(item),
                                          crearMaterial()
                                        )}
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          marginLeft: "0.5rem",
                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                      >
                                        <Create
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        onClick={() =>
                                          setConfirmDialog({
                                            title: `¿Está seguro de borrar el Material ${item?.nombre}?`,
                                            isOpen: true,
                                            onConfirm: () => {
                                              borrarMaterial(item?.id);
                                            },
                                          })
                                        }
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          marginLeft: "0.5rem",

                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                      >
                                        <Delete
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        {modalCreateEditMaterial && (
                          <CreateEditMaterialModal
                            open={modalCreateEditMaterial}
                            handleClose={handleCloseModalCreateEditMaterial}
                            material={materialSeleccionado}
                            setSnackBar={setSnackState}
                          />
                        )}
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <CustomSnackbar
            message={snackState.message}
            open={snackState.open}
            severity={snackState.severity}
            onClose={onClose}
          />
        </ContainerCustom>
      </RootContainer>
    </Protected>
  );
};
export default ListaMateriales;
