/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
// MATERIAL-UI
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
// ICONS
import PrintIcon from "@mui/icons-material/Print";
// STYLES
import useStyles from "./styles";
// COMPONENTS
import VerificarAcceso from "components/verificarAcceso";
import SearchBar from "components/Layout/searchBar/SearchBar";
import Card from "components/card";
import ImprimirInforme from "./components/imprimirInforme";
import LoadingText from "components/loadingText";
import CondicionPagoFacturas from "components/modifCondPagoFacturas";
import ConfirmDialog from "components/ConfirmDialog";
import CustomSnackbar from "components/Layout/CustomSnackbars";
// SERVICES
import cuentaCorrienteService from "services/cuentaCorriente.service";
// VARIOS
import mostrarErrorEP from "constants/funciones/mostrarError";
import { LogIn } from "context/LogInContext";
import { NotificationContext } from "context/notification/NotificationContext";
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import { useHistory, useParams } from "react-router-dom";
import CondicionPagoResumen from "components/modifCondPagoResumen/condicionPagoResumen";

import ButtonLoading from "components/buttonLoading/ButtonLoading";
import TableGeneric from "components/tableGeneric/tableGeneric";
import CollapseRow from "./components/collapseRow";
import usePrint from "hook/usePrint";
import { toContable } from "constants/funciones/toContable";
import { useDebounce } from "hook/useDebounce";
import { shallow } from "zustand/shallow";
import {
  useFacturaActions,
  useFacturasStore,
  useNoteTipeSelected,
  useOrderBy,
  useSearchNotesOrClients,
  useRef,
} from "../facturacion/store";
import moment from "moment";
import { FormControl, MenuItem, Select, Pagination } from "@mui/material";
import { namesCtaCte } from "./const";

//constants
const SORT_TYPE = Object.freeze({
  SORT_DATE: Symbol(),
  SORT_NUMFAC: Symbol(),
  SORT_MONTO: Symbol(),
  SORT_COND: Symbol(),
});

export default function FacturasCuentaCorriente() {
  const history = useHistory();
  const styles = useStyles();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { componentRef, handlePrint } = usePrint();
  const { cliente } = history?.location?.state;
  const [loadingInforme, setLoadingInforme] = useState(false);
  const [cuentasSeleccionadas, setCuentasSeleccionadas] = useState([]);
  const [openAgregarFormaPago, setOpenAgregarFormaPago] = useState(false);
  const [openCondicionPagoFacturas, setOpenCondicionPagoFacturas] =
    useState(false);
  const { control, reset, watch, getValues } = useForm({});
  const [total, setTotal] = useState();
  const [actual, setActual] = useState();
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const { logged } = useContext(LogIn);
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // state manager with zustand
  const filtro = useFacturasStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      tipo: state.tipo,
      ordfecha: state.ordfecha,
      ordnumfac: state.ordnumfac,
      ordmonto: state.ordmonto,
      ordcondi: state.ordcondi,
      page: state.page,
      dias: state.dias,
      condicion: state.condicion,
    }),
    shallow,
  );
  const selectTexto = useNoteTipeSelected();
  const searchText = useSearchNotesOrClients();
  const orderBy = useOrderBy();
  const ref = useRef();

  //Actions with zustand
  const {
    setFechaDesde,
    setFechaHasta,
    setSelectTexto,
    setSearch,
    setTipo,
    setDias,
    setPage,
    setTodo,
    setCondicion,
    setOrdFecha,
    toggleSort,
    setRef,
    resetState,
  } = useFacturaActions();

  // useDebounce hook for input text
  const debouncedValue = useDebounce(searchText, 300);

  // FUNCION DE BUSCADOR POR CLIENTE O FACTURA
  const handleSearch = async (text) => {
    setSearch(text);
  };

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  const mostrarPor = async (search) => {
    switch (search) {
      case "Todas":
        setTodo("Todas");
        break;
      case "Pagado":
        setCondicion("Pagado");
        break;
      case "A Cobrar":
        setCondicion("A Cobrar");
        break;
      case "Vencidas":
        setDias(moment().format("YYYY-MM-DD"));
        break;
      default:
        setTipo("Todas");
    }
  };

  const [listaCuentas, setListaCuentas] = useState([]);

  const headers = [
    { name: "" },
    {
      name: "Fecha Emisión",
      style: { minWidth: "10rem", padding: "0.5rem 0.5rem 0.5rem 0" },
      orderBy: namesCtaCte?.ordFecha,
    },
    { name: "Tipo de Comprobante", style: { minWidth: "7rem" } },
    { name: "Nº de Comprobante", style: { minWidth: "7rem" } },

    { name: "Nº Orden de Compra", style: { minWidth: "11rem" } },
    { name: "Nº Resumen", style: { minWidth: "8rem" } },
    { name: "Monto", style: { minWidth: "9rem" } },
    { name: "Pagado" },
    { name: "Saldo" },
    { name: "Acumulado" },
  ];

  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  const sortBy = (criteria) => {
    const sortType = orderBy ? "asc" : "desc";
    switch (criteria) {
      case SORT_TYPE.SORT_DATE:
        setOrdFecha(sortType);
        break;
      default:
        break;
    }
    toggleSort();
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  const obtenerCuentasSeleccionadas = () => {
    const values = getValues();

    const cuentasSeleccionadas = data?.results?.filter(
      (item) => values?.[item?.id],
    );

    setCuentasSeleccionadas(cuentasSeleccionadas);
  };

  const limpiarCuentasSeleccionadas = () => {
    reset({
      [namesCtaCte?.fechaDesde]: watch(namesCtaCte?.fechaDesde),
      [namesCtaCte?.fechaHasta]: watch(namesCtaCte?.fechaHasta),
      [namesCtaCte?.filtrarPor]: watch(namesCtaCte?.filtrarPor),
    });
    setCuentasSeleccionadas([]);
  };

  const haberCuentaCorriente = async () => {
    const { data } = await cuentaCorrienteService.obtenerHaberCtaCte(id);

    return data;
  };

  const { data: haber } = useQuery(
    ["haberCuentaCorriente"],
    haberCuentaCorriente,
    {
      onError: (error) => mostrarErrorEP(error, setSnackState),
      refetchInterval: false,
      refetchOnReconnect: false,
    },
  );

  const facturasCuentaCorriente = async () => {
    const { data } = isNotificationsEnabled
      ? await cuentaCorrienteService.getFacturasCtaCte(id, {
          ...filtro,
          search: debouncedValue,
        })
      : await cuentaCorrienteService.getFacturasCtaCteB(id, {
          ...filtro,
          search: debouncedValue,
        });

    return data;
  };

  const { data, isLoading } = useQuery(
    ["facturasCuentaCorriente", filtro, isNotificationsEnabled, debouncedValue],
    facturasCuentaCorriente,
    {
      onError: (error) => mostrarErrorEP(error, setSnackState),
      onSuccess: (dataFacturas) => {
        setTotal(dataFacturas?.total_paginas);
        setActual(dataFacturas?.actual);
      },
      refetchInterval: false,
      refetchOnReconnect: false,
    },
  );

  const refreshQueries = () => {
    queryClient.invalidateQueries({
      queryKey: ["facturasCuentaCorriente"],
    });
    queryClient.invalidateQueries({
      queryKey: ["haberCuentaCorriente"],
    });
  };

  const obtenerTodasFacturas = async () => {
    setLoadingInforme(true);
    let resultados = [];
    for (let index = 1; index <= data?.total_paginas; index++) {
      const dataSend = {
        ...filtro,
        search: debouncedValue,
      };
      try {
        const { data } = isNotificationsEnabled
          ? await cuentaCorrienteService.getFacturasCtaCte(id, dataSend)
          : await cuentaCorrienteService.getFacturasCtaCteB(id, dataSend);

        resultados = [...resultados, ...data?.results];
      } catch (error) {
        setLoadingInforme(false);
        return mostrarErrorEP(error, setSnackState);
      }
    }
    setLoadingInforme(false);
    setListaCuentas(resultados);
    handlePrint();
  };

  useEffect(() => {
    console.log("ref", ref);
    if (ref !== "ctacte-comprobantes") {
      resetState();
      setTimeout(() => {
        setRef("ctacte-comprobantes");
      }, 200);
      setSearch("");
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo={namesCtaCte?.modulo}
            history={history}
            logged={logged}
          />
        ) : (
          acceso && (
            <Grid
              container
              style={{
                margin: "2rem 2rem 4rem 2rem",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item style={{ marginRight: "4rem" }}>
                      <Grid container>
                        <Typography variant="body1" style={styles?.typography}>
                          Nombre:
                        </Typography>

                        <Typography variant="body1">
                          {cliente?.[namesCtaCte?.clienteNombre]}
                        </Typography>
                      </Grid>

                      <Grid container>
                        <Typography variant="body1" style={styles?.typography}>
                          DNI:
                        </Typography>

                        <Typography variant="body1">
                          {cliente?.[namesCtaCte?.clienteCuil]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item style={{ marginRight: "2rem" }}>
                      <Grid container>
                        <Typography variant="body1" style={styles?.typography}>
                          Dirección:
                        </Typography>

                        <Typography variant="body1">
                          {cliente?.[namesCtaCte?.clienteDireccion]}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Typography variant="body1" style={styles?.typography}>
                          Teléfono:
                        </Typography>
                        <Typography variant="body1">
                          {cliente?.[namesCtaCte?.clienteTelefono]}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item style={{ margin: "0.5rem 0" }}>
                      <Grid
                        container
                        style={{
                          ...styles?.total,
                          backgroundColor: "#ff000066",
                        }}
                      >
                        <Typography variant="body1" style={styles?.typography}>
                          Total Debe:
                        </Typography>

                        <Typography variant="body1">
                          {toContable(data?.total_debe)}
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        style={{
                          ...styles?.total,
                          backgroundColor: "#aedf0080",
                        }}
                      >
                        <Typography variant="body1" style={styles?.typography}>
                          Total Haber:
                        </Typography>

                        <Typography variant="body1">
                          {toContable(haber?.credito_haber)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ margin: "0.5rem 0" }}>
                  <Grid container justifyContent="flex-end">
                    <ButtonLoading
                      style={{
                        textTransform: "capitalize",
                        marginBottom: "0.5rem",
                      }}
                      startIcon={<PrintIcon />}
                      onClick={obtenerTodasFacturas}
                      isLoading={loadingInforme}
                      disabled={isLoading || loadingInforme}
                    >
                      Imprimir Informe
                    </ButtonLoading>
                  </Grid>
                  <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={cuentasSeleccionadas?.length < 2}
                      onClick={() => setOpenCondicionPagoFacturas(true)}
                      style={{
                        backgroundColor:
                          cuentasSeleccionadas?.length < 2 ? "grey" : "#001176",
                        textTransform: "capitalize",
                        maxHeight: "2rem",
                        marginRight: "0.5rem",
                        color: "#FFFFFF",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Varias facturas en un mismo pago
                    </Button>

                    <Button
                      variant="contained"
                      disabled={cuentasSeleccionadas?.length < 1}
                      color="primary"
                      size="small"
                      onClick={() => setOpenAgregarFormaPago(true)}
                      style={{
                        backgroundColor:
                          cuentasSeleccionadas?.length < 1 ? "grey" : "#001176",
                        textTransform: "capitalize",
                        maxHeight: "2rem",
                        color: "#FFFFFF",
                      }}
                    >
                      Cambiar Condición de Pago
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                alignItems="center"
                style={{ marginBottom: "1rem" }}
              >
                <Grid xl={2} lg={2} md={2} sm={12} xs={12} item>
                  <TextField
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Fecha Desde"
                    focused
                    defaultValue={filtro.fecha_desde}
                    onChange={(e) =>
                      setFechaDesde(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                    inputProps={{
                      max: filtro.fecha_hasta,
                    }}
                  />
                </Grid>
                <Grid xl={2} lg={2} md={2} sm={12} xs={12} item>
                  <TextField
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Fecha Hasta"
                    focused
                    defaultValue={filtro.fecha_hasta}
                    onChange={(e) =>
                      setFechaHasta(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                    inputProps={{
                      min: filtro.fecha_desde,
                      max: moment().format("YYYY-MM-DD"),
                    }}
                  />
                </Grid>
                <Grid xl={2} lg={2} md={2} sm={12} xs={12} item>
                  <Box>
                    <FormControl style={{ width: "190px" }}>
                      <Select
                        size="small"
                        variant="outlined"
                        value={selectTexto}
                        displayEmpty
                        renderValue={
                          selectTexto !== ""
                            ? undefined
                            : () => <Placeholder>Mostrar por:</Placeholder>
                        }
                        onChange={(e) => (
                          // eslint-disable-next-line no-sequences
                          mostrarPor(e.target.value),
                          setSelectTexto(e.target.value)
                        )}
                      >
                        <MenuItem value={"Todas"}>Todas</MenuItem>
                        <MenuItem value={"Vencidas"}>
                          Impagas Mayor a 30 días
                        </MenuItem>
                        <MenuItem value={"A Cobrar"}>Impagas</MenuItem>
                        <MenuItem value={"Pagado"}>Pagado</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid xl={2} lg={4} md={5} sm={12} xs={12} item>
                  <SearchBar
                    placeholder="N° Resúmen/Orden/Factura"
                    defaultValue={searchText}
                    value={searchText}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item container xl={4} lg={12} md={12} sm={12} xs={12}>
                  <Grid item style={styles?.puntoAmarillo} />

                  <Grid item>
                    <Typography variant="body1">
                      Facturas Impagas Mayor a 30 Días
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Card
                title="Lista de Comprobantes en Cuenta Corriente"
                justifyContent="center"
              >
                {isLoading ? (
                  <LoadingText title="Cargando" />
                ) : data?.count > 0 ? (
                  <>
                    <TableGeneric
                      size="small"
                      stickyHeader
                      headers={headers}
                      orderBy={sortBy}
                      styles={styles?.tableGeneric}
                    >
                      {data?.results?.map((item, indexItem) => (
                        <CollapseRow
                          key={indexItem}
                          item={item}
                          control={control}
                          obtenerCuentasSeleccionadas={
                            obtenerCuentasSeleccionadas
                          }
                        />
                      ))}
                    </TableGeneric>
                  </>
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    style={{ margin: "0.5rem" }}
                  >
                    <Typography variant="subtitle1">
                      No se encontraron resultados
                    </Typography>
                  </Grid>
                )}
              </Card>
              {data && (
                <Grid container justifyContent="center">
                  <Grid style={{ marginTop: "0.5rem" }}>
                    <Pagination
                      count={total}
                      color="primary"
                      onChange={(event, value) => setPage(value)}
                      page={filtro?.page}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid hidden>
                <ImprimirInforme
                  componentRef={componentRef}
                  cuentas={listaCuentas}
                  cliente={cliente}
                  totalDebe={data?.total_debe}
                  filtro={filtro}
                />
              </Grid>
            </Grid>
          )
        )}
        <CondicionPagoResumen
          open={openAgregarFormaPago}
          setOpen={setOpenAgregarFormaPago}
          facturasSeleccionadas={cuentasSeleccionadas}
          setFacturasSeleccionadas={setCuentasSeleccionadas}
          setConfirmDialog={setConfirmDialog}
          setSnackState={setSnackState}
          resetCheckboxes={limpiarCuentasSeleccionadas}
          refreshQueries={refreshQueries}
        />

        <CondicionPagoFacturas
          open={openCondicionPagoFacturas}
          setOpen={setOpenCondicionPagoFacturas}
          setConfirmDialog={setConfirmDialog}
          setSnackState={setSnackState}
          items={cuentasSeleccionadas}
          setItems={setCuentasSeleccionadas}
          resetCheckboxes={limpiarCuentasSeleccionadas}
          refreshQueries={refreshQueries}
        />

        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          autoHideDuration={snackState.autoHideDuration}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
}
