import React,{useContext} from "react"
import { LogIn } from "context/LogInContext";
import { useHistory } from "react-router-dom";

const Protected = ({children}) => {
  const history = useHistory();
  const { logged} = useContext(LogIn);
    
    if (!logged) {
        history.push("/");
      }
    
      return (
        <>{children}</>
    )
}

export default Protected