export const useStyles = () => {
  
    return {
      tableProvidersMaterials: {
        table: () => ({}),
        tableContainer: () => ({ maxHeight: "60vh", overflow: "scroll" }),
        tableHead: () => ({}),
        tableBody: () => ({}),
        tableRowHead: () => ({}),
        tableRowBody: () => ({}),
      },
      tableCell: { borderBottom: 0, padding: "0.4rem" },
    };
  };
  