import LayoutHome from "../layout/LayoutHome";
import Home from "../screens/home";
import Error404 from "../screens/error404";
import EditUserForm from "../screens/edit-user-form";
import AdminClaves from "screens/panel-admin/admin-usuarios";
import Tracking from "screens/panel-admin/tracking";
import Registro from "screens/auth/register";
import Login from "screens/auth/login";
import RecoverPassword from "screens/auth/recover-password";
import ChangePassword from "screens/auth/change-password";
import Success from "screens/success";
import Logs from "screens/logs";
import ErrorPermiso from "screens/error-permiso";
import AdminPrivilegios from "screens/panel-admin/admin-privilegios";
import ErrorVerificacion from "screens/error-verificacion";
import ErrorRedireccion from "screens/errorRedireccion/error-redireccion";
import ErrorArchivoInexistente from "screens/error-archivo/archivo-inexistente";

// CLIENTES
import Clientes from "screens/clientes";
import CrearCliente from "screens/clientes/crear-cliente";
import EstadoDeCuenta from "screens/clientes/estado-cuenta/estadoCuenta";
import Formulas from "screens/formulas";
import CrearRemito from "screens/remitos/crear-remito";
import Agenda from "screens/agenda";

// FACTURACION
import Facturacion from "../screens/facturacion/index";
import FacturasClienteSeleccionado from "../screens/facturacion/seleccionar-cliente/index";
import FacturasPorCliente from "../screens/facturacion/factura-por-cliente/facturaCliente";
import VisualizarFactura from "screens/facturacion/visualizar";
import CrearFactura from "screens/facturacion/crearFactura";
import EditarFactura from "screens/facturacion/editarFactura";
import CrearRecibo from "screens/facturacion/crearRecibo";
import EditarRecibo from "screens/facturacion/editarRecibo";
import NotasCreditoDebito from "screens/facturacion/notas-credito-debito/notasCreditoDebitoList";

// PANTALLAS SECCIÓN ORDENES DE COMPRA
import OrdenesBlancoNegro from "screens/ordenes/index";
import CrearOrden from "screens/ordenes/crearOrden";
import VerOrden from "screens/ordenes/verOrden";
import EditarOrden from "screens/ordenes/editarOrden";
import VerOrdenNegro from "screens/ordenes/verOrdenNegro";

import Remitos from "screens/remitos/index.js";
import RemitosPorClienteSeleccionado from "screens/remitos/remitos-por-cliente";
import RemitosPorCliente from "screens/remitos/seleccionar-cliente";
import VisualizarRemito from "screens/remitos/visualizar";
import TotalMaterialMensual from "screens/remitos/total-material-mensual";
import ListaMateriales from "screens/formulas/lista-materiales";

import VisualizarResumen from "screens/remitos/remitos-por-cliente/visualizar-resumen";
import Resumen from "screens/remitos/remitos-por-cliente/resumen";
import ResumenClienteSeleccionado from "screens/remitos/menu-resumenes/seleccionar-cliente/index";
import ResumenLiquidacion from "screens/remitos/remitos-por-cliente/resumen-liquidacion";
import ResumenesLiquidaciones from "screens/remitos/menu-resumenes";

// RECIBOS
import VisualizarRecibo from "screens/facturacion/visualizar/ver-Recibo";

// CUENTA CORRIENTE
import CuentaCorriente from "screens/cuenta-corriente/cuentaCorriente";
import FacturasCuentaCorriente from "screens/cuenta-corriente/facturasCuentaCorriente";
import CrearFacturaOrden from "screens/facturacion/crearFacturaOrden";
import OrdenCompraAnticipada from "screens/ordenes/ordenCompraAnticipada";
import CargarRemitoAnticipado from "screens/remitos/crear-remito-anticipado/cargar-remito-anticipado";

// PROVEEDORES
import NuevoEditarRemito from "screens/proveedores/remitos/crear-remito/index.js";
import RemitosProveedores from "screens/proveedores/remitos/index.js";
import DetallesRemitoProveedor from "screens/proveedores/remitos/ver-detalles/index.js";
import ListadoProveedores from "screens/proveedores/listado-proveedores/ListadoProveedores";
import CrearEditarProveedor from "screens/proveedores/crear-editar-proveedores/CrearEditarProveedor";
import DetalleProveedor from "screens/proveedores/detalle-proveedor/DetalleProveedor";
import ListadoMaterialesProveedor from "screens/proveedores/materiales/listado-materiales/ListadoMaterialesProveedor";
import ChoferesAutorizados from "screens/proveedores/choferes-autorizados/listado-choferes/ChoferesAutorizados";

const routes = [
  {
    path: "/",
    component: LayoutHome,
    exact: false,
    routes: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/edit-user-form",
        component: EditUserForm,
        exact: true,
      },
      {
        path: "/clientes",
        component: Clientes,
        exact: true,
      },
      {
        path: "/clientes/crear-cliente",
        component: CrearCliente,
        exact: true,
      },
      {
        path: "/clientes/estado-cuenta",
        component: EstadoDeCuenta,
        exact: true,
      },
      {
        path: "/ordenes/",
        component: OrdenesBlancoNegro,
        exact: true,
      },
      {
        path: "/ordenes/crear-orden/",
        component: CrearOrden,
        exact: true,
      },
      {
        path: "/ordenes/cargar-orden-compra-anticipada/",
        component: OrdenCompraAnticipada,
        exact: true,
      },
      {
        path: "/ordenes/ver-orden/:id/",
        component: VerOrden,
        exact: true,
      },
      {
        path: "/ordenes/ver-orden-negro/:id/",
        component: VerOrdenNegro,
        exact: true,
      },
      {
        path: "/ordenes/editar-orden/:id/",
        component: EditarOrden,
        exact: true,
      },
      {
        path: "/remitos/crear-remito",
        component: CrearRemito,
        exact: true,
      },
      {
        path: "/remitos/crear-remito-anticipado",
        component: CargarRemitoAnticipado,
        exact: true,
      },
      {
        path: "/remitos",
        component: Remitos,
        exact: true,
      },
      {
        path: "/remitos/seleccionar-cliente",
        component: RemitosPorCliente,
        exact: true,
      },
      {
        path: "/remitos/visualizar/:id/",
        component: VisualizarRemito,
        exact: true,
      },
      {
        path: "/remitos/total-mensual-materiales",
        component: TotalMaterialMensual,
        exact: true,
      },
      {
        path: "/resumenes/seleccionar-cliente",
        component: ResumenClienteSeleccionado,
        exact: true,
      },
      {
        path: "/resumenes/resumen-por-cliente/:id/",
        component: ResumenesLiquidaciones,
        exact: true,
      },
      {
        path: "/formulas",
        component: Formulas,
        exact: true,
      },
      {
        path: "/formulas/lista-materiales",
        component: ListaMateriales,
        exact: true,
      },
      {
        path: "/agenda",
        component: Agenda,
        exact: true,
      },
      {
        path: "/facturacion",
        component: Facturacion,
        exact: true,
      },
      {
        path: "/recibos/visualizar-recibo/:id/",
        component: VisualizarRecibo,
        exact: true,
      },
      {
        path: "/facturacion/crear-factura-orden/",
        component: CrearFacturaOrden,
        exact: true,
      },
      {
        path: "/facturacion/editar-factura-orden/:id/",
        component: CrearFacturaOrden,
        exact: true,
      },
      {
        path: "/facturacion/crear-factura/",
        component: CrearFactura,
        exact: true,
      },
      {
        path: "/facturacion/editar-factura/:id/",
        component: EditarFactura,
        exact: true,
      },
      {
        path: "/recibo/crear-recibo/",
        component: CrearRecibo,
        exact: true,
      },
      {
        path: "/recibo/editar-recibo/:id/",
        component: EditarRecibo,
        exact: true,
      },
      {
        path: "/facturacion/seleccionar-cliente",
        component: FacturasClienteSeleccionado,
        exact: true,
      },
      {
        path: "/facturacion/facturas-por-cliente/:id/",
        component: FacturasPorCliente,
        exact: true,
      },
      {
        path: "/facturacion/visualizar-factura",
        component: VisualizarFactura,
        exact: true,
      },
      {
        path: "/facturacion/notas-credito-debito",
        component: NotasCreditoDebito,
        exact: true,
      },
      {
        path: "/cuenta-corriente/",
        component: CuentaCorriente,
        exact: true,
      },
      {
        path: "/cuenta-corriente/:id/facturas",
        component: FacturasCuentaCorriente,
        exact: true,
      },
      {
        path: "/panel-admin/admin-usuarios",
        component: AdminClaves,
        exact: true,
      },
      {
        path: "/panel-admin/tracking",
        component: Tracking,
        exact: true,
      },
      {
        path: "/panel-admin/admin-privilegios",
        component: AdminPrivilegios,
        exact: true,
      },
      {
        path: "/auth/register",
        component: Registro,
        exact: true,
      },
      {
        path: "/auth/login",
        component: Login,
        exact: true,
      },
      {
        path: "/auth/recover-password",
        component: RecoverPassword,
        exact: true,
      },
      {
        path: "/auth/change-password/:user_hash",
        component: ChangePassword,
        exact: true,
      },
      {
        path: "/success",
        component: Success,
        exact: true,
      },
      {
        path: "/logs",
        component: Logs,
        exact: true,
      },
      {
        path: "/remitos/remitos-por-cliente/:id/",
        component: RemitosPorClienteSeleccionado,
        exact: true,
      },
      {
        path: `/remitos/remitos-por-cliente/visualizar-resumen/:id/`,
        component: VisualizarResumen,
        exact: true,
      },
      {
        path: "/remitos/remitos-por-cliente/resumen/:id/",
        component: Resumen,
        exact: true,
      },
      {
        path: "/remitos/remitos-por-cliente/resumen-liquidacion/:id/",
        component: ResumenLiquidacion,
        exact: true,
      },
      {
        path: "/proveedores/crear-editar",
        component: CrearEditarProveedor,
        exact: true,
      },
      {
        path: "/proveedores/listado",
        component: ListadoProveedores,
        exact: true,
      },
      {
        path: "/proveedores/ver-detalle",
        component: DetalleProveedor,
        exact: true,
      },      
      {
        path: "/proveedores/materiales/listado",
        component: ListadoMaterialesProveedor,
        exact: true,
      },
      {
        path: "/proveedores/choferes-autorizados",
        component: ChoferesAutorizados,
        exact: true,
      },
      {
        path: "/proveedores/remitos/:id",
        component: RemitosProveedores,
        exact: true,
      },
      {
        path: "/proveedores/nuevo-editar-remito",
        component: NuevoEditarRemito,
        exact: true,
      },
      {
        path: "/proveedores/detalle-remito/:id",
        component: DetallesRemitoProveedor,
        exact: true,
      },
      {
        path: "/error-permiso",
        component: ErrorPermiso,
        exact: true,
      },
      {
        path: "/error-verificacion",
        component: ErrorVerificacion,
        exact: true,
      },
      {
        path: "/error-redireccion",
        component: ErrorRedireccion,
        exact: true,
      },
      {
        path: "/error-recurso-inexistente",
        component: ErrorArchivoInexistente,
        exact: true,
      },

      {
        component: Error404,
      },
    ],
  },
];

export default routes;
