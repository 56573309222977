import { styled } from "@material-ui/core/styles";
import { TableRow } from "@material-ui/core";

const StyledTableRow = styled(TableRow)(() => ({
  "&.MuiTableRow-root": {
    backgroundColor: "var(--backgroundColor)",
    fontWeight: "var(--fontWeight)",
  },
  "&.MuiTableRow-root.MuiTableRow-hover:hover": {
    backgroundColor: "var(--backgroundColor,rgba(0, 0, 0, 0.04))",
  },
}));

export default StyledTableRow;
