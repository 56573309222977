/* eslint-disable react-hooks/exhaustive-deps */

// REACT
import { useState } from "react";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

// MATERIAL UI
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";

// ICONS
import AddIcon from "@mui/icons-material/Add";
import { Delete, Visibility } from "@material-ui/icons";

// COMPONENTS
import VerificarAcceso from "components/verificarAcceso";
import Card from "components/card";
import ConfirmDialog from "components/ConfirmDialog";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ButtonLoading from "components/buttonLoading/ButtonLoading";

// SERVICES
import clientesService from "services/clientes.service";
import ordenesService from "services/ordenes.service";
import formulaService from "services/formula.service";

// VARIOS
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import { LogIn } from "context/LogInContext";
import rules from "constants/rules";
import Theme from "theme";
import mostrarErrorEP from "constants/funciones/mostrarError";
import moment from "moment";
import HistoricoPrecios from "./historicoPrecios";
import NewAutocomplete from "components/inputs/newAutocomplete";
import InputDate from "components/inputs/newInputDate";
import InputText from "components/inputs/newInputText";
import NewSelect from "components/inputs/newSelect";
import InputNumber from "components/inputs/newInputNumber";
import { mayorListaId } from "components/modifCondPagoLiquidacion/constants";
import NewCheckbox from "components/inputs/newCheckbox";

const EditarOrden = () => {
  // HOOK HISTORY
  const history = useHistory();

  // HOOK PARAMS
  const { id } = useParams();

  // HOOK FORM
  const { handleSubmit, control, watch } = useForm();

  // HOOK CONTEXT
  const { logged } = useContext(LogIn);

  // CONSTANTES
  // ==========================================================================
  const { presupuesto } = history?.location?.state;
  const clienteSeleccionado = watch("clienteOrden");
  // ==========================================================================

  // ESTADOS
  // ==========================================================================
  // Para verificar acceso de un usuario al modulo
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // Para CustomSnackbar
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // Para ConfirmDialog
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // Para capturar datos de endpoints
  const [orden, setOrden] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [formulas, setFormulas] = useState([]);

  // Para spinners y skeletons
  const [cargandoClientes, setCargandoClientes] = useState(false);
  const [editandoOrden, setEditandoOrden] = useState(false);

  // Para modal HistoricoPrecios
  const [modalHistorico, setModalHistorico] = useState(false);
  const [lineaSeleccionada, setLineaSeleccionada] = useState(null);

  // Para el manejo de las lineas de inputs
  const [lineas, setLineas] = useState([]);
  const [idLinea, setIdLinea] = useState(null);

  // Para enviar al back
  const [datos_formula, setDatos_formula] = useState([]);
  const [datos_eliminar, setDatos_eliminar] = useState([]);
  const [datos_editar, setDatos_editar] = useState([]);
  // ==========================================================================

  // FUNCIONES FLECHA
  // ==========================================================================
  // Para cerrar CustomSnackbar
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Para filtrar clientes por dni o nombre en autocomplete
  const filtrarClientes = (options, option) => {
    const resultados = options.filter(
      (cliente) =>
        cliente?.nombre.includes(option?.inputValue?.toUpperCase()) ||
        cliente?.cuil_dni.includes(option?.inputValue?.toUpperCase()),
    );

    return resultados;
  };

  // Para agregar una linea de inputs con select de formulas
  const agregarLinea = () => {
    let lineaExtra = {
      id: idLinea,
      isNew: true,
      formula_nombre: null,
      cantidad_m3: 0,
      precio_original: 0,
      fecha_vigencia_precio: null,
    };

    setIdLinea(idLinea + 1);
    setLineas([...lineas, lineaExtra]);
    setDatos_formula([...datos_formula, lineaExtra]);
  };

  // Para agregar una linea de inputs con input de concepto
  const agregarConcepto = () => {
    let lineaExtra = {
      id: idLinea,
      isNew: true,
      formula: null,
      cantidad_m3: 0,
      precio_original: 0,
      fecha_vigencia_precio: null,
      isConcepto: true,
    };

    setIdLinea(idLinea + 1);
    setLineas([...lineas, lineaExtra]);
    setDatos_formula([...datos_formula, lineaExtra]);
  };

  // Para eliminar una linea
  const eliminarHormigon = (index) => {
    const linea = lineas[index];

    lineas.splice(index, 1);

    datos_editar?.forEach((item, index) => {
      if (linea?.id === item?.id) {
        datos_editar.splice(index, 1);
        setDatos_editar(datos_editar);
      }
    });

    if (!linea?.isNew) {
      setDatos_eliminar([...datos_eliminar, linea?.id]);
    } else {
      datos_formula?.forEach((item, index) => {
        if (linea?.id === item?.id) {
          datos_formula.splice(index, 1);
          setDatos_formula(datos_formula);
        }
      });
    }
  };

  // Para modificar una linea
  const modificarLinea = (index) => {
    const linea = lineas[index];

    lineas.splice(index, 1, linea);

    if (!linea?.isNew) {
      setDatos_editar([...datos_editar, linea]);

      datos_editar?.forEach((item, index) => {
        if (linea?.id === item?.id) {
          datos_editar.splice(index, 1, linea);
          setDatos_editar(datos_editar);
        }
      });
    }
  };
  // ==========================================================================

  // ENDPOINTS
  // ==========================================================================
  // Para obtener la orden
  const obtenerOrden = async () => {
    try {
      const { data, status } = presupuesto
        ? await ordenesService.verOrdenNegro(id)
        : await ordenesService.verOrden(id);

      if (status > 199 && status < 300) {
        setOrden(data);
        setLineas(data?.detalle_orden);
        setIdLinea(mayorListaId(data?.detalle_orden) + 1);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };

  // Para obtener clientes
  const obtenerClientes = async () => {
    setCargandoClientes(true);
    try {
      const { data, status } = await clientesService.getAllClientes();
      if (status > 199 && status < 300) {
        data.forEach((cliente) => {
          cliente.nombre = cliente?.nombre?.toUpperCase();
        });

        const filtrado = data?.filter(
          (cliente) => cliente?.notificacion === false,
        );

        setClientes(filtrado);
        setCargandoClientes(false);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      setCargandoClientes(false);
      return error;
    }
  };

  //  Para obtener formulas
  const obtenerFormulas = async () => {
    try {
      const { data, status } = await formulaService.getAllFormulas();
      if (status > 199 && status < 300) {
        setFormulas(data);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };

  // Para editar una orden
  const onSubmit = async (data) => {
    setEditandoOrden(true);

    for (let index = 0; index < datos_editar.length; index++) {
      const hormigon = datos_editar[index];
      const datoHormigon = {
        id: hormigon?.id,
        formula: hormigon?.formula,
        concepto: hormigon?.concepto,
        cantidad_m3: hormigon?.cantidad_m3,
        precio_actual: hormigon?.precio_actual,
        fecha_vigencia_precio: hormigon?.fecha_vigencia_precio,
      };
      datos_editar?.splice(index, 1, datoHormigon);
      setDatos_editar(datos_editar);
    }

    for (let index = 0; index < datos_formula.length; index++) {
      const hormigon = datos_formula[index];
      const datoHormigon = {
        formula: hormigon?.formula,
        concepto: hormigon?.concepto,
        cantidad_m3: hormigon?.cantidad_m3,
        precio_original: hormigon?.precio_original,
        fecha_vigencia_precio: hormigon?.fecha_vigencia_precio,
      };
      datos_formula?.splice(index, 1, datoHormigon);
      setDatos_formula(datos_formula);
    }

    const dataSend = {
      fecha_carga: data?.fecha_carga,
      cliente: data?.clienteOrden?.id,
      presupuesto: presupuesto,
      condicion: data?.condicion ? "Pagado" : "A Cobrar",
      datos_formula: datos_formula,
      datos_eliminar: datos_eliminar,
      datos_editar: datos_editar,
    };

    try {
      const res = await ordenesService.editarOrden(id, dataSend);

      if (res.status > 199 && res.status < 300) {
        setSnackState({
          ...snackState,
          open: true,
          severity: "success",
          message: `Orden Editada Exitosamente`,
        });
        setTimeout(() => {
          setEditandoOrden(false);
          history.push(`/ordenes/`);
        }, [2000]);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      setEditandoOrden(false);
      return error;
    }
  };
  // ==========================================================================

  // FUNCIONES EFFECT
  // ==========================================================================
  useEffect(() => {
    obtenerOrden();
    obtenerClientes();
    obtenerFormulas();
  }, []);
  // ==========================================================================
  return (
    <Protected>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RootContainer>
          {verificando ? (
            <VerificarAcceso
              setVerificando={setVerificando}
              setAcceso={setAcceso}
              modulo="orden-compra"
              history={history}
              logged={logged}
            />
          ) : (
            acceso && (
              <Grid
                container
                style={{
                  padding: "1rem 0 5rem 2rem",
                  width: "95%",
                }}
              >
                <Card title="Editar Orden de Compra" justifyContent="center">
                  <Grid
                    container
                    style={{
                      padding: "1rem 4rem",
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      style={{ paddingTop: "1rem" }}
                    >
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0 0" }}
                      >
                        {orden === null ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <NewAutocomplete
                            control={control}
                            rules={rules.generic}
                            name="clienteOrden"
                            label="Cliente:"
                            placeholder="Nombre o CUIT"
                            size="small"
                            defaultValue={{
                              id: orden?.cliente_id,
                              nombre: orden?.cliente,
                              cuil_dni: orden?.cliente_cuil,
                              direccion: orden?.cliente_dir,
                            }}
                            freeSolo
                            clearOnBlur
                            options={clientes}
                            loadingText="Cargando..."
                            loading={cargandoClientes}
                            noOptionsText="No Hay Clientes"
                            getOptionLabel={(option) => option.nombre}
                            filterOptions={(options, option) =>
                              filtrarClientes(options, option)
                            }
                            InputLabelProps={{ required: true }}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0 0" }}
                      >
                        {orden === null ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <InputDate
                            name="fecha_carga"
                            label="Fecha Carga:"
                            InputLabelProps={{ required: true, shrink: true }}
                            control={control}
                            rules={rules.generic}
                            defaultValue={moment(
                              orden?.fecha_carga,
                              "DD-MM-YYYY",
                            ).format("YYYY-MM-DD")}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0 0" }}
                      >
                        {orden === null ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <InputText
                            name="num_orden"
                            label="Nº Orden de Compra:"
                            disabled
                            InputLabelProps={{ required: true }}
                            control={control}
                            rules={
                              presupuesto
                                ? rules.genericNoRequered
                                : rules.numberRequered
                            }
                            defaultValue={
                              orden?.num_orden?.toString() ??
                              orden?.id?.toString()
                            }
                          />
                        )}
                      </Grid>
                    </Grid>

                    {clienteSeleccionado && (
                      <Grid container style={{ paddingTop: "0.5rem" }}>
                        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "bold",
                              paddingRight: "0.5rem",
                            }}
                          >
                            CUIT/CUIL:
                          </Typography>
                          <Typography variant="body2">
                            {clienteSeleccionado?.cuil_dni}
                          </Typography>
                        </Grid>

                        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "bold",
                              paddingRight: "0.5rem",
                            }}
                          >
                            Domicilio:
                          </Typography>
                          <Typography variant="body2">
                            {clienteSeleccionado?.direccion}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container style={{ paddingTop: "0.5rem" }}>
                      {orden === null ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ height: "4rem", width: "6rem" }}
                        />
                      ) : (
                        <Grid container>
                          <Grid
                            item
                            style={{ margin: "0.5rem 0.5rem 0.5rem 0" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              children="Agregar Línea"
                              startIcon={<AddIcon />}
                              style={{ textTransform: "none" }}
                              onClick={() => agregarLinea()}
                            />
                          </Grid>

                          <Grid
                            item
                            style={{ margin: "0.5rem 0.5rem 0.5rem 0" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              children="Agregar Concepto"
                              startIcon={<AddIcon />}
                              style={{ textTransform: "none" }}
                              onClick={() => agregarConcepto()}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    {orden === null && formulas === null ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        style={{ height: "4rem", width: "100%" }}
                      />
                    ) : (
                      <TableContainer style={{ paddingTop: "1rem" }}>
                        <Table size="small">
                          <TableBody>
                            {lineas?.length > 0 &&
                              lineas
                                ?.map((linea, index) => (
                                  <TableRow key={linea.id}>
                                    <TableCell
                                      align="center"
                                      style={{
                                        padding: "0.5rem 0.5rem 0.7rem 0",
                                        borderBottom: 0,
                                        width: "1rem",
                                      }}
                                    >
                                      <IconButton
                                        size="small"
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                        onClick={() =>
                                          setConfirmDialog({
                                            title: `ELIMINAR ${
                                              linea?.isConcepto
                                                ? "CONCEPTO"
                                                : "HORMIGÓN"
                                            } ${
                                              linea?.formula_nombre ??
                                              linea?.concepto ??
                                              ""
                                            }`,
                                            isOpen: true,
                                            onConfirm: () => {
                                              eliminarHormigon(index);
                                            },
                                          })
                                        }
                                      >
                                        <Delete
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{
                                        padding: "0.5rem 0.5rem 0.5rem 0",
                                        borderBottom: 0,
                                        width: "24.5%",
                                      }}
                                    >
                                      {linea?.isConcepto || linea?.concepto ? (
                                        <InputText
                                          name={"concepto" + index}
                                          label="Concepto:"
                                          control={control}
                                          rules={rules.generic}
                                          defaultValue={linea?.concepto}
                                          onChange={(e, value) => {
                                            linea.concepto = value;

                                            modificarLinea(index);
                                          }}
                                        />
                                      ) : (
                                        <NewSelect
                                          name={"formula_nombre" + index}
                                          label="Tipo Hormigón:"
                                          control={control}
                                          rules={rules.generic}
                                          options={formulas}
                                          option="nombre"
                                          optionValue="nombre"
                                          defaultValue={linea?.formula__nombre}
                                          onChange={(e, value) => {
                                            const formula = formulas?.find(
                                              (formula) =>
                                                formula?.nombre === value,
                                            );

                                            linea.formula_nombre = value;
                                            linea.formula = formula?.id;

                                            modificarLinea(index);
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{
                                        padding: "0.5rem 0.5rem 0.7rem 0",
                                        borderBottom: 0,
                                        width: "24.5%",
                                      }}
                                    >
                                      <InputNumber
                                        name={"cantidad_m3" + linea?.id}
                                        label="Cantidad M³:"
                                        control={control}
                                        rules={rules.observacion}
                                        defaultValue={linea?.cantidad_m3 ?? 0}
                                        onChange={(e, value) => {
                                          linea.cantidad_m3 = Number(
                                            value?.toFixed(2),
                                          );

                                          modificarLinea(index);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{
                                        padding: "0.5rem 0.5rem 0.7rem 0",
                                        borderBottom: 0,
                                        width: "24.5%",
                                      }}
                                    >
                                      <InputNumber
                                        name={"precio_original" + linea?.id}
                                        label={
                                          linea?.isNew
                                            ? "Precio:"
                                            : "Precio Original:"
                                        }
                                        control={control}
                                        rules={rules.observacion}
                                        disabled={!linea?.isNew}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                        defaultValue={
                                          linea?.precio_original ?? 0
                                        }
                                        onChange={(e, value) => {
                                          linea.precio_original = Number(
                                            value?.toFixed(2),
                                          );

                                          modificarLinea(index);
                                        }}
                                      />
                                    </TableCell>
                                    {!linea?.isNew && (
                                      <TableCell
                                        align="left"
                                        style={{
                                          padding: "0.5rem 0.5rem 0.7rem 0",
                                          borderBottom: 0,
                                          width: "24.5%",
                                        }}
                                      >
                                        <InputNumber
                                          name={"precio_actual" + linea?.id}
                                          label="Precio Actual:"
                                          control={control}
                                          rules={rules.observacion}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                          defaultValue={
                                            linea?.precio_actual ?? 0
                                          }
                                          onChange={(e, value) => {
                                            linea.precio_actual = Number(
                                              value?.toFixed(2),
                                            );

                                            modificarLinea(index);
                                          }}
                                        />
                                      </TableCell>
                                    )}

                                    <TableCell
                                      align="left"
                                      style={{
                                        padding: "0.5rem 0.5rem 0.7rem 0",
                                        borderBottom: 0,
                                        width: "24.5%",
                                      }}
                                    >
                                      <InputDate
                                        name={
                                          "fecha_vigencia_precio" + linea?.id
                                        }
                                        label="Fecha Vigente:"
                                        control={control}
                                        rules={rules.observacion}
                                        defaultValue={
                                          linea?.fecha_vigencia_precio
                                        }
                                        onChange={(e, value) => {
                                          linea.fecha_vigencia_precio = value;

                                          modificarLinea(index);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        padding: "0.5rem 0.5rem 0.7rem 0",
                                        borderBottom: 0,
                                        width: "1rem",
                                      }}
                                    >
                                      <IconButton
                                        size="small"
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                        onClick={() => {
                                          setLineaSeleccionada(linea);
                                          setModalHistorico(true);
                                        }}
                                      >
                                        <Visibility
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                                .reverse()}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    <Grid container style={{ marginTop: "1rem" }}>
                      {orden === null ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ height: "4rem", width: "6rem" }}
                        />
                      ) : (
                        <NewCheckbox
                          name="condicion"
                          control={control}
                          label="Pagado"
                          color="primary"
                          defaultValue={orden?.condicion === "Pagado"}
                        />
                      )}
                    </Grid>

                    <Grid
                      container
                      justifyContent="flex-end"
                      style={{ marginTop: "1rem" }}
                    >
                      {orden === null ? (
                        <>
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{
                              height: "4rem",
                              width: "6rem",
                              marginRight: "0.5rem",
                            }}
                          />
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem", width: "6rem" }}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            children="Volver"
                            style={{
                              marginRight: "1rem",
                              textTransform: "none",
                            }}
                            onClick={() => history.goBack()}
                          />
                          <ButtonLoading
                            isLoading={editandoOrden}
                            disabled={editandoOrden}
                            children="Confirmar"
                            type="submit"
                            style={{ textTransform: "none" }}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Card>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
                <CustomSnackbar
                  message={snackState.message}
                  open={snackState.open}
                  severity={snackState.severity}
                  autoHideDuration={snackState.autoHideDuration}
                  onClose={onClose}
                />
                <HistoricoPrecios
                  open={modalHistorico}
                  close={setModalHistorico}
                  orden={lineaSeleccionada}
                  presupuesto={presupuesto}
                />
              </Grid>
            )
          )}
        </RootContainer>
      </form>
    </Protected>
  );
};

export default EditarOrden;
