import axios from "./axios.config";

class UsuarioService {
  editUser(id, data) {
    /**
     * @params {id del usuario y body con los campos a modificar}
     * @return (void) info del usuario editado
     */
    return axios.put(`/api/usuarios/editar/${id}/`, data);
  }

  getUserData(id) {
    /**
     * @params {id del usuario }
     * @return (void) info del usuario editado
     */
    return axios.get(`/api/usuarios/${id}/`);
  }

  getUsers() {
    /**
     * @return retorna todos los usuarios del sistema
     */
    return axios.get(`/api/usuarios/`);
  }

  getModulos() {
    /**
     * @return retorna todos los modulos del sistema
     */
    return axios.get(`/api/modulos/`);
  }

  /* Crear Rol
   */

  createRol(data) {
    return axios.post(`/api/usuarios/roles/`, data);
  }
  // Listar Permisos Acceso Rol-Modulo

  getRol(id) {
    return axios.get(`/api/usuarios/roles/${id}/`);
  }

  addRol(data) {
    const body = {
      rol: data.rolId,
    };
    return axios.put(`/api/usuarios/editar/${data.userId}/`, body);
  }
  getPermisos(id) {
    /**
     * @id id del rol
     * @return retorna todos los Permisos para ese rol
     */
    return axios.get(`/api/modulos/listar-permisos/rol/${id}/`);
  }

  // Obtener 1 acceso a Modulo
  getPermiso(id) {
    /**
     * @return retorna 1 permiso
     */
    return axios.get(`/api/modulos/relacion-modulos/${id}/`);
  }

  accesoModulo(data) {
    /**
  
     */
    return axios.post(`/api/modulos/relacion-modulos/`, data);
  }

  delPermiso(id) {
    /*
    Servicio para Borrar Permiso

    Eliminando relacion con modulo usuarios
    */
    return axios.delete(`/api/modulos/relacion-modulos/${id}/`);
  }

  editAccess(id, data) {
    /**
     * @params {}
     * @return (void)
     */
    return axios.put(`/api/usuarios/editar/${id}/`, data);
  }

  // Inactivar a un Usuario
  inactivateUser(id) {
    /**
     * @params id usuario a inactivas
     * @return
     */
    return axios.patch(`/api/usuarios/inactivar/${id}/`);
  }

  activateUser(id) {
    /**
     * @params id usuario a inactivas
     * @return
     */
    return axios.patch(`/api/usuarios/activar/${id}/`);
  }

  deleteUser(pk) {
    /**
     * @params {usuario a borrar}
     * @return
     */
    return axios.delete(`api/usuarios/eliminar/${pk}/`);
  }

  checkAccess(data) {
    /**
     * @params {data : nombre del modulo al cual intenta acceder}
     * @return
     */
    return axios.post("api/modulos/checkPermiso/", data);
  }

  requestAccess(data) {
    /**
     * @params {data : nombre del modulo al cual solicita acceso}
     * @return
     */
    return axios.post("api/modulos/enviar-mail/", data);
  }
}

export default new UsuarioService();
