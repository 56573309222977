import {
    Box,
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import InputField from "components/Layout/inputCustom/inputField";
import NewAutocomplete from "components/inputs/newAutocomplete";
import rules from "constants/rules";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import proveedoresService from "services/proveedores.service";
import Theme from "theme";
import { useAllProvidersMaterialsNoPaginated,  useGetProvidersMaterialsTypes } from "../../hook";

const CrearEditarMaterialesModal = ({
    materialSeleccionado,
    onClose,
    open,
    onSuccess,
    onError,
}) => { 
    
    //  HOOK FORM
    const { handleSubmit, control, setValue, watch } = useForm({
        defaultValues :{
            nombre: materialSeleccionado?.nombre_material ?? "",
            material_id: materialSeleccionado?.material_id ?? "",
            tipo_material: materialSeleccionado?.tipo_material ?? "",
            tipo_material_id: materialSeleccionado?.tipo_material_id ?? ""
        }
    })
  
    // QUERY PARA OBTENER LISTADO DE MATERIALES
    const {
        data: materialsList,
        isLoading: loadingProvidersList,
        isFetching: fechingMaterials,
    } = useAllProvidersMaterialsNoPaginated({
    });

    // QUERY PARA OBTENER LISTADO DE TIPOS DE MATERIALES
    const {
        data: typesList,
        isLoading: loadingTypes,
    } = useGetProvidersMaterialsTypes({idMaterial: watch("material_id")
    });

    // FUNCION CREAR EDITAR MATERIAL
    const onSubmit = async ({nombre, tipo_material, tipo_material_id, material_id}) => {
        let endpoint = "";
        let type = "";
        const dataSend = {
            nombre,
            tipo_material
        }
        if (materialSeleccionado?.material_id) {
            type = "Edición";
            endpoint = proveedoresService?.editProviderMaterial(material_id, {...dataSend, tipo_mat_id : tipo_material_id } );
        } else {
            type = "Creación";
            let material_id = (materialsList.filter((item) => item.nombre === nombre,)[0]?.id)
            endpoint = proveedoresService?.createProviderMaterial({...dataSend, material_id } );
        }
        try {
            const res = await endpoint;
            if (res.status > 199 && res.status < 299) {
                onSuccess(`${type} de Material correctamente`);
            } else {
                onError(`Error en la ${type}`);
            }
            onClose();
        } catch (error) {
            onError(error?.response?.data?.message ?? "Error en el servidor");
            onClose();
        }
    }

    const handleInputChange = (e, data) => {
        return data;
    };

    return (
        <Modal
            onClose={() => {
                onClose();
            }}
            open={open}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "ffffff",
                        borderRadius: 10,
                        boxShadow: 20,
                    }}
                >
                    {/* Titulo de la vista */}
                    <Grid
                        container
                        direction="row"
                        justifyContent={"center"}
                        alignItems="center"
                        alignContent="center"
                        style={{
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            backgroundColor: "#001176",
                            minHeight: "2.5rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                        }}
                    >
                        <Typography
                            variant="h5"
                            style={{ height: "2rem", color: "white", textAlign: "center" }}
                        >
                            {`${materialSeleccionado?.id ? `Editar` : `Nuevo`} Material`}
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        style={{
                            backgroundColor: "#ffffff",
                            padding: "2rem",
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                        }}
                    >
                        <Grid container item direction="row" justifyContent="space-between">
                            <NewAutocomplete
                                control={control}
                                rules={rules.generic}
                                name="nombre"
                                label="Nombre:*"
                                placeholder="Ingrese nombre"
                                size="small"
                                freeSolo
                                autoSelect
                                style={{
                                    width: "100%",
                                }}
                                options={materialsList?.map((item) => {return item.nombre})}
                                loading={loadingProvidersList || fechingMaterials}
                                onInputChange={(e, data) => handleInputChange(e, data)}
                                defaultValue={""}
                            />
                        </Grid>
                        <Grid container style={{ marginTop: "1.5rem" }}>
                            <InputField
                                name="tipo_material"
                                placeholder="Ingrese tipo de material"
                                control={control}
                                rules={rules.generic}
                                label="Tipo de Material:*"
                                type="text"
                                InputLabelProps={{ shrink: true }}

                            />
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                style={{ marginTop: "1rem" }}
                                justifyContent="flex-end"
                            >
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        style={{
                                            backgroundColor: "#ffffff",
                                            color: Theme.palette.primary.main,
                                            borderColor: Theme.palette.primary.main,
                                            borderWidth: "2px",
                                        }}
                                        onClick={() => {
                                            onClose();
                                        }}
                                    >
                                        Volver
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        type="submit"
                                    >
                                        Confirmar
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Modal>
    );
};

export default CrearEditarMaterialesModal;

CrearEditarMaterialesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    materialSeleccionado: PropTypes.object,
};
