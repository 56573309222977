/* eslint-disable react-hooks/exhaustive-deps */
// REACT
// ICONS
// COMPONENTS
// SERVICES
// VARIOS

import {
  Button,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Card from "components/card";
import moment from "moment";
import { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Theme from "theme";
import SearchBar from "components/Layout/searchBar/SearchBar";
import mostrarErrorEP from "constants/funciones/mostrarError";
import facturacionServices from "services/facturacion.services";
import { useParams } from "react-router-dom";
import ordenesService from "services/ordenes.service";
import { useEffect } from "react";

const AsociarOrden = ({
  open,
  close,
  setSnackState,
  setConfirmDialog,
  dataAsociar,
  setRecargar,
  presupuesto,
}) => {
  const { cantidadFormulas, cliente } = dataAsociar;
  const { id } = useParams();

  const [ordenes, setOrdenes] = useState([]);

  const listarOrdenesCliente = async () => {
    try {
      const { data, status } = presupuesto
        ? await ordenesService.listarOrdenesNegroCliente(
            cliente?.cliente_id,
            filtro,
          )
        : await ordenesService.listarOrdenesCliente(
            cliente?.cliente_id,
            filtro,
          );

      if (status > 199 && status < 300) {
        setOrdenes(data);
      }
    } catch (error) {
      return error;
    }
  };

  // ESTADOS PARA MANEJAR LA SELECCION DE FECHAS
  const [minDate, setMinDate] = useState(moment().format("YYYY-MM-01"));
  const [maxDate, setMaxDate] = useState(moment().format("YYYY-MM-DD"));

  // ESTADO QUE CONTIENE TODOS LOS FILTROS A USARSE
  const [filtro, setFiltro] = useState({
    fecha_desde: minDate,
    fecha_hasta: maxDate,
    search: "",
    ordFecha: "",
  });

  // ESTADO PARA MANEJAR EL ORDEN DE LAS COLUMNAS
  const [asc, setAsc] = useState(false);

  // FUNCION ENCARGADA DE SETEAR EL FILTRO PARA ORDENAR LA TABLA SEGUN LAS OPCIONES ELEGIDAS
  const ordenar = () => {
    // Variables para ordenar columnas
    let orden = asc ? "asc" : "desc";

    setFiltro({
      ...filtro,
      ordFecha: orden,
    });

    setAsc(!asc);
  };

  // FUNCION DE BUSCADOR
  const handleBusqueda = (text) => {
    if (text.length > 0) {
      setFiltro({ ...filtro, search: text });
    } else {
      setFiltro({ ...filtro, search: "" });
    }
  };

  useEffect(() => {
    if (open) {
      listarOrdenesCliente();
    }
  }, [open, filtro]);

  const asociarOrdenCompra = (idOrden) => {
    const dataSend = { orden_compra: idOrden, formulas: cantidadFormulas() };

    setConfirmDialog({
      isOpen: true,
      title: presupuesto
        ? "Asignar Liquidación a Orden de Compra"
        : "Asignar Resumen a Orden de Compra",
      onConfirm: async () => {
        try {
          const res = presupuesto
            ? await facturacionServices.asociarLiquidacionOC(id, dataSend)
            : await facturacionServices.asociarResumenOC(id, dataSend);

          if (res.status > 199 && res.status < 300) {
            setSnackState({
              open: true,
              severity: "success",
              message: presupuesto
                ? "Liquidación Asociada Exitosamente"
                : "Orden Asociada Exitosamente",
            });
            setRecargar(true);
            setTimeout(() => {
              handleClose();
            }, [2000]);
          }
        } catch (error) {
          mostrarErrorEP(error, setSnackState);
          return error;
        }
      },
    });
  };

  const handleClose = () => {
    close(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid
        container
        item
        xl={5}
        lg={5}
        md={6}
        sm={8}
        xs={10}
        style={{
          backgroundColor: "#ffffff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
        }}
      >
        <Card title="Asignar Resumen a Orden de Compra" justifyContent="center">
          <Grid container justifyContent="center" style={{ padding: "1rem" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Para asignar el resumen a una orden de compra,
            </Typography>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              busque por número de orden de compra o seleccione de la lista.
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", padding: "0.5rem 0.5rem 0.5rem 0" }}
            >
              Buscar por Nº de Orden de Compra:
            </Typography>

            <Grid item xl={4} lg={4} md={6} sm={8} xs={10}>
              <SearchBar
                placeholder="Buscar por Nº"
                onChange={(e) => handleBusqueda(e.target.value)}
              />
            </Grid>

            <Grid
              style={{ margin: "0.5rem 0" }}
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <TextField
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  label="Fecha Desde"
                  focused
                  defaultValue={minDate && minDate}
                  onChange={(e) =>
                    setMinDate(moment(e.target.value).format("YYYY-MM-DD"))
                  }
                  inputProps={{
                    max: maxDate,
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  label="Fecha Hasta"
                  focused
                  defaultValue={maxDate && maxDate}
                  onChange={(e) =>
                    setMaxDate(moment(e.target.value).format("YYYY-MM-DD"))
                  }
                  inputProps={{
                    min: minDate,
                    max: moment().format("YYYY-MM-DD"),
                  }}
                />
              </Grid>
              <Grid item style={{ marginRight: "0.5rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    maxHeight: "2rem",
                  }}
                  onClick={() => {
                    setFiltro({
                      ...filtro,
                      fecha_desde: minDate,
                      fecha_hasta: maxDate,
                    });
                  }}
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>

            {ordenes?.length > 0 ? (
              <TableContainer style={{ maxHeight: "18rem" }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: 0, fontWeight: "bold" }}
                      >
                        Nº de Orden de Compra
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: 0, fontWeight: "bold" }}
                      >
                        Fecha
                        <IconButton size="small" onClick={() => ordenar()}>
                          <CompareArrowsIcon
                            style={{
                              width: "1rem",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: 0, fontWeight: "bold" }}
                      >
                        Asignar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordenes?.map((orden) => (
                      <TableRow key={orden?.id}>
                        <TableCell style={{ borderBottom: 0 }}>
                          {orden?.num_orden ?? orden?.id ?? "-"}
                        </TableCell>
                        <TableCell style={{ borderBottom: 0 }}>
                          {orden?.fecha_carga}
                        </TableCell>
                        <TableCell style={{ borderBottom: 0 }}>
                          <IconButton
                            size="small"
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginRight: "0.5rem",
                              borderRadius: 5,
                              backgroundColor: Theme.palette.primary.main,
                              color: "#ffffff",
                            }}
                            onClick={() =>
                              window.open(
                                presupuesto
                                  ? `/ordenes/ver-orden-negro/${orden?.id}/`
                                  : `/ordenes/ver-orden/${orden?.id}/`,
                              )
                            }
                          >
                            <VisibilityIcon
                              style={{
                                height: "1.2rem",
                                width: "1.2rem",
                              }}
                            />
                          </IconButton>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            children="Asignar OC"
                            startIcon={<CheckIcon />}
                            style={{
                              marginRight: "1rem",
                              height: "1.5rem",
                              textTransform: "none",
                            }}
                            onClick={() => asociarOrdenCompra(orden?.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "3rem",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                No Se Encontraron Ordenes de Compra
              </Grid>
            )}

            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "1rem" }}
            >
              <Button
                variant="text"
                color="primary"
                size="small"
                children="Cancelar"
                onClick={() => handleClose()}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Modal>
  );
};

export default AsociarOrden;
