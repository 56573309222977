import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const TablaCtaCte = ({ classes, clientes, isNotificationsEnabled, Theme }) => {
  const history = useHistory();
  const verResumenCtaCte = (cliente) => {
    history.push(`/cuenta-corriente/${cliente?.id}/facturas`, { cliente });
  };

  const verEstadoCtaCte = (cliente) => {
    history.push(`/clientes/estado-cuenta`, { cliente });
  };

  return (
    <Grid
      container
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classes.generalContainer}
    >
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          style={{ width: "90%", margin: "0 auto" }}
          size="small"
        >
          <TableHead>
            <TableRow style={{ height: "3rem" }}>
              <TableCell align="left" width="50%">
                Cliente
              </TableCell>
              <TableCell align="center" width="50%">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientes?.length > 0 ? (
              clientes?.map((cliente) =>
                cliente?.notificacion === !isNotificationsEnabled ? (
                  <TableRow
                    hover
                    key={cliente?.id}
                    style={{
                      height: "3rem",
                      backgroundColor:
                        cliente?.notificacion === true ? "#E26E6E" : "",
                    }}
                  >
                    <TableCell
                      style={{ textTransform: "capitalize" }}
                      width="50%"
                    >
                      {cliente?.nombre?.toUpperCase()}
                    </TableCell>
                    <TableCell align="center" width="50%">
                      <Grid container spacing={1} justifyContent="center">
                        <Grid item>
                          <Button
                            size="small"
                            onClick={() => verEstadoCtaCte(cliente)}
                            style={{
                              textTransform: "capitalize",
                              width: "12rem",
                              height: "2rem",
                              borderRadius: 5,
                              backgroundColor: Theme.palette.primary.main,
                              color: "#ffffff",
                            }}
                          >
                            Estado de Cuenta
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            onClick={() => verResumenCtaCte(cliente)}
                            style={{
                              textTransform: "capitalize",
                              width: "12rem",
                              height: "2rem",
                              borderRadius: 5,
                              backgroundColor: Theme.palette.primary.main,
                              color: "#ffffff",
                            }}
                          >
                            Ver Comprobante
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : (
                  cliente?.notificacion === false && (
                    <TableRow
                      hover
                      key={cliente?.id}
                      style={{
                        height: "3rem",
                      }}
                    >
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {cliente?.nombre}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Button
                          onClick={() => verResumenCtaCte(cliente)}
                          style={{
                            textTransform: "capitalize",
                            width: "12rem",
                            height: "2rem",
                            borderRadius: 5,
                            backgroundColor: Theme.palette.primary.main,
                            color: "#ffffff",
                          }}
                        >
                          Ver Comprobantes
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                ),
              )
            ) : (
              <Grid item style={{ margin: "0.5rem" }}>
                <Typography>No se encontraron clientes</Typography>
              </Grid>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
export default TablaCtaCte;
