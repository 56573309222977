import {
  Grid,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Create, Delete } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import StyledIconButton from "components/StyledIconButton";
import { useHistory } from "react-router-dom";
import ordenesService from "services/ordenes.service";
import mostrarErrorEP from "constants/funciones/mostrarError";
import { Link as LinkRouter } from "react-router-dom";

export default function TableRowOrdenes({
  item,
  setConfirmDialog,
  setSnackState,
  resetSearch,
}) {
  const tableCellStyle = {
    fontWeight: item?.condicion === "Pagado" && item?.presupuesto && "bold",
  };

  const history = useHistory();

  const handleClickResumen = (resumen) => {
    history.push(
      item?.presupuesto
        ? `/remitos/remitos-por-cliente/resumen-liquidacion/${resumen}`
        : `/remitos/remitos-por-cliente/resumen/${resumen}`,
    );
  };

  const handleClickEliminar = () => {
    setConfirmDialog({
      title: `ELIMINAR ORDEN Nº ${item?.num_orden ?? item?.id}`,
      isOpen: true,
      onConfirm: () => {
        eliminarOrden();
      },
    });
  };

  const handleClickEditar = () => {
    history.push(`/ordenes/editar-orden/${item?.id}/`, {
      presupuesto: item?.presupuesto,
    });
  };

  const handleClickCargarRemito = (datosOrden) => {
    if (item?.anticipada) {
      history.push(`/remitos/crear-remito-anticipado`, { datosOrden });
    } else {
      history.push("/remitos/crear-remito", {
        remito: {
          cliente: {
            id: datosOrden?.cliente_id,
            nombre: datosOrden?.cliente,
            cuil_dni: datosOrden?.cliente_cuil_dni,
          },
          num_orden: datosOrden?.num_orden,
          orden_id: datosOrden?.id,
          resumen_asociado: datosOrden?.resumen_asociado,
        },
        edicion: false,
      });
    }
  };

  const handleClickFacturar = () => {
    history.push({
      pathname: `/facturacion/crear-factura-orden/`,
      state: item,
    });
  };

  const validarFacturarOrden = () => {
    if (item?.condicion === "A Cobrar") {
      return {
        disabled: true,
        tooltip: `No se puede generar ${
          item?.presupuesto ? "recibo" : "factura"
        } porque esta orden tiene condición "A Cobrar"`,
      };
    }

    if (item?.num_factura_orden) {
      return {
        disabled: true,
        tooltip: `No se puede generar ${
          item?.presupuesto ? "recibo" : "factura"
        } porque esta orden ya está facturada`,
      };
    }

    if (!item?.tiene_forma_pago) {
      return {
        disabled: true,
        tooltip: `No se puede generar ${
          item?.presupuesto ? "recibo" : "factura"
        } porque esta orden no tiene formas de pago`,
      };
    }

    if (item?.num_facturas_resumenes?.length > 0) {
      return {
        disabled: true,
        tooltip: `No se puede generar ${
          item?.presupuesto ? "recibo" : "factura"
        } porque esta orden tiene resumenes facturados`,
      };
    }

    return {
      disabled: false,
      tooltip: `Facturar Orden Nº${item?.num_orden ?? item?.id}`,
    };
  };

  const eliminarOrden = async () => {
    try {
      const { status } = item?.presupuesto
        ? await ordenesService.eliminarOrdenNegro(item?.id)
        : await ordenesService.eliminarOrden(item?.id);

      if (status > 199 && status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Orden Eliminada Exitosamente",
        });
        resetSearch();
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };

  return (
    <TableRow
      hover
      style={{
        backgroundColor:
          (item?.condicion === "Pagado" && "#43a047") ||
          (item?.presupuesto && "#E26E6E"),
      }}
    >
      <TableCell style={tableCellStyle}>{item?.cliente ?? "-"}</TableCell>
      <TableCell style={tableCellStyle}>
        {item?.num_orden ?? item?.id ?? "-"}
      </TableCell>
      <TableCell style={tableCellStyle}>{item?.fecha_carga ?? "-"}</TableCell>
      <TableCell align="center" style={tableCellStyle}>
        {item?.anticipada && <CheckOutlinedIcon color="primary" />}
      </TableCell>
      <TableCell>
        {item?.resumen_asociado?.length > 0
          ? item?.resumen_asociado?.map((resumen) => (
              <Tooltip
                key={resumen}
                title={
                  <Typography variant="body2">
                    {`Ver Resumen Nº${resumen}`}
                  </Typography>
                }
                placement="top"
                arrow
              >
                <Link
                  component="button"
                  underline="none"
                  variant="body1"
                  onClick={() => handleClickResumen(resumen)}
                  style={{
                    fontWeight: "bold",
                    color: "#1C3AA9",
                    marginRight: "0.5rem",
                  }}
                >
                  {resumen}
                </Link>
              </Tooltip>
            ))
          : "-"}
      </TableCell>
      <TableCell>{item?.num_factura_orden ?? "-"}</TableCell>
      <TableCell style={tableCellStyle}>{item?.condicion ?? "-"}</TableCell>
      <TableCell>
        <Grid container style={{ width: "10rem" }}>
          <LinkRouter
            to={
              item?.presupuesto
                ? `/ordenes/ver-orden-negro/${item?.id}/`
                : `/ordenes/ver-orden/${item?.id}/`
            }
          >
            <StyledIconButton size="small">
              <Tooltip
                title={
                  <Typography variant="body2">
                    {`Visualizar Orden Nº${item?.num_orden ?? item?.id}`}
                  </Typography>
                }
                placement="top"
                arrow
              >
                <VisibilityIcon style={{ height: "1.2rem", width: "1.2rem" }} />
              </Tooltip>
            </StyledIconButton>
          </LinkRouter>

          <StyledIconButton
            size="small"
            disabled={item?.condicion === "Pagado"}
            onClick={handleClickEliminar}
          >
            <Tooltip
              title={
                <Typography variant="body2" align="center">
                  {item?.condicion === "Pagado"
                    ? `No se puede eliminar esta orden porque tiene condición "Pagado"`
                    : `Eliminar Orden Nº${item?.num_orden ?? item?.id}`}
                </Typography>
              }
              placement="top"
              arrow
            >
              <Delete style={{ height: "1.2rem", width: "1.2rem" }} />
            </Tooltip>
          </StyledIconButton>

          <StyledIconButton
            size="small"
            disabled={item?.condicion === "Pagado"}
            onClick={handleClickEditar}
          >
            <Tooltip
              title={
                <Typography variant="body2" align="center">
                  {item?.condicion === "Pagado"
                    ? `No se puede editar esta orden porque tiene condición "Pagado"`
                    : `Editar Orden Nº${item?.num_orden ?? item?.id}`}
                </Typography>
              }
              placement="top"
              arrow
            >
              <Create style={{ height: "1.2rem", width: "1.2rem" }} />
            </Tooltip>
          </StyledIconButton>

          <StyledIconButton
            size="small"
            disabled={validarFacturarOrden().disabled}
            onClick={handleClickFacturar}
          >
            <Tooltip
              title={
                <Typography variant="body2" align="center">
                  {validarFacturarOrden()?.tooltip}
                </Typography>
              }
              placement="top"
              arrow
            >
              <MonetizationOnIcon
                style={{ height: "1.2rem", width: "1.2rem" }}
              />
            </Tooltip>
          </StyledIconButton>
          <StyledIconButton
            size="small"
            onClick={() => {
              handleClickCargarRemito(item);
            }}
          >
            <Tooltip
              title={
                <Typography variant="body2" align="center">
                  {item?.anticipada
                    ? `Cargar Remito a Orden de Compra Anticipada`
                    : `Cargar Remito a Orden de Compra`}
                </Typography>
              }
              placement="top"
              arrow
            >
              <PostAddOutlinedIcon
                style={{ height: "1.2rem", width: "1.2rem" }}
              />
            </Tooltip>
          </StyledIconButton>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
