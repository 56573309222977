import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

export default function ControlledTextfield({
  name,
  defaultValue,
  control,
  type,
  onChange,
  rules,
  ...otherProps
}) {
  const NaNToNull = (value) => {
    if (isNaN(value)) return null;

    return value;
  };

  const handleChange = (event, onChangeForm) => {
    const { value, valueAsNumber } = event?.target;
    const newValue = type === "number" ? NaNToNull(valueAsNumber) : value;

    onChangeForm(newValue);

    if (onChange) {
      onChange(event, newValue);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange: onChangeForm, value },
        fieldState: { error },
      }) => (
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          inputMode={type === "number" ? "numeric" : undefined}
          type={type ?? "text"}
          id={name}
          name={name}
          error={!!error}
          value={value ?? ""}
          helperText={error ? error.message : null}
          onChange={(event) => handleChange(event, onChangeForm)}
          {...otherProps}
        />
      )}
    />
  );
}
