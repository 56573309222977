// MATERIAL UI
import {Grid, Typography} from "@material-ui/core";

const Card = ({title, justifyContent, children}) => {
  return (
    <Grid container>
      <Grid
        container
        direction="row"
        justifyContent={justifyContent}
        alignItems="center"
        alignContent="center"
        style={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: "#001176",
          minHeight: "2.5rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <Typography
          variant="h5"
          style={{height: "2rem", color: "white", textAlign: "center"}}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: "#ffffffff",
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          // marginBottom: "4rem"
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default Card;
