import React, { useContext } from "react";
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import Create from "@material-ui/icons/Create";
import useStyles from "./styles";
import Theme from "theme";
import { Delete } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { NotificationContext } from "context/notification/NotificationContext";

const TableCustom = ({
  clientes,
  notification,
  borrarCliente,
  verCliente,
  editarCliente,
  setConfirmDialog,
  history,
}) => {
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                zIndex: 1000,
                textAlign: "left",
                whiteSpace: "nowrap",
                position: "sticky",
                left: 0,
                borderRight: "1px solid grey",
              }}
            >
              Nombre
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              Dirección
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              Ciudad
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              CUIL - CUIT
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              Cond. IVA
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              Teléfono
            </TableCell>
            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              Email
            </TableCell>

            <TableCell
              style={{
                textAlign: "left",
                whiteSpace: "nowrap",
                paddingLeft: "2.5rem",
              }}
            >
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientes?.length > 0 &&
            clientes.map((cliente) =>
              //check notification enable
              cliente.notificacion === !isNotificationsEnabled ? (
                <TableRow
                  hover
                  key={cliente.id}
                  style={{
                    backgroundColor:
                      isNotificationsEnabled === false
                        ? clientes?.filter(
                            (element) => element.notificacion === true,
                          ).length
                          ? "#E26E6E"
                          : ""
                        : null,
                  }}
                >
                  <TableCell
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      position: "sticky",
                      left: 0,
                      backgroundColor:
                        isNotificationsEnabled === false
                          ? clientes?.filter(
                              (element) => element.notificacion === true,
                            ).length
                            ? "#E26E6E"
                            : ""
                          : "#FFFFFF",
                      borderRight: "1px solid grey",
                    }}
                  >
                    {cliente.nombre ? cliente.nombre.toUpperCase() : "-"}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    {cliente.direccion ? cliente.direccion.toUpperCase() : "-"}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    {cliente.provincia ? cliente.provincia.toUpperCase() : "-"}{" "}
                    {cliente.ciudad ? cliente.ciudad.toUpperCase() : "-"}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    {cliente.cuil_dni ? cliente.cuil_dni : "-"}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    {cliente.condicion_iva
                      ? cliente.condicion_iva.toUpperCase()
                      : "-"}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    {cliente.num_telefono ? cliente.num_telefono : "-"}
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    {cliente.email ? cliente.email : "-"}
                  </TableCell>

                  <TableCell
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    <IconButton
                      onClick={() => verCliente(cliente)}
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginLeft: "0.5rem",
                        borderRadius: 5,
                        backgroundColor: Theme.palette.primary.main,
                        color: "#ffffff",
                      }}
                    >
                      <VisibilityIcon
                        style={{ height: "1.2rem", width: "1.2rem" }}
                      />
                    </IconButton>

                    <IconButton
                      onClick={() => editarCliente(cliente)}
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginLeft: "0.5rem",
                        borderRadius: 5,
                        backgroundColor: Theme.palette.primary.main,
                        color: "#ffffff",
                      }}
                    >
                      <Create style={{ height: "1.2rem", width: "1.2rem" }} />
                    </IconButton>

                    <IconButton
                      onClick={() =>
                        setConfirmDialog({
                          title: `¿Está seguro de eliminar al cliente ${cliente.nombre.toUpperCase()}?`,
                          isOpen: true,
                          onConfirm: () => {
                            borrarCliente(cliente.id);
                          },
                        })
                      }
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginLeft: "0.5rem",

                        borderRadius: 5,
                        backgroundColor: Theme.palette.primary.main,
                        color: "#ffffff",
                      }}
                    >
                      <Delete style={{ height: "1.2rem", width: "1.2rem" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ) : (
                //check notification disable users sin notification
                cliente.notificacion === false && (
                  <TableRow
                    hover
                    key={cliente.id}
                    style={{
                      backgroundColor:
                        isNotificationsEnabled === false
                          ? notification?.filter(
                              (element) => element.id === cliente.id,
                            ).length
                            ? "#E26E6E"
                            : ""
                          : null,
                    }}
                  >
                    <TableCell
                      style={{
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        position: "sticky",
                        left: 0,
                        backgroundColor: "#ffffff",
                        borderRight: "1px solid grey",
                      }}
                    >
                      {cliente.nombre ? cliente.nombre.toUpperCase() : "-"}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      {cliente.direccion
                        ? cliente.direccion.toUpperCase()
                        : "-"}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      {cliente.provincia
                        ? cliente.provincia.toUpperCase()
                        : "-"}{" "}
                      {cliente.ciudad ? cliente.ciudad.toUpperCase() : "-"}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      {cliente.cuil_dni ? cliente.cuil_dni : "-"}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      {cliente.condicion_iva
                        ? cliente.condicion_iva.toUpperCase()
                        : "-"}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      {cliente.num_telefono ? cliente.num_telefono : "-"}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      {cliente.email ? cliente.email : "-"}
                    </TableCell>

                    <TableCell
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      <IconButton
                        onClick={() => verCliente(cliente)}
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          marginLeft: "0.5rem",
                          borderRadius: 5,
                          backgroundColor: Theme.palette.primary.main,
                          color: "#ffffff",
                        }}
                      >
                        <VisibilityIcon
                          style={{ height: "1.2rem", width: "1.2rem" }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => editarCliente(cliente)}
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          marginLeft: "0.5rem",
                          borderRadius: 5,
                          backgroundColor: Theme.palette.primary.main,
                          color: "#ffffff",
                        }}
                      >
                        <Create style={{ height: "1.2rem", width: "1.2rem" }} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          setConfirmDialog({
                            title: `¿Está seguro de eliminar al cliente ${cliente.nombre}?`,
                            isOpen: true,
                            onConfirm: () => {
                              borrarCliente(cliente.id);
                            },
                          })
                        }
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          marginLeft: "0.5rem",

                          borderRadius: 5,
                          backgroundColor: Theme.palette.primary.main,
                          color: "#ffffff",
                        }}
                      >
                        <Delete style={{ height: "1.2rem", width: "1.2rem" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              ),
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCustom;
