export const namesCtaCte = {
  modulo: "cuenta-corriente",
  tipo: "tipo",
  page: "page",
  search: "search",
  fechaDesde: "fecha_desde",
  fechaHasta: "fecha_hasta",
  filtrarPor: "filtrar_por",
  dias: "dias",
  ordFecha: "ordfechem",
  factura: "num_factura",
  orden: "orden_compra",
  resumen: "resumen",
  montoFactura: "total",
  pagado: "pagado",
  saldo: "saldo",
  acumulado: "acumulado",
  presupuesto: "presupuesto",
  fechaEmision: "fecha_emision",
  fechaVto: "fecha_venc_pago",
  subItems: "formas_pago",
  fechaMedioPago: "fecha_pago",
  montoMedioPago: "monto",
  formaPago: "forma_pago",
  chequeMedioPago: "nro_cheque",
  fechaVtoMedioPago: "fecha_vencimiento",
  observacionMedioPago: "observacion",
  clienteNombre: "nombre",
  clienteCuil: "cuil_dni",
  clienteDireccion: "direccion",
  clienteTelefono: "num_telefono",
  num_comprobante: "num_comprobante",
  tipoComprobante: "tipo",
};
