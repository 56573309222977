import StyledTableCell from "components/styled/tableCell";
import StyledTableRow from "components/styled/tableRow";
import useStyles from "../../cuenta-corriente/styles";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import TableGeneric from "components/tableGeneric/tableGeneric";
export default function TableRowEstadoCuentas({ item, control }) {
  const styles = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { num_orden, fecha_carga, anticipada, resumenes_asociados, detalle_orden } = item;
  const headers = [
    { name: "Producto/Servicios" },
    { name: "Cantidad" },
    { name: "Restante" },
    { name: "Precio Original" },
    { name: "Precio Actual" },
    { name: "Fecha Vigente" },
  ];

  return (
    <>
      <StyledTableRow style={styles?.tableGeneric?.tableRowBody(item)} hover>
        <StyledTableCell>
        <IconButton
            size="small"
            onClick={() => setOpen(!open)}
            style={{ marginRight: "1rem" }}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
          {num_orden ?? "-"}
        </StyledTableCell>
        <StyledTableCell >
          {fecha_carga ?? "-"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {anticipada && <CheckOutlinedIcon color="primary" />}          
        </StyledTableCell>
        <StyledTableCell>
          {resumenes_asociados?.map((unResumen, indice) => {
            return (indice === 0) ?
            <Link
            style={{
              fontWeight: 700,
              fontSize: "17px",
              color:"#1C3AA9",
              cursor: "pointer",
              textDecoration: "none",
            }}
          onClick={() =>
            history.push(
              `/remitos/remitos-por-cliente/resumen/${unResumen?.nro_resumen}/`,
            )
          }
          >
            {`${unResumen?.nro_resumen} `}
          </Link> :
            <Link
            style={{
              fontWeight: 700,
              fontSize: "17px",
              color: "#1C3AA9",
              cursor: "pointer",
              textDecoration: "none",
            }}
            onClick={() =>
              history.push(
                `/remitos/remitos-por-cliente/resumen/${unResumen?.nro_resumen}/`,
              )
            }
          >
            {`/ ${unResumen?.nro_resumen}`}
          </Link>         
          })}
        </StyledTableCell>
      </StyledTableRow>

      {/* Collapse Row */}
      <StyledTableRow>
        <StyledTableCell style={{ padding: "0" }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container style={{ padding: "0.5rem 2rem" }}>
              <Typography style={{ fontWeight: "bold" }}>
                DETALLE DE ORDEN
              </Typography>

              {detalle_orden?.length > 0 ? (
                <TableGeneric
                  size="small"
                  item={item}
                  headers={headers}
                  component={Paper}
                  styles={styles?.tableCollapse}
                >
                  {detalle_orden?.map(
                    (subItem, indexSubitem) => (
                      <StyledTableRow
                        key={indexSubitem}
                        hover
                        style={styles?.tableCollapse?.tableRowBody(subItem)}
                      >
                        <StyledTableCell>
                          {subItem?.formula__nombre
                            ?? "-"}
                        </StyledTableCell>

                        <StyledTableCell>
                        {subItem?.cantidad_m3
                            ?? "-"}
                        </StyledTableCell>

                        <StyledTableCell>
                        {subItem?.cant_restante
                            ?? "-"}
                        </StyledTableCell>

                        <StyledTableCell>
                        {subItem?.precio_original
                            ?? "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                        {subItem?.precio_actual
                            ?? "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                        {subItem?.fecha_vigencia_precio
                            ?? "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                    ),
                  )}
                </TableGeneric>
              ) : (
                <Grid container justifyContent="center">
                  <Typography variant="subtitle1">
                    No se encontraron resultados
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
