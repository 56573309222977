/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

// MATERIAL UI
import { Grid } from "@material-ui/core";

// COMPONENTS
import VerificarAcceso from "components/verificarAcceso";
import Card from "components/card";
import ConfirmDialog from "components/ConfirmDialog";
import CustomSnackbar from "components/Layout/CustomSnackbars";

// VARIOS
import { LogIn } from "context/LogInContext";
import Protected from "protected";
import AgregarArchivo from "./agregarArchivo";
import RootContainer from "layout/rootContainer";
import ordenesService from "services/ordenes.service";
import { useQuery } from "react-query";
import LoadingText from "components/loadingText";
import FormCrearFacturaOrden from "screens/facturacion/components/formCrearFacturaOrden";

export default function CrearFacturaOrden() {
  const history = useHistory();
  const { state } = history?.location;
  const { logged } = useContext(LogIn);

  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);
  const [modalAgregarArchivo, setModalAgregarArchivo] = useState(false);
  const [archivoSeleccionado, setArchivoSeleccionado] = useState(null);
  const [URLArchivo, setURLArchivo] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  const formatOrden = (data) => {
    data?.detalle_orden?.forEach((item) => {
      item.bonif = 0;
      item.iva = 21;
      item.cant_metros = item?.cantidad_m3;
      item.tipo = item?.concepto ?? item?.formula__nombre;
      item.precio_unitario = item?.precio_actual;
      item.subtotal = Number(
        (item?.cant_metros * item.precio_unitario).toFixed(2),
      );
      item.importe = item?.subtotal;
    });

    return data;
  };

  const getOrden = async () => {
    const { data } = state?.presupuesto
      ? await ordenesService.verOrdenNegro(state?.id)
      : await ordenesService.verOrden(state?.id);

    return formatOrden(data);
  };

  const { data: orden, isLoading: loadingOrden } = useQuery(
    ["getOrden", state?.id],
    getOrden,
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="facturacion"
            history={history}
            logged={logged}
          />
        ) : (
          acceso && (
            <Grid
              container
              style={{
                padding: "1rem 0 5rem 2rem",
                width: "95%",
              }}
            >
              <Card
                title={`Crear ${state?.presupuesto ? "Recibo" : "Factura"}`}
                justifyContent="center"
              >
                {loadingOrden ? (
                  <LoadingText title="Cargando..." />
                ) : (
                  <FormCrearFacturaOrden
                    orden={orden}
                    state={state}
                    URLArchivo={URLArchivo}
                    archivoSeleccionado={archivoSeleccionado}
                    setModalAgregarArchivo={setModalAgregarArchivo}
                    setSnackState={setSnackState}
                  />
                )}
              </Card>
              <AgregarArchivo
                open={modalAgregarArchivo}
                handleClose={setModalAgregarArchivo}
                setArchivoSeleccionado={setArchivoSeleccionado}
                setURLArchivo={setURLArchivo}
                setSnackState={setSnackState}
              />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              <CustomSnackbar
                message={snackState.message}
                open={snackState.open}
                severity={snackState.severity}
                autoHideDuration={snackState.autoHideDuration}
                onClose={onClose}
              />
            </Grid>
          )
        )}
      </RootContainer>
    </Protected>
  );
}
