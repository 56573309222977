import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  container: {
    backgroundColor: "#000838",
    flexShrink: 0,
    bottom: 0,
    position: "fixed",
    zIndex: 5,
  },
  footerComponent: {
    padding: "0.5rem",
    alignItems: "center",
  },
  footerText: {
    color: "#ffffff",
  },
  copyrightText: {
    fontSize: "0.8rem",
  },
  softwareName: {
    fontSize: "0.9rem",
  },
}));
