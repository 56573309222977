import { makeStyles } from "@material-ui/core/styles";
import Theme from "theme";

const useStyles = makeStyles({
  textFieldContainer: {
    marginTop: "5rem",
    marginBottom: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  resumenHeaderItem: {
    fontWeight: 400,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
  },
  resumenHeaderItemTitle: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    border: "none",
  },

  generalContainer: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },

  headerTable: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    minHeight: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  headerTableTittle: {
    height: "2rem",
    color: "white",
    textAlign: "center",
  },

  tableContainer: {
    margin: "0 auto",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, .2)",
    width: "100%",
    maxHeight: "53vh",
    overflow: "auto",
  },
  tableRow: {
    padding: "0 5rem",
  },
  tableCellHeaders: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    minWidth: "7rem",
    padding: 0,
  },

  tableCell: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "left",
    padding: "0 0.5rem ",
  },
  totalRow: {
    backgroundColor: "#CCCFE4",
  },
  totalCell: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "186.69%",

    color: "rgba(67, 59, 59, 0.87)",
  },
  button2: {
    background: "FFF",
    color: "#001176",
    textTransform: "capitalize",
    marginLeft: "1rem",
    border: "2px solid #001176",
    maxHeight: "2rem",
  },
  tableCell2: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
  },
  tablecell3: {
    fontSize: "16px",
    whiteSpace: "nowrap",
  },
  iconButton: {
    width: "1.5rem",
    height: "1.5rem",
    marginLeft: "0.5rem",
    borderRadius: 5,
    backgroundColor: Theme.palette.primary.main,
    color: "#ffffff",
  },
  icon: {
    height: "1.2rem",
    width: "1.2rem",
  },
});
export default useStyles;
