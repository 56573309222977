import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({

  textFieldContainer: {
    marginTop: "5rem",
    marginBottom: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "98%",
    marginRight: "4vw",
  },

  remitoHeader: {

  },
  remitoHeaderItem: {
    fontWeight: 400,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)"
  },
  remitoHeaderItemTitle: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    border: "none"
  },

  generalContainer: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "5rem"
  },

  headerTable: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    minHeight: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  headerTableTittle: {
    height: "2rem",
    color: "white",
    textAlign: "center",
  },
  tableContainer: {
    overflow: "auto",
    scrollbarWidth: "0px",
    width: "100%",
    maxHeight: "68vh"
  },
  table: {
    width: "100%",

  },
  tableRow: {
    whiteSpace: "nowrap",
    alignItems: "center",
  },

  tableCellHeaders: {
    textAlign: "left",
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",

    color: "rgba(67, 59, 59, 0.87)"
  },

  tableCell: {
    fontSize: "16px",

  },
  totalRow: {
    backgroundColor: "#CCCFE4"
  },
  totalCell: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "186.69%",
    /* or 26px */


    color: "rgba(67, 59, 59, 0.87)"
  }
});
export default useStyles;
