import Theme from "theme";

const useStyles = () => {


    return {
        tableProvidersMaterials: {
          table: () => ({}),
          tableContainer: () => ({ maxHeight: "60vh", overflow: "scroll" }),
          tableHead: () => ({}),
          tableBody: () => ({}),
          tableRowHead: () => ({}),
          tableRowBody: () => ({}),
        },
        tableCell: { borderBottom: 0, padding: "0.4rem" },
        formControl: {
            minWidth: 150
        },
        selectEmpty: {
            marginTop: Theme.spacing(2)
        },
        menuPaper: {
            maxHeight: 160
        },
        cardContainerScrollable: {
            maxHeight: "65vh", overflow: "scroll"
        },
        tableContainer: {
            backgroundColor: "red"
        },
      };    
};
export default useStyles;
