import React, { createContext, useState } from "react";
import jwt from "jwt-decode";
export const UserContext = createContext();

export const UserProvider = (props) => {
  let token = "";
  if (localStorage.getItem("token")) {
    token = jwt(localStorage.getItem("token"));
  }

  const defaultValue = {
    user_id: token.user_id || "",
    user_name: token.nombre_usuario || "",
    user_email: token.email || "",
    user_rol: token.rol || "",
    user_nombreRol: token.rol_nombre || "",
    user_matricula: token.matricula || "",
    user_token: token || "",
  };

  const [user, setUser] = useState(defaultValue);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
