import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid, Typography } from "@material-ui/core";
import Theme from "theme";
import { toContable } from "constants/funciones/toContable";

export default function SaldoFavor({ saldoDialog, setSaldoDialog }) {
  const handleClose = () => {
    setSaldoDialog({ ...saldoDialog, isOpen: false });
  };

  return (
    <Dialog onClose={handleClose} open={saldoDialog?.isOpen}>
      <Grid style={{ padding: "0.5rem" }}>
        <DialogContent>
          <Typography style={{ color: "#555555" }} align="center">
            {`Desea confirmar la carga de los medios de pago quedando a favor un saldo de ${toContable(
              saldoDialog?.saldo * -1,
            )}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSaldoDialog({ ...saldoDialog, isOpen: false })}
            color="default"
          >
            CANCELAR
          </Button>
          <Button
            onClick={() => {
              saldoDialog?.onConfirm();
              setSaldoDialog({ ...saldoDialog, isOpen: false });
            }}
            variant="contained"
            style={{
              color: "#ffffff",
              backgroundColor: Theme.palette.primary.main,
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
}
