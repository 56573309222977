import {
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import StyledTableCell from "components/styled/tableCell";
import StyledTableRow from "components/styled/tableRow";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import useStyles from "../styles";

import { useState } from "react";
import TableGeneric from "components/tableGeneric/tableGeneric";
import { namesCtaCte } from "../const";
import { toContable } from "constants/funciones/toContable";
import useDayjs from "hook/useDayjs";
import NewCheckbox from "components/inputs/newCheckbox";
import { useContext } from "react";
import { NotificationContext } from "context/notification/NotificationContext";

export default function CollapseRow({
  item,
  control,
  obtenerCuentasSeleccionadas,
}) {
  const styles = useStyles();
  const dayjs = useDayjs();
  const { isNotificationsEnabled } = useContext(NotificationContext);

  const [open, setOpen] = useState(false);

  const headers = [
    { name: "Fecha de Pago" },
    { name: "Monto" },
    { name: "Forma de Pago" },
    { name: "Nº de Cheque" },
    { name: "Fecha de Vencimiento" },
    { name: "Observaciones" },
  ];

  return (
    <>
      <StyledTableRow style={styles?.tableGeneric?.tableRowBody(item)} hover>
        <StyledTableCell
          style={{ minWidth: "5rem", padding: "0.5rem 0rem 0.5rem 0.5rem" }}
        >
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
            style={{ marginRight: "1rem" }}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>

          {item?.namesCtaCte?.tipoComprobante === "FAC" && (
            <NewCheckbox
              name={item?.id?.toString()}
              control={control}
              size="small"
              color="primary"
              onChange={() => obtenerCuentasSeleccionadas()}
              disabled={
                item?.condicion === "Pagado" ||
                (!isNotificationsEnabled && !item?.presupuesto)
              }
              style={{ margin: "0", padding: "0" }}
            />
          )}
        </StyledTableCell>

        <StyledTableCell style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}>
          {item?.[namesCtaCte?.fechaEmision]
            ? dayjs(item?.[namesCtaCte?.fechaEmision]).format("DD-MM-YYYY")
            : "-"}
        </StyledTableCell>

        <StyledTableCell>
          {item?.[namesCtaCte?.tipoComprobante] ?? "-"}
        </StyledTableCell>

        <StyledTableCell>
          {item?.[namesCtaCte?.num_comprobante] ?? "-"}
        </StyledTableCell>

        <StyledTableCell>
          {item?.[namesCtaCte?.orden]?.orden ??
            item?.[namesCtaCte?.orden]?.num_orden ??
            "-"}
        </StyledTableCell>

        <StyledTableCell>{item?.[namesCtaCte?.resumen] ?? "-"}</StyledTableCell>

        <StyledTableCell>
          {toContable(item?.[namesCtaCte?.montoFactura])}
        </StyledTableCell>

        <StyledTableCell>
          {toContable(item?.[namesCtaCte?.pagado])}
        </StyledTableCell>

        <StyledTableCell>
          {toContable(item?.[namesCtaCte?.saldo])}
        </StyledTableCell>

        <StyledTableCell>
          {toContable(item?.[namesCtaCte?.acumulado])}
        </StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
        <StyledTableCell style={{ padding: "0" }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container style={{ padding: "0.5rem 2rem" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Medios de Pago
              </Typography>

              {item?.[namesCtaCte?.subItems]?.length > 0 ? (
                <TableGeneric
                  size="small"
                  item={item}
                  headers={headers}
                  component={Paper}
                  styles={styles?.tableCollapse}
                >
                  {item?.[namesCtaCte?.subItems]?.map(
                    (subItem, indexSubitem) => (
                      <StyledTableRow
                        key={indexSubitem}
                        hover
                        style={styles?.tableCollapse?.tableRowBody(subItem)}
                      >
                        <StyledTableCell>
                          {subItem?.[namesCtaCte?.fechaMedioPago]
                            ? dayjs(
                                subItem?.[namesCtaCte?.fechaMedioPago],
                              ).format("DD-MM-YYYY")
                            : "-"}
                        </StyledTableCell>

                        <StyledTableCell>
                          {toContable(subItem?.[namesCtaCte?.montoMedioPago])}
                        </StyledTableCell>

                        <StyledTableCell>
                          {subItem?.[namesCtaCte?.formaPago] ?? "-"}
                        </StyledTableCell>

                        <StyledTableCell>
                          {subItem?.[namesCtaCte?.chequeMedioPago] ?? "-"}
                        </StyledTableCell>

                        <StyledTableCell>
                          {subItem?.[namesCtaCte?.fechaVtoMedioPago]
                            ? dayjs(
                                subItem?.[namesCtaCte?.fechaVtoMedioPago],
                              ).format("DD-MM-YYYY")
                            : "-"}
                        </StyledTableCell>

                        <StyledTableCell>
                          {subItem?.[namesCtaCte?.observacionMedioPago] ?? "-"}
                        </StyledTableCell>
                      </StyledTableRow>
                    ),
                  )}
                </TableGeneric>
              ) : (
                <Grid container justifyContent="center">
                  <Typography variant="subtitle1">
                    No se encontraron resultados
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
