import { Grid, Modal } from "@material-ui/core";
import Card from "./card";

export default function ModalGeneric({
  open,
  handleClose,
  title,
  justifyContent = "center",
  children,
  xl = 4,
  lg = 5,
  md = 8,
  sm = 8,
  xs = 10,
}) {
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose();
        }
      }}
    >
      <Grid
        container
        item
        xl={xl}
        lg={lg}
        md={md}
        sm={sm}
        xs={xs}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Card title={title} justifyContent={justifyContent}>
          {children}
        </Card>
      </Grid>
    </Modal>
  );
}
