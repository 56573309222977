import React, { useState, useEffect, useContext } from "react";
import Protected from "protected";
import {
  Grid,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import Theme from "theme";
import useStyles from "./styles";
import RootContainer from "layout/rootContainer";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { useQuery, useQueryClient } from "react-query";
import { NotificationContext } from "context/notification/NotificationContext";
import { useHistory } from "react-router-dom";
import { LogIn } from "context/LogInContext";
import fileDownload from "js-file-download";
import { useDebounce } from "hook/useDebounce";
import { shallow } from "zustand/shallow";
import {
  useOrderBy,
  useSearch,
  useActions,
  useStore,
  useTextSelected,
  useSearchNotesOrClients,
} from "constants/store";

// SERVICES
import UsuarioService from "services/usuario.service";
import ProveedoresService from "services/proveedores.service";

// COMPONENTS
import CustomSnackbar from "components/Layout/CustomSnackbars";
import SearchBar from "components/Layout/searchBar/SearchBar";
import { TablaTipoMateriales } from "../constants/headerBodyTipoMateriales";
import { TablaTipoTransportistas } from "../constants/headerBodyTipoTransportistas";
import { TablaTipoCombustibles } from "../constants/headerBodyTipoCombustibles";
import { TablaTipoGeneral } from "../constants/headerBodyTipoGeneral";
import LoadingText from "components/loadingText";
import ModalAgregarFactura from "../components/modalAgregarFactura";
import ModalEliminarRemito from "../components/modalEliminarRemito";

//ICONS
import VisibilityIcon from "@material-ui/icons/Visibility";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Create from "@material-ui/icons/Create";
import { Delete } from "@material-ui/icons";
import ExcelIcon from "icons/excel";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import TotalizadorModal from "./totalizadores/totalizadorModal";

//constants
const SORT_TYPE = Object.freeze({
  SORT_DATE: Symbol(),
  SORT_NUMFAC: Symbol(),
  SORT_QUANTITY: Symbol(),
  SORT_TRANS: Symbol(),
  SORT_COMB: Symbol(),
  SORT_IN: Symbol(),
  SORT_MONTO: Symbol(),
});

const RemitosProveedores = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { logged } = useContext(LogIn);
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const [proveedor] = useState(history?.location?.state);

  const Tabla =
    proveedor?.tipo === "Transportista"
      ? TablaTipoTransportistas
      : proveedor?.tipo === "Combustible"
      ? TablaTipoCombustibles
      : proveedor?.tipo === "General"
      ? TablaTipoGeneral
      : TablaTipoMateriales;

  //Defino estado para los filtros
  const [filtrado, setFiltrado] = useState([]);
  const [remitoSeleccionada, setRemitoSeleccionada] = useState();
  const [modalEliminarRemitoVisibility, setModalEliminarRemitoVisibility] =
    useState(false);
  const [modalAgregarFacturaVisibility, setModalAgregarFacturaVisibility] =
    useState(false);

  // eslint-disable-next-line no-unused-vars
  const [remitos, setRemitos] = useState([]);
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // ESTADOS PARA NAVEGACION ENTRE PAGINAS
  const [actual, setActual] = useState();
  const [total, setTotal] = useState();

  // state manager with zustand
  const filtro = useStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      ordfecha: state.ordfecha,
      ordnumfac: state.ordnumfac,
      ordmonto: state.ordmonto,
      ordcomb: state.ordcomb,
      ordingreso: state.ordingreso,
      ordtrans: state.ordtrans,
      ordcant: state.ordcant,
      page: state.page,
    }),
    shallow,
  );

  const searchText = useSearchNotesOrClients();
  const orderBy = useOrderBy();
  const minDate = filtro?.fecha_desde;
  const maxDate = filtro?.fecha_hasta;

  //Actions with zustand
  const {
    setSearch,
    setPage,
    setFechaDesde,
    setFechaHasta,
    setOrdFecha,
    setOrdNumFac,
    setOrdCant,
    setOrdComb,
    setOrdIngreso,
    setOrdTrans,
    setOrdMonto,
    toggleSort,
  } = useActions();

  // useDebounce hook for input text search
  const debouncedSearch = useDebounce(searchText, 200);

  const placeholderText = () => {
    let placeholderText = "";
    if (proveedor?.tipo === "Transportista") {
      placeholderText = "Buscar por Cantera / Arenera";
    } else if (proveedor?.tipo === "General") {
      placeholderText = "Buscar por Productos / Servicios";
    } else if (proveedor?.tipo === "Combustible") {
      placeholderText = "Buscar por Chofer / Transporte";
    } else {
      placeholderText = "Buscar por Transportista";
    }
    return placeholderText;
  };

  // DECLARACION DE ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // VERIFICAR ACCESOS A MODULO
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await UsuarioService.checkAccess({ modulo: "remitos" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "remitos" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "remitos" });
    }
  };

  // FUNCION PARA TRAER LISTA DE RemitoS
  const obtenerRemitos = async (filtro) => {
    try {
      const res = await ProveedoresService.getRemitoProveedor(proveedor, {
        ...filtro,
        search: debouncedSearch,
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  };

  // QUERY PARA OBTENER RemitoS DE PROVEEDORES
  const { data: ArrayRemitos, isFetching } = useQuery(
    ["Remitos", filtro, isNotificationsEnabled, debouncedSearch],
    () => obtenerRemitos(filtro),
    {
      onSuccess: (data) => {
        setRemitos(data?.results?.toSpliced(data.results.length - 1));
        setFiltrado(data?.results?.toSpliced(data.results.length - 1));

        //Seteo los datos del paginado
        setActual(data.actual);
        setTotal(data.total_paginas);
      },
      onError: (error) => {
        console.log("Error", error);
      },
      refetchInterval: false, //si estamos mucho tiempo en un mismo sitio, refresca la pagina cada cierto tiempo por si hay cambios nuevos
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false, //para que no se actualice la pagina automaticamente al hacer foco en ella
    },
  );

  // FUNCION DE BUSCADOR
  const handleSearch = async (text) => {
    setSearch(text);
  };

  // FUNCION ASIGNAR CRITERIO DE ORDENAMIENTO
  const asignarCriterioOrd = (orderBy) => {
    switch (orderBy) {
      case "fecha": 
        return SORT_TYPE.SORT_DATE;
      case "factura_remito": 
        return SORT_TYPE.SORT_NUMFAC;
      case "ingreso": 
        return SORT_TYPE.SORT_IN;
      case "trans":
          return SORT_TYPE.SORT_TRANS;
      case "tipo_combustible":
          return SORT_TYPE.SORT_COMB;
      case "cantera_arenera":
          return SORT_TYPE.SORT_QUANTITY;
      case "monto": 
          return SORT_TYPE.SORT_MONTO;          
      default:
          break;
      }
  }

  // FUNCION DE ORDENAMIENTO
  const sortBy = (criteria) => {
    const sortType = orderBy ? "asc" : "desc";
    switch (criteria) {
      case SORT_TYPE.SORT_DATE:
        setOrdFecha(sortType);
        break;
      case SORT_TYPE.SORT_IN:
        setOrdIngreso(sortType);
        break;
      case SORT_TYPE.SORT_TRANS:
        setOrdTrans(sortType);
        break;
      case SORT_TYPE.SORT_NUMFAC:
        setOrdNumFac(sortType);
        break;
      case SORT_TYPE.SORT_QUANTITY:
        setOrdCant(sortType);
        break;
      case SORT_TYPE.SORT_MONTO:
        setOrdMonto(sortType);
        break;
      case SORT_TYPE.SORT_COMB:
        setOrdComb(sortType);
        break;
      default:
        break;
    }
    toggleSort();
  };

  // ABRIR MODAL ELIMINAR Remito
  const eliminarRemito = (remito) => {
    setRemitoSeleccionada(remito);
    setModalEliminarRemitoVisibility(true);
  };

  // CERRAR MODAL ELIMINAR Remito
  const handleCloseModalEliminarRemito = () => {
    setModalEliminarRemitoVisibility(false);
  };

  // EDITAR Remito
  const editarRemito = (remito) => {
    history.push("/proveedores/nuevo-editar-remito", {
      remito,
      proveedor,
    });
  };

  // CERRAR MODAL Agregar Factura
  const handleCloseModalAgregarFactura = () => {
    setModalAgregarFacturaVisibility(false);
  };

  // DESCARGA DE EXCEL CON EL LISTADO QUE SE TIENE EN LA TABLA
  const descargarExcel = async () => {
    try {
      const res = await ProveedoresService.descargarExcelRemitos(
        proveedor.id,
        filtro,
      );
      if (res.status >= 200 && res.status < 300) {
        fileDownload(
          res.data,
          `remitos_${proveedor?.razon_social}_${minDate}_${maxDate}.xlsx`,
        );
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: "Error al descargar el archivo.",
      });
    }
  };
  const [totalizador, setTotalizador] = useState(false);
  // Funciones del totalizador
  const handleModalTotalizador = () => {
    setTotalizador(!totalizador);
  };

  // VERIFICACIÓN DE PERMISOS
  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Valida que no se ingresó desde el listado de proveedores, por lo tanto no existe state y debe volver al listado
  useEffect(() => {
    !proveedor && history.push("/proveedores/listado");
  }, [proveedor]);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{
              marginTop: "15rem",
            }}
          >
            <CircularProgress
              style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            {acceso && (
              <>
                <Grid
                  style={{
                    paddingTop: "1rem",
                    width: "95%",
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    item
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    <Grid
                      container
                      item
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item container xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Grid item style={{ paddingRight: "1rem" }}>
                          <Typography className={classes.HeaderItemTitle}>
                            Razón Social:{" "}
                            <span className={classes.HeaderItem}>
                              {proveedor?.razon_social ?? "-"}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.HeaderItemTitle}>
                            CUIT/CUIL:{" "}
                            <span className={classes.HeaderItem}>
                              {proveedor?.cuil_cuit ?? "-"}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item style={{ paddingRight: "1rem" }}>
                        <ButtonLoading
                          onClick={() => descargarExcel()}
                          startIcon={<ExcelIcon />}
                        >
                          Descargar Excel
                        </ButtonLoading>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    container
                    item
                    direction="row"
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <Grid
                      xl={8}
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "0.5rem" }}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Desde"
                          focused
                          defaultValue={filtro.fecha_desde}
                          onChange={(e) =>
                            setFechaDesde(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            max: filtro.fecha_hasta,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Hasta"
                          focused
                          defaultValue={filtro.fecha_hasta}
                          onChange={(e) =>
                            setFechaHasta(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            min: filtro.fecha_desde,
                            max: moment().format("YYYY-MM-DD"),
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <SearchBar
                          placeholder={placeholderText()}
                          defaultValue={searchText}
                          value={searchText}
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      container
                      justifyContent={"flex-end"}
                      alignContent="flex-start"
                      alignItems="center"
                      style={{ marginTop: "0.5rem" }}
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={filtrado?.length > 0 ? false : true}
                          style={{
                            //backgroundColor: filtrado.length ? "#001176" : "grey",

                            color: "white",
                            maxHeight: "2rem",
                          }}
                          onClick={() => handleModalTotalizador()}
                        >
                          Totalizadores
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ maxHeight: "2rem" }}
                          onClick={() =>
                            history.push("/proveedores/nuevo-editar-remito", {
                              proveedor,
                            })
                          }
                        >
                          Nuevo Remito
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ maxHeight: "2rem" }}
                          onClick={() => setModalAgregarFacturaVisibility(true)}
                        >
                          Agregar Factura
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{
                    maxWidth: "95%",
                    marginTop: "0.5rem",
                    marginBottom: "5rem",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    className={classes.headerTable}
                  >
                    <Typography
                      variant="h5"
                      className={classes.headerTableTittle}
                    >
                      Listado de Remitos
                    </Typography>
                  </Grid>
                  {isFetching ? (
                    <LoadingText title="Cargando Remitos del Proveedor" />
                  ) : (
                    <>
                      <TableContainer
                        component={Paper}
                        className={classes.tableContainer}
                      >
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow className="tableRow">
                              {Tabla?.headers?.map((header) =>
                                header?.type === "order" ? (
                                  <TableCell
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: 700,
                                      fontSize: "16px",
                                      color: "rgba(67, 59, 59, 0.87)",
                                      minWidth: "9rem",
                                    }}
                                  >
                                    {header.value}
                                    <IconButton
                                      onClick={() => sortBy(asignarCriterioOrd(header.id_ord))}
                                    >
                                      <CompareArrowsIcon
                                        style={{
                                          width: "1rem",
                                          transform: "rotate(90deg)",
                                        }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    className={classes.tableCellHeaders}
                                    style={{ paddingLeft: "0.5rem" }}
                                  >
                                    {header.value}
                                  </TableCell>
                                ),
                              )}
                              <TableCell
                                className={classes.tableCellHeaders}
                                style={{ paddingLeft: "0.5rem" }}
                              >
                                {"Acciones"}
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {filtrado?.length > 0 &&
                            filtrado.map((remito) => (
                              <TableBody>
                                <TableRow hover key={remito.id}>
                                  {Tabla?.body?.map((atribute) =>
                                    atribute?.type === "date" ? (
                                      <TableCell
                                        style={{
                                          fontFamily: "Roboto",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          fontSize: "16px",
                                          minWidth: "10rem",
                                        }}
                                      >
                                        {moment(remito[atribute?.value]).format(
                                          "DD/MM/YYYY",
                                        ) ?? atribute?.default}
                                      </TableCell>
                                    ) : (
                                      <TableCell className={classes.tableCell}>
                                        {remito[atribute?.value] ??
                                          atribute?.default}
                                      </TableCell>
                                    ),
                                  )}

                                  <TableCell>
                                    <Grid
                                      container
                                      item
                                      direction="row"
                                      justifyContent="left"
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "8rem",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() =>
                                          history.push(
                                            `/proveedores/detalle-remito/${remito.id}`,
                                            {
                                              remito,
                                              proveedor,
                                            },
                                          )
                                        }
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",

                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                      >
                                        <VisibilityIcon
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => eliminarRemito(remito)}
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          marginLeft: "0.5rem",

                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                      >
                                        <Delete
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => editarRemito(remito)}
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          marginLeft: "0.5rem",
                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                      >
                                        <Create
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                        </Table>
                      </TableContainer>
                      {filtrado?.length > 0 ? (
                        <>
                          <Grid
                            container
                            item
                            direction="row"
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            style={{ margin: "1rem" }}
                          >
                            <Pagination
                              size="small"
                              count={total}
                              color="primary"
                              onChange={(event, value) => setPage(value)}
                              page={filtro?.page}
                            />
                          </Grid>

                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{
                              backgroundColor: "#E5E5E5",
                              color: "#433B3B",
                              width: "auto",
                              maxHeight: "100%",
                              marginRight: "2rem",
                              padding: "0.5rem 1rem",
                              borderRadius: "20px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "18px",
                                marginRight: "1rem",
                              }}
                            >
                              {proveedor?.tipo === "General"
                                ? "Total Monto: $"
                                : "Total Ingreso:"}
                            </Typography>
                            <Typography
                              style={{ fontSize: "20px", fontWeight: 700 }}
                            >
                              {
                                ArrayRemitos?.results[
                                  ArrayRemitos?.results?.length - 1
                                ]?.total_ingresos
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{
                              backgroundColor: "#E5E5E5",
                              color: "#433B3B",
                              width: "auto",
                              maxHeight: "100%",
                              marginRight: "2rem",
                              padding: "0.5rem 1rem",
                              borderRadius: "20px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "18px",
                                marginRight: "1rem",
                              }}
                            >
                              {proveedor?.tipo === "General"
                                ? "Total Saldo: $"
                                : "Total Saldo:"}
                            </Typography>
                            <Typography
                              style={{ fontSize: "20px", fontWeight: 700 }}
                            >
                              {ArrayRemitos?.results[
                                ArrayRemitos?.results?.length - 1
                              ]?.total_saldo ?? 0}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          container
                          justifyContent="center"
                          style={{ margin: "0.5rem" }}
                        >
                          <Typography variant="subtitle1">
                            No se encontraron remitos para el transportista
                            elegido y/o en el período seleccionado.
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                <ModalEliminarRemito
                  open={modalEliminarRemitoVisibility}
                  handleClose={handleCloseModalEliminarRemito}
                  remitoSeleccionado={remitoSeleccionada}
                />
                <ModalAgregarFactura
                  open={modalAgregarFacturaVisibility}
                  handleClose={handleCloseModalAgregarFactura}
                  queryClient={queryClient}
                  idProv={proveedor?.id}
                />
                {totalizador && (
                  <TotalizadorModal
                    open={handleModalTotalizador}
                    handleClose={handleModalTotalizador}
                    remitos={ArrayRemitos.results}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                )}
              </>
            )}
          </>
        )}
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};
export default RemitosProveedores;
