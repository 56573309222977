import { styled } from "@material-ui/core/styles";
import { TableHead } from "@material-ui/core";

const StyledTableHead = styled(TableHead)(() => ({
  "&.MuiTableHead-root": {
    backgroundColor: "var(--backgroundColor)",
  },
}));

export default StyledTableHead;
