import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  tableContainer: {
    overflow: "auto",
    width: "100%",
    maxHeight: "65vh",
  },
  table: {
    width: "100%",
  },
  menu: {},
});
export default useStyles;
