import { create } from "zustand";
import { devtools } from "zustand/middleware"
import { ProvidersSubtypes } from "../constants/ProvidersSubtypes";

// DESESTRUCTURING PROVIDERS SUBTYPES 
const { transportistasSubtypes, materialesSubtypes } = ProvidersSubtypes;

const defaultState = {
    subTiposProveedor: [{ nombre: "Todos", value: "Todos" }, ...materialesSubtypes, ...transportistasSubtypes],
    textSelected: "Todos",
    providerSubTypeSelected: "Todos",
    search: "",
    tipo: "",
    subtipo: "",
    page: 1,
};

export const useProveedoresStore = create(
    devtools((set) => ({
        ...defaultState,
        actions: {
            setSubTipoProveedor: (value) =>
                set((state) => ({
                    subTiposProveedor: value
                })),
            setSearch: (value) =>
                set((state) => ({
                    search: value
                })),
            setTipo: (value) =>
                set((state) => ({
                    tipo: value,
                    sort: "",
                    page: 1
                })),
            setSubTipo: (value) =>
                set((state) => ({
                    subtipo: value,
                    sort: "",
                    page: 1
                })),
            setSelectTexto: (value) => set(() => ({ textSelected: value })),
            setProviderSubTypeSelected: (value) => set(() => ({ providerSubTypeSelected: value })),
            setPage: (value) => set((state) => ({ page: value })),
        },
    })),
);

export const useMaterialesDeProveedoresStore = create(
    devtools((set) => ({
        ...defaultState,
        actions: {
            setSearch: (value) =>
                set((state) => ({
                    search: value,
                    sort: "",
                    page: 1
                })),
            setTipo: (value) =>
                set((state) => ({
                    tipo: value,
                    sort: "",
                    page: 1
                })),
            setPage: (value) => set((state) => ({ page: value })),
        },
    })),
);

export const useChoferesAutorizadosStore = create(
    devtools((set) => ({
        ...defaultState,
        actions: {
            setSearch: (value) =>
                set((state) => ({
                    search: value,
                    sort: "",
                    page: 1
                })),
            setTipo: (value) =>
                set((state) => ({
                    tipo: value,
                    sort: "",
                    page: 1
                })),
            setPage: (value) => set((state) => ({ page: value })),
        },
    })),
);

export const useSearchMaterialOrTipeMaterial = () => useMaterialesDeProveedoresStore((state) => state.search);
export const useMaterialesProveedoresActions = () => useMaterialesDeProveedoresStore((state) => state.actions);
export const useSearchNombreDniEmpresa = () => useChoferesAutorizadosStore((state) => state.search);
export const useChoferesAutorizadosActions = () => useChoferesAutorizadosStore((state) => state.actions);
export const useSearchRazonSocialOrCuit = () => useProveedoresStore((state) => state.search);
export const useProviderTypeSelected = () => useProveedoresStore((state) => state.textSelected);
export const useProviderSubTypeSelected = () => useProveedoresStore((state) => state.providerSubTypeSelected);
export const useArrayProviderSubType = () => useProveedoresStore((state) => state.subTiposProveedor);
export const useProveedoresActions = () => useProveedoresStore((state) => state.actions);
