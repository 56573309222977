/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import Protected from "protected";
import { useReactToPrint } from "react-to-print";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import PrintIcon from "@material-ui/icons/Print";
import Logo from "../../../../assets/img/LogoAyupi.png";
import { ReactComponent as RemintoR } from "assets/svg/remito_r.svg";
import { ReactComponent as RemintoX } from "assets/svg/remito_x.svg";
import { toContable } from "constants/funciones/toContable";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory, useParams } from "react-router-dom";
import remitosService from "services/remitos.service";

const DetallesRemitoProveedor = () => {
  const history = useHistory();
  const { id } = useParams();
  const styles = useStyles();
  const componentRef = useRef();
  const [loadingRemito, setLoadingRemito] = useState(false);
  // const [dataRemito, setDataRemito] = useState();
  const { remito, proveedor, materiales } = history.location.state;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log("remito y proveedor, y materiales", {
    remito,
    proveedor,
    materiales,
  });

  useEffect(() => {
    setLoadingRemito(true);
    setTimeout(() => {
      setLoadingRemito(false);
    }, 1000);
  }, []);

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  return (
    <Protected>
      <>
        {!loadingRemito && !!remito ? (
          <Grid container style={{ padding: "0 4rem 8rem 8rem" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              item
              style={{ marginTop: "0.5rem" }}
            >
              <Grid></Grid>
              <Grid>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    margin: "0.5rem 0.5rem 0.5rem 0",
                  }}
                  onClick={() => history.goBack()}
                >
                  Volver
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    textTransform: "capitalize",
                    margin: "0.5rem 0",
                  }}
                  startIcon={<PrintIcon />}
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
              </Grid>
            </Grid>
            <Grid container item className={styles.generalContainer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                className={styles.headerTable}
              >
                <Typography variant="h5" className={styles.headerTableTittle}>
                  Remito N° {remito?.factura_remito}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              ref={componentRef}
              style={{
                border: "1px solid black",
                backgroundColor: "#ffffff",
                paddingBottom: "2rem",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems:
                    windowDimenion.winWidth < 800 ? "center" : "flex-start",
                  flexDirection:
                    windowDimenion.winWidth < 800 ? "column" : "row",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderBottom: "1px solid black",
                }}
                container
                spacing={1}
              >
                <Grid item>
                  <Box
                    component="img"
                    sx={{
                      height: 90,
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={styles.textStyle}>
                    Dom. Fiscal:
                  </Typography>
                  <Typography className={styles.textStyle}>
                    Av. 9 de Julio 635 - Rcia. Chaco
                  </Typography>

                  <Typography
                    className={styles.textStyle}
                    style={{ paddingTop: "1rem" }}
                  >
                    Ruta 16 - Km. 18,5
                  </Typography>
                  <Typography className={styles.textStyle}>
                    Tel: 0362-4349303 - 4765771
                  </Typography>
                  <Typography className={styles.textStyle}>
                    3500 Resistencia - Chaco
                  </Typography>

                  <Typography
                    className={styles.textStyle}
                    style={{ paddingTop: "1rem" }}
                  >
                    IVA RESPONSABLE INSCRIPTO
                  </Typography>
                </Grid>
                <Grid item>
                  <RemintoR />
                </Grid>
                <Grid item style={{ display: "flex", flexDirection: "row" }}>
                  <Grid style={{ display: "flex", flexDirection: "column" }}>
                    <Typography className={styles.textStyle2}>
                      Remito
                    </Typography>
                    <Typography className={styles.textStyle2}>
                      N° {remito?.factura_remito}
                    </Typography>
                    <Typography className={styles.textStyle}>
                      Documento no válido como Factura
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ display: "flex", flexDirection: "row" }}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      D
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      M
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                      }}
                    >
                      A
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      width: "100px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                      }}
                    >
                      {moment(remito?.fecha).format("DD")}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                      }}
                    >
                      {moment(remito?.fecha).format("MM")}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                      }}
                    >
                      {moment(remito?.fecha).format("yyyy")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  padding: "1.5rem",
                  borderBottom: "1px solid black",
                }}
              >
                <Grid
                  item
                  container
                  spacing={1}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      Proveedor:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {proveedor?.razon_social}
                    </Typography>
                  </Grid>
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      CUIT/CUIL Proveedor:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {proveedor?.cuil_cuit}
                    </Typography>
                  </Grid>
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      Tipo de Proveedor:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {proveedor?.tipo}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: ".6rem",
                  }}
                ></Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  padding: "1.5rem",
                }}
                container
              >
                {(proveedor?.tipo === "Transportista" ||
                  proveedor?.tipo === "Materiales") && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Material:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.nombre_material}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Tipo de Material:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.nombre_tipo_material}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Ingreso:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.ingreso}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Ton. Báscula:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.toneladas_bascula}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {proveedor?.tipo === "Combustible" && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Tipo de Combustible:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.tipo_combustible}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Litros:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.cantidad_litros}
                      </Typography>
                    </Grid>

                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Kilometraje:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.kilometros}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {proveedor?.tipo === "general" && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Monto:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {toContable(remito?.monto)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  padding: "1.5rem",
                }}
                container
              >
                {proveedor?.tipo === "Materiales" && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Transporte:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.transporte}
                      </Typography>
                    </Grid>

                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Orígen:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.origen}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {proveedor?.tipo === "Transportista" && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Cantera/Arenera:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.cantera_arenera}
                      </Typography>
                    </Grid>

                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Orígen:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.origen}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {proveedor?.tipo === "Combustible" && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Proveedor Transporte:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.proveedor_transporte}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Chofer/Tambor:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.chofer_tambor}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Patente:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.patente}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {proveedor?.tipo === "General" && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Productos/Servicios:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.producto_servicio}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  padding: "1.5rem",
                }}
                container
              >
                {(proveedor?.tipo === "Transportistas" ||
                  proveedor?.tipo === "Materiales") && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Chofer:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.chofer}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Pat. Tractor:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.patente_tractor}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Pat. Batea:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {remito?.patente_batea}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingTop: "14rem",
            }}
          >
            <CircularProgress />
          </Grid>
        )}
      </>
    </Protected>
  );
};
export default DetallesRemitoProveedor;
