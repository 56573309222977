import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { toContable } from "constants/funciones/toContable";
import useStyles from "screens/facturacion/seleccionar-cliente/styles";
import Logo from "../../../assets/img/LogoAyupi.png";
import Card from "components/card";

export default function ImprimirInformeCuentaCorriente({
  componentRef,
  dataOrdenes,
  filtro,
  cliente,
  totalDebe,
  data
}) {
  const classes = useStyles();

  return (
    <Grid ref={componentRef}>
      <style type="text/css" media="print">
        {`@page { 
            margin: 1rem 0 !important; 
            size: A4 landscape;
            }
            ::-webkit-scrollbar,
            ::-webkit-scrollbar {
              display: none;
            }
            ::-webkit-scrollbar-track,
            ::-webkit-scrollbar-thumb {
              display: none;
            }
            scrollbar-width: none;
            -ms-overflow-style: none;`}
      </style>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ padding: "2rem" }}
      >
        <Grid container xl={4} lg={4} md={4} sm={4} xs={4}>
          <Grid item>
            <Box
              component="img"
              sx={{
                height: 100,
                marginLeft: "2.5rem",
                marginTop: "1rem",
              }}
              alt="Logo"
              src={Logo}
            />
          </Grid>
        </Grid>
        <Grid
          container
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={8}
          style={{
            marginTop: "3.5rem",
          }}
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            INFORME ESTADO DE CUENTA CORRIENTE
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ padding: "1rem 1rem 0 1rem" }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "16px",
                color: "rgba(67, 59, 59, 0.87)",
              }}
            >
              Cliente:{" "}
              <span
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "16px",
                  color: "rgba(67, 59, 59, 0.87)",
                  border: "none",
                }}
              >
                {cliente?.nombre ?? "--"}
              </span>
            </Typography>
          </Grid>
        </Grid>
        {/* Estado de Cuenta */}
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="body1">Estado de Cuenta</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" style={{ padding: "1rem 1rem 0 1rem" }}>
          <Grid container direction="column" xs={3}>
            <Grid item>
              <Typography variant="caption">Total Pagado</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="primary"
                style={{ fontSize: "x-large", fontWeight: 700 }}
              >
                {toContable(data?.total_pagado) ?? "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" xs={3}>
            <Grid item>
              <Typography variant="caption">Total a Favor</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="inherit"
                style={{ fontSize: "x-large", fontWeight: 700 }}
              >
                {toContable(data?.credito_haber) ?? "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" xs={3}>
            <Grid item>
              <Typography variant="caption">Factura Pendientes</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="error"
                style={{ fontSize: "x-large", fontWeight: 700 }}
              >
                {data?.cant_facturas_impagas ?? "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" xs={3}>
            <Grid item>
              <Typography variant="caption">Total por pagar</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="error"
                style={{ fontSize: "x-large", fontWeight: 700 }}
              >
                {toContable(data?.credito_haber) ?? "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* INICIO DE TABLA */}
        <Grid
          container
          item
          className={classes.generalContainer}
          style={{ padding: "1rem 1rem 0 1rem" }}
        >
          <Card title="Resumen de Materiales" justifyContent="center">
            <TableContainer className={classes.TableContainer}>
              <Table style={{ margin: "0 auto" }} size="small">
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCellHeaders}>
                      Producto/Servicios
                    </TableCell>
                    <TableCell className={classes.tableCellHeaders}>
                      Cantidad Total
                    </TableCell>
                    <TableCell className={classes.tableCellHeaders}>
                      Restante Total
                    </TableCell>
                    <TableCell className={classes.tableCellHeaders}>
                      Ordenes de Compra
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataOrdenes?.results?.length > 0 ? (
                    dataOrdenes?.results?.map((item, index) => (
                      <TableRow
                        hover
                        key={index}
                        style={{
                          height: "3rem",
                        }}
                      >
                        <TableCell>
                          {item?.formula ?? "-"}
                        </TableCell>
                        <TableCell>
                          {item?.cantidad_m3 ?? "-"}
                        </TableCell>
                        <TableCell>
                          {item?.cant_restante ?? "-"}
                        </TableCell>
                        <TableCell>
                          {item?.ordenes?.map((unaOrden, indice) => {
                            return (indice === 0) ?
                              `${unaOrden?.num_orden} `
                              :
                              `/ ${unaOrden?.num_orden}`

                          })}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Grid item style={{ margin: "0.5rem" }}>
                      <Typography>No se encontraron cuentas</Typography>
                    </Grid>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
