import { createContext, useState } from "react";

export const ToggleDrawerContext = createContext();

export const ToggleDrawerProvider = (props) => {
  const [drawer, setDrawer] = useState(false);
  return (
    <ToggleDrawerContext.Provider value={{ drawer, setDrawer }}>
      {props.children}
    </ToggleDrawerContext.Provider>
  );
};
