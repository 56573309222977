import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  panelRoot: {
    backgroundColor: "#F0F0F0",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    
  },
  textFieldContainer: {
    marginTop: "5rem",
    marginBottom: 0,
    width: "98%",
    marginRight: "4vw",
  },
  generalContainer: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
    marginTop: "1rem",
  },

  indicacion: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16px",
    margin: "0.5rem 0",
    color: "rgba(67, 59, 59, 0.87)",
  },

});
export default useStyles;
