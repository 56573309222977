import { useQuery } from "react-query";
import remitosService from "services/remitos.service";

// FUNCION PARA TRAER LISTA DE REMITOS
const getRemitosbyClient = async ({
  filtro,
  debouncedValue,
  isNotificationsEnabled,
  clientId,
}) => {
  try {
    // make params to send api
    const params = {
      ...filtro,
      obra: debouncedValue,
      fecha: `${filtro.fecha_desde}|${filtro.fecha_hasta}`,
      id: clientId,
    };
    delete params.fecha_desde;
    delete params.fecha_hasta;

    //check endpoint to make request
    const endpoint = isNotificationsEnabled
      ? `filtrarRemitosPorFecha`
      : `filtrarRemitosBPorFecha`;
    const res = await remitosService[endpoint](params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useRemitosByClient({
  filtro,
  debouncedValue,
  isNotificationsEnabled,
  clientId,
  onSuccess,
}) {
  return useQuery(
    ["RemitosCliente", filtro, debouncedValue, isNotificationsEnabled],
    () =>
      getRemitosbyClient({
        filtro,
        debouncedValue,
        isNotificationsEnabled,
        clientId,
      }),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );
}
