import React from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import useStyles from "../../../../components/tableAgenda/styles";

const TablaChica = ({
  DataAgenda,
  date,
  setDataAgenda,
  moment,
  ordenarPor,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      className={classes.generalContainer}
      style={{ marginTop: "2rem", width: "99%" }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        className={classes.headerTable}
      >
        <Typography variant="h5" className={classes.headerTableTittle}>
          {moment(date).locale("es").format("dddd LL")}
        </Typography>
      </Grid>

      <TableContainer className={classes.tableContainer}>
        {!DataAgenda?.detail ? (
          <Table className={classes.table} size="small">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell className={classes.headTxt}>
                  Hora
                  <IconButton
                    style={{ margin: "0 0.5rem", padding: 0 }}
                    onClick={() => ordenarPor("hora_salida")}
                  >
                    <CompareArrowsIcon
                      style={{
                        width: "1rem",
                        padding: 0,
                        margin: 0,
                        transform: "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell className={classes.headTxt}>
                  Mts3
                  <IconButton
                    style={{ margin: "0 0.5rem", padding: 0 }}
                    onClick={() => ordenarPor("cantidad_volumen")}
                  >
                    <CompareArrowsIcon
                      style={{
                        width: "1rem",
                        padding: 0,
                        margin: 0,
                        transform: "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell className={classes.headTxt}>Tipo F.</TableCell>
                <TableCell className={classes.headTxt}>
                  Cliente
                  <IconButton
                    style={{ margin: "0 0.5rem", padding: 0 }}
                    onClick={() => ordenarPor("cliente_id")}
                  >
                    <CompareArrowsIcon
                      style={{
                        width: "1rem",
                        padding: 0,
                        margin: 0,
                        transform: "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell className={classes.headTxt}>Ciudad</TableCell>
                <TableCell className={classes.headTxt}>
                  Dirección
                  <IconButton
                    style={{ margin: "0 0.5rem", padding: 0 }}
                    onClick={() => ordenarPor("direccion_obra")}
                  >
                    <CompareArrowsIcon
                      style={{
                        width: "1rem",
                        padding: 0,
                        margin: 0,
                        transform: "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DataAgenda?.map((data) => (
                <>
                  <TableRow
                    hover
                    key={data.id}
                    style={{
                      backgroundColor:
                        data?.entrega === "Reasignado" ||
                        data?.entrega === "Suspendido"
                          ? "yellow"
                          : data?.bombeo
                          ? "#aefa43"
                          : "white",
                      borderBottom: "1px solid black",
                      height: "3rem",
                    }}
                  >
                    <TableCell
                      className={classes.tableRow}
                      style={{
                        color:
                          data?.entrega === "Reasignado" ||
                          data?.entrega === "Suspendido"
                            ? "#f2190a"
                            : "none",
                      }}
                    >
                      {data?.hora_salida
                        ? moment(data?.hora_salida, "HH:mm:ss").format("HH:mm")
                        : "-"}
                    </TableCell>
                    <TableCell
                      className={classes.tableRow}
                      style={{
                        color:
                          data?.entrega === "Reasignado" ||
                          data?.entrega === "Suspendido"
                            ? "#f2190a"
                            : "none",
                      }}
                    >
                      {data?.cantidad_volumen ? data.cantidad_volumen : ""}
                    </TableCell>
                    <TableCell
                      className={classes.tableRow}
                      style={{
                        color:
                          data?.entrega === "Reasignado" ||
                          data?.entrega === "Suspendido"
                            ? "#f2190a"
                            : "none",
                      }}
                    >
                      {data?.tipo_formula ? data.tipo_formula : ""}
                    </TableCell>

                    <TableCell
                      className={classes.tableRow}
                      style={{
                        color:
                          data?.entrega === "Reasignado" ||
                          data?.entrega === "Suspendido"
                            ? "#f2190a"
                            : "none",
                      }}
                    >
                      {data?.cliente_id
                        ? data.cliente_id.nombre.toUpperCase()
                        : ""}
                    </TableCell>
                    <TableCell
                      className={classes.tableRow}
                      style={{
                        color:
                          data?.entrega === "Reasignado" ||
                          data?.entrega === "Suspendido"
                            ? "#f2190a"
                            : "none",
                      }}
                    >
                      {data?.ciudad ? data?.ciudad.toUpperCase() : ""}
                    </TableCell>
                    <TableCell
                      className={classes.tableRow}
                      style={{
                        color:
                          data?.entrega === "Reasignado" ||
                          data?.entrega === "Suspendido"
                            ? "#f2190a"
                            : "none",
                      }}
                    >
                      {data?.direccion_obra
                        ? data?.direccion_obra.toUpperCase()
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      borderBottom: "1px solid black",
                      height: "2rem",
                    }}
                  ></TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Grid width="100%">
            <Typography
              style={{
                margin: "0 auto",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              La agenda de este día está vacía
            </Typography>
          </Grid>
        )}
      </TableContainer>
    </Grid>
  );
};

export default TablaChica;
