const rules = {
  apellido: {
    required: {
      value: true,
      message: "El campo Apellido es requerido",
    },
    pattern: {
      value: /^[a-z ñáéíóú']+$/i,
      message: "Apellido solo acepta letras.",
    },
  },
  nombre: {
    required: {
      value: true,
      message: "El campo Nombre es requerido",
    },
    pattern: {
      value: /^[a-z ñáéíóú']+$/i,
      message: "Nombre solo acepta letras.",
    },
  },
  email: {
    required: {
      value: true,
      message: "El campo Email es requerido",
    },
    pattern: {
      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      message: "Email inválido. Formato aaaa@bbb.ccc",
    },
  },
  emailNoRequired: {
    required: {
      value: false,
      message: "El campo Email es requerido",
    },
    pattern: {
      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      message: "Email inválido. Formato aaaa@bbb.ccc",
    },
  },
  monto: {
    required: {
      value: true,
      message: "El campo monto es requerido",
    },
    pattern: {
      value: /^[0-9]+([.][0-9]+)?$/,
      message: "monto inválido utilice . para separar decimales",
    },
  },
  usuario: {
    required: {
      value: true,
      message: "El campo Usuario es requerido",
    },
    minLength: {
      value: 3,
      message: "El usuario debe contener al menos 3 caracteres", // JS only: <p>error message</p> TS only support string
    },
  },
  password: {
    required: {
      value: true,
      message: "La Contraseña es requerida",
    },
    pattern: {
      value: /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])|(?=.*?\W).*$/,
      //   message:
      //     "La contraseña debe contener al menos 8 caracteres, incluyendo una mayúscula, una minúscula, un número y un caracter especial. Ej: Orlando1$",
    },
    minLength: {
      value: 8,
      message: "La contraseña debe contener al menos 8 caracteres", // JS only: <p>error message</p> TS only support string
    },
  },
  passwordEdit: {
    // required: {
    //   value: false,
    //   message: "La Contraseña es requerida",
    // },
    pattern: {
      value: /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])|(?=.*?\W).*$/,
    },
    minLength: {
      value: 8,
      message: "La contraseña debe contener al menos 8 caracteres", // JS only: <p>error message</p> TS only support string
    },
  },
  passwordConfirm: {
    required: {
      value: true,
      message: "La Confirmación de Contraseña es requerida",
    },
    pattern: {
      value: /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])|(?=.*?\W).*$/,
      //   message:
      //     "La contraseña debe contener al menos 8 caracteres, incluyendo una mayúscula, una minúscula, un número y un caracter especial. Ej: Orlando1$",
    },
    minLength: {
      value: 8,
      message:
        "La confirmación de contraseña debe contener al menos 8 caracteres", // JS only: <p>error message</p> TS only support string
    },
  },
  numeroCalle: {
    required: {
      value: true,
      message: "N° de calle es requerido",
    },
    maxLength: {
      value: 5,
      message: "El N° no puede exceder 5 caracteres",
    },
    pattern: {
      value: /^[0-9]{1,8}$/i,
      message: "El N° de calle solo acepta números",
    },
  },

  codigoPostal: {
    required: {
      value: true,
      message: "Código Postal es requerido",
    },
    pattern: {
      value: /^[0-9]{4}$/i,
      message: "El CP debe tener 4 dígitos",
    },
  },
  generic: {
    required: {
      value: true,
      message: "Este campo es requerido",
    },
    maxLength: {
      value: 200,
      message: "Este campo no puede superar los 100 caracteres",
    },
  },
  patente: {
    required: {
      value: true,
    },
    pattern: {
      value: /^([a-zA-Z0-9_-]){1,7}$/i,
      message: "Este campo es inválido",
    },
  },
  patenteNoRequiered: {
    required: {
      value: false,
    },
    pattern: {
      value: /^([a-zA-Z0-9_-]){1,7}$/i,
      message: "Este campo es inválido",
    },
  },
  observacion: {
    required: {
      value: false,
      message: "Este campo es requerido",
    },
    maxLength: {
      value: 60,
      message: "Este campo no puede superar los 60 caracteres",
    },
  },
  dni: {
    required: {
      value: true,
      message: "El campo DNI es requerido",
    },
    pattern: {
      value: /^[0-9]{5,8}$/i,
      message: "DNI sólo acepta números.",
    },
  },
  pasaporte: {
    required: {
      value: true,
      message: "El campo Pasaporte es requerido",
    },
    pattern: {
      value: /^[a-z]{3}[0-9]{6}$/i,
      message: "Pasaporte sólo acepta letras y números.",
    },
  },
  cuil: {
    required: {
      value: true,
      message: "El campo CUIL/CUIT es requerido",
    },
    pattern: {
      value: /^[0-9]{11}$/i,
      message: "CUIL/CUIT sólo acepta números.",
    },
  },
  cuilDni: {
    required: {
      value: true,
      message: "El campo es requerido",
    },
    pattern: {
      value: /^[0-9]{5,11}$/i,
      message: "CUIL o DNI inválido.",
    },
  },
  fechaNacimiento: {
    required: {
      value: true,
      message: "El campo Fecha de Nacimiento es requerido",
    },
    // pattern: {
    //   value: /^\d{2}([.//])\d{2}\1\d{4}$/,
    //   message: "El formato de la fecha es inválido",
    // },
  },
  fechaPago: {
    required: {
      value: true,
      message: "El campo Fecha de Pago es requerido",
    },
  },
  fechaPagoNoRequered: {
    required: {
      value: false,
    },
  },
  genericNoRequered: {
    required: {
      value: false,
    },
  },
  calle: {
    required: {
      value: true,
      message: "Este campo es requerido",
    },
    pattern: {
      value: /^[0-9 a-z áéíóúñ.,]+$/i,
      message: "Este campo solo acepta números y/o letras",
    },
  },
  numberNoRequered: {
    required: {
      value: false,
    },
    maxLength: {
      value: 10,
      message: "Este campo no puede superar los 10 caracteres de longitud",
    },
  },
  numero: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9]+$/i,
      message: "Este campo solo acepta números",
    },
  },
  numeroDecimal: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9]+([.|,][0-9]+)?$/i,
      message: "Ingrese un número mayor o igual a 0",
    },
  },
  numeroDecimalRequerido: {
    required: {
      value: true,
      message: "Este campo es requerido",
    },
    pattern: {
      value: /^[0-9]+([.|,][0-9]+)?$/i,
      message: "Ingrese un número mayor o igual a 0",
    },
  },
  numPiso: {
    required: {
      value: false,
    },
    maxLength: {
      value: 2,
      message: "Piso válido hasta 2 dígitos",
    },
    pattern: {
      value: /^[0-9]+$/i,
      message: "Este campo solo acepta números",
    },
  },
  numNupciasNoRequered: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9]{1,2}$/i,
      message: "Número de Nupcias entre 0 y 99.",
    },
  },
  telefono: {
    required: {
      value: true,
      message: "El campo Teléfono es requerido",
    },
    pattern: {
      value: /^[0-9]{10,12}$/i,
      message: "Teléfono sólo acepta números de 10 a 12 dígitos.",
    },
  },
  telefonoNoRequired: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9]{10,12}$/i,
      message: "Teléfono/Celular sólo acepta números de 10 a 12 dígitos",
    },
  },
  celular: {
    required: {
      value: true,
      message: "El campo Celular es requerido",
    },
    pattern: {
      value: /^[0-9]{10,12}$/i,
      message: "Teléfono/Celular sólo acepta números de 10 a 12 dígitos",
    },
  },
  celularNoRequired: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9]{10,12}$/i,
      message: "Celular sólo acepta números de 10 a 12 dígitos.",
    },
  },
  numbcias: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9\b]+$/i,
      message: "Nupcias inválido.",
    },
  },
  NoRequered: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9\b]+$/i,
      message: "Este campo solo admite números",
    },
  },
  folio: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9]{3}$/i,
      message: "Folio inválido. Ej: 001, 024",
    },
  },
  datosCasa: {
    required: {
      value: false,
    },
    pattern: {
      value: /^[0-9 a-z]+$/i,
      message: "Este campo solo acepta números y/o letras",
    },
  },
  porcentajeRequerido: {
    required: {
      value: true,
    },
    pattern: {
      value: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i,
      message: "Este campo solo acepta valores entre 0 y 100",
    },
  },
  numberRequered: {
    required: {
      value: true,
      message: "Este campo es requerido",
    },
    pattern: {
      value: /^[0-9]+$/i,
      message: "Este campo solo acepta números",
    },
  },
};

export default rules;
