import { useQuery } from "react-query";
import formulaService from "services/formula.service";

// FUNCION PARA TRAER LISTA DE REMITOS
const getFormulas = async () => {
  try {
    const res = await formulaService.getFormulas();
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useFormulas() {
  return useQuery(["getFormulas"], () => getFormulas({}), {
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });
}
