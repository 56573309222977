const { styled, IconButton } = require("@material-ui/core");

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: "0.5rem",
  width: "1.5rem",
  height: "1.5rem",
  borderRadius: 5,
  backgroundColor: theme.palette.primary.main,
  color: "#ffffff",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "&:disabled": {
    backgroundColor: theme.palette.grey[400],
    color: "#ffffff",
  },
  "&.Mui-disabled": {
    pointerEvents: "auto",
  },
}));

export default StyledIconButton;
