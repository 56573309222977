import { useQuery } from "react-query";
//SERVICES
import facturacionServices from "services/facturacion.services";

// FUNCION PARA TRAER LISTA DE RESUMENES DEL CLIENTE
const getSummariesClientId = async ({
  idClient,
  filtro,
  isNotificationsEnabled
}) => {
  try {
    //check endpoint to make request
    const endpoint =  isNotificationsEnabled ? `listarResumenes` : `listarLiquidaciones`;
    const res = await facturacionServices[endpoint](idClient, filtro);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useGetSummariesClientId({
  idClient,
  filtro,
  isNotificationsEnabled
}) {
  return useQuery(
    ["ResumenesDelCliente", idClient,
      filtro],
    () =>
      getSummariesClientId({
        idClient,
        filtro,
        isNotificationsEnabled
      }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );
}

// FUNCION PARA TRAER LISTA DE REMITOS PARA PREVISUALIZAR RESUMENES
const getGetPreviewsummary = async ({
  idClient,
  filtro,
  excludedIds,
  isNotificationsEnabled
}) => {
  try {
    //Generating URL Parameters:
    const params = new URLSearchParams();
    params.append('id', idClient);
    params.append('page', 1);
    params.append('fecha', `${filtro.fecha_desde}|${filtro.fecha_hasta}`);
    params.append('condicion', `${filtro.condicion}`);
    params.append('dias', `${filtro.dias}`);
    params.append('formula', `${filtro.formula}`);
    params.append('obra', `${filtro.obra}`);
    for (const id of excludedIds) {
      params.append('remitos_excluidos', id);
    }
    // verifico isNotificationEnabled (si esta habilitado Ctrl+Alt+K  false='remitosB')
    const endpoint = isNotificationsEnabled ? `vistaPreviaResumen` : `vistaPreviaResumenLiquidacion`;
    const res = await facturacionServices[endpoint](params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useGetPreviewsummary({
  idClient,
  filtro,
  excludedIds,
  checkTodosLosRemitos,
  isNotificationsEnabled
}) {
  return useQuery(
    ["VistaPreviaResumen", idClient,
      filtro, excludedIds],
    () =>
      getGetPreviewsummary({
        idClient,
        filtro,
        excludedIds,
        isNotificationsEnabled
      }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      enabled: checkTodosLosRemitos
    },
  );
}