import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";

import LoadRoutes from "../config/LoadRoutes";

import HeaderAuth from "./HeaderDrawerAuth";
import Footer from "./footer";
import ListDrawer from "./ListDrawer";
import { LogIn } from "context/LogInContext";
import { ToggleDrawerContext } from "context/ToggleDrawerContext";

const drawerWidth = 220;
const drawerWidthInClose = 52;

const styles = (theme) => ({
  root: {
    backgroundColor: "#000838",
    flex: 1,
  },
  rootClose: {
    marginLeft: 0,
  },
  drawer: {
    maxWidth: drawerWidth,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    marginTop: "4rem",
    width: drawerWidth,
    backgroundColor: "#000838",
  },
  drawerClose: {
    marginTop: "4rem",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    maxWidth: drawerWidthInClose,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: "#000838",
  },
  content: {
    backgroundColor: "#F0F0F0",
    flexGrow: 1,
    p: 1,
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
  },
});

const MiniDrawer = (props) => {
  const { logged, activateLogged } = useContext(LogIn);
  const { classes, routes } = props;
  const { drawer, setDrawer } = useContext(ToggleDrawerContext);

  //checket login in false
  useEffect(() => {
    if (!localStorage.getItem("logeado")) {
      activateLogged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("logeado")]);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootClose]: !logged,
      })}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
        flexDirection: " column",
        boxSizing: "content-box",
      }}
    >
      {logged && (
        <>
          <HeaderAuth open={drawer} setOpen={setDrawer} />
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: drawer,
              [classes.drawerClose]: !drawer,
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: drawer,
                [classes.drawerClose]: !drawer,
              }),
            }}
            open={drawer}
          >
            <ListDrawer setOpen={setDrawer} open={drawer} />
          </Drawer>
        </>
      )}

      {logged ? (
        <>
          <div className={classNames(classes.content)}>
            <LoadRoutes routes={routes} />
          </div>
          <Footer />
        </>
      ) : (
        <LoadRoutes routes={routes} />
      )}
    </div>
  );
};

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default React.memo(withStyles(styles, { withTheme: true })(MiniDrawer));
