import React, { useState, useEffect, useContext } from "react";
import Protected from "protected";
import {
  Grid,
  Typography,
  IconButton,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import Theme from "theme";
import useStyles from "./remitos-por-cliente/styles";
import RootContainer from "layout/rootContainer";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import { NotificationContext } from "context/notification/NotificationContext";
import { useHistory } from "react-router-dom";
import { LogIn } from "context/LogInContext";
import fileDownload from "js-file-download";
import remitosService from "services/remitos.service";
import Pagination from "@material-ui/lab/Pagination";

// SERVICES
import UsuarioService from "services/usuario.service";
import RemitosService from "services/remitos.service";

// COMPONENTS
import ModalAnularRemito from "components/remitos/modalAnularRemito";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import SearchBar from "components/Layout/searchBar/SearchBar";

//ICONS
import VisibilityIcon from "@material-ui/icons/Visibility";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Create from "@material-ui/icons/Create";
import { Delete } from "@material-ui/icons";
import ExcelIcon from "icons/excel";
import LoadingText from "components/loadingText";
import { useDebounce } from "hook/useDebounce";
import { shallow } from "zustand/shallow";
import {
  useObra,
  useOrderBy,
  useRemitoActions,
  useRemitoStore,
  useTextSelected,
  useRef,
} from "./store";
import { useFormulas } from "./hook";

//constants
const SORT_TYPE = Object.freeze({
  SORT_DATE: Symbol(),
  SORT_INVOICE: Symbol(),
  SORT_QUANTITY: Symbol(),
  SORT_CONSTRUCTION: Symbol(),
});

const Remitos = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { logged } = useContext(LogIn);
  const { isNotificationsEnabled } = useContext(NotificationContext);

  //Defino estado para los filtros
  const [filtrado, setFiltrado] = useState([]);
  const [remitoSeleccionado, setRemitoSeleccionado] = useState();
  const [modalAnularRemitoVisibility, setModalAnularRemitoVisibility] =
    useState(false);

  // eslint-disable-next-line no-unused-vars
  const [remitos, setRemitos] = useState([]);
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  const [actual, setActual] = useState();
  const [total, setTotal] = useState();

  // DECLARACION DE ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  // state manager with zustand
  const filtro = useRemitoStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      todo: state.todo,
      estado: state.estado,
      condicion: state.condicion,
      excedente: state.excedente,
      dias: state.dias,
      formula: state.formula,
      ordremi: state.ordremi,
      ordfecha: state.ordfecha,
      ordmts: state.ordmts,
      ordobra: state.ordobra,
      page: state.page,
    }),
    shallow,
  );

  const selectTexto = useTextSelected();
  const searchText = useObra();
  const orderBy = useOrderBy();
  const ref = useRef();
  const minDate = filtro?.fecha_desde;
  const maxDate = filtro?.fecha_hasta;

  //Actions with zustand
  const {
    setFechaDesde,
    setFechaHasta,
    setObras,
    setCondicion,
    setEstado,
    setExcedente,
    setFormula,
    setTodo,
    setDias,
    setSelectTexto,
    increasePage,
    decreasePage,
    resetPage,
    setOrdFecha,
    setOrdRemi,
    setOrdmts,
    setOrdObras,
    toggleSort,
    reset,
    setRef,
    setPage,
  } = useRemitoActions();

  // useDebounce hook for input text search
  const debouncedSearch = useDebounce(searchText, 200);

  // FUNCION PARA IR PAGINA SIGUIENTE
  const nextPage = async () => {
    if (filtro.page >= total) return;
    increasePage();
  };

  // FUNCION PARA IR PAGINA ANTERIOR
  const previousPage = () => {
    if (filtro.page <= 1) return;
    decreasePage();
  };

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // VERIFICAR ACCESOS A MODULO
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await UsuarioService.checkAccess({ modulo: "remitos" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "remitos" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "remitos" });
    }
  };

  // FUNCION PARA TRAER LISTA DE REMITOS
  const obtenerRemitos = async (search) => {
    try {
      if (isNotificationsEnabled) {
        const res = await RemitosService.listarRemitosPorFecha(
          filtro.page,
          minDate,
          maxDate,
          search.estado,
          search.condicion,
          search.dias,
          search.excedente,
          search.formula,
          debouncedSearch,
          search.ordremi,
          search.ordfecha,
          search.ordmts,
          search.ordobra,
        );
        if (res.status === 200) {
          return res.data;
        }
      } else {
        const res = await RemitosService.listarRemitosBPorFecha(
          filtro.page,
          minDate,
          maxDate,
          search.estado,
          search.condicion,
          search.dias,
          search.formula,
          debouncedSearch,
          search.ordremi,
          search.ordfecha,
          search.ordmts,
          search.ordobra,
        );
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      return error;
    }
  };

  // QUERY PARA OBTENER REMITOS DE CLIENTES
  // eslint-disable-next-line no-unused-vars
  const { data: ArrayRemitos, isLoading } = useQuery(
    ["Remitos", filtro, isNotificationsEnabled, debouncedSearch],
    () => obtenerRemitos(filtro),
    {
      onSuccess: (data) => {
        setRemitos(data.results);
        setFiltrado(data.results);
        // Seteo los datos del paginado
        setActual(data.actual);
        setTotal(data.total_paginas);
      },
      onError: (error) => {
        console.log("Error", error);
      },
      refetchInterval: false, //si estamos mucho tiempo en un mismo sitio, refresca la pagina cada cierto tiempo por si hay cambios nuevos
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false, //para que no se actualice la pagina automaticamente al hacer foco en ella
    },
  );

  // FUNCION CONTROL FILTROS MOSTRAR POR
  const mostrarPor = async (search) => {
    switch (search) {
      case "Sin Factura":
        setEstado("Sin Factura");
        break;
      case "A Facturar":
        setEstado("A Facturar");
        break;
      case "Facturado":
        setEstado("Facturado");
        break;
      case "Excedente":
        setExcedente(true);
        break;
      case "Pagado":
        setCondicion("Pagado");
        break;
      case "A Cobrar":
        setCondicion("A Cobrar");
        break;
      case "Mayor90":
        setDias(moment().format("YYYY-MM-DD"));
        break;
      case "Todos":
        setTodo("Todos");
        break;
      case formulas?.map((item) => item.nombre === filtro).nombre:
        setFormula(filtro);
        break;
      default:
        setFormula(search);
    }
    resetPage();
  };

  const filtrarPorFecha = () => {
    setFechaDesde(minDate);
    setFechaHasta(maxDate);
    resetPage();
  };

  // ABRIR MODAL ANULAR REMITO
  const anularRemito = (remito) => {
    setRemitoSeleccionado(remito);
    setModalAnularRemitoVisibility(true);
  };

  // CERRAR MODAL ANULAR REMITO
  const handleCloseModalAnularRemito = () => {
    setModalAnularRemitoVisibility(false);
  };

  // EDITAR REMITO
  const editarRemito = (remito) => {
    history.push("/remitos/crear-remito", {
      remito,
      edicion: true,
    });
  };

  // FUNCION DE BUSCADOR POR OBRA
  const handleSearch = async (text) => {
    setObras(text);
    resetPage();
  };

  const ordenarPor = (criterio) => {
    const sortType = orderBy ? "asc" : "desc";
    switch (criterio) {
      case SORT_TYPE.SORT_DATE:
        setOrdFecha(sortType);
        break;
      case SORT_TYPE.SORT_INVOICE:
        setOrdRemi(sortType);
        break;
      case SORT_TYPE.SORT_QUANTITY:
        setOrdmts(sortType);
        break;
      case SORT_TYPE.SORT_CONSTRUCTION:
        setOrdObras(sortType);
        break;
      default:
        break;
    }
    toggleSort();
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  const descargarExcel = async () => {
    try {
      const res = await remitosService.descargarExcelRemitos({
        fecha_desde: minDate,
        fecha_hasta: maxDate,
      });
      if (res.status >= 200 && res.status < 300) {
        fileDownload(res.data, `remitos_${minDate}_${maxDate}.xlsx`);
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: "Error al descargar el archivo.",
      });
    }
  };

  // TRAER LISTADO DE FORMULAS
  const { data: formulas } = useFormulas();

  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries(["Remitos", filtro, isNotificationsEnabled]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationsEnabled]);

  useEffect(() => {
    console.log("ref", ref);
    if (ref !== "lista-remitos") {
      reset();
      setTimeout(() => {
        setRef("lista-remitos");
      }, 200);
      setObras("");
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{
              marginTop: "15rem",
            }}
          >
            <CircularProgress
              style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            {acceso && (
              <>
                <Grid
                  style={{
                    paddingTop: "1rem",
                    width: "95%",
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    item
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    <Grid
                      container
                      item
                      direction="row"
                      justifyContent="justify-content"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ maxHeight: "2rem" }}
                        onClick={() =>
                          history.push("/remitos/crear-remito", {})
                        }
                      >
                        crear Remito
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "none",
                          marginLeft: "1rem",
                          maxHeight: "2rem",
                        }}
                        onClick={() => descargarExcel()}
                        startIcon={<ExcelIcon />}
                      >
                        Descargar Excel
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    item
                    direction="row"
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <Grid
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "0.5rem" }}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Desde"
                          focused
                          defaultValue={minDate && minDate}
                          onChange={(e) =>
                            setFechaDesde(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            max: maxDate,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Hasta"
                          focused
                          defaultValue={maxDate && maxDate}
                          onChange={(e) =>
                            setFechaHasta(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            min: filtro?.fecha_desde,
                            max: moment().format("YYYY-MM-DD"),
                          }}
                        />
                      </Grid>
                      <Grid item style={{ marginRight: "0.5rem" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => filtrarPorFecha()}
                          style={{
                            textTransform: "capitalize",
                            maxHeight: "2rem",
                          }}
                        >
                          Filtrar
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent={"flex-end"}
                      alignContent="flex-start"
                      alignItems="center"
                      style={{ marginTop: "0.5rem" }}
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <Box>
                          <FormControl style={{ width: "190px" }}>
                            <Select
                              size="small"
                              variant="outlined"
                              value={selectTexto}
                              displayEmpty
                              renderValue={
                                selectTexto !== ""
                                  ? undefined
                                  : () => (
                                    <Placeholder>Mostrar por:</Placeholder>
                                  )
                              }
                              onChange={(e) => {
                                mostrarPor(e.target.value);
                                setSelectTexto(e.target.value);
                              }}
                            >
                              <MenuItem value={"Todos"}>Todos</MenuItem>
                              <MenuItem value={"Mayor90"}>
                                A cobrar mayor a 90 días
                              </MenuItem>
                              <MenuItem value={"Pagado"}>Pagados</MenuItem>
                              <MenuItem value={"A Cobrar"}>A cobrar</MenuItem>
                              <MenuItem value={"Sin Factura"}>
                                Sin Factura
                              </MenuItem>
                              <MenuItem value={"A Facturar"}>
                                A Facturar
                              </MenuItem>
                              <MenuItem value={"Facturado"}>Facturado</MenuItem>
                              <MenuItem value={"Excedente"}>Excedentes</MenuItem>
                              {formulas?.length > 0 &&
                                formulas?.map((item) => (
                                  <MenuItem value={item.id} key={item.id}>
                                    {item.nombre.toUpperCase()}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item>
                        <SearchBar
                          placeholder="Buscar por Obra"
                          defaultValue={searchText}
                          value={searchText}
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  style={{
                    maxWidth: "95%",
                    marginTop: "0.5rem",
                    marginBottom: "5rem",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    className={classes.headerTable}
                  >
                    <Typography
                      variant="h5"
                      className={classes.headerTableTittle}
                    >
                      Lista de Remitos
                    </Typography>
                  </Grid>
                  {isLoading ? (
                    <LoadingText title="Cargando Remitos" />
                  ) : (
                    <>
                      <TableContainer
                        component={Paper}
                        className={classes.tableContainer}
                      >
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow className="tableRow">
                              <TableCell
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  color: "rgba(67, 59, 59, 0.87)",
                                  minWidth: "10rem",
                                }}
                              >
                                Fecha
                                <IconButton
                                  onClick={() =>
                                    ordenarPor(SORT_TYPE.SORT_DATE)
                                  }
                                >
                                  <CompareArrowsIcon
                                    style={{
                                      width: "1rem",
                                      transform: "rotate(90deg)",
                                    }}
                                  />
                                </IconButton>
                              </TableCell>

                              <TableCell
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  color: "rgba(67, 59, 59, 0.87)",
                                  minWidth: "9rem",
                                  padding: 0,
                                }}
                              >
                                N° de Remito
                                <IconButton
                                  onClick={() =>
                                    ordenarPor(SORT_TYPE.SORT_INVOICE)
                                  }
                                >
                                  <CompareArrowsIcon
                                    style={{
                                      width: "1rem",
                                      transform: "rotate(90deg)",
                                    }}
                                  />
                                </IconButton>
                              </TableCell>

                              <TableCell
                                style={{
                                  paddingLeft: "0.5rem",
                                }}
                                className={classes.tableCellHeaders}
                              >
                                Tipo H
                              </TableCell>

                              <TableCell
                                style={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "16px",
                                  color: "rgba(67, 59, 59, 0.87)",
                                  minWidth: "8rem",
                                  textAlign: "left",
                                  padding: 0,
                                }}
                              >
                                Mts3
                                <IconButton
                                  onClick={() =>
                                    ordenarPor(SORT_TYPE.SORT_QUANTITY)
                                  }
                                >
                                  <CompareArrowsIcon
                                    style={{
                                      width: "1rem",
                                      transform: "rotate(90deg)",
                                    }}
                                  />
                                </IconButton>
                              </TableCell>

                              <TableCell
                                className={classes.tableCellHeaders}
                                style={{ paddingLeft: "0.5rem" }}
                              >
                                Cliente
                              </TableCell>

                              <TableCell
                                className={classes.tableCellHeaders}
                                style={{ paddingLeft: "0.5rem" }}
                              >
                                Obra
                                <IconButton
                                  onClick={() =>
                                    ordenarPor(SORT_TYPE.SORT_CONSTRUCTION)
                                  }
                                >
                                  <CompareArrowsIcon
                                    style={{
                                      width: "1rem",
                                      transform: "rotate(90deg)",
                                    }}
                                  />
                                </IconButton>
                              </TableCell>

                              <TableCell
                                style={{ paddingLeft: "0.5rem" }}
                                className={classes.tableCellHeaders}
                              >
                                Estado
                              </TableCell>

                              <TableCell
                                style={{ paddingLeft: "0.5rem" }}
                                className={classes.tableCellHeaders}
                              >
                                Condición
                              </TableCell>

                              <TableCell
                                align="center"
                                className={classes.tableCellHeaders}
                              >
                                Acciones
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {filtrado?.length > 1 &&
                            filtrado
                              .filter((item) => !item.total_mts)
                              .map((remito) => (
                                <TableBody>
                                  <TableRow
                                    hover
                                    key={remito.id}
                                    style={{
                                      backgroundColor: remito?.presupuesto
                                        ? "#E26E6E"
                                        : moment(remito.fecha) <
                                          moment().subtract(90, "days") &&
                                          remito.condicion === "A Cobrar"
                                          ? "#FBEF94"
                                          : "",
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        minWidth: "10rem",
                                      }}
                                    >
                                      {moment(remito?.fecha).format(
                                        "DD-MM-YYYY",
                                      ) ?? "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {remito?.nro_remito ?? "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {remito?.tipo ?? "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {remito?.cantidad_mc ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.cliente?.nombre?.toUpperCase() ??
                                        "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {remito?.obra?.toUpperCase() ?? "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {remito?.estado ?? "-"}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {remito?.condicion ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                      <Grid
                                        container
                                        item
                                        direction="row"
                                        justifyContent="center"
                                        style={{
                                          whiteSpace: "nowrap",
                                          minWidth: "8rem",
                                        }}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            history.push(
                                              `/remitos/visualizar/${remito.id}`,
                                              {
                                                presupuesto:
                                                  remito?.presupuesto,
                                              },
                                            )
                                          }
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",
                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <VisibilityIcon
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => anularRemito(remito)}
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",

                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <Delete
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => editarRemito(remito)}
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",
                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <Create
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              ))}
                        </Table>
                      </TableContainer>
                      {filtrado?.length > 1 ? (
                        <>
                          <Grid container justifyContent="center">
                            <Grid style={{ marginTop: "1rem" }}>
                              <Pagination
                                count={total}
                                color="primary"
                                onChange={(event, value) => setPage(value)}
                                page={filtro?.page}
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{
                              backgroundColor: "#E5E5E5",
                              color: "#433B3B",
                              width: "auto",
                              maxHeight: "100%",
                              marginRight: "2rem",
                              padding: "0.5rem 1rem",
                              borderRadius: "20px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "18px",
                                marginRight: "1rem",
                              }}
                            >
                              Total de M³:{" "}
                            </Typography>
                            <Typography
                              style={{ fontSize: "20px", fontWeight: 700 }}
                            >
                              {filtrado[filtrado.length - 1].total_mts}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          container
                          justifyContent="center"
                          style={{ margin: "0.5rem" }}
                        >
                          <Typography variant="subtitle1">
                            No se encontraron remitos con estas caracteristicas
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                <ModalAnularRemito
                  open={modalAnularRemitoVisibility}
                  handleClose={handleCloseModalAnularRemito}
                  remitoSeleccionado={remitoSeleccionado}
                />
              </>
            )}
          </>
        )}
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};
export default Remitos;
