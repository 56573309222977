/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import Protected from "protected";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import RootContainer from "layout/rootContainer";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import fileDownload from "js-file-download";
import { NotificationContext } from "context/notification/NotificationContext";
import remitosService from "services/remitos.service";
import FormulaService from "services/formula.service";
import UsuarioService from "services/usuario.service";
import { useHistory } from "react-router-dom";
import SearchBar from "components/Layout/searchBar/SearchBar";
import { LogIn } from "context/LogInContext";
import Theme from "theme";
import ExcelIcon from "icons/excel";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TotalMaterialMensual = () => {
  const classes = useStyles();
  const history = useHistory();
  const { logged } = useContext(LogIn);
  const { isNotificationsEnabled } = useContext(NotificationContext);

  const [maxDate, setMaxDate] = useState(moment().format("YYYY-MM-DD"));
  const [minDate, setMinDate] = useState(moment().format("YYYY-MM-01"));
  const [acceso, setAcceso] = useState(false);
  const [verificando, setVerificando] = useState(true);
  const [busqueda, setBusqueda] = useState("");
  const [data, setData] = useState([]);

  const [formulas, setFormulas] = useState([]);
  const [total, setTotal] = useState([]);

  const [materiales, setMateriales] = useState();

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  //funcion para traer todas las formulas que hay en la base de datos
  const obtenerFormulas = async () => {
    try {
      const res = await remitosService.getFormulas();
      setFormulas(res.data.results);
    } catch (error) {
      return error;
    }
  };

  const [remitos, setRemitos] = useState([]);

  // eslint-disable-next-line array-callback-return
  remitos?.map((unRemito) => {
    unRemito?.consumo?.sort(function (a, b) {
      if (a.subtipo > b.subtipo) {
        return 1;
      }
      if (a.subtipo < b.subtipo) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  });
  materiales?.sort(function (a, b) {
    if (a.subtipo > b.subtipo) {
      return 1;
    }
    if (a.subtipo < b.subtipo) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  //Traer todos los materiales de BD
  const obtenerMateriales = async () => {
    let dataSend = {
      fecha_desde: minDate,
      fecha_hasta: maxDate,
      fech: isNotificationsEnabled,
    };
    try {
      setAcceso(true);
      const res = await FormulaService.getConsumoMensual(dataSend);
      if (res.status > 199 && res.status < 300) {
        setAcceso(false);
        setMateriales(res.data.header);
        setRemitos(res.data.lista_remitos);
        setData(res.data);
        SumarCantidadesTotal(res.data.header, res.data.lista_remitos);
      }
    } catch (error) {
      setAcceso(false);
      setSnackState({
        open: true,
        severity: "error",
        message: "Error al obtener Materiales",
      });
      return error;
    }
  };

  const [totales, setTotales] = useState([]);

  //SUMA LA CANTIDAD DE TOTAL DE FORMULA DE TODOS LOS REMITOS QUE TIENEN LA MISMA
  const SumarCantidadesTotal = (losmateriales, losremitos) => {
    let cantidad = [];
    if (losmateriales && losremitos) {
      losmateriales?.forEach((unmaterial) => {
        let aux = 0;
        losremitos?.forEach((unremito) => {
          const encontrado = unremito?.consumo?.find(
            (element) => element?.subtipo === unmaterial?.subtipo,
          );
          if (encontrado) {
            aux += encontrado?.cantidad;
          }
        });
        cantidad?.push({ subtipo: unmaterial?.subtipo, total: aux });
      });
    }

    setTotales(cantidad);
  };
  // VERIFICAR ACCESOS A MODULO
  const verificarAcceso = async () => {
    try {
      const res = await UsuarioService.checkAccess({ modulo: "remitos" });
      if (res.status === 200) {
        setVerificando(false);
        // setAcceso(true);
      } else {
        // setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "remitos" });
      }
    } catch (error) {
      // setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "remitos" });
    }
  };

  const handleBusqueda = async (text) => {
    let search = text.toLowerCase();
    let result = [];
    if (text.length > 0) {
      result = data?.lista_remitos?.filter((remito) =>
        remito?.remito?.cliente?.toLowerCase().includes(search),
      );
      setRemitos(result);
      SumarCantidadesTotal(data?.header, result);
    } else {
      setRemitos(data?.lista_remitos);
      SumarCantidadesTotal(data?.header, data?.lista_remitos);
    }
  };

  const descargarExcel = async () => {
    try {
      const res = await remitosService.descargarExcelConsumos({
        fecha_desde: minDate,
        fecha_hasta: maxDate,
      });
      if (res.status >= 200 && res.status < 300) {
        fileDownload(res.data, `consumos_totales_${minDate}_${maxDate}.xlsx`);
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: "Error al descargar el archivo.",
      });
    }
  };

  useEffect(() => {
    obtenerMateriales();
    obtenerFormulas();
  }, [isNotificationsEnabled]);

  useEffect(() => {
    handleBusqueda(busqueda);
  }, [busqueda]);

  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{
              marginTop: "15rem",
            }}
          >
            <CircularProgress
              style={{
                color: Theme.palette.primary.main,
                marginRight: "1rem",
              }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            <div
              style={{
                paddingRight: "1rem",
                width: "95%",
                height: "100%",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                style={{ marginTop: "3rem" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <TextField
                    item
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Fecha Desde"
                    style={{ marginRight: "1rem", marginBottom: "1rem" }}
                    onChange={(e) =>
                      setMinDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                    inputProps={{
                      max: maxDate,
                    }}
                    defaultValue={moment().format("YYYY-MM-01")}
                  />
                  <TextField
                    item
                    size="small"
                    variant="outlined"
                    style={{ marginRight: "1rem", marginBottom: "1rem" }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Fecha Hasta"
                    onChange={(e) =>
                      setMaxDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                    defaultValue={moment().format("YYYY-MM-DD")}
                    inputProps={{
                      min: minDate,
                      max: moment().format("YYYY-MM-DD"),
                    }}
                  />

                  <Button
                    item
                    xs={1}
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#001176",
                      maxHeight: "2rem",
                      textTransform: "capitalize",
                      marginBottom: "1rem",
                    }}
                    onClick={() => obtenerMateriales()}
                  >
                    Filtrar
                  </Button>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <Grid style={{ marginBottom: "1rem" }}>
                    <SearchBar
                      placeholder="Ingrese Nombre del Cliente"
                      onChange={(e) => handleBusqueda(e.target.value)}
                    />
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      marginLeft: "1rem",
                      marginBottom: "1rem",
                      maxHeight: "2rem",
                    }}
                    onClick={() => descargarExcel()}
                    startIcon={<ExcelIcon />}
                  >
                    Descargar Excel
                  </Button>
                </Grid>
              </Grid>
              <Grid container item className={classes.generalContainer}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  className={classes.headerTable}
                >
                  <Typography
                    variant="h5"
                    className={classes.headerTableTittle}
                  >
                    Consumo de Materiales
                  </Typography>
                </Grid>

                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader size="small">
                    <TableHead style={{ maxHeight: "5vh" }}>
                      <TableRow>
                        <TableCell width={"8%"}>Fecha </TableCell>
                        <TableCell width={"8%"}>Remito</TableCell>
                        <TableCell
                          width={"15%"}
                          style={{
                            zIndex: 1000,
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            position: "sticky",
                            left: 0,
                            borderRight: "1px solid grey",
                          }}
                        >
                          Cliente
                        </TableCell>
                        <TableCell>Fórmula</TableCell>

                        {materiales?.map((material) => (
                          <TableCell
                            key={material?.subtipo}
                            style={{ minWidth: "12rem" }}
                          >
                            {material?.subtipo}{" "}
                            <span
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              ({material?.unidad})
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {acceso ? (
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                        style={{
                          marginTop: "1rem",
                        }}
                      >
                        <CircularProgress
                          size="2rem"
                          style={{
                            color: Theme.palette.primary.main,
                            marginRight: "1rem",
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: "#000000" }}
                        >
                          Cargando...
                        </Typography>
                      </Grid>
                    ) : (
                      <TableBody>
                        {remitos?.map((item, index) => (
                          <StyledTableRow key={item.remito}>
                            <StyledTableCell className={classes.tableCell}>
                              {moment(item?.remito?.fecha).format("DD-MM-YYYY")}
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableCell}>
                              {item.remito?.nro_remito}
                            </StyledTableCell>

                            <StyledTableCell
                              className={classes.tableCell}
                              style={{
                                textAlign: "left",
                                whiteSpace: "nowrap",
                                position: "sticky",
                                left: 0,
                                backgroundColor:
                                  index % 2 === 0 ? "#F5F5F5" : "#FFFFFF",
                                borderRight: "1px solid grey",
                              }}
                            >
                              {item?.remito?.cliente?.toUpperCase()}
                            </StyledTableCell>
                            <StyledTableCell
                              className={classes.tableCell}
                              style={{ minWidth: "12rem" }}
                            >
                              {item?.remito?.formula}
                            </StyledTableCell>
                            {item?.consumo?.map((consumo) => {
                              return (
                                <StyledTableCell
                                  className={classes.tableCell}
                                  style={{
                                    fontWeight:
                                      consumo?.cantidad &&
                                      consumo?.cantidad > 0 &&
                                      "bold",
                                  }}
                                >
                                  {consumo?.cantidad?.toString()}
                                </StyledTableCell>
                              );
                            })}
                          </StyledTableRow>
                        ))}
                        {(!remitos || remitos?.length < 1) && (
                          <Grid container>
                            <Typography
                              style={{ margin: "1rem", width: "12rem" }}
                              variant="body2"
                            >
                              No se encontraron resultados
                            </Typography>
                          </Grid>
                        )}
                        <StyledTableRow
                          className={classes.totalRow}
                          style={{
                            width: "100%",
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                            scroll: "auto",
                            backgroundColor: "#CCCFE4",
                            bottom: 0,
                            position: "sticky",
                          }}
                        >
                          <StyledTableCell
                            className={classes.totalCell}
                            style={{
                              minWidth: "10rem",
                              maxWidth: "12rem",
                              zIndex: 1000,
                              whiteSpace: "nowrap",
                              left: 0,
                              backgroundColor: "#CCCFE4",
                            }}
                          ></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell
                            className={classes.totalCell}
                            style={{
                              color: "black",
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#CCCFE4",
                              minWidth: "12rem",
                            }}
                          >
                            TOTAL del período
                          </StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          {totales?.length > 0 &&
                            totales?.map((material) => (
                              <StyledTableCell
                                key={material?.subtipo}
                                width="3%"
                                style={{
                                  color: "black",
                                  fontSize: "18px",
                                  fontWeight: 700,
                                }}
                              >
                                {Math.round(material?.total)}
                              </StyledTableCell>
                            ))}
                        </StyledTableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          </>
        )}
      </RootContainer>
    </Protected>
  );
};
export default TotalMaterialMensual;
