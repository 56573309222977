import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Typography, Modal, Grid, Button, TextField } from "@material-ui/core";
import Theme from "theme";
import FormulaService from "services/formula.service";
import ConfirmDialog from "components/ConfirmDialog";
import { makeStyles } from "@material-ui/core/styles";
import MaterialesCard from "components/editarFormulaModal/materialesCard";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import { useQuery, useQueryClient } from "react-query";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "auto",
    height: "auto",
    overflow: "auto",
  },
}));

const CreateFormulaModal = ({
  open,
  handleClose,
  editar,
  dataFormula,
  setSnackBar,
}) => {
  const queryClient = useQueryClient();

  const [verificado, setVerificado] = useState(true);
  const [sinStackear, setSinStackear] = useState();
  const [stackeado, setStackeado] = useState([]);
  const classes = useStyles();
  // INICIO DECLARACIONES DE LIBRERIA ENCARGADA DE VALIDACIONES
  const { handleSubmit } = useForm({
    defaultValues: {
      nombre: dataFormula ? dataFormula?.nombre : "",
    },
  });

  const [nombre, setNombre] = useState(editar ? dataFormula?.nombre : "");
  const [cargando, setCargando] = useState(false);
  const [materiales, setMateriales] = useState([]);
  const [opcionesMateriales, setOpcionesMateriales] = useState([]);
  const [habilitarBotonAgregar, setHabilitarBotonAgregar] = useState(true);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  //
  const limpiar = () => {
    setVerificado(true);
    setSinStackear();
    setStackeado([]);
  };

  const stackear = () => {
    setStackeado([
      ...stackeado,
      {
        //formula: dataFormula?.id,
        tipo: sinStackear.tipo,
        subTipo: sinStackear.subTipo,
        cantidad: sinStackear.cantidad,
        unidad: sinStackear.unidad,
      },
    ]);
    setMateriales([...materiales, ...stackeado]);
  };

  const agregarFormula = () => {
    setHabilitarBotonAgregar(false);
    if (sinStackear && verificado) {
      stackear();
    }
    setMateriales([
      ...materiales,
      {
        //formula:dataFormula?.id,
        tipo: null,
        subtipo: null,
        cantidad: null,
        unidad: null,
      },
    ]);
  };

  // Funcion para Guardar obejeto a BD
  const onSubmit = async (data) => {
    if (verificado && sinStackear) {
      stackeado.push(sinStackear);
    }

    let sendData = [];
    await stackeado.forEach((item) => {
      sendData?.push({
        tipo: item?.tipo,
        subtipo: item?.subTipo,
        cantidad: item?.cantidad,
        unidad: item?.unidad,
        formula: dataFormula?.id,
      });
    });

    let arrayMateriales = { dataMateriales: sendData };
    const body = new FormData();
    body.append("nombre", nombre.toUpperCase());
    body.append("materiales", JSON.stringify(arrayMateriales));

    try {
      setCargando(true);
      let res;
      if (!editar) {
        res = await FormulaService.addMaterial(body);
      } else {
        //editar material nombre y valores
        res = await FormulaService.editFormulaNombre(dataFormula.id, body);
      }
      if (res.status > 199 && res.status < 300) {
        setSnackBar({
          open: true,
          severity: "success",
          message: editar
            ? `Formula Editada Correctamente`
            : `Fórmula Creada correctamente`,
        });
      } else {
        setCargando(false);
        setSnackBar({
          open: true,
          severity: "error",
          message: editar
            ? `Error en la Edición de Fórmula`
            : `Error en la Creación de Fórmula`,
        });
      }
      queryClient.invalidateQueries("formulas");
    } catch (error) {
      setCargando(false);

      setSnackBar({
        open: true,
        severity: "error",
        message: error?.response?.data?.Error ?? "Error del Servidor",
      });
    }

    limpiar();
    handleClose();
  };

  const obtenerMaterialesFormula = async () => {
    try {
      const res = await FormulaService.obtenerMateriales(dataFormula?.id);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  // Query para actualizar Mariales despues de Borrar y Editar
  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery("MaterialesDeFormula", obtenerMaterialesFormula, {
    onSuccess: (data) => {
      if (data?.length > 0) {
        setMateriales(data);
      } else {
        setMateriales([]);
      }
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  // Función para traer lista de Materiales
  const obtenerMateriales = async () => {
    try {
      const res = await FormulaService.getMateriales();
      setOpcionesMateriales(res.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    obtenerMateriales();
  }, []);

  return (
    <>
      <Modal open={open} className={classes.paper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            xl={6}
            lg={8}
            md={10}
            sm={10}
            xs={11}
            style={{
              backgroundColor: "#ffffff",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: 10,
              overflow: "auto",
            }}
          >
            <Grid
              container
              item
              justifyContent="center"
              alignContent="center"
              style={{
                backgroundColor: Theme.palette.primary.main,
                height: "3rem",
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
              }}
            >
              {editar ? (
                <Typography variant="h5" style={{ color: "#ffffff" }}>
                  Modificar Fórmula
                </Typography>
              ) : (
                <Typography variant="h5" style={{ color: "#ffffff" }}>
                  Crear Fórmula
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction="column"
              container
              justifyContent="flex-start"
              style={{
                marginLeft: "3rem",
                marginTop: "1rem",
              }}
            >
              <Typography item>Nombre de Fórmula:</Typography>

              <TextField
                item
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={12}
                name="nombre"
                label="Nombre"
                variant="outlined"
                size="small"
                autoComplete="off"
                value={nombre}
                required
                type={"text"}
                style={{ width: "80%", marginTop: "1rem" }}
                onChange={(e) => (
                  // eslint-disable-next-line no-sequences
                  setNombre(e.target.value), setVerificado(true)
                )}
              />
            </Grid>

            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction="row"
              container
              justifyContent="flex-start"
              style={{
                marginLeft: "3rem",
                marginTop: "1rem",
              }}
            >
              <Typography>Lista de Materiales:</Typography>
            </Grid>
            {/*  Llamada al card de materiales*/}
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction="row"
              style={{ marginLeft: "3rem" }}
            >
              {materiales?.length > 0 &&
                materiales.map(
                  (elementos) =>
                    opcionesMateriales.length && (
                      <MaterialesCard
                        key={elementos.id}
                        setSinStackear={setSinStackear}
                        setVerificado={setVerificado}
                        setHabilitarBotonAgregar={setHabilitarBotonAgregar}
                        materiales={elementos}
                        opcionesChoices={opcionesMateriales}
                        cerrarModal={handleClose}
                      />
                    ),
                )}
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-start"
              style={{
                marginLeft: "3rem",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={!habilitarBotonAgregar}
                onClick={() => agregarFormula()}
              >
                Agregar Material
              </Button>
            </Grid>
            <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                color="primary"
                variant="outlined"
                size="small"
                style={{ marginRight: "0.5rem", marginTop: "1rem" }}
                onClick={() => {
                  queryClient.invalidateQueries("Formulas");
                  handleClose();
                  limpiar();
                }}
              >
                Volver
              </Button>
              <ButtonLoading
                type="submit"
                disabled={!verificado || cargando ? true : false}
                isLoading={cargando}
                style={{ marginRight: "0.5rem", marginTop: "1rem" }}
              >
                Guardar
              </ButtonLoading>
            </Grid>

            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Grid>
        </form>
      </Modal>
    </>
  );
};

export default CreateFormulaModal;
