import { useQuery } from "react-query";

// Services
import clientesService from "services/clientes.service";

export const useGetAllClientes = (filters) => {
  // FUNCION PARA TRAER TODOS LOS CLIENTES SIN PAGINADO
  const obtenerClientes = async () => {
    const { data } = await clientesService.getAllClientes();
    return data;
  };

  // FUNCION PARA FILTRAR LOS CLIENTES POR NOMBRE Y CUIT/DNI
  const filtrarClientes = (clientes) => {
    if (filters) {
      return clientes.filter(
        (unCliente) =>
          unCliente.nombre.toLowerCase().includes(filters.toLowerCase()) ||
          unCliente.cuil_dni.includes(filters),
      );
    } else {
      return clientes;
    }
  };

  return useQuery(["clientes"], () => obtenerClientes(), {
    select: (clientes) => filtrarClientes(clientes),
  });
};
