/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import { useState } from "react";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useCallback } from "react";

// MATERIAL UI
import {
  Button,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";

// ICONS
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';

// COMPONENTS
import Card from "components/card";
import VerificarAcceso from "components/verificarAcceso";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import HistoricoPrecios from "./historicoPrecios";
import mostrarErrorEP from "constants/funciones/mostrarError";
import Imprimir from "./components/imprimir";

//SERVICES
import ordenesService from "services/ordenes.service";

// VARIOS
import { LogIn } from "context/LogInContext";
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import Theme from "theme";
import moment from "moment";
import VisualizarFactura from "screens/facturacion/visualizar";
import DetallePago from "./components/detallePago";
import { useToggle } from "hook/useToogle";
import ModalCambiarHormigon from "./modalCambiarHormigon";

const VerOrden = () => {
  // Trae los datos de la pantalla anterior
  const history = useHistory();

  // Trae el id sin tener que usar el history
  const { id } = useParams();

  // Obtiene datos del usuario que ingresó, para verificar si tiene acceso al modulo
  const { logged } = useContext(LogIn);

  // Para identificar los componentes a imprimir
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  // ESTADOS
  // ==========================================================================
  // Para verificar acceso de un usuario al modulo
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // Estado para mostrar mensaje de éxito, error o alerta en la parte inferior del sistema
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // Para modal HistoricoPrecios
  const [modalHistoricoPrecios, setModalHistoricoPrecios] = useState(false);

  // Para modal Cambiar Hormigón
  const [modalCambiarHormigon, setModalCambiarHormigon] = useState(false);

  const [ordenSeleccionada, setOrdenSeleccionada] = useState(null);

  const [openDetallePago, toogleOpenDetallePago] = useToggle();

  // Para capturar la orden
  const [orden, setOrden] = useState(null);

  // Para ver una factura
  const [facturaId, setFacturaId] = useState(null);
  const [openVisualizarFactura, setOpenVisualizarFactura] = useState(false);
  // ==========================================================================

  // FUNCIONES CALLBACK
  // ==========================================================================
  // Para obtener el contenido a imprimir
  const handleOnBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      resolve();
    });
  }, []);

  // Para imprimir el contenido
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  // ==========================================================================

  // FUNCIONES FLECHA
  // ==========================================================================
  // Estado para cerrar mensaje de éxito, error o alerta
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Para calcular el total de la orden
  const calcularTotalOrden = () => {
    const resultado = orden?.detalle_orden?.reduce(
      (acumulador, itemActual) =>
        acumulador +
        Number(
          (itemActual?.cantidad_m3 * itemActual?.precio_actual).toFixed(2),
        ),
      0,
    );

    return Number(resultado.toFixed(2));
  };
  // ==========================================================================

  // Estado para ejecutar la acción de imprimir
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });

  // ENDPOINTS
  // ==========================================================================
  //  Para obtener la orden
  const obtenerOrden = async () => {
    try {
      const { data, status } = await ordenesService.verOrden(id);
      if (status > 199 && status < 300) {
        setOrden(data);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };
  // ==========================================================================

  // FUNCIONES EFFECT: Función que se ejecutará cada vez que nuestro componente se renderice
  // ==========================================================================
  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  useEffect(() => {
    obtenerOrden();
  }, []);
  // ==========================================================================

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="orden-compra"
            history={history}
            logged={logged}
          />
        ) : acceso && !openVisualizarFactura ? (
          <Grid
            container
            style={{
              padding: "1rem 0 5rem 2rem",
              width: "95%",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: "1rem 0" }}
            >
              <Typography
                style={{
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#000000",
                  paddingLeft: "1rem",
                }}
              >
                Visualizar Orden de Compra
              </Typography>
              <Grid>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  children="Imprimir"
                  style={{
                    textTransform: "capitalize",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<PrintIcon />}
                  onClick={handlePrint}
                />
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    <Typography variant="body2" align="center">
                      Esta orden no tiene formas de pago cargadas
                    </Typography>
                  }
                  disableFocusListener={!!orden?.formas_pago}
                  disableHoverListener={!!orden?.formas_pago}
                  disableTouchListener={!!orden?.formas_pago}
                >
                  <span>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disabled={!orden?.formas_pago}
                      style={{ textTransform: "none", marginRight: "0.5rem" }}
                      onClick={() => toogleOpenDetallePago()}
                    >
                      Detalle de Pago
                    </Button>
                  </span>
                </Tooltip>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "none" }}
                  onClick={() => history.goBack()}
                >
                  Volver
                </Button>
              </Grid>
            </Grid>

            <Card
              title={`ORDEN DE COMPRA Nº ${orden?.num_orden ?? "-"}`}
              justifyContent="center"
            >
              <Grid
                container
                style={{
                  padding: "1rem 3rem",
                }}
              >
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Cliente
                </Typography>

                <Grid container>
                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      Nombre
                    </Typography>
                    <Typography variant="body2">{orden?.cliente}</Typography>
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      Dirección
                    </Typography>
                    <Typography variant="body2">
                      {orden?.cliente_dir}
                    </Typography>
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      Ciudad
                    </Typography>
                    <Typography variant="body2">
                      {orden?.cliente_ciudad}
                    </Typography>
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      CUIL - CUIT
                    </Typography>
                    <Typography variant="body2">
                      {orden?.cliente_cuil}
                    </Typography>
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      Condición IVA
                    </Typography>
                    <Typography variant="body2">
                      {orden?.cliente_condicion}
                    </Typography>
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      Teléfono
                    </Typography>
                    <Typography variant="body2">
                      {orden?.cliente_telefono}
                    </Typography>
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      Fecha de Carga
                    </Typography>
                    <Typography variant="body2">
                      {orden?.fecha_carga}
                    </Typography>
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      Nº Factura
                    </Typography>
                    {orden?.factura_de_orden?.id_factura ? (
                      <Link
                        component="button"
                        variant="body2"
                        underline="hover"
                        target="_blank"
                        rel="noopener"
                        style={{ marginRight: "0.5rem" }}
                        onClick={() => {
                          setOpenVisualizarFactura(true);
                          setFacturaId(orden?.factura_de_orden?.id_factura);
                        }}
                      >
                        {orden?.factura_de_orden?.nro_factura}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </Grid>
                </Grid>

                <Grid container>
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    RESÚMENES ASOCIADOS
                  </Typography>
                </Grid>

                {orden?.resumenes_asociados?.length > 0 ? (
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            Nº RESUMEN
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            ESTADO
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            Nº FACTURA
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            CONDICIÓN
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {orden?.resumenes_asociados.map(
                          (resumen, indexResumen) => (
                            <TableRow key={indexResumen}>
                              <TableCell
                                align="left"
                                style={{
                                  padding: "0.5rem 0.5rem 0.5rem 0",
                                  borderBottom: 0,
                                }}
                              >
                                <Link
                                  component="button"
                                  variant="body2"
                                  underline="hover"
                                  target="_blank"
                                  rel="noopener"
                                  style={{ marginRight: "0.5rem" }}
                                  onClick={() =>
                                    history.push(
                                      `/remitos/remitos-por-cliente/resumen/${resumen?.nro_resumen}`,
                                    )
                                  }
                                >
                                  {resumen?.nro_resumen}
                                </Link>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: "0.5rem 0.5rem 0.5rem 0",
                                  borderBottom: 0,
                                }}
                              >
                                {resumen?.estado ?? "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: "0.5rem 0.5rem 0.5rem 0",
                                  borderBottom: 0,
                                }}
                              >
                                {resumen?.num_factura !== "-" ? (
                                  <Link
                                    component="button"
                                    variant="body2"
                                    underline="hover"
                                    target="_blank"
                                    rel="noopener"
                                    style={{ marginRight: "0.5rem" }}
                                    onClick={() => {
                                      setOpenVisualizarFactura(true);
                                      setFacturaId(resumen?.factura_id);
                                    }}
                                  >
                                    {resumen?.num_factura}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  padding: "0.5rem 0.5rem 0.5rem 0",
                                  borderBottom: 0,
                                }}
                              >
                                {resumen?.condicion ?? "-"}
                              </TableCell>
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    -
                  </Typography>
                )}

                <Grid container style={{ marginTop: "1rem" }}>
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    DETALLE DE LA ORDEN
                  </Typography>
                </Grid>
              </Grid>

              {orden?.detalle_orden?.length > 0 ? (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            padding: "0.5rem 0",
                            width: "4rem",
                            borderBottom: 0,
                          }}
                        />
                        <TableCell
                          align="left"
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            fontWeight: "bold",
                            borderBottom: 0,
                          }}
                        >
                          PRODUCTO/SERVICIO
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            fontWeight: "bold",
                            borderBottom: 0,
                          }}
                        >
                          CANTIDAD
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            fontWeight: "bold",
                            borderBottom: 0,
                          }}
                        >
                          M³ RESTANTES
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            fontWeight: "bold",
                            borderBottom: 0,
                          }}
                        >
                          PRECIO ORIGINAL
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            fontWeight: "bold",
                            borderBottom: 0,
                          }}
                        >
                          PRECIO ACTUAL
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            fontWeight: "bold",
                            borderBottom: 0,
                          }}
                        >
                          FECHA VIGENTE
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orden?.detalle_orden?.map((linea, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            style={{
                              padding: "0.5rem 0",
                              width: "4rem",
                              borderBottom: 0,
                            }}
                          >
                            <Grid container justifyContent="space-evenly" direction="row"
                              style={{
                                minWidth: "80px"
                              }}
                            >
                              <Grid item >
                                <Tooltip title="Modificaciones de Precios">
                                  <IconButton
                                    size="small"
                                    style={{
                                      width: "1.5rem",
                                      height: "1.5rem",
                                      borderRadius: 5,
                                      backgroundColor: Theme.palette.primary.main,
                                      color: "#ffffff",
                                    }}
                                    onClick={() => {
                                      setOrdenSeleccionada(linea);
                                      setModalHistoricoPrecios(true);
                                    }}
                                  >
                                    <VisibilityIcon
                                      style={{
                                        height: "1.2rem",
                                        width: "1.2rem",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              {orden?.anticipada &&
                                <Grid item>
                                  <Tooltip title="Cambiar Hormigón" disableHoverListener={!!linea?.concepto}>
                                    <IconButton
                                      size="small"
                                      disabled={linea?.concepto || linea?.cant_restante <= 0}
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        borderRadius: 5,
                                        backgroundColor: (linea?.concepto || linea?.cant_restante <= 0) ? Theme.paletteCustom.backgroundGrey.main : Theme.palette.primary.main,
                                        color: "#ffffff",
                                      }}
                                      onClick={() => {
                                        setOrdenSeleccionada({...linea, orden_id : orden?.id});
                                        setModalCambiarHormigon(true);
                                      }}
                                    >
                                      <RepeatOutlinedIcon
                                        style={{
                                          height: "1.2rem",
                                          width: "1.2rem",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              }
                            </Grid>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              borderBottom: 0,
                            }}
                          >
                            {linea?.concepto ?? linea?.formula__nombre ?? "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              borderBottom: 0,
                            }}
                          >
                            {linea?.cantidad_m3?.toLocaleString("es-AR")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              borderBottom: 0,
                            }}
                          >
                            {!linea?.concepto && linea?.formula__nombre
                              ? linea?.cant_restante?.toLocaleString("es-AR")
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              borderBottom: 0,
                            }}
                          >
                            {linea?.precio_original?.toLocaleString("es-AR", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "ARS",
                            })}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              borderBottom: 0,
                            }}
                          >
                            {linea?.precio_actual?.toLocaleString("es-AR", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "ARS",
                            })}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              borderBottom: 0,
                            }}
                          >
                            {linea?.fecha_vigencia_precio
                              ? moment(linea?.fecha_vigencia_precio).format(
                                "DD-MM-YYYY",
                              )
                              : "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid container style={{ padding: "0 3rem" }}>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    -
                  </Typography>
                </Grid>
              )}
            </Card>
            <HistoricoPrecios
              open={modalHistoricoPrecios}
              close={setModalHistoricoPrecios}
              orden={ordenSeleccionada}
              presupuesto={false}
            />
            {modalCambiarHormigon &&
              <ModalCambiarHormigon
                open={modalCambiarHormigon}
                close={setModalCambiarHormigon}
                orden={ordenSeleccionada}
                setSnackState={setSnackState}
                mostrarErrorEP={mostrarErrorEP} 
                actualizarOrden={obtenerOrden}
              />
            }
            <Grid hidden>
              {orden && (
                <Imprimir
                  componentRef={componentRef}
                  orden={orden}
                  totalOrden={calcularTotalOrden}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <VisualizarFactura
            facturaId={facturaId}
            setOpenVisualizarFactura={setOpenVisualizarFactura}
          />
        )}
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          autoHideDuration={snackState.autoHideDuration}
          onClose={onClose}
        />
        <DetallePago
          open={openDetallePago}
          handleClose={toogleOpenDetallePago}
          orden={orden}
        />
      </RootContainer>
    </Protected>
  );
};

export default VerOrden;
