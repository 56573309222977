import React, { useState } from "react";
import { Typography, Modal, Grid, Button, TextareaAutosize, MenuItem } from "@material-ui/core";
import Theme from "theme";
import rules from "constants/rules";
import InputField from "components/Layout/inputCustom/inputField";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import SelectCustom from "components/Layout/select/selectCustom";
import FormulaService from "services/formula.service";
import ButtonLoading from "components/buttonLoading/ButtonLoading";

const CreateEditMaterialModal = ({ material, open, handleClose, setSnackBar }) => {

    const { handleSubmit, control, reset } = useForm();
    const queryClient = useQueryClient();
    const [cargando, setCargando] = useState(false);

    // Función Cerrar Modal Materiales
    const cerrar = () => {
        reset();
        handleClose();
    };

    // Función Enviar Datos Al Back
    const onSubmit = async (data) => {
        const sendData = {
            tipo: data?.tipo && data.tipo,
            nombre: data?.nombre && data.nombre.toUpperCase(),
            unidad: data?.unidad && data.unidad,
            descripcion: data?.descripcion && data.descripcion
        }
        let endpoint = "";
        let type = "";

        if (material){
            type = "Editado";
            endpoint = FormulaService.editMaterial(material?.id, sendData);
        }else{
            type = "Creado";
            endpoint =  FormulaService.createMaterial(sendData);
        }
        try {
            setCargando(true);
            const res = await endpoint;  
            if (res.status > 199 && res.status < 300) {
                setSnackBar({
                    open: true,
                    severity: "success",
                    message: `Material ${type} correctamente`,
                  });
                  queryClient.invalidateQueries("Materiales");
            }else {
                setCargando(false);
                setSnackBar({
                  open: true,
                  severity: "error",
                  message: `Error al Agregar Material`,
                });
              }
        } catch (error) {
            setCargando(false);
            setSnackBar({
                message: error?.response?.data?.msj ?? "Error de conexión Editar material",
                severity: "error",
                open: true,
            });
        }
        cerrar();
    };


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        item
                        direction="column"
                        justifyContent="flex-start"
                        alignContent="flex-end"
                        alignItems="flex-end"
                        xl={4}
                        lg={4}
                        md={6}
                        sm={6}
                        xs={11}
                        style={{
                            backgroundColor: "#ffffff",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            borderRadius: 10,
                            minHeight: "20vh",
                        }}
                    >
                        <Grid
                            container
                            item
                            justifyContent="center"
                            alignContent="center"
                            style={{
                                height: "3rem",
                                backgroundColor: Theme.palette.primary.main,
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                            }}
                        >
                            <Typography style={{ color: "#ffffff", fontSize: 20 }}>
                                {material?.id ? "Editar Material" : "Crear Material"}
                            </Typography>
                        </Grid>
                        <Grid container item style={{ padding: "1rem" }}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <SelectCustom
                                    name="tipo"
                                    control={control}
                                    label={"Tipo *"}
                                    size="small"
                                    rules={rules.generic}
                                    defaultValue={material?.tipo ?? ""}
                                >
                                    <MenuItem value={""}></MenuItem>
                                    <MenuItem value={"Aridos"}>Áridos</MenuItem>
                                    <MenuItem value={"Cemento"}>Cemento</MenuItem>
                                    <MenuItem value={"Agua"}>Agua</MenuItem>
                                    <MenuItem value={"Aditivos"}>Aditivos</MenuItem>
                                    <MenuItem value={"Otros"}>Otros</MenuItem>

                                </SelectCustom>
                            </Grid>
                            <Grid
                                container
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                style={{ marginTop: "1rem" }}
                            >
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    style={{ marginBottom: "1rem", padding: "0.5rem" }}
                                >
                                    <InputField
                                        name="nombre"
                                        label={"Nombre"}
                                        defaultValue={material?.nombre ?? ""}
                                        control={control}
                                        rules={rules.generic}
                                        type={"text"}
                                        autoComplete='off'
                                        InputLabelProps={{ required: true }}
                                    />
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ marginBottom: "1rem", padding: "0.5rem" }}>

                                    <SelectCustom
                                        name="unidad"
                                        control={control}
                                        label="Unidad*"
                                        size="small"
                                        rules={rules.generic}
                                        defaultValue={material?.unidad ?? ""}
                                    >
                                        <MenuItem value={"KGS"}>KGS</MenuItem>
                                        <MenuItem value={"CM3"}>CM3</MenuItem>

                                    </SelectCustom>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                <InputField
                                    name="descripcion"
                                    placeholder={"Descripción"}
                                    defaultValue={material?.descripcion ?? ""}
                                    control={control}
                                    rules={rules.genericNoRequered}
                                    type={"text"}
                                    InputLabelProps={{ required: true }}
                                    InputProps={{
                                        inputComponent: TextareaAutosize,
                                        rows: 3
                                    }}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                justifyContent="flex-end"
                                style={{ marginTop: "1rem" }}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginRight: "1rem" }}
                                    onClick={() => cerrar()}
                                >
                                    Volver
                                </Button>
                                <ButtonLoading type="submit" disabled={cargando ? true : false} isLoading={cargando}>
                                    Confirmar
                                </ButtonLoading>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Modal>
        </>
    );
};

export default CreateEditMaterialModal;
