/* eslint-disable react-hooks/exhaustive-deps */
//REACT
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { toContable } from "constants/funciones/toContable";

//MATERIAL UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  TableContainer,
  TextField,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import Select from "@mui/material/Select";
import Link from "@material-ui/core/Link";

//ICONS
import { CompareArrows, Create, Delete, Visibility } from "@material-ui/icons";

//COMPONENTS
import SearchBar from "components/Layout/searchBar/SearchBar";
import ConfirmDialog from "components/ConfirmDialog";
import VerificarAcceso from "components/verificarAcceso";
import RootContainer from "layout/rootContainer";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import NewCheckbox from "components/inputs/newCheckbox";
import CondicionPagoResumen from "components/modifCondPagoResumen/condicionPagoResumen";
import Pagination from "@material-ui/lab/Pagination";

//SERVICES
import facturacionServices from "services/facturacion.services";

//VARIOS
import Protected from "protected";
import FactClienteStyles from "./FacClienteStyles";
import { LogIn } from "context/LogInContext";
import moment from "moment";
import VisualizarFactura from "../visualizar";
import { NotificationContext } from "context/notification/NotificationContext";
import { namesFacturacion } from "../constants/const";
import CondicionPagoFacturas from "components/modifCondPagoFacturas";
import cuentaCorrienteService from "services/cuentaCorriente.service";
import mostrarErrorEP from "constants/funciones/mostrarError";
import StyledIconButton from "components/StyledIconButton";
import { useDebounce } from "hook/useDebounce";
import { shallow } from "zustand/shallow";
import {
  useFacturaActions,
  useFacturasStore,
  useNoteTipeSelected,
  useOrderBy,
  useSearchNotesOrClients,
  useRef,
} from "../store";

//constants
const SORT_TYPE = Object.freeze({
  SORT_DATE: Symbol(),
  SORT_NUMFAC: Symbol(),
  SORT_MONTO: Symbol(),
  SORT_COND: Symbol(),
});

const FacturasPorCliente = () => {
  const history = useHistory();
  const classes = FactClienteStyles();
  const { logged } = useContext(LogIn);
  const queryClient = useQueryClient();
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const cliente = history?.location?.state;
  const [total, setTotal] = useState();
  const [actual, setActual] = useState();

  //ESTADO PARA VERIFICAR ACCESO A MODULO
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [openVisualizarFactura, setOpenVisualizarFactura] = useState(false);
  const [facturaId, setFacturaId] = useState();

  // ESTADOS PARA CAMBIAR CONDICION DE FACTURAS
  const [facturasSeleccionadas, setFacturasSeleccionadas] = useState([]);
  const [openAgregarFormaPago, setOpenAgregarFormaPago] = useState(false);
  const [openCondicionPagoFacturas, setOpenCondicionPagoFacturas] =
    useState(false);
  const [variosPagosAlasMismasFacturas, setVariosPagosAlasMismasFacturas] =
    useState(false);

  // Hook useForm
  const { control, reset, getValues } = useForm();

  // state manager with zustand
  const filtro = useFacturasStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      tipo: state.tipo,
      ordfecha: state.ordfecha,
      ordnumfac: state.ordnumfac,
      ordmonto: state.ordmonto,
      ordcondi: state.ordcondi,
      page: state.page,
      dias: state.dias,
      condicion: state.condicion,
    }),
    shallow,
  );
  const selectTexto = useNoteTipeSelected();
  const searchText = useSearchNotesOrClients();
  const orderBy = useOrderBy();
  const ref = useRef();

  //Actions with zustand
  const {
    setFechaDesde,
    setFechaHasta,
    setSelectTexto,
    setSearch,
    setTipo,
    setDias,
    setPage,
    setTodo,
    setCondicion,
    setOrdFecha,
    setOrdNumFac,
    setOrdMonto,
    setOrdCondi,
    toggleSort,
    setRef,
    resetState,
  } = useFacturaActions();

  // useDebounce hook for input text
  const debouncedValue = useDebounce(searchText, 300);

  // FUNCION DE BUSCADOR POR CLIENTE O FACTURA
  const handleSearch = async (text) => {
    setSearch(text);
  };

  //===========================ESTADOS PARA SNACKBAR============================
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  // Funcion encargada de cerrar el snackbar
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }
  //===========================FIN SNACKBAR=====================================

  //=========================== CAMBIAR CONDICION DE PAGO =======================
  const handleChecked = () => {
    const values = getValues();

    const facturasSeleccionadas = facturasCliente?.results?.filter(
      (item) => values?.[item?.id],
    );

    setFacturasSeleccionadas(facturasSeleccionadas);
  };
  //=========================== FIN CAMBIAR CONDICION DE PAGO =====================================

  //=============================== ESTADOS PARA ACCIONES =========================================

  // ESTADO PARA ABRIR MODAL DE ELIMINAR FACTURA
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  const eliminarFactura = async (factura) => {
    if (!factura.presupuesto) {
      try {
        const res = await facturacionServices.deleteFactura(factura.id);
        if (res.status > 199 && res.status < 300) {
          setSnackState({
            open: true,
            severity: "success",
            message: "Factura eliminada exitosamente",
          });
          queryClient.invalidateQueries({ queryKey: ["FacturasCliente"] });
        } else {
          setSnackState({
            open: "true",
            severity: "error",
            message: "Error al eliminar factura",
          });
        }
      } catch (error) {
        setSnackState({
          open: true,
          severity: "warning",
          message: "Error de servidor al borrar",
        });
      }
    } else {
      try {
        const res = await facturacionServices.deleteRecibo(factura.id);
        if (res.status > 199 && res.status < 300) {
          setSnackState({
            open: true,
            severity: "success",
            message: "Recibo eliminado exitosamente",
          });
          queryClient.invalidateQueries({ queryKey: ["FacturasCliente"] });
        } else {
          setSnackState({
            open: "true",
            severity: "error",
            message: "Error al eliminar recibo",
          });
        }
      } catch (error) {
        setSnackState({
          open: true,
          severity: "warning",
          message: "Error de servidor al borrar",
        });
      }
    }
  };

  const verFactura = (factura) => {
    if (!factura.presupuesto) {
      setOpenVisualizarFactura(true);
      setFacturaId(factura.id);
    } else {
      history.push(`/recibos/visualizar-recibo/${factura.id}/`);
    }
  };
  //=============================== FIN ESTADOS PARA ACCIONES ==============================================

  // ========= Query Estado de Cuenta del cliente ===== //
  const haberCuentaCorriente = async () => {
    const { data } = await cuentaCorrienteService.obtenerHaberCtaCte(
      cliente.id,
    );

    return data;
  };

  const { data: haber } = useQuery(
    ["haberCuentaCorriente"],
    haberCuentaCorriente,
    {
      onError: (error) => mostrarErrorEP(error, setSnackState),
      refetchInterval: false,
      refetchOnReconnect: false,
    },
  );

  //=============================== OBTENER FACTURAS POR CLIENTE ==============================================
  const obtenerReciboCliente = async () => {
    const { data } = isNotificationsEnabled
      ? await facturacionServices.getFacturaCliente(cliente?.id, {
          ...filtro,
          search: debouncedValue,
        })
      : await facturacionServices.getReciboCliente(cliente?.id, {
          ...filtro,
          search: debouncedValue,
        });

    return data;
  };

  const { data: facturasCliente, isLoading } = useQuery(
    ["FacturasCliente", filtro, isNotificationsEnabled, debouncedValue],
    obtenerReciboCliente,
    {
      onError: (error) => mostrarErrorEP(error, setSnackState),
      onSuccess: (facturasCliente) => {
        setTotal(facturasCliente?.total_paginas);
        setActual(facturasCliente?.actual);
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );
  //=============================== FIN OBTENER FACTURAS POR CLIENTE ==============================================

  const refreshQueries = () => {
    queryClient.invalidateQueries({
      queryKey: ["FacturasCliente"],
    });
    queryClient.invalidateQueries({
      queryKey: ["haberCuentaCorriente"],
    });
  };

  //=============================== ESTADOS PARA FILTROS ==============================================
  const mostrarPor = async (search) => {
    switch (search) {
      case namesFacturacion?.todas:
        setTodo("Todas");
        break;
      case namesFacturacion?.aCobrar:
        setCondicion(namesFacturacion?.aCobrar);
        break;
      case namesFacturacion?.pagado:
        setCondicion(namesFacturacion?.pagado);
        break;
      case "Vencidas":
        setDias(moment().format("YYYY-MM-DD"));
        break;
      default:
        setTipo(namesFacturacion?.todas);
    }
  };
  //=============================== FIN FILTROS ==============================================

  //=============================== ORDENAR POR ==============================================
  const sortBy = (criteria) => {
    const sortType = orderBy ? "asc" : "desc";
    switch (criteria) {
      case SORT_TYPE.SORT_DATE:
        setOrdFecha(sortType);
        break;
      case SORT_TYPE.SORT_NUMFAC:
        setOrdNumFac(sortType);
        break;
      case SORT_TYPE.SORT_MONTO:
        setOrdMonto(sortType);
        break;
      case SORT_TYPE.SORT_COND:
        setOrdCondi(sortType);
        break;
      default:
        break;
    }
    toggleSort();
  };
  //=============================== FIN ORDENAR POR ==============================================
  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  useEffect(() => {
    console.log("ref", ref);
    if (ref !== "facturas-cliente") {
      resetState();
      setTimeout(() => {
        setRef("facturas-cliente");
      }, 200);
      setSearch("");
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="facturacion"
            history={history}
            logged={logged}
          />
        ) : (
          <>
            {acceso && !openVisualizarFactura ? (
              <>
                <Grid
                  container
                  style={{
                    margin: "1rem 2rem 0rem 2rem",
                    overflow: "auto",
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid item style={{ marginRight: "4rem" }}>
                          <Grid container>
                            <Typography
                              className={classes.facturaHeaderItemTitle}
                            >
                              Nombre:{" "}
                              <span className={classes.facturaHeaderItem}>
                                {cliente?.nombre ?? "-"}
                              </span>
                            </Typography>
                          </Grid>

                          <Grid container>
                            <Typography
                              className={classes.facturaHeaderItemTitle}
                            >
                              CUIL-CUIT:{" "}
                              <span className={classes.facturaHeaderItem}>
                                {cliente?.cuil_dni ?? "-"}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item style={{ marginRight: "2rem" }}>
                          <Grid container>
                            <Typography
                              className={classes.facturaHeaderItemTitle}
                            >
                              Dirección:{" "}
                              <span className={classes.facturaHeaderItem}>
                                {cliente
                                  ? `${cliente.direccion} ${cliente.ciudad} - ${cliente.provincia}`
                                  : "-"}
                              </span>
                            </Typography>
                          </Grid>

                          <Grid container>
                            <Typography
                              className={classes.facturaHeaderItemTitle}
                            >
                              Teléfono:{" "}
                              <span className={classes.facturaHeaderItem}>
                                {cliente?.num_telefono ?? "-"}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item style={{ margin: "0.5rem 0" }}>
                          <Grid
                            container
                            className={classes?.total}
                            style={{
                              backgroundColor: "#ff000066",
                            }}
                          >
                            <Typography
                              className={classes.facturaHeaderItemTitle}
                            >
                              Total Debe:{" "}
                              <span className={classes.facturaHeaderItem}>
                                {toContable(facturasCliente?.total_debe ?? 0)}
                              </span>
                            </Typography>
                          </Grid>

                          <Grid
                            container
                            className={classes?.total}
                            style={{
                              backgroundColor: "#aedf0080",
                            }}
                          >
                            <Typography
                              className={classes.facturaHeaderItemTitle}
                            >
                              Total Haber:{" "}
                              <span className={classes.facturaHeaderItem}>
                                {toContable(haber?.credito_haber ?? 0)}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={facturasSeleccionadas?.length < 2}
                        onClick={() => {
                          setOpenCondicionPagoFacturas(true);
                          setVariosPagosAlasMismasFacturas(true);
                        }}
                        style={{
                          backgroundColor:
                            facturasSeleccionadas?.length < 2
                              ? "grey"
                              : "#001176",
                          textTransform: "capitalize",
                          maxHeight: "2rem",
                          margin: "0.5rem 0.5rem 0.5rem 0",
                          color: "#FFFFFF",
                        }}
                      >
                        Varios pagos a las mismas Facturas
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={facturasSeleccionadas?.length < 1}
                        style={{
                          backgroundColor:
                            facturasSeleccionadas?.length < 1
                              ? "grey"
                              : "#001176",
                          textTransform: "capitalize",
                          maxHeight: "2rem",
                          margin: "0.5rem 0.5rem 0.5rem 0",
                          color: "#FFFFFF",
                        }}
                        onClick={() => {
                          setOpenAgregarFormaPago(true);
                          setVariosPagosAlasMismasFacturas(false);
                        }}
                      >
                        Cambiar Condición de Pago
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    direction="row"
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <Grid
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "0.5rem" }}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Desde"
                          required
                          focused
                          defaultValue={filtro.fecha_desde}
                          onChange={(e) =>
                            setFechaDesde(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            max: filtro.fecha_hasta,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Hasta"
                          required
                          focused
                          defaultValue={filtro.fecha_hasta}
                          onChange={(e) =>
                            setFechaHasta(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            min: filtro.fecha_desde,
                            max: moment().format("YYYY-MM-DD"),
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent={"flex-end"}
                      alignContent="flex-start"
                      alignItems="center"
                      style={{ marginTop: "0.5rem" }}
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <Box>
                          <FormControl style={{ width: "190px" }}>
                            <Select
                              size="small"
                              variant="outlined"
                              value={selectTexto}
                              displayEmpty
                              renderValue={
                                selectTexto !== ""
                                  ? undefined
                                  : () => (
                                      <Placeholder>Mostrar por:</Placeholder>
                                    )
                              }
                              onChange={(e) => (
                                // eslint-disable-next-line no-sequences
                                mostrarPor(e.target.value),
                                setSelectTexto(e.target.value)
                              )}
                            >
                              <MenuItem value={"Todas"}>Todas</MenuItem>
                              <MenuItem value={"Vencidas"}>
                                Vencidas +30 Días
                              </MenuItem>
                              <MenuItem value={namesFacturacion?.aCobrar}>
                                A Cobrar
                              </MenuItem>
                              <MenuItem value={namesFacturacion?.pagado}>
                                Pagado
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item>
                        <SearchBar
                          placeholder="Buscar por N° Factura"
                          defaultValue={searchText}
                          value={searchText}
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    style={{
                      maxWidth: "95%",
                      marginTop: "0.5rem",
                      marginBottom: "5rem",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      className={classes.headerTable}
                    >
                      <Typography
                        variant="h5"
                        className={classes.headerTableTittle}
                      >
                        Lista de Facturas
                      </Typography>
                    </Grid>

                    {isLoading ? (
                      <Grid container justifyContent="center">
                        <Grid>
                          <CircularProgress
                            size="1.5rem"
                            style={{ margin: "2rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <TableContainer
                          component={Paper}
                          style={{
                            maxHeight: "49vh",
                            overflow: "auto",
                          }}
                        >
                          <Table size="small" stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  className={classes.tableCell2}
                                  style={{ minWidth: "10rem" }}
                                >
                                  N° Factura
                                  <IconButton
                                    onClick={() =>
                                      sortBy(SORT_TYPE.SORT_NUMFAC)
                                    }
                                  >
                                    <CompareArrows
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{ minWidth: "11rem", padding: 0 }}
                                >
                                  Fecha de Emisión
                                  <IconButton
                                    onClick={() => sortBy(SORT_TYPE.SORT_DATE)}
                                  >
                                    <CompareArrows
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{ minWidth: "10rem" }}
                                >
                                  Orden de Compra
                                </TableCell>

                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{
                                    minWidth: "9rem",
                                    padding: 0,
                                  }}
                                >
                                  Mts3
                                </TableCell>

                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{
                                    minWidth: "9rem",
                                    padding: 0,
                                  }}
                                >
                                  Monto
                                  <IconButton
                                    onClick={() => sortBy(SORT_TYPE.SORT_MONTO)}
                                  >
                                    <CompareArrows
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{
                                    minWidth: "8rem",
                                    padding: 0,
                                  }}
                                >
                                  Condición
                                  <IconButton
                                    onClick={() => sortBy(SORT_TYPE.SORT_COND)}
                                  >
                                    <CompareArrows
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCellHeaders}
                                >
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            {facturasCliente?.results?.map((fac) => (
                              <TableBody key={fac?.id}>
                                <TableRow
                                  hover
                                  style={{
                                    backgroundColor: fac?.presupuesto
                                      ? "#E26E6E"
                                      : "",
                                  }}
                                >
                                  <TableCell
                                    align="left"
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      minWidth: "10rem",
                                    }}
                                  >
                                    <NewCheckbox
                                      control={control}
                                      name={fac?.id?.toString()}
                                      color="primary"
                                      label={fac?.num_factura ?? "-"}
                                      defaultValue={false}
                                      onChange={() => handleChecked()}
                                      disabled={
                                        fac?.condicion === "Pagado" ||
                                        (!isNotificationsEnabled &&
                                          !fac?.presupuesto)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    {moment(fac?.fecha_emision).format(
                                      "DD-MM-YYYY",
                                    ) ?? "-"}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className={classes.tablecell3}
                                  >
                                    {fac?.orden_compra?.num_orden ||
                                    fac?.orden_compra?.id ? (
                                      <Link
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "17px",
                                          color:
                                            (fac?.orden_compra?.num_orden ||
                                              fac?.orden_compra?.id) &&
                                            "#1C3AA9",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                        }}
                                        onClick={() =>
                                          history.push(
                                            fac?.presupuesto
                                              ? `/ordenes/ver-orden-negro/${
                                                  fac?.orden_compra?.id ?? ""
                                                }/`
                                              : `/ordenes/ver-orden/${
                                                  fac?.orden_compra?.id ?? ""
                                                }/`,
                                          )
                                        }
                                      >
                                        {fac?.orden_compra?.num_orden ||
                                          fac?.orden_compra?.id}
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tablecell3}
                                    style={{
                                      paddingLeft: "0.5rem",
                                    }}
                                  >
                                    {fac?.cantidad_mc ?? "-"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tablecell3}
                                    style={{
                                      paddingLeft: 0,
                                    }}
                                  >
                                    {fac?.total?.toLocaleString("es-AR", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                      style: "currency",
                                      currency: "ARS",
                                    }) ?? "-"}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {fac?.condicion ?? "-"}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Grid
                                      container
                                      item
                                      direction="row"
                                      justifyContent="center"
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "8rem",
                                      }}
                                    >
                                      <StyledIconButton
                                        size="small"
                                        onClick={() => verFactura(fac)}
                                      >
                                        <Visibility className={classes.icon} />
                                      </StyledIconButton>

                                      <StyledIconButton
                                        size="small"
                                        disabled={fac?.orden_facturada}
                                        onClick={() =>
                                          setConfirmDialog({
                                            title: fac?.presupuesto
                                              ? `¿Está seguro de eliminar el recibo ${fac?.num_factura}?`
                                              : `¿Está seguro de eliminar la factura ${fac?.num_factura}?`,
                                            isOpen: true,
                                            onConfirm: () => {
                                              eliminarFactura(fac);
                                            },
                                          })
                                        }
                                      >
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            <Typography
                                              variant="body2"
                                              align="center"
                                            >
                                              No se puede eliminar esta factura
                                              porque tiene una orden facturada
                                            </Typography>
                                          }
                                          disableFocusListener={
                                            !fac?.orden_facturada
                                          }
                                          disableHoverListener={
                                            !fac?.orden_facturada
                                          }
                                          disableTouchListener={
                                            !fac?.orden_facturada
                                          }
                                        >
                                          <Delete className={classes.icon} />
                                        </Tooltip>
                                      </StyledIconButton>

                                      <StyledIconButton
                                        size="small"
                                        disabled={
                                          fac?.presupuesto &&
                                          fac?.condicion === "Pagado"
                                        }
                                        onClick={() =>
                                          history.push(
                                            fac?.presupuesto
                                              ? `/recibo/editar-recibo/${
                                                  fac?.id ?? ""
                                                }/`
                                              : `/facturacion/editar-factura/${
                                                  fac?.id ?? ""
                                                }/`,
                                          )
                                        }
                                      >
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            <Typography
                                              variant="body2"
                                              align="center"
                                            >
                                              No se puede editar este recibo
                                              porque tiene orden de compra
                                              pagado
                                            </Typography>
                                          }
                                          disableFocusListener={
                                            !fac?.presupuesto &&
                                            fac?.condicion === "Pagado"
                                          }
                                          disableHoverListener={
                                            !fac?.presupuesto &&
                                            fac?.condicion === "Pagado"
                                          }
                                          disableTouchListener={
                                            !fac?.presupuesto &&
                                            fac?.condicion === "Pagado"
                                          }
                                        >
                                          <Create className={classes.icon} />
                                        </Tooltip>
                                      </StyledIconButton>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                          </Table>
                        </TableContainer>
                        {facturasCliente && (
                          <Grid container justifyContent="center">
                            <Grid style={{ marginTop: "1rem" }}>
                              <Pagination
                                count={total}
                                color="primary"
                                onChange={(event, value) => setPage(value)}
                                page={filtro?.page}
                              />
                            </Grid>
                          </Grid>
                        )}

                        {facturasCliente?.count === 0 && (
                          <Grid
                            container
                            justifyContent="center"
                            style={{ margin: "0.5rem" }}
                          >
                            <Typography variant="subtitle1">
                              No se encontraron facturas con estas
                              características
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>

                {/* Botón Cambiar Cond. de Pago */}
                <CondicionPagoResumen
                  open={openAgregarFormaPago}
                  setOpen={setOpenAgregarFormaPago}
                  facturasSeleccionadas={facturasSeleccionadas}
                  setFacturasSeleccionadas={setFacturasSeleccionadas}
                  setConfirmDialog={setConfirmDialog}
                  setSnackState={setSnackState}
                  resetCheckboxes={reset}
                  refreshQueries={refreshQueries}
                />

                {/* Botón Varios Pagos a Las Mismas Facturas */}
                <CondicionPagoFacturas
                  open={openCondicionPagoFacturas}
                  setOpen={setOpenCondicionPagoFacturas}
                  setConfirmDialog={setConfirmDialog}
                  setSnackState={setSnackState}
                  items={facturasSeleccionadas}
                  setItems={setFacturasSeleccionadas}
                  resetCheckboxes={reset}
                  refreshQueries={refreshQueries}
                  variosPagosAlasMismasFacturas={variosPagosAlasMismasFacturas}
                />
              </>
            ) : (
              <VisualizarFactura
                facturaId={facturaId}
                setOpenVisualizarFactura={setOpenVisualizarFactura}
              />
            )}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </>
        )}
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};
export default FacturasPorCliente;
