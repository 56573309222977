// MUI && REACT 6& LIBRARIES
import { Button, Grid } from "@material-ui/core";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import moment from "moment";
import { useToggle } from "hook/useToogle";
import mostrarErrorEP from "constants/funciones/mostrarError";


// SERVICES
import ordenesServices from "services/ordenes.service";
import facturacionServices from "services/facturacion.services";


// COMPONENTS
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import Card from "components/card";
import ConfirmDialog from "components/ConfirmDialog";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import MediosPago from "./components/mediosPago";
import CrearFacturaCompraAnticipada from "screens/facturacion/factura-compra-anticipada/crearFacturaCompraAnticipada";


const OrdenCompraAnticipada = () => {
  const history = useHistory();
  const [openMediosPago, setOpenMediosPago] = useToggle();
  const [mediosPago, setMediosPago] = useState([]);


  const handleClickAgregarPagos = () => {
    setOpenMediosPago();
  };


  // Desestructuring history state dataSend: datos de orden y clienteSeleccionado: cliente de la orden
  const { dataSend, clienteSeleccionado } = history?.location?.state;


  // Estados
  const [archivoSeleccionado, setArchivoSeleccionado] = useState(null);
  const [creandoFactura, setCreandoFactura] = useState(false);


  //ESTADO DE CONFIRM DIALOG
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });


  // ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });


  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }


  // Armar array de lineas con datos de formula, de la Orden y campos de Factura
  const nuevoArreglo = dataSend?.datosFormula.map(objeto => (
    {
      "id": objeto.id,
      "tipo": objeto.isConcepto ? objeto.concepto : objeto.formula_nombre,
      "cant_metros": objeto.cantidad_m3,
      "precio_unitario": objeto.precio_original,
      "fecha_vigencia_precio": objeto.fecha_vigencia_precio,
      "formula": objeto.formula,
      "bonif": 0,
      "subtotal": objeto.cantidad_m3 * objeto.precio_original,
      "iva": 0,
      "importe": objeto.cantidad_m3 * objeto.precio_original,
    }
  ));


  // Use Form Factura
  const factura = useForm({
    defaultValues: {
      lineas_factura: nuevoArreglo
    }
  });


  // Desestructuring Form
  const { handleSubmit, control, setValue } = factura;


  const [importandoDatos, setImportandoDatos] = useState(false);
  const importarDatosPDF = async () => {
    setImportandoDatos(true);
    const dataSend = new FormData();
    dataSend.append("pdf", archivoSeleccionado);


    try {
      const { data, status } = await facturacionServices.importarDatosPDF(
        dataSend,
      );


      if (status > 199 && status < 300) {
        setValue("numFact", data?.comp_nro);
        setValue("puntoVenta", data?.punto_de_venta);
        setValue("fechaVencPago", moment(
          data?.fecha_vto_para_el_pago, "DD/MM/YYYY").format(
            "YYYY-MM-DD"));
        setValue("fecha_emision", moment(data?.fecha_de_emision, "DD/MM/YYYY").format(
          "YYYY-MM-DD"));
        setValue("tipoFactura", data?.factura);
        setValue("cond_iva", data?.cond_frente_al_iva);
        setImportandoDatos(false);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      setImportandoDatos(false);
      return error;
    }
  };


  // Array Fields de lineas de factura
  const { fields } = useFieldArray({
    control,
    name: 'lineas_factura'
  });


  // Watch de lineas
  const watchTestLine = useWatch({
    control,
    name: "lineas_factura",
    defaultValue: fields
  });


  // Actualizo valores del form data fields array de lineas
  const actualizar = (value, index) => {
    const { precio_unitario, cant_metros, bonif, iva } = watchTestLine[index];
    let importe, subtotal;
    switch (value.name) {
      case 'precio_unitario':
        importe = cant_metros * value.value - bonif;
        subtotal = importe + importe * (iva / 100);
        break;
      case 'cant_metros':
        importe = precio_unitario * value.value - bonif;
        subtotal = importe + importe * (iva / 100);
        break;
      case 'bonif':
        importe = precio_unitario * cant_metros - value.value;
        subtotal = importe + importe * (iva / 100);
        break;
      case 'iva':
        importe = watchTestLine[index].importe || 0;
        subtotal = importe + importe * (value.value / 100);
        break;
      default:
        return '';
    }
    setValue(`lineas_factura[${index}].importe`, importe);
    setValue(`lineas_factura[${index}].subtotal`, subtotal);
  };




  // Calcular Total de Factura
  const calcularTotal = () => {
    let total = watchTestLine.reduce((acumulador, actual) => acumulador + actual.subtotal, 0);
    return Number(total.toFixed(2));
  };


  // Función onSubmit
  const onSubmit = (data) => {


    //Desestructuring orden data
    const { datosFormula, num_orden } = dataSend;




    // Total Factura
    let totalFactura = calcularTotal();


    setConfirmDialog({
      title: `Crear Factura Nº${data?.numFact}`,
      isOpen: true,
      onConfirm: async () => {
        setCreandoFactura(true);
        // Form Data para enviar
        let dataSend = new FormData();
        if (archivoSeleccionado) {
          dataSend.append("documento", archivoSeleccionado);
        }
        dataSend.append("num_factura", data?.numFact);
        dataSend.append("num_orden", num_orden);
        dataSend.append("total", totalFactura);
        dataSend.append("fecha_venc_pago", data?.fechaVencPago);
        dataSend.append("fecha_emision", moment(dataSend?.fecha_carga).format("YYYY-MM-DD"));
        dataSend.append("fecha_carga", moment(dataSend?.fecha_carga).format("YYYY-MM-DD"));
        dataSend.append("cond_iva", clienteSeleccionado?.condicion_iva);
        dataSend.append("tipo_factura", data?.tipoFactura);
        dataSend.append("punto_venta", data?.puntoVenta);
        dataSend.append("compra_anticipada", 1);
        dataSend.append("presupuesto", 0);
        dataSend.append("cliente", clienteSeleccionado?.id);
        dataSend.append("resumen", "");
        dataSend.append("condicion_fact", data?.condicion ? "Pagado" : "A Cobrar");
        dataSend.append("condicion", data?.condicion ? "Pagado" : "A Cobrar");
        dataSend.append("linea_factura", JSON.stringify(data?.lineas_factura));
        dataSend.append("lineas_pago", JSON.stringify(mediosPago));
        dataSend.append("datosFormula", JSON.stringify(datosFormula));
        // Agrega para identificar que se genera una orden de compra anticipada
        dataSend.append("anticipada", true);
        try {
          const res = await ordenesServices.crearOrden(dataSend);
          if (res.status > 199 && res.status < 300) {
            setSnackState({
              ...setSnackState,
              open: true,
              severity: "success",
              message: "Factura Creada Exitosamente",
            });
            setTimeout(() => {
              setCreandoFactura(false);
              history.push(`/facturacion/`);
            }, [2000]);
          }
        } catch (error) {
          mostrarErrorEP(error, setSnackState);
          setCreandoFactura(false);
          return error;
        }
      },
    });
  };


  return (
    <Protected>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RootContainer>
          <Grid
            container
            style={{
              padding: "2rem 1rem 2rem 1rem",
              width: "95%",
            }}
          >
            <Card title="Cargar Datos de Facturación" justifyContent="center">
              <CrearFacturaCompraAnticipada
                clienteSeleccionado={clienteSeleccionado}
                dataSend={dataSend}
                fields={fields}
                control={control}
                calcularTotal={calcularTotal}
                setSnackState={setSnackState}
                watchTestLine={watchTestLine}
                actualizar={actualizar}
                handleClickAgregarPagos={handleClickAgregarPagos}
                archivoSeleccionado={archivoSeleccionado}
                setArchivoSeleccionado={setArchivoSeleccionado}
                importarDatosPDF={importarDatosPDF}
                importandoDatos={importandoDatos}
              />
              <Grid
                container
                style={{
                  padding: "1rem 4rem",
                }}
              >
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginTop: "1rem" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    children="Volver"
                    style={{ marginRight: "1rem", textTransform: "none" }}
                    onClick={() => history.goBack()}
                  />
                  <ButtonLoading
                    isLoading={creandoFactura}
                    type="submit"
                    children="Confirmar"
                    style={{ textTransform: "none" }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <CustomSnackbar
            message={snackState.message}
            open={snackState.open}
            severity={snackState.severity}
            autoHideDuration={snackState.autoHideDuration}
            onClose={onClose}
          />
        </RootContainer>
      </form>
      <MediosPago
        open={openMediosPago}
        handleClose={setOpenMediosPago}
        totalOrden={calcularTotal}
        setConfirmDialog={setConfirmDialog}
        setMediosPago={setMediosPago}
      />
    </Protected>
  )
}
export default OrdenCompraAnticipada;