import React, { useState } from "react";
import { Typography, Modal, Grid, Button } from "@material-ui/core";
import Theme from "theme";

import CustomSnackbar from "components/Layout/CustomSnackbars";
import { makeStyles } from "@material-ui/core/styles";
import { InfoOutlined } from "@material-ui/icons";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import { useQueryClient } from "react-query";
import ProveedoresService from "services/proveedores.service";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "auto",
    height: "auto",
    overflow: "auto",
  },
}));

const ModalEliminarRemito = ({ open, handleClose, remitoSeleccionado }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  //ESTADOS
  const [cargando, setCargando] = useState(false);

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Función para Eliminar Remito de la BD
  const eliminarRemito = async () => {
    try {
      setCargando(true);
      const res = await ProveedoresService.deleteRemito(remitoSeleccionado?.id);
      if (res.status > 199 && res.status < 299) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Remito borrado exitosamente",
        });
        setCargando(false);
        queryClient.invalidateQueries("Remitos");
      } else {
        setCargando(false);
        setSnackState({
          open: true,
          severity: "error",
          message: "Error al borrar el remito",
        });
      }
    } catch (error) {
      setCargando(false);
      setSnackState({
        open: true,
        severity: "warning",
        message: "Hay problemas para eliminar el remito.",
      });
    }
    handleClose();
  };
  return (
    <>
      <Modal open={open} className={classes.paper}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignContent="center"
          alignItems="flex-end"
          spacing={2}
          xl={5}
          lg={5}
          md={6}
          sm={6}
          xs={11}
          style={{
            backgroundColor: "#ffffff",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
            minHeight: "auto",
            overflow: "auto",
          }}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <Typography item variant="h5" style={{ color: "#ffffff" }}>
              Eliminar Remito
            </Typography>
          </Grid>
          <Grid
            container
            item
            xl={8}
            lg={8}
            md={8}
            sm={12}
            xs={12}
            style={{ margin: "1rem auto", padding: "1rem" }}
            direction="row"
            justifyContent="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <InfoOutlined
                style={{
                  width: "4rem",
                  height: "4rem",
                  color: Theme.palette.primary.main,
                }}
              />
              <Typography
                style={{ color: "#555555", fontSize: "20px", fontWeight: 700 }}
                align="center"
              >
                ¿Está Seguro?
              </Typography>
              <Typography variant="h6">
                {`Nº de Remito ${remitoSeleccionado?.factura_remito}`}
              </Typography>
            </Grid>
            <Typography
              variant="body1"
              align="center"
              style={{
                marginTop: "1rem",
                color: Theme.palette.error.main,
                fontFamily: "Roboto",
                fontStyle: "normal",
              }}
            >
              Nota: si existe este remito para otro proveedor también se
              eliminará.
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ padding: "1rem" }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="small"
              style={{ marginRight: "0.5rem" }}
              onClick={() => handleClose()}
            >
              Volver
            </Button>
            <ButtonLoading
              onClick={() => eliminarRemito()}
              disabled={cargando ? true : false}
              isLoading={cargando}
            >
              Confirmar
            </ButtonLoading>
          </Grid>
        </Grid>
      </Modal>
      <CustomSnackbar
        message={snackState.message}
        open={snackState.open}
        severity={snackState.severity}
        onClose={onClose}
      />
    </>
  );
};
export default ModalEliminarRemito;
