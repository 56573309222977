import { CircularProgress, Grid, Typography } from "@material-ui/core";

const LoadingText = ({ title }) => {
  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      style={{ height: "3rem" }}
    >
      <Typography style={{ fontSize: 18, marginRight: "0.5rem" }}>
        {title}
      </Typography>
      <CircularProgress size={25} color="primary" />
    </Grid>
  );
};

export default LoadingText;
