const mostrarErrorEP = (error, setSnackState) => {
  const data = error?.response?.data;
  const status = error?.response?.status;

  if (status > 499 && status < 600) {
    setSnackState({
      open: true,
      severity: "error",
      message: "Error en el Servidor",
      autoHideDuration: 2000,
    });
  } else if (status === 404) {
    setSnackState({
      open: true,
      severity: "error",
      message: "404 No Encontrado",
      autoHideDuration: 2000,
    });
  } else if (status === 423) {
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        setSnackState({
          open: true,
          severity: "warning",
          message: element.toString(),
          autoHideDuration: 3000,
        });
      }
    }
  } else {
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        setSnackState({
          open: true,
          severity: "error",
          autoHideDuration: 3000,
          message: element.toString(),
        });
      }
    }
  }
};

export default mostrarErrorEP;
