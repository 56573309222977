/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Checkbox,
    Grid,
    InputAdornment,
    MenuItem,
    Modal,
    Typography,
} from "@material-ui/core";
import rules from "constants/rules";
import moment from "moment";

// HOOK
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useGetFormulas } from "hook/useGetFormulas";

// COMPONENTS
import InputField from "components/Layout/inputCustom/inputField";
import SelectCustom from "components/Layout/select/selectCustom";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import Card from "components/card";
import StyledTableCell from "components/styled/tableCell";
import StyledTableRow from "components/styled/tableRow";
import TableGeneric from "components/tableGeneric/tableGeneric";

// STYLES
import { makeStyles } from "@material-ui/core/styles";
import ordenesService from "services/ordenes.service";

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: 160
    },
}));

const ModalCambiarHormigon = ({ open, close, orden, setSnackState, mostrarErrorEP, actualizarOrden }) => {

    const classes = useStyles();

    // Estado de envio de datos al BE
    const [ loading, setLoading] = useState(false);

    // Estado Active checked Total
    const [totalChecked, setTotalChecked] = useState(false);

    // Query para obtener todas las formulas
    const { data: formulas } = useGetFormulas();

    // REACT HOOK FORM
    const { handleSubmit, control, setValue, watch, clearErrors, setError } = useForm({
        defaultValues: {
            formula_id: orden?.formula,
            tipo_hormigon: orden?.formula__nombre,
            cantidad_restante: orden?.cant_restante,
            precio_original: orden?.precio_original,
            cantidad_formula: 1,
            saldo_remanente: 0,
            precio_actual: 0,
            fecha_vigente: moment().format("YYYY-MM-DD"),
            cantidad: 0,
            tipo_formula: "",
        },
    });
   
    // FUNCION CONTROLA CHECK DE TOTAL
    const handleCheckedTotal = (e, o) => {
        if (e.target.checked) {
            setTotalChecked(true);
            setValue("cantidad_formula", watch("cantidad_restante"));
        } else {
            setTotalChecked(false);
            setValue("cantidad_formula", 1);
        };
    }

    // HEADERS TABLE
    const headersTableEstadoCuenta = [
        {
            name: "Tipo de Hormigón",
        },
        {
            name: "Cant. Restante",
        },
        {
            name: "Precio Original",
        },
        {
            name: "Total",
        },
        {
            name: "Cantidad",
        },
    ];

    // FUNCION ENVIAR DATOS AL BACK
    const onSubmit = async (data) => {
        let dataSend = {
            swap_formula:
            {
                /***
                * cant_restante: cantidad disponible en la OCA
                * cantidad: cantidad de mts3 a cambiar del hormigón seleccionado
                * id: id de la linea de orden dónde se encuentra el hormigón
                */
                cant_restante: data?.cantidad_restante,
                id: orden?.id,
                cantidad: data?.cantidad_formula,
                // Datos del nuevo hormigón
                formula: data?.tipo_formula,
                concepto: null,
                precio_original: data?.precio_actual,
                fecha_vigencia_precio: data?.fecha_vigente,
                cantidad_m3: data?.cantidad
            }
        }

        // Validar precio_actual
        if (data?.precio_actual > 0) {
            try {
                setLoading(true);
                const res = await ordenesService.cambiarHormigon(orden?.orden_id, dataSend);
                if (res.status > 199 && res.status < 300) {
                    setSnackState({
                        open: true,
                        severity: "success",
                        message: "Se ha cambiado hormigón de orden de manera exitosa",
                    });
                }
                actualizarOrden();
            } catch (error) {
                mostrarErrorEP(error, setSnackState);
            }
        } else {
            setError("precio_actual", { type: "noEqual", message: "Ingrese precio" })
        }
        setLoading(false);
        handleClose();
    };

    // CLOSE MODAL
    const handleClose = () => {
        close(false);
    };

    // EFFECT PARA CALCULAR SALDO REMANENTE
    useEffect(() => {
        if (Number(watch("cantidad_formula")) > 0 && Number(watch("cantidad_formula")) <= watch("cantidad_restante")) {
            clearErrors("cantidad_formula");
            setValue("saldo_remanente", watch("cantidad_formula") * watch("precio_original"));
        } else {
            setError("cantidad_formula", { type: "noEqual", message: "Cantidad inválida" })
        }
    }, [watch("cantidad_formula")]);

    // EFFECT PARA CALCULAR CANTIDAD DE NUEVO HORMIGON
    useEffect(() => {
        clearErrors("precio_actual");
        if (watch("precio_actual") > 0) {
            setValue("cantidad", (watch("saldo_remanente") / watch("precio_actual")).toFixed(1));
        } else {
            setValue("cantidad", 0);
        }
    }, [watch("precio_actual")]);

    return (
        <Modal open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xl={6}
                    lg={6}
                    md={7}
                    sm={8}
                    xs={10}
                    style={{
                        backgroundColor: "#ffffff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: 10,
                    }}
                >
                    <Card title="Cambiar Hormigón" justifyContent="center">
                        <Grid container style={{ padding: "1rem" }}>
                            <Grid container>
                                <TableGeneric
                                    size="small"
                                    stickyHeader
                                    headers={headersTableEstadoCuenta}
                                >
                                    <StyledTableRow
                                        hover>
                                        <StyledTableCell>
                                            {watch("tipo_hormigon") ?? "-"}
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            {watch("cantidad_restante") ?? "-"}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {watch("precio_original")?.toLocaleString("es-AR", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                                style: "currency",
                                                currency: "ARS",
                                            }) ?? "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Checkbox
                                                color="primary"
                                                onChange={(e, o) =>
                                                    handleCheckedTotal(e)
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell width={160}>
                                            <InputField
                                                fullWidth
                                                name="cantidad_formula"
                                                size="small"
                                                type="number"
                                                disabled={totalChecked}
                                                control={control}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableGeneric>
                            </Grid>
                            <Grid container spacing={1} alignItems="center" style={{ marginTop: "0.5rem" }}>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        {"Saldo Remanente:"}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ width: "120px" }}>
                                    <Typography
                                        variant="body2"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        {watch("saldo_remanente")?.toLocaleString("es-AR", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                            style: "currency",
                                            currency: "ARS",
                                        }) ?? "-"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems="center" style={{ marginTop: "0.5rem" }}>
                                <Grid item>
                                    <Typography
                                        variant="h6"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        {"Nuevo Hormigón:"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems="center" style={{ marginTop: "0.5rem" }}>
                                <Grid item style={{ width: "160px" }}>
                                    <SelectCustom
                                        name="tipo_formula"
                                        control={control}
                                        size="small"
                                        label="Tipo Hormigón"
                                        rules={rules.generic}
                                        MenuProps={{ classes: { paper: classes?.menuPaper } }}
                                    >
                                        {formulas?.length > 0 &&
                                            formulas?.map((item) => (
                                                <MenuItem value={item.id} key={item.id}>
                                                    {item.nombre}
                                                </MenuItem>
                                            ))
                                        }
                                    </SelectCustom>
                                </Grid>
                                <Grid item style={{ width: "160px" }}>
                                    <InputField
                                        name="precio_actual"
                                        size="small"
                                        type="number"
                                        label="Precio Actual"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        control={control}
                                        rules={rules.numeroDecimalRequerido}
                                    />
                                </Grid>
                                <Grid item style={{ width: "160px" }}>
                                    <InputField
                                        name="fecha_vigente"
                                        size="small"
                                        type="date"
                                        label={"Fecha Vigente"}
                                        control={control}
                                        rules={rules.generic}
                                    />
                                </Grid>
                                <Grid item style={{ width: "160px" }}>
                                    <InputField
                                        fullWidth
                                        name="cantidad"
                                        label={"Cantidad"}
                                        size="small"
                                        type="number"
                                        disabled={true}
                                        control={control}
                                        rules={rules.numeroDecimalRequerido}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} direction="row" justifyContent="flex-end" style={{ marginTop: "0.5rem" }}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                        children="Volver"
                                        style={{ textTransform: "none" }}
                                        onClick={() => handleClose()}
                                    />
                                </Grid>
                                <Grid item>
                                    <ButtonLoading
                                        isLoading={loading}
                                        disabled={false}
                                        children="Confirmar"
                                        type="submit"
                                        style={{ textTransform: "none" }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </form>
        </Modal>
    );
};


export default ModalCambiarHormigon;
