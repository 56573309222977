import { React, useState } from "react";
import Protected from "protected";
import { Button, Grid, Typography } from "@material-ui/core";
import Theme from "theme";
import { useHistory } from "react-router-dom";
import usuarioService from "services/usuario.service";
import RootContainer from "layout/rootContainer";

import ContainerCustom from "components/comtainerCustom";
const ErrorPermiso = () => {
  const [solicitudEnviada, setSolicitudEnviada] = useState(false);
  const history = useHistory();
  const handleSubmit = async () => {
    try {
      const res = await usuarioService.requestAccess({
        modulo: history?.location?.state?.modulo,
      });

      if (res.status === 200) {
        setSolicitudEnviada(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Protected>
    <RootContainer>
      <ContainerCustom>
        <Grid
          container
          item
          xl={3}
          lg={4}
          md={6}
          sm={8}
          xs={12}
          style={{
            borderRadius: 10,
            backgroundColor: "#ffffff",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            marginTop: "1rem",
            height: "100%"
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            alignContent="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              width: "100%",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                color: "#ffffff",
              }}
            >
              {solicitudEnviada ? "Solicitud Enviada" : "Acceso Denegado"}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="column"
            style={{
              padding: "2rem",
            }}
          >
            {solicitudEnviada ? (
              <Typography style={{ textAlign: "center", fontSize: "1.2rem" }}>
                Su solicitud ha sido enviada. Para mayor información comuníquese
                con el administrador a través del siguiente email:
              </Typography>
            ) : (
              <Typography style={{ textAlign: "center", fontSize: "1.2rem" }}>
                Usted no tiene acceso a este módulo del sistema. Si cree que
                debe tener acceso a este módulo, haga click en el botón "Enviar
                solicitud de acceso" o comuníquese con el administrador a través
                del siguiente email:
              </Typography>
            )}
            <Typography
              color="primary"

              style={{
                textAlign: "center",
                fontSize: "1.2rem",
              }}
            >
              vivian_gauna@hotmail.com
            </Typography>
          </Grid>

          <Grid
            container
            item
            direction="column"
            style={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
              marginBottom: "1rem",
            }}
          >
            <Button
              variant="contained"
              color={solicitudEnviada ? "grey" : "primary"}
              onClick={() => handleSubmit()}
              disabled={solicitudEnviada}
              style={{ marginBottom: "1.5rem" }}
            >
              Enviar solicitud de acceso
            </Button>
          </Grid>
        </Grid>
      </ContainerCustom>
    </RootContainer>
    </Protected>
  );
};
export default ErrorPermiso;
