import {
    Box,
    Button,
    Collapse,
    Grid,
    MenuItem,
    Typography,
} from "@material-ui/core";
import Theme from "theme";
import Modal from "@material-ui/core/Modal";
import { Alert, Stack } from "@mui/material";
import PropTypes from "prop-types";

// COMPONENTS
import InputField from "components/Layout/inputCustom/inputField";
import rules from "constants/rules";

// SERVICES
import proveedoresService from "services/proveedores.service";

// HOOK
import { useForm } from "react-hook-form";
import { useGetAllProviders } from "../../hook";
import SelectCustom from "components/Layout/select/selectCustom";

// PAGE STYLES
import useStyles from "../../crear-editar-proveedores/CrearEditarProveedoresStyles";


const CrearEditarChoferModal = ({
    choferSeleccionado, 
    onClose,
    open,
    onError,
    onSuccess,
}) => {
    const classes = useStyles();

    //  HOOK FORM
    const { handleSubmit, control, watch, reset } = useForm({
        defaultValues: {
            nombre: choferSeleccionado?.nombre ?? "",
            dni: choferSeleccionado?.dni ?? "",
            proveedor: choferSeleccionado?.proveedor_estado ? choferSeleccionado?.proveedor_id : null
        }
    })

    // Query Success
    const onSuceess = (data) => {
        // Seteo los datos del paginado
        // setActual(data?.actual);
        // setTotal(data?.total_paginas);
    };

    // QUERY PARA OBTENER LISTADO DE PROVEEDORES
    /**
     * ! PEDIR CESAR 
     * endppoint que me traiga lista de proveedores sin paginar para el select.
     * TODO modificar queries para llamar al hook correcto
     */
    const {
        data: providersList
    } = useGetAllProviders({
        filtro: "",
        debouncedValue: "",
        onSuccess: onSuceess,
    });

    // FUNCION CREAR EDITAR CHOFER
    const onSubmit = async (data) => {
        let endpoint = "";
        let type = "";        
        if (choferSeleccionado?.id) {
            type = "Edición";
            endpoint = proveedoresService?.editAuhthorizedDriver(choferSeleccionado?.id, data);
        } else {
            type = "Creación";
            endpoint = proveedoresService?.createAuhthorizedDriver(data);
        }
        try {
            const res = await endpoint;
            if (res.status > 199 && res.status < 299) {
                onSuccess(`${type} de Chofer de manera exitosa`);
            } else {
                onError(`Error en la ${type}`);
            }
            reset();
            onClose();
        } catch (error) {
            onError(error?.response?.data?.message ?? "Error en el servidor");
            reset();
            onClose();
        }
    }
    
    return (
        <Modal
            onClose={() => {
                onClose();
            }}
            open={open}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "ffffff",
                        borderRadius: 10,
                        boxShadow: 20,
                    }}
                >
                    {/* Titulo de la vista */}
                    <Grid
                        container
                        direction="row"
                        justifyContent={"center"}
                        alignItems="center"
                        alignContent="center"
                        style={{
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            backgroundColor: "#001176",
                            minHeight: "2.5rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                        }}
                    >
                        <Typography
                            variant="h5"
                            style={{ height: "2rem", color: "white", textAlign: "center" }}
                        >
                            {`${choferSeleccionado?.id ? `Editar` : `Nuevo`} Chofer`}
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        style={{
                            backgroundColor: "#ffffff",
                            padding: "2rem",
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                        }}
                    >
                        <Grid item>
                            <InputField
                                name="nombre"
                                placeholder="Ingrese nombre y apellido del chofer"
                                control={control}
                                rules={rules.generic}
                                label="Nombre y Apellido:*"
                                type="text"
                                InputLabelProps={{ shrink: true }}

                            />
                        </Grid>
                        <Grid item style={{ marginTop: "1rem" }}>
                            <InputField
                                name="dni"
                                placeholder="Ingrese el DNI del chofer"
                                control={control}
                                rules={rules.dni}
                                label="DNI:*"
                                type="text"
                                InputLabelProps={{ shrink: true }}

                            />
                        </Grid>
                        <Grid item style={{ marginTop: "1rem" }}>
                            <SelectCustom
                                control={control}
                                rules={rules.generic}
                                placeholder="Seleccione una Empresa"
                                name="proveedor"
                                label="Empresa:*"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                MenuProps={{ classes: { paper: classes?.menuPaper } }}
                            >
                                {providersList?.results?.length > 0 &&
                                    providersList?.results?.map((item) => (
                                        <MenuItem key={item.id} value={item.id
                                        }>
                                            {item.razon_social}
                                        </MenuItem>
                                    ))}
                            </SelectCustom>

                        </Grid>
                        <Grid container style={{ marginTop: "1rem" }}>
                                  <Collapse in={choferSeleccionado?.id && !watch("proveedor") && !choferSeleccionado?.proveedor_estado}>

                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                      <Alert variant="outlined" severity="warning" size="small">
                                        Empresa {choferSeleccionado?.proveedor} ha sido eliminada, es necesario seleccionar empresa actual.
                                      </Alert>
                                    </Stack>

                                  </Collapse>
                                </Grid>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            style={{ marginTop: "1rem" }}
                            justifyContent="flex-end"
                        >
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    style={{
                                        backgroundColor: "#ffffff",
                                        color: Theme.palette.primary.main,
                                        borderColor: Theme.palette.primary.main,
                                        borderWidth: "2px",
                                    }}
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Volver
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    type="submit"
                                >
                                    Confirmar
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>

                </Box>
            </form>
        </Modal>
    );
};

export default CrearEditarChoferModal;

CrearEditarChoferModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    choferSeleccionado: PropTypes.object,
};
