//HOOK
import { useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDebounce } from "hook/useDebounce";
import { useEstadoCuenta } from "../hook";
import { useOrdenesPendientes } from "../../ordenes/hook";
import { useReactToPrint } from "react-to-print";
// MATERIAL-UI
import { Button, Grid, Typography } from "@material-ui/core";

// COMPONENTS
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import { LogIn } from "context/LogInContext";
import VerificarAcceso from "components/verificarAcceso";
import SearchBar from "components/Layout/searchBar/SearchBar";
import TableRowEstadoCuentas from "../components/tableRowEstadoCuenta";
import HeaderEstadoCuenta from "../components/headerEstadoCuenta";
import TableGeneric from "components/tableGeneric/tableGeneric";
import Card from "components/card";
import LoadingText from "components/loadingText";
import CustomSnackbar from "components/Layout/CustomSnackbars";

//CONSTANTS
import { headersTableEstadoCuenta } from "../constants/headersTableEstadoCuenta";

// STYLES
import useStyles from "../../proveedores/listado-proveedores/ListadoProveedoresStyle";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
//import { Button } from "@mui/material";
import PrintIcon from "@material-ui/icons/Print";


const EstadoDeCuenta = () => {
  const history = useHistory();
  const classes = useStyles();
  const { logged } = useContext(LogIn);

  // History State con Datos del Cliente
  const { cliente } = history?.location?.state;

  // Estado para Busqueda de la tabla de Materiales
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  //Estado para verificar acceso al módulo
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  //Hook Form
  const { control } = useForm();

  // ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // QUERY ORDENES PENDIENTES
  const { data: dataOrdenes, isFetching: isLoadingDataOrdenes } = useOrdenesPendientes({ idCliente: cliente?.id, debouncedSearch });

  // QUERY ESTADO DE CUENTAS
  const { data: dataEstadoCuentaCliente, isFetching: isLoadingEstadoCuentaCliente } = useEstadoCuenta({ idCliente: cliente?.id });
  // 
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Función Filtrar lista de Materiales
  const handleBusqueda = (text) => {
    if (text.length > 0) {
      setSearch(text);
    } else {
      setSearch("");
    }
  };

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="cuenta-corriente"
            history={history}
            logged={logged}
          />
        ) : (
          <>
            {acceso && (
              <Grid
                container
                style={{
                  margin: "2rem 1rem 3rem 1rem",
                  overflow: "auto",
                }}
              >
                <Grid
                
                  xs={12}
                  container
                  
                  justifyContent="flex-end"
                  direction="row"
                //   style={{ marginTop: "2rem" }}
                >
                  <Grid item style={{ marginRight: "0.5rem" }}>
                    <ButtonLoading
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        textTransform: "capitalize",
                        margin: "0.5rem 0",
                      }}
                      startIcon={<PrintIcon />}
                      onClick={handlePrint}
                    // isLoading={loadingInforme}
                    >
                      Imprimir Informe
                    </ButtonLoading>
                  </Grid>
                  <Grid item style={{ marginRight: "0.5rem" }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      style={{
                        textTransform: "capitalize",
                        margin: "0.5rem 0.5rem 0.5rem 0",
                      }}
                      onClick={() => history.goBack()}
                    >
                      Volver
                    </Button>
                  </Grid>
                </Grid>


                <Grid ref={componentRef} container style={{ padding: "0 1rem" }}>
                  {/* Cabecera de Pantalla Datos Cliente y Estado resumen de cuenta */}
                  <HeaderEstadoCuenta data={dataEstadoCuentaCliente} dataOrdenes={dataOrdenes} isLoading={isLoadingEstadoCuentaCliente} cliente={cliente} />

                  {/* Buscar dentro tabla */}
                  <Grid container item>
                    <Grid
                      direction="row"
                      justifyContent="flex-end"
                      item
                      container
                    >
                      <SearchBar
                        placeholder="Buscar Por N° Orden"
                        onChange={(e) => handleBusqueda(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {/* Table */}
                  <Grid
                    container
                    item
                    style={{ padding: "1rem 0rem 1rem 0rem" }}
                  >
                    <Card title="Resúmen de Ordenes" justifyContent="center">
                      {isLoadingDataOrdenes ? (
                        <LoadingText title="Cargando" />
                      ) : dataOrdenes?.results?.length > 0 ? (
                        <>
                          <TableGeneric
                            size="small"
                            stickyHeader
                            headers={headersTableEstadoCuenta}
                            styles={classes?.tableProvidersMaterials}
                          >
                            {dataOrdenes?.results?.map((item, indexItem) => (
                              <TableRowEstadoCuentas
                                key={indexItem}
                                item={item}
                                control={control}
                              />
                            ))}
                          </TableGeneric>
                        </>
                      ) : (
                        <Grid
                          container
                          justifyContent="center"
                          style={{ margin: "0.5rem" }}
                        >
                          <Typography variant="subtitle1">
                            No se encontraron resultados
                          </Typography>
                        </Grid>
                      )}
                    </Card>

                    <CustomSnackbar
                      message={snackState.message}
                      open={snackState.open}
                      severity={snackState.severity}
                      autoHideDuration={snackState.autoHideDuration}
                      onClose={onClose}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </RootContainer>
    </Protected>
  );
};
export default EstadoDeCuenta;
