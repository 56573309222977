// Hook para traer Todos los materiales cargados
import { useQuery } from "react-query";
// Services
import MaterialesService from "services/materiales.service";

export const useGetMaterialesSinPaginar = (filters) => {
  // FUNCION PARA TRAER Todos los materiales
  const obtenerMateriales = async () => {
    const { data } = await MaterialesService.getMaterialesSinPaginar();
    return data;
  };

  // FUNCION PARA FILTRAR LOS Materiales por tipo
  const filtrarMaterialPorTipo = (materiales) => {
    if (filters) {
      return materiales.filter((unmaterial) =>
        unmaterial.nombre.toLowerCase().includes(filters.toLowerCase()),
      );
    } else {
      return materiales;
    }
  };

  return useQuery(["materiales"], () => obtenerMateriales(), {
    select: (materiales) => filtrarMaterialPorTipo(materiales),
  });
};
