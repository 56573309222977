import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { Grid, Typography, TextField } from "@material-ui/core";
import { ReportProblemOutlined, InfoOutlined } from "@material-ui/icons";
import Theme from "theme";

export default function CustomConfirmDialog({
  confirmDialog,
  setConfirmDialog,
  cambiarEstado,
  turno,
}) {
  const handleClose = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const [hora, setHora] = useState(turno?.hora_salida);

  const handleHora = (e) => {
    setHora(e);
  };

  useEffect(() => {
    handleHora(turno?.hora_salida);
  }, [turno]);

  return (
    <Dialog onClose={handleClose} open={confirmDialog.isOpen}>
      <Grid style={{ padding: "1rem 4rem" }}>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {confirmDialog.mode !== null &&
            confirmDialog.mode !== undefined &&
            confirmDialog.mode === "red" ? (
              <ReportProblemOutlined
                style={{ width: "4rem", height: "4rem", color: "#ff0000" }}
              />
            ) : (
              <InfoOutlined
                style={{
                  width: "4rem",
                  height: "4rem",
                  color: Theme.palette.primary.main,
                }}
              />
            )}

            <Typography style={{ color: "#555555" }} align="center">
              ¿Está Seguro?
            </Typography>
          </Grid>
          <Typography style={{ color: "#555555" }} align="center">
            {confirmDialog.title}
          </Typography>
          <Grid align="center">
            <Typography style={{ marginTop: "1rem", fontWeight: "bold" }}>
              Hora de Entrega
            </Typography>

            <TextField
              id="time"
              type="time"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              defaultValue={turno?.hora_salida}
              variant="outlined"
              style={{ width: "100%", margin: "1rem 0" }}
              onChange={(e) => handleHora(e.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
            variant="contained"
            color="default"
          >
            CANCELAR
          </Button>
          <Button
            onClick={() => {
              cambiarEstado(turno, "Entregado", hora);
              confirmDialog.onConfirm();
              setConfirmDialog({ ...confirmDialog, isOpen: false });
            }}
            variant="contained"
            style={{
              color: "#ffffff",
              backgroundColor:
                confirmDialog.mode === "red"
                  ? "#ff0000"
                  : Theme.palette.primary.main,
            }}
          >
            ACEPTAR
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
}

CustomConfirmDialog.propTypes = {
  confirmDialog: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    mode: PropTypes.string,
  }).isRequired,
  setConfirmDialog: PropTypes.func.isRequired,
};
