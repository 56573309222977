import { makeStyles } from "@material-ui/core/styles";
import Theme from "theme";
const useStyles = makeStyles({
  root: {
    "& > *": {
      marginTop: Theme.spacing(2),
    },
  },
  headerTable: {
    height: "3rem",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  generalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3rem",
    marginBottom: "1rem",
  },
  headerTableTittle: {
    color: "white",
    textAlign: "center",
  },
  gridContainerText: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "1rem",
  },
  textContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    minWidth: "250px",
    justifyContent: "flex-start",
  },
  textStyle: {
    fontSize: 10,
    fontWeight: 700,
    color: "rgba(0, 0, 0, 0.54)",
  },
  textStyle2: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "rgba(67, 59, 59, 0.87)",
    fontWeight: 700,
  },
  tableContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  table: {
    width: "100%",
  },
  tableRow: {
    whiteSpace: "nowrap",
    alignItems: "center",
  },
  tableCellHeaders: {
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",
    color: "rgba(67, 59, 59, 0.87)",
  },
  tableCell: {
    fontSize: "16px",
  },
  totalRow: {
    backgroundColor: "#F3F3F3",
  },
  linea: {
    content: "",
    borderRight: "5px solid rgba(0, 0, 0, 0.54)",
    zIndex: 1,
    float: "center",
  },
  totalCell: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
  },
});
export default useStyles;
