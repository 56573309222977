import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  generalContainer: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  headerTable: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    minHeight: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  headerTableTittle: {
    eight: "2rem",
    color: "white",
    textAlign: "center",
  },
  textPrimary: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.54)",
    textTransform: "uppercase",
  },
  textSecondary: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    textTransform: "uppercase",
  },
  gridContainerText: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "1rem",
  },
  textContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    minWidth: "250px",
    justifyContent: "flex-start",
  },
  textStyle: {
    fontSize: 10,
    fontWeight: 700,
    color: "rgba(0, 0, 0, 0.54)",
  },
  textStyle2: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "rgba(67, 59, 59, 0.87)",
    fontWeight: 700,
  },
});
export default useStyles;
