import { InputAdornment } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import IconButtonAction from "components/iconButtonAction/iconButtonAction";
import InputDate from "components/inputs/newInputDate";
import InputNumber from "components/inputs/newInputNumber";
import InputText from "components/inputs/newInputText";
import NewSelect from "components/inputs/newSelect";
import StyledTableCell from "components/styled/tableCell";
import StyledTableRow from "components/styled/tableRow";
import rules from "constants/rules";
import { NotificationContext } from "context/notification/NotificationContext";
import { useContext } from "react";
import { names } from "./const";
import { useStyles } from "./styles";

export default function RowLineas({
  items,
  item,
  indexItem,
  control,
  confirmEliminarLinea,
  handleChangeInput,
  handleCredito,
  anyCredito,
  isCheque,
  variosPagosAlasMismasFacturas,
}) {
  const styles = useStyles();
  const { isNotificationsEnabled } = useContext(NotificationContext);

  return (
    <StyledTableRow>
      {items?.length > 1 && (
        <StyledTableCell
          style={{ borderBottom: 0, padding: "0.4rem 0.4rem 0.4rem 0" }}
        >
          <IconButtonAction onClick={() => confirmEliminarLinea(indexItem)}>
            <Delete
              style={{
                height: "1.2rem",
                width: "1.2rem",
              }}
            />
          </IconButtonAction>
        </StyledTableCell>
      )}

      <StyledTableCell
        style={{ borderBottom: 0, padding: "0.4rem 0.4rem 0.15rem 0.4rem" }}
      >
        <NewSelect
          name={names?.formaPago + item?.id}
          label="Forma de Pago:"
          control={control}
          options={
            isNotificationsEnabled
              ? variosPagosAlasMismasFacturas
                ? [
                    "Efectivo",
                    "Transferencia Bancaria",
                    "Cheque",
                    "E-Cheq",
                    "Tarjeta Débito/Crédito",
                    "Retenciones Impositivas",
                    {
                      name: "Haber de Cuenta Corriente",
                      value: "Haber de Cuenta Corriente",
                      disabled: anyCredito,
                    },
                  ]
                : [
                    "Efectivo",
                    "Transferencia Bancaria",
                    "Cheque",
                    "E-Cheq",
                    "Tarjeta Débito/Crédito",
                    "Nota de Crédito",
                    "Nota de Débito",
                    "Retenciones Impositivas",
                    {
                      name: "Haber de Cuenta Corriente",
                      value: "Haber de Cuenta Corriente",
                      disabled: anyCredito,
                    },
                  ]
              : [
                  "Efectivo",
                  "Cheque",
                  {
                    name: "Haber de Cuenta Corriente",
                    value: "Haber de Cuenta Corriente",
                    disabled: anyCredito,
                  },
                ]
          }
          onChange={(e, value) => {
            handleChangeInput(value, item, names?.formaPago);
            handleCredito(item, value);
          }}
          rules={rules?.generic}
          style={{ minWidth: "10rem" }}
        />
      </StyledTableCell>

      {isCheque(names?.formaPago + item?.id) && (
        <StyledTableCell style={styles?.tableCell}>
          <InputText
            name={names?.nro + item?.id}
            label="Número:"
            control={control}
            onChange={(e, value) => handleChangeInput(value, item, names?.nro)}
            rules={rules?.generic}
            style={{ minWidth: "6rem" }}
          />
        </StyledTableCell>
      )}

      <StyledTableCell style={styles?.tableCell}>
        <InputNumber
          name={names?.monto + item?.id}
          label="Monto:"
          control={control}
          onChange={(e, value) => handleChangeInput(value, item, names?.monto)}
          rules={rules?.numeroDecimalRequerido}
          style={{ minWidth: "6rem" }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </StyledTableCell>

      <StyledTableCell style={styles?.tableCell}>
        <InputDate
          name={names?.fechaPago + item?.id}
          label="Fecha de pago:"
          control={control}
          onChange={(e, value) =>
            handleChangeInput(value, item, names?.fechaPago)
          }
          rules={rules?.observacion}
        />
      </StyledTableCell>

      {isCheque(names?.formaPago + item?.id) && (
        <StyledTableCell style={styles?.tableCell}>
          <InputDate
            name={names?.fechaVto + item?.id}
            label="Fecha de Vto:"
            control={control}
            onChange={(e, value) =>
              handleChangeInput(value, item, names?.fechaVto)
            }
            rules={rules?.generic}
          />
        </StyledTableCell>
      )}

      <StyledTableCell style={styles?.tableCell}>
        <InputText
          name={names?.observacion + item?.id}
          label="Observación:"
          control={control}
          onChange={(e, value) =>
            handleChangeInput(value, item, names?.observacion)
          }
          style={{ minWidth: "8rem" }}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}
