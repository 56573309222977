// Hook para traer Todos los proveedores materiales sin paginar
import { useQuery } from "react-query";
// Services
import ProveedoresService from "services/proveedores.service";

export const useGetCanterasAreneras = (filters) => {
  // FUNCION PARA TRAER TODOS LOS PROVEEDORES MATERIALES
  const obtenerCanterasAreneras = async () => {
    const { data } = await ProveedoresService.getCanterasAreneras();
    return data;
  };

  // FUNCION PARA FILTRAR LOS PROVEEDORES POR NOMBRE Y CUIT/DNI
  const filtrarCanterasArenerasPorNombre = (cant_are) => {
    if (filters) {
      return cant_are.filter((unprov) =>
        unprov.nombre.toLowerCase().includes(filters.toLowerCase()),
      );
    } else {
      return cant_are;
    }
  };

  return useQuery(["canteras_areneras"], () => obtenerCanterasAreneras(), {
    select: (canterasAreneras) =>
      filtrarCanterasArenerasPorNombre(canterasAreneras),
  });
};
