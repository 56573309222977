import ModalGeneric from "components/modalGeneric";
import TableCustomizable from "components/tableCustomizable";
import { useState } from "react";
import TableRowMediosPago from "./tableRowMediosPago";
import { Button, Grid, TableCell, TableRow } from "@material-ui/core";
import dayjs from "dayjs";
import AddIcon from "@material-ui/icons/Add";
import { toContable } from "constants/funciones/toContable";
import { useForm } from "react-hook-form";
import { Alert } from "@mui/material";

export default function MediosPago({
  open,
  handleClose,
  totalOrden,
  setConfirmDialog,
  setMediosPago,
}) {
  const headerStyle = {
    paddingTop: "0.1rem",
    paddingBottom: "0.1rem",
    border: 0,
    backgroundColor: "#CCCFE4",
  };

  const { control, watch, handleSubmit } = useForm();

  const [id, setId] = useState(1);
  const defaultLinea = {
    id,
    agregar: true,
    forma_pago: "Efectivo",
    monto: 0,
    fecha_pago: dayjs().format("YYYY-MM-DD"),
    observacion: "",
  };

  const [lineas, setLineas] = useState([defaultLinea]);

  const handleClickAgregar = () => {
    setLineas([...lineas, { ...defaultLinea, id: id + 1 }]);
    setId(id + 1);
  };

  const calcularTotalLineas = () => {
    const sumatoria = lineas?.reduce(
      (acumulador, item) => acumulador + item?.monto,
      0,
    );

    return Number(sumatoria.toFixed(2));
  };

  const calcularSaldo = () => {
    const saldo = totalOrden() - calcularTotalLineas();

    return Number(saldo.toFixed(2));
  };

  const onSubmit = () => {
    const lineasLimpias = lineas?.map((item) => {
      if (item?.forma_pago !== "Cheque" && item?.forma_pago !== "E-Cheq") {
        item.nro_cheque = null;
        item.fecha_vencimiento = null;
      }

      const { agregar, id, ...nuevoItem } = item;

      return nuevoItem;
    });
    setMediosPago([...lineasLimpias]);
    handleClose();
  };

  return (
    <ModalGeneric
      title="Agregar Formas de Pago"
      justifyContent="center"
      open={open}
      handleClose={handleClose}
      xl={8}
      lg={9}
      md={10}
      sm={10}
      xs={11}
    >
      <TableCustomizable
        size="small"
        headers={[
          { name: "", style: { ...headerStyle, minWidth: "11rem" } },
          { name: "Monto", style: headerStyle },
          {
            name: calcularSaldo() < 0 ? "Saldo a Favor" : "Saldo",
            style: {
              ...headerStyle,
              minWidth: calcularSaldo() < 0 ? "8rem" : 0,
            },
          },
        ]}
        styleTableContainer={() => ({ marginTop: "0.5rem" })}
      >
        <TableRow>
          <TableCell style={{ borderBottom: 0 }}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleClickAgregar}
              style={{ textTransform: "none" }}
            >
              Agregar Línea
            </Button>
          </TableCell>

          <TableCell style={{ borderBottom: 0 }}>
            {toContable(totalOrden())}
          </TableCell>

          <TableCell style={{ borderBottom: 0 }}>
            {calcularSaldo() < 0
              ? toContable(calcularSaldo() * -1)
              : toContable(calcularSaldo())}
          </TableCell>
        </TableRow>
      </TableCustomizable>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TableCustomizable
          size="small"
          styleTableContainer={() => ({
            overflowX: "unset",
            overflowY: "auto",
            maxHeight: "45vh",
          })}
        >
          {lineas?.map((linea, index) => (
            <TableRowMediosPago
              key={linea?.id}
              index={index}
              linea={linea}
              lineas={lineas}
              setLineas={setLineas}
              control={control}
              watch={watch}
              setConfirmDialog={setConfirmDialog}
            />
          ))}
        </TableCustomizable>

        <Grid style={{ maxWidth: "28rem", padding: "0.5rem 1rem" }}>
          <Alert
            severity="info"
            style={{
              padding: "0 0.25rem",
              fontWeight: "bold",
            }}
          >
            Para confirmar, el "Saldo" debe ser $0,00
          </Alert>
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          style={{ padding: "0.5rem 1rem" }}
        >
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleClose}
            style={{ textTransform: "none", marginRight: "1rem" }}
          >
            Volver
          </Button>

          <Button
            size="small"
            variant="contained"
            color="primary"
            type="submit"
            disabled={calcularSaldo() !== 0}
            style={{ textTransform: "none" }}
          >
            Confirmar
          </Button>
        </Grid>
      </form>
    </ModalGeneric>
  );
}
