import { useQuery } from "react-query";
import facturacionServices from "services/facturacion.services";

// FUNCION PARA TRAER LISTA DE NOTAS(CREDITOS COMO DEBITOS)
const getAllNotes = async ({
  filtro,
  debouncedValue,
}) => {
  try {
    // make params to send api
    const params = {
      ...filtro,
      search: debouncedValue,
    };
    //check endpoint to make request
    const endpoint = `filtrarNotasPorFecha`;
    const res = await facturacionServices[endpoint](params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

// FUNCION PARA TRAER LINEAS DE UNA NOTA(CREDITOS COMO DEBITOS)
const getNoteLineDetails = async ({
  idNota,
}) => {
  try {
    const endpoint = `obtenerLineasDeNotaPorId`;
    const res = await facturacionServices[endpoint](idNota);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};


export function useGetAllNotes({
  filtro,
  debouncedValue,
  onSuccess,
}) {
  return useQuery(
    ["Notas", filtro, debouncedValue],
    () =>
      getAllNotes({
        filtro,
        debouncedValue,
      }),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );
}

export function useGetDetailsNoteById({
  idNota, onSuccess }) {
  return useQuery(
    ["DetalleLineasDeNotas", idNota],
    () =>
      getNoteLineDetails({
        idNota,
      }),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      enabled: false // (!) handle refetchs manually
    },
  );
}