import axios from "./axios.config";

class LogService {
  getLogs() {
    return axios.get("/api/logs/");
  }

  searchLogs(data) {
    return axios.get(`/api/logs/?search=${data}`);
  }
}

export default new LogService();
