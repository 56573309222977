import { makeStyles } from "@material-ui/core/styles";
import Theme from "theme";

const useStyles = makeStyles({
  headerTable: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    minHeight: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  headerTableTittle: {
    height: "2rem",
    color: "white",
    textAlign: "center",
  },

  tableContainer: {
    margin: "0 auto",
    maxHeight: "50vh",
    overflow: "auto",
  },

  tableContainerCrearFactura: {
    margin: "0 auto",
    maxHeight: "50vh",
    overflow: "auto",
    backgroundColor: "#ffffffff",
    boxShadow: "0px",
  },  
  tableCellHeaders: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    width: "auto",
    padding: "1rem",
  },
  tableCellHeadersFirst: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    width: "auto",
    padding: "1rem 0 1rem 1rem",
  },

  tableCellHeadersLast: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    width: "auto",
    padding: "1rem 1rem 1rem 0",
  },

  tableCell: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "left",
    padding: "0.5rem 1rem",
  },

  tableCellFirst: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "left",
    padding: "0.5rem 0 0.5rem 1rem",
  },

  tableCellLast: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "left",
    padding: "0.5rem 1rem 0.5rem 0",
  },

  totalRow: {
    backgroundColor: "#CCCFE4",
  },

  totalCell: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "186.69%",

    color: "rgba(67, 59, 59, 0.87)",
  },

  button2: {
    background: "FFF",
    color: "#001176",
    textTransform: "capitalize",
    marginLeft: "1rem",
    border: "2px solid #001176",
    maxHeight: "2rem",
  },

  tableCell2: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
  },

  tablecell3: {
    fontSize: "16px",
    whiteSpace: "nowrap",
  },
  compraAnticipadaHeaderItem: {
    fontWeight: 400,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
  },
  compraAnticipadaHeaderItemTitle: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    border: "none",
  },
  tableCellCompraAnticipada: {
    width: "18%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderStyle: "border-box"
  },
  iconButton: {
    width: "1.5rem",
    height: "1.5rem",
    marginRight: "0.5rem",
    borderRadius: 5,
    backgroundColor: Theme.palette.primary.main,
    color: "#ffffff",
  },
  resize: {
    fontSize: 11
  },

  icon: {
    height: "1.2rem",
    width: "1.2rem",
  },

  logo: {
    height: "4.5rem",
  },
});
export default useStyles;
