import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  generalContainer: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
    marginTop: "1rem",
    maxWidth: "100%",
  },
  headerTable: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    minHeight: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  headerTableTittle: {
    height: "2rem",
    color: "white",
    textAlign: "center",
    textTransform: "capitalize"
  },
  tableContainer: {
    margin: "2rem 1rem",
    overflow: "auto",
    width: "100%",
    maxHeight: "50vh",


  },
  head: {
    background: "#E5E5E5",
    height: "16px",
  },
  headTxt: {
    textAlign: "left",

    whiteSpace: "nowrap",
    fontSize: "16px",
    width: "auto",
  },
  headTxt2: {
    textAlign: "center",
    //maxWidth: "30px",
    whiteSpace: "nowrap",
    fontSize: "16px",
    width: "auto",

  },

  tableRow: {
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "left",
    whiteSpace: "nowrap",
    border: "none",
  },
  tableRow2: {
    textAlign: "center",
    fontSize: "18px",
    whiteSpace: "nowrap",
    border: "none",
  },
  botonEntrega: {
    background: "#001176",
    borderRadius: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.16px",
    color: "#FFFFFF",
    width: "135px",
    height: "25px",
    fontSize: "12px",
    lineHeight: "24px",
    textTransform: "capitalize",
  },
});
export default useStyles;
