/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import { useState, useEffect } from "react";
// MATERIAL-UI
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
// ICONS
import { Delete } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
// STYLES
import Theme from "theme";
// COMPONENTS
import InputDate from "components/inputs/newInputDate";
import InputText from "components/inputs/newInputText";
import NewSelect from "components/inputs/newSelect";
import InputNumber from "components/inputs/newInputNumber";
// VARIOS
import rules from "constants/rules";

export default function Lineas({
  control,
  watch,
  setConfirmDialog,
  factura,
  facturas,
  setFacturas,
  isNotificationsEnabled,
  setSnackState,
  error,
  setError,
}) {
  const classes = useStyles();
  const [id, setId] = useState(factura?.id_linea);
  const [formasPago, setFormasPago] = useState(factura?.formasPago ?? []);
  const [formasAgregar, setFormasAgregar] = useState(
    factura?.formasAgregar ?? [],
  );

  const obtenerCredito = () => {
    const found = factura?.formasPago?.find((item) => item?.credito);

    return found?.id;
  };
  const [anyCredito, setAnyCredito] = useState(obtenerCredito() ?? null);
  const [errorCredito, setErrorCredito] = useState(false);

  const [formasModificar, setFormasModificar] = useState([]);
  const [formasEliminar, setFormasEliminar] = useState([]);

  useEffect(() => {
    factura.formasAgregar = formasAgregar;
    factura.formasModificar = formasModificar;
    factura.formasEliminar = formasEliminar;
    factura.saldo = calcularSaldo();

    setFacturas([...facturas]);
  }, [formasPago, formasAgregar, formasModificar, formasEliminar]);

  useEffect(() => {
    setFormasPago([...formasPago]);
  }, []);

  const isCheq = (input) => {
    const value = watch(input);

    return value === "Cheque" || value === "E-Cheq";
  };

  const calcularSaldo = () => {
    const resultado = formasPago?.reduce(
      (acumulador, itemActual) =>
        itemActual?.forma_pago !== "Nota de Débito"
          ? acumulador + itemActual.monto
          : acumulador - itemActual.monto,
      0,
    );

    return Number((factura?.total - resultado).toFixed(2));
  };

  const agregarForma = () => {
    const forma = {
      id: id,
      isNew: true,
      observacion: null,
      fecha_pago: null,
      forma_pago: null,
      nro_cheque: null,
      monto: null,
      credito: false,
    };

    setId(id + 1);
    setFormasPago([...formasPago, forma]);
    setFormasAgregar([...formasAgregar, forma]);
  };

  const modificarForma = (index) => {
    const formaPago = formasPago[index];

    formasPago?.splice(index, 1, formaPago);
    setFormasPago([...formasPago]);

    if (!formaPago?.isNew) {
      setFormasModificar([
        ...formasModificar,
        { ...formaPago, id_linea: formaPago?.id },
      ]);

      formasModificar?.forEach((item, index) => {
        if (formaPago?.id === item?.id_linea) {
          formasModificar?.splice(index, 1, {
            ...formaPago,
            id_linea: formaPago?.id,
          });
          setFormasModificar(formasModificar);
        }
      });
    }
  };

  const eliminarForma = (index, forma) => {
    formasPago?.splice(index, 1);
    setFormasPago([...formasPago]);

    if (anyCredito && anyCredito === forma?.id) {
      setAnyCredito(null);
    }

    if (calcularSaldo() >= 0) {
      setErrorCredito(false);
      setError(error?.filter((item) => item !== factura?.id));
    }

    formasModificar?.forEach((item, index) => {
      if (forma?.id === item?.id) {
        formasModificar?.splice(index, 1);
        setFormasModificar(formasModificar);
      }
    });

    if (!forma?.isNew) {
      setFormasEliminar([...formasEliminar, forma?.id]);
    } else {
      formasAgregar?.forEach((item, index) => {
        if (forma?.id === item?.id) {
          formasAgregar?.splice(index, 1);
          setFormasAgregar(formasAgregar);
        }
      });
    }
  };

  useEffect(() => {
    if (errorCredito) {
      setSnackState({
        open: true,
        severity: "info",
        message: "No puede usar crédito teniendo saldo negativo",
        autoHideDuration: 5000,
      });
    }
  }, [errorCredito]);

  return (
    <Grid container style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}>
      <Grid container>
        <Button
          variant="contained"
          color="primary"
          size="small"
          children="Agregar Línea"
          startIcon={<AddIcon />}
          style={{ textTransform: "none" }}
          onClick={() => agregarForma()}
        />
      </Grid>

      <TableContainer style={{ paddingTop: "0.5rem" }}>
        <Table size="small">
          <TableBody>
            {formasPago
              ?.map((forma, indexForma) => (
                <TableRow key={forma?.id + factura?.id}>
                  {formasPago?.length > 1 && (
                    <TableCell
                      align="center"
                      style={{
                        padding: "0.5rem 0.5rem 0.8rem 0",
                        borderBottom: 0,
                        width: "1rem",
                      }}
                    >
                      <IconButton
                        size="small"
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 5,
                          backgroundColor: Theme.palette.primary.main,
                          color: "#ffffff",
                        }}
                        onClick={() =>
                          setConfirmDialog({
                            title: `ELIMINAR FORMA DE PAGO ${
                              forma?.forma_pago?.toUpperCase() ?? ""
                            }`,
                            isOpen: true,
                            onConfirm: () => {
                              eliminarForma(indexForma, forma);
                            },
                          })
                        }
                      >
                        <Delete
                          style={{
                            height: "1.2rem",
                            width: "1.2rem",
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  )}

                  <TableCell
                    align="left"
                    style={{
                      padding: "0.5rem 0.5rem 0.5rem 0",
                      borderBottom: 0,
                      width: "6rem",
                    }}
                    className={classes?.errorCredito}
                  >
                    <NewSelect
                      name={"forma_pago" + forma?.id + factura?.id}
                      label="Forma de Pago:"
                      control={control}
                      rules={rules.generic}
                      options={
                        isNotificationsEnabled
                          ? [
                              "Efectivo",
                              "Transferencia Bancaria",
                              "Cheque",
                              "E-Cheq",
                              "Tarjeta Débito/Crédito",
                              "Nota de Crédito",
                              "Nota de Débito",
                              "Retenciones Impositivas",
                              {
                                name: "Haber de Cuenta Corriente",
                                value: "Haber de Cuenta Corriente",
                                disabled: anyCredito,
                              },
                            ]
                          : [
                              "Efectivo",
                              "Cheque",
                              {
                                name: "Haber de Cuenta Corriente",
                                value: "Haber de Cuenta Corriente",
                                disabled: anyCredito,
                              },
                            ]
                      }
                      defaultValue={forma?.forma_pago && forma?.forma_pago}
                      focused={anyCredito === forma?.id && errorCredito}
                      onChange={(e, value) => {
                        forma.forma_pago = value;
                        forma.credito = value === "Haber de Cuenta Corriente";

                        if (
                          !anyCredito &&
                          value === "Haber de Cuenta Corriente"
                        ) {
                          setAnyCredito(forma?.id);

                          if (calcularSaldo() < 0) {
                            setErrorCredito(true);
                            setError([...error, factura?.id]);
                          }
                        } else if (anyCredito === forma?.id) {
                          setAnyCredito(null);
                        }

                        if (calcularSaldo() >= 0) {
                          setErrorCredito(false);
                          setError(
                            error?.filter((item) => item !== factura?.id),
                          );
                        }

                        modificarForma(indexForma);
                      }}
                    />
                  </TableCell>

                  {isCheq("forma_pago" + forma?.id + factura?.id) && (
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.8rem 0",
                        borderBottom: 0,
                        width: "6rem",
                      }}
                    >
                      <InputText
                        name={"nro_cheque" + forma?.id + factura?.id}
                        label="Número:"
                        control={control}
                        rules={rules.numberRequered}
                        defaultValue={forma?.nro_cheque && forma?.nro_cheque}
                        onChange={(e, value) => {
                          forma.nro_cheque = value;

                          modificarForma(indexForma);
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    style={{
                      padding: "0.5rem 0.5rem 0.8rem 0",
                      borderBottom: 0,
                      width: "6rem",
                    }}
                    className={classes?.errorCredito}
                  >
                    <InputNumber
                      name={"monto" + forma?.id + factura?.id}
                      label="Monto:"
                      control={control}
                      rules={rules.numeroDecimalRequerido}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      defaultValue={forma?.monto && forma?.monto}
                      focused={anyCredito === forma?.id && errorCredito}
                      onChange={(e, value) => {
                        forma.monto = value;

                        if (anyCredito && calcularSaldo() < 0) {
                          setErrorCredito(true);
                          setError([...error, factura?.id]);
                        } else {
                          setErrorCredito(false);
                          setError(
                            error?.filter((item) => item !== factura?.id),
                          );
                        }

                        modificarForma(indexForma);
                      }}
                    />
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{
                      padding: "0.5rem 0.5rem 0.8rem 0",
                      borderBottom: 0,
                      width: "1rem",
                    }}
                    className={classes?.errorCredito}
                  >
                    <InputDate
                      name={"fecha_pago" + forma?.id + factura?.id}
                      label="Fecha de Pago:"
                      control={control}
                      rules={rules.observacion}
                      defaultValue={forma?.fecha_pago && forma?.fecha_pago}
                      focused={anyCredito === forma?.id && errorCredito}
                      onChange={(e, value) => {
                        forma.fecha_pago = value;

                        modificarForma(indexForma);
                      }}
                    />
                  </TableCell>

                  {isCheq("forma_pago" + forma?.id + factura?.id) && (
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.8rem 0",
                        borderBottom: 0,
                        width: "6rem",
                      }}
                    >
                      <InputDate
                        name={"fecha_vencimiento" + forma?.id + factura?.id}
                        label="Fecha de Vencimiento:"
                        control={control}
                        rules={rules.fechaPagoNoRequered}
                        defaultValue={
                          forma?.fecha_vencimiento && forma?.fecha_vencimiento
                        }
                        onChange={(e, value) => {
                          forma.fecha_vencimiento = value;

                          modificarForma(indexForma);
                        }}
                      />
                    </TableCell>
                  )}

                  <TableCell
                    align="left"
                    style={{
                      padding: "0.5rem 0.5rem 0.8rem 0",
                      borderBottom: 0,
                      width: "10rem",
                    }}
                    className={classes?.errorCredito}
                  >
                    <InputText
                      name={"observacion" + forma?.id + factura?.id}
                      label="Observación:"
                      control={control}
                      rules={rules.observacion}
                      defaultValue={forma?.observacion && forma?.observacion}
                      focused={anyCredito === forma?.id && errorCredito}
                      onChange={(e, value) => {
                        forma.observacion = value;

                        modificarForma(indexForma);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
              .reverse()}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  errorCredito: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
}));
