import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import Logo from "../../../../assets/img/LogoAyupi.png";
import { ReactComponent as ReciboX } from "../../../../assets/svg/remito_x.svg";
import useStyles from "../styles";

export default function ImprimirDetallePagoX({ componentRef, recibo, saldo }) {
  const classes = useStyles();

  // Variable global que toma el monto total de una factura
  let saldoActual = recibo?.total;

  // Función para calcular el saldo
  const saldoItem = (item) => {
    const resultado = Number((saldoActual - item?.monto).toFixed(2));

    saldoActual = resultado;

    return resultado;
  };
  return (
    <Grid ref={componentRef}>
      <style type="text/css" media="print">
        {`@page { 
          margin: 1rem 0 !important; 
          size: A4 landscape;
          }
          ::-webkit-scrollbar,
          ::-webkit-scrollbar {
            display: none;
          }
          ::-webkit-scrollbar-track,
          ::-webkit-scrollbar-thumb {
            display: none;
          }
          scrollbar-width: none;
          -ms-overflow-style: none;`}
      </style>
      <Grid
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid xl={4} lg={4} md={4} sm={4} xs={4}>
          <Grid item>
            <Box
              component="img"
              sx={{
                height: 80,
                marginLeft: "2.5rem",
                marginTop: "1rem",
              }}
              alt="Logo"
              src={Logo}
            />
          </Grid>
        </Grid>
        <Grid
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={8}
          style={{
            maxWidth: "50%",
            marginTop: "2rem",
          }}
        >
          <ReciboX />
        </Grid>
      </Grid>

      <Grid
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid
          container
          xl={7}
          lg={7}
          md={7}
          sm={7}
          xs={7}
          style={{ paddingLeft: "2rem" }}
        >
          <Grid container direction="row" justifyContent="flex-start">
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                color: "rgba(67, 59, 59, 0.87)",
                fontWeight: 700,
              }}
            >
              Razón Social:
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.54)",
                marginLeft: "0.5rem",
              }}
            >
              Ayupí Hormigones S.R.L
            </Typography>
          </Grid>

          <Grid container direction="row" justifyContent="flex-start">
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                color: "rgba(67, 59, 59, 0.87)",
                fontWeight: 700,
              }}
            >
              Domicilio:
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.54)",
                marginLeft: "0.5rem",
              }}
            >
              Ruta Nac N°16 Km 18,5 Resistencia Chaco
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ marginTop: "3rem" }}
      >
        <Grid
          xl={7}
          lg={7}
          md={7}
          sm={7}
          xs={7}
          style={{ paddingLeft: "2.5rem" }}
        >
          <Grid container direction="row" justifyContent="flex-start">
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                color: "rgba(67, 59, 59, 0.87)",
                fontWeight: 700,
              }}
            >
              Cliente:
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.54)",
                marginLeft: "0.5rem",
              }}
            >
              {recibo?.cliente?.nombre ?? "-"}
            </Typography>
          </Grid>

          <Grid container direction="row" justifyContent="flex-start">
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                color: "rgba(67, 59, 59, 0.87)",
                fontWeight: 700,
              }}
            >
              Domicilio:
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: 500,
                color: "rgba(0, 0, 0, 0.54)",
                marginLeft: "0.5rem",
              }}
            >
              {recibo
                ? `${recibo?.cliente?.direccion} ${recibo?.cliente?.ciudad} ${recibo?.cliente?.provincia}`
                : "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: "2rem" }}
        >
          <Typography
            style={{
              fontSize: "20px",
              lineHeight: "25px",
              color: "rgba(67, 59, 59, 0.87)",
              fontWeight: 700,
            }}
          >
            Monto:
          </Typography>
          <Typography
            style={{
              fontSize: "20px",
              lineHeight: "25px",
              fontWeight: 500,
              color: "rgba(0, 0, 0, 0.54)",
              marginLeft: "0.5rem",
            }}
          >
            {recibo?.total?.toLocaleString("es-AR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "currency",
              currency: "ARS",
            }) ?? "-"}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{ padding: "1.5rem 2.5rem 0rem 2.5rem" }}
        className={classes.generalContainer}
      >
        {recibo?.formas_pago?.length > 0 ? (
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow
                  className={classes.tableRow}
                  style={{
                    background: "#E26E6E",
                    height: "2.5rem",
                  }}
                >
                  <TableCell width="18%" className={classes.tableCellHeaders}>
                    Fecha de Pago
                  </TableCell>

                  <TableCell width="10%" className={classes.tableCellHeaders}>
                    Monto
                  </TableCell>

                  <TableCell width="18%" className={classes.tableCellHeaders}>
                    Forma de Pago
                  </TableCell>

                  <TableCell width="18%" className={classes.tableCellHeaders}>
                    N° Cheque/E-Cheq
                  </TableCell>

                  <TableCell width="15%" className={classes.tableCellHeaders}>
                    Fecha Vto.
                  </TableCell>

                  <TableCell width="15%" className={classes.tableCellHeaders}>
                    Saldo
                  </TableCell>

                  <TableCell width="18%" className={classes.tableCellHeaders}>
                    Observación
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recibo?.formas_pago?.map((pago, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {pago?.fecha_pago
                        ? moment(pago?.fecha_pago).format("DD/MM/YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {pago?.monto?.toLocaleString("es-AR", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "ARS",
                      }) ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {pago?.forma_pago ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {pago?.nro_cheque ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {pago?.fecha_vencimiento
                        ? moment(pago?.fecha_vencimiento).format("DD/MM/YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {saldoItem(pago)?.toLocaleString("es-AR", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "ARS",
                      }) ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {pago?.observacion ?? "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container style={{ padding: "0 3rem" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              No hay pagos cargados
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        style={{ padding: "1.5rem 2.5rem 0rem 2.5rem" }}
      >
        <Typography
          style={{
            fontSize: "20px",
            lineHeight: "25px",
            color: "rgba(67, 59, 59, 0.87)",
            fontWeight: 700,
          }}
        >
          Saldo:
        </Typography>
        <Typography
          style={{
            fontSize: "20px",
            lineHeight: "25px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.54)",
            marginLeft: "0.5rem",
          }}
        >
          {saldo()?.toLocaleString("es-AR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "ARS",
          }) ?? "-"}
        </Typography>
      </Grid>
    </Grid>
  );
}
