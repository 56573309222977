/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import { useState } from "react";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// MATERIAL UI
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

// ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// COMPONENTS
import VerificarAcceso from "components/verificarAcceso";
import RootContainer from "layout/rootContainer";
import Card from "components/card";
import ConfirmDialog from "components/ConfirmDialog";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ButtonLoading from "components/buttonLoading/ButtonLoading";

// SERVICES
import facturacionServices from "services/facturacion.services";

// VARIOS
import { LogIn } from "context/LogInContext";
import Protected from "protected";
import Logo from "../../assets/img/LogoAyupi.png";
import rules from "constants/rules";
import useStyles from "./crearFacturaStyles";
import mostrarErrorEP from "constants/funciones/mostrarError";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import InputText from "components/inputs/newInputText";
import InputDate from "components/inputs/newInputDate";
import NewSelect from "components/inputs/newSelect";
import { mayorListaId } from "components/modifCondPagoLiquidacion/constants";
import InputNumber from "components/inputs/newInputNumber";
import { Delete } from "@material-ui/icons";
import { ReactComponent as RemintoX } from "assets/svg/remito_x.svg";
import { namesFacturacion } from "./constants/const";

const EditarRecibo = () => {
  // HOOK useHistory
  const history = useHistory();

  const { id } = useParams();

  // CONSTANTE QUE CONTIENE ESTILOS
  const classes = useStyles();

  // ESTADO PARA MANEJAR EL FORMULARIO
  const { handleSubmit, control, watch } = useForm();

  // ESTADOS PARA VERIFICAR ACCESO DE UN USUARIO AL MODULO
  const { logged } = useContext(LogIn);
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  const [factura, setFactura] = useState(null);

  const obtenerFactura = async () => {
    try {
      const { data, status } = await facturacionServices.verRecibo(id);
      if (status > 199 && status < 300) {
        setFactura(data);
        setLineas(data?.linea_factura);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    obtenerFactura();
  }, []);

  //ESTADO DE CONFIRM DIALOG
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // Fecha elegida para el inicio de licencia
  const fecha_emision = watch(namesFacturacion?.fechaEmision);

  const calcularTotal = () => {
    let total = 0;

    lineas.forEach((linea) => {
      if (linea?.subtotal !== undefined) {
        total += linea?.subtotal;
      }
    });

    return Number(total.toFixed(2));
  };
  const calcularImporteYSubtotal = (index) => {
    const linea = lineas[index];

    linea.cant_metros = Number((linea?.cant_metros ?? 0).toFixed(2));
    linea.precio_unitario = Number((linea?.precio_unitario ?? 0).toFixed(2));
    linea.bonif = Number((linea?.bonif ?? 0).toFixed(2));
    linea.iva = Number((linea?.iva ?? 0).toFixed(2));

    linea.importe = Number(
      (linea?.precio_unitario * linea?.cant_metros - linea?.bonif).toFixed(2),
    );
    linea.subtotal = Number(
      (linea?.importe + (linea?.importe * linea?.iva) / 100).toFixed(2),
    );

    setLineas([...lineas]);
  };

  const [lineas, setLineas] = useState([]);
  const [idLinea, setIdLinea] = useState(mayorListaId(lineas) + 1);

  const [agregarLineas, setAgregarLineas] = useState([]);
  const [modificarLineas, setModificarLineas] = useState([]);
  const [eliminarLineas, setEliminarLineas] = useState([]);

  const agregarLinea = () => {
    let lineaExtra = {
      id: idLinea,
      isNew: true,
      tipo: "",
      precio_unitario: 0,
      cant_metros: 0,
      bonif: 0,
      iva: 0,
      importe: 0,
      subtotal: 0,
    };

    setIdLinea(idLinea + 1);
    setLineas([...lineas, lineaExtra]);
    setAgregarLineas([...agregarLineas, lineaExtra]);
  };

  const modificarLinea = (index) => {
    const linea = lineas[index];

    lineas.splice(index, 1, linea);

    if (!linea?.isNew) {
      setModificarLineas([...modificarLineas, linea]);

      modificarLineas?.forEach((item, index) => {
        if (linea?.id === item?.id) {
          modificarLineas.splice(index, 1, linea);
          setModificarLineas(modificarLineas);
        }
      });
    }
  };

  const eliminarLinea = (index) => {
    const linea = lineas[index];

    lineas.splice(index, 1);
    setLineas([...lineas]);

    if (!linea?.isNew) {
      setEliminarLineas([...eliminarLineas, linea?.id]);

      modificarLineas?.forEach((item, index) => {
        if (linea?.id === item?.id) {
          modificarLineas.splice(index, 1);
          setModificarLineas([...modificarLineas]);
        }
      });
    } else {
      agregarLineas?.forEach((item, index) => {
        if (linea?.id === item?.id) {
          agregarLineas.splice(index, 1);
          setAgregarLineas([...agregarLineas]);
        }
      });
    }
  };

  const [editandoFactura, setEditandoFactura] = useState(false);

  const onSubmit = (data) => {
    setConfirmDialog({
      title: `Editar Recibo Nº${data?.num_factura}`,
      isOpen: true,
      onConfirm: async () => {
        setEditandoFactura(true);

        let total = calcularTotal();
        let dataSend = new FormData();

        dataSend.append(namesFacturacion?.numFact, data?.num_factura);
        dataSend.append("total", total);
        dataSend.append(namesFacturacion?.fechaVencPago, data?.fecha_venc_pago);
        dataSend.append(namesFacturacion?.fechaEmision, data?.fecha_emision);
        dataSend.append(namesFacturacion?.condIva, data?.cond_iva);
        dataSend.append(namesFacturacion?.puntoVenta, data?.punto_venta);
        dataSend.append("agregar_lineas", JSON.stringify(agregarLineas));
        dataSend.append("linea_factura", JSON.stringify(modificarLineas));
        dataSend.append("eliminar_lineas", JSON.stringify(eliminarLineas));
        dataSend.append(namesFacturacion?.condicion, namesFacturacion?.aCobrar);

        try {
          const res = await facturacionServices.editarRecibo(id, dataSend);

          if (res.status > 199 && res.status < 300) {
            setSnackState({
              ...snackState,
              open: true,
              severity: "success",
              message: "Recibo Editado Exitosamente",
            });
            setTimeout(() => {
              setEditandoFactura(false);
              history.goBack();
            }, [2000]);
          }
        } catch (error) {
          setEditandoFactura(false);
          mostrarErrorEP(error, setSnackState);
          return error;
        }
      },
    });
  };

  return (
    <Protected>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RootContainer>
          {verificando ? (
            <VerificarAcceso
              setVerificando={setVerificando}
              setAcceso={setAcceso}
              modulo="facturacion"
              history={history}
              logged={logged}
            />
          ) : (
            acceso && (
              <Grid
                container
                style={{
                  padding: "1rem 0 5rem 2rem",
                  width: "95%",
                }}
              >
                <Card title="Editar Recibo" justifyContent="center">
                  <Grid
                    container
                    style={{
                      padding: "1rem 4rem",
                    }}
                  >
                    {!factura ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        style={{ height: "4.5rem", width: "4.5rem" }}
                      />
                    ) : (
                      <Grid
                        container
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={7}
                        xs={7}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <img src={Logo} className={classes.logo} alt="logo" />

                        <RemintoX />
                      </Grid>
                    )}

                    <Grid container style={{ paddingTop: "0.5rem" }}>
                      <Grid
                        container
                        item
                        xl={9}
                        lg={9}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ paddingRight: "0.5rem" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "8rem", width: "100%" }}
                          />
                        ) : (
                          <>
                            <Grid
                              container
                              item
                              xl={7}
                              lg={7}
                              md={7}
                              sm={7}
                              xs={12}
                              style={{ paddingRight: "0.5rem" }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                Razón Social:
                              </Typography>
                              <Typography variant="body2">
                                Ayupí Hormigones SRL
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xl={5}
                              lg={5}
                              md={5}
                              sm={5}
                              xs={12}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                C.U.I.T:
                              </Typography>
                              <Typography variant="body2">
                                23-31313131-9
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xl={7}
                              lg={7}
                              md={7}
                              sm={7}
                              xs={12}
                              style={{ paddingRight: "0.5rem" }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                Condición IVA:
                              </Typography>
                              <Typography variant="body2">
                                Responsable Inscripto
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xl={5}
                              lg={5}
                              md={5}
                              sm={5}
                              xs={12}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                Insc. Ing. Brutos:
                              </Typography>
                              <Typography variant="body2">
                                23-31313131-9
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xl={7}
                              lg={7}
                              md={7}
                              sm={7}
                              xs={12}
                              style={{ paddingRight: "0.5rem" }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                Domicilio:
                              </Typography>
                              <Typography variant="body2">
                                Ruta Nac Nº 16 Km 18,5 Resistencia Chaco
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xl={5}
                              lg={5}
                              md={5}
                              sm={5}
                              xs={12}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "0.5rem",
                                }}
                              >
                                Inicio de Actividades:
                              </Typography>
                              <Typography variant="body2">
                                01/01/2000
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>

                      <Grid
                        container
                        item
                        alignItems="center"
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem", width: "100%" }}
                          />
                        ) : (
                          <InputText
                            name={namesFacturacion?.numFact}
                            label="Número de Recibo:"
                            InputLabelProps={{ required: true }}
                            control={control}
                            rules={rules.generic}
                            defaultValue={factura?.num_factura}
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid container style={{ paddingTop: "1rem" }}>
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <InputDate
                            name={namesFacturacion?.fechaEmision}
                            label="Fecha Emisión:"
                            InputLabelProps={{ required: true, shrink: true }}
                            control={control}
                            rules={rules.generic}
                            defaultValue={factura?.fecha_emision}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <InputDate
                            name={namesFacturacion?.fechaVencPago}
                            label="Fecha Vencimiento Pago:"
                            disabled={!fecha_emision}
                            InputLabelProps={{ required: true, shrink: true }}
                            inputProps={{ min: fecha_emision }}
                            control={control}
                            rules={rules.generic}
                            defaultValue={factura?.fecha_venc_pago}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <NewSelect
                            control={control}
                            rules={rules.generic}
                            name={namesFacturacion?.puntoVenta}
                            label="Punto de Venta:*"
                            defaultValue={factura?.punto_venta}
                            options={["00001", "00002"]}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <NewSelect
                            control={control}
                            rules={rules.generic}
                            name={namesFacturacion?.condIva}
                            label="Condición IVA:*"
                            defaultValue={factura?.cond_iva}
                            options={[
                              "IVA Responsable Inscripto",
                              "IVA Sujeto Exento",
                              "Consumidor Final",
                              "Sujeto No Categorizado",
                              "Proveedor del Exterior",
                              "Cliente del Exterior",
                              "IVA Liberado",
                              "IVA No Alcanzado",
                              "Responsable Monotributo",
                              "Monotributista Social",
                              "Monotributista Trabajador Independiente Promovido",
                            ]}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <InputText
                            disabled
                            name={namesFacturacion?.cliente}
                            label="Cliente"
                            InputLabelProps={{ shrink: true }}
                            className={classes.inputDisabled}
                            control={control}
                            defaultValue={factura?.cliente?.nombre}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <InputText
                            disabled
                            name={namesFacturacion?.cuitCuil}
                            label="CUIT/CUIL:"
                            InputLabelProps={{ shrink: true }}
                            className={classes.inputDisabled}
                            control={control}
                            defaultValue={factura?.cliente?.cuil_dni}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
                      >
                        {!factura ? (
                          <Skeleton
                            variant="text"
                            animation="wave"
                            style={{ height: "4rem" }}
                          />
                        ) : (
                          <InputText
                            disabled
                            name={namesFacturacion?.domicilio}
                            label="Domicilio:"
                            variant="outlined"
                            type="text"
                            className={classes.inputDisabled}
                            InputLabelProps={{ shrink: true }}
                            control={control}
                            defaultValue={factura?.cliente?.direccion}
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      alignItems="center"
                      style={{ padding: "1rem 0" }}
                      xl="auto"
                      lg="auto"
                      md="auto"
                      sm="auto"
                      xs="auto"
                    >
                      {!factura ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ height: "4rem", width: "40%" }}
                        />
                      ) : (
                        <>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold" }}
                          >
                            Liquidación Asociada:
                          </Typography>
                          <Link
                            style={{
                              fontWeight: 700,
                              marginLeft: "0.5rem",
                              fontSize: "17px",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            onClick={() =>
                              history.push(
                                `/remitos/remitos-por-cliente/resumen-liquidacion/${factura?.resumen}`,
                              )
                            }
                          >
                            {factura?.resumen ?? "-"}
                          </Link>
                        </>
                      )}
                    </Grid>

                    {!factura ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        style={{ height: "4rem", width: "100%" }}
                      />
                    ) : (
                      <>
                        <Grid container style={{ padding: "1rem 0" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            children="Agregar Concepto"
                            startIcon={<AddCircleOutlineIcon />}
                            style={{ textTransform: "none" }}
                            onClick={() => agregarLinea()}
                          />
                        </Grid>

                        <TableContainer
                          className={classes.tableContainerCrearFactura}
                        >
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className={classes.tableCellHeadersFirst}
                                  style={{
                                    width: "1rem",
                                    backgroundColor: "#E26E6E",
                                  }}
                                ></TableCell>
                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{ backgroundColor: "#E26E6E" }}
                                >
                                  Producto/Servicio
                                </TableCell>
                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{ backgroundColor: "#E26E6E" }}
                                >
                                  Cantidad M³
                                </TableCell>
                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{ backgroundColor: "#E26E6E" }}
                                >
                                  Precio Unitario
                                </TableCell>
                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{ backgroundColor: "#E26E6E" }}
                                >
                                  Bonificación
                                </TableCell>
                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{ backgroundColor: "#E26E6E" }}
                                >
                                  Importe
                                </TableCell>
                                <TableCell
                                  className={classes.tableCellHeadersLast}
                                  style={{ backgroundColor: "#E26E6E" }}
                                >
                                  Subtotal
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {lineas?.length > 0 &&
                                lineas.map((linea, index) => (
                                  <TableRow key={linea.id}>
                                    <TableCell
                                      className={classes.tableCellFirst}
                                      style={{ width: "1rem" }}
                                    >
                                      {(linea?.isNew || linea?.concepto) && (
                                        <IconButton
                                          size="small"
                                          color="primary"
                                          onClick={() => eliminarLinea(index)}
                                        >
                                          <Delete />
                                        </IconButton>
                                      )}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {linea?.isNew || linea?.concepto ? (
                                        <InputText
                                          name={
                                            namesFacturacion?.tipo + linea?.id
                                          }
                                          control={control}
                                          rules={rules.observacion}
                                          defaultValue={linea?.tipo ?? ""}
                                          onChange={(e, value) => {
                                            linea.tipo = value;

                                            modificarLinea(index);
                                          }}
                                        />
                                      ) : (
                                        <Typography variant="body2">
                                          {linea?.tipo}
                                        </Typography>
                                      )}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {linea?.isNew || linea?.concepto ? (
                                        <InputNumber
                                          name={
                                            namesFacturacion?.cantMtrs +
                                            linea?.id
                                          }
                                          control={control}
                                          rules={rules.observacion}
                                          defaultValue={linea?.cant_metros ?? 0}
                                          onChange={(e, value) => {
                                            linea.cant_metros = value;

                                            calcularImporteYSubtotal(index);
                                            modificarLinea(index);
                                          }}
                                        />
                                      ) : (
                                        <Typography variant="body2">
                                          {linea?.cant_metros}
                                        </Typography>
                                      )}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      <InputNumber
                                        name={
                                          namesFacturacion?.precioUnitario +
                                          linea?.id
                                        }
                                        control={control}
                                        rules={rules.observacion}
                                        defaultValue={
                                          linea?.precio_unitario ?? 0
                                        }
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={(e, value) => {
                                          linea.precio_unitario = value;

                                          calcularImporteYSubtotal(index);
                                          modificarLinea(index);
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      <InputNumber
                                        name={
                                          namesFacturacion?.bonificacion +
                                          linea?.id
                                        }
                                        control={control}
                                        rules={rules.observacion}
                                        defaultValue={linea?.bonif ?? 0}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={(e, value) => {
                                          linea.bonif = value;

                                          calcularImporteYSubtotal(index);
                                          modificarLinea(index);
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {linea?.importe?.toLocaleString("es-AR", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: "currency",
                                        currency: "ARS",
                                      }) ?? "-"}
                                    </TableCell>

                                    <TableCell
                                      className={classes.tableCellLast}
                                    >
                                      {linea?.subtotal?.toLocaleString(
                                        "es-AR",
                                        {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                          style: "currency",
                                          currency: "ARS",
                                        },
                                      ) ?? "-"}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}

                    <Grid
                      container
                      justifyContent="flex-end"
                      style={{ marginTop: "1rem" }}
                    >
                      {!factura ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ height: "4rem", width: "10%" }}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          {"Total " +
                            calcularTotal()?.toLocaleString("es-AR", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "ARS",
                            }) ?? "-"}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      container
                      justifyContent="flex-end"
                      style={{ marginTop: "1rem" }}
                    >
                      {!factura ? (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          style={{ height: "4rem", width: "20%" }}
                        />
                      ) : (
                        <>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            children="Volver"
                            style={{
                              marginRight: "1rem",
                              textTransform: "none",
                            }}
                            onClick={() => history.goBack()}
                          />
                          <ButtonLoading
                            isLoading={editandoFactura}
                            disabled={editandoFactura}
                            type="submit"
                            children="Confirmar"
                            style={{ textTransform: "none" }}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Card>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
                <CustomSnackbar
                  message={snackState.message}
                  open={snackState.open}
                  severity={snackState.severity}
                  autoHideDuration={snackState.autoHideDuration}
                  onClose={onClose}
                />
              </Grid>
            )
          )}
        </RootContainer>
      </form>
    </Protected>
  );
};

export default EditarRecibo;
