/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

// MATERIAL UI
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

// ICONS
import VisibilityIcon from "@material-ui/icons/Visibility";

// COMPONENTS
import SearchBar from "components/Layout/searchBar/SearchBar";
import LoadingText from "components/loadingText";

// SERVICES
import remitosService from "services/remitos.service";

// VARIOS
import moment from "moment";
import Theme from "theme";
import useStyles from "./styles";
import facturacionServices from "services/facturacion.services";
import mostrarErrorEP from "constants/funciones/mostrarError";
import Pagination from "components/pagination";
import NewCheckbox from "components/inputs/newCheckbox";
import { useForm } from "react-hook-form";

const ModalSelecRemitos = ({
  open,
  close,
  setSnackState,
  setConfirmDialog,
  arregloRemitos,
  cliente,
  setRecargar,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const history = useHistory();

  const { control } = useForm();

  const [minDate, setMinDate] = useState(moment().format("YYYY-01-01"));
  const [maxDate, setMaxDate] = useState(moment().format("YYYY-MM-DD"));
  const [dataRemitos, setDataRemitos] = useState([]);
  const [cargandoRemitos, setCargandoRemitos] = useState(true);

  const [filtro, setFiltro] = useState({
    page: 1,
    minDate: minDate,
    maxDate: maxDate,
    obra: "",
  });

  const handleBusqueda = async (text) => {
    if (text.length > 0) {
      setFiltro({ ...filtro, obra: text, page: 1 });
    } else {
      setFiltro({ ...filtro, obra: "", page: 1 });
    }
  };

  const listarRemitosResumen = async () => {
    try {
      const { data, status } =
        await remitosService.listarRemitosFiltradosResumen(
          filtro,
          cliente?.cliente_id,
          id,
        );
      if (status > 199 && status < 300) {
        setDataRemitos(data);
        setCargandoRemitos(false);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      setCargandoRemitos(false);
      return error;
    }
  };

  useEffect(() => {
    if (open) {
      arregloRemitos?.forEach((objeto) => {
        objeto.remito_id = parseInt(objeto?.remito_id);
      });
      listarRemitosResumen();
    }
  }, [filtro, open]);

  const [remitosAgregar, setRemitosAgregar] = useState([]);
  const [remitosQuitar, setRemitosQuitar] = useState([]);

  const handleChek = (remito, value) => {
    if (value === true) {
      if (!arregloRemitos?.some((o) => o?.remito_id === remito?.id)) {
        remitosAgregar.push(remito?.id);
        setRemitosAgregar(remitosAgregar);
      }

      if (remitosQuitar?.includes(remito?.id)) {
        remitosQuitar?.forEach((element, index) => {
          if (element === remito?.id) {
            remitosQuitar.splice(index, 1);
            setRemitosQuitar(remitosQuitar);
          }
        });
      }
    } else {
      remitosAgregar?.forEach((element, index) => {
        if (element === remito?.id) {
          remitosAgregar.splice(index, 1);
          setRemitosAgregar(remitosAgregar);
        }
      });

      if (arregloRemitos?.some((o) => o?.remito_id === remito?.id)) {
        remitosQuitar.push(remito?.id);
        setRemitosQuitar(remitosQuitar);
      }
    }
  };

  const agregarQuitarRemitos = async () => {
    let sendData = {
      agregar: remitosAgregar,
      quitar: remitosQuitar,
    };

    if (
      remitosQuitar?.length === arregloRemitos?.length &&
      remitosAgregar?.length === 0
    ) {
      setSnackState({
        open: true,
        severity: "error",
        message: `Debe seleccionar al menos un remito`,
        autoHideDuration: 3000,
      });
      return;
    }

    try {
      const { status } = await facturacionServices.agregarQuitarRemitos(
        id,
        sendData,
      );
      if (status > 199 && status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: `Remitos modificados correctamente`,
        });
        setRecargar(true);
        handleClose();
      }
    } catch (error) {
      const errorMessage =
        "Se seleccionaron remitos que existen en otros resumenes";

      if (error?.response?.data?.Error === errorMessage) {
        setSnackState({ open: true, severity: "error", message: errorMessage });
      } else {
        mostrarErrorEP(error, setSnackState);
      }
      return error;
    }
  };

  const handleClose = () => {
    close(false);
  };

  useEffect(() => {
    queryClient.invalidateQueries(["RemitosCliente", filtro]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid
        container
        style={{
          backgroundColor: "#ffffff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
          maxWidth: "60%",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          className={classes.headerTable}
        >
          <Typography variant="h5" className={classes.headerTableTittle}>
            Lista de Remitos
          </Typography>
        </Grid>
        <Grid container>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={8}
            xs={10}
            style={{ marginTop: "1rem" }}
          >
            <SearchBar
              placeholder="Buscar por Obra"
              onChange={(e) => handleBusqueda(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xl={7}
            lg={7}
            md={7}
            sm={8}
            xs={10}
            style={{ margin: "1rem 0 " }}
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <TextField
                size="small"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Fecha Desde"
                focused
                defaultValue={minDate && minDate}
                onChange={(e) =>
                  setMinDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
                inputProps={{
                  max: maxDate,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Fecha Hasta"
                focused
                defaultValue={maxDate && maxDate}
                onChange={(e) =>
                  setMaxDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
                inputProps={{
                  min: minDate,
                  max: moment().format("YYYY-MM-DD"),
                }}
              />
            </Grid>
            <Grid item style={{ marginRight: "0.5rem" }}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  maxHeight: "2rem",
                }}
                onClick={() =>
                  setFiltro({
                    ...filtro,
                    minDate: minDate,
                    maxDate: maxDate,
                    page: 1,
                  })
                }
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {cargandoRemitos ? (
          <LoadingText title="Cargando Remitos" />
        ) : (
          dataRemitos?.count > 0 && (
            <>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow className="tableRow">
                      <TableCell
                        style={{
                          borderBottom: 0,
                          fontWeight: "bold",
                          paddingLeft: "4rem",
                        }}
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: 0, fontWeight: "bold" }}
                      >
                        N° Remito
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: 0, fontWeight: "bold" }}
                      >
                        Tipo H
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: 0, fontWeight: "bold" }}
                      >
                        Obra
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: 0, fontWeight: "bold" }}
                      >
                        Condición
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dataRemitos?.results?.map((rem) => (
                      <TableRow key={rem?.id}>
                        <TableCell
                          style={{
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                            textAlign: "left",
                          }}
                        >
                          <NewCheckbox
                            color="primary"
                            name={`${rem.id}`}
                            control={control}
                            onChange={(e, value) => handleChek(rem, value)}
                            defaultValue={rem?.resumen_id === parseInt(id)}
                          />
                          {moment(rem?.fecha).format("DD-MM-YYYY") ?? "-"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {rem?.nro_remito ?? "-"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {rem?.tipo ?? "-"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {rem?.obra ?? "-"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {rem?.condicion ?? "-"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <IconButton
                            size="small"
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginRight: "0.5rem",
                              borderRadius: 5,
                              backgroundColor: Theme.palette.primary.main,
                              color: "#ffffff",
                            }}
                            onClick={() =>
                              history.push(`/remitos/visualizar/${rem.id}/`, {
                                presupuesto: rem?.presupuesto,
                              })
                            }
                          >
                            <VisibilityIcon
                              style={{
                                height: "1.2rem",
                                width: "1.2rem",
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )
        )}

        {!cargandoRemitos && (
          <Pagination
            data={dataRemitos}
            filtro={filtro}
            setFiltro={setFiltro}
          />
        )}
        <Grid
          container
          justifyContent="flex-end"
          style={{ margin: "1rem 1rem 1rem 0" }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            children="Cancelar"
            style={{ marginRight: "0.5rem" }}
            onClick={() => handleClose()}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            children="Aceptar"
            onClick={() =>
              setConfirmDialog({
                title: `¿Está seguro de confirmar los cambios?`,
                isOpen: true,
                onConfirm: () => {
                  agregarQuitarRemitos();
                },
              })
            }
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ModalSelecRemitos;
