import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  generalContainer: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  headerTable: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    minHeight: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  headerTableTittle: {
    eight: "2rem",
    color: "white",
    textAlign: "center",
  },
  tableContainer: {
    scrollbarWidth: "0px",
    maxHeight: "23rem",
  },
  table: {
    width: "90%",
  },
  tableCellHeaders: {
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",

    color: "rgba(67, 59, 59, 0.87)",
  },
  tableCell: {
    fontSize: "16px",
  },
  menu: {},
});
export default useStyles;
