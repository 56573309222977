
export const TableProvidersList =  {
    headers: [
        { name: "Tipo" },
        { name: "Sub-Tipo" },
        { name: "Razón Social" },
        { name: "Cat. Fiscal" },
        { name: "CUIT/CUIL" },
        { name: "Acciones" },
      ],
};
