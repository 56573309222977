import {
  Button,
  Checkbox,
  Grid,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Theme from "theme";
import moment from "moment";
import "moment/locale/es";

const useStyles = makeStyles({
  typographyContent1: {
    marginRight: "0.5rem",
    color: "#5F5C5E",
    fontSize: 18,
  },
  typographyContent2: {
    color: "black",
    fontSize: 16,
  },
});

const ModalAsociarTurno = ({
  open,
  handleClose,
  turnos,
  turnoSeleccionado,
  setTurnoSeleccionado,
  datosSeleccionadosRemito,
}) => {
  const classes = useStyles();

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignContent="flex-end"
          alignItems="flex-end"
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={9}
          style={{
            backgroundColor: "#ffffff",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
            minHeight: "20vh",
            overflow: "auto",
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            alignContent="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <Typography variant="h5" style={{ color: "#ffffff" }}>
              Asociar Turno
            </Typography>
          </Grid>
          <Grid
            container
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "1rem" }}
          >
            <Typography>
              {moment(datosSeleccionadosRemito?.fechaRemito ?? datosSeleccionadosRemito?.fecha_carga)
                .format("dddd DD [de] MMMM [de] YYYY")
                .toUpperCase()}
            </Typography>{" "}
          </Grid>

          {turnos && (
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Hora</TableCell>
                      <TableCell
                        style={{
                          textAlign: "left",
                          //width: "25%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        M2
                      </TableCell>
                      <TableCell>Tipo H</TableCell>
                      <TableCell>Dirección</TableCell>
                      <TableCell>Seleccionar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {turnos.length > 0 ? (
                      turnos.map((turno) => (
                        <TableRow key={turno.id}>
                          <TableCell
                            style={{ textAlign: "left", whiteSpace: "nowrap" }}
                            className={classes.typographyContent1}
                          >
                            {turno?.hora_salida
                              ? moment(turno?.hora_salida, "HH:mm:ss").format(
                                  "HH:mm",
                                )
                              : "-"}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "left", whiteSpace: "nowrap" }}
                            className={classes.typographyContent1}
                          >
                            {turno?.cantidad_volumen ?? "-"}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "left", whiteSpace: "nowrap" }}
                            className={classes.typographyContent1}
                          >
                            {turno?.tipo_formula ?? "-"}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "left", whiteSpace: "nowrap" }}
                            className={classes.typographyContent1}
                          >
                            {turno?.direccion_obra ?? "-"}
                          </TableCell>

                          <TableCell
                            style={{ textAlign: "left", whiteSpace: "nowrap" }}
                          >
                            <Checkbox
                              checked={
                                turnoSeleccionado?.id === turno?.id
                                  ? true
                                  : false
                              }
                              color="primary"
                              value={turno.id}
                              onChange={(e) => {
                                e.target.checked
                                  ? setTurnoSeleccionado(turno)
                                  : setTurnoSeleccionado("");
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "0.5rem",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Grid item style={{ margin: "0.5rem" }}>
                        <Typography>
                          Cliente no tiene turno para la fecha cargada en remito
                        </Typography>
                      </Grid>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          <Grid
            container
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            justifyContent="flex-end"
            alignContent="left"
            style={{ padding: "1rem" }}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              style={{ marginRight: "0.5rem", marginTop: "1rem" }}
              onClick={() => {
                handleClose();
              }}
            >
              CONFIRMAR
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
export default ModalAsociarTurno;
