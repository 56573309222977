export const TablaTipoTransportistas = {
  headers: [
    { value: "Fecha", type: "order", id_ord: "fecha" },
    { value: "Factura/Remito", type: "order", id_ord: "factura_remito" },
    { value: "Ingreso", type: "order", id_ord: "ingreso" },
    { value: "Cantera/Arenera", type: "order", id_ord: "cantera_arenera" },
    { value: "Material", type: "normal" },
    { value: "Tipo de Material", type: "normal" },
    { value: "Ton. Báscula", type: "normal" },
    { value: "Chofer", type: "normal" },
  ],
  body: [
    { value: "fecha", type: "date", default: "-" },
    { value: "factura_remito", type: "none", default: "-" },
    { value: "ingreso", type: "float", default: "-" },
    { value: "cantera_arenera", type: "none", default: "-" },
    { value: "nombre_material", type: "none", default: "-" },
    { value: "nombre_tipo_material", type: "none", default: "-" },
    { value: "toneladas_bascula", type: "float", default: "-" },
    { value: "chofer", type: "none", default: "-" },
  ],
};
