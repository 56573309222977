import { Delete } from "@material-ui/icons";
import IconButtonAction from "components/iconButtonAction/iconButtonAction";
import StyledTableCell from "components/styled/tableCell";
import StyledTableRow from "components/styled/tableRow";
import { toContable } from "constants/funciones/toContable";
import useDayjs from "hook/useDayjs";
import { names } from "./const";
import { useStyles } from "./styles";

export default function RowFacturas({
  items,
  item,
  indexItem,
  confirmEliminarFactura,
}) {
  const dayjs = useDayjs();
  const styles = useStyles();

  return (
    <StyledTableRow>
      {items?.length > 1 && (
        <StyledTableCell
          style={{ borderBottom: 0, padding: "0.4rem 0.4rem 0.4rem 0" }}
        >
          <IconButtonAction
            onClick={() => confirmEliminarFactura(item, indexItem)}
          >
            <Delete
              style={{
                height: "1.2rem",
                width: "1.2rem",
              }}
            />
          </IconButtonAction>
        </StyledTableCell>
      )}

      <StyledTableCell style={styles?.tableCell}>
        {item?.[names?.facturaNro]}
      </StyledTableCell>

      <StyledTableCell style={styles?.tableCell}>
        {item?.[names?.facturaFechaEmision]
          ? dayjs(item?.[names?.facturaFechaEmision]).format("DD-MM-YYYY")
          : "-"}
      </StyledTableCell>

      <StyledTableCell style={styles?.tableCell}>
        {item?.[names?.facturaOrden]?.num_orden ??
          item?.[names?.facturaOrden]?.id ??
          "-"}
      </StyledTableCell>

      <StyledTableCell style={styles?.tableCell}>
        {item?.[names?.facturaMts]}
      </StyledTableCell>

      <StyledTableCell style={styles?.tableCell}>
        {toContable(item?.[names?.facturaMonto])}
      </StyledTableCell>

      <StyledTableCell style={styles?.tableCell}>
        {toContable(item?.[names?.facturaSaldo])}
      </StyledTableCell>
    </StyledTableRow>
  );
}
