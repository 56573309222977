// MUI & REACT & LIBRARIES
import { React, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Grid,
  Collapse,
} from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

// Hook
import { UserContext } from "context/UserContext";
import { useContext } from "react";
import { useEffect } from "react";

// Iconos
import PersonIcon from "@material-ui/icons/Person";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PaidIcon from "@mui/icons-material/Paid";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ListIcon from "@mui/icons-material/List";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    color: "#c4c4c4",
    "& .Mui-selected": {
      backgroundColor: "#404041",
      color: "#c4c4c4",
      fontWeight: "bold"
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#404041"
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
    height: "2rem",
  },
  absolute: {
    position: "absolute",
    right: theme.spacing(2),
  },
}));



const ListDrawer = ({ setOpen, open }) => {
  
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const rol = user.user_nombreRol;

  // Desestructuring pathname de useLocation de react-router-dom
  const { pathname } = useLocation();

  // Estado para controlar apertura de los Modulos
  const [openCliente, setOpenCliente] = useState(false);
  const [openFormula, setOpenFormula] = useState(false);
  const [openRemito, setOpenRemito] = useState(false);
  const [openFacturacion, setOpenFacturacion] = useState(false);
  const [openOrdenes, setOpenOrdenes] = useState(false);
  const [openProveedores, setOpenProveedores] = useState(false);

  // Funcion cambiar estado de apertura de Clientes
  const handleOpenCliente = () => {
    setOpenCliente(!openCliente);
    setOpen(true);
  };

  // Funcion cambiar estado de apertura de Fórmulas
  const handleOpenFormula = () => {
    setOpenFormula(!openFormula);
    setOpen(true);
  };

  // Funcion cambiar estado de apertura de Remitos
  const handleOpenRemito = () => {
    setOpenRemito(!openRemito);
    setOpen(true);
  };

  // Funcion cambiar estado de apertura de Facturación
  const handleOpenFactura = () => {
    setOpenFacturacion(!openFacturacion);
    setOpen(true);
  };

  // Funcion cambiar estado de apertura de Facturación
  const handleOpenOrdenes = () => {
    setOpenOrdenes(!openOrdenes);
    setOpen(true);
  };

  // Funcion cambiar estado de apertura de Facturación
  const handleOpenProveedores = () => {
    setOpenProveedores(!openProveedores);
    setOpen(true);
  };

  useEffect(() => {
    if (open === false) {
      setOpenCliente(false);
      setOpenFormula(false);
      setOpenRemito(false);
      setOpenFacturacion(false);
      setOpenOrdenes(false);
      setOpenProveedores(false);
    }
  }, [open]);

  if (rol === "administrador") {
    return (
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        style={{ backgroundColor: "#000838" }}
      >
        <Grid>
          <List component="nav" className={classes.root}>
            <ListItem
              button
              onClick={handleOpenCliente}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={<Typography variant="body2">Clientes</Typography>}
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <AccountCircleIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Clientes" />
              {openCliente ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/* Desplegable SubMenu Clientes */}
            <Collapse in={openCliente} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/clientes"}
                  selected={"/clientes" === pathname}
                >
                  <ListItemText primary="Lista de Clientes" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              onClick={handleOpenFormula}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={<Typography variant="body2">Fórmulas</Typography>}
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <ShowChartIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Fórmulas" />
              {openFormula ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/* Desplegable SubMenu Formulas */}
            <Collapse in={openFormula} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/formulas"}
                  selected={"/formulas" === pathname}

                >
                  <ListItemText primary="Lista de Fórmulas" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/formulas/lista-materiales"}
                  selected={"/formulas/lista-materiales" === pathname}
                >
                  <ListItemText primary="Lista de Materiales" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              onClick={handleOpenRemito}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={<Typography variant="body2">Remitos</Typography>}
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <ListAltOutlinedIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Remitos" />
              {openRemito ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/* Desplegable SubMenu Remitos */}
            <Collapse in={openRemito} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/remitos"}
                  selected={"/remitos" === pathname}
                >
                  <ListItemText primary="Lista de Remitos" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/remitos/seleccionar-cliente"}
                  selected={"/remitos/seleccionar-cliente" === pathname}
                >
                  <ListItemText primary="Remitos del Cliente" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/remitos/total-mensual-materiales"}
                  selected={"/remitos/total-mensual-materiales" === pathname}
                >
                  <ListItemText primary="Consumo Material" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/resumenes/seleccionar-cliente"}
                  selected={"/resumenes/seleccionar-cliente" === pathname}
                >
                  <ListItemText primary="Resúmenes del Cliente" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              onClick={handleOpenFactura}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={<Typography variant="body2">Facturación</Typography>}
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <PaidIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Facturación" />
              {openFacturacion ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/* Desplegable SubMenu Facturación */}
            <Collapse in={openFacturacion} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/facturacion"}
                  selected={"/facturacion" === pathname}
                >
                  <ListItemText primary="Lista de Facturas" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/facturacion/seleccionar-cliente"}
                  selected={"/facturacion/seleccionar-cliente" === pathname}
                >
                  <ListItemText primary="Facturas por Cliente" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/facturacion/notas-credito-debito"}
                  selected={"/facturacion/notas-credito-debito" === pathname}
                >
                  <ListItemText primary="Notas Créd./Deb." />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              onClick={handleOpenOrdenes}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={<Typography variant="body2">Ordenes</Typography>}
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <ListIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Ordenes" />
              {openOrdenes ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/* Desplegable SubMenu Ordenes */}
            <Collapse in={openOrdenes} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/ordenes"}
                  selected={"/ordenes" === pathname}
                >
                  <ListItemText primary="Lista de Ordenes" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/ordenes/crear-orden"}
                  selected={"/ordenes/crear-orden" === pathname}
                >
                  <ListItemText primary="Crear Orden" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              component={NavLink}
              className={classes.root}
              to="/cuenta-corriente/"
              selected={"/cuenta-corriente/" === pathname}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={
                  <Typography variant="body2">Cuenta Corriente</Typography>
                }
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <PointOfSaleIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Cuenta Corriente" />
            </ListItem>

            <ListItem
              button
              component={NavLink}
              className={classes.root}
              to="/agenda"
              selected={"/agenda" === pathname}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={<Typography variant="body2">Turnos</Typography>}
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <InsertDriveFileIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Turnos" />
            </ListItem>

            <ListItem
              button
              component={NavLink}
              className={classes.root}
              to="/error-permiso"
              selected={"/error-permiso" === pathname}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={<Typography variant="body2">Otros Ingresos</Typography>}
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <AttachMoneyIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Otros Ingresos" />
            </ListItem>

            <ListItem
              button
              onClick={handleOpenProveedores}
              style={{ height: "2rem" }}
            >
              <Tooltip
                title={<Typography variant="body2">Proveedores</Typography>}
                aria-label="add"
                placement="right"
                disableFocusListener={open}
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                  <AirportShuttleIcon style={{ color: "#c4c4c4" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Proveedores" />
              {openProveedores ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {/* Desplegable SubMenu Proveedores */}
            <Collapse in={openProveedores} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/proveedores/listado"}
                  selected={"/proveedores/listado" === pathname}
                >
                  <ListItemText primary="Lista Proveedores" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/proveedores/materiales/listado"}
                  selected={"/proveedores/materiales/listado" === pathname}
                >
                  <ListItemText primary="Materiales Prov." />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  to={"/proveedores/choferes-autorizados"}
                  selected={"/proveedores/choferes-autorizados" === pathname}
                >
                  <ListItemText primary="Choferes Autorizados" />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider />
        </Grid>

        <Grid
          style={{
            bottom: "6rem",
            position: "absolute",
          }}
        >
          <List component="nav">
            <ListItem alignItems="flex-start">
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <PersonIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
              <ListItemText>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  style={{ paddingTop: "0.3rem" }}
                >
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontSize: "0.9rem",
                    }}
                  >
                    {user.user_name}
                  </Typography>
                </Grid>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      style={{ backgroundColor: "#000838" }}
    >
      <Grid>
        <List component="nav" className={classes.root}>
          <ListItem
            button
            onClick={handleOpenCliente}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Clientes</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <AccountCircleIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Clientes" />
            {openCliente ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          {/* Desplegable SubMenu Clientes */}
          <Collapse in={openCliente} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/clientes"}
                selected={"/clientes" === pathname}
              >
                <ListItemText primary="Lista de Clientes" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={handleOpenFormula}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Fórmulas</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <ShowChartIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Fórmulas" />
            {openFormula ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          {/* Desplegable SubMenu Formulas */}
          <Collapse in={openFormula} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/formulas"}
                selected={"/formulas" === pathname}
              >
                <ListItemText primary="Lista de Fórmulas" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/formulas/lista-materiales"}
                selected={"/formulas/lista-materiales" === pathname}
              >
                <ListItemText primary="Lista de Materiales" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={handleOpenRemito}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Remitos</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <ListAltOutlinedIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Remitos" />
            {openRemito ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          {/* Desplegable SubMenu Remitos */}
          <Collapse in={openRemito} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/remitos"}
                selected={"/remitos" === pathname}
              >
                <ListItemText primary="Lista de Remitos" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/remitos/seleccionar-cliente"}
                selected={"/remitos/seleccionar-cliente" === pathname}
              >
                <ListItemText primary="Remitos del Cliente" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/remitos/total-mensual-materiales"}
                selected={"/remitos/total-mensual-materiales" === pathname}
              >
                <ListItemText primary="Consumo Material" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/resumenes/seleccionar-cliente"}
                selected={"/resumenes/seleccionar-cliente" === pathname}
              >
                <ListItemText primary="Resúmenes del Cliente" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={handleOpenFactura}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Facturación</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <PaidIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Facturación" />
            {openFacturacion ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          {/* Desplegable SubMenu Facturación */}
          <Collapse in={openFacturacion} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/facturacion"}
                selected={"/facturacion" === pathname}
              >
                <ListItemText primary="Lista de Facturas" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/facturacion/seleccionar-cliente"}
                selected={"/facturacion/seleccionar-cliente" === pathname}
              >
                <ListItemText primary="Facturas por Cliente" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/facturacion/notas-credito-debito"}
                selected={"/facturacion/notas-credito-debito" === pathname}
              >
                <ListItemText primary="Notas Créd./Deb." />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={handleOpenOrdenes}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Ordenes</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <ListIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Ordenes" />
            {openOrdenes ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          {/* Desplegable SubMenu Ordenes */}
          <Collapse in={openOrdenes} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/ordenes"}
                selected={"/ordenes" === pathname}
              >
                <ListItemText primary="Lista de Ordenes" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/ordenes/crear-orden"}
                selected={"/ordenes/crear-orden" === pathname}
              >
                <ListItemText primary="Crear Orden" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            component={NavLink}
            className={classes.root}
            to="/cuenta-corriente/"
            selected={"/cuenta-corriente/" === pathname}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Cuenta Corriente</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <PointOfSaleIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Cuenta Corriente" />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            className={classes.root}
            to="/agenda"
            selected={"/agenda" === pathname}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Turnos</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <InsertDriveFileIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Turnos" />
          </ListItem>

          <ListItem
            button
            component={NavLink}
            className={classes.root}
            to="/error-permiso"
            selected={"/error-permiso" === pathname}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Otros Ingresos</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <AttachMoneyIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Otros Ingresos" />
          </ListItem>

          <ListItem
            button
            onClick={handleOpenProveedores}
            style={{ height: "2rem" }}
          >
            <Tooltip
              title={<Typography variant="body2">Proveedores</Typography>}
              aria-label="add"
              placement="right"
              disableFocusListener={open}
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
                <AirportShuttleIcon style={{ color: "#c4c4c4" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Proveedores" />
            {openProveedores ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {/* Desplegable SubMenu Proveedores */}
          <Collapse in={openProveedores} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/proveedores/listado"}
                selected={"/proveedores/listado" === pathname}
              >
                <ListItemText primary="Lista Proveedores" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/proveedores/materiales/listado"}
                selected={"/proveedores/materiales/listado" === pathname}
              >
                <ListItemText primary="Materiales Prov." />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={NavLink}
                to={"/proveedores/choferes-autorizados"}
                selected={"/proveedores/choferes-autorizados" === pathname}
              >
                <ListItemText primary="Choferes Autorizados" />
              </ListItem>
            </List>
          </Collapse>
        </List>
        <Divider />
      </Grid>

      <Grid>
        <List component="nav">
          <ListItem alignItems="flex-start">
            <ListItemIcon style={{ minWidth: 0, paddingRight: ".7rem" }}>
              <PersonIcon style={{ color: "#c4c4c4" }} />
            </ListItemIcon>
            <ListItemText>
              <Grid container direction="column" alignItems="flex-start">
                <Typography
                  style={{
                    color: "#ffffff",
                    fontSize: "0.9rem",
                  }}
                >
                  {user.user_name}
                </Typography>
              </Grid>
            </ListItemText>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default ListDrawer;
