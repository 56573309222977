/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Protected from "protected";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import RootContainer from "layout/rootContainer";
import useStyles from "./PrivilegiosStyles";
import UsuarioService from "services/usuario.service";

const AdminPrivilegios = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [rolUser, setRolUser] = useState(
    history?.location?.state?.rol ? history.location.state.rol : null,
  );
  const [permisos, setPermisos] = useState([]);
  const [modulos, setModulos] = useState([]);

  // Funcion para agregar permiso
  const agregarPermiso = async (id) => {
    const data = {
      rol: history?.location?.state?.rol?.id,
      modulo: id,
    };
    try {
      const res = await UsuarioService.accesoModulo(data);
      if (res.status === 201) {
      }
      queryClient.invalidateQueries("ListarPermisos");
    } catch (error) {
      return error;
    }
  };

  // Funcion para eliminar permiso
  const quitarPermiso = async (id) => {
    const rolModulo = permisos.find((valor) => valor.modulo === id);
    try {
      const res = await UsuarioService.delPermiso(rolModulo.id);
      if (res.status > 199 && res.status < 299) {
        queryClient.invalidateQueries("ListarPermisos");
      }
    } catch (error) {
      return error;
    }
  };

  // Función para Listar Permisos
  const ListarPermisos = async () => {
    try {
      const res = await UsuarioService.getPermisos(
        history?.location?.state?.rol?.id,
      );
      if (res.status > 199 && res.status < 299) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  };

  // Función para Listar Modulos
  const ListarModulos = async () => {
    try {
      const res = await UsuarioService.getModulos();
      if (res.status > 199 && res.status < 299) {
        const modulos = res.data.results;
        setModulos(
          modulos.filter(
            (mod) =>
              mod.nombre !== "logs" &&
              mod.nombre !== "usuarios" &&
              mod.nombre !== "agenda" &&
              mod.nombre !== "adm_modulos" &&
              mod.nombre !== "otros-ingresos" 
          ),
        );
      }
    } catch (error) {
      return error;
    }
  };

  //Funcion para Crear Rol
  const crearRol = async () => {
    const data = {
      nombre: history?.location?.state?.id?.toString(),
    };
    try {
      const res = await UsuarioService.createRol(data);
      if (res.status > 199 && res.status < 299) {
        setRolUser(res.data.id);
        asignarRolUsuario(res.data.id);
        queryClient.invalidateQueries("ListarPermisos");
      }
    } catch (error) {
      console.log("Error al crear Rol", error);
    }
  };

  // Funcion para Asignar Rol a Usuario
  const asignarRolUsuario = async (id) => {
    const dataSend = {
      userId: history.location.state.id,
      rolId: id,
    };
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await UsuarioService.addRol(dataSend);
    } catch (error) {
      return error;
    }
  };

  const { data: ArrayPermisos } = useQuery("ListarPermisos", ListarPermisos, {
    onSuccess: (data) => {
      setPermisos(data);
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });

  // Valido si usuario tiene rol
  const validarRol = () => {
    if (history?.location?.state?.rol === null) {
      crearRol();
    } else {
      setRolUser(history?.location?.state?.rol?.id);
    }
  };

  useEffect(() => {
    validarRol();
    ListarPermisos();
    ListarModulos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Protected>
      <RootContainer>
        <div
          style={{
            paddingTop: "4rem",
            paddingRight: "1rem",
            width: "50%",
            height: window.innerHeight - 50,
          }}
        >
          <Grid container item className={classes.generalContainer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              className={classes.headerTable}
              xs={12}
              item
            >
              <Grid item xs={9}>
                <Typography variant="h5" className={classes.headerTableTittle}>
                  Asignar Permisos
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <Button
                  onClick={() => history.push("/panel-admin/admin-usuarios")}
                  style={{ color: "white" }}
                >
                  {" "}
                  Volver{" "}
                </Button>
              </Grid>
            </Grid>
            <TableContainer className={classes.tableContainer}>
              <Table size="small" stickyHeader className={classes.table}>
                <TableHead>
                  <TableCell>
                    Usuario:{" "}
                    {history?.location?.state?.nombre
                      ? history.location.state.nombre
                      : "-"}{" "}
                    {history?.location?.state?.apellido
                      ? history.location.state.apellido
                      : "-"}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    N° Empleado:{" "}
                    {history?.location?.state?.matricula
                      ? history.location.state.matricula
                      : "-"}
                  </TableCell>

                  <TableRow>
                    <TableCell style={{ textAlign: "left" }}>Módulos</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Permitir/Quitar Acceso
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modulos?.length > 0 &&
                    modulos?.map((item) => (
                      <>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.nombre === "formulas"
                              ? "Fórmulas"
                              : item.nombre === "facturacion"
                              ? "Facturación"
                              : item.nombre[0].toUpperCase() +
                                item.nombre.replaceAll("-", " ").substr(1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ textAlign: "center" }}
                          >
                            <Checkbox
                              checked={
                                permisos?.length > 0 &&
                                permisos?.find(
                                  (unPermiso) => unPermiso.modulo === item.id,
                                )
                                  ? true
                                  : false
                              }
                              value={item.id}
                              onChange={(e) => {
                                e.target.checked
                                  ? agregarPermiso(item.id)
                                  : quitarPermiso(item.id);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "0.5rem",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
              <TableRow></TableRow>
            </TableContainer>
          </Grid>
        </div>
      </RootContainer>
    </Protected>
  );
};
export default AdminPrivilegios;
