import { styled } from "@material-ui/core/styles";
import { TableContainer } from "@material-ui/core";

const StyledTableContainer = styled(TableContainer)(() => ({
  "&.MuiTableContainer-root": {
    backgroundColor: "var(--backgroundColor)",
  },
}));

export default StyledTableContainer;
