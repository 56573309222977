import { TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import ControlledSelect from "components/ControlledSelect";
import ControlledTextfield from "components/ControlledTextfield";
import StyledIconButton from "components/StyledIconButton";
import rules from "constants/rules";

export default function TableRowMediosPago({
  linea,
  index,
  lineas,
  setLineas,
  control,
  watch,
  setConfirmDialog,
}) {
  const isCheque = () => {
    const formaPago = watch("forma_pago" + linea?.id);

    return formaPago === "Cheque" || formaPago === "E-Cheq";
  };

  const handleClickEliminar = () => {
    setConfirmDialog({
      title: `ELIMINAR LINEA`,
      isOpen: true,
      onConfirm: () => eliminarLinea(),
    });
  };

  const eliminarLinea = () => {
    lineas?.splice(index, 1);
    setLineas([...lineas]);
  };

  const modificarLinea = (key, value) => {
    linea[key] = value;
    setLineas([...lineas]);
  };

  return (
    <TableRow>
      {lineas?.length > 1 && (
        <TableCell style={{ width: "1rem", paddingRight: 0, borderBottom: 0 }}>
          <StyledIconButton size="small" onClick={handleClickEliminar}>
            <Tooltip
              title={
                <Typography variant="body2">Eliminar Forma de Pago</Typography>
              }
              placement="top"
              arrow
            >
              <Delete style={{ height: "1.2rem", width: "1.2rem" }} />
            </Tooltip>
          </StyledIconButton>
        </TableCell>
      )}

      <TableCell
        style={{
          minWidth: "12rem",
          paddingTop: "0.75rem",
          paddingRight: 0,
          borderBottom: 0,
        }}
      >
        <ControlledSelect
          name={"forma_pago" + linea?.id}
          control={control}
          shrink
          label="Forma de Pago:"
          options={[
            "Efectivo",
            "Transferencia Bancaria",
            "Cheque",
            "E-Cheq",
            "Retenciones Impositivas",
          ]}
          defaultValue={linea?.forma_pago}
          rules={rules?.generic}
          onChange={(e) => modificarLinea("forma_pago", e?.target?.value)}
        />
      </TableCell>

      {isCheque() && (
        <TableCell
          style={{ minWidth: "10rem", paddingRight: 0, borderBottom: 0 }}
        >
          <ControlledTextfield
            name={"nro_cheque" + linea?.id}
            control={control}
            label="Número:"
            rules={rules?.generic}
            onChange={(e, value) => modificarLinea("nro_cheque", value)}
          />
        </TableCell>
      )}

      <TableCell style={{ minWidth: "8rem", paddingRight: 0, borderBottom: 0 }}>
        <ControlledTextfield
          name={"monto" + linea?.id}
          control={control}
          label="Monto:"
          type="number"
          rules={rules?.numeroDecimalRequerido}
          defaultValue={linea?.monto}
          onChange={(e, value) => modificarLinea("monto", value)}
        />
      </TableCell>

      <TableCell style={{ paddingRight: 0, borderBottom: 0 }}>
        <ControlledTextfield
          name={"fecha_pago" + linea?.id}
          control={control}
          label="Fecha de Pago:"
          type="date"
          rules={rules?.generic}
          defaultValue={linea?.fecha_pago}
          onChange={(e, value) => modificarLinea("fecha_pago", value)}
        />
      </TableCell>

      {isCheque() && (
        <TableCell style={{ paddingRight: 0, borderBottom: 0 }}>
          <ControlledTextfield
            name={"fecha_vencimiento" + linea?.id}
            control={control}
            label="Fecha de Vto:"
            type="date"
            rules={rules?.generic}
            InputLabelProps={{ shrink: true }}
            onChange={(e, value) => modificarLinea("fecha_vencimiento", value)}
          />
        </TableCell>
      )}

      <TableCell style={{ minWidth: "10rem", borderBottom: 0 }}>
        <ControlledTextfield
          name={"observacion" + linea?.id}
          control={control}
          label="Observación"
          defaultValue={linea?.observacion}
          onChange={(e, value) => modificarLinea("observacion", value)}
        />
      </TableCell>
    </TableRow>
  );
}
