import { TableCell, TableRow } from "@material-ui/core";
import { toContable } from "constants/funciones/toContable";
import dayjs from "dayjs";

export default function TableRowDetallePago({ item }) {
  return (
    <TableRow>
      <TableCell>{dayjs(item?.fecha_pago).format("DD-MM-YYYY")}</TableCell>
      <TableCell>{toContable(item?.monto)}</TableCell>
      <TableCell>{item?.forma_pago}</TableCell>
      <TableCell>{item?.nro_cheque ?? "-"}</TableCell>
      <TableCell>
        {item?.fecha_vencimiento
          ? dayjs(item?.fecha_vencimiento).format("DD-MM-YYYY")
          : "-"}
      </TableCell>
      <TableCell>{item?.observacion ?? "-"}</TableCell>
    </TableRow>
  );
}
