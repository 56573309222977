// MUI && REACT 6& LIBRARIES
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead } from "@material-ui/core";
import { MenuItem, TableRow } from "@mui/material";


// COMPONENTS
import InputNumber from "components/inputs/newInputNumber";
import SelectCustom from "components/Layout/select/selectCustom";


// CONSTANTS
import rules from "constants/rules";
import { toContable } from "constants/funciones/toContable";
import { IVAFactura } from "../constants/IVAFactura";


//STYLES
import useStyles from "../../ordenes/styles";


const LinesFacturaCompraAnticipada = ({ fields, control, actualizar, watchTestLine }) => {
    const classes = useStyles();
    return (
        <Grid container style={{ marginTop: "1rem" }}>
            <TableContainer component={Paper} style={{ maxHeight: 180 }}>
                <Table stickyHeader size="small">
                    <TableHead style={{ backgroundColor: "#F3F3F3" }}>
                        <TableRow>
                            <TableCell align="left" className={classes.tableCellCompraAnticipada}>Tipo Hormigón</TableCell>
                            <TableCell align="left" className={classes.tableCellCompraAnticipada}>Cantidad Mts³</TableCell>
                            <TableCell align="left">Precio Unitario</TableCell>
                            <TableCell align="left">$ Bonif.</TableCell>
                            <TableCell align="left">Importe</TableCell>
                            <TableCell align="left">% IVA</TableCell>
                            <TableCell align="center" className={classes.tableCellCompraAnticipada}>
                                Subtotal
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.length > 0 &&
                            fields.map((linea, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="left">
                                            {linea?.tipo}
                                        </TableCell>
                                        <TableCell align="left">
                                            <InputNumber
                                                name={`lineas_factura[${index}].cant_metros`}
                                                control={control}
                                                rules={rules.observacion}
                                                onChange={(e, value) => {
                                                    actualizar({ value: Number(value), name: 'cant_metros' }, index);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <InputNumber
                                                name={`lineas_factura[${index}].precio_unitario`}
                                                control={control}
                                                rules={rules.observacion}
                                                onChange={(e, value) => {
                                                    actualizar({ value: Number(value), name: 'precio_unitario' }, index);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <InputNumber
                                                name={`lineas_factura[${index}].bonif`}
                                                placeholder={"$"}
                                                control={control}
                                                rules={rules.observacion}
                                                onChange={(e, value) => {
                                                    actualizar({ value: Number(value), name: 'bonif' }, index);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="left" >
                                            {toContable(watchTestLine[index].importe)}
                                        </TableCell>
                                        <TableCell align="left">
                                            <SelectCustom
                                                name={`lineas_factura[${index}].iva`}
                                                control={control}
                                                size="small"
                                                rules={rules.generic}
                                                MenuProps={{ classes: { paper: classes?.menuPaper } }}
                                                funcionAdicional={(e) => {
                                                    actualizar({ value: Number(e), name: 'iva' }, index);
                                                }}
                                            >
                                                {IVAFactura?.length > 0 &&
                                                    IVAFactura?.map((item) => (
                                                        <MenuItem value={item.value} key={item.id}>
                                                            {item.nombre}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </SelectCustom>
                                        </TableCell>
                                        <TableCell align="center" >
                                            {toContable(watchTestLine[index].subtotal)}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}


export default LinesFacturaCompraAnticipada