/* eslint-disable no-unused-vars */
import Protected from "protected";
import {
  Grid,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { LogIn } from "context/LogInContext";
import Theme from "theme";

// Hook
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useGetFormulas } from "hook/useGetFormulas";

// Components
import RootContainer from "layout/rootContainer";
import ContainerCustom from "components/comtainerCustom";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ConfirmDialog from "components/ConfirmDialog";
import ModalDetalleFormula from "components/editarFormulaModal/detalleFormulaModal";
import CreateFormulaModal from "components/formulas/createFormulaModal";
import VerificarAcceso from "components/verificarAcceso";

// Services
import formulaService from "services/formula.service";

// Icons
import { Delete } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Create from "@material-ui/icons/Create";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

const Formulas = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { logged } = useContext(LogIn);
  // ESTADOS
  const [acceso, setAcceso] = useState(false);
  const [verificando, setVerificando] = useState(true);
  const [dataFormula, setDataFormula] = useState();
  const [editar, setEditar] = useState(false);
  const [busqueda, setBusqueda] = useState("");

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  const [modalCreateFormulaVisibility, setModalCreateFormulaVisibility] =
    useState(false);
  const [modalDetalleFormulaVisibility, setModalDetalleFormulaVisibility] =
    useState(false);

  // Funcion Cerrar SnackBar
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Query para obtener todas las formulas y/o filtrarlo por nombre
  const { data, isLoading } = useGetFormulas(busqueda);

  // Funcion para Eliminar Formula
  const borrarFormula = async (id) => {
    try {
      const res = await formulaService.deleteFormula(id);
      if (res.status > 199 && res.status < 299) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Fórmula borrada exitosamente",
        });
        queryClient.invalidateQueries("formulas");
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: "Error al borrar Fórmula",
        });
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "warning",
        message: "Esta Fórmula esta siendo utilizada",
      });
    }
  };

  const crearFormula = () => {
    setModalCreateFormulaVisibility(true);
    setDataFormula();
  };

  // Cerrar modal Crear Formula
  const handleCloseModalCreateFormula = () => {
    setModalCreateFormulaVisibility(false);
    setEditar(false);
    setDataFormula();
  };

  // Abrir Modal Detalle de Formulas
  const detalleFormula = (formula) => {
    setModalDetalleFormulaVisibility(true);
    setDataFormula(formula);
  };

  // Cerrar Modal Detalle de Formulas
  const handleCloseModalDetalleFormula = () => {
    setModalDetalleFormulaVisibility(false);
  };

  return (
    <Protected>
      <RootContainer>
        <ContainerCustom>
          {verificando ? (
            <VerificarAcceso
              setVerificando={setVerificando}
              setAcceso={setAcceso}
              modulo="formulas"
              history={history}
              logged={logged}
            />
          ) : (
            <>
              {acceso && (
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignContent="center"
                  style={{
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    marginTop: "1rem",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <TextField
                        size="small"
                        id="outlined-full-width"
                        label="Buscar por Nombre"
                        variant="outlined"
                        value={busqueda}
                        onChange={(e) => {
                          setBusqueda(e.target.value);
                        }}
                        style={{
                          margin: "0.8rem 1rem 0 0",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={
                                  busqueda
                                    ? () => {
                                        setBusqueda("");
                                      }
                                    : null
                                }
                              >
                                {busqueda ? (
                                  <ClearOutlinedIcon />
                                ) : (
                                  <SearchOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          color: "#ffffff",
                          backgroundColor: Theme.palette.primary.main,
                          textTransform: "capitalize",
                          marginTop: "1rem",
                          marginRight: "1rem",
                        }}
                        onClick={() => crearFormula()}
                      >
                        CREAR FORMULA
                      </Button>
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        color: "#ffffff",
                        backgroundColor: Theme.palette.primary.main,
                        textTransform: "capitalize",
                        marginTop: "1rem",
                      }}
                      onClick={() => history.push("/formulas/lista-materiales")}
                    >
                      LISTA MATERIALES
                    </Button>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    style={{
                      backgroundColor: "#ffffff",
                      marginBottom: "4rem",
                      marginTop: "1rem",
                      boxShadow: "0px 5px 5px rgba(0, 0, 0, .2)",
                      borderRadius: 10,
                      minWidth: "20rem",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        backgroundColor: "#001176",
                        height: "3rem",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color: "white",
                        }}
                      >
                        Fórmulas
                      </Typography>
                    </Grid>

                    {isLoading ? (
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ width: "5vw", height: "150px" }}
                      >
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <TableContainer
                        style={{ maxHeight: "60vh", overflow: "auto" }}
                      >
                        <Table stickyHeader size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                width={"50%"}
                                style={{ textAlign: "center" }}
                              >
                                Fórmula
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Acciones
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(!data || data?.length <= 0) && (
                              <TableRow>
                                <Typography
                                  style={{ margin: "1rem", width: "14rem" }}
                                >
                                  No se encontraron Fórmulas
                                </Typography>
                              </TableRow>
                            )}
                            {data &&
                              data?.length > 0 &&
                              data?.map((item) => (
                                <TableRow hover key={item?.id}>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {item?.nombre}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    <IconButton
                                      onClick={() => {
                                        crearFormula();
                                        setEditar(true);
                                        setDataFormula(item);
                                      }}
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        marginLeft: "0.5rem",
                                        borderRadius: 5,
                                        backgroundColor:
                                          Theme.palette.primary.main,
                                        color: "#ffffff",
                                      }}
                                    >
                                      <Create
                                        style={{
                                          height: "1.2rem",
                                          width: "1.2rem",
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setConfirmDialog({
                                          title: `¿Está seguro de eliminar la fórmula ${item?.nombre}?`,
                                          isOpen: true,
                                          onConfirm: () => {
                                            borrarFormula(item?.id);
                                          },
                                        });
                                      }}
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        marginLeft: "0.5rem",

                                        borderRadius: 5,
                                        backgroundColor:
                                          Theme.palette.primary.main,
                                        color: "#ffffff",
                                      }}
                                    >
                                      <Delete
                                        style={{
                                          height: "1.2rem",
                                          width: "1.2rem",
                                        }}
                                      />
                                    </IconButton>

                                    <IconButton
                                      onClick={() => {
                                        detalleFormula(item);
                                      }}
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        marginLeft: "0.5rem",
                                        borderRadius: 5,
                                        backgroundColor:
                                          Theme.palette.primary.main,
                                        color: "#ffffff",
                                      }}
                                    >
                                      <VisibilityIcon
                                        style={{
                                          height: "1.2rem",
                                          width: "1.2rem",
                                        }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        <ModalDetalleFormula
                          open={modalDetalleFormulaVisibility}
                          handleClose={handleCloseModalDetalleFormula}
                          dataFormula={dataFormula}
                        />
                        {/** ESTE MODAL SIRVE PARA CREAR Y MODIFICAR FORMULAS*/}
                        {modalCreateFormulaVisibility && (
                          <CreateFormulaModal
                            open={modalCreateFormulaVisibility}
                            handleClose={handleCloseModalCreateFormula}
                            editar={editar}
                            dataFormula={dataFormula}
                            setSnackBar={setSnackState}
                          />
                        )}
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <CustomSnackbar
            message={snackState.message}
            open={snackState.open}
            severity={snackState.severity}
            onClose={onClose}
          />
        </ContainerCustom>
      </RootContainer>
    </Protected>
  );
};
export default Formulas;
