import { React } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import RootContainer from "layout/rootContainer";

const Success = () => {
  const history = useHistory();

  // la pantalla success recibe un objeto con dos campos
  // tarea: es un string que define la tarea que se realizó y te trajo a ésta pantalla y en base a la cual se define el redireccionamiento
  // data: es un objeto con información pertinente (generalmente el response) de la tarea realizada

  const tarea = history.location?.state?.tarea;
  const data = history.location?.state?.data;

  const onSubmit = () => {
    if (tarea === "registro") {
      history.push("/auth/login");
    } else if (tarea === "edicion") {
      history.push("/panel-admin/admin-usuarios");
    } else if (tarea === "solicitud-pass" || tarea === "cambio-pass") {
      history.push("/auth/login");
    }
  };

  return (
    <RootContainer>
      <div
        style={{
          addingRight: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: window.innerHeight,
        }}
      >
        <Grid
          container
          item
          xl={3}
          lg={4}
          md={6}
          sm={8}
          xs={12}
          style={{
            borderRadius: 10,
            backgroundColor: "#ffffff",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            marginTop: "1rem",
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            style={{ textAlign: "center", padding: "2rem" }}
          >
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              ¡GENIAL!
            </Typography>

            {tarea === "registro" ? (
              <>
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "1.4rem",
                    fontWeight: "normal",
                    marginTop: "1rem",
                  }}
                >
                  Registro del usuario "{data.email}" exitoso.
                </Typography>

                <Typography
                  style={{
                    color: "#ff0000",
                    fontSize: "1.2rem",
                    fontWeight: "normal",
                    marginTop: "1rem",
                  }}
                >
                  En breve recibirá un email con el link de activación en su
                  casilla de correo.
                </Typography>
              </>
            ) : (
              <Typography
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "normal",
                  marginTop: "1rem",
                }}
              >
                {tarea === "edicion" &&
                  `Edición del usuario "${data.email}" exitosa.`}
                {tarea === "solicitud-pass" &&
                  `Se ha enviado un email con el link de cambio de contraseña a la dirección "${data.email}". Verifique su correo electrónico.`}
                {tarea === "cambio-pass" && "Contraseña cambiada exitosamente."}
              </Typography>
            )}
          </Grid>

          <Grid container item direction="column" style={{ padding: "2rem" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmit()}
            >
              {tarea === "registro" && "Ir a inicio de sesión"}
              {tarea === "edicion" && "Volver a administración"}
              {(tarea === "solicitud-pass" || tarea === "cambio-pass") &&
                "Volver al inicio"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </RootContainer>
  );
};
export default Success;
