//MATERIAL UI
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

//VARIOS
import Logo from "../../../assets/img/LogoAyupi.png";
import { ReactComponent as OrdenCompra } from "../../../assets/svg/orden_de_compra.svg";
import { ReactComponent as OrdenCompraX } from "../../../assets/svg/orden_de_compra_x.svg";
import useStyles from "./styles";
import moment from "moment";

export default function ImprimirOrden({ componentRef, orden, totalOrden }) {
  const classes = useStyles();
  return (
    <Grid ref={componentRef}>
      <style type="text/css" media="print">
        {`@page { 
          margin: 1rem 0 !important; 
          size: A4;
          }
          ::-webkit-scrollbar,
          ::-webkit-scrollbar {
            display: none;
          }
          ::-webkit-scrollbar-track,
          ::-webkit-scrollbar-thumb {
            display: none;
          }
          scrollbar-width: none;
          -ms-overflow-style: none;`}
      </style>
      <Grid
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid xl={4} lg={4} md={4} sm={4} xs={4}>
          <Grid item>
            <Box
              component="img"
              sx={{
                height: 80,
                marginLeft: "2.5rem",
                marginTop: "1rem",
              }}
              alt="Logo"
              src={Logo}
            />
          </Grid>
        </Grid>
        <Grid
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={8}
          style={{
            maxWidth: "60%",
            marginTop: "3rem",
          }}
        >
          {orden?.presupuesto ? <OrdenCompraX /> : <OrdenCompra />}
        </Grid>
      </Grid>

      <Grid
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ marginTop: "1rem" }}
      >
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ paddingLeft: "2.5rem" }}
        >
          <Grid
            container
            style={{
              padding: "1rem 3rem",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: "bold",
              }}
            >
              CLIENTE
            </Typography>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              style={{
                marginTop: "1rem",
              }}
            >
              <Grid item>
                <Typography
                  style={{
                    fontWeight: "bold",
                    paddingRight: "0.5rem",
                    color: "rgba(67, 59, 59, 0.87)",
                  }}
                >
                  Nombre
                </Typography>
                <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
                  {orden?.cliente}
                </Typography>
              </Grid>

              <Grid item style={{ marginLeft: "2rem" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    paddingRight: "0.5rem",
                    color: "rgba(67, 59, 59, 0.87)",
                  }}
                >
                  Dirección
                </Typography>
                <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
                  {orden?.cliente_dir}
                </Typography>
              </Grid>

              <Grid item style={{ marginRight: "4rem" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    paddingRight: "0.5rem",
                    color: "rgba(67, 59, 59, 0.87)",
                  }}
                >
                  Ciudad
                </Typography>
                <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
                  {orden?.cliente_ciudad}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              style={{
                marginTop: "1rem",
              }}
            >
              <Grid item>
                <Typography
                  style={{
                    fontWeight: "bold",
                    paddingRight: "0.5rem",
                    color: "rgba(67, 59, 59, 0.87)",
                  }}
                >
                  CUIL - CUIT
                </Typography>
                <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
                  {orden?.cliente_cuil}
                </Typography>
              </Grid>
              {!orden?.presupuesto && (
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      paddingRight: "0.5rem",
                      color: "rgba(67, 59, 59, 0.87)",
                    }}
                  >
                    Cond. IVA
                  </Typography>
                  <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
                    {orden?.cliente_condicion}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography
                  style={{
                    fontWeight: "bold",
                    paddingRight: "0.5rem",
                    color: "rgba(67, 59, 59, 0.87)",
                  }}
                >
                  Teléfono
                </Typography>
                <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
                  {orden?.cliente_telefono}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontWeight: "bold",
                    paddingRight: "0.5rem",
                    color: "rgba(67, 59, 59, 0.87)",
                  }}
                >
                  Fecha de Carga
                </Typography>
                <Typography variant="body2" style={{ marginTop: "0.5rem" }}>
                  {orden?.fecha_carga}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="center"
              style={{
                marginTop: "3rem",
              }}
            >
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "rgba(67, 59, 59, 0.87)" }}
              >
                {orden?.condicion}
              </Typography>

              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="center"
                style={{
                  marginTop: "0.5rem",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    marginRight: "0.5rem",
                    color: "rgba(67, 59, 59, 0.87)",
                  }}
                >
                  Monto Total:
                </Typography>
                <Typography variant="h5">
                  {totalOrden()?.toLocaleString("es-AR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "ARS",
                  }) ?? "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{ padding: "1.5rem 2.5rem 0rem 2.5rem" }}
        className={classes.generalContainer}
      >
        {orden?.detalle_orden?.length > 0 ? (
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow
                  className={classes.tableRow}
                  style={{
                    background: orden?.presupuesto ? "#E26E6E" : "#CCCFE4",
                    height: "2.5rem",
                  }}
                >
                  <TableCell width="18%" className={classes.tableCellHeaders}>
                    PRODUCTO/SERVICIO
                  </TableCell>

                  <TableCell width="15%" className={classes.tableCellHeaders}>
                    CONCEPTO
                  </TableCell>

                  <TableCell width="18%" className={classes.tableCellHeaders}>
                    CANTIDAD M³
                  </TableCell>

                  <TableCell width="15%" className={classes.tableCellHeaders}>
                    PRECIO
                  </TableCell>

                  <TableCell width="15%" className={classes.tableCellHeaders}>
                    FECHA VIG.
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orden?.detalle_orden?.map((orden, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {orden?.formula__nombre ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {orden?.concepto ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 3rem",
                        borderBottom: 0,
                      }}
                    >
                      {orden?.cantidad_m3?.toLocaleString("es-AR") ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {orden?.precio_original?.toLocaleString("es-AR", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "ARS",
                      }) ?? "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0.5rem 0.5rem 0.5rem 1rem",
                        borderBottom: 0,
                      }}
                    >
                      {orden?.fecha_vigencia_precio
                        ? moment(orden?.fecha_vigencia_precio).format(
                            "DD-MM-YYYY",
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container style={{ padding: "0 3rem" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              No hay ordenes cargadas
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
