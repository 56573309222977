/* eslint-disable no-mixed-operators */
import { React, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { NavLink } from "react-router-dom";
import Theme from "theme";
import TokenLessService from "../../../services/tokenLess.service";
import jwt from "jwt-decode";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "context/UserContext";
import { LogIn } from "context/LogInContext";
import { ReactComponent as Logo } from "../../../assets/svg/LogoAyupiVerde.svg";
import MyReCaptchaComponent from "../Reaptcha";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
    code: null,
  });

  if (localStorage.getItem("token")) {
    history.push("/");
  }

  const [error, setError] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);
  const [loading, setLoading] = useState(false);
  const { logged, activateLogged } = useContext(LogIn);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // Funcion para mostrar password
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const guardarSesion = () => {
    activateLogged(!logged);
  };

  const [sendCodeValidation, setSendCodeValidation] = useState(false);
  const [sendCodeMessageValidation, setSendCodeMessageValidation] =
    useState("");

  // Funcion para enviar al back
  const iniciarSesion = async () => {
    setLoading(true);

    const data = {
      email: values.email.toLowerCase(),
      password: values.password,
      code: values.code,
    };

    try {
      const res = await TokenLessService.loginUser(data);
      if (res.status === 200) {
        setSendCodeValidation(false);
        const token = jwt(res.data.access);
        localStorage.setItem("token", res.data.access);
        setUser({
          user_token: res.data.access,
          user_id: token.user_id || "",
          user_name: token.nombre_usuario || "",
          user_email: token.email || "",
          user_rol: token.rol || "",
          user_nombreRol: token.rol_nombre || "",
          user_matricula: token.matricula || "",
        });

        guardarSesion();
        if (token.rol === 1) {
          history.push("/panel-admin/admin-usuarios");
        } else {
          history.push("/");
        }
      }
    } catch (error) {
      if (error.response.data.code) {
        setValues({
          ...values,
          code: null,
        });
        setSendCodeValidation(error.response.data.code);
        setSendCodeMessageValidation(error.response.data.mensaje);
      }

      setLoading(false);
      setError(true);
      setErrorLogin("Datos inválidos. Verifique usuario y/o clave");
    }
  };
  const handleHelperText = () => {
    setErrorLogin(null);
    setError(false);
    setLoading(false);
  };

  const [successReaptcha, setSuccessReaptcha] = useState(true);
  const [expireReaptcha, setExpireReaptcha] = useState(true);
  const [errorReaptcha, setErrorReaptcha] = useState(true);

  const funtionSuccess = () => {
    setExpireReaptcha(true);
    setErrorReaptcha(true);
    setSuccessReaptcha(false);
  };
  const funtionExpire = () => {
    setSuccessReaptcha(true);
    setErrorReaptcha(true);
    setExpireReaptcha(true);
  };
  const funtionError = () => {
    setSuccessReaptcha(true);
    setExpireReaptcha(true);
    setErrorReaptcha(false);
  };

  useEffect(() => {
    setExpireReaptcha(true);
    setErrorReaptcha(true);
    setSuccessReaptcha(true);
  }, [sendCodeValidation]);
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      style={{
        backgroundColor: "#F0F0F0",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100vw",
      }}
    >
      {" "}
      <Grid
        xl={3}
        lg={4}
        md={6}
        sm={6}
        xs={11}
        item
        container
        style={{
          marginTop: "6rem",
          marginBottom: "5rem",
          borderRadius: 10,
          backgroundColor: "#ffffff",
          paddingLeft: 0,
          paddingRight: 0,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <Grid
          container
          item
          justifyContent="center"
          alignContent="center"
          style={{
            paddingTop: "2rem",
            backgroundColor: "#00000000",
            height: "5rem",
            width: "100%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <Logo />
        </Grid>
        {!sendCodeValidation ? (
          <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            style={{ padding: "2rem" }}
          >
            <TextField
              error={error}
              fullWidth
              variant="outlined"
              label="E-Mail"
              name="email"
              onChange={handleChange("email")}
              onClick={handleHelperText}
            />
            <TextField
              error={error}
              fullWidth
              variant="outlined"
              label="Contraseña"
              type={values.showPassword ? "text" : "password"}
              name="password"
              style={{ marginTop: "1rem" }}
              value={values.password}
              helperText={errorLogin}
              onChange={handleChange("password")}
              onClick={handleHelperText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid container item direction="column" align="center">
              <Grid style={{ marginTop: "1rem" }}>
                <MyReCaptchaComponent
                  funtionSuccess={() => funtionSuccess()}
                  funtionExpire={() => funtionExpire()}
                  funtionError={() => funtionError()}
                />
              </Grid>
              <ButtonLoading
                disabled={
                  (successReaptcha &&
                    errorReaptcha &&
                    expireReaptcha &&
                    !values.code) ||
                  loading
                    ? true
                    : false
                }
                isLoading={loading}
                style={{ marginTop: "2rem" }}
                color="primary"
                onClick={iniciarSesion}
              >
                Confirmar
              </ButtonLoading>
              <NavLink
                color="inherit"
                to="/auth/recover-password"
                style={{ color: Theme.palette.primary.main, marginTop: "1rem" }}
              >
                ¿Olvidaste tu Contraseña?
              </NavLink>
              <Typography align="center" style={{ marginTop: "2rem" }}>
                {" "}
                ¿ERES NUEVO?
              </Typography>
              <NavLink
                to="/auth/register"
                variant="body2"
                underline="none"
                style={{ color: Theme.palette.primary.main }}
              >
                Crea una cuenta
              </NavLink>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            style={{ padding: "2rem" }}
          >
            <TextField
              name="code"
              label="Código de verificación"
              type={"text"}
              rules={{ required: true }}
              autoComplete="new-password"
              InputProps={{
                autoComplete: "new-password",
              }}
              inputProps={{
                maxLength: 8,
                minLength: 8,
              }}
              onChange={handleChange("code")}
            />
            <Typography style={{ color: "#D1001F", paddingTop: ".8rem" }}>
              {values.code ? "" : sendCodeMessageValidation}
            </Typography>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              style={{ marginTop: "1rem" }}
            >
              <MyReCaptchaComponent
                funtionSuccess={() => funtionSuccess()}
                funtionExpire={() => funtionExpire()}
                funtionError={() => funtionError()}
              />
            </Grid>
            <Grid container item direction="column" align="center">
              <ButtonLoading
                disabled={successReaptcha && errorReaptcha && expireReaptcha}
                isLoading={loading}
                style={{ marginTop: "2rem" }}
                color="primary"
                onClick={() => iniciarSesion()}
              >
                Confirmar
              </ButtonLoading>
              <Button
                variant="outlined"
                color="primary"
                style={{ border: "none" }}
                onClick={() => history.goBack()}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default Login;
