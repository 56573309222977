import {
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Card from "components/card";
import ConfirmDialog from "components/ConfirmDialog";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import Pagination from "@material-ui/lab/Pagination";
import VerificarAcceso from "components/verificarAcceso";
import mostrarErrorEP from "constants/funciones/mostrarError";
import { LogIn } from "context/LogInContext";
import { NotificationContext } from "context/notification/NotificationContext";
import RootContainer from "layout/rootContainer";
import moment from "moment";
import Protected from "protected";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import VisualizarFactura from "screens/facturacion/visualizar";
import facturacionServices from "services/facturacion.services";
import Liquidaciones from "./liquidaciones-del-cliente";
import Resumenes from "./resumenes-del-cliente";
import useStyles from "./styles";
import { shallow } from "zustand/shallow";
import { useRemitoActions, useRemitoStore, useRef } from "../store";
import { useGetSummariesClientId } from "../hook";

export default function ResumenesLiquidaciones() {
  const history = useHistory();
  const cliente = history?.location?.state;
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const { logged } = useContext(LogIn);
  const classes = useStyles();

  const [dataPagination, setDataPagination] = useState(null);
  const [openVisualizarFactura, setOpenVisualizarFactura] = useState(false);
  const [facturaId, setFacturaId] = useState();
  const [total, setTotal] = useState();


  // ESTADO QUE CONTIENE TODOS LOS FILTROS A USARSE
  const [filtro2, setFiltro2] = useState({
    ordnum: "",
    ordfecha: "desc",
    ordnumorden: "",
  });

  //Estado para verificar acceso a módulo
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  //ESTADO DE CONFIRM DIALOG
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // state manager with zustand
  const filtro = useRemitoStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      todo: state.todo,
      estado: state.estado,
      condicion: state.condicion,
      dias: state.dias,
      formula: state.formula,
      ordremi: state.ordremi,
      ordfecha: state.ordfecha,
      ordmts: state.ordmts,
      ordobra: state.ordobra,
      page: state.page,
    }),
    shallow,
  );

  const ref = useRef();
  const minDate = filtro?.fecha_desde;
  const maxDate = filtro?.fecha_hasta;

  //Actions with zustand
  const {
    setFechaDesde,
    setFechaHasta,
    resetPage,
    reset,
    setRef,
    setPage
  } = useRemitoActions();

  const filterByDate = () => {
    setFechaDesde(minDate);
    setFechaHasta(maxDate);
    resetPage();
  };

// Query Success
   const onSuccess = (data) => {
    // Seteo los datos del paginado
    setTotal(data?.total_paginas);
};

// QUERY PARA OBTENER LISTADO DE RESUMENES POR CLIENTE ID
const {
    data: listaResumenes,
    isLoading: loadingResumenes,
    refetch
} = useGetSummariesClientId({
    idClient: cliente.id,
    filtro: { ...filtro, ...filtro2 },
    isNotificationsEnabled,
    onSuccess,
});

useEffect(() => { refetch() }, [isNotificationsEnabled])

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }
  
  // ESTADO PARA MANEJAR EL ORDEN DE LAS COLUMNAS
  const [asc, setAsc] = useState(true);

  // FUNCION ENCARGADA DE SETEAR EL FILTRO PARA ORDENAR LA TABLA SEGUN LAS OPCIONES ELEGIDAS
  const ordenarPor = (criterio) => {
    let orden = asc ? "asc" : "desc";

    switch (criterio) {
      case "numero":
        setFiltro2({
          ...filtro2,
          ordnum: orden,
          ordfecha: "",
          ordnumorden: "",
        });
        setAsc(!asc);
        break;

      case "fecha":
        setFiltro2({
          ...filtro2,
          ordnum: "",
          ordfecha: orden,
          ordnumorden: "",
        });
        setAsc(!asc);
        break;

      case "num_ord":
        setFiltro2({
          ...filtro2,
          ordnum: "",
          ordfecha: "",
          ordnumorden: orden,
        });
        setAsc(!asc);
        break;

      default:
        break;
    }
  };

  const verFactura = (factura_id) => {
    setOpenVisualizarFactura(true);
    setFacturaId(factura_id);
  };

  const verRecibo = (recibo_id) => {
    history.push(`/recibos/visualizar-recibo/${recibo_id}/`);
  };

  const eliminarResumen = async (id) => {
    try {
      const { status } = await facturacionServices.deleteResumen(id);
      if (status > 199 && status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Resumen eliminado exitosamente",
        });
        setFiltro2({ ...filtro2 });
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };

  const eliminarLiquidacion = async (id) => {
    try {
      const { status } = await facturacionServices.deleteLiquidacion(id);
      if (status > 199 && status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Liquidación eliminada exitosamente",
        });
        setFiltro2({ ...filtro2 });
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };

  useEffect(() => {
    console.log("ref", ref);
    if (ref !== "resumen-cliente") {
      reset();
      setTimeout(() => {
        setRef("resumen-cliente");
      }, 200);
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="facturacion"
            history={history}
            logged={logged}
          />
        ) : acceso && !openVisualizarFactura ? (
          <Grid
            style={{
              paddingTop: "1rem",
              width: "90%",
              height: "100%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              item
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <Grid>
                  <Typography className={classes.resumenHeaderItemTitle}>
                    CUIL-CUIT:{" "}
                    <span className={classes.resumenHeaderItem}>
                      {cliente?.cuil_dni ?? "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.resumenHeaderItemTitle}>
                    Nombre:{" "}
                    <span className={classes.resumenHeaderItem}>
                      {cliente?.nombre ?? "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.resumenHeaderItemTitle}>
                    Dirección:{" "}
                    <span className={classes.resumenHeaderItem}>
                      {cliente
                        ? `${cliente.direccion} ${cliente.ciudad} ${cliente.provincia}`
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                container
                item
                justifyContent="flex-end"
                direction="row"
                style={{ marginTop: "2rem" }}
              >
                <Grid item style={{ marginRight: "1rem" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Fecha Desde"
                    focused
                    defaultValue={minDate && minDate}
                    onChange={(e) =>
                      setFechaDesde(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                    inputProps={{ max: maxDate }}
                  />
                </Grid>
                <Grid item style={{ marginRight: "1rem" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Fecha Hasta"
                    focused
                    defaultValue={maxDate && maxDate}
                    onChange={(e) =>
                      setFechaHasta(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                    inputProps={{
                      min: filtro?.fecha_desde,
                      max: moment().format("YYYY-MM-DD"),
                    }}
                  />
                </Grid>
                <Grid item style={{ marginRight: "0.5rem" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      maxHeight: "2rem",
                      marginTop: "0.2rem",
                    }}
                    onClick={() => filterByDate()}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Card title="Lista de Resúmenes" justifyContent="center">
              {isNotificationsEnabled ? (
                <Resumenes
                  lista={listaResumenes}
                  loading={loadingResumenes}
                  ordenarPor={ordenarPor}
                  setSnackState={setSnackState}
                  setConfirmDialog={setConfirmDialog}
                  setDataLista={setDataPagination}
                  verFactura={verFactura}
                  eliminarResumen={eliminarResumen}
                />
              ) : (
                <Liquidaciones
                  lista={listaResumenes}
                  loading={loadingResumenes}
                  ordenarPor={ordenarPor}
                  setConfirmDialog={setConfirmDialog}
                  verFactura={verFactura}
                  verRecibo={verRecibo}
                  eliminarResumen={eliminarResumen}
                  eliminarLiquidacion={eliminarLiquidacion}
                  filtro={filtro}
                />
              )}
            </Card>

            {listaResumenes?.results?.length > 1 ? (
              <Grid container justifyContent="center">
              <Grid style={{ marginTop: "1rem" }}>
                <Pagination
                  count={total}
                  color="primary"
                  onChange={(event, value) => setPage(value)}
                  page={filtro?.page}
                />
              </Grid>
            </Grid>
            ) : 
            ((listaResumenes?.results?.length === 0) ) && (
              <Grid
                container
                justifyContent="center"
                style={{ margin: "0.5rem" }}
              >
                <Typography variant="subtitle1">
                  No se encontraron remitos con estas caracteristicas
                </Typography>
              </Grid>
            )}

            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
            <CustomSnackbar
              message={snackState.message}
              open={snackState.open}
              severity={snackState.severity}
              autoHideDuration={snackState.autoHideDuration}
              onClose={onClose}
            />
          </Grid>
        ) : (
          <VisualizarFactura
            facturaId={facturaId}
            setOpenVisualizarFactura={setOpenVisualizarFactura}
          />
        )}
      </RootContainer>
    </Protected>
  );
}
