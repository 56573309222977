/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React
import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

// Materia UI
import {
  Grid,
  Typography,
  Box,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  Button,
  MenuItem,
  Tooltip,
} from "@material-ui/core";

// Components
import ConfirmDialog from "components/ConfirmDialog";
import AsociarOrden from "./asociarOrden";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ErrorOrdenes from "./errorOrdenes";
import ModalSelecRemitos from "components/modalAgregarRemitos";
import StyledMenu from "components/Layout/styledMenu";

// Services
import formulaService from "services/formula.service";
import facturacionServices from "services/facturacion.services";
import ordenesService from "services/ordenes.service";

// Styles
import useStyles from "./resumenStyles";

// Icons
import PrintIcon from "@material-ui/icons/Print";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import LanguageIcon from "@material-ui/icons/Language";
import EmailIcon from "@material-ui/icons/Email";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  Delete,
  FormatListBulleted,
  MonetizationOn,
  AddCircleOutline,
} from "@material-ui/icons";

// Varios
import mostrarErrorEP from "constants/funciones/mostrarError";
import moment from "moment";
import Logo from "../../../../assets/img/LogoAyupi.png";
import LogoIram from "../../../../assets/img/LogoIram.png";
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import LoadingText from "components/loadingText";

const Resumen = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
  });

  // Estados para componente ConfirmDialog
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // Estados de modales
  const [modalSeleccionarRemitos, setModalSeleccionarRemitos] = useState(false);
  const [modalAsociarOrden, setModalAsociarOrden] = useState(false);
  const [modalErrorOrdenes, setModalErrorOrdenes] = useState(false);

  // Estado para recargar resumen
  const [recargar, setRecargar] = useState(true);

  const [cliente, setCliente] = useState(null);
  const [remitos, setRemitos] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  //
  const [cargandoDatos, setCargandoDatos] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const obtenerResumen = async () => {
    setCargandoDatos(true);
    try {
      const { data, status } = await facturacionServices.planillaResumen(id);
      if (status > 199 && status < 300) {
        setCliente(data);
        setRemitos(data?.remitos);
      }
    } catch (error) {
      setCargandoDatos(false);
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };

  const [formulas, setFormulas] = useState([]);

  // Funcion para obtener todas las fórmulas
  const obtenerFormulas = async () => {
    try {
      const { data, status } = await formulaService.getFormulas();
      if (status > 199 && status < 300) {
        setFormulas(data);
      }
    } catch (error) {
      return error;
    }
  };

  const [ordenes, setOrdenes] = useState([]);

  const listarOrdenesCliente = async () => {
    try {
      const { data, status } = await ordenesService.listarOrdenesCliente(
        cliente?.cliente_id,
        {
          fecha_desde: "2000-01-01",
          fecha_hasta: "3000-01-01",
          search: "",
          ordFecha: "",
        },
      );

      if (status > 199 && status < 300) {
        setOrdenes(data);
      }
    } catch (error) {
      return error;
    }
  };

  // Declaración de Estados
  const [total, setTotal] = useState([]);
  const [filtrado, setFiltrado] = useState([]);

  // Separa en [] todos los remitos según su tipo de fórmula
  const organizarRemitos = () => {
    let totalEnLoop = [];
    formulas?.forEach((formula) => {
      let result = [];
      remitos?.forEach((un_remito) => {
        if (formula?.nombre === un_remito?.tipo) {
          result.push(un_remito);
        }
      });
      if (result?.length > 0) {
        totalEnLoop = [...totalEnLoop, result];
      }
    });
    setTotal(totalEnLoop);
    setFiltrado(totalEnLoop);
  };

  const calcularCantidad = (array) => {
    let data = [];
    let sumaCantidad = 0;
    array.map((remito) => {
      if (remito.length > 1) {
        sumaCantidad = remito
          .map((item) => Number(item.cantidad_mc))
          .reduce((prev, curr) => prev + curr, 0);
      } else {
        sumaCantidad = Number(remito[0].cantidad_mc);
      }
      data.push({
        id: remito[0]?.remito_id,
        tipo: remito[0]?.tipo,
        cant_metros: sumaCantidad,
      });
    });
    return data;
  };

  useEffect(() => {
    if (recargar) {
      setCargandoDatos(true);

      obtenerFormulas();
      setRecargar(false);
    }
  }, [recargar]);

  useEffect(() => {
    if (formulas) {
      obtenerResumen();
    }
  }, [formulas]);

  useEffect(() => {
    if (remitos) {
      organizarRemitos();
    }
  }, [remitos]);

  useEffect(() => {
    if (cliente) {
      listarOrdenesCliente();
      setCargandoDatos(false);
    }
  }, [cliente]);

  // Eliminar Resumen
  const eliminarResumen = async (id) => {
    try {
      const res = await facturacionServices.deleteResumen(id);
      if (res.status > 199 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Resumen eliminado exitosamente",
        });
        history.goBack();
      } else {
        setSnackState({
          open: "true",
          severity: "error",
          message: "Error al eliminar resumen",
        });
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "warning",
        message: "Error de servidor al borrar",
      });
    }
  };

  // Suma la cantidad de total de fórmula de todos los remitos
  const SumarCantidadesTotal = (array) => {
    let cantidad = [];
    array.map((remito) =>
      cantidad.push(Number(remito.cantidad_mc) || Number(remito.cant_metros)),
    );
    let cantidad_total = cantidad.reduce((a, b) => a + b, 0);
    return cantidad_total.toFixed(2);
  };

  const cantidadFormulas = () => {
    let cantidadesFormulas = [];

    filtrado?.forEach((remitos) => {
      let cantidad_total = 0;

      remitos?.forEach((remito, index) => {
        let { tipo, cantidad_mc } = remito;
        let { id } = formulas?.find((formula) => tipo === formula?.nombre);

        cantidad_total += parseFloat(cantidad_mc);

        let cantidad_formula = { id, cantidad_m3: cantidad_total };

        if (index === remitos?.length - 1) {
          cantidadesFormulas.push(cantidad_formula);
          cantidad_total = 0;
        }
      });
    });

    return cantidadesFormulas;
  };

  // Datos para modales
  const dataAsociar = { cantidadFormulas, cliente };
  return (
    <Protected>
      <RootContainer>
        <>
          <Grid
            style={{
              paddingTop: "1rem",
              width: "95%",
              height: "100%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              item
            >
              <Grid>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{
                    backgroundColor: "#001176",
                    textTransform: "capitalize",
                    margin: "0.5rem 0",
                  }}
                  startIcon={<PrintIcon />}
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    marginLeft: "0.5rem",
                  }}
                  onClick={() => history.goBack()}
                >
                  Volver
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddCircleIcon />}
                  onClick={(e) => handleClick(e)}
                  style={{
                    textTransform: "capitalize",
                    margin: "0.5rem 0",
                  }}
                >
                  Acciones
                </Button>

                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      <Typography variant="body2" align="center">
                        No se puede eliminar el resumen porque tiene asociado
                        una factura o una orden
                      </Typography>
                    }
                    disableFocusListener={
                      cliente?.factura_id === "-" && cliente?.orden_id === "-"
                    }
                    disableHoverListener={
                      cliente?.factura_id === "-" && cliente?.orden_id === "-"
                    }
                    disableTouchListener={
                      cliente?.factura_id === "-" && cliente?.orden_id === "-"
                    }
                  >
                    <MenuItem
                      disabled={
                        cliente?.factura_id !== "-" || cliente?.orden_id !== "-"
                      }
                      onClick={() => {
                        if (
                          cliente?.factura_id === "-" &&
                          cliente?.orden_id === "-"
                        ) {
                          setConfirmDialog({
                            title: `¿Está seguro de eliminar el resumen ${id}?`,
                            isOpen: true,
                            onConfirm: () => {
                              handleClose();
                              eliminarResumen(id);
                            },
                          });
                        }
                      }}
                      style={{ pointerEvents: "auto" }}
                    >
                      <Delete style={{ color: "#001176" }} />
                      Eliminar Resumen
                    </MenuItem>
                  </Tooltip>

                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      <Typography variant="body2" align="center">
                        {`No se puede generar una factura porque este resumen ${
                          cliente?.orden_con_forma_pago
                            ? 'tiene una orden con la condición "Pagado"'
                            : " ya la tiene"
                        }`}
                      </Typography>
                    }
                    disableFocusListener={
                      cliente?.factura_id === "-" ||
                      (cliente?.factura_id === "-" &&
                        !cliente?.orden_con_forma_pago)
                    }
                    disableHoverListener={
                      cliente?.factura_id === "-" ||
                      (cliente?.factura_id === "-" &&
                        !cliente?.orden_con_forma_pago)
                    }
                    disableTouchListener={
                      cliente?.factura_id === "-" ||
                      (cliente?.factura_id === "-" &&
                        !cliente?.orden_con_forma_pago)
                    }
                  >
                    <MenuItem
                      disabled={
                        cliente?.factura_id !== "-" ||
                        (cliente?.factura_id !== "-" &&
                          !cliente?.orden_con_forma_pago)
                      }
                      onClick={() => {
                        if (
                          cliente?.factura_id === "-" ||
                          (cliente?.factura_id === "-" &&
                            !cliente?.orden_con_forma_pago)
                        ) {
                          history.push("/facturacion/crear-factura/", {
                            id_resumen: id,
                            remitos: calcularCantidad(filtrado),
                            dataCliente: cliente,
                          });
                        }
                      }}
                      style={{ pointerEvents: "auto" }}
                    >
                      <MonetizationOn style={{ color: "#001176" }} />
                      Generar Factura
                    </MenuItem>
                  </Tooltip>

                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      <Typography variant="body2" align="center">
                        No se puede asociar una orden porque este resumen ya la
                        tiene
                      </Typography>
                    }
                    disableFocusListener={cliente?.orden_id === "-"}
                    disableHoverListener={cliente?.orden_id === "-"}
                    disableTouchListener={cliente?.orden_id === "-"}
                  >
                    <MenuItem
                      disabled={cliente?.orden_id !== "-"}
                      onClick={() => {
                        if (cliente?.orden_id === "-") {
                          handleClose();
                          ordenes?.length > 0
                            ? setModalAsociarOrden(true)
                            : setModalErrorOrdenes(true);
                        }
                      }}
                      style={{ pointerEvents: "auto" }}
                    >
                      <FormatListBulleted style={{ color: "#001176" }} />
                      Asociar Orden de Compra
                    </MenuItem>
                  </Tooltip>

                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      <Typography variant="body2" align="center">
                        No se pueden agregar o quitar remitos porque este
                        resumen ya tiene asociado una factura o una orden
                      </Typography>
                    }
                    disableFocusListener={
                      cliente?.factura_id === "-" && cliente?.orden_id === "-"
                    }
                    disableHoverListener={
                      cliente?.factura_id === "-" && cliente?.orden_id === "-"
                    }
                    disableTouchListener={
                      cliente?.factura_id === "-" && cliente?.orden_id === "-"
                    }
                  >
                    <MenuItem
                      disabled={
                        cliente?.factura_id !== "-" || cliente?.orden_id !== "-"
                      }
                      onClick={() => {
                        if (
                          cliente?.factura_id === "-" &&
                          cliente?.orden_id === "-"
                        ) {
                          handleClose();
                          setModalSeleccionarRemitos(true);
                        }
                      }}
                      style={{ pointerEvents: "auto" }}
                    >
                      <AddCircleOutline style={{ color: "#001176" }} />
                      Agregar/Quitar Remitos
                    </MenuItem>
                  </Tooltip>
                </StyledMenu>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            style={{
              maxWidth: "95%",
              marginTop: "1rem",
              marginBottom: "5rem",
              border: "1px solid black",
              backgroundColor: "#ffffff",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              className={classes.headerTable}
            >
              <Typography variant="h5" className={classes.headerTableTittle}>
                Resumen
              </Typography>
            </Grid>
            {cargandoDatos ? (
              <LoadingText title={"Cargando Resumen"} />
            ) : (
              <Grid ref={componentRef}>
                <style type="text/css" media="print">
                  {`@page { 
                    margin: 1rem 0 !important; 
                    size: A4 landscape;
                  }`}
                </style>
                <Grid
                  container
                  justifyContent="center"
                  style={{ padding: "0rem 5rem 0rem 5rem" }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: "3rem" }}
                  >
                    <Grid item>
                      <Box
                        component="img"
                        sx={{
                          height: 100,
                          marginRight: "1rem",
                        }}
                        alt="Logo"
                        src={Logo}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "1rem",
                        }}
                      >
                        <Grid container direction="row">
                          <Typography
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              padding: "5px",
                            }}
                          >
                            Ruta N° 16 Km 18,5 - Resistencia Chaco
                          </Typography>
                        </Grid>
                        <Grid container direction="row">
                          <PhoneInTalkOutlinedIcon />
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              padding: "5px",
                              marginLeft: "1rem",
                            }}
                          >
                            {"0362-4765771"}
                          </Typography>
                        </Grid>
                        <Grid container direction="row">
                          <PermPhoneMsgIcon />
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              padding: "5px",
                              marginLeft: "1rem",
                            }}
                          >
                            {"0362-4270876"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "1rem",
                        }}
                      >
                        <Grid container direction="row">
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              padding: "12px",
                            }}
                          >
                            {""}
                          </Typography>
                        </Grid>
                        <Grid container direction="row">
                          <LanguageIcon />
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              padding: "5px",
                              marginLeft: "1rem",
                            }}
                          >
                            {"www.ayupihormigones.com"}
                          </Typography>
                        </Grid>
                        <Grid container direction="row">
                          <EmailIcon />
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 700,
                              padding: "5px",
                              marginLeft: "1rem",
                            }}
                          >
                            {"ayupi@hotmail.com.ar"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Box
                          component="img"
                          sx={{
                            height: 90,
                            marginLeft: "2rem",
                          }}
                          alt="Logo"
                          src={LogoIram}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  style={{ padding: "0rem 2rem 0rem 2rem" }}
                  className={classes.generalContainer}
                >
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: "2rem" }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: "25px",
                          fontWeight: 700,
                          padding: "5px",
                        }}
                      >
                        PLANILLA DE RESUMEN {id}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography
                        style={{
                          marginTop: "1rem",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        DATOS DEL CLIENTE:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacig="2rem"
                    direction="row"
                    justifyContent="space-around"
                    style={{ marginTop: "1rem" }}
                  >
                    <Grid xl={2} lg={2} md={2} sm={4} xs={4} item>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Nombre:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          fontWeight: 400,
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        {cliente?.nombre ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid xl={2} lg={2} md={2} sm={4} xs={4} item>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Dirección:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          fontWeight: 400,
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        {cliente?.direccion ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid xl={2} lg={2} md={2} sm={4} xs={4} item>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Ciudad:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          fontWeight: 400,
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        {cliente?.ciudad ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid xl={2} lg={2} md={2} sm={4} xs={4} item>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        CUIL/CUIT:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          fontWeight: 400,
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        {cliente?.cuil_dni ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid xl={2} lg={2} md={2} sm={4} xs={4} item>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Cond. Iva:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          fontWeight: 400,
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        {cliente?.condicion_iva ?? "-"}
                      </Typography>
                    </Grid>
                    <Grid xl={2} lg={2} md={2} sm={4} xs={4} item>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          color: "rgba(67, 59, 59, 0.87)",
                          fontWeight: 700,
                        }}
                      >
                        Teléfono:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          fontWeight: 400,
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                      >
                        {cliente?.num_telefono ?? "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  {total?.length ? (
                    <TableContainer className={classes.tableContainer}>
                      <Table className={classes.table} size="small">
                        <TableHead>
                          <TableRow className={classes.tableRow}>
                            <TableCell
                              width="15%"
                              className={classes.tableCellHeaders}
                            >
                              FECHA
                            </TableCell>
                            <TableCell
                              width="10%"
                              className={classes.tableCellHeaders}
                            >
                              REMITO N°
                            </TableCell>
                            <TableCell
                              width="20%"
                              className={classes.tableCellHeaders}
                            >
                              TIPO
                            </TableCell>
                            <TableCell
                              width="13%"
                              className={classes.tableCellHeaders}
                            >
                              CANTIDAD M3
                            </TableCell>
                            <TableCell
                              width="20%"
                              className={classes.tableCellHeaders}
                            >
                              OBRA
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filtrado.map(
                            (grupo) =>
                              grupo.length > 0 && (
                                <>
                                  {grupo.map((remito, index) => (
                                    <TableRow key={index}>
                                      <TableCell className={classes.tableCell}>
                                        {moment(remito?.fecha).format(
                                          "DD-MM-YYYY",
                                        ) ?? "-"}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {remito?.nro_remito ?? "-"}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {remito?.tipo ?? "-"}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableCell}
                                        style={{ paddingLeft: "2.5rem" }}
                                      >
                                        {`${
                                          remito.cantidad_mc ||
                                          remito.cant_metros
                                        } `}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {remito?.obra ?? "-"}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  <TableRow className={classes.totalRow}>
                                    <TableCell> </TableCell>

                                    <TableCell></TableCell>
                                    <TableCell
                                      className={classes.linea}
                                    ></TableCell>

                                    <TableCell
                                      className={classes.totalCell}
                                      style={{ justifyContent: "flex-start" }}
                                    >
                                      {`TOTAL: ${SumarCantidadesTotal(grupo)}`}
                                    </TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </>
                              ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Grid item style={{ margin: "0.5rem" }}>
                      <Typography>No posee remitos en resumen</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
        <AsociarOrden
          open={modalAsociarOrden}
          close={setModalAsociarOrden}
          setSnackState={setSnackState}
          setConfirmDialog={setConfirmDialog}
          dataAsociar={dataAsociar}
          presupuesto={false}
          setRecargar={setRecargar}
        />
        <ErrorOrdenes open={modalErrorOrdenes} close={setModalErrorOrdenes} />
        <ModalSelecRemitos
          open={modalSeleccionarRemitos}
          close={setModalSeleccionarRemitos}
          setSnackState={setSnackState}
          setConfirmDialog={setConfirmDialog}
          cliente={cliente}
          arregloRemitos={remitos}
          setRecargar={setRecargar}
          nro_resumen={id}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
          autoHideDuration={snackState.autoHideDuration}
        />
      </RootContainer>
    </Protected>
  );
};
export default Resumen;
