// MUI React && React Hook and Libraries imports
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetAllProviders } from "../hook";
import { useDebounce } from "hook/useDebounce";
import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  CircularProgress,
  IconButton,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import Theme from "theme";
import { LogIn } from "context/LogInContext";
import Pagination from "@material-ui/lab/Pagination";

// ZUSTAND LIBRARIES IMPORTS STORE
import {
  useArrayProviderSubType,
  useProveedoresActions,
  useProveedoresStore,
  useProviderSubTypeSelected,
  useProviderTypeSelected,
  useSearchRazonSocialOrCuit,
} from "../store";
import { shallow } from "zustand/shallow";

// COMPONENTS
import Protected from "protected";
import RootContainer from "layout/rootContainer";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import Card from "components/card";
import TableGeneric from "components/tableGeneric/tableGeneric";
import { TableProvidersList } from "../constants/TableProvidersList";
import SearchBar from "components/Layout/searchBar/SearchBar";
import LoadingText from "components/loadingText";
import { ProvidersSubtypes } from "../constants/ProvidersSubtypes";

// SERVICES
import UsuarioService from "services/usuario.service";

//ICONS
import VisibilityIcon from "@material-ui/icons/Visibility";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import Create from "@material-ui/icons/Create";
import { Delete } from "@material-ui/icons";

// PAGE STYLES
import useStyles from "./ListadoProveedoresStyle";
import proveedoresService from "services/proveedores.service";
import ConfirmDialog from "components/ConfirmDialog";

const ListadoProveedores = () => {
  const classes = useStyles();
  const history = useHistory();
  const { logged } = useContext(LogIn);

  // CABECERA DE TABLA
  const { headers } = TableProvidersList;

  // ESTADOS PARA VALIDAR PERMISO A MODULO
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // ESTADOS PARA NAVEGACION ENTRE PAGINAS

  const [actual, setActual] = useState();
  const [total, setTotal] = useState();

  // DESESTRUCTURING PROVIDERS SUBTYPES
  const { transportistasSubtypes, materialesSubtypes } = ProvidersSubtypes;

  // ESTADO MOSTRAR OPCION TODOS CUANDO TENGO SUBTIPOS
  const deafultProviderSubType = [
    { nombre: "Todos", value: "Todos" },
    ...materialesSubtypes,
    ...transportistasSubtypes,
  ];

  // DECLARACION DE ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // MODAL DE CONFIRMACION AL SALIR DEL FORM DE CARGA
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // FUNCION VERIFICAR ACCESOS A MODULO
  /**
   * ! Llevarlo a un hook
   */

  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await UsuarioService.checkAccess({ modulo: "proveedores" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "proveedores" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "proveedores" });
    }
  };

  // ESTADO DE LOS FILTROS CON ZUSTAND
  const filtro = useProveedoresStore(
    (state) => ({
      tipo: state.tipo,
      sub_tipo: state.subtipo,
      page: state.page,
    }),
    shallow,
  );

  const selectTexto = useProviderTypeSelected();
  const selectTextoSubType = useProviderSubTypeSelected();
  const searchText = useSearchRazonSocialOrCuit();

  // ARRAY DE SUBTIPOS QUE LO TENGO GUARDADO EN STORE
  const subTiposProveedor = useArrayProviderSubType();

  // ACTIONS STATE ZUSTAND
  const {
    setSelectTexto,
    setProviderSubTypeSelected,
    setSubTipoProveedor,
    setSearch,
    setTipo,
    setSubTipo,
    setPage,
  } = useProveedoresActions();

  // USE DEBOUNCE HOOK FOR INPUT TEXT
  const debouncedValue = useDebounce(searchText, 1500);

  // Query Success
  const onSuccess = (data) => {
    // Seteo los datos del paginado
    setActual(data?.actual);
    setTotal(data?.total_paginas);
  };

  // QUERY PARA OBTENER LISTADO DE PROVEEDORES
  const {
    data: providersList,
    isLoading: loadingProvidersList,
    refetch,
  } = useGetAllProviders({
    filtro,
    debouncedValue,
    onSuccess,
  });

  // FUNCION DEL SELECT POR TIPO DE PROVEEDOR
  const mostrarPor = async (search) => {
    let tipo = "";
    let subTipoProveedor = [];
    let providerSubTypeSelected = "Todos";
    switch (search) {
      case "Todos":
        subTipoProveedor = [
          { nombre: "Todos", value: "Todos" },
          ...materialesSubtypes,
          ...transportistasSubtypes,
        ];
        break;
      case "Transportistas":
        tipo = "Transportista";
        subTipoProveedor = [
          { nombre: "Todos", value: "Todos" },
          ...transportistasSubtypes,
        ];
        break;
      case "Materiales":
        tipo = "Materiales";
        subTipoProveedor = [
          { nombre: "Todos", value: "Todos" },
          ...materialesSubtypes,
        ];
        break;
      case "Combustibles":
        tipo = "Combustible";
        subTipoProveedor = [{ nombre: "Generales", value: "Generales" }];
        providerSubTypeSelected = "Generales";
        break;
      case "General":
        tipo = "General";
        subTipoProveedor = [{ nombre: "Generales", value: "Generales" }];
        providerSubTypeSelected = "Generales";
        break;
      default:
        tipo = "";
    }
    setSubTipo("");
    setProviderSubTypeSelected(providerSubTypeSelected);
    setTipo(tipo);
    setSubTipoProveedor(subTipoProveedor);
  };

  // FUNCION DEL SELECT POR TIPO DE PROVEEDOR
  const mostrarPorSubTipo = async (search) => {
    switch (search) {
      case "Todos":
        setSubTipo("");
        break;
      case "Cemento":
        setSubTipo("Cemento");
        break;
      case "Áridos":
        setSubTipo("Áridos");
        break;
      case "Generales":
        setSubTipo("Generales");
        break;
      case "Canteras":
        setSubTipo("Canteras");
        break;
      case "Areneras":
        setSubTipo("Areneras");
        break;
      case "Combustibles":
        setSubTipo("Combustibles");
        break;
      case "Cementera":
        setSubTipo("Cementera");
        break;
      default:
        setSubTipo("");
    }
  };

  // FUNCION DE BUSCADOR POR RAZON SOCIAL O CUIT/CUIL
  const handleSearch = async (text) => {
    setSearch(text);
  };

  // FUNCION ELIMINAR PROVEEDOR
  const eliminarProveedor = async (id) => {
    try {
      const res = await proveedoresService.deleteProvider(id);
      if (res.status > 199 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: `Proveedor Eliminado correctamente`,
        });
        refetch();
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: "Error al Eliminar Proveedor",
        });
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: "Error al Eliminar Proveedor",
      });
    }
  };

  // VERIFICACIÓN DE PERMISOS
  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{
              marginTop: "15rem",
            }}
          >
            <CircularProgress
              style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            {acceso && (
              <>
                <Grid container item style={{ padding: "1rem 1rem 0 1rem" }}>
                  <Grid container item direction="row">
                    <Grid
                      md={4}
                      xs={12}
                      style={{ marginTop: "0.5rem" }}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ maxHeight: "2rem" }}
                          onClick={() =>
                            history.push("/proveedores/crear-editar", {
                              idProvider: undefined,
                            })
                          }
                        >
                          Nuevo Proveedor
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      md={8}
                      xs={12}
                      container
                      justifyContent={"flex-end"}
                      style={{ marginTop: "0.5rem" }}
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <FormControl
                          variant="outlined"
                          size="small"
                          style={{ width: "180px" }}
                        >
                          <InputLabel id="select-tipe">
                            Tipo Proveedor
                          </InputLabel>
                          <Select
                            size="small"
                            variant="outlined"
                            value={selectTexto}
                            label={"Tipo Proveedor"}
                            MenuProps={{
                              classes: { paper: classes?.menuPaper },
                            }}
                            onChange={(e) => {
                              mostrarPor(e.target.value);
                              setSelectTexto(e.target.value);
                            }}
                          >
                            <MenuItem value={"Todos"}>Todos</MenuItem>
                            <MenuItem value={"Transportistas"}>
                              Transportistas
                            </MenuItem>
                            <MenuItem value={"Materiales"}>Materiales</MenuItem>
                            <MenuItem value={"Combustibles"}>
                              Combustibles
                            </MenuItem>
                            <MenuItem value={"General"}>Generales</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {(subTiposProveedor?.length > 0 ||
                        filtro?.subtipo !== "") && (
                        <Grid item>
                          <FormControl
                            variant="outlined"
                            size="small"
                            style={{ width: "180px" }}
                          >
                            <InputLabel id="select-sub-tipe">
                              SubTipo Prov
                            </InputLabel>
                            <Select
                              size="small"
                              variant="outlined"
                              value={selectTextoSubType}
                              label={"Tipo Proveedor"}
                              MenuProps={{
                                classes: { paper: classes?.menuPaper },
                              }}
                              onChange={(e) => {
                                mostrarPorSubTipo(e.target.value);
                                setProviderSubTypeSelected(e.target.value);
                              }}
                            >
                              {subTiposProveedor?.length > 0 &&
                                subTiposProveedor?.map((item) => (
                                  <MenuItem key={item.id} value={item.nombre}>
                                    {item.nombre}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item>
                        <SearchBar
                          name="search"
                          placeholder="Razón Social o CUIT/CUIL"
                          defaultValue={searchText}
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* INICIO DE TABLA */}
                <Grid container item style={{ padding: "1rem 1rem 0 1rem" }}>
                  <Card title="Listado de Proveedores" justifyContent="center">
                    {loadingProvidersList ? (
                      <LoadingText title="Cargando Proveedores" />
                    ) : (
                      <>
                        {providersList?.results?.length > 0 ? (
                          <>
                            <TableGeneric
                              size="small"
                              headers={headers}
                              styles={classes?.tableProvidersMaterials}
                            >
                              {providersList?.results?.length > 0 &&
                                providersList?.results?.map((item, index) => (
                                  <TableRow hover key={index}>
                                    <TableCell>
                                      {item?.tipo.toUpperCase() ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                      {item?.sub_tipo.toUpperCase() ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                      {item?.razon_social.toUpperCase() ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                      {item?.cond_iva.toUpperCase() ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                      {item?.cuil_cuit ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                      <Grid
                                        container
                                        item
                                        direction="row"
                                        style={{
                                          whiteSpace: "nowrap",
                                          minWidth: "8rem",
                                        }}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            history.push(
                                              `/proveedores/ver-detalle`,
                                              { idProvider: item?.id },
                                            )
                                          }
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",

                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <VisibilityIcon
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            setConfirmDialog({
                                              title: `Eliminará al proveedor ${item?.razon_social.toUpperCase()}`,
                                              isOpen: true,
                                              onConfirm: () => {
                                                eliminarProveedor(item?.id);
                                              },
                                            })
                                          }
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",

                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <Delete
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            history.push(
                                              "/proveedores/crear-editar",
                                              { idProvider: item?.id },
                                            )
                                          }
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",
                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <Create
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            history.push(
                                              `/proveedores/remitos/${item?.id}`,
                                              item,
                                            )
                                          }
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.5rem",
                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <ListOutlinedIcon
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableGeneric>
                            <Grid
                              container
                              item
                              direction="row"
                              justifyContent="center"
                              alignContent="center"
                              alignItems="center"
                              style={{ margin: "0.5rem" }}
                            >
                              <Pagination
                                count={total}
                                color="primary"
                                onChange={(event, value) => setPage(value)}
                                page={filtro?.page}
                              />
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            container
                            justifyContent="center"
                            style={{ margin: "0.5rem" }}
                          >
                            <Typography align="center" variant="subtitle1">
                              {`No se encontraron Proveedores ${
                                filtro?.tipo && "de tipo " + filtro?.tipo
                              } ${
                                filtro?.sub_tipo &&
                                "de sub tipo " + filtro?.sub_tipo
                              } ${
                                searchText &&
                                "por Razón Social y/o CUIT buscado "
                              }`}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Card>
                </Grid>
              </>
            )}
          </>
        )}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          autoHideDuration={snackState.autoHideDuration}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};

export default ListadoProveedores;
