import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { RadioGroup } from "@material-ui/core";

const RadioCustom = ({ name, rules, control, checked, children, ...props }) => (
  <Controller
    rules={rules}
    control={control}
    name={name}
    render={({ field, fieldState: { error } }) => (
      <RadioGroup
        {...field}
        error={!!error}
        helperText={error ? error.message : null}
        {...props}
      >
        {children}
      </RadioGroup>
    )}
  />
);

RadioCustom.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default RadioCustom;
