import { Button, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import Theme from "theme";

const useStyles = makeStyles({
  typographyContent1: {
    marginRight: "0.5rem",
    color: "#5F5C5E",
    fontSize: 18,
  },
  typographyContent2: {
    color: "black",
    fontSize: 16,
  },
});

const DetalleFormulaModal = ({ open, handleClose, dataFormula }) => {
  const classes = useStyles();
  const listaMateriales = dataFormula?.materiales
    ? dataFormula.materiales
    : null;

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignContent="flex-end"
          alignItems="flex-end"
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={11}
          style={{
            backgroundColor: "#ffffff",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
            minHeight: "20vh",
            overflow: "auto",
          }}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignContent="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <Typography variant="h5" style={{ color: "#ffffff" }}>
              Ver Detalle de Fórmula
            </Typography>
          </Grid>
          <Grid
            container
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ padding: "1rem 0", marginLeft: "5%" }}
          >
            <Typography style={{ fontSize: "20px" }}>
              {"Fórmula"}&nbsp;
            </Typography>{" "}
            <Typography
              style={{ fontSize: "20px" }}
              className={classes.typographyContent1}
            >
              {dataFormula?.nombre ? dataFormula?.nombre : " - "}
            </Typography>
          </Grid>
          {listaMateriales?.length > 0 &&
            listaMateriales.map((item) => (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-around"
                style={{ width: "90%", margin: "0 auto" }}
              >
                <Grid
                  container
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={12}
                  xs={4}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ padding: "1rem" }}
                >
                  <Typography className={classes.typographyContent2}>
                    {"Tipo:"}&nbsp;
                  </Typography>{" "}
                  <Typography className={classes.typographyContent1}>
                    {item?.tipo ? item?.tipo : " - "}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xl={5}
                  lg={5}
                  md={5}
                  sm={12}
                  xs={4}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: "1rem" }}
                >
                  <Typography className={classes.typographyContent2}>
                    {"Nombre:"}&nbsp;
                  </Typography>{" "}
                  <Typography className={classes.typographyContent1}>
                    {item?.subtipo ? item?.subtipo : " - "}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={4}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ padding: "1rem" }}
                >
                  <Typography className={classes.typographyContent2}>
                    {"Cantidad:"}&nbsp;
                  </Typography>{" "}
                  <Typography className={classes.typographyContent1}>
                    {item?.cantidad ? item?.cantidad : " 0 "}&nbsp;
                    {item?.unidad ? item?.unidad : "-"}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          {(listaMateriales?.length <= 0 || listaMateriales === null) && (
            <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ padding: "1rem" }}
            >
              <Typography className={classes.typographyContent1}>
                {"- No Posee Materiales Cargados"}&nbsp;
              </Typography>{" "}
            </Grid>
          )}
          <Grid
            container
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction="row"
            justifyContent="flex-end"
            alignContent="left"
            style={{ padding: "1rem" }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="small"
              style={{ marginRight: "0.5rem", marginTop: "1rem" }}
              onClick={() => {
                handleClose();
              }}
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
export default DetalleFormulaModal;
