/* eslint-disable react-hooks/exhaustive-deps */

import {
  Button,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Card from "components/card";
import LoadingText from "components/loadingText";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import ordenesService from "services/ordenes.service";

const HistoricoPrecios = ({ open, close, orden, presupuesto }) => {
  const [historicoPrecios, setHistoricoPrecios] = useState([]);
  const [cargandoHistorico, setCargandoHistorico] = useState(false);

  // ESTADO QUE CONTIENE TODOS LOS FILTROS A USARSE
  const [filtro, setFiltro] = useState({
    fm: "",
    fv: "desc",
  });

  const listarHistorico = async () => {
    setCargandoHistorico(true);
    try {
      const { data, status } = presupuesto
        ? await ordenesService.historicoPreciosNegro(orden?.id, filtro)
        : await ordenesService.historicoPrecios(orden?.id, filtro);

      if (status > 199 && status < 300) {
        setHistoricoPrecios(data);
        setCargandoHistorico(false);
      }
    } catch (error) {
      setCargandoHistorico(false);
      return error;
    }
  };

  useEffect(() => {
    if (open) {
      listarHistorico();
    }
  }, [open, filtro]);

  // ESTADO PARA MANEJAR EL ORDEN DE LAS COLUMNAS
  const [asc, setAsc] = useState(false);

  // FUNCION ENCARGADA DE SETEAR EL FILTRO PARA ORDENAR LA TABLA SEGUN LAS OPCIONES ELEGIDAS
  const ordenarPor = (criterio) => {
    // Variables para ordenar columnas
    let orden = asc ? "asc" : "desc";

    switch (criterio) {
      case "modificacion":
        setFiltro({
          ...filtro,
          fm: orden,
          fv: "",
        });
        setAsc(!asc);
        break;

      case "vigencia":
        setFiltro({
          ...filtro,
          fm: "",
          fv: orden,
        });
        setAsc(!asc);
        break;

      default:
        break;
    }
  };

  const handleClose = () => {
    close(false);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        xl={6}
        lg={6}
        md={7}
        sm={8}
        xs={10}
        style={{
          backgroundColor: "#ffffff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
        }}
      >
        <Card title="Modificaciones de Precio" justifyContent="center">
          <Grid container style={{ padding: "1rem" }}>
            <Grid container item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
              >
                {orden?.formula__nombre ? "Tipo Hormigón:" : "Concepto:"}
              </Typography>
              <Typography variant="body2">
                {orden?.formula__nombre ?? orden?.concepto ?? "-"}
              </Typography>
            </Grid>
            <Grid container item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
              >
                Fecha Carga:
              </Typography>
              <Typography variant="body2">
                {moment(orden?.orden__fecha_carga).format("DD-MM-YYYY")}
              </Typography>
            </Grid>

            <Grid container item xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
              >
                Precio Original:
              </Typography>
              <Typography variant="body2">
                {orden?.precio_original?.toLocaleString("es-AR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "ARS",
                })}
              </Typography>
            </Grid>

            {cargandoHistorico ? (
              <LoadingText title="Cargando Histórico de Precios" />
            ) : historicoPrecios?.count > 0 ? (
              <TableContainer>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          padding: "0.5rem 0.5rem 0.5rem 0",
                          fontWeight: "bold",
                          width: "33%",
                          borderBottom: 0,
                        }}
                      >
                        Fecha Modificación
                        <IconButton
                          size="small"
                          onClick={() => ordenarPor("modificacion")}
                        >
                          <CompareArrowsIcon
                            style={{
                              width: "1rem",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </IconButton>
                      </TableCell>

                      <TableCell
                        style={{
                          padding: "0.5rem 0.5rem 0.5rem 0",
                          fontWeight: "bold",
                          width: "33%",
                          borderBottom: 0,
                        }}
                      >
                        Fecha de Vigencia
                        <IconButton
                          size="small"
                          onClick={() => ordenarPor("vigencia")}
                        >
                          <CompareArrowsIcon
                            style={{
                              width: "1rem",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </IconButton>
                      </TableCell>

                      <TableCell
                        style={{
                          padding: "0.5rem 0.5rem 0.5rem 0",
                          fontWeight: "bold",
                          width: "33%",
                          borderBottom: 0,
                        }}
                      >
                        Precio Modificado
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {historicoPrecios?.results?.map((precio, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            borderBottom: 0,
                          }}
                        >
                          {precio?.fecha_modificado}
                        </TableCell>

                        <TableCell
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            borderBottom: 0,
                          }}
                        >
                          {precio?.fecha_vigencia ?? "-"}
                        </TableCell>

                        <TableCell
                          style={{
                            padding: "0.5rem 0.5rem 0.5rem 0",
                            borderBottom: 0,
                          }}
                        >
                          {precio?.precio_modificado?.toLocaleString("es-AR", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "ARS",
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "3rem",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                No Hay Histórico de Precios
              </Grid>
            )}

            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                size="small"
                color="primary"
                children="Cerrar"
                style={{ textTransform: "none" }}
                onClick={() => handleClose()}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Modal>
  );
};

export default HistoricoPrecios;
