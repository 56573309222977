// MUI && REACT && LIBRARIES
import { Button, CircularProgress, Grid, MenuItem, Typography, Collapse } from "@material-ui/core"
import { Alert } from "@material-ui/lab";
import Stack from '@mui/material/Stack';
import Theme from "theme";
import { LogIn } from "context/LogInContext";
import { ProvidersSubtypes } from "../constants/ProvidersSubtypes"

// HOOK
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "hook/useLocation";
import { useGetDetailsProviderById } from "../hook";

// COMPONENTS
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import LoadingText from "components/loadingText";
import ConfirmDialog from "components/ConfirmDialog";
import SelectCustom from "components/Layout/select/selectCustom";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import Card from "components/card";
import InputField from "components/Layout/inputCustom/inputField";
import rules from "constants/rules";
import  { CondicionesIVA } from "../constants/condicionesIVA"

// PAGE STYLES
import useStyles from "./CrearEditarProveedoresStyles";

// SERVICES
import usuarioService from "services/usuario.service";
import proveedoresService from "services/proveedores.service";



const CrearEditarProveedor = () => {

  const classes = useStyles();
  const history = useHistory();

  // DESESTRUCTURING LOGIN STATE FROM USER CONTEXT
  const { logged } = useContext(LogIn);

  // DESESTRUCTURING ID PROVIDERS FROM STATE
  const { idProvider } = history.location?.state;

  // DESESTRUCTURING PROVIDERS SUBTYPES 
  const { transportistasSubtypes, materialesSubtypes } = ProvidersSubtypes;

  // ESTADOS PARA VALIDAR PERMISO A MODULO
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // ESTADO PARA MANEJAR EL ALERT
  const [openAlert, setOpenAlert] = useState(false);


  // SUBTIPOS
  const [arraySubtipos, setArraySubtipos] = useState([])

  // DECLARACION DE ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // MODAL DE CONFIRMACION AL SALIR DEL FORM DE CARGA
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // ESTADO DE GUARDADO EN BD
  const [loading, setLoading] = useState(false);

  // QUERY PARA OBTENER DETALLE DE PROVEEDOR
  const { data: dataProvider, isLoading: loadingDataProvider
  } = useGetDetailsProviderById({ idProvider });

  // REACT HOOK FORM LOAD DEFAULTS VALUES FROM QUERY PROVIDER
  const { handleSubmit, control, setValue, reset, watch, clearErrors } = useForm({
    defaultValues:
    {
      cuil_cuit: dataProvider?.cuil_cuit ?? "",
      razon_social: dataProvider?.razon_social ?? "",
      tipo: dataProvider?.tipo ?? "",
      sub_tipo: dataProvider?.sub_tipo ?? "",
      cond_iva: dataProvider?.cond_iva ?? "",
      telefono: dataProvider?.telefono ?? "",
      email: dataProvider?.email ?? "",
      direccion_nombre: dataProvider?.direccion_nombre ?? "",
      provincia_nombre: dataProvider?.provincia_nombre ?? "",
      provincia: dataProvider?.provincia ?? "",
      departamento_nombre: dataProvider?.departamento_nombre ?? "",
      departamento: dataProvider?.departamento ?? "",
      localidad_nombre: dataProvider?.localidad_nombre ?? "",
      localidad: dataProvider?.localidad ?? ""
    }
  });

  let prov = dataProvider?.provincia_nombre;
  let dpto = dataProvider?.departamento_nombre;
  let tipoActual = dataProvider?.tipo;

  // HOOK PARA TRAER PROVINCIAS, LOCALIDADES Y DEPARTAMENTOS
  const {
    provincias,
    departamentos,
    localidades,
    getDepartamentos,
    getLocalidades,
  } = useLocation({ prov, dpto, setValue });

  // FUNCION VERIFICAR ACCESOS A MODULO  
  /**
   * ! Llevarlo a un hook
   */
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await usuarioService.checkAccess({ modulo: "proveedores" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "proveedores" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "proveedores" });
    }
  };

  // FUNCION ONSUBMIT PARA GUARDAR EN BD
  const onSubmit = async (data) => {
    let aux = data;
    if (data?.sub_tipo === "") {
      aux = { ...data, sub_tipo: "Generales" }
    }
    let endpoint = "";
    let type = "";
    if (dataProvider?.id) {
      //Se editan los datos de proveedor
      type = "editado";
      endpoint = proveedoresService?.editProvider(idProvider, data);
    } else {
      //Se crean los datos de proveedor
      type = "creado";
      endpoint = proveedoresService?.createProvider(aux);
    }
    try {
      setLoading(true);
      const res = await endpoint;
      if (res.status >= 200 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: `Proveedor ${type} correctamente`,
        });
        setTimeout(() => {
          reset();
          history.push("/proveedores/listado");
          setLoading(false);
        }, [2000]);
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: dataProvider?.id ? "Error de Edicion" : "Error de creacion",
        });
        setLoading(false);
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: error.toString(),
      });
      setLoading(false);
    }
  };
  
  // VERIFICACIÓN DE PERMISOS
  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);

  // EFFECT RUNS WHEN PROVIDERS DATA IS UPDATED
  useEffect(() => {
    // reset form with provider data
    reset(dataProvider);
  }, [loadingDataProvider]);

  // THE EFFECT IS EXECUTED WHEN THE SELECTABLE TYPE OF PROVIDER IS CHANGED.
  useEffect(() => {
    watch("tipo") === "Transportista" && setArraySubtipos(transportistasSubtypes);
    watch("tipo") === "Materiales" && setArraySubtipos(materialesSubtypes);
    if (watch("tipo") === "Combustible" || watch("tipo") === "Generales") {
      setArraySubtipos([]);
      clearErrors("sub_tipo");
    };
    //  setOpenAlert(true);
  }, [watch("tipo")])

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{
              marginTop: "15rem",
            }}
          >
            <CircularProgress
              style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            {acceso && (
              <>
                {/* */}
                <Grid
                  container
                  item
                  className={classes?.container}
                >
                  <Card title={idProvider ? "Editar Proveedor" : "Nuevo Proveedor"} justifyContent="center">
                    {loadingDataProvider ? (
                      <LoadingText title={idProvider ? "Cargando Edición de Proveedor" : "Cargando Formulario de Proveedor"} />
                    ) : (
                      <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Grid
                            container
                            item
                            className={classes?.cardContainerScrollable}
                          >
                            <Grid
                              container
                              spacing={1}
                              item
                              style={{
                                margin: "1rem 1rem",
                              }}
                            >
                              <Grid
                                item
                                xl={2}
                                lg={2}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <InputField
                                  name="cuil_cuit"
                                  label="CUIL/CUIT:*"
                                  placeholder="Ingrese CUIL o CUIT"
                                  control={control}
                                  rules={rules.cuil}
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                />
                              </Grid>
                              <Grid
                                item
                                xl={4}
                                lg={4}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <InputField
                                  name="razon_social"
                                  label="Razón Social:*"
                                  placeholder="Ingrese Razón Social"
                                  control={control}
                                  rules={rules.generic}
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                />
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <SelectCustom
                                  control={control}
                                  rules={rules.generic}
                                  name="cond_iva"
                                  label="Condición IVA:*"
                                  size="small"
                                  MenuProps={{ classes: { paper: classes?.menuPaper } }}
                                >
                                  {CondicionesIVA?.length > 0 &&
                                    CondicionesIVA?.map((item) => (
                                      <MenuItem key={item.id} value={item.value}>
                                        {item.nombre}
                                      </MenuItem>
                                    ))}
                                </SelectCustom>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <InputField
                                  item
                                  fullWidth
                                  name="telefono"
                                  label="N° de Teléfono*"
                                  placeholder="Ingrese Número de Teléfono"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  InputLabelProps={{ shrink: true }}
                                  rules={rules.generic}
                                  control={control}
                                />
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <SelectCustom
                                  control={control}
                                  rules={rules.generic}
                                  name="tipo"
                                  label="Proveedor:*"
                                  size="small"
                                  funcionAdicional={() => { idProvider && setOpenAlert(true) }}
                                >
                                  <MenuItem value={"Transportista"}>Transportistas</MenuItem>
                                  <MenuItem value={"Materiales"}>Materiales</MenuItem>
                                  <MenuItem value={"Combustible"}>Combustibles</MenuItem>
                                  <MenuItem value={"General"}>Generales</MenuItem>
                                </SelectCustom>
                              </Grid>                            
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <SelectCustom
                                  control={control}
                                  rules={!arraySubtipos?.length ? rules.genericNoRequered : rules.generic}
                                  disabled={!arraySubtipos?.length}
                                  name="sub_tipo"
                                  label="Tipo Proveedor"
                                  size="small"
                                >
                                  {arraySubtipos?.length > 0 &&
                                    arraySubtipos?.map((item) => (
                                      <MenuItem key={item.id} value={item.nombre}>
                                        {item.nombre}
                                      </MenuItem>
                                    ))}
                                </SelectCustom>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <InputField
                                  item
                                  fullWidth
                                  name="email"
                                  label="Email"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  placeholder="Ingrese correo electrónico"
                                  InputLabelProps={{ shrink: true }}
                                  rules={rules.emailNoRequired}
                                  control={control}
                                  style={{ marginBottom: "1rem" }}
                                />
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <InputField
                                  name="direccion"
                                  label="Dirección"
                                  placeholder="Ingrese Dirección"
                                  control={control}
                                  rules={rules.genericNoRequered}
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                />
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <SelectCustom
                                  name="provincia_nombre"
                                  label={"Provincia:*"}
                                  control={control}
                                  funcionAdicional={(e) => { getDepartamentos(e); setValue("provincia", provincias?.find((prov) => prov.nombre === e)?.id) }}
                                  size="small"
                                  rules={rules.generic}
                                  displayEmpty
                                  MenuProps={{ classes: { paper: classes?.menuPaper } }}
                                >
                                  {provincias?.length > 0 &&
                                    provincias?.map((item) => (
                                      <MenuItem key={item.id} value={item.nombre}>
                                        {item.nombre}
                                      </MenuItem>
                                    ))}
                                </SelectCustom>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <SelectCustom
                                  name="departamento_nombre"
                                  label={"Departamento:*"}
                                  control={control}
                                  funcionAdicional={(e) => { getLocalidades(e); setValue("departamento", departamentos?.find((dptos) => dptos.nombre === e)?.id) }}
                                  size="small"
                                  rules={rules.generic}
                                  displayEmpty
                                  MenuProps={{ classes: { paper: classes?.menuPaper } }}
                                >
                                  {departamentos?.length > 0 &&
                                    departamentos?.map((item) => (
                                      <MenuItem key={item.id} value={item.nombre}>
                                        {item.nombre}
                                      </MenuItem>
                                    ))}
                                </SelectCustom>
                              </Grid>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                <SelectCustom
                                  name="localidad_nombre"
                                  label={"Localidad:*"}
                                  control={control}
                                  funcionAdicional={(e) => { setValue("localidad", localidades?.find((loc) => loc.nombre === e)?.id) }}
                                  size="small"
                                  rules={rules.generic}
                                  displayEmpty
                                  MenuProps={{ classes: { paper: classes?.menuPaper } }}
                                >
                                  {localidades?.length > 0 &&
                                    localidades?.map((item) => (
                                      <MenuItem key={item.id} value={item.nombre}>
                                        {item.nombre}
                                      </MenuItem>
                                    ))}
                                </SelectCustom>

                              </Grid>

                              <Grid container spacing={1} direction="row" alignItems="center" justifyContent="flex-end" item style={{
                                padding: "1rem",
                              }}>
                                <Grid item>
                                  <Collapse in={dataProvider && idProvider && tipoActual !== watch("tipo") ? true : false}>

                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                      <Alert variant="outlined" severity="warning" size="small">
                                        Si cambia el tipo de proveedor, los remitos creados para el tipo anterior no los podrá visualizar.
                                      </Alert>
                                    </Stack>

                                  </Collapse>
                                </Grid>
                                <Grid item>
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                      setConfirmDialog({
                                        title: `No se guardarán los datos cargados del proveedor`,
                                        isOpen: true,
                                        onConfirm: () => {
                                          history.goBack();
                                        },
                                      })
                                    }
                                  >
                                    Volver
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <ButtonLoading
                                    isLoading={loading}
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    variant="contained"
                                  >
                                    Guardar
                                  </ButtonLoading>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      </>
                    )}
                  </Card>
                </Grid>
              </>
            )}
          </>
        )}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
          autoHideDuration={snackState.autoHideDuration}
        />
      </RootContainer>
    </Protected>
  )
}

export default CrearEditarProveedor