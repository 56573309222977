import { createNewUrl } from "constants/funciones/createUrl";
import axios from "./axios.config";

class ProveedoresService {
  // PROVEEDORES

  /**
   * * Crear Proveedor
   * @param {*} body
   * @returns
   */
  createProvider(body) {
    return axios.post(`api/proveedores/`, body);
  }

  /**
   * * Editar Proveedor
   * @param {*} id : id de Proveedor
   * @returns
   */
  editProvider(id, body) {
    return axios.patch(`api/proveedores/${id}/`, body);
  }

  /**
   * * Eliminar Proveedor
   * @param {*} id : id de Proveedor
   * @returns
   */
  deleteProvider(id) {
    return axios.delete(`api/proveedores/${id}/`);
  }

  /**
   * * Listar Proveedores
   * @returns  todos los proveedores o filtra según params paginado
   * @params filtros opcionales por tipo o cuit_cuil/razón social
   */
  getFilteredAllProviders(params) {
    const base_url = `api/proveedores/`;
    const url = `${base_url}${createNewUrl(params)}`;
    return axios.get(url);
  }

  /**
   * * Listar Proveedores Sin Paginación
   * @returns  todos los proveedores sin paginacion
   * @params
   */
  getAllProviders() {
    const base_url = `api/proveedores/sin-paginacion/`;
    const url = `${base_url}${createNewUrl()}`;
    return axios.get(url);
  }

  /** Obtener proveedores de tipo transportista
   * @returns todos los proveedores que sean de tipo transportista
   */
  getTransportistas() {
    return axios.get(`api/proveedores/?tipo=Transportista`);
  }
  /**
   * * Obtener Detalle de un proveedor
   * @param id: id del proveedor
   * @returns
   */
  getDetailsProviderById(id) {
    return axios.get(`api/proveedores/${id}/`);
  }

  // MATERIALES DE PROVEEDORES

  /**
   * * Listar Todos los Materiales Proveedores
   * @returns  todos los proveedores o filtra según params
   * @params filtros opcionales por tipo o cuit_cuil/razón social
   */
  getFilteredAllProvidersMaterials(params) {
    const base_url = `api/proveedores/materiales/`;
    const url = `${base_url}${createNewUrl(params)}`;
    return axios.get(url);
  }

  /**
   * * Listar Todos los Materiales Proveedores Sin Paginación
   */
  getAllProvidersMaterialsNoPaginated() {
    return axios.get(`api/proveedores/materiales-sin-paginacion/`);
  }

  /**
   * * Listar Todos los Materiales Proveedores Sin Paginación
   */
  getProvidersMaterialsTypes(id) {
    return axios.get(`/api/proveedores/materiales-sin-paginacion/${id}/tipos`);
  }

  /**
   * * Eliminar Material
   * @param {*} id : id de Material
   * @returns
   */
  deleteProviderMaterial(id) {
    return axios.delete(`api/proveedores/materiales/${id}/`);
  }

  /**
   * * Crear Material
   * @param {*}
   * @returns
   */
  createProviderMaterial(body) {
    return axios.post(`api/proveedores/materiales/`, body);
  }

  /**
   * * Editar Material
   * @param {*} id : id de Material
   * @returns
   */
  editProviderMaterial(id, body) {
    return axios.patch(`api/proveedores/materiales/${id}/`, body);
  }

  // CHOFERES AUTORIZADOS

  /**
   * * Listar Todos los Choferes Autorizados
   * @returns  todos los choferes o filtra según params
   * @params filtros opcionales por nombre, cuit o empresa
   */
  getFilteredAllAuthorizedDrivers(params) {
    const base_url = `api/proveedores/choferes/`;
    const url = `${base_url}${createNewUrl(params)}`;
    return axios.get(url);
  }

  /**
   * * Crear Chofer
   * @param {*}
   * @returns
   */
  createAuhthorizedDriver(body) {
    return axios.post(`api/proveedores/choferes/`, body);
  }

  /**
   * * Editar Chofer
   * @param {*} id : id de Chofer
   * @returns
   */
  editAuhthorizedDriver(id, body) {
    return axios.patch(`api/proveedores/choferes/${id}/`, body);
  }

  /**
   * * Eliminar Chofer
   * @param {*} id : id de Chofer
   * @returns
   */
  cambiarEstadoChofer(id) {
    return axios.delete(`api/proveedores/choferes/${id}/`);
  }

  // REMITOS DE PROVEEDORES

  /** Obtener proveedores de tipo materiales
   * @returns todos los proveedores que sean de tipo materiales
   */
  getCanterasAreneras() {
    return axios.get(`api/proveedores/?tipo=Materiales`);
  }

  // REMITOS DE PROVEEDORES

  /** Obtener remitos del proveedor
   * @param {object} prov datos del proveedor
   */
  getRemitoProveedor(prov, filtro) {
    console.log("**** axios", filtro);
    return axios.get(
      `api/proveedores/${prov.id}/listar-remito-proveedor/?search=${filtro.search}&fecha_desde=${filtro.fecha_desde}&fecha_hasta=${filtro.fecha_hasta}&ordFecha=${filtro.ordfecha}&ordIngreso=${filtro.ordingreso}&ordTrans=${filtro.ordtrans}&ordFactRem=${filtro.ordnumfac}&ordCant=${filtro.ordcant}&ordMonto=${filtro.ordmonto}&ordComb=${filtro.ordcomb}&page=${filtro.page}`,
    );
  }

  /** 
  Post Crear Remito Proveedor
  * @param {object} prov datos del proveedor
  * @param {object} body datos del remito
  */
  addRemito(prov, body) {
    return axios.post(
      `api/proveedores/${prov?.id}/remito-proveedor/?type=${
        prov?.tipo === "Materiales"
          ? "mat"
          : prov?.tipo === "Transportista"
          ? "trans"
          : prov?.tipo === "Combustible"
          ? "comb"
          : "gral"
      }`,
      body,
    );
  }

  /** 
  Editar Remito Proveedor
  * @param {number} id identificador del remito
  * @param {object} body datos del remito
  */
  editRemito(id, body) {
    return axios.put(`/api/proveedores/remito-proveedor/${id}/`, body);
  }

  /**
   * * Eliminar Remito
   * @param {*} id : id de remito
   * @returns
   */
  deleteRemito(id) {
    return axios.delete(`api/proveedores/remito-proveedor/${id}/`);
  }

  /** 
  Cargar Factura Proveedor
  * @param {number} idProv identificador del proveedor
  * @param {string} valorFactura valor que se va a facturar
  */
  cargarFactura(idProv, valorFactura) {
    return axios.patch(`/api/proveedores/${idProv}/`, {
      total_facturado: valorFactura,
    });
  }

  /** 
  Descargar Excel de Remitos del Proveedor
  * @param {object} filtro información para filtrar los datos del excel
  * @param {number} idProv identificador del proveedor
  */
  descargarExcelRemitos(idProv, filtro) {
    return axios.get(
      `api/proveedores/remito-proveedor/${idProv}/generar-excel/?search=${
        filtro.buscador ? filtro.buscador : ""
      }&fecha_desde=${filtro.fecha_desde}&fecha_hasta=${
        filtro.fecha_hasta
      }&ordFecha=${filtro.ordfecha}&ordIngreso=${filtro.ordingreso}&ordTrans=${
        filtro.ordtransp
      }&ordFactRem=${filtro.ordfacremi}&ordCant=${
        filtro.ordcantaren
      }&ordMonto=${filtro.ordmonto}&ordComb=${filtro.ordcomb}`,

      {
        responseType: "blob",
      },
    );
  }
}

export default new ProveedoresService();
