import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

const locale = {
  name: "es",
  weekdays: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
};

const useDayjs = () => {
  dayjs.locale(locale, null, true);
  dayjs.locale("es");
  dayjs.extend(customParseFormat);

  return dayjs;
};

export default useDayjs;
