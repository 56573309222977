import React, { useEffect, useState } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import { Close, Save } from "@material-ui/icons";
import ClientesService from "services/clientes.service";
import { useQueryClient } from "react-query";
import ConfirmDialog from "components/ConfirmDialog";

const ResponsableCard = ({
  setVerificado,
  setSinStackear,
  responsables,
  setResponsables,
  desabilitarEliminar,
  setSnackBar,
}) => {
  const queryClient = useQueryClient();

  const [nombre, setNombre] = useState(
    responsables?.nombre && responsables.nombre
  );
  const [telefono, setTelefono] = useState(
    responsables?.num_telefono && responsables.num_telefono
  );
  const [email, setEmail] = useState(
    responsables?.email && responsables?.email
  );

  const [errorNombre, setErrorNombre] = useState();
  const [errorTelefono, setErrorTelefono] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [habilitarBoton, setHabilitarBoton] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  const handleChangeNombre = (e) => {
    setHabilitarBoton(true);
    let regex = new RegExp(/^[a-z ñáéíóú']+$/i);
    setNombre(e);
    if (regex.test(e)) {
      setErrorNombre(false);
    } else {
      setErrorNombre(true);
    }
  };

  const handleChangeTelefono = (e) => {
    setHabilitarBoton(true);
    setTelefono(e);
    if (e) {
      setErrorTelefono(false);
    } else {
      setErrorTelefono(true);
    }
  };

  const handleChangeEmail = (e) => {
    setHabilitarBoton(true);
    let regex = new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
    setEmail(e);
    if (regex.test(e) || e === "") {
      setErrorEmail(false);
    } else {
      setErrorEmail(true);
    }
  };

  const verificarCreacion = () => {
    if (nombre && telefono && !errorNombre && !errorTelefono & !errorEmail) {
      setVerificado(true);
      setSinStackear({
        nombre,
        telefono,
        email,
      });
    } else {
      setVerificado(false);
    }
  };

  const editarResponsable = async () => {
    const sendData = {
      nombre: nombre.toUpperCase(),
      num_telefono: telefono,
      email: email,
    };
    try {
      const res = await ClientesService.actualizarResponsable(
        responsables.id,
        sendData
      );
      if (res.status > 199 && res.status < 299) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Actualización exitosa",
        });
        queryClient.invalidateQueries("obtenerResponsables");
        setHabilitarBoton(false);
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error actualizando",
        });
      }
    } catch (error) {
      if (error?.response?.data?.email) {
        setErrorEmail("true");
        setSnackBar({
          open: true,
          severity: "error",
          message: error?.response?.data?.email,
        });
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error Servidor",
        });
      }
    }
  };

  const eliminarResponsable = async () => {
    try {
      const res = await ClientesService.eliminarResponsable(responsables.id);
      if (res.status > 199 && res.status < 299) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Eliminación exitosa",
        });
        setResponsables([]);
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          message: "Error en eliminación",
        });
      }
    } catch (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Error en el servidor",
      });
    }
    queryClient.invalidateQueries("obtenerResponsables");
  };

  useEffect(() => {
    verificarCreacion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nombre, telefono, email]);

  return (
    <Grid
      container
      direction="row"
      alignContent="center"
      alignItems="center"
      spacing={2}
      justifyContent="space-between"
      style={{
        margin: "0.5rem",
        padding: "0.5rem",
        borderRadius: 10,
      }}
    >
      <Grid container item xl={3} lg={3} md={6} sm={6} xs={12}>
        <TextField
          name="nombre"
          size="small"
          variant="outlined"
          fullWidth
          value={nombre && nombre}
          label="Nombre"
          type="text"
          autoComplete="off"
          onChange={(e) => handleChangeNombre(e.target.value)}
          error={errorNombre}
        />
      </Grid>

      <Grid container item xl={3} lg={3} md={6} sm={6} xs={12}>
        <TextField
          name="telefono"
          size="small"
          fullWidth
          variant="outlined"
          label="Teléfono"
          type="text"
          autoComplete="off"
          value={telefono && telefono}
          onChange={(e) => handleChangeTelefono(e.target.value)}
          error={errorTelefono}
        />
      </Grid>
      <Grid
        container
        item
        xl={responsables?.id ? 4 : 6}
        lg={responsables?.id ? 4 : 6}
        md={6}
        sm={6}
        xs={12}
        style={{ padding: "0.5rem" }}
      >
        <TextField
          fullWidth
          name="email"
          label="Email"
          variant="outlined"
          size="small"
          type="text"
          autoComplete="off"
          value={email && email}
          onChange={(e) => handleChangeEmail(e.target.value)}
          error={errorEmail}
        />
      </Grid>

      <Grid container item xl={2} lg={2} md={5} sm={5} xs={12} direction="row">
        {responsables?.id && (
          <Grid
            container
            spacing={1}
            item
            direction="row"
            alignItems="center"
            style={{
              borderRadius: 5,
              height: "2.5rem",
            }}
          >
            <Grid item>
              <IconButton
                style={{ width: "1.6rem", height: "1.6rem" }}
                onClick={() => editarResponsable()}
                disabled={!habilitarBoton && !errorNombre && !errorTelefono}
              >
                <Save
                  color={
                    habilitarBoton && !errorNombre && !errorTelefono
                      ? "primary"
                      : "#ffff"
                  }
                  style={{ height: "1.6rem", width: "1.6rem" }}
                />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                style={{ width: "1.6rem", height: "1.6rem" }}
                disabled={desabilitarEliminar}
                onClick={() =>
                  setConfirmDialog({
                    title: `¿Está seguro de eliminar al responsable?`,
                    isOpen: true,
                    onConfirm: () => {
                      eliminarResponsable();
                    },
                  })
                }
              >
                <Close
                  color={!desabilitarEliminar ? "primary" : "#ffff"}
                  style={{ height: "1.6rem", width: "1.6rem" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default ResponsableCard;
