import { Grid, Button, Typography } from "@material-ui/core";
import { ReactComponent as Logo } from "../../assets/svg/logoAyupi.svg";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { styled } from "@material-ui/styles";

import { UserContext } from "context/UserContext";
import { LogIn } from "context/LogInContext";
import Theme from "theme";

const Leyenda = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.primary.main,
  color: "#ffffff",
  fontSize: "1rem",
  borderRadius: "1rem",
  padding: theme.spacing(1.5),
}));

const Home = () => {
  let history = useHistory();

  const { logged } = useContext(LogIn);
  const { user } = useContext(UserContext);

  const handleClick = () => {
    history.push("/auth/login");
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        style={{
          backgroundColor: "#AEDF00",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      >
        {" "}
        <Grid
          container
          xl={4}
          lg={4}
          md={6}
          sm={8}
          xs={12}
          item
          direction="column"
          alignItems="center"
        >
          {logged ? (
            user.user_rol === 1 ? (
              history.push("/panel-admin/admin-usuarios")
            ) : (
              <Leyenda>
                {"Bienvenido/a"} {user.user_name}
              </Leyenda>
            )
          ) : (
            <>
              <Grid container item direction="column" alignItems="center">
                <Logo />
                <Button
                  variant="contained"
                  size="large"
                  style={{ marginTop: "4rem" }}
                  color="primary"
                  onClick={handleClick}
                >
                  INICIAR SESION
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
          direction="row"
          justifyContent="flex-end"
          alignContent="flex-end"
          style={{ marginTop: "10rem" }}
        >
          <Typography
            style={{
              marginRight: "1rem",
              color: Theme.palette.backgroundBlack.main,
            }}
          >
            Versión 1.6.3 - 02/08/2023
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default Home;
