import { React, useEffect, useState, useContext } from "react";
import Protected from "protected";
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Theme from "theme";
import { useHistory } from "react-router-dom";
import UsuarioService from "services/usuario.service";
import RootContainer from "layout/rootContainer";
import LogService from "services/log.service";
import axios from "../../services/axios.config";
import ContainerCustom from "components/comtainerCustom";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { LogIn } from "context/LogInContext";
const Logs = () => {
  const history = useHistory();
  const { logged } = useContext(LogIn);
  const [acceso, setAcceso] = useState(false);
  const [verificando, setVerificando] = useState(true);

  const [actual, setActual] = useState();
  const [total, setTotal] = useState();
  const [stringBusqueda, setStringBusqueda] = useState();

  const [siguiente, setSiguiente] = useState(null);
  const [anterior, setAnterior] = useState(null);
  const [logs, setLogs] = useState([]);

  const siguientePagina = async () => {
    const res = await axios.get(siguiente);
    setLogs(res.data.results);
    setSiguiente(res.data.next);
    setAnterior(res.data.previous);
    setActual(res.data.actual);
    setTotal(res.data.total_paginas);
  };
  const anteriorPagina = async () => {
    const res = await axios.get(anterior);
    setLogs(res.data.results);
    setSiguiente(res.data.next);
    setAnterior(res.data.previous);
    setActual(res.data.actual);
    setTotal(res.data.total_paginas);
  };

  const handleBusqueda = async (text) => {
    setStringBusqueda(text);
    setTimeout(buscar(text), 700);
  };

  const buscar = async (text) => {
    try {
      const res = await LogService.searchLogs(text);
      if (res.status === 200) {
        setLogs(res.data.results);
        setSiguiente(res.data.next);
        setAnterior(res.data.previous);
        setActual(res.data.actual);
        setTotal(res.data.total_paginas);
      }
    } catch (error) {
      setLogs(null);
      setSiguiente(null);
      setAnterior(null);
      setActual(0);
    }
  };

  const verificarAcceso = async () => {
    try {
      const res = await UsuarioService.checkAccess({ modulo: "logs" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "logs" });
      }
    } catch (error) {
      setAcceso(false);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "logs" });
    }
  };

  const getLogs = async () => {
    try {
      const res = await LogService.getLogs();
      if (res.status > 199 && res.status < 299) {
        setLogs(res.data.results);
        setSiguiente(res.data.next);
        setAnterior(res.data.previous);
        setActual(res.data.actual);
        setTotal(res.data.total_paginas);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (logged) {
      verificarAcceso();
      getLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Protected>
      <RootContainer>
        <div
          style={{
            paddingTop: "1rem",
            paddingRight: "1rem",
            width: "100%",
            height: window.innerHeight - 50,
          }}
        >
          {verificando && (
            <ContainerCustom>
              <Grid
                container
                item
                direction="column"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <CircularProgress style={{ color: "#ffffff" }} />
                <Typography style={{ color: "#ffffff" }}>
                  Verificando permisos
                </Typography>
              </Grid>
            </ContainerCustom>
          )}

          {acceso && (
            <>
              <Grid
                container
                item
                justifyContent="flex-end"
                style={{ marginTop: "5rem", marginBottom: "1rem" }}
              >
                <TextField
                  size="small"
                  id="outlined-full-width"
                  label="Ingrese usuario"
                  variant="outlined"
                  value={stringBusqueda && stringBusqueda}
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 5,
                    minWidth: "15rem",
                  }}
                  onChange={(e) => handleBusqueda(e.target.value)}
                  InputLabelProps={{
                    style: { color: "#555555" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton size="small">
                          <SearchOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                container
                item
                style={{
                  borderRadius: 10,
                  backgroundColor: "#ffffff",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "2rem",
                }}
              >
                <Grid
                  container
                  item
                  justifyContent="center"
                  alignContent="center"
                  style={{
                    backgroundColor: Theme.palette.primary.main,
                    height: "3rem",
                    width: "100%",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    Información de eventos
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  style={{
                    padding: "1rem",
                    maxHeight: "90%",
                  }}
                >
                  <TableContainer
                    style={{
                      overflow: "scroll",
                      scrollbarWidth: "0px",
                      width: "100%",
                      height: window.innerHeight - 350,
                    }}
                  >
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{
                              textAlign: "left",
                              width: "25%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Usuario
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              textAlign: "left",
                              width: "25%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Rol
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              textAlign: "left",
                              width: "25%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Fecha
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              textAlign: "left",
                              width: "25%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Acción
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {logs?.length > 0 &&
                          logs?.map((log) => (
                            <TableRow key={log.id}>
                              <TableCell align="left">
                                {log?.usuario?.email ? log.usuario.email : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {log?.rol?.nombre ? log.rol.nombre : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {log?.created ? log.created : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {log?.detalle ? log.detalle : "-"}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    {anterior && (
                      <IconButton onClick={() => anteriorPagina()}>
                        <ArrowBack />
                      </IconButton>
                    )}

                    <Typography>
                      {actual}/{total} Páginas
                    </Typography>

                    {siguiente && (
                      <IconButton onClick={() => siguientePagina()}>
                        <ArrowForward />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </RootContainer>
    </Protected>
  );
};
export default Logs;
