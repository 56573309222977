//REACT
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { useDebounce } from "hook/useDebounce";
import { useGetAllNotes, useDownloadNotes } from "../hook";

//MATERIAL UI
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  TableContainer,
  TextField,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
} from "@material-ui/core";
import Select from "@mui/material/Select";
import Link from "@material-ui/core/Link";
import Pagination from "@material-ui/lab/Pagination";
import { LogIn } from "context/LogInContext";
import moment from "moment";
import { shallow } from "zustand/shallow";

//ICONS
import { CompareArrows, Visibility } from "@material-ui/icons";

//COMPONENTS
import Protected from "protected";
import SearchBar from "components/Layout/searchBar/SearchBar";
import VerificarAcceso from "components/verificarAcceso";
import RootContainer from "layout/rootContainer";
import StyledIconButton from "components/StyledIconButton";
import VisualizarFactura from "../visualizar";
import { namesFacturacion } from "../constants/const";
import {
  useFacturaActions,
  useFacturasStore,
  useNoteTipeSelected,
  useOrderBy,
  useSearchNotesOrClients,
  useRef,
} from "../store";

//STYLES
import FactClienteStyles from "../FacturaStyles";
import ButtonLoading from "components/buttonLoading/ButtonLoading";

//ICONS
import ExcelIcon from "icons/excel";

const NotasCreditoDebito = () => {
  const history = useHistory();
  const classes = FactClienteStyles();
  const { logged } = useContext(LogIn);

  //ESTADO PARA VERIFICAR ACCESO A MODULO
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // ESTADO PARA ABRIR VISUALIZAR NOTA CREDITO/DEBITO
  const [openVisualizarFactura, setOpenVisualizarFactura] = useState(false);
  const [facturaId, setFacturaId] = useState();
  const [ordenDetails, setOrdenDetails] = useState(false);

  // ESTADOS PARA NAVEGACION ENTRE PAGINAS
  const [actual, setActual] = useState();
  const [total, setTotal] = useState();

  //=============================== ESTADOS PARA ACCIONES =========================================

  // ESTADO PARA ABRIR VISUALIZAR NOTA CREDITO/DEBITO

  const verFactura = (id) => {
    setOpenVisualizarFactura(true);
    setFacturaId(id);
  };

  const verOrden = (data) => {
    setOrdenDetails(data);
    setOpenVisualizarFactura(true);
    setFacturaId(data.factura_id);
  };
  //=============================== FIN ESTADOS PARA ACCIONES ==============================================

  // state manager with zustand
  const filtro = useFacturasStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      tipo: state.tipo,
      sort: state.sort,
      page: state.page,
    }),
    shallow,
  );
  const selectTexto = useNoteTipeSelected();
  const searchText = useSearchNotesOrClients();
  const orderBy = useOrderBy();
  const ref = useRef();

  //Actions with zustand
  const {
    setFechaDesde,
    setFechaHasta,
    setSelectTexto,
    setSearch,
    setTipo,
    setPage,
    setSort,
    toggleSort,
    resetState,
    setRef,
  } = useFacturaActions();

  // useDebounce hook for input text
  const debouncedValue = useDebounce(searchText, 300);

  const onSuccess = (data) => {
    // Seteo los datos del paginado
    setActual(data?.actual);
    setTotal(data?.total_paginas);
  };

  // QUERY PARA OBTENER NOTAS(CREDITOS && DEBITOS)
  const { data: filtrado, isLoading } = useGetAllNotes({
    filtro,
    debouncedValue,
    onSuccess,
  });

  // FUNCION DE BUSCADOR POR NOTA O CLIENTE
  const handleSearch = async (text) => {
    setSearch(text);
  };

  //=============================== SETEAR FILTROS ==============================================

  const mostrarPor = async (search) => {
    switch (search) {
      case namesFacturacion?.todas:
        setTipo("");
        break;
      case namesFacturacion?.notasCredito:
        setTipo(namesFacturacion?.notasCredito);
        break;
      case namesFacturacion?.notasDebito:
        setTipo(namesFacturacion?.notasDebito);
        break;
      default:
        setTipo(namesFacturacion?.todas);
    }
  };
  //=============================== FIN FILTROS ==============================================

  // -- Order Column -- //
  const sortBy = (campo) => {
    // sortType = -1 para desc y 1 para asc
    const sortType = orderBy ? "1" : "-1";
    setSort(`${campo}:${sortType}`);
    toggleSort();
  };

  //-- Query Descargar Excel --//
  const {
    isLoading: loadingExcel,
    isRefetching,
    refetch,
  } = useDownloadNotes({
    filtro,
    debouncedValue,
  });

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  useEffect(() => {
    console.log("ref", ref);
    if (ref !== "notas-cred-deb") {
      resetState();
      setTimeout(() => {
        setRef("notas-cred-deb");
      }, 200);
      setSearch("");
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="facturacion"
            history={history}
            logged={logged}
          />
        ) : (
          <>
            {acceso && !openVisualizarFactura ? (
              <>
                <Grid
                  container
                  style={{
                    margin: "1rem 2rem 0rem 2rem",
                    overflow: "auto",
                  }}
                >
                  <Grid
                    container
                    item
                    direction="row"
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <ButtonLoading
                          isLoading={loadingExcel || isRefetching}
                          color="primary"
                          startIcon={<ExcelIcon />}
                          onClick={() => refetch()}
                        >
                          Descargar Excel
                        </ButtonLoading>
                      </Grid>
                    </Grid>
                    <Grid
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "0.5rem" }}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Desde"
                          required
                          focused
                          defaultValue={filtro.fecha_desde}
                          onChange={(e) =>
                            setFechaDesde(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            max: filtro.fecha_hasta,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Fecha Hasta"
                          required
                          focused
                          defaultValue={filtro.fecha_hasta}
                          onChange={(e) =>
                            setFechaHasta(
                              moment(e.target.value).format("YYYY-MM-DD"),
                            )
                          }
                          inputProps={{
                            min: filtro.fecha_desde,
                            max: moment().format("YYYY-MM-DD"),
                          }}
                        />
                      </Grid>
                      {/* <Grid item style={{ marginRight: "0.5rem" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => filterByDate()}
                          style={{
                            textTransform: "capitalize",
                            maxHeight: "2rem",
                          }}
                        >
                          Filtrar
                        </Button>
                      </Grid> */}
                    </Grid>

                    <Grid
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent={"flex-end"}
                      alignContent="flex-start"
                      alignItems="center"
                      style={{ marginTop: "0.5rem" }}
                      spacing={1}
                      item
                    >
                      <Grid item>
                        <Box>
                          <FormControl style={{ width: "190px" }}>
                            <Select
                              size="small"
                              variant="outlined"
                              value={selectTexto}
                              displayEmpty
                              renderValue={
                                selectTexto !== ""
                                  ? undefined
                                  : () => (
                                      <Placeholder>
                                        {selectTexto
                                          ? selectTexto
                                          : "Mostrar por"}
                                        :
                                      </Placeholder>
                                    )
                              }
                              onChange={(e) => {
                                mostrarPor(e.target.value);
                                setSelectTexto(e.target.value);
                              }}
                            >
                              <MenuItem value={namesFacturacion?.todas}>
                                Todas
                              </MenuItem>
                              <MenuItem value={namesFacturacion?.notasCredito}>
                                Notas de Credito
                              </MenuItem>
                              <MenuItem value={namesFacturacion?.notasDebito}>
                                Notas de Debito
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item>
                        <SearchBar
                          placeholder="Buscar por N° Nota o Cliente"
                          defaultValue={searchText}
                          value={searchText}
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    style={{
                      marginTop: "0.5rem",
                      marginBottom: "5rem",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      className={classes.headerTable}
                    >
                      <Typography
                        variant="h5"
                        className={classes.headerTableTittle}
                      >
                        Lista de Notas
                      </Typography>
                    </Grid>

                    {isLoading ? (
                      <Grid container justifyContent="center">
                        <Grid>
                          <CircularProgress
                            size="1.5rem"
                            style={{ margin: "2rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <TableContainer
                          component={Paper}
                          style={{
                            maxHeight: "49vh",
                            overflow: "auto",
                          }}
                        >
                          <Table size="small" stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  className={classes.tableCell2}
                                  style={{ minWidth: "9rem" }}
                                >
                                  N° Nota
                                  <IconButton onClick={() => sortBy("id")}>
                                    <CompareArrows
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  className={classes.tableCell2}
                                  style={{ minWidth: "14rem" }}
                                >
                                  Fecha de Emisión
                                  <IconButton onClick={() => sortBy("creado")}>
                                    <CompareArrows
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  className={classes.tableCell2}
                                  style={{ minWidth: "12rem" }}
                                >
                                  Cliente
                                </TableCell>

                                <TableCell
                                  className={classes.tableCell2}
                                  style={{
                                    minWidth: "9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  N° Factura Asoc.
                                </TableCell>

                                <TableCell className={classes.tableCell2}>
                                  Orden/Resumen
                                  <IconButton onClick={() => sortBy("orden")}>
                                    <CompareArrows
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell className={classes.tableCell2}>
                                  Monto
                                  <IconButton onClick={() => sortBy("monto")}>
                                    <CompareArrows
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell2}
                                >
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            {filtrado?.results?.length > 0 &&
                              filtrado?.results?.map((fac) => (
                                <TableBody key={fac?.id}>
                                  <TableRow
                                    hover
                                    style={{
                                      backgroundColor: fac?.presupuesto
                                        ? "#E26E6E"
                                        : "",
                                    }}
                                  >
                                    <TableCell className={classes.tableCell3}>
                                      {fac?.id ?? "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell3}>
                                      {fac?.creado ?? "-"}
                                    </TableCell>

                                    <TableCell
                                      className={classes.tableCell3}
                                      style={{
                                        fontWeight: 700,
                                        fontSize: "17px",
                                        color:
                                          fac?.cliente?.nombre && "#1C3AA9",
                                        textDecoration: "none",
                                      }}
                                    >
                                      {fac?.cliente?.nombre ?? "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell3}>
                                      {fac?.factura_id ? (
                                        <Link
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "17px",
                                            color: "#1C3AA9",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                          }}
                                          onClick={() =>
                                            verFactura(fac?.factura_id)
                                          }
                                        >
                                          {`${
                                            fac?.factura_id
                                              ? fac?.num_factura
                                              : "-"
                                          } `}
                                        </Link>
                                      ) : (
                                        "-"
                                      )}
                                    </TableCell>
                                    <TableCell className={classes.tableCell3}>
                                      {fac?.orden ?? "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell3}>
                                      {fac?.monto?.toLocaleString("es-AR", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: "currency",
                                        currency: "ARS",
                                      }) ?? "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell3}>
                                      <Grid
                                        container
                                        item
                                        direction="row"
                                        justifyContent="center"
                                        style={{
                                          whiteSpace: "nowrap",
                                          minWidth: "8rem",
                                        }}
                                      >
                                        <StyledIconButton
                                          size="small"
                                          onClick={() => verOrden(fac)}
                                        >
                                          <Visibility
                                            className={classes.icon}
                                          />
                                        </StyledIconButton>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              ))}
                          </Table>
                        </TableContainer>
                        {filtrado?.results?.length > 0 && (
                          <Grid container justifyContent="center">
                            <Grid style={{ marginTop: "0.5rem" }}>
                              <Pagination
                                count={total}
                                color="primary"
                                onChange={(event, value) => setPage(value)}
                                page={filtro?.page}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {filtrado.count === 0 && (
                          <Grid
                            container
                            justifyContent="center"
                            style={{ margin: "0.5rem" }}
                          >
                            <Typography variant="subtitle1">
                              No se encontraron Notas
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <VisualizarFactura
                facturaId={facturaId}
                ordenDetails={ordenDetails}
                setOpenVisualizarFactura={setOpenVisualizarFactura}
              />
            )}
          </>
        )}
      </RootContainer>
    </Protected>
  );
};
export default NotasCreditoDebito;
