import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  panelRoot: {
    backgroundColor: "#F0F0F0",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
  },
  textFieldContainer: {
    marginBottom: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  generalContainer: {
    borderRadius: 10,
    backgroundColor: "#ffffff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
    marginTop: "1rem",
  },
  headerTable: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    minHeight: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  headerTableTittle: {
    eight: "2rem",
    color: "white",
    textAlign: "center",
  },
  tableContainer: {
    overflow: "scroll",
    scrollbarWidth: "0px",
  },
  table: {
    width: "90%",
  },
});
export default useStyles;
