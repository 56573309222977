/* eslint-disable react-hooks/exhaustive-deps */
//REACT
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useContext } from "react";

//MATERIAL UI
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CompareArrows } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";

//SERVICES
import facturacionServices from "services/facturacion.services";
//VARIOS
import moment from "moment";
import useStyles from "./styles";
import { NotificationContext } from "context/notification/NotificationContext";
import mostrarErrorEP from "constants/funciones/mostrarError";
import LoadingText from "components/loadingText";
import StyledIconButton from "components/StyledIconButton";

export default function Resumenes({
  lista,
  loading,
  ordenarPor,
  setSnackState,
  setConfirmDialog,
  setOpenAgregarFormaPago,
  setDataLista,
  verFactura,
  eliminarResumen,
}) {
  const history = useHistory();
  const cliente = history?.location?.state;
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const classes = useStyles();

  const [cargandoTabla, setCargandoTabla] = useState(false);
  const [listaResumenes, setListaResumenes] = useState([]);


  return (
    <>
      {loading ? (
        <Grid container justifyContent="center">
        <Grid>
          <CircularProgress
            size="1.5rem"
            style={{ margin: "2rem" }}
          />
        </Grid>
      </Grid>
      ) : (
        <TableContainer component={Paper} className={classes.tableContainer} >
          <Table size="small">
            <TableHead>
              <TableRow className="tableRow">
                <TableCell
                  className={classes.tableCell2}
                  style={{
                    minWidth: "15rem",
                    paddingLeft: "5rem",
                  }}
                >
                  N° Resumen
                  <IconButton onClick={() => ordenarPor("numero")}>
                    <CompareArrows
                      style={{
                        width: "1rem",
                        transform: "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </TableCell>

                <TableCell
                  className={classes.tableCell2}
                  style={{ minWidth: "10rem" }}
                >
                  Fecha
                  <IconButton onClick={() => ordenarPor("fecha")}>
                    <CompareArrows
                      style={{
                        width: "1rem",
                        transform: "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </TableCell>

                <TableCell
                  className={classes.tableCell2}
                  style={{ minWidth: "10rem" }}
                >
                  N° Factura
                </TableCell>

                <TableCell
                  className={classes.tableCell2}
                  style={{ minWidth: "10rem" }}
                >
                  N° Orden
                  <IconButton onClick={() => ordenarPor("num_ord")}>
                    <CompareArrows
                      style={{
                        width: "1rem",
                        transform: "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </TableCell>

                <TableCell
                  align="right"
                  style={{ paddingRight: "3rem" }}
                  className={classes.tableCellHeaders}
                >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lista?.count > 0 &&
                lista?.results?.map((resumen) => (
                  <TableRow hover key={resumen.nro_resumen}>
                    <TableCell
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        minWidth: "10rem",
                        paddingLeft: "5rem",
                      }}
                    >
                      {resumen?.nro_resumen ?? "-"}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {moment(resumen?.resumen_fecha).format("DD-MM-YYYY") ?? "-"}
                    </TableCell>

                    <TableCell width="20%" align="left">
                      {resumen?.nro_factura ? (
                        <Link
                          style={{
                            fontWeight: 700,
                            fontSize: "17px",
                            color: "#1C3AA9",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          onClick={() => verFactura(resumen?.factura_id)}
                        >
                          {resumen?.nro_factura}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </TableCell>

                    <TableCell width="15%" align="left">
                      {resumen?.orden?.num_orden ? (
                        <Link
                          style={{
                            fontWeight: 700,
                            fontSize: "17px",
                            color: "#1C3AA9",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          onClick={() =>
                            history.push(
                              `/ordenes/ver-orden/${resumen?.orden?.id ?? ""}/`,
                            )
                          }
                        >
                          {resumen?.orden?.num_orden}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        style={{
                          whiteSpace: "nowrap",
                          minWidth: "10rem",
                        }}
                      >
                        <StyledIconButton
                          disabled={
                            resumen?.nro_factura || resumen?.orden?.num_orden
                          }
                          onClick={() =>
                            setConfirmDialog({
                              title: `¿Está seguro de eliminar el resumen ${resumen?.nro_resumen}?`,
                              isOpen: true,
                              onConfirm: () => {
                                eliminarResumen(resumen?.nro_resumen);
                              },
                            })
                          }
                        >
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              <Typography variant="body2" align="center">
                                {resumen?.nro_factura || resumen?.orden?.num_orden
                                  ? `No se puede eliminar este resumen porque tiene
                              asociado una factura o una orden`
                                  : `Eliminar Resumen Nº${resumen?.nro_resumen}`}
                              </Typography>
                            }
                          >
                            <Delete className={classes.icon} />
                          </Tooltip>
                        </StyledIconButton>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/remitos/remitos-por-cliente/resumen/${resumen.nro_resumen}`,
                            )
                          }
                          children="Ver Resumen"
                          style={{
                            textTransform: "capitalize",
                            maxHeight: "1.5rem",
                          }}
                        />
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer >
      )}
    </>
  )
}
