import { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import InputField from "components/Layout/inputCustom/inputField";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";

const CargarTurno = ({
  classes,
  control,
  rules,
  moment,
  newClient,
  clientes,
  setCliente,
  setFormula,
  cliente,
  formulas,
  NumberFormatCustom,
  setDates,
  editarTurno,
  reasignar,
  turnoNuevo,
  clearErrors,
  setValue,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [inputValueCliente, setInputValueCliente] = useState(
    turnoNuevo?.cliente_id?.nombre || "",
  );
  // eslint-disable-next-line no-unused-vars
  const [inputValueFormula, setInputValueFormula] = useState(
    turnoNuevo?.tipo_formula || "",
  );

  useEffect(() => {
    setValue("cliente_nombre", turnoNuevo?.cliente_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueCliente]);

  useEffect(() => {
    setValue("tipo_formula", turnoNuevo?.tipo_formula);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueFormula]);
  return (
    <Grid style={editarTurno ? { marginTop: "5rem" } : { marginTop: 0 }}>
      <Typography
        variant="h5"
        style={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "23px",
          lineHeight: "24px",
          color: "rgba(0, 0, 0, 0.54)",
          marginTop: "1rem",
        }}
      >
        {editarTurno
          ? reasignar
            ? "Reasignar Turno"
            : "Modificar Turno"
          : "Cargar Turno"}
      </Typography>

      <Grid
        item
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <Typography
            variant="subtitle1"
            className={classes.indicacion}
            style={{ margin: "1rem 0" }}
          >
            Fecha:*
          </Typography>

          {reasignar ? (
            <InputField
              item
              name="fecha_turno"
              size="small"
              label={""}
              variant="outlined"
              InputLabelProps={{ shrink: true, required: true }}
              type="date"
              onChange={(e) => {
                setDates(e.target.value);
                setValue("fecha_turno", e.target.value);
                clearErrors("fecha_turno");
              }}
              inputProps={{ min: moment().format("YYYY-MM-DD") }}
              control={control}
              rules={rules.generic}
              defaultValue={turnoNuevo?.fecha_turno}
            />
          ) : (
            <InputField
              item
              name="fecha_turno"
              size="small"
              label={""}
              variant="outlined"
              disabled={(editarTurno && turnoNuevo?.entrega === "Entregado") ? true : false}
              InputLabelProps={{ shrink: true, required: true }}
              type="date"
              onChange={(e) => {
                setDates(e.target.value);
                setValue("fecha_turno", e.target.value);
                clearErrors("fecha_turno");
              }}
              control={control}
              rules={rules.generic}
              defaultValue={turnoNuevo?.fecha_turno}
            />
          )}
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <Typography
            variant="subtitle1"
            className={classes.indicacion}
            style={{ margin: "1rem 0" }}
          >
            Hora:*
          </Typography>

          <InputField
            name="hora_salida"
            size="small"
            label={""}
            variant="outlined"
            disabled={(editarTurno && turnoNuevo?.entrega === "Entregado") && !reasignar}
            control={control}
            rules={rules.generic}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              step: 300, // 5 min
            }}
            type="time"
            defaultValue={turnoNuevo?.hora_salida}
          />
        </Grid>
      </Grid>

      {!reasignar && (
        <>
          {!newClient && (
            <Grid
              item
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                <Typography
                  variant="subtitle1"
                  className={classes.indicacion}
                  style={{ marginTop: "1rem" }}
                >
                  Cliente:*
                </Typography>

                <Controller
                  control={control}
                  name="cliente_nombre"
                  rules={rules.generic}
                  defaultValue={turnoNuevo?.cliente_id?.nombre}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      size="small"
                      onChange={(event, item) => {
                        onChange(item);
                        setCliente(item);
                      }}
                      value={value}
                      disabled={(editarTurno && turnoNuevo?.entrega === "Entregado") ? true : false}
                      options={clientes}
                      getOptionLabel={(item) =>
                        item.nombre ? item.nombre : ""
                      }
                      getOptionSelected={(option, value) =>
                        value === undefined ||
                        value === "" ||
                        option.nombre === value.nombre
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          variant="outlined"
                          placeholder={turnoNuevo?.cliente_id?.nombre || ""}
                          style={{ color: "black" }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          required
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                <Typography variant="subtitle1" className={classes.indicacion}>
                  DNI/CUIT:
                </Typography>
                <Typography variant="subtitle1" className={classes.indicacion2}>
                  {cliente
                    ? cliente.cuil_dni
                    : turnoNuevo?.cliente_id?.cuil_dni}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                className={classes.indicacion}
                style={{ margin: "1rem 0" }}
              >
                Ciudad:*
              </Typography>

              <InputField
                name="ciudad"
                label={""}
                variant="outlined"
                size="small"
                control={control}
                rules={rules.generic}
                defaultValue={turnoNuevo?.ciudad?.toUpperCase()}
              />
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                className={classes.indicacion}
                style={{ margin: "1rem 0" }}
              >
                Dirección Obra:*
              </Typography>

              <InputField
                name="direccion_obra"
                variant="outlined"
                label={""}
                size="small"
                control={control}
                rules={rules.generic}
                defaultValue={turnoNuevo?.direccion_obra?.toUpperCase()}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                className={classes.indicacion}
                style={{ marginTop: "1rem" }}
              >
                Tipo de Hormigón:*
              </Typography>

              <Controller
                control={control}
                name="tipo_formula"
                rules={rules.generic}
                defaultValue={turnoNuevo?.tipo_formula}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      onChange(item);
                      setFormula(item?.nombre);
                    }}
                    value={value}
                    options={formulas}
                    disabled={(editarTurno && turnoNuevo?.entrega === "Entregado") ? true : false}
                    getOptionLabel={(item) => (item?.nombre ? item.nombre : "")}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.nombre === value.nombre
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        placeholder={turnoNuevo?.tipo_formula || ""}
                        style={{ color: "black" }}
                        error={!!error}
                        helperText={error ? error.message : null}
                        required
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                className={classes.indicacion}
                style={{ margin: "1rem 0" }}
              >
                Cantidad:*
              </Typography>
              <Controller
                name="cantidad_volumen"
                control={control}
                defaultValue={turnoNuevo?.cantidad_volumen}
                rules={rules.generic}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        min: 0,
                      },
                    }}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                className={classes.indicacion}
                style={{ margin: "1rem 0" }}
              >
                Datos Adicionales:
              </Typography>
              <InputField
                name="datos_adicionales"
                label={""}
                variant="outlined"
                size="small"
                control={control}
                defaultValue={
                  turnoNuevo?.datos_adicionales?.toUpperCase() ?? ""
                }
              />
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                className={classes.indicacion}
                style={{ margin: "1rem 0" }}
              >
                Contacto:
              </Typography>
              <InputField
                name="contacto"
                label={""}
                variant="outlined"
                size="small"
                control={control}
                defaultValue={turnoNuevo?.contacto ?? ""}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CargarTurno;
