import { Box, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import FooterStyles from "./FooterStyles";
import Logo from "../../assets/img/LogoCognitisFooter.png";

const Footer = () => {
  const classes = FooterStyles();
  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid
        container
        item
        direction="row"
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justifyContent="space-between"
        className={classes.footerComponent}
        style={{
          textAlign: "center",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        <Grid
          container
          item
          direction="column"
          xl={10}
          lg={10}
          md={9}
          sm={6}
          xs={6}
          style={{ paddingLeft: "15vw" }}
        >
          <Typography
            className={`${classes.footerText} ${classes.copyrightText}`}
          >
            Copyright © 2022
          </Typography>
          <Typography
            className={`${classes.footerText} ${classes.softwareName}`}
          >
            Ayupí Hormigones
          </Typography>
        </Grid>

        <Grid
          container
          item
          justifyContent="flex-end"
          xl={2}
          lg={2}
          md={3}
          sm={6}
          xs={6}
          style={{ paddingRight: "1vw" }}
        >
          <Box
            component="img"
            sx={{
              height: 30,
            }}
            alt="Logo"
            src={Logo}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Footer;
