//REACT
import { React, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

//MATERIAL UI
import {
  Grid,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

//COMPONENTS
import RootContainer from "layout/rootContainer";
import VerificarAcceso from "components/verificarAcceso";

//VARIOS
import Theme from "theme";
import Protected from "protected";
import { LogIn } from "context/LogInContext";
import useStyles from "../../seleccionar-cliente/styles";

// ICONS
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { NotificationContext } from "context/notification/NotificationContext";
import { useGetAllClientes } from "hook/useGetAllClientes";
import LoadingText from "components/loadingText";

const ResumenClienteSeleccionado = () => {
  const history = useHistory();
  const classes = useStyles();
  const { logged } = useContext(LogIn);
  const { isNotificationsEnabled } = useContext(NotificationContext);

  // Estado para controlar filtro por nombre o dni/cuit
  const [filters, setFilters] = useState();

  //Estado para verifiar acceso al módulo
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // Query para obtener todos los clientes y/o filtrarlo por nombre o cuit/dni
  const { data, isLoading } = useGetAllClientes(filters);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="remitos"
            history={history}
            logged={logged}
          />
        ) : (
          <>
            {acceso && (
              <>
                <Grid
                  container
                  item
                  className={classes.generalContainer}
                  style={{
                    backgroundColor: "#ffffff",
                    marginLeft: "10rem",
                    marginRight: "10rem",
                    marginTop: "3rem",
                    boxShadow: "0px 5px 5px rgba(0, 0, 0, .2)",
                  }}
                >
                  {/* TITULO DE LA VISTA  */}
                  <Grid
                    container
                    alignContent="center"
                    justifyContent="center"
                    item
                    style={{
                      height: "3rem",
                      backgroundColor: Theme.palette.primary.main,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Typography variant="h5" style={{ color: "#ffffff" }}>
                      Seleccionar Cliente
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    style={{ margin: "1rem" }}
                  >
                    <Typography variant="subtitle2">
                      Para acceder a los resúmenes de un cliente, buscar por
                      Nombre o DNI/CUIT:
                    </Typography>
                  </Grid>
                  <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid
                      container
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: "1rem" }}
                      alignContent="center"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TextField
                        size="small"
                        id="outlined-full-width"
                        label="Nombre o DNI/CUIT"
                        variant="outlined"
                        value={filters}
                        type="search"
                        onChange={(e) => {
                          setFilters(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: !filters && (
                            <InputAdornment position="end">
                              <IconButton size="small" disabled={!filters}>
                                <SearchOutlinedIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.generalContainer}
                  >
                    {isLoading ? (
                      <LoadingText title="Cargando Clientes" />
                    ) : data?.length > 0 ? (
                      <TableContainer className={classes.tableContainer}>
                        <Table
                          stickyHeader
                          style={{ width: "90%", margin: "0 auto" }}
                          size="small"
                        >
                          <TableHead>
                            <TableRow style={{ height: "3rem" }}>
                              <TableCell
                                className={classes.tableCellHeaders}
                                width="20%"
                              >
                                CUIL/CUIT
                              </TableCell>
                              <TableCell
                                className={classes.tableCellHeaders}
                                width="25%"
                              >
                                Nombre
                              </TableCell>
                              <TableCell
                                className={classes.tableCellHeaders}
                                width="35%"
                              >
                                Ciudad
                              </TableCell>
                              <TableCell
                                className={classes.tableCellHeaders}
                                width="15%"
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Ver Resúmenes
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {data?.length > 0 ? (
                              data?.map((cliente) =>
                                cliente.notificacion ===
                                !isNotificationsEnabled ? (
                                  <TableRow
                                    hover
                                    key={cliente.id}
                                    style={{
                                      height: "3rem",
                                      backgroundColor:
                                        cliente.notificacion === true
                                          ? "#E26E6E"
                                          : "",
                                    }}
                                  >
                                    <TableCell>
                                      {cliente.cuil_dni
                                        ? cliente.cuil_dni
                                        : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {cliente.nombre
                                        ? cliente.nombre.toUpperCase()
                                        : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {cliente?.ciudad
                                        ? cliente.ciudad.toUpperCase()
                                        : "-"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      <IconButton
                                        onClick={() =>
                                          history.push(
                                            `/resumenes/resumen-por-cliente/${cliente.id}`,
                                            cliente,
                                          )
                                        }
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          marginLeft: "2rem",
                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                      >
                                        <VisibilityIcon
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  cliente.notificacion === false && (
                                    <TableRow
                                      key={cliente.id}
                                      style={{
                                        height: "3rem",
                                        backgroundColor:
                                          cliente.notificacion === true
                                            ? ""
                                            : "",
                                      }}
                                    >
                                      <TableCell>
                                        {cliente.cuil_dni
                                          ? cliente.cuil_dni
                                          : "-"}
                                      </TableCell>
                                      <TableCell>
                                        {cliente.nombre
                                          ? cliente.nombre.toUpperCase()
                                          : "-"}
                                      </TableCell>
                                      <TableCell>
                                        {cliente?.ciudad
                                          ? cliente.ciudad.toUpperCase()
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        <IconButton
                                          onClick={() =>
                                            history.push(
                                              `/resumenes/resumen-por-cliente/${cliente.id}`,
                                              cliente,
                                            )
                                          }
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "2rem",
                                            borderRadius: 5,
                                            backgroundColor:
                                              Theme.palette.primary.main,
                                            color: "#ffffff",
                                          }}
                                        >
                                          <VisibilityIcon
                                            style={{
                                              height: "1.2rem",
                                              width: "1.2rem",
                                            }}
                                          />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                ),
                              )
                            ) : (
                              <Grid item style={{ margin: "0.5rem" }}>
                                <Typography>
                                  No se encontraron clientes
                                </Typography>
                              </Grid>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Grid
                        container
                        justifyContent="center"
                        style={{ margin: "0.5rem" }}
                      >
                        <Typography variant="subtitle1">
                          No se encontraron resultados
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </RootContainer>
    </Protected>
  );
};

export default ResumenClienteSeleccionado;
