import axios from '../axios.config';

class NotificationService {
    /**
     * @param {Number} user_id cliente creado
     * @returns void null
     */
    notificationPost(user_id) {
        return axios.post('api/clientesb/crear-cliente-notificacion/', user_id);
    }

    /**
     * @returns void list notification users
     */
    notificationGet() {
        return axios.get('api/clientesb/listar-clientes-notificacion');
    }

    /**
     * @returns void list notification users
     */
     notificationDelete(user_id) {
        return axios.delete(`api/clientesb/eliminar-cliente-notificacion/${user_id}`);
    }


}

export default new NotificationService();