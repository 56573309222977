import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

export default function TableCustomizable({
  children,
  item,
  headers,
  orderBy,
  component,
  size,
  stickyHeader,
  styleTableContainer,
  styleTable,
  styleTableHead,
  styleTableRowHead,
  styleTableBody,
}) {
  return (
    <TableContainer
      component={component}
      style={styleTableContainer ? styleTableContainer(item) : {}}
    >
      <Table
        size={size}
        stickyHeader={stickyHeader}
        style={styleTable ? styleTable(item) : {}}
      >
        <TableHead style={styleTableHead ? styleTableHead(item) : {}}>
          <TableRow style={styleTableRowHead ? styleTableRowHead(item) : {}}>
            {headers?.map(
              (header, indexHeader) =>
                header && (
                  <TableCell key={indexHeader} style={header?.style}>
                    {header?.name}

                    {header?.orderBy && (
                      <IconButton
                        style={{ width: "0.2rem", height: "0.2rem" }}
                        onClick={() => orderBy(header?.orderBy)}
                      >
                        <CompareArrowsIcon
                          style={{ width: "1rem", transform: "rotate(90deg)" }}
                        />
                      </IconButton>
                    )}
                  </TableCell>
                ),
            )}
          </TableRow>
        </TableHead>

        <TableBody style={styleTableBody ? styleTableBody(item) : {}}>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
