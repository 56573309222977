import { IconButton } from "@material-ui/core";
import Theme from "theme";

export default function IconButtonAction({ children, onClick }) {
  return (
    <IconButton
      size="small"
      style={{
        width: "1.5rem",
        height: "1.5rem",
        borderRadius: 5,
        backgroundColor: Theme.palette.primary.main,
        color: "#ffffff",
      }}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
}
