// Hook para traer Todos los proveedores transportistas sin paginar
import { useQuery } from "react-query";
// Services
import ProveedoresService from "services/proveedores.service";

export const useGetTransportistas = (filters) => {
  // FUNCION PARA TRAER TODOS LOS PROVEEDORES TRANSPORTISTAS
  const obtenerTransportistas = async () => {
    const { data } = await ProveedoresService.getTransportistas();
    return data;
  };

  // FUNCION PARA FILTRAR LOS PROVEEDORES POR NOMBRE Y CUIT/DNI
  const filtrarTransportistaPorNombre = (transportistas) => {
    if (filters) {
      return transportistas.filter((unTransp) =>
        unTransp.nombre.toLowerCase().includes(filters.toLowerCase()),
      );
    } else {
      return transportistas;
    }
  };

  return useQuery(["transportistas"], () => obtenerTransportistas(), {
    select: (transportistas) => filtrarTransportistaPorNombre(transportistas),
  });
};
