import moment from "moment";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const defaultState = {
  fecha_desde: moment().format("YYYY-MM-01"),
  fecha_hasta: moment().format("YYYY-MM-DD"),
  todo: "",
  estado: "",
  condicion: "",
  dias: "",
  textSelected: "",
  search: "",
  tipo: "",
  sort: "",
  ordcondi: "",
  ordfecha: "",
  ordnumfac: "",
  ordmonto: "",
  page: 1,
  order_by: true,
  ref: "",
};

export const useFacturasStore = create(
  devtools((set) => ({
    ...defaultState,
    actions: {
      setFechaDesde: (value) =>
        set((state) => ({ fecha_desde: value, page: 1 })),
      setFechaHasta: (value) =>
        set((state) => ({ fecha_hasta: value, page: 1 })),
      setTodo: (value) =>
        set((state) => ({
          todo: value,
          estado: "",
          condicion: "",
          dias: "",
          formula: "",
          page: 1,
        })),
      setCondicion: (value) =>
        set((state) => ({
          condicion: value,
          estado: "",
          dias: "",
          formula: "",
          todo: "",
          page: 1,
        })),
      setEstado: (value) =>
        set((state) => ({
          estado: value,
          condicion: "",
          dias: "",
          page: 1,
          todo: "",
        })),
      setDias: (value) =>
        set((state) => ({
          dias: value,
          estado: "",
          condicion: "",
          page: 1,
        })),
      setSearch: (value) => set(() => ({ search: value, page: 1 })),
      setRef: (value) => set(() => ({ ref: value })),
      setTipo: (value) =>
        set((state) => ({
          tipo: value,
          sort: "",
          page: 1,
        })),
      setSort: (value) =>
        set((state) => ({
          sort: value,
        })),
      setOrdFecha: (value) =>
        set(() => ({
          ordfecha: value,
          ordcondi: "",
          ordmonto: "",
          ordnumfac: "",
        })),
      setOrdNumFac: (value) =>
        set(() => ({
          ordfecha: "",
          ordcondi: "",
          ordmonto: "",
          ordnumfac: value,
        })),
      setOrdMonto: (value) =>
        set(() => ({
          ordfecha: "",
          ordcondi: "",
          ordmonto: value,
          ordnumfac: "",
        })),
      setOrdCondi: (value) =>
        set(() => ({
          ordfecha: "",
          ordcondi: value,
          ordmonto: "",
          ordnumfac: "",
        })),
      setSelectTexto: (value) => set(() => ({ textSelected: value, page: 1 })),
      increasePage: () => set((state) => ({ page: state.page + 1 })),
      decreasePage: () => set((state) => ({ page: state.page - 1 })),
      setPage: (value) => set((state) => ({ page: value })),
      toggleSort: (value) => set((state) => ({ order_by: !state.order_by })),
      resetPage: (value) => set(() => ({ page: 1 })),
      resetState: () => set((state) => defaultState),
    },
  })),
);

export const useTextSelected = () =>
  useFacturasStore((state) => state.textSelected);
export const useSearchNotesOrClients = () =>
  useFacturasStore((state) => state.search);
export const useNoteTipeSelected = () =>
  useFacturasStore((state) => state.textSelected);
export const useOrderBy = () => useFacturasStore((state) => state.order_by);
export const useRef = () => useFacturasStore((state) => state.ref);
export const useFacturaActions = () =>
  useFacturasStore((state) => state.actions);
