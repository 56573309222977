import { useQuery } from "react-query";
import facturacionServices from "services/facturacion.services";
import fileDownload from "js-file-download";

// FUNCION PARA TRAER EXCEL DE NOTAS SEGUN FILTROS
const getExcel = async ({
    filtro,
    debouncedValue,
}) => {
    try {
        // make params to send api
        const params = {
            ...filtro,
            search: debouncedValue,
        };
        //check endpoint to make request
        const endpoint = `descargarExcelNotas`;
        const res = await facturacionServices[endpoint](params);
        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        return error;
    }
};

export function useDownloadNotes({
    filtro,
    debouncedValue
}) {
    return useQuery(
        ["descargarExcel", filtro, debouncedValue],
        () => getExcel({
            filtro,
            debouncedValue,
        }),
        {
            onSuccess: (data) => {
                fileDownload(data, `notas_cred_deb.xlsx`);
            },
            refetchOnWindowFocus: false,
            enabled: false // (!) handle refetchs manually
        }
    );
}