import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@material-ui/core";


const CheckboxCustom = ({
  name,
  label,
  labelPlacement,
  color,
  control,
  defaultChecked,
  checked,
  disabled,
  ...props
}) => {
  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultChecked}
          checked={checked}
          render={({ field }) => (
            <Checkbox {...field} checked={checked} defaultChecked={defaultChecked} disabled={disabled} color={color} />
          )}
        />
      }
    />
  );
};

CheckboxCustom.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.objectOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.objectOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.bool.isRequired,
  color: PropTypes.string,
  value: PropTypes.string,
};
CheckboxCustom.defaultProps = {
  value: "",
  color: "red",
};
export default CheckboxCustom;
