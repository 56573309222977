import dayjs from "dayjs";
import { namesCtaCte } from "./const";

const useStyles = () => ({
  tableGeneric: {
    table: () => ({}),
    tableContainer: () => ({ maxHeight: "35vh", overflow: "auto" }),
    tableHead: () => ({ zIndex: 3 }),
    tableBody: () => ({}),
    tableRowHead: () => ({}),
    tableRowBody: (item) => ({
      "--backgroundColor": item?.[namesCtaCte?.presupuesto]
        ? "#E26E6E"
        : (dayjs(item?.[namesCtaCte?.fechaVto]) < dayjs().subtract(30, "days") && item?.[namesCtaCte?.condicion] !== "Pagado") &&
          "#FBEF94",
    }),
  },
  tableCollapse: {
    table: () => ({}),
    tableContainer: () => ({ width: "100%" }),
    tableHead: () => ({}),
    tableBody: () => ({}),
    tableRowHead: (item) => ({
      "--backgroundColor": item?.[namesCtaCte?.presupuesto]
        ? "#E26E6E"
        : dayjs(item?.[namesCtaCte?.fechaVto]) < dayjs().subtract(30, "days") &&
          "#FBEF94",
    }),
    tableRowBody: () => ({}),
  },
  typography: {
    fontWeight: "bold",
    marginRight: "0.5rem",
  },
  total: {
    padding: "0.2rem 0.75rem",
    marginBottom: "0.2rem",
    borderRadius: "0.4rem",
  },
  puntoAmarillo: {
    width: "20px",
    height: "20px",
    borderRadius: "100%",
    backgroundColor: "#FBEF94",
    marginRight: "0.5rem",
  },
});

export default useStyles;
