import { Button, CircularProgress, Grid } from "@material-ui/core";

const ButtonLoading = ({
  isLoading,
  sizeLoading,
  onClick,
  color,
  children,
  disabled,
  ...props
}) => {
  return (
    <Button
      size="small"
      variant="contained"
      disabled={disabled ? disabled : false}
      color={color ? color : "primary"}
      onClick={!isLoading ? onClick : null}
      {...props}
    >
      {isLoading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ width: "7vw" }}
        >
          <CircularProgress
            size={sizeLoading ? sizeLoading : "1.5rem"}
            style={{ color: "white" }}
          />
        </Grid>
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonLoading;
