import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { ReportProblemOutlined, InfoOutlined } from "@material-ui/icons";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Theme from "theme";

export default function ConfirmDialog({ confirmDialog, setConfirmDialog }) {
  const handleClose = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  return (
    <Dialog onClose={handleClose} open={confirmDialog.isOpen}>
      <Grid style={{ padding: "0.5rem" }}>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {confirmDialog?.severity === "error" ? (
              <ReportProblemOutlined
                style={{
                  width: "4rem",
                  height: "4rem",
                  color: Theme.palette.primary.main,
                }}
              />
            ) :             
            confirmDialog?.severity === "warning" ? (
              <HelpOutlineOutlinedIcon
                style={{
                  width: "4rem",
                  height: "4rem",
                  color: Theme.palette.primary.main,
                }}
              />
            ) : 
            (
              <InfoOutlined
                style={{
                  width: "4rem",
                  height: "4rem",
                  color: Theme.palette.primary.main,
                }}
              />
            )}

            <Typography
              style={{ color: "#555555", fontSize: "20px", fontWeight: 700 }}
              align="center"
            >
              {confirmDialog?.severity === "error" ? "Error" :  confirmDialog?.severity === "warning" ? "¿Continuar?" :"¿Está Seguro?"}
            </Typography>
          </Grid>
          <Typography style={{ color: "#555555" }} align="center">
            {confirmDialog.title}
          </Typography>
        </DialogContent>
        <DialogActions>
          {confirmDialog?.severity !== "error" && (
            <Button
              onClick={() =>
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }
              color="default"
            >
              CANCELAR
            </Button>
          )}
          <Button
            onClick={() => {
              if (confirmDialog.onConfirm) confirmDialog.onConfirm();
              setConfirmDialog({ ...confirmDialog, isOpen: false });
            }}
            variant="contained"
            style={{
              color: "#ffffff",
              backgroundColor: Theme.palette.primary.main,
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  confirmDialog: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    severity: PropTypes.string,
  }).isRequired,
  setConfirmDialog: PropTypes.func.isRequired,
};
