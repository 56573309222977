// MUI && REACT && LIBRARIES
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { LogIn } from "context/LogInContext";
import Theme from 'theme';

// HOOK
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from 'react-hook-form';
import { useGetDetailsProviderById } from '../hook';

// COMPONENTS
import RootContainer from 'layout/rootContainer';
import Protected from 'protected';
import Card from 'components/card';
import LoadingText from 'components/loadingText';
import InputField from 'components/Layout/inputCustom/inputField';
import rules from 'constants/rules';

// STYLES
import useStyles from "../crear-editar-proveedores/CrearEditarProveedoresStyles";

// SERVICES
import usuarioService from 'services/usuario.service';

const DetalleProveedor = () => {
    const classes = useStyles();
    const history = useHistory();

    // DESESTRUCTURING ID PROVIDERS FROM STATE
    const { idProvider } =  history.location?.state; 

    // DESESTRUCTURING LOGIN STATE FROM USER CONTEXT
    const { logged } = useContext(LogIn);

    // ESTADOS PARA VALIDAR PERMISO A MODULO
    const [verificando, setVerificando] = useState(true);
    const [acceso, setAcceso] = useState(false);

    // QUERY PARA OBTENER DETALLE DE PROVEEDOR
    const { data: dataProvider, isLoading: loadingDataProvider,
    } = useGetDetailsProviderById({idProvider});

    // REACT HOOK FORM LOAD DEFAULTS VALUES FROM QUERY PROVIDER
    const { control, reset } = useForm({
        defaultValues:
        {
            cuil_cuit: dataProvider?.cuil_cuit ?? "Sin Datos",
            razon_social: dataProvider?.razon_social ?? "Sin Datos",
            tipo: dataProvider?.tipo ?? "Sin Datos",
            sub_tipo: dataProvider?.sub_tipo ?? "Sin Datos",
            cond_iva: dataProvider?.cond_iva ?? "Sin Datos",
            telefono: dataProvider?.telefono ?? "Sin Datos",
            email: dataProvider?.email ?? "Sin Datos",
            direccion: dataProvider?.direccion ?? "Sin Datos",
            provincia_nombre: dataProvider?.provincia_nombre ?? "Sin Datos",
            provincia: dataProvider?.provincia ?? "Sin Datos",
            departamento_nombre: dataProvider?.departamento_nombre ?? "Sin Datos",
            departamento: dataProvider?.departamento ?? "Sin Datos",
            localidad_nombre: dataProvider?.localidad_nombre ?? "Sin Datos",
            localidad: dataProvider?.localidad ?? "Sin Datos"
        }
    });

    // FUNCION VERIFICAR ACCESOS A MODULO  
    /**
     * ! Llevarlo a un hook
     */

    const verificarAcceso = async () => {
        setAcceso(true);
        try {
            const res = await usuarioService.checkAccess({ modulo: "clientes" });
            if (res.status === 200) {
                setVerificando(false);
                setAcceso(true);
            } else {
                setAcceso(false);
                setVerificando(false);
                // history.push("/error-permiso", { modulo: "clientes" });
            }
        } catch (error) {
            setAcceso(true);
            setVerificando(false);
            // history.push("/error-permiso", { modulo: "clientes" });
        }
    };

    // COMPONENT VIEW FIELD DETAIL
    function ViewFieldDisabled(props) {
        return (
            <>
                <InputField
                    name={props.name}
                    label={props.label}
                    disabled
                    InputLabelProps={{ classes: { root: classes.inputLabel } }}
                    InputProps={{ classes: { root: classes.disabledInput } }}
                    control={control}
                    type="text"
                    rules={rules.genericNoRequered}
                />
            </>);
    }

    // VERIFICACIÓN DE PERMISOS
    useEffect(() => {
        if (logged) {
            verificarAcceso();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // EFFECT RUNS WHEN PROVIDERS DATA IS UPDATED
    useEffect(() => {
        // reset form with provider data
        reset(dataProvider);
    }, [dataProvider]);

    return (
        <Protected>
            <RootContainer>
                {verificando ? (
                    <Grid container direction="row" justifyContent="center" alignContent="center" alignItems="center" style={{ marginTop: "15rem"}}>
                        <CircularProgress style={{ color: Theme.palette.primary.main, marginRight: "1rem" }} />
                        <Typography style={{ color: "#000000" }}>
                            Verificando permisos
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        {acceso && (
                            <>
                                <Grid item container className={classes?.container}>
                                    <Card title="Detalle Proveedor" justifyContent="center">
                                        {loadingDataProvider ? (
                                            <LoadingText title="Cargando Detalle de Proveedor" />
                                        ) : (
                                            <>
                                                <Grid item container className={classes?.cardContainerScrollable}>
                                                    <Grid item container spacing={1} style={{ margin: "2rem 1rem 1rem 1rem" }}>
                                                        <Grid item xs={12} md={6} lg={2} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="cuil_cuit" label="CUIL/CUIT" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={4} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="razon_social" label="Razón Social" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={3} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="cond_iva" label="Condición IVA" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={3} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="telefono" label="N° de Teléfono" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={3} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="tipo" label="Proveedor" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={3} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="sub_tipo" label="Tipo Proveedor" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="email" label="Email" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={3} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="direccion" label="Dirección" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={3} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="provincia_nombre" label="Provincia" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={3} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="departamento_nombre" label="Departamento" />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={3} style={{ marginBottom: "1.5rem" }}>
                                                            <ViewFieldDisabled name="localidad_nombre" label="Localidad" />
                                                        </Grid>
                                                        <Grid item container spacing={1} direction="row" justifyContent="flex-end">
                                                            <Grid item>
                                                                <Button
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={() => history.goBack()}
                                                                >
                                                                    Volver
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="small"
                                                                    onClick={() => history.push("/proveedores/crear-editar", { idProvider })}
                                                                >
                                                                    Editar
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </Card>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </RootContainer>
        </Protected>
    )
}

export default DetalleProveedor