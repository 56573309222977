import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

export default function NewSelect({
  name,
  label,
  defaultValue,
  rules,
  control,
  onChange,
  options,
  option,
  optionValue,
  style,
  disabled,
  focused,
  ...props
}) {
  const labelId = `${name}-label`;

  const onChangeFunction = (e, value) => {
    if (onChange) {
      onChange(e, value);
    }
  };

  const findOption = (value) => {
    if (value) {
      const found = options?.find(
        (item) =>
          item?.[optionValue] === value ||
          item?.value === value ||
          item === value,
      );

      return found?.[optionValue] ?? found?.value ?? found;
    }
  };

  return (
    <FormControl
      focused={focused}
      fullWidth
      style={style}
      variant="outlined"
      size="small"
      disabled={disabled}
    >
      <InputLabel id={labelId} htmlFor={labelId}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              size="small"
              name={name}
              id={name}
              labelId={labelId}
              label={label}
              error={!!error}
              value={findOption(value) ?? ""}
              onChange={(e, value) => {
                onChangeFunction(e, value?.props?.value);
                onChange(e?.target?.value);
              }}
              disabled={disabled}
              {...props}
            >
              {options?.length > 0 ? (
                options?.map((item, index) =>
                  typeof item === "object" ? (
                    <MenuItem
                      value={item?.[optionValue] ?? item?.value}
                      key={index}
                      {...item}
                    >
                      {item?.[option] ?? item?.name}
                    </MenuItem>
                  ) : (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ),
                )
              ) : (
                <MenuItem disabled>No se encontraron resultados</MenuItem>
              )}
            </Select>
            <FormHelperText error>
              {error ? error.message : null}
            </FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
}
