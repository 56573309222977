import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { toContable } from "constants/funciones/toContable";
import ImprimirInformeCuentaCorriente from "./imprimirInformeCuentaCorriente";


export default function HeaderEstadoCuenta({ data, isLoading, cliente, dataOrdenes }) {


  return (
    <>
      {/* Datos Cliente Botones Imprimir y Volver */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        item
        style={{ marginBottom: "1rem" }}
      >
        <Grid item xs={12}>
          <Grid>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "16px",
                color: "rgba(67, 59, 59, 0.87)",
              }}
            >
              Cliente:{" "}
              <span
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "16px",
                  color: "rgba(67, 59, 59, 0.87)",
                  border: "none",
                }}
              >
                {cliente?.nombre ?? "--"}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Estado de Cuenta */}
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="body1">Estado de Cuenta</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-around"
        style={{
          backgroundColor: "white",
          borderRadius: 10,
          padding: "1rem",
          margin: "1rem 5rem 1rem 5rem",
        }}
      >
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid container xs={2}
            direction="column"
            alignContent="center"
            alignItems="center">
            <Grid item>
              <Typography variant="caption" style={{ whiteSpace: "nowrap" }}>Factura Pendientes</Typography>
            </Grid>
            <Grid item>
              {isLoading ?
                <CircularProgress size={25} />
                :
                <Typography
                  variant="h5"
                  color="error"
                  style={{ fontSize: "x-large", fontWeight: 700 }}
                >
                  {data?.cant_facturas_impagas ?? "-"}
                </Typography>
              }
            </Grid>
          </Grid>
          <Grid
            container xs={2}
            direction="column"
            alignContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="caption" style={{ whiteSpace: "nowrap" }}>Total por pagar</Typography>
            </Grid>
            <Grid item>
              {isLoading ?
                <CircularProgress size={25} />
                :
                <Typography
                  variant="h5"
                  color="error"
                  style={{ fontSize: "x-large", fontWeight: 700 }}
                >
                  {toContable(data?.total_debe) ?? "-"}
                </Typography>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid hidden>
        <ImprimirInformeCuentaCorriente
          //componentRef={componentRef}
          data={data}
          dataOrdenes={dataOrdenes}
          cliente={cliente}
          totalDebe={data?.total_debe}
          filtro={"filtro"}
        />
      </Grid>
    </>
  );
}
