import { Button, Grid, Typography } from "@material-ui/core";
import ModalGeneric from "components/modalGeneric";
import TableCustomizable from "components/tableCustomizable";
import { toContable } from "constants/funciones/toContable";
import useDayjs from "hook/useDayjs";
import TableRowDetallePago from "./tableRowDetallePago";

export default function DetallePago({ open, handleClose, orden }) {
  const headerStyle = {
    paddingTop: "0.1rem",
    paddingBottom: "0.1rem",
    border: 0,
  };

  const dayjs = useDayjs();

  const totalOrden = () => {
    const totalOrden = orden?.detalle_orden?.reduce(
      (acumulador, itemActual) =>
        acumulador + itemActual?.precio_actual * itemActual?.cantidad_m3,
      0,
    );

    return Number(totalOrden?.toFixed(2));
  };

  const totalPagos = () => {
    const totalPagos = orden?.formas_pago?.reduce(
      (acumulador, itemActual) => acumulador + itemActual?.monto,
      0,
    );

    return Number(totalPagos?.toFixed(2));
  };

  const saldo = () => {
    const saldo = totalOrden() ?? 0 - totalPagos() ?? 0;

    return Number(saldo?.toFixed(2));
  };

  return (
    <ModalGeneric
      title="Detalle de Pago"
      open={open}
      handleClose={handleClose}
      lg={8}
      md={10}
      sm={10}
      xs={11}
    >
      <Grid
        container
        justifyContent="space-between"
        style={{
          backgroundColor: orden?.presupuesto ? "#E26E6E" : "#CCCFE4",
          marginTop: "0.5rem",
          padding: "0 1rem",
        }}
      >
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Nº Orden:
          <Typography
            component="span"
            variant="body1"
            style={{ paddingLeft: "0.5rem" }}
          >
            {orden?.num_orden}
          </Typography>
        </Typography>

        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Fecha Emisión:
          <Typography
            component="span"
            variant="body1"
            style={{ paddingLeft: "0.5rem" }}
          >
            {dayjs(orden?.fecha_carga, "DD-MM-YYYY").format("DD/MM/YYYY")}
          </Typography>
        </Typography>

        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Monto:
          <Typography
            component="span"
            variant="body1"
            style={{ paddingLeft: "0.5rem" }}
          >
            {toContable(totalOrden())}
          </Typography>
        </Typography>

        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Saldo:
          <Typography
            component="span"
            variant="body1"
            style={{ paddingLeft: "0.5rem" }}
          >
            {toContable(saldo())}
          </Typography>
        </Typography>
      </Grid>

      <TableCustomizable
        size="small"
        headers={[
          { name: "Fecha de Pago", style: headerStyle },
          { name: "Monto", style: headerStyle },
          { name: "Forma de Pago", style: headerStyle },
          { name: "Nº Cheque/E-Cheq", style: headerStyle },
          { name: "Fecha Vto.", style: headerStyle },
          { name: "Observaciones", style: headerStyle },
        ]}
        styleTableContainer={() => ({ marginTop: "0.5rem" })}
      >
        {orden?.formas_pago?.map((item) => (
          <TableRowDetallePago key={item?.id} item={item} />
        ))}
      </TableCustomizable>

      <Grid
        container
        justifyContent="flex-end"
        style={{ margin: "0.5rem 1rem" }}
      >
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleClose}
          style={{ textTransform: "none" }}
        >
          Volver
        </Button>
      </Grid>
    </ModalGeneric>
  );
}
