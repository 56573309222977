import moment from "moment";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const defaultState = {
  fecha_desde: moment().format("YYYY-MM-01"),
  fecha_hasta: moment().format("YYYY-MM-DD"),
  todo: "",
  estado: "",
  condicion: "",
  excedente: false,
  dias: "",
  formula: "",
  obra: "",
  ordremi: "",
  ordfecha: "",
  ordmts: "",
  ordobra: "",
  textSelected: "",
  search: "",
  page: 1,
  order_by: true,
  remCheck: [],
  ref: "",
  // check Seleccionar Todos Remitos en un Rango para generar Resumen
  checkTodosLosRemitos: false,
  // Arreglo de Ids de Remitos a Excluir
  remitosIdsAExcluir: []
};

export const useRemitoStore = create(
  devtools((set) => ({
    ...defaultState,
    // actions
    actions: {
      setFechaDesde: (value) => set((state) => ({ fecha_desde: value, page: 1 })),
      setFechaHasta: (value) => set((state) => ({ fecha_hasta: value, page: 1 })),
      setTodo: (value) =>
        set((state) => ({
          todo: value,
          estado: "",
          condicion: "",
          dias: "",
          formula: "",
          excedente: false,
        })),
      setCondicion: (value) =>
        set((state) => ({
          condicion: value,
          estado: "",
          dias: "",
          formula: "",
          todo: "",
          excedente: false,
        })),
      setCheckTodosLosRemitos: (value) => set((state) => ({ checkTodosLosRemitos: value })),
      setObras: (value) => set((state) => ({ obra: value })),
      setEstado: (value) =>
        set((state) => ({
          estado: value,
          condicion: "",
          dias: "",
          formula: "",
          todo: "",
          excedente: false,
        })),
      setDias: (value) =>
        set((state) => ({
          dias: value,
          estado: "",
          condicion: "",
          formula: "",
          todo: "",
          excedente: false,
        })),
      setExcedente: (value) =>
      set((state) => ({
        dias: "",
        estado: "",
        condicion: "",
        formula: "",
        todo: "",
        excedente: true,
      })),
      setFormula: (value) =>
        set((state) => ({
          formula: value,
          estado: "",
          condicion: "",
          dias: "",
          todo: "",
          excedente: false,
        })),
      setRemCheck: (value) => set(() => ({ remCheck: value })),
      clearRemCheck: (value) => set(() => ({ remCheck: [] })),
      addRemCheck: (value) =>
        set((state) => ({ remCheck: [...state.remCheck, value] })),
      setRemitosIdsAExcluir: (value) => set(() => ({ remitosIdsAExcluir: value })),
      clearRemitosIdsAExcluir: (value) => set(() => ({ remitosIdsAExcluir: [] })),
      setSelectTexto: (value) => set(() => ({ textSelected: value })),
      setSearch: (value) => set(() => ({ search: value })),
      increasePage: () => set((state) => ({ page: state.page + 1 })),
      decreasePage: () => set((state) => ({ page: state.page - 1 })),
      setPage: (value) => set((state) => ({ page: value })),
      resetPage: (value) => set(() => ({ page: 1 })),
      setOrdFecha: (value) =>
        set(() => ({
          ordfecha: value,
          ordremi: "",
          ordmts: "",
          ordobra: "",
        })),
      setOrdRemi: (value) =>
        set(() => ({
          ordremi: value,
          ordfecha: "",
          ordmts: "",
          ordobra: "",
        })),
      setOrdmts: (value) =>
        set(() => ({
          ordmts: value,
          ordfecha: "",
          ordremi: "",
          ordobra: "",
        })),
      setOrdObras: (value) =>
        set(() => ({
          ordobra: value,
          ordmts: "",
          ordfecha: "",
          ordremi: "",
        })),
      setRef: (value) =>
        set(() => ({
          ref: value,
        })),
      toggleSort: (value) => set((state) => ({ order_by: !state.order_by })),
      reset: () => set((state) => defaultState),
    },
  })),
);

// unique selector for each state
// export const useEstado = () => useRemitoStore((state) => state.estado);

// export const useCondicion = () => useRemitoStore((state) => state.condicion);
// export const useFormula = () => useRemitoStore((state) => state.formula);
// export const useDias = () => useRemitoStore((state) => state.dias);
export const useObra = () => useRemitoStore((state) => state.obra);
export const useOrderBy = () => useRemitoStore((state) => state.order_by);
export const useRef = () => useRemitoStore((state) => state.ref);

export const useTextSelected = () =>
  useRemitoStore((state) => state.textSelected);

//  ▶ one selector for all our actions
export const useRemitoActions = () => useRemitoStore((state) => state.actions);
