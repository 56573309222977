import React from "react";
import Button from "@material-ui/core/Button";
import { Grid, Modal, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import Theme from "theme";
import Card from "components/card";
import { useHistory } from "react-router-dom";

const ErrorOrdenes = ({ open, close }) => {
  const history = useHistory();

  const handleClose = () => {
    close(false);
  };
  return (
    <Modal onClose={handleClose} open={open}>
      <Grid
        container
        xl={5}
        lg={5}
        md={6}
        sm={8}
        xs={10}
        style={{
          backgroundColor: "#ffffff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
        }}
      >
        <Card title="ERROR" justifyContent="center">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "1rem" }}
          >
            <InfoOutlined
              style={{
                width: "4rem",
                height: "4rem",
                color: Theme.palette.primary.main,
              }}
            />

            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              No existen ordenes de
            </Typography>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              compra para este
            </Typography>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              cliente
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "1rem" }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                children="Crear Orden"
                style={{
                  textTransform: "none",
                  marginRight: "0.5rem",
                }}
                onClick={() => history.push(`/ordenes/crear-orden/`)}
              />
              <Button
                variant="contained"
                size="small"
                color="primary"
                children="Aceptar"
                style={{
                  textTransform: "none",
                }}
                onClick={() => handleClose()}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Modal>
  );
};

export default ErrorOrdenes;
