import { React } from "react";
import { IconButton } from "@material-ui/core";
import StyledTableContainer from "components/styled/tableContainer";
import StyledTable from "components/styled/table";
import StyledTableHead from "components/styled/tableHead";
import StyledTableBody from "components/styled/tableBody";
import StyledTableCell from "components/styled/tableCell";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import StyledTableRow from "components/styled/tableRow";

export default function TableGeneric({
  children,
  item,
  headers,
  orderBy,
  component,
  size,
  stickyHeader,
  styles,
}) {
  return (
    <StyledTableContainer
      component={component}
      style={styles?.tableContainer(item)}
    >
      <StyledTable
        size={size}
        stickyHeader
        style={styles?.table(item)}
      >
        <StyledTableHead style={styles?.tableHead(item)}>
          <StyledTableRow style={styles?.tableRowHead(item)}>
            {headers?.map(
              (header, indexHeader) =>
                header && (
                  <StyledTableCell key={indexHeader} style={header?.style}>
                    {header?.name}

                    {header?.orderBy && (
                      <IconButton
                        style={{ width: "0.2rem", height: "0.2rem" }}
                        onClick={() => orderBy(header?.orderBy)}
                      >
                        <CompareArrowsIcon
                          style={{ width: "1rem", transform: "rotate(90deg)" }}
                        />
                      </IconButton>
                    )}
                  </StyledTableCell>
                ),
            )}
          </StyledTableRow>
        </StyledTableHead>

        <StyledTableBody style={styles?.tableBody(item)}>
          {children}
        </StyledTableBody>
      </StyledTable>
    </StyledTableContainer>
  );
}
