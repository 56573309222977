import React from "react";
import ReCaptcha from "@matt-block/react-recaptcha-v2";

const MyReCaptchaComponent = ({ funtionSuccess, funtionExpire, funtionError }) => {

    let REACT_RECAPTCHA = process.env.REACT_APP_RECAPTCHA ?? ''

    return (
        <ReCaptcha
            siteKey={`${REACT_RECAPTCHA}`}
            theme="light"
            size="normal"
            onSuccess={(captcha) => funtionSuccess()}
            onExpire={() => funtionExpire()}
            onError={() => funtionError()}
        />
    );

}

export default MyReCaptchaComponent