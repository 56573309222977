import React, { useState } from "react";
import { Typography, Modal, Grid, Button } from "@material-ui/core";
import Theme from "theme";

import CustomSnackbar from "components/Layout/CustomSnackbars";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    paper: {
        width: "auto",
        height: "auto",
        overflow: "auto",
    },
}));

const ModalCancelacion = ({
    open,
    handleClose,
    turno,
    moment,
    eliminarTurno
}) => {
    const classes = useStyles();
    const [snackState, setSnackState] = useState({
        message: "no message",
        open: false,
    });

    const onClose = () => {
        setSnackState({ ...snackState, open: false });
    };

    return (
        <>
            <Modal open={open} className={classes.paper}>

                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="flex-end"
                    spacing={2}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={6}
                    style={{
                        backgroundColor: "#ffffff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: 10,
                        minHeight: "auto",
                        overflow: "auto",

                    }}
                >
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            backgroundColor: Theme.palette.primary.main,
                            height: "3rem",
                            borderTopRightRadius: 10,
                            borderTopLeftRadius: 10
                        }}
                    >
                        <Typography item variant="h5" style={{ color: "#ffffff" }}>
                            Cancelar Turno
                        </Typography>
                    </Grid>
                        <Grid
                            item
                            xl={8}
                            lg={8}
                            md={8}
                            sm={12}
                            xs={12}
                            style={{ margin: "1rem 0.5rem" }}
                            direction="row"
                            justifyContent="center"
                        >
                            <Typography
                                variant="h5"
                                align="center"
                                style={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                }}
                            >   
                                ¿Esta seguro de cancelar el turno de <span style={{color: "#db0202"}}>{turno?.cliente_id?.nombre}</span> de las <span style={{color: "#db0202"}}>{moment(turno?.hora_salida, "HH:mm:ss").format("HH:mm")}</span> del día <span style={{color: "#db0202"}}>{moment(turno?.fecha_turno).format("DD/MM/YYYY")}</span>?
                            </Typography>
                        </Grid>
                    


                    <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            item
                            variant="contained"
                            color="default"
                            style={{
                                fontSize: "14px",
                                margin: "0.5rem"
                            }}
                            onClick={() => handleClose()}
                        >
                            VOLVER
                        </Button>
                            <Button
                                item
                                variant="contained"
                                style={{
                                    color: "white",
                                    backgroundColor: "#001176",
                                    fontSize: "14px",
                                    margin: "0.5rem"

                                }}
                                onClick={() => {
                                    eliminarTurno(turno);
                                    handleClose();
                                }}
                            >
                                CONFIRMAR
                            </Button>
                           

                    </Grid>
                </Grid>

            </Modal>
            <CustomSnackbar
                message={snackState.message}
                open={snackState.open}
                severity={snackState.severity}
                onClose={onClose}
            />
        </>
    );
};

export default ModalCancelacion;
