export const names = {
  formaPago: "forma_pago",
  nro: "nro_cheque",
  monto: "monto",
  fechaPago: "fecha_pago",
  fechaVto: "fecha_vencimiento",
  observacion: "observacion",
  facturaNro: "num_factura",
  facturaFechaEmision: "fecha_emision",
  facturaOrden: "orden_compra",
  facturaMts: "cantidad_mc",
  facturaMonto: "total",
  facturaSaldo: "saldo",
  credito: "credito",
};
