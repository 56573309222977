/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import { useCallback, useRef, useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// MATERIAL UI
import {
  Button,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";

// ICONS
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";

// COMPONENTS
import Card from "components/card";
import VerificarAcceso from "components/verificarAcceso";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import HistoricoPrecios from "./historicoPrecios";
import mostrarErrorEP from "constants/funciones/mostrarError";
import Imprimir from "./components/imprimir";

//SERVICES
import ordenesService from "services/ordenes.service";

// VARIOS
import { LogIn } from "context/LogInContext";
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import Theme from "theme";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useToggle } from "hook/useToogle";
import DetallePago from "./components/detallePago";

const VerOrdenNegro = () => {
  // HOOK HISTORY
  const history = useHistory();

  // HOOK PARAMS
  const { id } = useParams();

  // HOOK CONTEXT
  const { logged } = useContext(LogIn);

  // HOOKS REF
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  // ESTADOS
  // ==========================================================================
  // Para verificar acceso de un usuario al modulo
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // Para CustomSnackbar
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // Para modal HistoricoPrecios
  const [modalHistoricoPrecios, setModalHistoricoPrecios] = useState(false);
  const [ordenSeleccionada, setOrdenSeleccionada] = useState(null);
  const [openDetallePago, toogleOpenDetallePago] = useToggle();
  // Para capturar la orden
  const [orden, setOrden] = useState(null);
  // ==========================================================================

  // FUNCIONES CALLBACK
  // ==========================================================================
  // Para obtener el contenido a imprimir
  const handleOnBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      resolve();
    });
  }, []);

  // Para imprimir el contenido
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  // ==========================================================================

  // FUNCIONES FLECHA
  // ==========================================================================
  // Para cerrar CustomSnackbar
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Para calcular el total de la orden
  const calcularTotalOrden = () => {
    const resultado = orden?.detalle_orden?.reduce(
      (acumulador, itemActual) =>
        acumulador +
        Number(
          (itemActual?.cantidad_m3 * itemActual?.precio_actual).toFixed(2),
        ),
      0,
    );

    return Number(resultado.toFixed(2));
  };
  // ==========================================================================

  // HOOK REACTTOPRINT
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });

  // ENDPOINTS
  // ==========================================================================
  //  Para obtener la orden
  const obtenerOrden = async () => {
    try {
      const { data, status } = await ordenesService.verOrdenNegro(id);
      if (status > 199 && status < 300) {
        setOrden(data);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };
  // ==========================================================================

  // FUNCIONES EFFECT
  // ==========================================================================
  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  useEffect(() => {
    obtenerOrden();
  }, []);
  // ==========================================================================

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="orden-compra"
            history={history}
            logged={logged}
          />
        ) : (
          acceso && (
            <Grid
              container
              style={{
                padding: "1rem 0 5rem 2rem",
                width: "95%",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ margin: "1rem 0" }}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#000000",
                    paddingLeft: "1rem",
                  }}
                >
                  Visualizar Orden de Compra
                </Typography>
                <Grid>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    children="Imprimir"
                    style={{
                      textTransform: "capitalize",
                      marginRight: "0.5rem",
                    }}
                    startIcon={<PrintIcon />}
                    onClick={handlePrint}
                  />

                  <Tooltip
                    arrow
                    placement="bottom"
                    title={
                      <Typography variant="body2" align="center">
                        Esta orden no tiene formas de pago cargadas
                      </Typography>
                    }
                    disableFocusListener={!!orden?.formas_pago}
                    disableHoverListener={!!orden?.formas_pago}
                    disableTouchListener={!!orden?.formas_pago}
                  >
                    <span>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={!orden?.formas_pago}
                        style={{ textTransform: "none", marginRight: "0.5rem" }}
                        onClick={() => toogleOpenDetallePago()}
                      >
                        Detalle de Pago
                      </Button>
                    </span>
                  </Tooltip>

                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    children="Volver"
                    style={{ textTransform: "none" }}
                    onClick={() => history.goBack()}
                  />
                </Grid>
              </Grid>

              <Card title={`ORDEN DE COMPRA Nº ${id}`} justifyContent="center">
                <Grid
                  container
                  style={{
                    padding: "1rem 3rem",
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    Cliente
                  </Typography>

                  <Grid container>
                    <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                      >
                        Nombre
                      </Typography>
                      <Typography variant="body2">{orden?.cliente}</Typography>
                    </Grid>

                    <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                      >
                        Dirección
                      </Typography>
                      <Typography variant="body2">
                        {orden?.cliente_dir}
                      </Typography>
                    </Grid>

                    <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                      >
                        Ciudad
                      </Typography>
                      <Typography variant="body2">
                        {orden?.cliente_ciudad}
                      </Typography>
                    </Grid>

                    <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                      >
                        CUIL - CUIT
                      </Typography>
                      <Typography variant="body2">
                        {orden?.cliente_cuil}
                      </Typography>
                    </Grid>

                    <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                      >
                        Condición IVA
                      </Typography>
                      <Typography variant="body2">
                        {orden?.cliente_condicion}
                      </Typography>
                    </Grid>

                    <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                      >
                        Teléfono
                      </Typography>
                      <Typography variant="body2">
                        {orden?.cliente_telefono}
                      </Typography>
                    </Grid>

                    <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                      >
                        Fecha de Carga
                      </Typography>
                      <Typography variant="body2">
                        {orden?.fecha_carga}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 2rem 0.5rem 0" }}>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", paddingRight: "0.5rem" }}
                    >
                      Nº Recibo
                    </Typography>
                    {orden?.factura_de_orden?.id_factura ? (
                      <Link
                        component="button"
                        variant="body2"
                        underline="hover"
                        target="_blank"
                        rel="noopener"
                        style={{ marginRight: "0.5rem" }}
                        onClick={() => {
                          history.push(
                            `/recibos/visualizar-recibo/${orden?.factura_de_orden?.id_factura}`,
                          );
                        }}
                      >
                        {orden?.factura_de_orden?.nro_factura}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </Grid>

                  <Grid container>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      LIQUIDACIONES ASOCIADAS
                    </Typography>
                  </Grid>

                  {orden?.resumenes_asociados?.length > 0 ? (
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                fontWeight: "bold",
                                borderBottom: 0,
                              }}
                            >
                              Nº RESUMEN
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                fontWeight: "bold",
                                borderBottom: 0,
                              }}
                            >
                              ESTADO
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                fontWeight: "bold",
                                borderBottom: 0,
                              }}
                            >
                              Nº RECIBO
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                fontWeight: "bold",
                                borderBottom: 0,
                              }}
                            >
                              CONDICIÓN
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {orden?.resumenes_asociados.map(
                            (resumen, indexResumen) => (
                              <TableRow key={indexResumen}>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: "0.5rem 0.5rem 0.5rem 0",
                                    borderBottom: 0,
                                  }}
                                >
                                  <Link
                                    component="button"
                                    variant="body2"
                                    underline="hover"
                                    target="_blank"
                                    rel="noopener"
                                    style={{ marginRight: "0.5rem" }}
                                    onClick={() =>
                                      history.push(
                                        `/remitos/remitos-por-cliente/resumen-liquidacion/${resumen?.nro_resumen}`,
                                      )
                                    }
                                  >
                                    {resumen?.nro_resumen}
                                  </Link>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: "0.5rem 0.5rem 0.5rem 0",
                                    borderBottom: 0,
                                  }}
                                >
                                  {resumen?.estado ?? "-"}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: "0.5rem 0.5rem 0.5rem 0",
                                    borderBottom: 0,
                                  }}
                                >
                                  {resumen?.num_recibo !== "-" ? (
                                    <Link
                                      component="button"
                                      variant="body2"
                                      underline="hover"
                                      target="_blank"
                                      rel="noopener"
                                      style={{ marginRight: "0.5rem" }}
                                      onClick={() =>
                                        history.push(
                                          `/recibos/visualizar-recibo/${resumen?.recibo_id}`,
                                        )
                                      }
                                    >
                                      {resumen?.num_recibo}
                                    </Link>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    padding: "0.5rem 0.5rem 0.5rem 0",
                                    borderBottom: 0,
                                  }}
                                >
                                  {resumen?.condicion ?? "-"}
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      -
                    </Typography>
                  )}

                  <Grid container style={{ marginTop: "1rem" }}>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      DETALLE DE LA ORDEN
                    </Typography>
                  </Grid>
                </Grid>

                {orden?.detalle_orden?.length > 0 ? (
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              padding: "0.5rem 0",
                              width: "3rem",
                              borderBottom: 0,
                            }}
                          />
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            PRODUCTO/SERVICIO
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            CANTIDAD
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            M³ RESTANTES
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            PRECIO ORIGINAL
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            PRECIO ACTUAL
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0.5rem 0.5rem 0.5rem 0",
                              fontWeight: "bold",
                              borderBottom: 0,
                            }}
                          >
                            FECHA VIGENTE
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orden?.detalle_orden?.map((orden, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="center"
                              style={{
                                padding: "0.5rem 0",
                                width: "3rem",
                                borderBottom: 0,
                              }}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  borderRadius: 5,
                                  backgroundColor: Theme.palette.primary.main,
                                  color: "#ffffff",
                                }}
                                onClick={() => {
                                  setOrdenSeleccionada(orden);
                                  setModalHistoricoPrecios(true);
                                }}
                              >
                                <VisibilityIcon
                                  style={{
                                    height: "1.2rem",
                                    width: "1.2rem",
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                borderBottom: 0,
                              }}
                            >
                              {orden?.formula__nombre ?? orden?.concepto ?? "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                borderBottom: 0,
                              }}
                            >
                              {orden?.cantidad_m3?.toLocaleString("es-AR")}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                borderBottom: 0,
                              }}
                            >
                              {orden?.formula
                                ? orden?.cant_restante?.toLocaleString("es-AR")
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                borderBottom: 0,
                              }}
                            >
                              {orden?.precio_original?.toLocaleString("es-AR", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "ARS",
                              })}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                borderBottom: 0,
                              }}
                            >
                              {orden?.precio_actual?.toLocaleString("es-AR", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "ARS",
                              })}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0.5rem 0.5rem 0.5rem 0",
                                borderBottom: 0,
                              }}
                            >
                              {orden?.fecha_vigencia_precio
                                ? moment(orden?.fecha_vigencia_precio).format(
                                    "DD-MM-YYYY",
                                  )
                                : "-"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Grid container style={{ padding: "0 3rem" }}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      -
                    </Typography>
                  </Grid>
                )}
              </Card>

              <HistoricoPrecios
                open={modalHistoricoPrecios}
                close={setModalHistoricoPrecios}
                orden={ordenSeleccionada}
                presupuesto={true}
              />

              <Grid hidden>
                {orden && (
                  <Imprimir
                    componentRef={componentRef}
                    orden={orden}
                    totalOrden={calcularTotalOrden}
                  />
                )}
              </Grid>
            </Grid>
          )
        )}
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          autoHideDuration={snackState.autoHideDuration}
          onClose={onClose}
        />
        <DetallePago
          open={openDetallePago}
          handleClose={toogleOpenDetallePago}
          orden={orden}
        />
      </RootContainer>
    </Protected>
  );
};

export default VerOrdenNegro;
