import { makeStyles } from "@material-ui/core/styles";
import Theme from "theme";

const useStyles = makeStyles({
  headerTable: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#001176",
    minHeight: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  headerTableTittle: {
    height: "2rem",
    color: "white",
    textAlign: "center",
  },

  tableContainer: {
    margin: "0 auto",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, .2)",
    maxHeight: "50vh",
    overflow: "auto",
  },

  tableContainerCrearFactura: {
    margin: "0 auto",
    backgroundColor: "#ffffffff",
    boxShadow: "0px",
  },

  tableCellHeaders: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    width: "auto",
    padding: "0 1rem",
    borderBottom: 0,
    backgroundColor: "#F3F3F3",
  },

  tableCellHeadersFirst: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    width: "15rem",
    padding: "0 0 0 1rem",
    borderBottom: 0,
    backgroundColor: "#F3F3F3",
  },

  tableCellHeadersLast: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
    width: "auto",
    padding: "0 1rem 0 0",
    borderBottom: 0,
    backgroundColor: "#F3F3F3",
  },

  tableCell: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "left",
    padding: "0.5rem 1rem",
    borderBottom: 0,
  },

  tableCellFirst: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "left",
    padding: "0.5rem 0 0.5rem 1rem",
    borderBottom: 0,
    width: "4rem",
  },

  tableCellLast: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "left",
    padding: "0.5rem 1rem 0.5rem 0",
    borderBottom: 0,
  },

  totalRow: {
    backgroundColor: "#CCCFE4",
  },

  totalCell: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "186.69%",

    color: "rgba(67, 59, 59, 0.87)",
  },

  button2: {
    background: "FFF",
    color: "#001176",
    textTransform: "capitalize",
    marginLeft: "1rem",
    border: "2px solid #001176",
    maxHeight: "2rem",
  },

  tableCell2: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: "rgba(67, 59, 59, 0.87)",
  },

  tablecell3: {
    fontSize: "16px",
    whiteSpace: "nowrap",
  },

  iconButton: {
    width: "1.5rem",
    height: "1.5rem",
    marginRight: "0.5rem",
    borderRadius: 5,
    backgroundColor: Theme.palette.primary.main,
    color: "#ffffff",
  },

  icon: {
    height: "1.2rem",
    width: "1.2rem",
  },

  logo: {
    height: "4.5rem",
  },
  inputDisabled: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black",
    },
  },
});
export default useStyles;
