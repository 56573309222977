import React, { useState } from "react";
import { Typography, Modal, Grid, Button } from "@material-ui/core";
import Theme from "theme";
import moment from "moment";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "auto",
    height: "auto",
    overflow: "auto",
  },
  formulas: {
    fontSize: "18px",
    color: "rgba(67, 59, 59, 0.87)",
    textAlign: "left",
    fontFamily: "Roboto",
    fontWeight: 700,
  },
  cantidades: {
    textAlign: "right",
    fontSize: "20px",
  },
}));

const TotalizadorModal = ({ open, handleClose, remitos, minDate, maxDate }) => {
  const classes = useStyles();

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  let total = remitos[remitos.length - 1].Total_Formula;

  return (
    <>
      <Modal open={open} className={classes.paper}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          spacing={2}
          xl={5}
          lg={5}
          md={6}
          sm={6}
          xs={11}
          style={{
            backgroundColor: "#ffffff",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
            maxHeight: "85vh",
            overflow: "auto",
          }}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <Typography item variant="h5" style={{ color: "#ffffff" }}>
              Totalizador de Consumo
            </Typography>
          </Grid>
          <Grid
            item
            style={{ margin: "1rem 0" }}
            direction="row"
            justifyContent="center"
          >
            <Typography
              style={{
                textAlign: "center",
                letterSpacing: "0.15px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "23px",
              }}
            >
              Consumos en Mts³ desde {moment(minDate).format("DD-MM-YYYY")} hasta{" "}
              {moment(maxDate).format("DD-MM-YYYY")}
            </Typography>
          </Grid>
          {total?.length ? (
            total.map((form) => (
              <Grid
                item
                container
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={12}
                style={{
                  margin: "1rem",
                  paddingBottom: "1rem",
                  minWidth: "70vh",
                  maxWidth: "75vh",
                  borderBottom: "1px solid rgba(67, 59, 59, 0.87)",
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item style={{left: 0, width: "50%", marginRight: "2rem"}}>
                  <Typography className={classes.formulas}>
                    {form.tipo}:
                  </Typography>
                </Grid>
                <Grid item style={{ width: "30%", }}>
                  <Typography className={classes.cantidades}>
                    {form.cantidad}
                  </Typography>
                </Grid>
            </Grid>
            ))
          ) : (
            <Typography className={classes.formulas}>
              No se registran consumos en este periodo
            </Typography>
          )}
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ padding: "1rem" }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="small"
              style={{ marginRight: "0.5rem" }}
              onClick={() => handleClose()}
            >
              Volver
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <CustomSnackbar
        message={snackState.message}
        open={snackState.open}
        severity={snackState.severity}
        onClose={onClose}
      />
    </>
  );
};
export default TotalizadorModal;
