import {InputAdornment, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {Controller} from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";

export default function NewAutocomplete({
  control,
  rules,
  name,
  defaultValue,
  setValue,
  onChange,
  placeholder,
  label,
  InputLabelProps,
  options,
  value,
  ...props
}) {
  const onChangeFunction = (e, value) => {
    if (onChange) {
      onChange(e, value);
    }
  };

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Autocomplete
          {...props}
          id={name}
          options={options ?? []}
          onChange={(e, value) => {
            onChange(value);
            onChangeFunction(e, value);
          }}
          value={value ?? null}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              type="text"
              id={name}
              name={name}
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error.message : null}
              InputLabelProps={InputLabelProps}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}
