/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

// MATERIAL UI
import { Button, Grid, Modal, Typography } from "@material-ui/core";

// ICONS
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const AgregarArchivo = ({
  open,
  handleClose,
  setArchivoSeleccionado,
  setURLArchivo,
  setSnackState,
}) => {
  // ESTADO PARA GUARDAR EL ARCHIVO CARGADO
  const [archivo, setArchivo] = useState(null);

  // FUNCION ENCARGADA DE CAPTURAR EL ARCHIVO SOLTADO EN EL MODAL
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0].type === "application/pdf") {
      setArchivo(acceptedFiles[0]);
    } else {
      setSnackState({
        open: true,
        severity: "error",
        message: "Solamente se Aceptan Archivos PDF",
      });
    }
  }, []);

  useEffect(() => {
    if (archivo) {
      const url = URL.createObjectURL(archivo);

      setURLArchivo(url);
    }
  }, [archivo]);

  const guardarArchivo = () => {
    setArchivoSeleccionado(archivo);
    setArchivo(null);
    handleClose();
  };

  // ESTADOS PARA MANEJAR EL HOOK DROPZONE
  const { getRootProps, isDragActive } = useDropzone({ onDrop, noClick: true });
  return (
    <Modal open={open} onClose={handleClose}>
      <Grid
        {...getRootProps()}
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        xl={5}
        lg={5}
        md={6}
        sm={8}
        xs={10}
        style={{
          backgroundColor: "#ffffff",
          padding: "1rem 2rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "2px dashed grey",
          borderRadius: 10,
          height: "15rem",
        }}
      >
        {archivo ? (
          <>
            <Typography variant="h6">{archivo?.name}</Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "1rem" }}
            >
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  setArchivo(null);
                  handleClose();
                }}
                style={{ marginRight: "0.5rem" }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  setArchivoSeleccionado(archivo);
                  setArchivo(null);
                  handleClose();
                }}
              >
                Aceptar
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <CloudUploadIcon
              style={{ color: "black", height: "60px", width: "60px" }}
            />
            {isDragActive ? (
              <Typography variant="body1" style={{ fontSize: "bold" }}>
                Suelte el Archivo Aquí
              </Typography>
            ) : (
              <>
                <Typography variant="body1">
                  Arrastre y Suelte el Archivo Aquí
                </Typography>
                <Typography variant="body1">o</Typography>

                <label htmlFor="upload-file-click">
                  <input
                    hidden={true}
                    id="upload-file-click"
                    accept=".pdf"
                    type="file"
                    onChange={(e) => setArchivo(e.target.files[0])}
                  />
                  <Button
                    component="span"
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ textTransform: "none", fontWeight: "normal" }}
                  >
                    Seleccionar Archivo
                  </Button>
                </label>

                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginTop: "1rem" }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setArchivo(null);
                      handleClose();
                    }}
                    style={{ marginRight: "0.5rem" }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => guardarArchivo()}
                  >
                    Aceptar
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default AgregarArchivo;
