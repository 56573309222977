/* eslint-disable react-hooks/exhaustive-deps */

// REACT
import { useEffect } from "react";

// MATERIAL UI
import { CircularProgress, Grid, Typography } from "@material-ui/core";

// SERVICES
import usuarioService from "services/usuario.service";

// VARIOS
import Theme from "theme";

const VerificarAcceso = ({
  setVerificando,
  setAcceso,
  modulo,
  history,
  logged,
}) => {
  // Funcionalidad para verificar el acceso de un usuario a un modulo del sistema.
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const { status } = await usuarioService.checkAccess({ modulo: modulo });

      if (status > 199 && status < 300) {
        // Si la peticion es exitosa, el usuario puede acceder al modulo.
        setVerificando(false);
        setAcceso(true);
      }
    } catch (error) {
      // En caso de error, el usuario no puede acceder al modulo y se le muestra una pantalla de error de permiso.
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: modulo });
    }
  };

  // Efecto que ejecuta la funcion verificar acceso si el usuario esta logueado.
  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        marginTop: "15rem",
      }}
    >
      <CircularProgress
        style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
      />
      <Typography style={{ color: "#000000" }}>Verificando permisos</Typography>
    </Grid>
  );
};

export default VerificarAcceso;
