import axios from "./axios.config";

class FormulaService {
  /***
    ENDPOINT DE FORMULAS
  ***/

  /*
    Retorna lista de Formulas
  */
  getFormulas() {
    return axios.get("/api/formulas/listar-formulas/");
  }

  getAllFormulas() {
    return axios.get("/api/formulas/formulas/");
  }

  /*
    Crea Formula con materiales
    body = {
      nombre: nombre_formula,
      materiales: [{material1},{material2}]
    }
 */
  addMaterial(body) {
    return axios.post(`/api/formulas/crear-formula/`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  /*
   Edita Nombre Fórmula por id
  */
  editFormulaNombre(id, data) {
    return axios.patch(`/api/formulas/modificar-formula/${id}/`, data);
  }

  /*
   Elimina Fórmula por id
  */
  deleteFormula(id) {
    return axios.delete(`/api/formulas/eliminar-formula/${id}/`);
  }

  /*
    Obtener Lista de Material por Fórmula
    params: id de la fómula
  */
  obtenerMateriales(idFormula) {
    return axios.get(`api/formulas/listar-material/${idFormula}/`);
  }

  /*
   Cargar Materiales a la Fórmula se envía el id de Formula en el body
 
   body = {
           tipo: "",
           subtipo: "",
           cantidad: "",
           formula: id_formula,
         }
  */
  cargarMateriales(data) {
    return axios.post(`api/formulas/crear-material/`, data);
  }

  /*
     Editar Material cargado en Fórmula
     Envía en body Array de Materiales que se modifican
   body = [{
            id : id_material,
            tipo: "",
            subtipo: "",
            cantidad: "",
        }]
  */
  editarMaterial(data) {
    return axios.post(`/api/formulas/modificar-material/`, data);
  }

  /*
    Elimina Material cargado en la Fórmula
  */
  deleteMaterialFormula(id) {
    return axios.delete(`/api/formulas/eliminar-material/${id}/`);
  }

  /***
    ENDPOINT DE MATERIALES
  ***/

  /*
    Lista Materiales disponibles
  */
  getMateriales() {
    return axios.get("/api/formulas/listar-material-lista/");
  }

  getConsumoMensual(data) {
    return axios.post("/api/remitos/listar-consumo/", data);
  }

  /*
    Crear Material
  */
  createMaterial(data) {
    return axios.post(`/api/formulas/crear-material-lista/`, data);
  }

  /*
    Edita Material por id
    params: id del material y data a modificar
  */
  editMaterial(id, data) {
    return axios.patch(`/api/formulas/modificar-material-lista/${id}/`, data);
  }

  /*
    Elimina Material por id
    params: id del material a eliminar
  */
  deleteMaterial(id) {
    return axios.delete(`/api/formulas/eliminar-material-lista/${id}/`);
  }
}

export default new FormulaService();
