/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { CompareArrows } from "@material-ui/icons";
import { Delete } from "@material-ui/icons";
import useStyles from "./styles";
import moment from "moment";
import LoadingText from "components/loadingText";
import StyledIconButton from "components/StyledIconButton";

export default function Liquidaciones({
  lista,
  loading,
  ordenarPor,
  setConfirmDialog,
  verFactura,
  verRecibo,
  eliminarResumen,
  eliminarLiquidacion,
}) {
  const history = useHistory();
  const classes = useStyles();
    
  return loading ? (
    <LoadingText title="Cargando ..." />
  ) : (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow className="tableRow">
            <TableCell
              className={classes.tableCell2}
              style={{
                minWidth: "15rem",
                paddingLeft: "3rem",
              }}
            >
              N° Resumen
              <IconButton onClick={() => ordenarPor("numero")}>
                <CompareArrows
                  style={{
                    width: "1rem",
                    transform: "rotate(90deg)",
                  }}
                />
              </IconButton>
            </TableCell>

            <TableCell
              className={classes.tableCell2}
              style={{ minWidth: "10rem" }}
            >
              Fecha
              <IconButton onClick={() => ordenarPor("fecha")}>
                <CompareArrows
                  style={{
                    width: "1rem",
                    transform: "rotate(90deg)",
                  }}
                />
              </IconButton>
            </TableCell>

            <TableCell
              className={classes.tableCell2}
              style={{ paddingLeft: "3rem" }}
            >
              N° Factura
            </TableCell>

            <TableCell
              className={classes.tableCell2}
              style={{ minWidth: "10rem" }}
            >
              N° Orden
              <IconButton onClick={() => ordenarPor("num_ord")}>
                <CompareArrows
                  style={{
                    width: "1rem",
                    transform: "rotate(90deg)",
                  }}
                />
              </IconButton>
            </TableCell>

            <TableCell
              style={{ paddingLeft: "6rem" }}
              className={classes.tableCell2}
            >
              N° Recibo
            </TableCell>

            <TableCell
              align="right"
              className={classes.tableCellHeaders}
              style={{ paddingRight: "3rem" }}
            >
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lista?.count > 0 &&
            lista?.results?.map((liquidacion) => (
              <TableRow
                hover
                key={
                  liquidacion?.presupuesto
                    ? liquidacion.nro_resumen + "liquidacion"
                    : liquidacion.nro_resumen + "resumen"
                }
                style={{
                  backgroundColor: liquidacion?.presupuesto ? "#E26E6E" : "",
                }}
              >
                <TableCell
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    minWidth: "10rem",
                    paddingLeft: "3rem",
                  }}
                >
                  {liquidacion?.nro_resumen ?? "-"}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {moment(liquidacion?.resumen_fecha).format("DD-MM-YYYY") ??
                    "-"}
                </TableCell>

                <TableCell
                  width="20%"
                  align="left"
                  style={{ paddingLeft: "3rem" }}
                >
                  {!liquidacion?.presupuesto && liquidacion?.nro_factura ? (
                    <Link
                      style={{
                        fontWeight: 700,
                        fontSize: "17px",
                        color: "#1C3AA9",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={() => verFactura(liquidacion?.factura_id)}
                    >
                      {liquidacion?.nro_factura}
                    </Link>
                  ) : (
                    "-"
                  )}
                </TableCell>

                <TableCell width="15%" align="left">
                  {liquidacion?.orden?.num_orden || liquidacion?.orden?.id ? (
                    <Link
                      style={{
                        fontWeight: 700,
                        fontSize: "17px",
                        color: "#1C3AA9",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={() =>
                        history.push(
                          liquidacion?.presupuesto
                            ? `/ordenes/ver-orden-negro/${
                                liquidacion?.orden?.id ?? ""
                              }/`
                            : `/ordenes/ver-orden/${
                                liquidacion?.orden?.id ?? ""
                              }/`,
                        )
                      }
                    >
                      {liquidacion?.orden?.num_orden ??
                        liquidacion?.orden?.id ??
                        "-"}
                    </Link>
                  ) : (
                    "-"
                  )}
                </TableCell>

                <TableCell
                  width="15%"
                  align="left"
                  style={{ paddingLeft: "6rem" }}
                >
                  {liquidacion?.presupuesto && liquidacion?.nro_factura ? (
                    <Link
                      style={{
                        fontWeight: 700,
                        fontSize: "17px",
                        color: "#1C3AA9",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={() => verRecibo(liquidacion?.factura_id)}
                    >
                      {liquidacion?.nro_factura}
                    </Link>
                  ) : (
                    "-"
                  )}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    style={{
                      whiteSpace: "nowrap",
                      minWidth: "15rem",
                    }}
                  >
                    <StyledIconButton
                      disabled={
                        liquidacion?.nro_factura ||
                        liquidacion?.orden?.num_orden
                      }
                      onClick={() =>
                        setConfirmDialog({
                          title: `¿Está seguro de eliminar ${
                            liquidacion?.presupuesto
                              ? "la liquidación"
                              : "el resumen"
                          } ${liquidacion?.nro_resumen}?`,
                          isOpen: true,
                          onConfirm: () => {
                            liquidacion?.presupuesto
                              ? eliminarLiquidacion(liquidacion?.nro_resumen)
                              : eliminarResumen(liquidacion?.nro_resumen);
                          },
                        })
                      }
                    >
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <Typography variant="body2" align="center">
                            {liquidacion?.nro_factura ||
                            liquidacion?.orden?.num_orden
                              ? `No se puede eliminar ${
                                  liquidacion?.presupuesto
                                    ? "esta liquidación"
                                    : "este resumen"
                                } porque tiene
                            asociado ${
                              liquidacion?.presupuesto
                                ? "un recibo"
                                : "una factura"
                            } o una orden`
                              : `Eliminar ${
                                  liquidacion?.presupuesto
                                    ? "Liquidación"
                                    : "Resumen"
                                } Nº${liquidacion?.nro_resumen}`}
                          </Typography>
                        }
                      >
                        <Delete className={classes.icon} />
                      </Tooltip>
                    </StyledIconButton>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        history.push(
                          liquidacion?.presupuesto
                            ? `/remitos/remitos-por-cliente/resumen-liquidacion/${liquidacion.nro_resumen}`
                            : `/remitos/remitos-por-cliente/resumen/${liquidacion.nro_resumen}`,
                        )
                      }
                      style={{
                        textTransform: "none",
                        maxHeight: "1.5rem",
                      }}
                    >
                      {liquidacion?.presupuesto
                        ? "Ver Liquidación"
                        : "Ver Resumen"}
                    </Button>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
