import { createNewUrl } from "constants/funciones/createUrl";
import axios from "./axios.config";

class RemitosService {
  /*
  * Retorna lista de remitos por cliente
  /
  getRemitoClient(id) {
    return axios.get(/api/remitos/obtener-remitos-cliente/${id}/);
  }

  /*
  Post Crear Remito
  */
  addRemito(body) {
    return axios.post(`/api/remitos/crear-remito/`, body);
  }

  /*
  Editar Remito
  */
  editRemito(id, body) {
    return axios.patch(`/api/remitos/modificar-remito/${id}/`, body);
  }

  /*
  Retorna lista de turnos, por cliente filtrado por estado sin entregar
  entrega === nulo
 */
  getTurnoNoAsignado(id) {
    return axios.get(`/api/agenda/listar-turno-pendiente-cliente/${id}/`);
  }

  /* funcion para moverme de pagina lista de todos los remitos*/
  getNextPage(
    page,
    minDate,
    maxDate,
    searchEstado,
    searchCondicion,
    searchDias,
    searchFormula,
    searchObra,
    searchOrdRemi,
    searchOrdFecha,
    searchOrdMts,
    searchOrdObra,
    idCliente,
  ) {
    return axios.get(
      `api/remitos/listar-remitos-todos-fecha/?page=${page}&fecha=${minDate}|${maxDate}&estado=${searchEstado}&condicion=${searchCondicion}&dias=${searchDias}&formula=${searchFormula}&obra=${searchObra}&ordremi=${searchOrdRemi}&ordfecha=${searchOrdFecha}&ordmts=${searchOrdMts}&ordobra=${searchOrdObra}&id=${idCliente}`,
    );
  }

  /* funcion para moverme de pagina lista de todos los remitos*/
  getNextPageB(
    page,
    minDate,
    maxDate,
    searchEstado,
    searchCondicion,
    searchDias,
    searchFormula,
    searchObra,
    searchOrdRemi,
    searchOrdFecha,
    searchOrdMts,
    searchOrdObra,
    idCliente,
  ) {
    return axios.get(
      `api/remitos-b/listar-remitos-todos-fecha/?page=${page}&fecha=${minDate}|${maxDate}&estado=${searchEstado}&condicion=${searchCondicion}&dias=${searchDias}&formula=${searchFormula}&obra=${searchObra}&ordremi=${searchOrdRemi}&ordfecha=${searchOrdFecha}&ordmts=${searchOrdMts}&ordobra=${searchOrdObra}&id=${idCliente}`,
    );
  }

  /* funcion para moverme de pagina lista de remitos por cliente*/
  getNextPageCliente(
    page,
    minDate,
    maxDate,
    searchEstado,
    searchCondicion,
    searchDias,
    searchFormula,
    searchObra,
    searchOrdRemi,
    searchOrdFecha,
    searchOrdMts,
    searchOrdObra,
    idCliente,
  ) {
    return axios.get(
      `api/remitos/filtrar-remito-cliente/?page=${page}&fecha=${minDate}|${maxDate}&estado=${searchEstado}&condicion=${searchCondicion}&dias=${searchDias}&formula=${searchFormula}&obra=${searchObra}&ordremi=${searchOrdRemi}&ordfecha=${searchOrdFecha}&ordmts=${searchOrdMts}&ordobra=${searchOrdObra}&id=${idCliente}`,
    );
  }

  /* funcion para moverme de pagina lista de remitos por cliente*/
  getNextPageClienteB(
    page,
    minDate,
    maxDate,
    searchEstado,
    searchCondicion,
    searchDias,
    searchFormula,
    searchObra,
    searchOrdRemi,
    searchOrdFecha,
    searchOrdMts,
    searchOrdObra,
    idCliente,
  ) {
    return axios.get(
      `api/remitos-b/filtrar-remito-cliente/?page=${page}&fecha=${minDate}|${maxDate}&estado=${searchEstado}&condicion=${searchCondicion}&dias=${searchDias}&formula=${searchFormula}&obra=${searchObra}&ordremi=${searchOrdRemi}&ordfecha=${searchOrdFecha}&ordmts=${searchOrdMts}&ordobra=${searchOrdObra}&id=${idCliente}`,
    );
  }

  getTurnoCliente(body) {
    return axios.post(`/api/agenda/listar-turno-fecha-cliente/`, body);
  }

  /*
   */
  getRemitoClient(id) {
    return axios.get(`/api/remitos/obtener-remitos-cliente/${id}/`);
  }

  /*
   * Retorna lista de remitos no agendados del cliente
   */
  getRemitoNoAgendadoCliente(id) {
    return axios.get(`/api/remitos/obtener-remitos-filtrado/${id}/`);
  }

  getRemitos() {
    return axios.get(`/api/remitos/listar-remitos-cliente/`);
  }

  deleteRemito(id) {
    return axios.delete(`/api/remitos/modificar-remito/${id}/`);
  }

  //get remitos por cliente
  getRemitosPorCliente(id) {
    return axios.get(`/api/remitos/obtener-remitos-cliente/${id}/`);
  }

  /*
  Envia una observacion al anular un Remito
  */
  anularRemitoObservacion(id, body) {
    return axios.patch(`/api/remitos/anular-remito/${id}/`, body);
  }

  /** Parámetros de Filtros de Busquedas
   *
   * @param {Number} page página por default 1
   * @param {Date} minDate Fecha Inicio
   * @param {Date} maxDate Fecha Fin
   * @param {String} searchEstado "A Facturar" o "Facturado" o "Sin Factura"
   * @param {String} searchCondicion "Pagado" o "A Cobrar"
   * @param {Number} searchDias "días de atraso respecto a la fecha actual"
   * @param {String} searchFormula "Nombre de Fórmula"
   * @param {String} searchObra "Nombre de obra"
   * @param {Number} idCliente "id del cliente"
   */

  /*
   * @returns  remitos-b (por rango de fecha) según el cliente y parámetro de búsqueda
   */
  filtrarRemitosBPorFecha(
    // page = 1,
    // minDate,
    // maxDate,
    // searchEstado,
    // searchCondicion,
    // searchDias,
    // searchFormula,
    // searchObra,
    // searchOrdRemi,
    // searchOrdFecha,
    // searchOrdMts,
    // searchOrdObra,
    // idCliente,
    params,
  ) {
    const base_url = `api/remitos-b/filtrar-remito-cliente/`;
    const url = `${base_url}${createNewUrl(params)}`;
    return axios.get(url);
    // return axios.get(
    //   `api/remitos-b/filtrar-remito-cliente/?page=${page}&fecha=${minDate}|${maxDate}&estado=${searchEstado}&condicion=${searchCondicion}&dias=${searchDias}&formula=${searchFormula}&obra=${searchObra}&ordremi=${searchOrdRemi}&ordfecha=${searchOrdFecha}&ordmts=${searchOrdMts}&ordobra=${searchOrdObra}&id=${idCliente}`,
    // );
  }

  /*
   * @returns  remitos (por rango de fecha) según el cliente y parámetro de búsqueda
   */
  filtrarRemitosPorFecha(
    // page = 1,
    // minDate,
    // maxDate,
    // searchEstado,
    // searchCondicion,
    // searchDias,
    // searchFormula,
    // searchObra,
    // searchOrdRemi,
    // searchOrdFecha,
    // searchOrdMts,
    // searchOrdObra,
    // idCliente,
    params,
  ) {
    const base_url = `api/remitos/filtrar-remito-cliente/`;
    const url = `${base_url}${createNewUrl(params)}`;
    return axios.get(url);

    // return axios.get(
    //   `api/remitos/filtrar-remito-cliente/?page=${page}&fecha=${minDate}|${maxDate}&estado=${searchEstado}&condicion=${searchCondicion}&dias=${searchDias}&formula=${searchFormula}&obra=${searchObra}&ordremi=${searchOrdRemi}&ordfecha=${searchOrdFecha}&ordmts=${searchOrdMts}&ordobra=${searchOrdObra}&id=${idCliente}`,
    // );
  }

  /*
   * @returns todos los remitos-b (por rango de fecha) según parámetro de búsqueda
   */
  listarRemitosBPorFecha(
    page,
    minDate,
    maxDate,
    searchEstado,
    searchCondicion,
    searchDias,
    searchFormula,
    searchObra,
    searchOrdRemi,
    searchOrdFecha,
    searchOrdMts,
    searchOrdObra,
  ) {
    return axios.get(
      `api/remitos-b/listar-remitos-todos-fecha/?page=${page}&fecha=${minDate}|${maxDate}&estado=${searchEstado}&condicion=${searchCondicion}&dias=${searchDias}&formula=${searchFormula}&obra=${searchObra}&ordremi=${searchOrdRemi}&ordfecha=${searchOrdFecha}&ordmts=${searchOrdMts}&ordobra=${searchOrdObra}`,
    );
  }

  /*
   * @returns todos los remitos (por rango de fecha) según parámetro de búsqueda
   */
  listarRemitosPorFecha(
    page,
    minDate,
    maxDate,
    searchEstado,
    searchCondicion,
    searchDias,
    searchExcedente,
    searchFormula,
    searchObra,
    searchOrdRemi,
    searchOrdFecha,
    searchOrdMts,
    searchOrdObra,
  ) {
    return axios.get(
      `api/remitos/listar-remitos-todos-fecha/?page=${page}&fecha=${minDate}|${maxDate}&estado=${searchEstado}&condicion=${searchCondicion}&dias=${searchDias}&excedente=${searchExcedente ? true : ""}&formula=${searchFormula}&obra=${searchObra}&ordremi=${searchOrdRemi}&ordfecha=${searchOrdFecha}&ordmts=${searchOrdMts}&ordobra=${searchOrdObra}`,
    );
  }

  deleteRemitoPorId(id, body) {
    return axios.post(`/api/remitos/eliminar-remito/${id}/`, body);
  }
  /**
   * obtener remito por id
   * @param {number} id
   * @returns void data remito
   */
  getRemitosId(id) {
    return axios.get(`/api/remitos/obtener-remito/${id}/`);
  }

  getRemitosBId(id) {
    return axios.get(`/api/remitos-b/obtener-remito/${id}/`);
  }
  cambiarCondicionPago(body) {
    return axios.post(`api/remitos/remito-condicion-pagado/`, body);
  }

  descargarExcelConsumos(data) {
    return axios.post(`/api/remitos/exportar-consumo/`, data, {
      responseType: "blob",
    });
  }

  descargarExcelRemitos(data) {
    return axios.post(`/api/remitos/exportar-remitos/`, data, {
      responseType: "blob",
    });
  }

  /**
   * Generar Resumen
   * @param {*} body arreglo de los id de remitos  "remito":[2, 3]
   * @returns
   */
  generarResumen(body) {
    return axios.post(`api/resumenes/generar-resumen/`, body);
  }

  generarResumenMasivo(body) {
    return axios.post(`api/resumenes/generar-resumen-masivo/`, body);
  }

  generarResumenMasivoB(body) {
    return axios.post(`api/resumenes-b/generar-resumen-masivo/`, body);
  }

  generarResumenLiqui(body) {
    return axios.post(`api/resumenes-b/generar-resumen/`, body);
  }

  listarRemitosFiltradosResumen(filtro, idCliente, idResumen) {
    return axios.get(
      `api/remitos/filtrar-remito-cliente-resumen/?page=${filtro?.page}&cliente=${idCliente}&resumen=${idResumen}&fecha=${filtro?.minDate}|${filtro?.maxDate}&obra=${filtro?.obra}`,
    );
  }

  listarRemitosBFiltradosResumen(filtro, idCliente, idResumen) {
    return axios.get(
      `api/remitos-b/filtrar-remito-cliente-resumen/?page=${filtro?.page}&cliente=${idCliente}&resumen=${idResumen}&fecha=${filtro?.minDate}|${filtro?.maxDate}&obra=${filtro?.obra}`,
    );
  }
}

export default new RemitosService();
