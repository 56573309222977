export const namesFacturacion = {
  todas: "Todas",
  vencidas: "Vencidas",
  aCobrar: "A Cobrar",
  pagado: "Pagado",
  notasCredito: "credito",
  notasDebito: "debito",
  numFactura: "num_fac",
  numFacturaAsociada: "num_fac_asoc",
  numNota: "num_nota",
  fechaEmision: "fecha_emision",
  monto: "monto",
  condicion: "condicion",
  numFact: "num_factura",
  fechaVencPago: "fecha_venc_pago",
  tipoFactura: "tipo_factura",
  puntoVenta: "punto_venta",
  condIva: "cond_iva",
  cliente: "cliente",
  cuitCuil: "cuit_cuil",
  domicilio: "domicilio",
  tipo: "tipo",
  cantMtrs: "cant_metros",
  precioUnitario: "precio_unitario",
  bonificacion: "bonif",
};
