import axios from "./axios.config";
import { createNewUrl } from "constants/funciones/createUrl";

class FacturacionService {
  listarResumenes(id, filtro) {
    return axios.get(
      `api/resumenes/listar-resumen-cliente/${id}/?page=${filtro?.page}&desde=${filtro.fecha_desde}&hasta=${filtro.fecha_hasta}&ordnum=${filtro.ordnum}&ordfecha=${filtro.ordfecha}&ordnumfac=${filtro.ordnumfac}&ordnumorden=${filtro.ordnumorden}`,
    );
  }

  listarLiquidaciones(id, filtro) {
    return axios.get(
      `api/resumenes-b/listar-resumen-cliente/${id}/?page=${filtro?.page}&desde=${filtro.fecha_desde}&hasta=${filtro.fecha_hasta}&ordnum=${filtro.ordnum}&ordfecha=${filtro.ordfecha}&ordnumorden=${filtro.ordnumorden}`,
    );
  }

  vistaPreviaResumen(params) {
    const base_url = 'api/remitos/filtrar-remito-cliente-masivo/';
    const url = `${base_url}?${params.toString()}`;
    return axios.get(url);
  }

  vistaPreviaResumenLiquidacion(params) {
    const base_url = 'api/remitos-b/filtrar-remito-cliente-masivo/';
    const url = `${base_url}?${params.toString()}`;
    return axios.get(url);
  }
  
  deleteResumen(id) {
    /*
     * eliminar resumen de la lista
     */
    return axios.delete(`api/resumenes/eliminar-resumen/${id}/`);
  }

  deleteLiquidacion(id) {
    return axios.delete(`api/resumenes-b/eliminar-resumen/${id}/`);
  }

  planillaResumen(id) {
    /*
     * @returns todos los remitos asociados a la planilla de resumen
     */
    return axios.get(`api/resumenes/ver-resumen/${id}/`);
  }

  planillaLiquidacion(id) {
    /*
     * @returns todos los remitos en negro asociados a la planilla de liquidacion
     */
    return axios.get(`api/resumenes-b/ver-resumen/${id}/`);
  }

  asociarResumenOC(id, data) {
    return axios.patch(`/api/resumenes/asociar-orden-compra/${id}/`, data);
  }

  asociarLiquidacionOC(id, data) {
    return axios.patch(`/api/resumenes-b/asociar-orden-compra/${id}/`, data);
  }

  agregarQuitarRemitos(id, body) {
    return axios.post(`api/resumenes/cambiar-remitos-resumen/${id}/`, body);
  }

  agregarQuitarRemitosB(id, body) {
    return axios.post(`api/resumenes-b/cambiar-remitos-resumen/${id}/`, body);
  }

  listarResumenesFormaPago(body) {
    return axios.post(
      `api/resumenes/listar-resumenes-cliente-formapago/`,
      body,
    );
  }

  cambiarCondicionPago(body) {
    /**
     * * Cambiar la/s factura a PAGADO
     * @param {*} body
     * @returns
     */
    return axios.post(`/api/facturas/cambiar-condicion/`, body);
  }

  cambiarCondicionPagoNegro(body) {
    /**
     * * Cambiar la/s factura a PAGADO
     * @param {*} body
     * @returns
     */
    return axios.post(`/api/facturas-b/cambiar-condicion/`, body);
  }

  listarFacturas(filtro) {
    /*
     * @returns todas las facturas
     */
    return axios.get(
      `api/facturas/listar-facturas/?page=${filtro.page}&search=${filtro?.search}&fecha=${filtro?.fecha_desde}|${filtro?.fecha_hasta}&condicion=${filtro?.condicion}&dias=${filtro?.dias}&ordcondi=${filtro?.ordcondi}&ordfechem=${filtro?.ordfecha}&ordnumfac=${filtro?.ordnumfac}&ordmonto=${filtro?.ordmonto}`,
    );
  }

  listarRecibos(filtro) {
    /*
     * @returns todos los recibos
     */
    return axios.get(
      `api/facturas-b/listar-facturas/?page=${filtro.page}&search=${filtro?.search}&fecha=${filtro?.fecha_desde}|${filtro?.fecha_hasta}&condicion=${filtro?.condicion}&dias=${filtro?.dias}&ordcondi=${filtro?.ordcondi}&ordfechem=${filtro?.ordfecha}&ordnumfac=${filtro?.ordnumfac}&ordmonto=${filtro?.ordmonto}`,
    );
  }

  getFacturaCliente(id, filtro) {
    /*
     * @returns facturas de un cliente
     */
    return axios.get(
      `api/facturas/listar-facturas-cliente/${id}/?page=${filtro?.page}&search=${filtro?.search}&fecha=${filtro?.fecha_desde}|${filtro?.fecha_hasta}&condicion=${filtro?.condicion}&dias=${filtro?.dias}&ordcondi=${filtro?.ordcondi}&ordfechem=${filtro?.ordfecha}&ordnumfac=${filtro?.ordnumfac}&ordmonto=${filtro?.ordmonto}`,
    );
  }

  getReciboCliente(id, filtro) {
    /*
     * @returns recibos de un cliente
     */
    return axios.get(
      `api/facturas-b/listar-facturas-cliente/${id}/?page=${filtro?.page}&search=${filtro?.search}&fecha=${filtro?.fecha_desde}|${filtro?.fecha_hasta}&condicion=${filtro?.condicion}&dias=${filtro?.dias}&ordcondi=${filtro?.ordcondi}&ordfechem=${filtro?.ordfecha}&ordnumfac=${filtro?.ordnumfac}&ordmonto=${filtro?.ordmonto}`,
    );
  }

  verFactura(id) {
    /*
     *@Visualizar factura
     */
    return axios.get(`api/facturas/ver-factura/${id}/`);
  }

  verRecibo(id) {
    /*
     *@Visualizar recibo
     */
    return axios.get(`api/facturas-b/ver-factura/${id}/`);
  }

  deleteFactura(id) {
    /*
     * @return eliminar factura
     */
    return axios.delete(`api/facturas/eliminar-factura/${id}/`);
  }

  deleteRecibo(id) {
    /*
     * @return eliminar recibo
     */
    return axios.delete(`api/facturas-b/eliminar-factura/${id}/`);
  }

  crearFactura(data) {
    return axios.post(`api/facturas/crear-factura/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  crearRecibo(data) {
    return axios.post(`api/facturas-b/crear-factura/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  editarFactura(id, data) {
    return axios.patch(`api/facturas/editar-factura/${id}/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  editarRecibo(id, data) {
    return axios.patch(`api/facturas-b/editar-factura/${id}/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  descargarExcel(data) {
    /**
     * * Funcion para descargar Excel por rago de fecha
     * @params data: rango de fecha
     */
    return axios.post(`/api/facturas/generar-excel/`, data, {
      responseType: "blob",
    });
  }

  descargarExcelRecibo(data) {
    /**
     * * Funcion para descargar Excel por rago de fecha
     * @params data: rango de fecha
     */
    return axios.post(`/api/facturas-b/generar-excel/`, data, {
      responseType: "blob",
    });
  }

  getClientes() {
    /*
     * @return todos los clientes en la lista de facturas
     */

    return axios.get(`api/cta-corriente/listar-clientes-todos/`);
  }

  /*
   * @returns estado cuenta
   */
  getEstadoCuentaFacturasById(id, search) {
    return axios.get(
      `api/facturas/estado-cuenta-cliente/${id}/?search=${search}`,
    );
  }

  listarFacturasFormaPago(body) {
    return axios.post(`api/facturas/listar-facturas-cliente-formapago/`, body);
  }

  listarRecibosFormaPago(body) {
    return axios.post(
      `api/facturas-b/listar-facturas-cliente-formapago/`,
      body,
    );
  }

  importarDatosPDF(body) {
    return axios.post(`api/facturas/leer-factura/`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  obtenerPDF(id) {
    return axios.get(`api/facturas/obetener-archivo-pdf/${id}/`, {
      responseType: "blob",
    });
  }

  crearFacturaOrden({ dataSend }) {
    return axios.post(`api/facturas/crear-factura-de-orden/`, dataSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  crearFacturaOrdenNegro({ dataSend }) {
    return axios.post(`api/facturas-b/crear-factura-de-orden/`, dataSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // NOTAS CREDITO DEBITO
  /*
   * @returns  remitos (por rango de fecha) según el cliente y parámetro de búsqueda
   */
  filtrarNotasPorFecha(params) {
    const base_url = `api/notas/`;
    const url = `${base_url}${createNewUrl(params)}`;
    return axios.get(url);
  }

  // NOTAS CREDITO DEBITO LINEAS DE NOTA
  /*
   * @returns  objets client data and array of note lines
   */
  obtenerLineasDeNotaPorId(params) {
    const base_url = `api/notas/`;
    const url = `${base_url}${params}`;
    return axios.get(url);
  }

  // DESCARGAR EXCEL DE NOTAS CREDITO Y DEBITO
  /*
   * @returns
   */
  descargarExcelNotas(params) {
    const base_url = `api/notas/download-excel/`;
    const url = `${base_url}${createNewUrl(params)}`;
    console.log("--- ural", url);
    return axios.get(url);
  }

  crearAgregarRemitoAResumenAnticipado(dataSend) {
    return axios.post(`api/resumenes/generar-resumen-anticipado/`, dataSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
export default new FacturacionService();
