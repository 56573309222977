import axios from "./axios.config";

class OrdenesService {
  crearOrden(data) {
    return axios.post(`api/ordenCompra/crear-orden-compra/`, data);
  }

  listarOrdenes(filtro) {
    return axios.get(
      `api/ordenCompra/listar-orden-compra/?fecha_carga__gte=${filtro?.fecha_desde}&fecha_carga__lte=${filtro?.fecha_hasta}&search=${filtro?.search}&page=${filtro?.page}&cliente=${filtro?.ordCliente}&nor=${filtro?.ordOrden}&fecha=${filtro?.ordFecha}`,
    );
  }

  listarOrdenesNegro(filtro) {
    return axios.get(
      `api/ordenCompra-b/listar-orden-compra/?fecha_carga__gte=${filtro?.fecha_desde}&fecha_carga__lte=${filtro?.fecha_hasta}&search=${filtro?.search}&page=${filtro?.page}&cliente=${filtro?.ordCliente}&nor=${filtro?.ordOrden}&fecha=${filtro?.ordFecha}`,
    );
  }

  eliminarOrden(id) {
    return axios.delete(`api/ordenCompra/eliminar-orden-compra/${id}/`);
  }

  eliminarOrdenNegro(id) {
    return axios.delete(`api/ordenCompra-b/eliminar-orden-compra/${id}/`);
  }

  verOrden(id) {
    return axios.get(`api/ordenCompra/ver-orden-compra/${id}/`);
  }

  verOrdenNegro(id) {
    return axios.get(`api/ordenCompra-b/ver-orden-compra/${id}/`);
  }

  listarOrdenesPorCliente(id) {
    return axios.get(`api/ordenCompra/${id}/ver-ordenes-compra/`);
  }

  historicoPrecios(id, filtro) {
    return axios.get(
      `api/ordenCompra/historial-precios/${id}/?fm=${filtro?.fm}&fv=${filtro?.fv}`,
    );
  }

  historicoPreciosNegro(id, filtro) {
    return axios.get(
      `api/ordenCompra-b/historial-precios/${id}/?fm=${filtro?.fm}&fv=${filtro?.fv}`,
    );
  }

  editarOrden(id, data) {
    return axios.patch(`api/ordenCompra/editar-orden-compra/${id}/`, data);
  }

  listarOrdenesCliente(id, filtro) {
    return axios.get(
      `api/ordenCompra/listar-orden-compra-cliente/${id}/?search=${filtro?.search}&fecha_carga__gte=${filtro?.fecha_desde}&fecha_carga__lte=${filtro?.fecha_hasta}&fecha=${filtro?.ordFecha}`,
    );
  }

  listarOrdenesNegroCliente(id, filtro) {
    return axios.get(
      `api/ordenCompra-b/listar-orden-compra-cliente/${id}/?search=${filtro?.search}&fecha_carga__gte=${filtro?.fecha_desde}&fecha_carga__lte=${filtro?.fecha_hasta}&fecha=${filtro?.ordFecha}`,
    );
  }

  descargarExcel(filtro) {
    return axios.get(
      `api/ordenCompra/exportar-excel/?fecha_carga__gte=${filtro?.fecha_desde}&fecha_carga__lte=${filtro?.fecha_hasta}`,
      {
        responseType: "blob",
      },
    );
  }

  descargarExcelNegro(filtro) {
    return axios.get(
      `api/ordenCompra-b/exportar-excel/?fecha_carga__gte=${filtro?.fecha_desde}&fecha_carga__lte=${filtro?.fecha_hasta}`,
      {
        responseType: "blob",
      },
    );
  }

  // Cambiar Hormigon de Orden De Compra Anticipada
  cambiarHormigon(id, data) {
    return axios.patch(`api/ordenCompra/editar-orden-compra/${id}/`, data);
    }    
}

export default new OrdenesService();
