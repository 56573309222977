import React, { useState, useEffect, useContext } from "react";
import Protected from "protected";
import { useHistory } from "react-router-dom";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import useStyles from "./styles";
import RootContainer from "layout/rootContainer";
import Button from "@material-ui/core/Button";
import TableAgenda from "components/tableAgenda";
import TablaChica from "./agenda-split/tablaChica";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import "moment/locale/es";
import SearchBar from "components/Layout/searchBar/SearchBar";
import AgendaService from "../../services/agenda.service.js";
import rules from "constants/rules";
import { useForm } from "react-hook-form";
import formulaService from "services/formula.service";
import ClientesService from "services/clientes.service";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import CrearClienteNuevo from "./agenda-split/componentes/CrearClienteNuevo/index";
import Theme from "theme";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import CargarTurno from "./agenda-split/componentes/CargarTurno";
import { useLocation } from "hook/useLocation";
import ConfirmDialog from "components/ConfirmDialog";
import CheckboxCustom from "components/Layout/checkbox/checkboxCustom";
import UsuarioService from "services/usuario.service";
import { useQuery, useQueryClient } from "react-query";
import { LogIn } from "context/LogInContext";
import CustomSnackbar from "components/Layout/CustomSnackbars";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Agenda = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { logged } = useContext(LogIn);

  const [date, setDates] = useState(moment().format("YYYY-MM-DD"));
  const [DataAgenda, setDataAgenda] = useState([]);
  const [dataAgendaAux, setDataAgendaAux] = useState([]);
  const [cliente, setCliente] = useState({});
  const [clientes, setClientes] = useState();
  const [formulas, setFormulas] = useState();
  const [formula, setFormula] = useState();

  const [creacion, setCreacion] = useState(false);
  const [newClient, setNewClient] = useState(false);

  const [editarTurno, setEditarTurno] = useState(false);
  const [reasignar, setReasignar] = useState(false);
  const [turno, setTurno] = useState();

  const [acceso, setAcceso] = useState(false);
  const [verificando, setVerificando] = useState(true);
  const [cargando, setCargando] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [prov, setProv] = useState();

  const initialState = {
    nuevoCliente: {
      tipo_de_cliente: "",
      nombre: "",
      cuil_dni: "",
      num_telefono: "",
      provincia: "",
      direccion: "",
    },
    turno: {
      fecha_turno: turno?.fecha_turno,
      ciudad: turno?.ciudad,
      direccion_obra: turno?.direccion_obra,
      tipo_formula: turno?.tipo_formula,
      cantidad_volumen: turno?.cantidad_volumen,
      hora_salida: turno?.hora_salida
        ? moment(turno?.hora_salida, "HH:mm:ss").format("hh:mm")
        : "",
      contacto: turno?.contacto,
      datos_adicionales: turno?.datos_adicionales,
      presupuesto: turno?.presupuesto,
      bombeo: turno?.bombeo,
      cliente_id: turno?.cliente_id,
    },
  };

  const [turnoNuevo, setTurnoNuevo] = useState(initialState.turno);
  const [nuevoCliente, setNuevoCliente] = useState(initialState.nuevoCliente);

  //HOOOOOOOOOOOOOOOOKKKKKKKKKK FORMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    clearErrors,
    setError,
    errors,
  } = useForm({ turnoNuevo });

  const presupuesto = watch("presupuesto");
  const bombeo = watch("bombeo");

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  const obtenerTurnos = async (dia) => {
    try {
      const res = await AgendaService.getTurnos(dia);
      return res.data;
    } catch (error) {
      if (!verificando) {
        setSnackState({
          open: true,
          severity: "error",
          message: "Error al obtener Turnos",
        });
      }
      return error;
    }
  };

  const obtenerClientes = async () => {
    try {
      const res = await ClientesService.getAllClientes();
      if (res.status === 200) {
        setClientes(res.data);
      }
    } catch (error) {
      return error?.response?.data?.detail;
    }
  };

  const obtenerFormulas = async () => {
    try {
      const res = await formulaService.getFormulas();
      if (res.status === 200) {
        setFormulas(res.data);
      }
    } catch (error) {
      return error?.response?.data?.detail;
    }
  };

  //funcion que envia datos cuando cargas turno y cliente
  const onSubmit = async (turnoNuevo) => {
    let sendData = {
      turno: newClient
        ? {
            tipo_formula: formula,
            presupuesto: presupuesto,
            bombeo: bombeo,
            fecha_turno: turnoNuevo?.fecha_turno,
            ciudad: turnoNuevo?.ciudad,
            direccion_obra: turnoNuevo?.direccion_obra,
            cantidad_volumen: turnoNuevo?.cantidad_volumen,
            hora_salida: turnoNuevo?.hora_salida
              ? moment(turnoNuevo?.hora_salida, "HH:mm:ss").format("HH:mm")
              : "",
            contacto: turnoNuevo?.contacto,
            datos_adicionales: turnoNuevo?.datos_adicionales,
          }
        : {
            cliente_id: cliente?.id,
            tipo_formula: formula,
            presupuesto: presupuesto,
            bombeo: bombeo,
            fecha_turno: turnoNuevo?.fecha_turno,
            ciudad: turnoNuevo?.ciudad,
            direccion_obra: turnoNuevo?.direccion_obra,
            cantidad_volumen: turnoNuevo?.cantidad_volumen,
            hora_salida: turnoNuevo?.hora_salida
              ? moment(turnoNuevo?.hora_salida, "HH:mm:ss").format("HH:mm")
              : "",
            contacto: turnoNuevo?.contacto,
            datos_adicionales: turnoNuevo?.datos_adicionales,
          },

      cliente: newClient
        ? {
            ...nuevoCliente,
            nombre: turnoNuevo?.nombre,
            num_telefono: turnoNuevo?.num_telefono,
            direccion: turnoNuevo?.direccion,
            ciudad: turnoNuevo?.ciudad_cliente,
          }
        : {},
    };
    try {
      setCargando(true);
      const res = await AgendaService.cargarTurnoyCliente(sendData);
      if (res.status > 199 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: `Turno Agregado Correctamente`,
        });
        setCreacion(false);
        setDates(turnoNuevo.fecha_turno);
        queryClient.invalidateQueries("Turnos");
        setTurnoNuevo(initialState.turno);
        setNuevoCliente(initialState.nuevoCliente);
        setNewClient(false);
        setCliente();
        setFormula();
        obtenerClientes();
        reset();
        setCargando(false);
      } else {
        setCargando(false);
        setSnackState({
          open: true,
          severity: "error",
          message: `No se pudo cargar el Turno`,
        });
      }
    } catch (error) {
      setCargando(false);
      if (error?.response?.data?.Error?.cuil_dni) {
        setSnackState({
          open: true,
          severity: "error",
          message: error?.response?.data?.Error?.cuil_dni,
        });
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: `No se pudo cargar Turno`,
        });
      }
    }
  };

  //FUNCION PARA EDITAR TURNO
  const onSubmitEdicion = async (turnoNuevo) => {
    let sendData = {
      ...turnoNuevo,
      cliente_id: cliente.id,
      presupuesto: presupuesto,
      bombeo: bombeo,
      tipo_formula: formula,
      id: turno?.id,
    };
    try {
      setCargando(true);
      const res = await AgendaService.editTurno(sendData.id, sendData);
      if (res.status > 199 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: `Turno Modificado Correctamente`,
        });
        setCreacion(false);
        setEditarTurno(false);
        setDates(turnoNuevo.fecha_turno);
        setTurno();
        setTurnoNuevo(initialState.turno);
        setCliente();
        setFormula();
        reset();
        setCargando(false);
        queryClient.invalidateQueries("Turnos");
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: `No se pudo modificar el turno`,
        });
      }
    } catch (error) {}
  };

  //funcion para REASIGNAR turno
  const reasignarTurnoSubmit = async (turnoNuevo) => {
    let sendData = {
      ...turnoNuevo,
      id: turno?.id,
    };
    try {
      setCargando(true);
      const res = await AgendaService.reasignarTurnoAgenda(sendData);
      if (res.status > 199 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: `Turno Reasginado Correctamente`,
        });
        setDates(turnoNuevo.fecha_turno);
        queryClient.invalidateQueries("Turnos");
        setCreacion(false);
        setEditarTurno(false);
        setReasignar(false);
        setTurno();
        setTurnoNuevo(initialState.turno);
        setCliente();
        reset();
        setCargando(false);
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: `No se pudo modificar el turno`,
        });
      }
    } catch (error) {}
  };

  const cambiarEstado = async (data, string, hora) => {
    let id = data.id;
    let payload = {
      entrega: string,
      hora_entrega: hora ? hora : null,
    };
    try {
      const res = await AgendaService.putState(id, payload);
      if (res.status > 199 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: `Estado Modificado Correctamente`,
        });
        queryClient.invalidateQueries("Turnos");
        setTurno();
        setTurnoNuevo(initialState.turno);
        setCliente();
        reset();
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: `No se pudo modificar el turno`,
        });
      }
    } catch (error) {}
  };

  //funcion para ELIMINAR turno
  const eliminarTurno = async (data) => {
    let id = data.id;
    try {
      const res = await AgendaService.borrarTurno(id);
      if (res.status > 199 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: `Turno Cancelado Correctamente`,
        });
        queryClient.invalidateQueries("Turnos");
        setTurno();
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: `No se pudo cancelar el turno`,
        });
      }
    } catch (error) {}
  };

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // BUSQUEDA
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState("");

  const handleBusqueda = async (text) => {
    let search = text.toLowerCase();
    if (text.length > 0) {
      const result = dataAgendaAux.filter((turno) =>
        turno.cliente_id.nombre.toLowerCase().includes(search),
      );
      setDataAgenda(result);
    } else {
      queryClient.invalidateQueries("Turnos");
    }
  };

  const crearTurno = () => {
    if (turno) {
      setTurno();
      setTurnoNuevo(turno.initialState);
    }
    setCreacion(true);
  };

  //FUNCION CARGA DNICUIL Y SETEAR VARIABLE TIPO CLIENTE
  const handleChangeDni = (e) => {
    const regCUIT = RegExp(/^[0-9]{11}$/i);
    const regDNI = RegExp(/^[0-9]{6,8}$/i);
    if (regDNI || regCUIT) {
    }
    if (regDNI.test(e)) {
      clearErrors("cuil_dni");
      return "Particular";
    } else if (regCUIT.test(e)) {
      clearErrors("cuil_dni");
      return "Empresa";
    } else {
      setError("cuil_dni", { message: "Número Inválido" });
    }
  };

  // CUSTOM HOOK PARA TRAER LAS PROVINCIAS
  const { provincias } = useLocation({ prov, setValue });

  const Volver = () => {
    setCreacion(false);
    setEditarTurno(false);
    setReasignar(false);
    setNewClient(false);
    setCliente();
    setFormula();
    setTurno();
    setTurnoNuevo(initialState.turno);
    setNuevoCliente(initialState.nuevoCliente);
    reset();
  };

  const [asc, setAsc] = useState(false);
  const ordenarPor = (criterio) => {
    switch (criterio) {
      case "cantidad_volumen":
        if (asc) {
          //ascendente
          DataAgenda.sort((a, b) => a[criterio] - b[criterio]);
          setAsc(!asc);
        } else {
          //descendente
          DataAgenda.sort((a, b) => b[criterio] - a[criterio]);
          setAsc(!asc);
        }

        break;

      case "hora_salida":
        if (asc) {
          //ascendente
          DataAgenda.sort(
            (a, b) =>
              a[criterio].replace(/:/g, "") - b[criterio].replace(/:/g, ""),
          );

          setAsc(!asc);
        } else {
          //descendente
          DataAgenda.sort(
            (a, b) =>
              b[criterio].replace(/:/g, "") - a[criterio].replace(/:/g, ""),
          );

          setAsc(!asc);
        }
        break;

      case "cliente_id":
        if (asc) {
          //ascendente
          DataAgenda.sort(function (a, b) {
            if (
              a[criterio].nombre.toLowerCase() >
              b[criterio].nombre.toLowerCase()
            ) {
              return 1;
            }
            if (
              a[criterio].nombre.toLowerCase() <
              b[criterio].nombre.toLowerCase()
            ) {
              return -1;
            }
            return 0;
          });
          setAsc(!asc);
        } else {
          //descendente
          DataAgenda.sort(function (a, b) {
            if (
              a[criterio].nombre.toLowerCase() >
              b[criterio].nombre.toLowerCase()
            ) {
              return -1;
            }
            if (
              a[criterio].nombre.toLowerCase() <
              b[criterio].nombre.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          setAsc(!asc);
        }
        break;

      case "direccion_obra":
        if (asc) {
          //ascendente
          DataAgenda.sort(function (a, b) {
            if (a[criterio].toLowerCase() > b[criterio].toLowerCase()) {
              return 1;
            }
            if (a[criterio].toLowerCase() < b[criterio].toLowerCase()) {
              return -1;
            }
            return 0;
          });
          setAsc(!asc);
        } else {
          //descendente
          DataAgenda.sort(function (a, b) {
            if (a[criterio].toLowerCase() > b[criterio].toLowerCase()) {
              return -1;
            }
            if (a[criterio].toLowerCase() < b[criterio].toLowerCase()) {
              return 1;
            }
            return 0;
          });
          setAsc(!asc);
        }
        break;
      default:
    }
  };

  // Verificar Acceso a modulo Formula
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await UsuarioService.checkAccess({ modulo: "turnos" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "agenda" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "agenda" });
    }
  };

  const sumarCantidades = () => {
    if (DataAgenda.length) {
      let total = DataAgenda.filter(
        (item) =>
          item.entrega !== "Suspendido" && item.entrega !== "Reasignado",
      );
      if (total.length) {
        let cantidades = total.map((item) => item.cantidad_volumen);
        let suma = cantidades.reduce((a, b) => a + b, 0);
        return suma;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data: ArrayTurnos, isLoading } = useQuery(
    ["Turnos", date],
    () => obtenerTurnos(date),
    {
      onSuccess: (data) => {
        if (!searchText?.length > 0) {
          setDataAgenda(data);
          setDataAgendaAux(data);
        }
      },
      onError: (error) => {},
    },
  );

  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    obtenerClientes();
    obtenerFormulas();
  }, []);

  useEffect(() => {
    setTurnoNuevo(turno);
    setCliente(turno?.cliente_id);
    setFormula(turno?.tipo_formula);
  }, [turno]);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{
              marginTop: "15rem",
            }}
          >
            <CircularProgress
              style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            {acceso && (
              <Grid
                style={{
                  width: "100%",
                  height: window.innerHeight - 130,
                }}
              >
                {!creacion ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.textFieldContainer}
                    style={{
                      marginTop: "2rem",
                      marginBottom: "5rem",
                    }}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                    >
                      <Typography
                        style={{
                          color: "#433B3B",
                          font: "Roboto",
                          fontWeight: 700,
                          margin: "0.5rem 1rem",
                        }}
                      >
                        Seleccionar Día:
                      </Typography>

                      <TextField
                        size="small"
                        variant="outlined"
                        InputLabelProps={{ shrink: true, required: true }}
                        type="date"
                        onChange={(e) => setDates(e.target.value)}
                        value={date}
                        style={{ maxWidth: "15rem", margin: "0.5rem" }}
                      />

                      <Grid style={{ marginLeft: "0.5rem" }}>
                        <SearchBar
                          placeholder="Nombre del Cliente"
                          onChange={(e) => handleBusqueda(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#001176",
                          margin: "0.5rem 0.3rem",
                          textTransform: "capitalize",
                        }}
                        onClick={() =>
                          setDates(
                            moment(date)
                              .subtract(1, "days")
                              .format("YYYY-MM-DD"),
                          )
                        }
                      >
                        Día Anterior
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#001176",
                          margin: "0.5rem 0.3rem",
                          textTransform: "capitalize",
                        }}
                        onClick={() =>
                          setDates(
                            moment(date).add(1, "days").format("YYYY-MM-DD"),
                          )
                        }
                      >
                        Día Siguiente
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#001176",
                          margin: "0 0.3rem",
                          textTransform: "capitalize",
                        }}
                        onClick={() => crearTurno()}
                      >
                        Cargar Turno
                      </Button>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ marginTop: "1rem" }}
                      xl={6}
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={12}
                      >
                        <Grid
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "100%",
                            backgroundColor: "#aefa43",
                            margin: "0 0.5rem",
                          }}
                        ></Grid>
                        <Typography>Para Bombeo</Typography>
                      </Grid>

                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-start"
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                      >
                        <Grid
                          item
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "100%",
                            backgroundColor: "#ffe924",
                            marginRight: "0.5rem",
                          }}
                        ></Grid>
                        <Typography item>Reasignados/Suspendidos</Typography>
                      </Grid>
                    </Grid>

                    <TableAgenda
                      DataAgenda={DataAgenda}
                      setDataAgenda={setDataAgenda}
                      date={date}
                      moment={moment}
                      setCreacion={setCreacion}
                      turno={turno}
                      setTurno={setTurno}
                      setEditarTurno={setEditarTurno}
                      setReasignar={setReasignar}
                      obtenerTurnos={obtenerTurnos}
                      ordenarPor={ordenarPor}
                      cambiarEstado={cambiarEstado}
                      eliminarTurno={eliminarTurno}
                      isLoading={isLoading}
                    />

                    {!isLoading && (
                      <Grid
                        container
                        style={{
                          backgroundColor: "#E5E5E5",
                          color: "#433B3B",
                          width: "auto",
                          maxHeight: "100%",
                          marginRight: "2rem",
                          padding: "0.5rem 1rem",
                          borderRadius: "20px",
                          marginBottom: "5rem",
                        }}
                      >
                        <Typography style={{ fontSize: "18px" }}>
                          Cantidad de M³ Agendados:
                          <span style={{ fontSize: "20px", fontWeight: 700 }}>
                            {" "}
                            {sumarCantidades()}{" "}
                          </span>
                          <span style={{ fontSize: "10px", fontWeight: 700 }}>
                            (no se suman las cantidades de los turnos
                            Suspendidos ni Reasignados)
                          </span>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ) : formulas?.length > 0 ? (
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="space-between"
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-start"
                      style={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        marginTop: "3rem",
                      }}
                      xl={7}
                      lg={7}
                      md={7}
                      sm={12}
                      xs={12}
                      item
                    >
                      <SearchBar
                        item
                        xl={7}
                        lg={7}
                        md={7}
                        sm={12}
                        xs={12}
                        placeholder="Ingrese Nombre del Cliente"
                        onChange={(e) => handleBusqueda(e.target.value)}
                      />

                      {isLoading ? (
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            width: "5vw",
                            height: "150px",
                            margin: "0 auto",
                          }}
                        >
                          <CircularProgress />
                        </Grid>
                      ) : (
                        <>
                          <TablaChica
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            DataAgenda={DataAgenda}
                            setDataAgenda={setDataAgenda}
                            date={date}
                            moment={moment}
                            turno={turno}
                            setTurno={setTurno}
                            ordenarPor={ordenarPor}
                          />

                          <Grid
                            container
                            style={{
                              backgroundColor: "#E5E5E5",
                              color: "#433B3B",
                              width: "auto",
                              maxHeight: "100%",
                              margin: "0 2rem 2rem 0",
                              padding: "0.5rem 1rem",
                              borderRadius: "20px",
                            }}
                          >
                            <Typography style={{ fontSize: "18px" }}>
                              Cantidad de M³ Agendados:
                              <span
                                style={{ fontSize: "20px", fontWeight: 700 }}
                              >
                                {" "}
                                {sumarCantidades()}{" "}
                              </span>
                              <span
                                style={{ fontSize: "10px", fontWeight: 700 }}
                              >
                                (no se suman las cantidades de los turnos
                                Suspendidos ni Reasignados)
                              </span>
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    {/* FORMULARIO CREAR TURNO */}
                    <Grid
                      container
                      direction="column"
                      style={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        backgroundColor: "white",
                        minHeight: "89vh",
                        maxHeight: "200vh",
                        overflowY: "auto",
                      }}
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      item
                    >
                      {!editarTurno && (
                        <Grid
                          item
                          container
                          style={{
                            backgroundColor: Theme.palette.primary.main,
                            padding: "0.5rem",
                          }}
                        >
                          <Button
                            style={
                              newClient ? { color: "grey" } : { color: "white" }
                            }
                            onClick={() => setNewClient(false)}
                          >
                            CLIENTE EXISTENTE
                          </Button>
                          <Button
                            style={
                              !newClient
                                ? { color: "grey" }
                                : { color: "white" }
                            }
                            onClick={() => setNewClient(true)}
                          >
                            CLIENTE NUEVO
                          </Button>
                        </Grid>
                      )}

                      <form
                        item
                        container
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        style={{
                          padding: "1rem 3rem",
                        }}
                      >
                        {newClient && (
                          <CrearClienteNuevo
                            control={control}
                            rules={rules}
                            classes={classes}
                            provincias={provincias}
                            handleChangeDni={handleChangeDni}
                            nuevoCliente={nuevoCliente}
                            setNuevoCliente={setNuevoCliente}
                            clearErrors={clearErrors}
                            setValue={setValue}
                          />
                        )}

                        <CargarTurno
                          editarTurno={editarTurno}
                          reasignar={reasignar}
                          control={control}
                          rules={rules}
                          classes={classes}
                          moment={moment}
                          newClient={newClient}
                          clientes={clientes}
                          formulas={formulas}
                          NumberFormatCustom={NumberFormatCustom}
                          setDates={setDates}
                          turnoNuevo={turnoNuevo}
                          cliente={cliente}
                          setCliente={setCliente}
                          setFormula={setFormula}
                          clearErrors={clearErrors}
                          setValue={setValue}
                          errors={errors}
                          reset={reset}
                          formula={formula}
                        />

                        <Grid
                          container
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          direction="row"
                          justifyContent={
                            reasignar ? "flex-end" : "space-between"
                          }
                          alignItems="center"
                          style={{
                            padding: "0 1rem",
                            marginTop: "2rem",
                            marginBottom: "5rem",
                            paddingRight: "12rem",
                          }}
                        >
                          {!reasignar && (
                            <CheckboxCustom
                              name="presupuesto"
                              color="primary"
                              control={control}
                              rules={rules.generic}
                              label={"Presupuesto"}
                              defaultChecked={turno?.presupuesto ? true : false}
                              defaultValue={turno?.presupuesto ? true : false}
                            />
                          )}

                          {!reasignar && (
                            <CheckboxCustom
                              name="bombeo"
                              color="primary"
                              control={control}
                              rules={rules.generic}
                              label={"Para Bombeo"}
                              defaultChecked={turno?.bombeo ? true : false}
                              defaultValue={turno?.bombeo ? true : false}
                            />
                          )}

                          <Grid
                            justifyContent="flex-end"
                            style={{ marginTop: "1rem" }}
                          >
                            <Button
                              color="primary"
                              variant="outlined"
                              style={{
                                marginRight: "0.5rem",
                                marginBottom: "0.5rem",
                              }}
                              onClick={() =>
                                setConfirmDialog({
                                  title: `No se guardarán los datos cargados del cliente`,
                                  isOpen: true,
                                  onConfirm: () => {
                                    Volver();
                                  },
                                })
                              }
                            >
                              Volver
                            </Button>

                            {editarTurno ? (
                              <ButtonLoading
                                style={{
                                  marginBottom: "0.5rem",
                                }}
                                onClick={
                                  reasignar
                                    ? handleSubmit((turnoNuevo) =>
                                        reasignarTurnoSubmit(turnoNuevo),
                                      )
                                    : handleSubmit((turnoNuevo) =>
                                        onSubmitEdicion(turnoNuevo),
                                      )
                                }
                                disabled={cargando ? true : false}
                                isLoading={cargando}
                              >
                                Confirmar
                              </ButtonLoading>
                            ) : (
                              <ButtonLoading
                                style={{
                                  marginBottom: "0.5rem",
                                }}
                                onClick={handleSubmit((turnoNuevo) =>
                                  onSubmit(turnoNuevo),
                                )}
                                disabled={cargando ? true : false}
                                isLoading={cargando}
                              >
                                Confirmar
                              </ButtonLoading>
                            )}
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    style={{
                      marginTop: "15rem",
                    }}
                  >
                    <CircularProgress style={{ marginRight: "1rem" }} />
                    <Typography variant="h5">Cargando Formularios</Typography>
                  </Grid>
                )}
              </Grid>
            )}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </>
        )}

        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};
export default Agenda;
