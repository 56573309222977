import { useContext } from "react";
import { Grid } from "@material-ui/core";

import { ToggleDrawerContext } from "context/ToggleDrawerContext";
const RootContainer = (props) => {
  const { drawer } = useContext(ToggleDrawerContext);

  const calcularDrawer = () => {
    const calWidth = window.innerWidth;
    if (calWidth > 768 && drawer) {
      return true;
    }
    return false;
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="flex-start"
      style={{
        minHeight: "100%",
        backgroundColor: "#F0F0F0",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingLeft: calcularDrawer() ? "15rem" : "4rem",
      }}
    >
      {props.children}
    </Grid>
  );
};
export default RootContainer;
