import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

export default function InputNumber({
  name,
  label,
  defaultValue,
  type,
  rules,
  control,
  onChange,
  ...props
}) {
  const NaNToNull = (value) => {
    if (isNaN(value)) {
      return null;
    } else {
      return value;
    }
  };

  const onChangeFunction = (e, value) => {
    if (onChange) {
      onChange(e, NaNToNull(value));
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          size="small"
          fullWidth
          type="number"
          inputMode="numeric"
          id={name}
          name={name}
          label={label}
          variant="outlined"
          error={!!error}
          value={value ?? ""}
          helperText={error ? error.message : null}
          onChange={(e) => {
            onChange(e?.target?.value);
            onChangeFunction(e, e?.target?.valueAsNumber);
          }}
          {...props}
        />
      )}
    />
  );
}
