/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  MenuItem,
  Typography,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import Theme from "theme";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

// Hook
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useGetFormulas } from "hook/useGetFormulas";
import { useGetAllClientes } from "hook/useGetAllClientes";

// Services
import RemitosService from "services/remitos.service";
import remitosService from "services/remitos.service";

// Components
import Protected from "protected";
import RootContainerWhite from "layout/rootContainerWhite";
import InputField from "components/Layout/inputCustom/inputField";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import ConfirmDialog from "components/ConfirmDialog";
import SelectCustom from "components/Layout/select/selectCustom";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ModalAsociarTurno from "components/remitos/modalAsociarTurno";
import CheckboxCustom from "components/Layout/checkbox/checkboxCustom";
import NewAutocomplete from "components/inputs/newAutocomplete";

// Constants
import rules from "constants/rules";
import dayjs from "dayjs";
import { Alert, Stack } from "@mui/material";
import { useOrdenesByClientId } from "screens/ordenes/hook";
import NewSelect from "components/inputs/newSelect";

const CrearRemito = () => {
  const history = useHistory();
  const datosRemito = history.location?.state?.remito
    ? history.location.state.remito
    : null;

  const edicion = history.location?.state?.edicion
    ? history.location.state.edicion
    : false;

  // ESTADOS
  const [modalAsociarTurnoVisibility, setModalAsociarTurnoVisibility] =
    useState(false);
  const [turnoSeleccionado, setTurnoSeleccionado] = useState(
    datosRemito ? datosRemito?.turno : [],
  );

  // ESTADO PARA LISTA DE ORDENES
  const [ordenesDelCliente, setOrdenesDelCliente] = useState([]);

  // ESTADO PARA LISTA DE RESUMENES SEGUN UNA ORDEN
  const [resumenesDeOrden, setResumenesDeOrden] = useState(
    datosRemito?.resumen_asociado ?? [],
  );

  // ESTADO PARA LISTA DE FORMULAS SEGUN LA ORDEN
  const [formulasDeOrden, setFormulasDeOrden] = useState([]);

  // ESTADO PARA MANEJAR EL ALERT
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("default");

  // Estado para controlar filtro de cliente por nombre o dni/cuit
  const [filters, setFilters] = useState();

  const [errorNroRemitoExistente, setErrorNroRemitoExistente] = useState(false);
  const [errorNroRemitoExistenteMessage, setErrorNroRemitoExistenteMessage] =
    useState("");
  const [cargando, setCargando] = useState(false);
  const dia = dayjs().subtract(1, "day").format("YYYY-MM-DD");

  // INICIO DECLARACIONES DE LIBRERIA ENCARGADA DE VALIDACIONES
  const { handleSubmit, control, setValue, watch, reset, resetField } = useForm(
    {
      defaultValues: {
        cliente: datosRemito && {
          id: datosRemito?.cliente?.id,
          nombre: datosRemito?.cliente?.nombre,
          cuil_dni: datosRemito?.cliente?.cuil_dni,
        },
        cliente_id: datosRemito?.cliente_id ?? datosRemito?.cliente?.id,
        num_remito: datosRemito?.nro_remito ?? "",
        planta: datosRemito?.planta ?? "",
        fecha: datosRemito?.fecha ?? dia,
        nro_factura: datosRemito?.nro_factura ?? "",
        pagado: datosRemito?.condicion === "Pagado" ? true : false,
        direccion_entrega: datosRemito?.direccion_entrega ?? "",
        ciudad: datosRemito?.ciudad ?? "",
        patente: datosRemito?.patente ?? "",
        obra: datosRemito?.obra ?? "",
        observaciones: datosRemito?.observaciones_remito ?? "",
        turno_asociado: datosRemito?.turno_asociado ?? "",
        nombre_chofer: datosRemito?.apynom_chofer ?? "",
        tipo_formula: datosRemito?.formula_id ?? "",
        formula_id: datosRemito?.formula_id ?? "",
        observacion_formula: datosRemito?.observacion_formula ?? "",
        cantidad_formula: datosRemito?.cantidad_mc ?? "",
        presupuesto: datosRemito?.presupuesto ?? false,
        num_orden: datosRemito?.num_orden ?? "",
        orden_id: datosRemito?.orden_id ?? "",
        resumen_id: "",
      },
    },
  );

  const datosSeleccionadosRemito = {
    idCliente: watch("cliente_id"),
    fechaRemito: watch("fecha"),
    numero_remito: watch("num_remito"),
  };

  const checkedPagado = watch("pagado");
  const presupuesto = watch("presupuesto");

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // FUNCION PARA VALIDAR EDICION DE CLIENTE
  const validarEdicion = () => {
    let salida = false;
    if(edicion && datosRemito?.resumen_id){ 
      salida = true       
    }else{
      salida = datosRemito?.orden_id ? true : false;
    }
    return salida
  }
  // ABRIR MODAL DE ASOCIAR TURNO
  const asociarTurno = () => {
    setModalAsociarTurnoVisibility(true);
  };

  // CERRAR MODAL DE ASOCIAR TURNO
  const handleCloseModalAsociarTurno = () => {
    setModalAsociarTurnoVisibility(false);
  };

  // FUNCION QUE REDIRIGE A LA PANTALLA ANTERIOR VISITADA
  const Volver = () => {
    history.goBack();
  };

  // FUNCION ENVIAR DATOS AL BACK
  const onSubmit = async (data) => {
    // VALIDO CAMPOS OBLIGATORIOS
    if (!validarCampos()) {
      let dataSend = {
        agenda_id:
          turnoSeleccionado?.fecha_turno ===
          datosSeleccionadosRemito?.fechaRemito
            ? turnoSeleccionado?.id
            : null,
        fecha: data && data?.fecha,
        direccion_entrega: data && data?.direccion_entrega,
        planta: data && data?.planta,
        observaciones_remito: data && data?.observaciones,
        observacion_formula: data && data?.observacion_formula,
        ciudad: data && data?.ciudad,
        patente: data && data?.patente,
        obra: data && data?.obra,
        apynom_chofer: data && data?.nombre_chofer,
        cantidad_mc: data && data?.cantidad_formula,
        condicion:
          data?.pagado === "Pagado" || data?.pagado === true
            ? "Pagado"
            : "A Cobrar",
        cliente_id: data && data?.cliente_id,
        nro_remito: data && data?.num_remito,
        nro_factura: data?.nro_factura ? data.nro_factura : null,
        formula_id: data && data?.formula_id,
        presupuesto: data && data?.presupuesto,
        cambiar: datosRemito && data?.presupuesto !== datosRemito?.presupuesto,
        orden: data?.orden_id,
        resumen_id: data?.resumen_id,
      };
      let endpoint = "";
      let type = "";
      if (edicion) {
        type = "editado";
        endpoint = RemitosService.editRemito(datosRemito?.id, dataSend);
      } else {
        type = "creado";
        endpoint = RemitosService.addRemito(dataSend);
      }
      try {
        setCargando(true);
        const res = await endpoint;
        if (res.status > 199 && res.status < 300) {
          setCargando(false);
          setSnackState({
            open: true,
            severity: "success",
            message: `Remito ${type} correctamente`,
          });
          reset();
          setTurnoSeleccionado();
          setTimeout(() => {
            setOrdenesDelCliente([]);
            setResumenesDeOrden([]);
            setFormulasDeOrden([]);
            resetField("orden_id", "");
            setAlertMessage("");
            setOpenAlert(false);
          }, 1500);
        } else {
          setCargando(false);
          setSnackState({
            open: true,
            severity: "error",
            message: `Error en la Edición/Creación de Remito`,
          });
        }
      } catch (error) {
        setCargando(false);
        if (error?.response?.data?.nro_remito) {
          setErrorNroRemitoExistente(true);
          setErrorNroRemitoExistenteMessage(
            error?.response?.data?.nro_remito[0],
          );
        } else {
          setSnackState({
            open: true,
            severity: "error",
            message: "Error Servidor al Crear Remito",
          });
        }
      }
      if(edicion){
        setTimeout(() => {
          Volver()
        }, 1600)
      }
    }
  };  

  // VALIDO CAMPOS OBLIGATORIO CLIENTE Y REMITO
  const validarCampos = () => {
    if (!datosSeleccionadosRemito?.numero_remito) {
      setErrorNroRemitoExistente(true);
      setErrorNroRemitoExistenteMessage("Este campo es requerido");
    } else {
      setErrorNroRemitoExistente(false);
    }
    if (
      !datosSeleccionadosRemito?.idCliente ||
      !datosSeleccionadosRemito?.numero_remito
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Query para obtener todas las formulas
  const { data: formulas } = useGetFormulas();

  // Query para obtener todos los clientes
  const { data: clientes, isLoading: cargandoClientes } =
    useGetAllClientes(filters);

  //LO MOVI DEL MODAL, HAY QUE SEGUIR
  const [turnos, setTurnos] = useState([]);

  const obtenerTurnos = async () => {
    let dataSend = {
      cliente_id: datosSeleccionadosRemito?.idCliente ?? null,
      fecha: datosSeleccionadosRemito?.fechaRemito,
    };
    try {
      const res = await remitosService.getTurnoCliente(dataSend);
      if (res.status === 200) {
        setTurnos(res.data);
      }
    } catch (error) {
      return error;
    }
  };

  // OnChange de Autocomplete de Cliente
  const onChangeClient = (event, newInputValue, reason) => {
    if (reason === "clear") {
      setOrdenesDelCliente([]);
      setResumenesDeOrden([]);
      setFormulasDeOrden([]);
      resetField("orden_id", "");
      setAlertMessage("");
      setOpenAlert(false);
    }
    setFilters(newInputValue);
  };

  // Succes Query
  const onSuccess = (data) => {
    // Seteo Las Ordenes del Cliente Seleccionado
    setOrdenesDelCliente(data);
    // Si existe orden_id seteo formulas de esa orden
    datosRemito?.orden_id &&
      setFormulasDeOrden(
        data?.filter((orden) => orden.id === datosRemito?.orden_id)[0]
          ?.detalle_orden,
      );
  };

  // Query para traer todas las ordenes de un cliente
  // éstas ordenes pueden, no tener resumen asociado, o bien tener resumen asociado pero no facturados.
  const { isLoading: loadingDataOrden } = useOrdenesByClientId({
    idCliente: watch("cliente_id"),
    onSuccess,
  });

  useEffect(() => {
    if (formulasDeOrden?.length) {
      let filtrado = formulasDeOrden?.filter(
        (formula) => formula.formula === watch("tipo_formula"),
      );
      setAlertMessage(
        filtrado[0]?.cant_restante < 0
          ? `La Orden posee un excedente de ${Math.abs(
              filtrado[0]?.cant_restante,
            )} mts3 de ${filtrado[0]?.formula__nombre}`
          : `La Orden posee un restante de ${Math.abs(
              filtrado[0]?.cant_restante,
            )} mts3 de ${filtrado[0]?.formula__nombre}`,
      );
      setOpenAlert(true);
    }
  }, [watch("tipo_formula")]);

  useEffect(() => {
    obtenerTurnos();
  }, [watch("cliente_id"), watch("fecha")]);

  useEffect(() => {
    setErrorNroRemitoExistente(false);
    setErrorNroRemitoExistenteMessage("");
  }, [datosSeleccionadosRemito?.numero_remito]);

  return (
    <Protected>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RootContainerWhite>
          {formulas?.length > 0 ? (
            <>
              <Grid
                container
                item
                style={{
                  boxShadow: "0px 5px 5px rgba(0, 0, 0, .2)",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  borderRadius: 10,
                  backgroundColor: "#ffffff",
                  margin: "2vw",
                  marginBottom: "5rem",
                }}
              >
                {/* TITULO DE LA VISTA  */}
                <Grid
                  container
                  alignContent="center"
                  justifyContent="center"
                  item
                  style={{
                    height: "3rem",
                    backgroundColor: Theme.palette.primary.main,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Typography variant="h5" style={{ color: "#ffffff" }}>
                    {edicion ? "Modificar Remito" : "Crear Remito"}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ margin: "0 2rem", marginTop: "2rem" }}
                >
                  <Grid
                    container
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "0.5rem" }}
                  >
                    <Grid
                      container
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Cliente:*
                      </Typography>
                      <NewAutocomplete
                        control={control}
                        rules={rules.generic}
                        name="cliente"
                        placeholder="Nombre o CUIT"
                        size="small"
                        fullWidth
                        freeSolo
                        clearOnBlur
                        disabled={validarEdicion()}
                        options={clientes}
                        loadingText="Cargando..."
                        loading={cargandoClientes}
                        noOptionsText="No Hay Clientes"
                        getOptionLabel={(option) =>
                          option?.nombre?.toUpperCase() ?? ""
                        }
                        onInputChange={(event, newInputValue, reason) => {
                          onChangeClient(event, newInputValue, reason);
                        }}
                        onChange={(e, o) => {
                          setValue("cliente_id", o?.id);
                        }}
                        InputLabelProps={{ required: true }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent={"center"}
                      alignItems="center"
                      style={{ marginTop: "1rem" }}
                    >
                      <Typography>{watch("cliente")?.cuil_dni}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Grid
                      container
                      item
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        N° Remito:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="num_remito"
                        label="Nro Remito*"
                        variant="outlined"
                        size="small"
                        type="text"
                        control={control}
                        rules={rules.generic}
                        error={errorNroRemitoExistente}
                        helperText={errorNroRemitoExistenteMessage}
                        InputProps={{
                          autoComplete: "off",
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Planta:*
                      </Typography>
                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        defaultValue={""}
                        name="planta"
                        label="Planta*"
                        size="small"
                      >
                        <MenuItem value={1}>Planta 1</MenuItem>
                        <MenuItem value={2}>Planta 2</MenuItem>
                      </SelectCustom>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Fecha:*
                      </Typography>
                      <InputField
                        name="fecha"
                        label="Fecha"
                        control={control}
                        rules={rules.generic}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          max: moment().format("YYYY-MM-DD"),
                        }}
                        type="date"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ margin: "0 2rem" }}
                >
                  {/* Comentario Input de Factura */}
                  {/* <Grid
                    container
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignItems="center"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      N° Factura:
                    </Typography>
                    <InputField
                      fullWidth
                      name="nro_factura"
                      label="Nro Factura"
                      variant="outlined"
                      size="small"
                      type="number"
                      control={control}
                      rules={rules.genericNoRequered}
                      autoComplete="new-password"
                    />
                  </Grid> */}
                  {/* FIN Input Factura */}

                  {!edicion && (
                    <>
                      {/* AGREGA SELECT AUTOCOMPLETE PARA BUSCAR ORDENES DEL CLIENTE */}
                      <Grid
                        container
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        justifyContent="flex-start"
                        style={{ padding: "0.5rem" }}
                        alignItems="center"
                      >
                        <Typography style={{ marginBottom: "0.5rem" }}>
                          Orden:
                        </Typography>
                        {datosRemito?.orden_id ? (
                          <InputField
                            fullWidth
                            name="num_orden"
                            disabled
                            control={control}
                            rules={rules.genericNoRequered}
                          />
                        ) : (
                          <NewAutocomplete
                            control={control}
                            rules={rules.genericNoRequered}
                            name="num_orden"
                            placeholder="Ingrese número"
                            size="small"
                            autoSelect
                            style={{
                              width: "100%",
                            }}
                            clearOnBlur
                            options={ordenesDelCliente}
                            getOptionLabel={(option) => option.num_orden}
                            loading={loadingDataOrden}
                            onChange={(e, o) => {
                              setValue("orden_id", o?.id);
                              setFormulasDeOrden(o?.detalle_orden);
                              setResumenesDeOrden(o?.resumen_asociado);
                              setAlertMessage("");
                              setOpenAlert(false);
                            }}
                          />
                        )}
                      </Grid>
                      {/* FIN SELECT AUTOCOMPLETE PARA BUSCAR ORDENES DEL CLIENTE */}

                      {/* AGREGA SELECT DE RESUMEN SEGUN LA ORDEN SELECCIONADA */}
                      <Grid
                        container
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        justifyContent="flex-start"
                        style={{ padding: "0.5rem" }}
                        alignItems="center"
                      >
                        <Typography style={{ marginBottom: "0.5rem" }}>
                          Resumen:
                        </Typography>
                        <NewSelect
                          control={control}
                          rules={rules.genericNoRequered}
                          name="resumen_id"
                          options={resumenesDeOrden}
                        />
                      </Grid>
                      {/* FIN SELECT RESUMEN */}
                    </>
                  )}

                  <Grid
                    container
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    style={{ padding: "0.5rem" }}
                    alignContent="flex-end"
                    justifyContent="center"
                  >
                    <CheckboxCustom
                      name="pagado"
                      label="Pagado"
                      color="primary"
                      control={control}
                      labelPlacement="start"
                      checked={checkedPagado ? true : false}
                      style={{ marginBottom: "1rem" }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ margin: "0 2rem" }}
                >
                  <Grid
                    container
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignItems="center"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Obra:*
                    </Typography>
                    <InputField
                      fullWidth
                      name="obra"
                      label="Ingrese Nombre Obra"
                      variant="outlined"
                      size="small"
                      type="text"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      control={control}
                      rules={rules.generic}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignItems="center"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Dirección de Entrega:*
                    </Typography>
                    <InputField
                      fullWidth
                      name="direccion_entrega"
                      label="Ingrese Calle y Número"
                      variant="outlined"
                      size="small"
                      type="text"
                      autoComplete="new-password"
                      control={control}
                      rules={rules.generic}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    alignContent="center"
                    style={{ padding: "0.5rem" }}
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Ciudad:*
                    </Typography>
                    <InputField
                      fullWidth
                      name="ciudad"
                      label="Ciudad"
                      variant="outlined"
                      size="small"
                      type="text"
                      autoComplete="new-password"
                      defaultValue={""}
                      control={control}
                      rules={rules.generic}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ margin: "0 2rem" }}
                >
                  <Grid
                    container
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignItems="center"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Observaciones:
                    </Typography>
                    <InputField
                      fullWidth
                      name="observaciones"
                      label="Ingrese una Observación"
                      variant="outlined"
                      size="small"
                      type="text"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      control={control}
                      rules={rules.observacion}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignItems="flex-start"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Asociar Turno:
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={
                        !(
                          datosSeleccionadosRemito?.idCliente &&
                          datosSeleccionadosRemito?.fechaRemito
                        )
                          ? true
                          : false
                      }
                      startIcon={<AddIcon />}
                      onClick={() => asociarTurno()}
                    >
                      Asociar Turno
                    </Button>
                  </Grid>
                  {turnoSeleccionado?.fecha_turno ===
                    datosSeleccionadosRemito?.fechaRemito && (
                    <Grid
                      container
                      direction="column"
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem", backgroundColor: "#F3F3F3" }}
                      alignItems="flex-start"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Turno Seleccionado
                      </Typography>
                      <Grid
                        item
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item container direction="row">
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 700,
                              marginRight: "0.5rem",
                            }}
                          >
                            Día:{" "}
                          </Typography>
                          <Typography>
                            {moment(turnoSeleccionado?.fecha_turno).format(
                              "DD-MM-YYYY",
                            ) ?? "-"}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 700,
                              marginLeft: "1rem",
                              marginRight: "0.5rem",
                            }}
                          >
                            {" "}
                            Hora:{" "}
                          </Typography>
                          <Typography>
                            {turnoSeleccionado?.hora_salida
                              ? moment(
                                  turnoSeleccionado?.hora_salida,
                                  "HH:mm:ss",
                                ).format("HH:mm")
                              : "-"}
                          </Typography>
                        </Grid>

                        <Grid item container direction="row">
                          <Typography
                            style={{
                              fontSize: "15px",
                              fontWeight: 700,
                              marginRight: "0.5rem",
                            }}
                          >
                            Tipo de Hormigón:{" "}
                          </Typography>
                          <Typography>
                            {turnoSeleccionado?.tipo_formula}(
                            {turnoSeleccionado?.cantidad_volumen}Mts3)
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ margin: "0 2rem" }}
                >
                  <Grid
                    container
                    item
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignItems="center"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Nombre y Apellido del Chofer:*
                    </Typography>
                    <InputField
                      fullWidth
                      name="nombre_chofer"
                      label="Nombre y Apellido del Chofer"
                      variant="outlined"
                      size="small"
                      type="text"
                      autoComplete="new-password"
                      control={control}
                      rules={rules.nombre}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xl={2}
                    lg={2}
                    md={6}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignItems="center"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Patente:*
                    </Typography>
                    <InputField
                      fullWidth
                      name="patente"
                      label="Ingrese Patente"
                      variant="outlined"
                      size="small"
                      type="text"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      control={control}
                      rules={rules.patente}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0.5rem" }}>
                    <Collapse in={openAlert}>
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert
                          variant="outlined"
                          severity="warning"
                          size="small"
                        >
                          {`${alertMessage}`}
                        </Alert>
                      </Stack>
                    </Collapse>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ margin: "1rem 2.5rem" }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead style={{ backgroundColor: "#F3F3F3" }}>
                        <TableRow>
                          <TableCell align="left">Tipo de Hormigón *</TableCell>
                          <TableCell align="left">Observación</TableCell>
                          <TableCell align="left">
                            Cantidad&nbsp;(Mts3) *
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ minWidth: 190 }}
                          >
                            <SelectCustom
                              name="tipo_formula"
                              control={control}
                              size="small"
                              rules={rules.generic}
                              funcionAdicional={(e) => {
                                setValue("formula_id", e);
                              }}
                            >
                              {formulasDeOrden?.length > 0
                                ? formulasDeOrden?.map(
                                    (item) =>
                                      item?.concepto === null && (
                                        <MenuItem
                                          value={item.formula}
                                          key={item.formula}
                                        >
                                          {item.formula__nombre}
                                        </MenuItem>
                                      ),
                                  )
                                : formulas?.length > 0 &&
                                  formulas?.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                      {item.nombre}
                                    </MenuItem>
                                  ))}
                            </SelectCustom>
                          </TableCell>
                          <TableCell align="right">
                            <InputField
                              fullWidth
                              name="observacion_formula"
                              size="small"
                              type="text"
                              InputProps={{
                                autoComplete: "new-password",
                              }}
                              control={control}
                              rules={rules.observacion}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <InputField
                              fullWidth
                              name="cantidad_formula"
                              size="small"
                              type="number"
                              control={control}
                              rules={rules.numeroDecimalRequerido}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  style={{ margin: "0 2rem" }}
                >
                  <CheckboxCustom
                    name="presupuesto"
                    label="Presupuesto"
                    color="primary"
                    control={control}
                    labelPlacement="start"
                    checked={presupuesto}
                    disabled={!!datosRemito?.resumen_id}
                  />

                  {!!datosRemito?.resumen_id && (
                    <Alert
                      severity="info"
                      style={{
                        marginLeft: "1rem",
                        padding: "0 0.25rem",
                        fontWeight: "bold",
                      }}
                    >
                      {`El remito tiene ${
                        datosRemito?.presupuesto
                          ? "una liquidación asociada"
                          : "un resumen asociado"
                      }`}
                    </Alert>
                  )}
                </Grid>
                {/* CONTENEDOR BOTONES VOLVER Y CONFIRMAR */}
                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                  alignContent="left"
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ marginRight: "0.5rem" }}
                    onClick={() =>
                      setConfirmDialog({
                        title: `No se guardarán los datos cargados del remito`,
                        isOpen: true,
                        onConfirm: () => {
                          Volver();
                        },
                      })
                    }
                  >
                    Volver
                  </Button>
                  <ButtonLoading
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => validarCampos()}
                    disabled={cargando ? true : false}
                    isLoading={cargando}
                  >
                    Guardar
                  </ButtonLoading>
                </Grid>
              </Grid>
              {datosSeleccionadosRemito?.idCliente &&
                datosSeleccionadosRemito?.fechaRemito &&
                modalAsociarTurnoVisibility && (
                  <ModalAsociarTurno
                    open={modalAsociarTurnoVisibility}
                    handleClose={handleCloseModalAsociarTurno}
                    datosSeleccionadosRemito={datosSeleccionadosRemito}
                    turnoSeleccionado={turnoSeleccionado}
                    setTurnoSeleccionado={setTurnoSeleccionado}
                    turnos={turnos}
                  />
                )}
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              <CustomSnackbar
                message={snackState.message}
                open={snackState.open}
                severity={snackState.severity}
                onClose={onClose}
              />
            </>
          ) : (
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              style={{
                marginTop: "15rem",
              }}
            >
              <CircularProgress style={{ marginRight: "1rem" }} />
              <Typography variant="h5">Cargando Formulario</Typography>
            </Grid>
          )}
        </RootContainerWhite>
      </form>
    </Protected>
  );
};
export default CrearRemito;
