import { NotificationContext } from "context/notification/NotificationContext";
import { useContext } from "react";

export const useStyles = () => {
  const { isNotificationsEnabled } = useContext(NotificationContext);

  return {
    tableFacturas: {
      table: () => ({}),
      tableContainer: () => ({}),
      tableHead: () => ({}),
      tableBody: () => ({}),
      tableRowHead: () => ({
        backgroundColor: isNotificationsEnabled ? "#CCCFE4" : "#E26E6E",
      }),
      tableRowBody: () => ({}),
    },
    tableLineas: {
      table: () => ({}),
      tableContainer: () => ({ overflow: "hidden", overflowX: "auto" }),
      tableHead: () => ({}),
      tableBody: () => ({}),
      tableRowHead: () => ({}),
      tableRowBody: () => ({}),
    },
    tableCell: { borderBottom: 0, padding: "0.4rem" },
  };
};
