/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  MenuItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Theme from "theme";
import moment from "moment";

// Hook
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useGetMaterialesSinPaginar } from "hook/useGetMaterialesSinPaginar";
import { useGetTransportistas } from "hook/useGetTransportistas";
import { useGetCanterasAreneras } from "hook/useGetCanterasAreneras";

// Services
import ProveedoresService from "services/proveedores.service";

// Components
import Protected from "protected";
import RootContainerWhite from "layout/rootContainerWhite";
import InputField from "components/Layout/inputCustom/inputField";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import ConfirmDialog from "components/ConfirmDialog";
import SelectCustom from "components/Layout/select/selectCustom";
import CustomSnackbar from "components/Layout/CustomSnackbars";

// Constants
import rules from "constants/rules";
import dayjs from "dayjs";

const NuevoEditarRemito = () => {
  const history = useHistory();
  const datosRemito = history.location?.state?.remito
    ? history.location.state.remito
    : null;
  const datosProveedor = history.location?.state?.proveedor
    ? history.location.state.proveedor
    : null;

  const [errorNroRemitoExistente, setErrorNroRemitoExistente] = useState(false);
  const [errorNroRemitoExistenteMessage, setErrorNroRemitoExistenteMessage] =
    useState("");
  const [cargando, setCargando] = useState(false);
  const diaAyer = dayjs().subtract(1, "d").format("YYYY-MM-DD");

  // INICIO DECLARACIONES DE LIBRERIA ENCARGADA DE VALIDACIONES
  const { handleSubmit, control, watch, reset, setValue } = useForm({
    defaultValues: {
      fecha: datosRemito?.fecha ?? diaAyer,
      factura_remito: datosRemito?.factura_remito ?? "",
      material: datosRemito?.material ?? datosRemito?.combustible_id,
      tipo_material: datosRemito?.tipo_material ?? "",
      ingreso: datosRemito?.ingreso ?? "",
      toneladas_bascula: datosRemito?.toneladas_bascula ?? "",
      origen: datosRemito?.origen ?? "",
      chofer: datosRemito?.chofer ?? "",
      chofer_tambor: datosRemito?.chofer_tambor ?? "",
      proveedor_transporte: datosRemito?.transporte_id ?? datosRemito?.proveedor_transporte_id,
      patente: datosRemito?.patente ?? "",
      patente_tractor: datosRemito?.patente_tractor ?? "",
      patente_batea: datosRemito?.patente_batea ?? "",
      cantera_arenera: datosRemito?.cantera_arenera ?? "",
      trans_cantera: datosRemito?.trans_cantera ?? "",
      cantidad_litros: datosRemito?.cantidad_litros ?? "",
      kilometros: datosRemito?.kilometros ?? "",
      combustible: datosRemito?.combustible_id ?? "",
      tipo_combustible: datosRemito?.tipo_combustible_id ?? "",
      monto: datosRemito?.monto ?? "",
      producto_servicio: datosRemito?.producto_servicio ?? "",
    },
  });

  const datosSeleccionadosRemito = {
    fechaFacRemito: watch("fecha"),
    facRemito: watch("factura_remito"),
  };

  // Estado para aplicar filtros en materiales dependiendo el tipo de proveedor
  const filterBy = ["COMBUSTIBLE"];
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Query para obtener todas los materiales y/o filtrarlo por tipo (nombre)
  const { data: materiales, isLoading } = useGetMaterialesSinPaginar();
  const [tipos, setTipos] = useState([]);

  // Query para obtener todos los proveeedores transportistas
  const { data: transportistas, isLoadingTransp } = useGetTransportistas();

  // Query para obtener todos los proveeedores materiales
  const { data: canterasAreneras, isLoadingCantAre } = useGetCanterasAreneras();

  // FUNCION ENVIAR DATOS AL BACK
  const onSubmit = async (data) => {
    // VALIDO CAMPOS OBLIGATORIOS
    if (!validarCampos()) {
      let endpoint = "";
      let type = "";
      if (datosRemito) {
        type = "editado";
        endpoint = ProveedoresService.editRemito(datosRemito?.id, {
          ...data,
          proveedor: datosProveedor.id,
        });
      } else {
        type = "creado";
        endpoint = ProveedoresService.addRemito(datosProveedor, data);
      }
      try {
        setCargando(true);
        const res = await endpoint;
        if (res.status > 199 && res.status < 300) {
          setCargando(false);
          setSnackState({
            open: true,
            severity: "success",
            message: `Remito ${type} correctamente`,
          });
          reset();

          setTimeout(() => {
            Volver();
          }, 1000);
        } else {
          setCargando(false);
          setSnackState({
            open: true,
            severity: "error",
            message: `Error en la Edición/Creación de Remito`,
          });
        }
      } catch (error) {
        setCargando(false);
        if (error?.response?.data?.nro_remito) {
          setErrorNroRemitoExistente(true);
          setErrorNroRemitoExistenteMessage(
            error?.response?.data?.nro_remito[0],
          );
        } else {
          setSnackState({
            open: true,
            severity: "error",
            message: "Error Servidor al Crear Remito",
          });
        }
      }
    }
  };

  // FUNCION QUE REDIRIGE A LA PANTALLA LISTA DE REMITOS
  const Volver = () => {
    history.goBack(`/proveedores/remitos/${datosProveedor?.id}`);
  };

  // VALIDO CAMPOS OBLIGATORIO CLIENTE Y REMITO
  const validarCampos = () => {
    if (!datosSeleccionadosRemito?.facRemito) {
      setErrorNroRemitoExistente(true);
      setErrorNroRemitoExistenteMessage("Este campo es requerido");
    } else {
      setErrorNroRemitoExistente(false);
    }
    if (!datosSeleccionadosRemito?.facRemito) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setErrorNroRemitoExistente(false);
    setErrorNroRemitoExistenteMessage("");
  }, [datosSeleccionadosRemito?.facRemito]);

  const [mat, setMat] = useState();

  useEffect(() => {
    setMat(materiales?.filter((mat) => mat.id === watch("material")));
  }, [watch("material")]);

  useEffect(() => {
    mat?.at(0) && setTipos(mat[0]?.tipos);
  }, [mat]);

  return (
    <Protected>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RootContainerWhite>
          {materiales?.length > 0 ? (
            <>
              <Grid
                container
                item
                style={{
                  boxShadow: "0px 5px 5px rgba(0, 0, 0, .2)",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  borderRadius: 10,
                  backgroundColor: "#ffffff",
                  margin: "2vw",
                  marginBottom: "5rem",
                }}
              >
                {/* TITULO DE LA VISTA  */}
                <Grid
                  container
                  alignContent="center"
                  justifyContent="center"
                  item
                  style={{
                    height: "3rem",
                    backgroundColor: Theme.palette.primary.main,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Typography variant="h5" style={{ color: "#ffffff" }}>
                    {datosRemito ? "Modificar Remito" : "Crear Remito"}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ margin: "0 2rem", marginTop: "2rem" }}
                >
                  <Grid
                    container
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignContent="center"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Fecha:*
                    </Typography>
                    <InputField
                      name="fecha"
                      label="Fecha"
                      control={control}
                      rules={rules.generic}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        max: moment().format("YYYY-MM-DD"),
                      }}
                      type="date"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0.5rem" }}
                    alignContent="center"
                  >
                    <Typography style={{ marginBottom: "0.5rem" }}>
                      Remito/Factura:*
                    </Typography>
                    <InputField
                      fullWidth
                      name="factura_remito"
                      label="Ingrese número"
                      variant="outlined"
                      size="small"
                      type="text"
                      control={control}
                      rules={rules.generic}
                      error={errorNroRemitoExistente}
                      helperText={errorNroRemitoExistenteMessage}
                      InputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </Grid>
                </Grid>
                {/* FORMULARIOS DE ACUERDO AL TIPO DEL PROVEEDOR */}
                {datosProveedor?.tipo === "Transportista" ? (
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ margin: "0 2rem" }}
                  >
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Material:*
                      </Typography>
                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        name="material"
                        label="Seleccione material"
                        size="small"
                      >
                        {materiales?.map((material) => (
                          <MenuItem value={material.id}>
                            {material.nombre}
                          </MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Tipo de Material:*
                      </Typography>
                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        name="tipo_material"
                        label="Seleccione tipo de material"
                        size="small"
                      >
                        {tipos?.map((tipo) => (
                          <MenuItem value={tipo.id}>{tipo.nombre}</MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Ingreso:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="ingreso"
                        label="Ingrese valor numérico"
                        variant="outlined"
                        size="small"
                        type="number"
                        control={control}
                        rules={rules.numeroDecimalRequerido}
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Ton. Báscula:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="toneladas_bascula"
                        label="Ingrese valor numérico"
                        variant="outlined"
                        size="small"
                        type="number"
                        control={control}
                        rules={rules.numeroDecimalRequerido}
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Cantera/Arenera:*
                      </Typography>
                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        name="trans_cantera"
                        label="Seleccione cantera/arenera"
                        size="small"
                      >
                        {canterasAreneras?.results?.map((untransp) => (
                          <MenuItem value={untransp.id}>
                            {untransp.razon_social}
                          </MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Orígen:
                      </Typography>
                      <InputField
                        fullWidth
                        name="origen"
                        label="Ingrese orígen"
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="new-password"
                        control={control}
                        rules={rules.observacion}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Nombre y Apellido del Chofer:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="chofer"
                        label="Ingrese nombre y apellido"
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="new-password"
                        control={control}
                        rules={rules.nombre}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Patente Tractor:
                      </Typography>
                      <InputField
                        fullWidth
                        name="patente_tractor"
                        label="Ingrese patente del tractor"
                        variant="outlined"
                        size="small"
                        type="text"
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        control={control}
                        rules={rules.patenteNoRequiered}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Patente Batea:
                      </Typography>
                      <InputField
                        fullWidth
                        name="patente_batea"
                        label="Ingrese patente de la batea"
                        variant="outlined"
                        size="small"
                        type="text"
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        control={control}
                        rules={rules.patenteNoRequiered}
                      />
                    </Grid>
                  </Grid>
                ) : datosProveedor?.tipo === "General" ? (
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ margin: "0 2rem" }}
                  >
                    <Grid
                      container
                      item
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Monto:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="monto"
                        label="Ingrese monto"
                        variant="outlined"
                        size="small"
                        type="text"
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        control={control}
                        rules={rules.monto}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Productos/Servicios:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="producto_servicio"
                        label="Ingrese descripción"
                        variant="outlined"
                        size="small"
                        type="text"
                        multiline
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        control={control}
                        rules={rules.generic}
                      />
                    </Grid>
                  </Grid>
                ) : datosProveedor?.tipo === "Combustible" ? (

                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ margin: "0 2rem" }}
                  >

                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Combustible:*
                      </Typography>
                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        name="material"
                        label="Seleccione combustible"
                        size="small"
                        funcionAdicional={(value) => { setValue("combustible", value) }}
                      >
                        {
                        materiales.filter(itemMat => {
                         // filtro lista de materiales con item que incluyan en el nombre "combustible"
                         return filterBy.some(itemfilter => itemMat.nombre.toLowerCase().includes(itemfilter.toLowerCase()))
                        })?.map((material) => (
                          <MenuItem value={material.id}>
                            {material.nombre}
                          </MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Tipo de Combustible:*
                      </Typography>
                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        name="tipo_combustible"
                        label="Seleccione combustible"
                        size="small"
                      >
                        {tipos?.map((tipo) => (
                          <MenuItem value={tipo.id}>{tipo.nombre}</MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>

                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Litros:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="cantidad_litros"
                        label="Ingrese cantidad"
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="new-password"
                        control={control}
                        rules={rules.numeroDecimalRequerido}
                      />
                    </Grid>

                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Proveedor transporte:*
                      </Typography>
                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        name="proveedor_transporte"
                        label="Seleccione proveedor"
                        size="small"
                      >
                        {transportistas?.results?.map((untransp) => (
                          <MenuItem value={untransp.id}>
                            {untransp.razon_social}
                          </MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>

                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Chofer/Tambor:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="chofer_tambor"
                        label="Ingrese chofer/tambor"
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="new-password"
                        control={control}
                        rules={rules.generic}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Patente:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="patente"
                        label="Ingrese patente"
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="new-password"
                        control={control}
                        rules={rules.patente}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        KM:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="kilometros"
                        label="Ingrese kilometraje"
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="new-password"
                        control={control}
                        rules={rules.numeroDecimalRequerido}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ margin: "0 2rem" }}
                  >
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Material:*
                      </Typography>

                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        defaultValue={""}
                        name="material"
                        label="Seleccione material"
                        size="small"
                      >
                        {
                        materiales.filter(itemMat => {
                         // filtro lista de materiales con item que no incluyan en el nombre "combustible"
                         return filterBy.some(itemfilter => !itemMat.nombre.toLowerCase().includes(itemfilter.toLowerCase()))
                        })?.map((material) => (
                          <MenuItem value={material.id}>
                            {material.nombre}
                          </MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Tipo de Material:*
                      </Typography>

                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        disabled={tipos?.length < 1}
                        name="tipo_material"
                        label="Seleccione tipo de material"
                        size="small"
                      >
                        {tipos?.map((tipo) => (
                          <MenuItem value={tipo.id}>{tipo.nombre}</MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Ingreso:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="ingreso"
                        label="Ingrese valor numérico"
                        variant="outlined"
                        size="small"
                        type="number"
                        control={control}
                        rules={rules.numeroDecimalRequerido}
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Ton. Báscula:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="toneladas_bascula"
                        label="Ingrese valor numérico"
                        variant="outlined"
                        size="small"
                        type="number"
                        control={control}
                        rules={rules.numeroDecimalRequerido}
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignContent="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Proveedor Transportista:*
                      </Typography>
                      <SelectCustom
                        control={control}
                        rules={rules.generic}
                        name="trans_cantera"
                        label="Seleccione transporte"
                        size="small"
                      >
                        {transportistas?.results?.map((untransp) => (
                          <MenuItem value={untransp.id}>
                            {untransp.razon_social}
                          </MenuItem>
                        ))}
                      </SelectCustom>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Orígen:
                      </Typography>
                      <InputField
                        fullWidth
                        name="origen"
                        label="Ingrese orígen"
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="new-password"
                        control={control}
                        rules={rules.observacion}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Nombre y Apellido del Chofer:*
                      </Typography>
                      <InputField
                        fullWidth
                        name="chofer"
                        label="Ingrese nombre y apellido"
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="new-password"
                        control={control}
                        rules={rules.nombre}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Patente Tractor:
                      </Typography>
                      <InputField
                        fullWidth
                        name="patente_tractor"
                        label="Ingrese patente del tractor"
                        variant="outlined"
                        size="small"
                        type="text"
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        control={control}
                        rules={rules.patenteNoRequiered}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      style={{ padding: "0.5rem" }}
                      alignItems="center"
                    >
                      <Typography style={{ marginBottom: "0.5rem" }}>
                        Patente Batea:
                      </Typography>
                      <InputField
                        fullWidth
                        name="patente_batea"
                        label="Ingrese patente de la batea"
                        variant="outlined"
                        size="small"
                        type="text"
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        control={control}
                        rules={rules.patenteNoRequiered}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* CONTENEDOR BOTONES VOLVER Y CONFIRMAR */}
                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                  alignContent="left"
                  style={{
                    padding: "1rem",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ marginRight: "0.5rem" }}
                    onClick={() =>
                      setConfirmDialog({
                        title: `No se guardarán los datos cargados del remito`,
                        isOpen: true,
                        onConfirm: () => {
                          Volver();
                        },
                      })
                    }
                  >
                    Volver
                  </Button>
                  <ButtonLoading
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => validarCampos()}
                    disabled={cargando ? true : false}
                    isLoading={cargando}
                  >
                    Guardar
                  </ButtonLoading>
                </Grid>
              </Grid>
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              <CustomSnackbar
                message={snackState.message}
                open={snackState.open}
                severity={snackState.severity}
                onClose={onClose}
              />
            </>
          ) : (
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              style={{
                marginTop: "15rem",
              }}
            >
              <CircularProgress style={{ marginRight: "1rem" }} />
              <Typography variant="h5">Cargando Formulario</Typography>
            </Grid>
          )}
        </RootContainerWhite>
      </form>
    </Protected>
  );
};
export default NuevoEditarRemito;
