export const TablaTipoCombustibles = {
  headers: [
    { value: "Fecha", type: "order", id_ord: "fecha" },
    { value: "Factura/Remito", type: "order", id_ord: "factura_remito" },
    { value: "Tipo de Combustible", type: "order", id_ord: "tipo_combustible" },
    { value: "Litros", type: "normal" },
    { value: "Proveedor de Transporte", type: "normal" },
    { value: "Patente", type: "normal" },
    { value: "Chofer/Tambor", type: "normal" },
    { value: "KM", type: "normal" },
  ],
  body: [
    { value: "fecha", type: "date", default: "-" },
    { value: "factura_remito", type: "none", default: "-" },
    { value: "tipo_combustible", type: "none", default: "-" },
    { value: "cantidad_litros", type: "float", default: "-" },
    { value: "proveedor_transporte", type: "none", default: "-" },
    { value: "patente", type: "none", default: "-" },
    { value: "chofer_tambor", type: "none", default: "-" },
    { value: "kilometros", type: "float", default: "-" },
  ],
};
