import React, { useState } from "react";
import { Typography, Modal, Grid, Button } from "@material-ui/core";
import Theme from "theme";

import CustomSnackbar from "components/Layout/CustomSnackbars";
import { makeStyles } from "@material-ui/core/styles";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import ProveedoresService from "services/proveedores.service";
import { useForm } from "react-hook-form";
import InputField from "components/Layout/inputCustom/inputField";

// Constants
import rules from "constants/rules";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "auto",
    height: "auto",
    overflow: "auto",
  },
}));

const ModalAgregarFactura = ({ open, handleClose, queryClient, idProv }) => {
  const classes = useStyles();

  //ESTADOS
  const [cargando, setCargando] = useState(false);

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // INICIO DECLARACIONES DE LIBRERIA ENCARGADA DE VALIDACIONES
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      factura: 0,
    },
  });

  // Función para cargar Factura
  const cargarFactura = async () => {
    const valorFactura = watch("factura");
    try {
      setCargando(true);
      const res = await ProveedoresService.cargarFactura(
        idProv,
        valorFactura * 1,
      );
      if (res.status > 199 && res.status < 299) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Factura cargada exitosamente.",
        });
        setCargando(false);
        queryClient.invalidateQueries("Remitos");
      } else {
        setCargando(false);
        setSnackState({
          open: true,
          severity: "error",
          message: "Error al cargar factura.",
        });
      }
    } catch (error) {
      setCargando(false);
      setSnackState({
        open: true,
        severity: "warning",
        message: "Error al cargar factura.",
      });
    }
    handleClose();
    reset();
  };

  return (
    <>
      <Modal open={open} className={classes.paper}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignContent="center"
          alignItems="flex-end"
          spacing={2}
          xl={5}
          lg={5}
          md={6}
          sm={6}
          xs={11}
          style={{
            backgroundColor: "#ffffff",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
            minHeight: "auto",
            overflow: "auto",
          }}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <Typography item variant="h5" style={{ color: "#ffffff" }}>
              Agregar Factura
            </Typography>
          </Grid>
          <Grid
            container
            item
            xl={8}
            lg={8}
            md={8}
            sm={12}
            xs={12}
            style={{ margin: "1rem auto", padding: "1rem" }}
            direction="row"
            justifyContent="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography style={{ marginBottom: "0.5rem" }}>
                Valor a cargar:*
              </Typography>
              <InputField
                fullWidth
                name="factura"
                label="Ingrese valor numérico"
                variant="outlined"
                size="small"
                type="number"
                control={control}
                rules={rules.numeroDecimalRequerido}
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ padding: "1rem" }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="small"
              style={{ marginRight: "0.5rem" }}
              onClick={() => handleClose()}
            >
              Volver
            </Button>
            <ButtonLoading
              onClick={() => cargarFactura()}
              disabled={cargando ? true : false}
              isLoading={cargando}
            >
              Confirmar
            </ButtonLoading>
          </Grid>
        </Grid>
      </Modal>
      <CustomSnackbar
        message={snackState.message}
        open={snackState.open}
        severity={snackState.severity}
        onClose={onClose}
      />
    </>
  );
};
export default ModalAgregarFactura;
