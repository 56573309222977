import { Button, Grid, Link, Typography } from "@material-ui/core";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import InputDate from "components/inputs/newInputDate";
import InputText from "components/inputs/newInputText";
import NewSelect from "components/inputs/newSelect";
import TableCustomizable from "components/tableCustomizable";
import mostrarErrorEP from "constants/funciones/mostrarError";
import rules from "constants/rules";
import useDayjs from "hook/useDayjs";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import TableRowCrearFacturaOrden from "screens/facturacion/components/tableRowCrearFacturaOrden";
import { namesFacturacion } from "screens/facturacion/constants/const";
import useStyles from "../crearFacturaStyles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../../assets/img/LogoAyupi.png";
import facturacionServices from "services/facturacion.services";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { ReactComponent as RemintoX } from "assets/svg/remito_x.svg";

export default function FormCrearFacturaOrden({
  orden,
  state,
  setSnackState,
  archivoSeleccionado,
  URLArchivo,
  setModalAgregarArchivo,
}) {
  // Hooks
  const history = useHistory();
  const classes = useStyles();
  const dayjs = useDayjs();

  // Object destructuring hooks
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      cliente: orden?.cliente ?? "",
      cuit_cuil: orden?.cliente_cuil ?? "",
      domicilio: orden?.cliente_dir ?? "",
      fecha_emision: dayjs().format("YYYY-MM-DD"),
    },
  });

  // Array destructuring hooks
  const [importandoDatos, setImportandoDatos] = useState(false);
  const [lineas, setLineas] = useState(orden?.detalle_orden);

  // Constants
  const fecha_emision = watch(namesFacturacion?.fechaEmision);
  const headerStyle = {
    border: 0,
    backgroundColor: state?.presupuesto ? "#E26E6E" : "#CCCFE4",
    fontWeight: "bold",
    fontSize: "1rem",
  };

  // Array functions
  const calcularTotal = () => {
    const total = lineas?.reduce(
      (acumulador, itemActual) => acumulador + itemActual?.subtotal,
      0,
    );

    return Number(total.toFixed(2));
  };

  // Async array functions
  const importarDatosPDF = async () => {
    setImportandoDatos(true);
    const dataSend = new FormData();

    dataSend.append("pdf", archivoSeleccionado);

    try {
      const { data, status } = await facturacionServices.importarDatosPDF(
        dataSend,
      );

      if (status > 199 && status < 300) {
        reset({
          num_factura: data?.comp_nro,
          fecha_emision: moment(data?.fecha_de_emision, "DD/MM/YYYY").format(
            "YYYY-MM-DD",
          ),
          fecha_venc_pago: moment(
            data?.fecha_vto_para_el_pago,
            "DD/MM/YYYY",
          ).format("YYYY-MM-DD"),
          tipo_factura: data?.factura,
          cond_iva: data?.cond_frente_al_iva,
          punto_venta: data?.punto_de_venta,
        });

        setImportandoDatos(false);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      setImportandoDatos(false);
      return error;
    }
  };

  const crearFactura = async ({ dataSend }) => {
    return state?.presupuesto
      ? await facturacionServices.crearFacturaOrdenNegro({ dataSend })
      : await facturacionServices.crearFacturaOrden({ dataSend });
  };

  const { mutate, isLoading: creandoFactura } = useMutation(crearFactura);

  const onSubmit = async (data) => {
    const dataSend = new FormData();

    if (archivoSeleccionado) dataSend.append("documento", archivoSeleccionado);
    dataSend.append("num_factura", data?.num_factura);
    dataSend.append("fecha_venc_pago", data?.fecha_venc_pago);
    dataSend.append("fecha_emision", data?.fecha_emision);
    dataSend.append("cond_iva", data?.cond_iva);
    dataSend.append("tipo_factura", data?.tipo_factura ?? "X");
    dataSend.append("punto_venta", data?.punto_venta);
    dataSend.append("total", calcularTotal());
    dataSend.append("cliente", orden?.cliente_id);
    dataSend.append("orden", orden?.id);
    dataSend.append("condicion", "Pagado");
    dataSend.append("linea_factura", JSON.stringify(lineas));
    dataSend.append("presupuesto", state?.presupuesto);

    mutate(
      { dataSend },
      {
        onSuccess: () => {
          setSnackState({
            open: true,
            severity: "success",
            message: "Factura Creada Exitosamente",
          });

          setTimeout(() => {
            history.push(`/facturacion/`);
          }, 2000);
        },
        onError: (error) => {
          mostrarErrorEP(error, setSnackState);
          return error;
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        style={{
          padding: "1rem 4rem",
        }}
      >
        <Grid
          container
          item
          xl={6}
          lg={6}
          md={6}
          sm={7}
          xs={7}
          justifyContent="space-between"
          alignItems="center"
        >
          <img src={Logo} className={classes.logo} alt="logo" />

          {state?.presupuesto && <RemintoX />}
        </Grid>
        <Grid container style={{ paddingTop: "0.5rem" }}>
          <Grid
            container
            item
            xl={9}
            lg={9}
            md={12}
            sm={12}
            xs={12}
            style={{ paddingRight: "0.5rem" }}
          >
            <Grid
              container
              item
              xl={7}
              lg={7}
              md={7}
              sm={7}
              xs={12}
              style={{ paddingRight: "0.5rem" }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                  paddingRight: "0.5rem",
                }}
              >
                Razón Social:
              </Typography>
              <Typography variant="body2">Ayupí Hormigones SRL</Typography>
            </Grid>

            <Grid container item xl={5} lg={5} md={5} sm={5} xs={12}>
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                  paddingRight: "0.5rem",
                }}
              >
                C.U.I.T:
              </Typography>
              <Typography variant="body2">23-31313131-9</Typography>
            </Grid>

            <Grid
              container
              item
              xl={7}
              lg={7}
              md={7}
              sm={7}
              xs={12}
              style={{ paddingRight: "0.5rem" }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                  paddingRight: "0.5rem",
                }}
              >
                Condición IVA:
              </Typography>
              <Typography variant="body2">Responsable Inscripto</Typography>
            </Grid>

            <Grid container item xl={5} lg={5} md={5} sm={5} xs={12}>
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                  paddingRight: "0.5rem",
                }}
              >
                Insc. Ing. Brutos:
              </Typography>
              <Typography variant="body2">23-31313131-9</Typography>
            </Grid>

            <Grid
              container
              item
              xl={7}
              lg={7}
              md={7}
              sm={7}
              xs={12}
              style={{ paddingRight: "0.5rem" }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                  paddingRight: "0.5rem",
                }}
              >
                Domicilio:
              </Typography>
              <Typography variant="body2">
                Ruta Nac Nº 16 Km 18,5 Resistencia Chaco
              </Typography>
            </Grid>

            <Grid container item xl={5} lg={5} md={5} sm={5} xs={12}>
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                  paddingRight: "0.5rem",
                }}
              >
                Inicio de Actividades:
              </Typography>
              <Typography variant="body2">01/01/2000</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          >
            <InputText
              name={namesFacturacion?.numFact}
              label={`Número de ${state?.presupuesto ? "Recibo" : "Factura"}:`}
              InputLabelProps={{ required: true }}
              control={control}
              rules={rules.numberRequered}
            />
          </Grid>
        </Grid>

        <Grid container style={{ paddingTop: "1rem" }}>
          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          >
            <InputDate
              name={namesFacturacion?.fechaEmision}
              label="Fecha Emisión:"
              InputLabelProps={{ required: true, shrink: true }}
              inputProps={{ max: moment().format("YYYY-MM-DD") }}
              control={control}
              rules={rules.generic}
            />
          </Grid>

          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          >
            <InputDate
              name={namesFacturacion?.fechaVencPago}
              label="Fecha Vencimiento Pago:"
              disabled={!fecha_emision}
              InputLabelProps={{ required: true, shrink: true }}
              inputProps={{ min: fecha_emision }}
              control={control}
              rules={rules.generic}
            />
          </Grid>

          {!state?.presupuesto && (
            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
            >
              <NewSelect
                control={control}
                rules={rules.generic}
                name={namesFacturacion?.tipoFactura}
                label="Tipo de Factura:*"
                options={["A", "B", "C"]}
              />
            </Grid>
          )}

          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          >
            <NewSelect
              control={control}
              rules={rules.generic}
              name={namesFacturacion?.puntoVenta}
              label="Punto de Venta:*"
              options={["00001", "00002"]}
            />
          </Grid>

          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          >
            <NewSelect
              control={control}
              rules={rules.generic}
              name={namesFacturacion?.condIva}
              label="Condición IVA:*"
              options={[
                "IVA Responsable Inscripto",
                "IVA Sujeto Exento",
                "Consumidor Final",
                "Sujeto No Categorizado",
                "Proveedor del Exterior",
                "Cliente del Exterior",
                "IVA Liberado",
                "IVA No Alcanzado",
                "Responsable Monotributo",
                "Monotributista Social",
                "Monotributista Trabajador Independiente Promovido",
              ]}
            />
          </Grid>

          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          >
            <InputText
              disabled
              name={namesFacturacion?.cliente}
              label="Cliente"
              className={classes.inputDisabled}
              control={control}
            />
          </Grid>

          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          >
            <InputText
              disabled
              name={namesFacturacion?.cuitCuil}
              label="CUIT/CUIL:"
              className={classes.inputDisabled}
              control={control}
            />
          </Grid>

          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ padding: "0.5rem 0.5rem 0.5rem 0" }}
          >
            <InputText
              disabled
              name={namesFacturacion?.domicilio}
              label="Domicilio:"
              className={classes.inputDisabled}
              control={control}
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          style={{ paddingTop: "1rem" }}
          xl={6}
          lg={7}
          md={10}
          sm={12}
          xs={12}
        >
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", paddingRight: "1rem" }}
          >
            Adjuntar Factura Electrónica AFIP
          </Typography>

          <Button
            variant="contained"
            color="primary"
            size="small"
            children="Cargar PDF"
            style={{ textTransform: "none", marginRight: "1rem" }}
            startIcon={<CloudUploadIcon />}
            onClick={() => setModalAgregarArchivo(true)}
          />
        </Grid>

        <Grid
          container
          item
          alignItems="center"
          style={{ paddingTop: "1rem" }}
          xl={6}
          lg={5}
          md={12}
          sm={12}
          xs={12}
        >
          {archivoSeleccionado ? (
            <>
              <CheckIcon fontSize="small" color="success" />
              <Link
                href={URLArchivo}
                variant="body2"
                underline="hover"
                target="_blank"
                rel="noopener"
                children={archivoSeleccionado?.name}
              />
            </>
          ) : (
            <>
              <CloseIcon fontSize="small" color="error" />
              <Typography variant="body1" style={{ paddingLeft: "0.5rem" }}>
                No Hay Archivo Seleccionado
              </Typography>
            </>
          )}
        </Grid>

        <Grid container item alignItems="center">
          <ButtonLoading
            isLoading={importandoDatos}
            disabled={!archivoSeleccionado || importandoDatos}
            children="Importar Encabezado PDF"
            size="small"
            style={{ textTransform: "none", marginRight: "1rem" }}
            onClick={() => importarDatosPDF()}
          />
        </Grid>

        <Typography
          variant="body1"
          style={{ fontWeight: "bold", marginTop: "0.5rem" }}
        >
          Orden Asociada:
          <Typography
            variant="body1"
            component={"span"}
            style={{ marginLeft: "0.5rem" }}
          >
            {orden?.num_orden}
          </Typography>
        </Typography>

        <TableCustomizable
          size="small"
          headers={[
            {
              name: "Producto/Servicio",
              style: headerStyle,
            },
            {
              name: "Cantidad M³",
              style: headerStyle,
            },
            {
              name: "Precio Unitario",
              style: headerStyle,
            },
            {
              name: "Bonificación",
              style: headerStyle,
            },
            {
              name: "Importe",
              style: headerStyle,
            },
            watch(namesFacturacion?.tipoFactura) === "A" && {
              name: "IVA",
              style: headerStyle,
            },
            {
              name: "Subtotal",
              style: headerStyle,
            },
          ]}
          styleTableContainer={() => ({
            overflowX: "unset",
            overflowY: "auto",
            maxHeight: "45vh",
            width: "100%",
          })}
        >
          {lineas?.map((linea) => (
            <TableRowCrearFacturaOrden
              key={linea?.id}
              linea={linea}
              lineas={lineas}
              setLineas={setLineas}
              watch={watch}
              control={control}
            />
          ))}
        </TableCustomizable>

        <Grid container justifyContent="flex-end" style={{ marginTop: "1rem" }}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {"Total " +
              calcularTotal()?.toLocaleString("es-AR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "ARS",
              }) ?? "-"}
          </Typography>
        </Grid>

        <Grid container justifyContent="flex-end" style={{ marginTop: "1rem" }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            children="Volver"
            style={{ marginRight: "1rem", textTransform: "none" }}
            onClick={() => history.goBack()}
          />
          <ButtonLoading
            type="submit"
            children="Confirmar"
            isLoading={creandoFactura}
            disabled={creandoFactura}
            style={{ textTransform: "none" }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
