// REACT
import { React, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
// MATERIAL-UI
import { Grid, Typography } from "@material-ui/core";
// STYLES
import Theme from "theme";
import useStyles from "screens/facturacion/seleccionar-cliente/styles";
// COMPONENTS
import VerificarAcceso from "components/verificarAcceso";
import SearchBar from "components/Layout/searchBar/SearchBar";
import TablaCtaCte from "./components/tableCtaCte";
import LoadingText from "components/loadingText";
import CustomSnackbar from "components/Layout/CustomSnackbars";
// VARIOS
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import { LogIn } from "context/LogInContext";
import { NotificationContext } from "context/notification/NotificationContext";
import facturacionServices from "services/facturacion.services";
import { useQuery, useQueryClient } from "react-query";

const CuentaCorriente = () => {
  const history = useHistory();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const { logged } = useContext(LogIn);

  //Estado para verifiar acceso al módulo
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  // ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  const [clientes, setClientes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //--------------------- FUNCION PARA TRAER TOODOS LOS CLIENTES SIN PAGINADO-------------------------
  const obtenerClientes = async () => {
    const { data } = await facturacionServices.getClientes();
    return data;
  };

  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(["obtenerClientes"], obtenerClientes, {
    onSuccess: (data) => {
      setClientes(data);
      setIsLoading(false);
    },
    onError: () => setIsLoading(false),
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  //--------------------- FUNCION PARA BUSCAR CLIENTE -------------------------
  const handleBusqueda = (text) => {
    let search = text.toLowerCase();
    if (text.length > 0) {
      const result = data?.filter(
        (item) =>
          item.nombre.toLowerCase().includes(search) ||
          item.cuil_dni.includes(search),
      );

      setClientes(result);
    } else {
      queryClient.invalidateQueries("obtenerClientes");
    }
  };

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="cuenta-corriente"
            history={history}
            logged={logged}
          />
        ) : (
          <>
            {acceso && (
              <Grid
                container
                style={{
                  margin: "2rem 1rem 3rem 1rem",
                  overflow: "auto",
                }}
              >
                <Grid container style={{ padding: "0 1rem" }}>
                  <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      direction="row"
                      justifyContent="flex-start"
                      item
                      container
                    >
                      <SearchBar
                        placeholder="Buscar por Cliente"
                        onChange={(e) => handleBusqueda(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    className={classes.generalContainer}
                    style={{
                      marginTop: "1rem",
                      boxShadow: "0px 5px 5px rgba(0, 0, 0, .2)",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      className={classes.headerTable}
                    >
                      <Typography
                        variant="h5"
                        className={classes.headerTableTittle}
                      >
                        Lista Cuenta Corriente
                      </Typography>
                    </Grid>

                    {isLoading ? (
                      <LoadingText title="Cargando Clientes" />
                    ) : clientes?.length > 0 ? (
                      <TablaCtaCte
                        classes={classes}
                        clientes={clientes}
                        isNotificationsEnabled={isNotificationsEnabled}
                        Theme={Theme}
                      />
                    ) : (
                      <Grid
                        container
                        justifyContent="center"
                        style={{ margin: "0.5rem" }}
                      >
                        <Typography variant="subtitle1">
                          No se encontraron resultados
                        </Typography>
                      </Grid>
                    )}

                    <CustomSnackbar
                      message={snackState.message}
                      open={snackState.open}
                      severity={snackState.severity}
                      autoHideDuration={snackState.autoHideDuration}
                      onClose={onClose}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </RootContainer>
    </Protected>
  );
};
export default CuentaCorriente;
