import React, { useState } from "react";
import { Typography, Modal, Grid, Button } from "@material-ui/core";
import Theme from "theme";
import ResponsableCard from "./responsableCard";
import ClientesService from "services/clientes.service";
import { useQuery, useQueryClient } from "react-query";

const ResponsableCustomModal = ({
  open,
  handleClose,
  arrayResponsables,
  arrayResponsablesCargados,
  idCliente,
  setSnackBar,
}) => {
  const queryClient = useQueryClient();

  const [responsables, setResponsables] = useState([
    {
      nombre: null,
      telefono: null,
      email: null,
    },
  ]);

  // eslint-disable-next-line no-unused-vars
  const [habilitarBotonGuardar, setHabilitarBotonGuardar] = useState(true);
  const [desabilitarBotonEliminar, setDesabilitarBotonEliminar] =
    useState(false);
  const [verificado, setVerificado] = useState(true);
  const [sinStackear, setSinStackear] = useState();
  const [stackeado, setStackeado] = useState([]);

  const obtenerResponsables = async () => {
    const res = await ClientesService.obtenerResponsables(idCliente);
    try {
      if (res.status > 199 && res.status < 300) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data: responsablesQuery, isLoading } = useQuery(
    "obtenerResponsables",
    obtenerResponsables,
    {
      onSuccess: (data) => {
        if (stackeado?.length === 0) {
          setResponsables(data);
        }
      },
      onError: (error) => {
        console.log("Error", error);
      },
    }
  );

  const limpiar = () => {
    setVerificado(true);
    setSinStackear();
    setStackeado([]);
    /* limpiar repsonsables*/
    setResponsables([]);
  };

  const stackear = () => {
    setStackeado([
      ...stackeado,
      {
        nombre: sinStackear.nombre,
        telefono: sinStackear.telefono,
        email: sinStackear.email,
      },
    ]);
    setResponsables([...stackeado]);
  };

  const agregarResponsable = () => {
    setHabilitarBotonGuardar(true);
    setDesabilitarBotonEliminar(true);
    if (sinStackear && verificado) {
      stackear();
    }
    setResponsables([
      ...responsables,
      {
        nombre: null,
        telefono: null,
        email: null,
      },
    ]);

    setVerificado(false);
  };

  const guardarResponsables = async () => {
    if (verificado && sinStackear) {
      stackeado.push(sinStackear);
    }

    if (idCliente) {
      let sendData = [];
      if (responsables[0]?.nombre) {
        stackeado.shift();
      }
      await stackeado.forEach((item) => {
        if (item?.nombre) {
          sendData?.push({
            nombre: item?.nombre.toUpperCase(),
            num_telefono: item?.telefono,
            email: item?.email,
            cliente: idCliente,
          });
        }
      });

      if (sendData?.length > 0) {
        let arrayResponsables = { dataResponsables: sendData };
        const dataSend = new FormData();
        dataSend.append("responsables", JSON.stringify(arrayResponsables));
        try {
          const res = await ClientesService.agregarResponsable(dataSend);
          if (res.status > 199 && res.status < 299) {
            setSnackBar({
              open: true,
              severity: "success",
              message: "Actualización exitosa",
            });
            limpiar();
            queryClient.invalidateQueries("obtenerResponsables");
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error en actualizacion",
            });
          }
        } catch (error) {
          if (error?.response?.status === 400) {
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error servidor",
            });
          } else {
            setSnackBar({
              open: true,
              severity: "error",
              message: "Error en el servidor ",
            });
          }
        }
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          message: "No se cargó ningun responsable",
        });
      }
    } else {
      if (stackeado.length > 0) {
        let sendData = [...arrayResponsablesCargados];
        await stackeado.forEach((item) => {
          if (
            !arrayResponsablesCargados.find(
              (responsable) => responsable.nombre === item.nombre
            )
          ) {
            sendData.push({
              nombre: item?.nombre.toUpperCase(),
              num_telefono: item?.telefono,
              email: item?.email,
            });
          }
        });

        arrayResponsables(sendData);
        limpiar();
        handleClose();
      }
    }
    queryClient.invalidateQueries("obtenerResponsables");
    setHabilitarBotonGuardar(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid
        container
        item
        direction="column"
        justifyContent="flex-start"
        alignContent="flex-end"
        alignItems="flex-end"
        xl={7}
        lg={9}
        md={10}
        sm={10}
        xs={11}
        style={{
          backgroundColor: "#ffffff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
          minHeight: "20vh",
        }}
      >
        <Grid
          container
          item
          justifyContent="center"
          alignContent="center"
          style={{
            height: "3rem",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            backgroundColor: Theme.palette.primary.main,
          }}
        >
          <Typography variant="h5" style={{ color: "#ffffff" }}>
            {idCliente ? "Editar Responsables" : "Asignar Responsables"}
          </Typography>
        </Grid>
        <Grid container item style={{ maxHeight: "40vh", overflow: "auto" }}>
          {arrayResponsablesCargados?.length > 0 &&
            arrayResponsablesCargados.map((responsable) => (
              <ResponsableCard
                setVerificado={setVerificado}
                setSinStackear={setSinStackear}
                responsables={responsable}
                setResponsables={setResponsables}
                setSnackBar={setSnackBar}
                desabilitarEliminar={desabilitarBotonEliminar}
              />
            ))}
          {responsables?.length > 0 &&
            responsables.map((responsable) => (
              <ResponsableCard
                setVerificado={setVerificado}
                setSinStackear={setSinStackear}
                responsables={responsable}
                setResponsables={setResponsables}
                setSnackBar={setSnackBar}
                desabilitarEliminar={desabilitarBotonEliminar}
              />
            ))}
        </Grid>

        <Grid
          container
          item
          justifyContent="flex-start"
          style={{
            paddingLeft: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!verificado}
            onClick={() => agregarResponsable()}
          >
            Agregar Responsable
          </Button>
          <Grid
            container
            item
            justifyContent="flex-end"
            style={{
              marginTop: "0.5rem",
              paddingRight: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Button
              color="primary"
              style={{ marginRight: "0.5rem" }}
              onClick={() => {
                limpiar();
                queryClient.invalidateQueries("obtenerResponsables");
                setDesabilitarBotonEliminar(false);
                handleClose();
              }}
            >
              Volver
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!verificado}
              onClick={() => {
                guardarResponsables();
                setDesabilitarBotonEliminar(false);
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ResponsableCustomModal;
