import React, { useState } from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import Theme from "theme";
import AddBoxIcon from "@material-ui/icons/AddBox";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import useStyles from "./styles";
import CheckIcon from "@material-ui/icons/Check";
import ConfirmDialog from "../../components/ConfirmDialog";
import CustomConfirmDialog from "./CustomConfirmDialog.js";
import { useForm } from "react-hook-form";
import StyledMenu from "components/Layout/styledMenu";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import ModalCancelacion from "./ModalCancelacion";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
  },
}))(MenuItem);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const TableAgenda = ({
  DataAgenda,
  date,
  setDataAgenda,
  moment,
  setCreacion,
  setEditarTurno,
  turno,
  setTurno,
  setReasignar,
  obtenerTurnos,
  ordenarPor,
  cambiarEstado,
  eliminarTurno,
  isLoading,
}) => {
  const { reset } = useForm();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cancelacion, setCancelacion] = useState(false);

  const handleModalCancelar = () => {
    setCancelacion(!cancelacion);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });
  const [confirmCustomDialog, setConfirmCustomDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  const resetModal = () => {
    reset(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openMenu = (event, data) => {
    setAnchorEl(event.currentTarget);
    setTurno(data);
  };

  const editarTurno = () => {
    setCreacion(true);
    setEditarTurno(true);
  };

  const reasignarTurno = () => {
    setCreacion(true);
    setEditarTurno(true);
    setReasignar(true);
  };

  const calcularDiff = (data) => {
    let calculo = data.suma_cantidad_remito - data.cantidad_volumen;
    if (calculo > 0) {
      return (
        <Typography style={{ color: "green" }}>
          {Number(calculo.toFixed(2))}
        </Typography>
      );
    } else {
      return (
        <Typography style={{ color: "red" }}>
          {Number(calculo.toFixed(2))}
        </Typography>
      );
    }
  };

  return (
    <Grid container item className={classes.generalContainer}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        className={classes.headerTable}
      >
        <Typography variant="h5" className={classes.headerTableTittle}>
          {moment(date).locale("es").format("dddd LL")}
        </Typography>
      </Grid>

      {isLoading ? (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          style={{
            width: "5vw",
            height: "150px",
            margin: "0 auto",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <TableContainer className={classes.tableContainer}>
          {DataAgenda?.length ? (
            <Table className={classes.table} size="small">
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell className={classes.headTxt2}>
                    Hora
                    <IconButton
                      style={{ marginLeft: "0.5rem", padding: 0 }}
                      onClick={() => ordenarPor("hora_salida")}
                    >
                      <CompareArrowsIcon
                        style={{
                          width: "1.2rem",
                          padding: 0,
                          margin: 0,
                          transform: "rotate(90deg)",
                        }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.headTxt2}>
                    Mts3
                    <IconButton
                      style={{ marginLeft: "0.5rem", padding: 0 }}
                      onClick={() => ordenarPor("cantidad_volumen")}
                    >
                      <CompareArrowsIcon
                        style={{
                          width: "1.2rem",
                          padding: 0,
                          margin: 0,
                          transform: "rotate(90deg)",
                        }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.headTxt2}>Diff R</TableCell>

                  <TableCell className={classes.headTxt}>Tipo F.</TableCell>
                  <TableCell className={classes.headTxt2}>
                    Puntualidad
                  </TableCell>
                  <TableCell className={classes.headTxt} width="20%">
                    Cliente
                    <IconButton
                      style={{ marginLeft: "0.5rem", padding: 0 }}
                      onClick={() => ordenarPor("cliente_id")}
                    >
                      <CompareArrowsIcon
                        style={{
                          width: "1.2rem",
                          padding: 0,
                          margin: 0,
                          transform: "rotate(90deg)",
                        }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.headTxt}>Ciudad</TableCell>
                  <TableCell className={classes.headTxt} width="20%">
                    Dirección
                    <IconButton
                      style={{ marginLeft: "0.5rem", padding: 0 }}
                      onClick={() => ordenarPor("direccion_obra")}
                    >
                      <CompareArrowsIcon
                        style={{
                          width: "1.2rem",
                          padding: 0,
                          margin: 0,
                          transform: "rotate(90deg)",
                        }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.headTxt}>
                    D. Adicionales
                  </TableCell>
                  <TableCell className={classes.headTxt2}>Remito</TableCell>
                  <TableCell className={classes.headTxt}>Contacto</TableCell>
                  <TableCell className={classes.headTxt}>Entrega</TableCell>
                  <TableCell className={classes.headTxt}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {DataAgenda?.map((data) => (
                  <>
                    <TableRow
                      hover
                      key={data.id}
                      style={{
                        backgroundColor:
                          data?.entrega === "Reasignado" ||
                          data?.entrega === "Suspendido"
                            ? "yellow"
                            : data?.bombeo
                            ? "#aefa43"
                            : "white",
                        borderBottom: "1px solid black",
                        height: "3rem",
                      }}
                    >
                      <TableCell
                        className={classes.tableRow}
                        style={{
                          color:
                            data?.entrega === "Reasignado" ||
                            data?.entrega === "Suspendido"
                              ? "#f2190a"
                              : "none",
                        }}
                      >
                        {data?.hora_salida
                          ? moment(data?.hora_salida, "HH:mm:ss").format(
                              "HH:mm",
                            )
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          paddingLeft: "1.5rem",
                          fontSize: "18px",
                          border: "none",
                        }}
                      >
                        {data?.cantidad_volumen ? data.cantidad_volumen : ""}
                      </TableCell>
                      <TableCell className={classes.tableRow2}>
                        {data?.remitos[0]?.cantidad_mc
                          ? calcularDiff(data)
                          : ""}
                      </TableCell>
                      <TableCell
                        className={classes.tableRow}
                        style={{
                          color:
                            data?.entrega === "Reasignado" ||
                            data?.entrega === "Suspendido"
                              ? "#f2190a"
                              : "none",
                        }}
                      >
                        {data?.tipo_formula ? data.tipo_formula : ""}
                      </TableCell>
                      <TableCell
                        className={classes.tableRow2}
                        style={{
                          color: moment(
                            data?.fecha_turno + "T" + data?.hora_salida,
                          ).isBefore(
                            moment(
                              data?.fecha_turno + "T" + data?.hora_entrega,
                            ),
                          )
                            ? "#f2190a"
                            : "#50b141",
                        }}
                      >
                        {data?.hora_entrega
                          ? moment(data?.hora_entrega, "HH:mm:ss").format(
                              "HH:mm",
                            )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={classes.tableRow}
                        style={{
                          color:
                            data?.entrega === "Reasignado" ||
                            data?.entrega === "Suspendido"
                              ? "#f2190a"
                              : "none",
                        }}
                      >
                        {data?.cliente_id
                          ? data?.cliente_id?.nombre.toUpperCase()
                          : ""}
                      </TableCell>
                      <TableCell
                        className={classes.tableRow}
                        style={{
                          cursor: "pointer",
                          color:
                            data?.entrega === "Reasignado" ||
                            data?.entrega === "Suspendido"
                              ? "#f2190a"
                              : "none",
                        }}
                      >
                        {data?.ciudad ? data?.ciudad.toUpperCase() : ""}
                      </TableCell>
                      <TableCell
                        className={classes.tableRow}
                        style={{
                          cursor: "pointer",
                          color:
                            data?.entrega === "Reasignado" ||
                            data?.entrega === "Suspendido"
                              ? "#f2190a"
                              : "none",
                        }}
                      >
                        {data?.direccion_obra
                          ? data?.direccion_obra.toUpperCase()
                          : ""}
                      </TableCell>
                      <TableCell
                        className={classes.tableRow}
                        style={{ color: "red" }}
                      >
                        {data?.datos_adicionales
                          ? data?.datos_adicionales.toUpperCase()
                          : ""}
                      </TableCell>

                      <TableCell className={classes.tableRow2}>
                        {data?.presupuesto ? "" : "R"}
                      </TableCell>
                      <TableCell className={classes.tableRow}>
                        {data?.contacto ? data?.contacto : ""}
                      </TableCell>

                      {data?.entrega === "Reasignado" ? (
                        <TableCell
                          className={classes.tableRow}
                          style={{ cursor: "pointer", color: "#f2190a" }}
                        >
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  {data.entrega}
                                </Typography>
                                <Typography color="inherit">
                                  {moment(
                                    data?.historial_turno?.hora_nuevo,
                                    "HH:mm:ss",
                                  ).format("HH:mm")}{" "}
                                  {moment(
                                    data?.historial_turno?.fecha_nuevo,
                                    "YYYY-MM-DD",
                                  ).format("DD-MM-YYYY")}
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <Typography className={classes.tableRow}>
                              {data.entrega}
                            </Typography>
                          </HtmlTooltip>
                        </TableCell>
                      ) : (
                        <TableCell
                          className={classes.tableRow}
                          style={{
                            color:
                              data?.entrega === "Suspendido"
                                ? "#f2190a"
                                : "none",
                          }}
                        >
                          {data.entrega}
                        </TableCell>
                      )}
                      <TableCell className={classes.tableRow}>
                        {
                          // Habilita Entregado poder abrir menu para editar
                          //  data?.entrega === "Entregado" ||
                          data?.entrega === "Reasignado" ? (
                            <IconButton
                              style={{ height: "1.6rem", width: "1.6rem" }}
                            >
                              <AddBoxIcon
                                style={{
                                  height: "2rem",
                                  width: "2rem",
                                  marginLeft: "1.5rem",
                                  color: "grey",
                                }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              style={{ height: "1.6rem", width: "1.6rem" }}
                              onClick={(e) => openMenu(e, data)}
                            >
                              <AddBoxIcon
                                style={{
                                  height: "2rem",
                                  width: "2rem",
                                  marginLeft: "1.5rem",
                                  color: Theme.palette.primary.main,
                                }}
                              />
                            </IconButton>
                          )
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{
                        borderBottom: "1px solid black",
                        height: "2rem",
                      }}
                    ></TableRow>
                  </>
                ))}
                {turno && (
                  <StyledMenu
                    id="me-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuList>
                      <StyledMenuItem onClick={() => handleClose()}>
                        <ListItemIcon
                          onClick={() => {
                            editarTurno();
                            resetModal();
                          }}
                        >
                          <ModeEditOutlineOutlinedIcon
                            style={{
                              color: Theme.palette.primary.main,
                              fontSize: "1.5rem",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Editar"
                          onClick={() => {
                            editarTurno();
                            resetModal();
                          }}
                        />
                      </StyledMenuItem>

                      {turno?.entrega !== "Entregado" && (
                        <StyledMenuItem
                          onClick={() => {
                            handleClose();
                            handleModalCancelar();
                          }}
                        >
                          <ListItemIcon>
                            <DeleteIcon
                              style={{
                                color: Theme.palette.primary.main,
                                fontSize: "1.5rem",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="Cancelar" />
                        </StyledMenuItem>
                      )}

                      {!turno?.entrega && (
                        <StyledMenuItem>
                          <ListItemIcon>
                            <HighlightOffIcon
                              style={{
                                color: Theme.palette.primary.main,
                                fontSize: "1.5rem",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Suspender"
                            onClick={() => {
                              handleClose();
                              setConfirmDialog({
                                title: `¿Está seguro de suspender este turno?`,
                                isOpen: true,
                                onConfirm: () => {
                                  cambiarEstado(turno, "Suspendido");
                                  resetModal();
                                },
                              });
                            }}
                          />
                        </StyledMenuItem>
                      )}

                      <StyledMenuItem
                        onClick={() => {
                          reasignarTurno();
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <CompareArrowsIcon
                            style={{
                              color: Theme.palette.primary.main,
                              fontSize: "1.5rem",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Reasignar" />
                      </StyledMenuItem>

                      {!turno?.entrega && (
                        <StyledMenuItem>
                          <ListItemIcon>
                            <CheckIcon
                              style={{
                                color: Theme.palette.primary.main,
                                fontSize: "1.5rem",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Confirmar Entrega"
                            onClick={() => {
                              handleClose();
                              setConfirmCustomDialog({
                                title: `CONFIRMAR ENTREGA`,
                                isOpen: true,
                                onConfirm: () => {
                                  resetModal();
                                },
                              });
                            }}
                          />
                        </StyledMenuItem>
                      )}
                    </MenuList>
                  </StyledMenu>
                )}
              </TableBody>
            </Table>
          ) : (
            <Grid width="100%">
              <Typography
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                La agenda de este día está vacía o el cliente que busca no tiene
                turno para este día
              </Typography>
            </Grid>
          )}
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <CustomConfirmDialog
            confirmDialog={confirmCustomDialog}
            setConfirmDialog={setConfirmCustomDialog}
            cambiarEstado={cambiarEstado}
            turno={turno}
          />
        </TableContainer>
      )}

      {cancelacion && (
        <ModalCancelacion
          open={handleModalCancelar}
          handleClose={handleModalCancelar}
          turno={turno}
          moment={moment}
          eliminarTurno={eliminarTurno}
        />
      )}
    </Grid>
  );
};

export default TableAgenda;
