import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import {
  Delete,
  LockOpenOutlined,
  Pause,
  PlayArrowOutlined,
} from "@material-ui/icons";

import Create from "@material-ui/icons/Create";

import Theme from "theme";
import useStyles from "./styles";
const TableCustom = ({
  usuarios,
  borrarUsuario,
  activarUsuario,
  pausarUsuario,
  editarUsuario,
  abrirPrivilegios,
  setConfirmDialog,
  handleChange,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: "left",
                  width: "25%",
                  whiteSpace: "nowrap",
                }}
              >
                Nombre y Apellido
              </TableCell>
              <TableCell
                style={{
                  textAlign: "left",
                  width: "25%",
                  whiteSpace: "nowrap",
                }}
              >
                N° Empleado
              </TableCell>
              <TableCell
                style={{
                  textAlign: "left",
                  width: "25%",
                  whiteSpace: "nowrap",
                }}
              >
                Email
              </TableCell>
              <TableCell
                style={{
                  width: "25%",
                  whiteSpace: "nowrap",
                }}
                align="center"
              >
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usuarios?.length > 0
              ? usuarios
                  .sort(function (a, b) {
                    return a.id - b.id;
                  })
                  .map((user) => (
                    <TableRow key={user.id}>
                      <TableCell
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        {user.first_name.toUpperCase()}{" "}
                        {user.last_name.toUpperCase()}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        {user.matricula}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        {user.email}
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="center"
                          style={{ whiteSpace: "nowrap", minWidth: "8rem" }}
                        >
                          <IconButton
                            onClick={() => editarUsuario(user.id)}
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: "0.5rem",
                              borderRadius: 5,
                              backgroundColor: Theme.palette.primary.main,
                              color: "#ffffff",
                            }}
                          >
                            <Create
                              style={{ height: "1.2rem", width: "1.2rem" }}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              setConfirmDialog({
                                title: `¿Está seguro de borrar al usuario ${user.first_name}?`,
                                isOpen: true,
                                onConfirm: () => {
                                  borrarUsuario(user.id);
                                },
                              })
                            }
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: "0.5rem",

                              borderRadius: 5,
                              backgroundColor: Theme.palette.primary.main,
                              color: "#ffffff",
                            }}
                          >
                            <Delete
                              style={{ height: "1.2rem", width: "1.2rem" }}
                            />
                          </IconButton>
                          {user?.is_active ? (
                            <IconButton
                              onClick={() => pausarUsuario(user.id)}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "0.5rem",

                                borderRadius: 5,
                                backgroundColor: Theme.palette.primary.main,
                                color: "#ffffff",
                              }}
                            >
                              <Pause
                                style={{ height: "1.2rem", width: "1.2rem" }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => activarUsuario(user.id)}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "0.5rem",

                                borderRadius: 5,
                                backgroundColor: Theme.palette.primary.main,
                                color: "#ffffff",
                              }}
                            >
                              <PlayArrowOutlined
                                style={{ height: "1.2rem", width: "1.2rem" }}
                              />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => abrirPrivilegios(user)}
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: "0.5rem",
                              borderRadius: 5,
                              backgroundColor: Theme.palette.primary.main,
                              color: "#ffffff",
                            }}
                          >
                            <LockOpenOutlined
                              style={{ height: "1.2rem", width: "1.2rem" }}
                            />
                          </IconButton>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
              : isLoading && (
                  <Grid item style={{ margin: "0.5rem" }}>
                    <Typography>No se encontraron usuarios</Typography>
                  </Grid>
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.memo(TableCustom);
