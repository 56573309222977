/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Modal, Typography } from "@material-ui/core";
import Card from "components/card";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useState } from "react";
import { names } from "./const";
import moment from "moment";
import { toContable } from "constants/funciones/toContable";
import { useEffect } from "react";
import LoadingText from "components/loadingText";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import mostrarErrorEP from "constants/funciones/mostrarError";
import { NotificationContext } from "context/notification/NotificationContext";
import cuentaCorrienteService from "services/cuentaCorriente.service";
import TableGeneric from "components/tableGeneric/tableGeneric";
import RowFacturas from "./rowFacturas";
import RowLineas from "./rowLineas";
import { useStyles } from "./styles";
import SaldoFavor from "./saldoFavor";

export default function CondicionPagoFacturas({
  open,
  setOpen,
  items,
  setItems,
  resetCheckboxes,
  setConfirmDialog,
  setSnackState,
  refreshQueries,
  variosPagosAlasMismasFacturas,
}) {
  const styles = useStyles();
  const { isNotificationsEnabled } = useContext(NotificationContext);

  const { handleSubmit, control, reset, watch } = useForm();

  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(1);
  const defaultItem = { id, [names?.monto]: 0, [names?.credito]: false };
  const [lineas, setLineas] = useState([defaultItem]);
  const [anyCredito, setAnyCredito] = useState(null);
  const [saldoDialog, setSaldoDialog] = useState({
    isOpen: false,
    saldo: 0,
    onConfirm: null,
  });

  const headersFacturas = [
    items?.length > 1 && {
      name: "",
      style: { borderBottom: 0, padding: "0.4rem 0.4rem 0.4rem 0" },
    },
    { name: "Nº Factura", style: { ...styles?.tableCell, minWidth: "6rem" } },
    {
      name: "Fecha Emisión",
      style: { ...styles?.tableCell, minWidth: "7rem" },
    },
    {
      name: "Nº Ord. de Compra",
      style: { ...styles?.tableCell, minWidth: "9rem" },
    },
    { name: "Mts3", style: styles?.tableCell },
    { name: "Monto", style: styles?.tableCell },
    { name: "Saldo", style: styles?.tableCell },
  ];

  const isCheque = (input) => {
    const value = watch(input);

    return value === "Cheque" || value === "E-Cheq";
  };

  const confirmEliminarFactura = (item, indexItem) => {
    setConfirmDialog({
      title: `ELIMINAR FACTURA N° ${item?.num_factura}`,
      isOpen: true,
      onConfirm: () => eliminarFactura(indexItem),
    });
  };

  const confirmEliminarLinea = (indexItem) => {
    setConfirmDialog({
      title: `ELIMINAR LINEA`,
      isOpen: true,
      onConfirm: () => eliminarLinea(indexItem),
    });
  };

  const handleChangeInput = (value, item, name) => {
    item[name] = value;

    if (name === names?.formaPago) {
      item[names?.credito] = value === "Haber de Cuenta Corriente";
    }

    setLineas([...lineas]);
  };

  const handleCredito = (item, value) => {
    if (!anyCredito && value === "Haber de Cuenta Corriente") {
      setAnyCredito(item?.id);
    } else if (anyCredito === item?.id) {
      setAnyCredito(null);
    }
  };

  const eliminarFactura = (index) => {
    items?.splice(index, 1);
    setItems([...items]);
  };

  const agregarLinea = () => {
    setLineas([...lineas, { ...defaultItem, id: id + 1 }]);
    setId(id + 1);
  };

  const eliminarLinea = (index) => {
    const item = lineas[index];
    lineas?.splice(index, 1);

    if (anyCredito && anyCredito === item?.id) {
      setAnyCredito(null);
    }

    setLineas([...lineas]);
  };

  const saldoFavor = () => {
    const saldoItems = items?.reduce(
      (acumulador, item) => acumulador + item?.saldo,
      0,
    );
    const saldoLineas = lineas?.reduce(
      (acumulador, item) => acumulador + item?.monto,
      0,
    );

    return Number((saldoItems - saldoLineas).toFixed(2));
  };

  const controlarSaldo = () => {
    if (saldoFavor() < 0) {
      confirmSaldoFavor();
    } else {
      onSubmit();
    }
  };

  const confirmSaldoFavor = () => {
    setSaldoDialog({
      isOpen: true,
      saldo: saldoFavor(),
      onConfirm: () => onSubmit(),
    });
  };

  const handleClose = () => {
    resetCheckboxes();
    reset();
    setItems([]);
    setLineas([defaultItem]);
    setIsSubmit(false);
    setAnyCredito(null);
    setOpen(!open);
  };

  const onSubmit = async () => {
    setIsSubmit(true);
    const dataSend = {
      facturas: items?.map((item) => item?.id),
      formas_pago: lineas,
    };
    try {
      const { status } = isNotificationsEnabled
        ? await cuentaCorrienteService.cargarPagoFacturas(dataSend)
        : await cuentaCorrienteService.cargarPagoFacturasB(dataSend);
      if (status > 199 && status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Pagos Cargados Exitosamente",
          autoHideDuration: 2000,
        });
        setTimeout(() => {
          refreshQueries();
          handleClose();
        }, [2000]);
      }
    } catch (error) {
      setIsSubmit(false);
      return mostrarErrorEP(error, setSnackState);
    }
  };

  useEffect(() => {
    if (open) {
      const ordenado = items?.sort(
        (itemA, itemB) =>
          moment(itemA?.[names?.facturaFechaEmision]) <
            moment(itemB?.[names?.facturaFechaEmision]) && -1,
      );

      setItems([...ordenado]);
      setLoading(false);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose();
        }
      }}
    >
      <form onSubmit={handleSubmit(controlarSaldo)}>
        <Grid
          container
          item
          xl={9}
          lg={10}
          md={10}
          sm={10}
          xs={11}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
          }}
        >
          <Card title="Agregar Forma de Pago" justifyContent="center">
            {loading ? (
              <LoadingText title="Cargando" />
            ) : (
              <>
                <Grid
                  container
                  style={{
                    padding: "0.5rem 1rem 0 1rem",
                    maxHeight: "65vh",
                    overflow: "auto",
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    Grupo de Facturas
                  </Typography>

                  <TableGeneric
                    size="small"
                    headers={headersFacturas}
                    styles={styles?.tableFacturas}
                  >
                    {items?.map((item, indexItem) => (
                      <RowFacturas
                        key={item?.id}
                        saldo={saldoFavor()}
                        items={items}
                        item={item}
                        indexItem={indexItem}
                        confirmEliminarFactura={confirmEliminarFactura}
                      />
                    ))}
                  </TableGeneric>

                  <Grid container style={{ margin: "0.5rem 0" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      children="Agregar Línea"
                      startIcon={<AddIcon />}
                      style={{ textTransform: "none" }}
                      onClick={agregarLinea}
                    />
                  </Grid>

                  <TableGeneric size="small" styles={styles?.tableLineas}>
                    {lineas?.map((item, indexItem) => (
                      <RowLineas
                        key={item?.id}
                        items={lineas}
                        item={item}
                        indexItem={indexItem}
                        control={control}
                        confirmEliminarLinea={confirmEliminarLinea}
                        handleChangeInput={handleChangeInput}
                        handleCredito={handleCredito}
                        isCheque={isCheque}
                        anyCredito={anyCredito}
                        variosPagosAlasMismasFacturas={
                          variosPagosAlasMismasFacturas
                        }
                      />
                    ))}
                  </TableGeneric>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-end"
                  style={{ padding: "0.5rem 1rem" }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() =>
                        setConfirmDialog({
                          isOpen: true,
                          title: "Se perderán los cambios realizados",
                          onConfirm: () => handleClose(),
                        })
                      }
                    >
                      Volver
                    </Button>
                  </Grid>

                  <Grid item>
                    <ButtonLoading
                      type="submit"
                      disabled={isSubmit}
                      isLoading={isSubmit}
                      style={{ textTransform: "none" }}
                    >
                      Confirmar
                    </ButtonLoading>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "0 0 0.5rem 0.5rem",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{ fontWeight: "bold", marginRight: "1rem" }}
                    >
                      {`Saldo ${saldoFavor() < 0 ? "a Favor" : ""} Total`}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography style={{ marginRight: "1rem" }}>
                      {saldoFavor() >= 0
                        ? toContable(saldoFavor())
                        : toContable(saldoFavor() * -1)}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            <SaldoFavor
              saldoDialog={saldoDialog}
              setSaldoDialog={setSaldoDialog}
            />
          </Card>
        </Grid>
      </form>
    </Modal>
  );
}
