import { makeStyles } from "@material-ui/core/styles";
import Theme from "theme";


const useStyles = makeStyles({
  paper: {
    width: "auto",
    height: "auto",
    overflow: "auto",
},
   headerTable: {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      backgroundColor: Theme.palette.primary.main,
      minHeight: "2.5rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    headerTableTittle: {
      eight: "2rem",
      color: "white",
      textAlign: "center",
    },
    tableContainer: {
      overflow: "scroll",
      scrollbarWidth: "0px",
      //maxHeight: "20rem",
      // width: "50vw",
    },
    table: {
      width: "90%",
    },
    typographyContent1: {
      marginRight: "0.5rem",
      color: "rgba(67, 59, 59, 0.87)",
      fontSize: 16,
      fontWeight: "700"
    },
    typographyContent2: {
      color: "rgba(67, 59, 59, 0.87)",
      fontSize: 18,
    },
  });
  export default useStyles;