import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Controller } from "react-hook-form";

const SelectCustom = ({
  name,
  label,
  control,
  defaultValue,
  children,
  rules,
  funcionAdicional,
  ...props
}) => {
  const labelId = `${name}-label`;
  const handleChange = (e) => {
    if (funcionAdicional) {
      funcionAdicional(e);
    }
  };
  return (
    <FormControl fullWidth variant="outlined" {...props} size="small">
      <InputLabel id={labelId} htmlFor={labelId}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              value={value}
              onChange={(e) => {
                handleChange(e.target.value);
                onChange(e.target.value);
              }}
              labelId={labelId}
              label={label}
              error={!!error}
              {...props}
            >
              {children}
            </Select>
            <FormHelperText error>
              {error ? error.message : null}
            </FormHelperText>
          </>
        )}
        defaultValue={defaultValue} // make sure to set up defaultValue
      />
    </FormControl>
  );
};
export default SelectCustom;
