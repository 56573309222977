import { styled } from "@material-ui/core/styles";
import { TableBody } from "@material-ui/core";

const StyledTableBody = styled(TableBody)(() => ({
  "&.MuiTableBody-root": {
    backgroundColor: "var(--backgroundColor)",
  },
}));

export default StyledTableBody;
