import { createContext, useState } from 'react'

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(true)

    return (
        <NotificationContext.Provider value={{ isNotificationsEnabled, setIsNotificationsEnabled }}>
            {children}
        </NotificationContext.Provider>
    )
}
