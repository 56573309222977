import React from 'react'
import { Grid, Typography, TextField } from "@material-ui/core";
import InputField from "components/Layout/inputCustom/inputField";
import { Autocomplete } from "@material-ui/lab";
import { Controller } from "react-hook-form";
import rules from "constants/rules";

const CrearClienteNuevo = ({
  //rules, 
  control, 
  classes, 
  provincias, 
  handleChangeDni,
  nuevoCliente,
  setNuevoCliente,
  clearErrors,
  setValue
}) => {

    return (
        <>
            <Typography variant="h5"
                style={{
                    fontFamily: 'Roboto',
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "23px",
                    lineHeight: '24px',
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginTop: "1rem"

                }}
            >
                Crear Cliente
            </Typography>
             
            <Grid item container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}>

                    <Typography
                      variant="subtitle1"
                      className={classes.indicacion}
                      style={{ margin: "1rem 0" }}
                    >
                      Nombre Cliente:*
                    </Typography>

                    <InputField
                      name="nombre"
                      label={""}
                      variant="outlined"
                      size="small"
                      control={control}
                      rules={rules.generic}
                      defaultValue={nuevoCliente?.nombre?.toUpperCase()}
                    />

                  </Grid>

                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}>
                    <Typography
                      variant="subtitle1"
                      className={classes.indicacion}
                      style={{ margin: "1rem 0" }}
                    >
                      DNI/CUIT:*
                    </Typography>


                    <InputField
                      name="cuil_dni"
                      label={""}
                      variant="outlined"
                      size="small"
                      control={control}
                      rules={rules.generic}
                      defaultValue={nuevoCliente?.cuil_dni ?? ""}
                      value={nuevoCliente?.cuil_dni}
                      onChange={(e) => {
                        setNuevoCliente({...nuevoCliente, cuil_dni: e.target.value, tipo_de_cliente: handleChangeDni(e.target.value)})
                        //clearErrors("cuil_dni");
                        setValue("cuil_dni", e.target.value);}} 
                    />

                  </Grid>
                </Grid>

                <Grid item container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}>

                    <Typography
                      variant="subtitle1"
                      className={classes.indicacion}
                      style={{ margin: "1rem 0" }}
                    >
                      Provincia:*
                    </Typography>

                    <Controller 
                      control={control}
                      name="provincia"
                      rules={rules.generic}
                      render={({ field: { onChange, value }, fieldState: { error } }) => ( 
                        <Autocomplete
                        size="small"
                        onChange={(e, o) => {
                          onChange(o);
                          setNuevoCliente({...nuevoCliente, provincia: o?.nombre});
                        }}
                        value={value}
                        options={provincias}
                        getOptionLabel={(item) => ((item?.nombre ? item.nombre : ""))}
                        getOptionSelected={(option, value) =>
                          value === undefined || value === "" || option.nombre === value.nombre}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size='small'
                            error={!!error}
                            helperText={error ? error.message : null}
                            required
                            InputLabelProps={{
                              shrink: true, required: true,
                            }}
                          />
                        )}
                      />
                      )}
                      />
                  </Grid>

                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}>
                    <Typography
                      variant="subtitle1"
                      className={classes.indicacion}
                      style={{ margin: "1rem 0" }}
                    >
                      Ciudad:*
                    </Typography>


                    <InputField
                      name="ciudad_cliente"
                      label={""}
                      variant="outlined"
                      size="small"
                      control={control}
                      rules={rules.generic}
                      defaultValue={nuevoCliente?.ciudad?.toUpperCase()}
                    />

                  </Grid>
                </Grid>

                <Grid item container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}>

                    <Typography
                      variant="subtitle1"
                      className={classes.indicacion}
                      style={{ margin: "1rem 0" }}
                    >
                      Dirección:*
                    </Typography>

                    <InputField
                      name="direccion"
                      label={""}
                      variant="outlined"
                      size="small"
                      control={control}
                      rules={rules.generic}
                      defaultValue={nuevoCliente?.direccion?.toUpperCase()}
            
                    />

                  </Grid>

                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}>
                    <Typography
                      variant="subtitle1"
                      className={classes.indicacion}
                      style={{ margin: "1rem 0" }}
                    >
                      Teléfono:*
                    </Typography>


                    <InputField
                      name="num_telefono"
                      label={""}
                      variant="outlined"
                      size="small"
                      control={control}
                      rules={rules.generic}
                      defaultValue={nuevoCliente?.num_telefono}
                    />

                  </Grid>
                </Grid>
        </>

    )
}

export default CrearClienteNuevo