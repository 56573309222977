import React from "react";

const ContainerCustom = ({ children }) => {
  return (
    <div
      style={{
        addingRight: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};

export default ContainerCustom;
