import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import formulaService from "services/formula.service";
import rules from "constants/rules";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import SelectCustom from "components/Layout/select/selectCustom";
import { Save } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQueryClient } from "react-query";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const MaterialesCard = ({
  setSinStackear,
  setVerificado,
  setHabilitarBotonAgregar,
  materiales,
  opcionesChoices,
  cerrarModal,
}) => {
  const { control } = useForm();
  const queryClient = useQueryClient();

  const [opciones, setOpciones] = useState([]);
  const [tipo, setTipo] = useState(materiales?.tipo ? materiales.tipo : " ");
  const [subTipo, setSubTipo] = useState(
    materiales?.subtipo ? materiales.subtipo : " ",
  );
  const [cantidad, setCantidad] = useState();
  const [unidad, setUnidad] = useState();
  const [habilitarBoton, setHabilitarBoton] = useState(true);

  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const onClose = () => {
    setSnackState({ ...snackState, open: false });
    setTipo("");
    setCantidad("");
    setSubTipo("");
    setUnidad("");
  };

  // Funcion para Editar Material
  const editarMaterial = async () => {
    let sendData = [
      {
        id: materiales?.id,
        tipo: tipo ? tipo : materiales.tipo,
        subtipo: subTipo ? subTipo : materiales.subtipo,
        cantidad: cantidad ? cantidad : materiales.cantidad,
        unidad: unidad ? unidad : materiales.unidad,
      },
    ];

    try {
      const res = await formulaService.editarMaterial(sendData);
      if (res.status > 199 && res.status < 299) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Material Editado correctamente",
        });
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: "Error al Editar",
        });
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: error?.response?.data?.Error ?? "Error del Servidor",
      });
      setTimeout(() => {
        cerrarModal();
      }, 1000);
    }
    setHabilitarBoton(false);
  };

  // Funcion para Eliminar Material
  const borrarMaterial = async () => {
    try {
      const res = await formulaService.deleteMaterialFormula(materiales?.id);
      if (res.status > 199 && res.status < 299) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Eliminación exitosa",
        });
        queryClient.invalidateQueries("MaterialesDeFormula");
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: "Error en eliminación",
        });
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: "Error en el servidor",
      });
    }
  };

  const verificarCreacion = () => {
    if (tipo && subTipo && cantidad) {
      setVerificado(true);
      setHabilitarBotonAgregar(true);
      setSinStackear({
        id: materiales?.id,
        tipo,
        subTipo,
        cantidad,
        unidad,
      });
    } else {
      setVerificado(false);
    }
  };

  //
  const handleChangeTipo = (value) => {
    setHabilitarBoton(false);
    let options = opcionesChoices.filter((item) => item.tipo === value);
    setSubTipo("");
    setTipo(value);
    setOpciones(options);
  };

  const handleChangeSubTipo = (value) => {
    setHabilitarBoton(true);
    setSubTipo(value);
    setUnidad(opcionesChoices.find((item) => item.nombre === value)?.unidad);
  };

  const handleChangeCantidad = (value) => {
    setCantidad(value);
    setHabilitarBoton(true);
  };

  useEffect(() => {
    handleChangeTipo(materiales?.tipo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    verificarCreacion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipo, subTipo, cantidad, unidad]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        borderRadius: 10,
      }}
    >
      <Grid container item xl={3} lg={3} md={3} sm={3} xs={4}>
        <SelectCustom
          control={control}
          rules={rules.generic}
          defaultValue={materiales?.tipo ? materiales?.tipo : ""}
          name="tipo"
          label="Tipo*"
          size="small"
          funcionAdicional={(value) => handleChangeTipo(value)}
        >
          <MenuItem value={"Aridos"}>Áridos</MenuItem>
          <MenuItem value={"Cemento"}>Cemento</MenuItem>
          <MenuItem value={"Agua"}>Agua</MenuItem>
          <MenuItem value={"Aditivos"}>Aditivos</MenuItem>
          <MenuItem value={"Otros"}>Otros</MenuItem>
        </SelectCustom>
      </Grid>

      <Grid container item xl={3} lg={3} md={3} sm={3} xs={4}>
        <SelectCustom
          control={control}
          rules={rules.generic}
          defaultValue={subTipo && subTipo}
          name="subtipo"
          label="Nombre*"
          size="small"
          funcionAdicional={(value) => handleChangeSubTipo(value)}
        >
          {opciones.map((item) => (
            <MenuItem key={item.id} value={item.nombre}>
              {item.nombre}
            </MenuItem>
          ))}
        </SelectCustom>
      </Grid>

      <Grid
        container
        item
        xl={2}
        lg={2}
        md={3}
        sm={3}
        xs={4}
        style={{ padding: "0.5rem" }}
      >
        <TextField
          fullWidth
          size="small"
          label="Cantidad*"
          defaultValue={materiales?.cantidad ? materiales.cantidad : 0}
          onChange={(e) => handleChangeCantidad(e.target.value)}
          name="cantidad"
          variant="outlined"
          autoComplete="off"
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
              min: 0,
            },
          }}
        />
      </Grid>

      <Grid
        container
        item
        xl={3}
        lg={3}
        md={3}
        sm={3}
        xs={12}
        direction="row"
        justifyContent="flex-start"
      >
        {materiales?.unidad ? (
          <Typography> {materiales?.unidad ?? " - "} </Typography>
        ) : (
          <Typography>
            {" "}
            {opcionesChoices.find((item) => item.nombre === subTipo)?.unidad ??
              " - "}{" "}
          </Typography>
        )}
        {materiales?.tipo !== null && (
          <>
            <Grid item>
              <IconButton
                style={{ width: "1.6rem", height: "1.6rem", margin: "0 1rem" }}
                onClick={() => editarMaterial()}
                disabled={!habilitarBoton}
              >
                <Save
                  color={habilitarBoton ? "primary" : "#ffff"}
                  style={{ height: "1.6rem", width: "1.6rem" }}
                />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                style={{ width: "1.6rem", height: "1.6rem" }}
                onClick={() => borrarMaterial()}
                //disabled={!habilitarBoton}
              >
                <DeleteIcon
                  color={"primary"}
                  style={{ height: "1.6rem", width: "1.6rem" }}
                />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>

      <CustomSnackbar
        message={snackState.message}
        open={snackState.open}
        severity={snackState.severity}
        onClose={onClose}
      />
    </Grid>
  );
};

export default MaterialesCard;
