/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import Protected from "protected";
import { useReactToPrint } from "react-to-print";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import PrintIcon from "@material-ui/icons/Print";
import Logo from "../../../assets/img/LogoAyupi.png";
import { ReactComponent as RemintoR } from "assets/svg/remito_r.svg";
import { ReactComponent as RemintoX } from "assets/svg/remito_x.svg";

import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory, useParams } from "react-router-dom";
import remitosService from "services/remitos.service";
import RootContainerWhite from "layout/rootContainerWhite";

const VisualizarRemito = () => {
  const history = useHistory();
  const { id } = useParams();
  const styles = useStyles();
  const componentRef = useRef();
  const [loadingRemito, setLoadingRemito] = useState(false);
  const [dataRemito, setDataRemito] = useState();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  }); 

  const { presupuesto } = history?.location?.state;

  const obtenerRemito = async () => {
    let endpoint = "";
    if (presupuesto) {
      endpoint = await remitosService.getRemitosBId(id);
    } else {
      endpoint = await remitosService.getRemitosId(id);
    }
    try {
      const res = endpoint;
      if (res.status > 199 && res.status < 299) {
        setDataRemito(res.data);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    setLoadingRemito(true);
    setTimeout(() => {
      setLoadingRemito(false);
    }, 1000);
  }, []);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  useEffect(() => {
    obtenerRemito();
  }, []);

  return (
    <Protected>
      <RootContainerWhite>
      <>
        {!loadingRemito && !!dataRemito ? (
          <Grid container style={{ padding: "0 4rem 8rem 4rem" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              item
              style={{ marginTop: "0.5rem" }}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#000000",
                    paddingLeft: "1rem",
                  }}
                >
                  Visualizar Remito
                </Typography>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    margin: "0.5rem 0.5rem 0.5rem 0",
                  }}
                  onClick={() => history.goBack()}
                >
                  Volver
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    textTransform: "capitalize",
                    margin: "0.5rem 0",
                  }}
                  startIcon={<PrintIcon />}
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
              </Grid>
            </Grid>
            <Grid container item className={styles.generalContainer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                className={styles.headerTable}
              >
                <Typography variant="h5" className={styles.headerTableTittle}>
                  Remito N° {dataRemito?.nro_remito}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              ref={componentRef}
              style={{ border: "1px solid black", backgroundColor: "#ffffff" }}
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems:
                    windowDimenion.winWidth < 800 ? "center" : "flex-start",
                  flexDirection:
                    windowDimenion.winWidth < 800 ? "column" : "row",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderBottom: "1px solid black",
                }}
                container
                spacing={1}
              >
                <Grid item>
                  <Box
                    component="img"
                    sx={{
                      height: 90,
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={styles.textStyle}>
                    Dom. Fiscal:
                  </Typography>
                  <Typography className={styles.textStyle}>
                    Av. 9 de Julio 635 - Rcia. Chaco
                  </Typography>

                  <Typography
                    className={styles.textStyle}
                    style={{ paddingTop: "1rem" }}
                  >
                    Ruta 16 - Km. 18,5
                  </Typography>
                  <Typography className={styles.textStyle}>
                    Tel: 0362-4349303 - 4765771
                  </Typography>
                  <Typography className={styles.textStyle}>
                    3500 Resistencia - Chaco
                  </Typography>

                  <Typography
                    className={styles.textStyle}
                    style={{ paddingTop: "1rem" }}
                  >
                    IVA RESPONSABLE INSCRIPTO
                  </Typography>
                </Grid>
                <Grid item>
                  {dataRemito.presupuesto ? <RemintoX /> : <RemintoR />}
                </Grid>
                <Grid item style={{ display: "flex", flexDirection: "row" }}>
                  <Grid style={{ display: "flex", flexDirection: "column" }}>
                    <Typography className={styles.textStyle2}>
                      Remito
                    </Typography>
                    <Typography className={styles.textStyle2}>
                      N° {dataRemito.nro_remito}
                    </Typography>
                    <Typography className={styles.textStyle}>
                      Documento no válido como Factura
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ display: "flex", flexDirection: "row" }}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      D
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      M
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                      }}
                    >
                      A
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      width: "100px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                      }}
                    >
                      {moment(dataRemito?.fecha).format("DD")}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                      }}
                    >
                      {moment(dataRemito?.fecha).format("MM")}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        padding: "5px",
                      }}
                    >
                      {moment(dataRemito?.fecha).format("yyyy")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  padding: "1.5rem",
                  borderBottom: "1px solid black",
                }}
              >
                <Grid
                  item
                  container
                  spacing={1}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      Cliente:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.cliente_id?.nombre}
                    </Typography>
                  </Grid>
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      CUIT CLIENTE:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.cliente_id?.cuil_dni}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: ".6rem",
                  }}
                >
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      Dirección de la Obra:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.direccion_entrega}
                    </Typography>
                  </Grid>
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      Obra:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.obra ?? ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: ".6rem",
                  }}
                >
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      Ciudad:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.ciudad}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  padding: "1.5rem",
                  borderBottom: "1px solid black",
                }}
                container
              >
                <Grid
                  item
                  container
                  spacing={1}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      BOCA DE CARGA Nº:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.planta}
                    </Typography>
                  </Grid>
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      HORMIGÓN TIPO:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.formula_id?.nombre}
                    </Typography>
                  </Grid>
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      M³ DE HORMIGÓN:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.cantidad_mc}
                    </Typography>
                  </Grid>
                  {dataRemito?.observacion_formula && (
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        OBSERVACION FORMULA:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {dataRemito?.observacion_formula}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  padding: "1.5rem",
                  borderBottom: "1px solid black",
                  width: "100%",
                }}
                container
              >
                <Typography className={styles.textPrimary}>ENTREGA</Typography>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  padding: "1.5rem",
                  borderBottom: "1px solid black",
                }}
                container
              >
                <Grid
                  item
                  container
                  spacing={1}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      Patente:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.patente}
                    </Typography>
                  </Grid>
                  <Grid item className={styles.textContent}>
                    <Typography className={styles.textPrimary}>
                      Chofer:
                    </Typography>
                    <Typography className={styles.textSecondary}>
                      {dataRemito?.apynom_chofer}
                    </Typography>
                  </Grid>
                </Grid>
                {dataRemito?.turno && (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: ".6rem",
                    }}
                  >
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Observaciones:
                      </Typography>
                      <Typography className={styles.textSecondary}>
                        {dataRemito?.observaciones_remito}
                      </Typography>
                    </Grid>
                    <Grid item className={styles.textContent}>
                      <Typography className={styles.textPrimary}>
                        Turno:
                      </Typography>
                      <Typography variant="h5" className={styles.textSecondary}>
                        {moment(
                          dataRemito.turno.hora_salida,
                          "HH:mm:ss",
                        ).format("HH:mm")}{" "}
                        {moment(dataRemito.turno.fecha_salida).format(
                          "DD/MM/yyyy",
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              paddingTop: "14rem",
            }}
          >
            <CircularProgress />
          </Grid>
        )}
      </>
      </RootContainerWhite>
    </Protected>
  );
};
export default VisualizarRemito;
