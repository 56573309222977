import { styled } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";

const StyledTableCell = styled(TableCell)(() => ({
  "&.MuiTableCell-head": {
    fontWeight: "var(--fontWeight,bold)",
  },
}));

export default StyledTableCell;
