export const TablaTipoGeneral = {
  headers: [
    { value: "Fecha", type: "order", id_ord: "fecha" },
    { value: "Factura/Remito", type: "order", id_ord: "factura_remito" },
    { value: "Monto", type: "order", id_ord: "monto" },
    { value: "Productos/Servicios", type: "normal" },
  ],
  body: [
    { value: "fecha", type: "date", default: "-" },
    { value: "factura_remito", type: "none", default: "-" },
    { value: "monto", type: "money", default: "-" },
    { value: "producto_servicio", type: "none", default: "-" },
  ],
};
