// Hook para traer Todas las Fórmulas sin paginar
import { useQuery } from "react-query";
// Services
import formulaService from "services/formula.service";

export const useGetFormulas = (filters) => {
  // FUNCION PARA TRAER TODAS LAS FORMULAS
  const obtenerFormulas = async () => {
    const { data } = await formulaService.getFormulas();
    return data;
  };

  // FUNCION PARA FILTRAR LOS CLIENTES POR NOMBRE Y CUIT/DNI
  const filtrarFormulaPorNombre = (formulas) => {
    if (filters) {
      return formulas.filter((unaFormula) =>
        unaFormula.nombre.toLowerCase().includes(filters.toLowerCase()),
      );
    } else {
      return formulas;
    }
  };

  return useQuery(["formulas"], () => obtenerFormulas(), {
    select: (formulas) => filtrarFormulaPorNombre(formulas),
  });
};
