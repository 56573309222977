import {
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import ConfirmDialog from "components/ConfirmDialog";
import { toContable } from "constants/funciones/toContable";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "auto",
    height: "auto",
    overflow: "auto",
  },
}));

const SaldoFavor = ({
  open,
  handleClose,
  onSubmit,
  cambiandoCondicion,
  dataFactura,
  saldos,
}) => {
  const classes = useStyles();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  return (
    <Modal open={open} className={classes.paper}>
      <Grid
        container
        item
        xl={3}
        lg={3}
        md={6}
        sm={10}
        xs={10}
        style={{
          backgroundColor: "#ffffff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
          minHeight: "30vh",
          overflow: "auto",
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction="column"
          container
          style={{ marginTop: "1rem" }}
        >
          <Typography item align="center" style={{ fontWeight: 700 }}>
            ¿Desea confirmar la Carga de Medios de Pago?
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            padding: "1rem 1rem 0 1rem",
            marginBottom: "5rem",
          }}
        >
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow style={{ backgroundColor: "#CCCFE4" }}>
                  <TableCell
                    align="left"
                    style={{
                      padding: "0 0.5rem 0 1rem",
                      borderBottom: 0,
                    }}
                  >
                    Nº Factura
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      padding: "0 0.5rem 0 0",
                      borderBottom: 0,
                    }}
                  >
                    Saldo a Favor
                  </TableCell>
                </TableRow>
              </TableHead>
              {dataFactura
                ?.filter((item) => item?.saldo < 0)
                ?.map((factura) => (
                  <TableBody>
                    <TableRow key={factura?.id}>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0 0.5rem 0 1rem",
                          borderBottom: 0,
                        }}
                      >
                        {factura?.num_factura ?? "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0 0.5rem 0 0",
                          borderBottom: 0,
                        }}
                      >
                        {toContable(factura?.saldo * -1)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          style={{ margin: "0.5rem 1rem" }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            children="Volver"
            style={{ marginRight: "0.5rem", textTransform: "none" }}
            onClick={handleClose}
          />
          <ButtonLoading
            isLoading={cambiandoCondicion}
            disabled={cambiandoCondicion}
            children="Confirmar"
            style={{ textTransform: "none" }}
            onClick={onSubmit}
          />
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          style={{
            backgroundColor: "#CCCFE4",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            style={{ margin: "0.5rem 0 0.5rem 0" }}
          >
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                marginRight: "0.5rem",
                fontWeight: 700,
              }}
            >
              Saldo a Favor Total:
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                marginRight: "1rem",
              }}
            >
              {toContable(
                saldos?.filter((item) => item < 0).reduce((a, b) => a + b, 0) *
                  -1,
              )}
            </Typography>
          </Grid>
        </Grid>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </Grid>
    </Modal>
  );
};
export default SaldoFavor;
