import { useQuery } from "react-query";
import proveedoresService from "services/proveedores.service";

// FUNCION PARA TRAER LISTA DE TODOS LOS PROVEEDORES
const getAllProviders = async ({
    filtro,
    debouncedValue,
  }) => {
    try {
      // make params to send api
      const params = {
        ...filtro,
        search: debouncedValue,
      };
      //check endpoint to make request
      const endpoint = `getFilteredAllProviders`;
      const res = await proveedoresService[endpoint](params);
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  };

  // FUNCION PARA TRAER DETALLE DE UN PROVEEDOR
const getDetailsProviderById = async ({
  idProvider
}) => {
  try {
    const endpoint = `getDetailsProviderById`;
    const res = await proveedoresService[endpoint](idProvider);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useGetAllProviders({
    filtro,
    debouncedValue,
    onSuccess,
  }) {
    return useQuery(
      ["Proveedores", filtro, debouncedValue],
      () =>
        getAllProviders({
          filtro,
          debouncedValue,
        }),
      {
        onSuccess: (data) => {
          onSuccess(data);
        },
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
      },
    );
  }

export function useGetDetailsProviderById({
  idProvider
}) {
  return useQuery(
    ["Proveedores", idProvider],
    () =>
     getDetailsProviderById({
        idProvider
      }),
    {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      enabled: idProvider ? true : false,
    },
  );
}

// CHOFERES AUTORIZADOS

// FUNCION PARA TRAER LISTA DE TODOS LOS CHOFERES AUTORIZADOS
const getAllAuthorizedDrivers = async ({
  filtro,
  debouncedValue,
}) => {
  try {
    // make params to send api
    const params = {
      ...filtro,
      search: debouncedValue,
    };
    //check endpoint to make request
    const endpoint = `getFilteredAllAuthorizedDrivers`;
    const res = await proveedoresService[endpoint](params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useGetAllAuthorizedDrivers({
  filtro,
  debouncedValue,
  onSuccess,
}) {
  return useQuery(
    ["ChoferesAutorizados", filtro, debouncedValue],
    () =>
      getAllAuthorizedDrivers({
        filtro,
        debouncedValue,
      }),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );
}

// MATERIALES DE PROVEEDORES

// FUNCION PARA TRAER LISTA DE TODOS LOS MATERIALES DE PROVEEDORES
const getAllProvidersMaterials = async ({
  filtro,
  debouncedValue,
}) => {
  try {
    // make params to send api
    const params = {
      ...filtro,
      search: debouncedValue,
    };
    //check endpoint to make request
    const endpoint = `getFilteredAllProvidersMaterials`;
    const res = await proveedoresService[endpoint](params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useGetAllProvidersMaterials({
  filtro,
  debouncedValue,
  onSuccess,
}) {
  return useQuery(
    ["MaterialesDeProveedores", filtro, debouncedValue],
    () =>
      getAllProvidersMaterials({
        filtro,
        debouncedValue,
      }),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );
}

// FUNCION PARA TRAER LISTA DE TODOS LOS MATERIALES DE PROVEEDORES


const getAllProvidersMaterialsNoPaginated = async ({
  filtro,
  debouncedValue,
}) => {
  try {
    // make params to send api
    const params = {
      ...filtro,
      search: debouncedValue,
    };
    //check endpoint to make request
    const endpoint = `getAllProvidersMaterialsNoPaginated`;
    const res = await proveedoresService[endpoint](params);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useAllProvidersMaterialsNoPaginated({
  filtro,
  debouncedValue,
}) {
  return useQuery(
    ["MaterialesDeProveedoresSinPaginar", filtro, debouncedValue],
    () =>
    getAllProvidersMaterialsNoPaginated({
        filtro,
        debouncedValue,
      }),
    {  refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );
}

// FUNCION PARA TRAER LISTA DE TODOS LOS MATERIALES DE PROVEEDORES


const getProvidersMaterialsTypes = async ({
  idMaterial,
}) => {
  try {
   //check endpoint to make request
    const endpoint = `getProvidersMaterialsTypes`;
    const res = await proveedoresService[endpoint](idMaterial);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export function useGetProvidersMaterialsTypes({
  idMaterial,
}) {
  return useQuery(
    ["TiposDeMaterialesDeProveedores", idMaterial],
    () =>
    getProvidersMaterialsTypes({
      idMaterial
    }),
    {  refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      enabled: idMaterial ? true : false,
    },
  );
}