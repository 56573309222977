import { useState, useCallback } from "react";
import Protected from "protected";
import {
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import axios from "../../../services/axios.config";
// Iconos
import UsuarioService from "services/usuario.service";
import ConfirmDialog from "components/ConfirmDialog";
import useStyles from "./styles";
import TableCustom from "components/tableCustom";
import RootContainer from "layout/rootContainer";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import SearchBar from "components/Layout/searchBar/SearchBar";

const AdminClaves = () => {
  //hooks
  const [usuarios, setUsuarios] = useState([]);
  const history = useHistory();
  const queryClient = useQueryClient();

  const classes = useStyles();

  // Función para abrir alert snack
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  // Función para cerrar alert snack
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Editar Usuario
  const editarUsuario = (idUser) => {
    history.push("/edit-user-form", idUser);
  };

  const abrirPrivilegios = useCallback(
    (user) => {
      let dataUSer = {
        id: user.id,
        nombre: user.first_name,
        apellido: user.last_name,
        matricula: user.matricula,
        rol: user.rol,
      };
      history.push("/panel-admin/admin-privilegios", dataUSer);
    },
    [history]
  );

  // Función para traer los usuarios
  const obtenerUsuario = async () => {
    try {
      const res = await UsuarioService.getUsers();
      return res.data;
    } catch (error) {
      setSnackState({
        open: true,
        severity: "error",
        message: "Error al obtener los usuarios",
      });
      return error;
    }
  };

  const [siguiente, setSiguiente] = useState(null);
  const [anterior, setAnterior] = useState(null);
  const [actual, setActual] = useState();
  const [total, setTotal] = useState();

  const siguientePagina = async () => {
    const res = await axios.get(siguiente);
    if (res.status === 200) {
      setUsuarios(res.data.results);
      setSiguiente(res.data.next);
      setAnterior(res.data.previous);
      setActual(res.data.actual);
      setTotal(res.data.total_paginas);
    }
  };

  const anteriorPagina = async () => {
    const res = await axios.get(anterior);
    if (res.status === 200) {
      setUsuarios(res.data.results);
      setSiguiente(res.data.next);
      setAnterior(res.data.previous);
      setActual(res.data.actual);
      setTotal(res.data.total_paginas);
    }
  };

  const { data: ArrayUsuarios, isLoading } = useQuery(
    "Usuarios",
    obtenerUsuario,
    {
      onSuccess: (data) => {
        setUsuarios(data.results);
        setActual(data.actual);
        setTotal(data.total_paginas);
        setSiguiente(data.next);
        setAnterior(data.previous);
      },
      onError: (error) => {
        console.log("Error", error);
      },
    }
  );

  // Funcion para Inactivar a un Usuario
  const pausarUsuario = useCallback(
    async (id) => {
      try {
        const res = await UsuarioService.inactivateUser(id);
        if (res.status > 199 && res.status < 299) {
          setSnackState({
            open: true,
            severity: "success",
            message: "Usuario pausado exitosamente",
          });
          queryClient.invalidateQueries("Usuarios");
        } else {
          setSnackState({
            open: true,
            severity: "error",
            message: "Error al pausar Usuario",
          });
        }
      } catch (error) {
        setSnackState({
          open: true,
          severity: "info",
          message: "Error de conexión Inactivar",
        });
      }
    },
    [queryClient]
  );

  const activarUsuario = useCallback(
    async (id) => {
      try {
        const res = await UsuarioService.activateUser(id);
        if (res.status > 199 && res.status < 299) {
          setSnackState({
            open: true,
            severity: "success",
            message: "Usuario activado exitosamente",
          });
          queryClient.invalidateQueries("Usuarios");
        } else {
          setSnackState({
            open: true,
            severity: "error",
            message: "Error al activar Usuario",
          });
        }
      } catch (error) {
        setSnackState({
          open: true,
          severity: "info",
          message: "Error de conexión",
        });
      }
    },
    [queryClient]
  );

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // Funcion para Borrar un usuario
  const borrarUsuario = async (id) => {
    try {
      const res = await UsuarioService.deleteUser(id);
      if (res.status > 199 && res.status < 299) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Usuario borrado exitosamente",
        });
        queryClient.invalidateQueries("Usuarios");
      } else {
        setSnackState({
          open: true,
          severity: "error",
          message: "Error al borrar Usuario",
        });
      }
    } catch (error) {
      setSnackState({
        open: true,
        severity: "info",
        message: "Error de conexión borrar",
      });
    }
  };

  // Controlador del inputSearch
  const handleChange = (e) => {
    filtrar(e.target.value);
  };

  // Funcion para filtrar por Email y Nombre Apellido
  const filtrar = (terminoBusqueda) => {
    if (ArrayUsuarios.results.length > 0) {
      // eslint-disable-next-line array-callback-return
      let resultadosBusqueda = ArrayUsuarios.results.filter((elemento) => {
        if (
          elemento.email
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase()) ||
          elemento.first_name
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase()) ||
          elemento.last_name
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return elemento;
        }
      });
      setUsuarios(resultadosBusqueda);
    }
  };

  return (
    <Protected>
      <RootContainer>
        <div
          style={{
            paddingTop: "1rem",
            paddingRight: "1rem",
            width: "100%",
            height: "100%",
          }}
        >
          <div className={classes.textFieldContainer}>
            <Grid>
              <SearchBar
                placeholder="Buscar por Nombre o Email"
                onChange={handleChange}
              />
            </Grid>
          </div>
          <Grid container item className={classes.generalContainer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              className={classes.headerTable}
            >
              <Typography variant="h5" className={classes.headerTableTittle}>
                Administrador de Usuarios
              </Typography>
            </Grid>

            {isLoading ? (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                style={{ width: "5vw", height: "150px" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <TableCustom
                  usuarios={usuarios}
                  editarUsuario={editarUsuario}
                  borrarUsuario={borrarUsuario}
                  activarUsuario={activarUsuario}
                  pausarUsuario={pausarUsuario}
                  abrirPrivilegios={abrirPrivilegios}
                  setConfirmDialog={setConfirmDialog}
                  isLoading={isLoading}
                />
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  {anterior && (
                    <IconButton onClick={() => anteriorPagina()}>
                      <ArrowBack />
                    </IconButton>
                  )}
                  <Typography>
                    {actual}/{total} Páginas
                  </Typography>

                  {siguiente && (
                    <IconButton onClick={() => siguientePagina()}>
                      <ArrowForward />
                    </IconButton>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </div>

        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};
export default AdminClaves;
