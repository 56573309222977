// MUI React && React Hook and Libraries imports 
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetAllAuthorizedDrivers } from "../../hook";
import { useDebounce } from "hook/useDebounce";
import {
    Grid,
    Typography,
    TableRow,
    TableCell,
    CircularProgress,
    IconButton,
    Button,
} from "@material-ui/core";
import Theme from "theme";
import { LogIn } from "context/LogInContext";
import Pagination from "@material-ui/lab/Pagination";

// ZUSTAND LIBRARIES IMPORTS STORE
import { useChoferesAutorizadosActions, useChoferesAutorizadosStore, useSearchNombreDniEmpresa } from "../../store";
import { shallow } from "zustand/shallow";

// COMPONENTS
import Protected from "protected";
import RootContainer from "layout/rootContainer";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import Card from "components/card";
import TableGeneric from "components/tableGeneric/tableGeneric";
import { TableMaterialsList } from "../../constants/TableChoferesAutorizados";
import SearchBar from "components/Layout/searchBar/SearchBar";
import LoadingText from "components/loadingText";

// SERVICES
import UsuarioService from "services/usuario.service";

//ICONS
import Create from "@material-ui/icons/Create";
import ConfirmDialog from "components/ConfirmDialog";
import proveedoresService from "services/proveedores.service";
import CrearEditarChoferModal from "../crear-editar-chofer/CrearEditarChoferModal.js";
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

// STYLES
import { useStyles } from "../../materiales/listado-materiales/ListadoMaterialesProveedorStyles";


const ChoferesAutorizados = () => {
    const classes = useStyles();
    const history = useHistory();
    const { logged } = useContext(LogIn);

    // CABECERA DE TABLA
    const { headers } = TableMaterialsList;

    // ESTADOS PARA VALIDAR PERMISO A MODULO
    const [verificando, setVerificando] = useState(true);
    const [acceso, setAcceso] = useState(false);

    // ESTADOS PARA NAVEGACION ENTRE PAGINAS
    const [actual, setActual] = useState();
    const [total, setTotal] = useState();

    // ESTADO PARA ENVIAR DATOS DE CHOFER A EDITAR
    const [materialSeleccionado, setMaterialSeleccionado] = useState();

    // DECLARACION DE ESTADO DE SNACKBAR
    const [snackState, setSnackState] = useState({
        message: "no message",
        open: false,
        autoHideDuration: 2000,
    });

    // MODAL DE CONFIRMACION AL SALIR DEL FORM DE CARGA
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        onConfirm: null,
    });

    const [modalCreateEditMaterialVisibility, setModalCreateEditMaterialVisibility] =
        useState(false);

    // CERRAR MODAL DE CREAR EDITAR MATERIAL
    const handleCloseModalCreateEditMaterial = () => {
        setMaterialSeleccionado();
        setModalCreateEditMaterialVisibility(false);
    };

    // FUNCION ONSUCCES CREATE EDIT CHOFER MODAL
    const onSuccessCreateEditMaterial = (message) => {
        setSnackState({
            open: true,
            severity: "success",
            message: `${message}`,
        })
        setMaterialSeleccionado();
        refetchDrivers();
    }

    // FUNCION ONERROR CREATE EDIT MATERIAL MODAL
    const onErrorCreateEditMaterial = (message) => {
        setSnackState({
            open: true,
            severity: "error",
            message: `${message}`,
        })
        setMaterialSeleccionado();
        refetchDrivers();
    }

    // FUNCION EDITAR MATERIAL
    const editarMaterial = (material) => {
        setMaterialSeleccionado(material);
        setModalCreateEditMaterialVisibility(true)
    }
    // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
    function onClose() {
        setSnackState({ ...snackState, open: false });
    }

    // FUNCION VERIFICAR ACCESOS A MODULO  
    /**
     * ! Llevarlo a un hook
     */

    const verificarAcceso = async () => {
        setAcceso(true);
        try {
            const res = await UsuarioService.checkAccess({ modulo: "proveedores" });
            if (res.status === 200) {
                setVerificando(false);
                setAcceso(true);
            } else {
                setAcceso(false);
                setVerificando(false);
                history.push("/error-permiso", { modulo: "proveedores" });
            }
        } catch (error) {
            setAcceso(true);
            setVerificando(false);
            history.push("/error-permiso", { modulo: "proveedores" });
        }
    };

    // ESTADO DE LOS FILTROS CON ZUSTAND
    const filtro = useChoferesAutorizadosStore(
        (state) => ({
            tipo: state.tipo,
            page: state.page,
        }),
        shallow,
    );

    const searchText = useSearchNombreDniEmpresa();

    // ACTIONS STATE ZUSTAND
    const {
        setSearch,
        setPage,
    } = useChoferesAutorizadosActions();

    // USE DEBOUNCE HOOK FOR INPUT TEXT
    const debouncedValue = useDebounce(searchText, 1500);

    // Query Success
    const onSuccess = (data) => {
        // Seteo los datos del paginado
        setActual(data?.actual);
        setTotal(data?.total_paginas);
    };

    // QUERY PARA OBTENER LISTADO DE CHOFERES
    const {
        data: driversList,
        isLoading: loadingDriverList,
        isFetching: fetchingDrivers,
        refetch: refetchDrivers
    } = useGetAllAuthorizedDrivers({
        filtro,
        debouncedValue,
        onSuccess,
    });

    // FUNCION DE BUSCADOR POR NOMBRE DE MATERIAL O TIPO DE MATERIAL
    const handleSearch = async (text) => {
        setSearch(text);
    };

    // FUNCION ELIMINAR PROVEEDOR
    const cambiarEstadoChofer = async ({ id, estado }) => {
        let body = {
            estado: !estado
        }
        try {
            const res = await proveedoresService.editAuhthorizedDriver(id, body);
            if (res.status > 199 && res.status < 300) {
                setSnackState({
                    open: true,
                    severity: "success",
                    message: `Cambio de Estado a Chofer correctamente`,
                });
                refetchDrivers();
            } else {
                setSnackState({
                    open: true,
                    severity: "error",
                    message: "Error al cambiar estado a chofer",
                });
            }
        } catch (error) {
            setSnackState({
                open: true,
                severity: "error",
                message: "Error al cambiar estado a chofer",
            });
        }
    };

    // VERIFICACIÓN DE PERMISOS
    useEffect(() => {
        if (logged) {
            verificarAcceso();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Protected>
            <RootContainer>
                {verificando ? (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                        style={{
                            marginTop: "15rem",
                        }}
                    >
                        <CircularProgress
                            style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
                        />
                        <Typography style={{ color: "#000000" }}>
                            Verificando permisos
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        {acceso && (
                            <>
                                <Grid
                                    container
                                    item
                                    style={{ padding: "1rem 1rem 0 1rem" }}
                                >

                                    <Grid
                                        container
                                        item
                                        direction="row"

                                    >
                                        <Grid
                                            md={4}
                                            xs={12}
                                            style={{ marginTop: "0.5rem" }}
                                            container
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                            item
                                        >
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    style={{ maxHeight: "2rem" }}
                                                    onClick={() =>
                                                        setModalCreateEditMaterialVisibility(true)
                                                    }
                                                >
                                                    Nuevo Chofer
                                                </Button>
                                            </Grid>

                                        </Grid>

                                        <Grid
                                            md={8}
                                            xs={12}
                                            container
                                            justifyContent={"flex-end"}
                                            style={{ marginTop: "0.5rem" }}
                                            spacing={1}
                                            item
                                        >
                                            <Grid item>
                                                <SearchBar
                                                    name="search"
                                                    placeholder="Nombre, DNI o Empresa"
                                                    defaultValue={searchText}
                                                    onChange={(e) => {
                                                        handleSearch(e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    style={{ padding: "1rem 1rem 0 1rem" }}
                                >
                                    <Card title="Listado de Choferes Autorizados" justifyContent="center">
                                        {loadingDriverList || fetchingDrivers ? (
                                            <LoadingText title="Cargando Listado de Choferes" />
                                        ) : (<>
                                            {driversList?.results?.length > 0 ? (
                                                <>
                                                    <TableGeneric
                                                        size="small"
                                                        stickyHeader
                                                        headers={headers}
                                                        styles={classes?.tableProvidersMaterials}
                                                    >
                                                        {driversList?.results?.length > 0 && (
                                                            driversList?.results?.map((item, index) => (
                                                                <TableRow
                                                                    hover
                                                                    key={index}
                                                                    style={{
                                                                        height: "3rem",
                                                                    }}
                                                                >
                                                                    <TableCell align="left">
                                                                        {item?.nombre?.toUpperCase() ?? "-"}
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {item?.dni ?? "-"}
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {item?.proveedor?.toUpperCase() ?? "-"}
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {item?.estado ? "ACTIVO" : "INACTIVO"}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid
                                                                            container
                                                                            item
                                                                            direction="row"
                                                                            justifyContent="center"
                                                                            style={{
                                                                                whiteSpace: "nowrap",
                                                                                minWidth: "8rem",
                                                                            }}
                                                                        >

                                                                            <IconButton
                                                                                onClick={() => editarMaterial(item)}
                                                                                style={{
                                                                                    width: "1.5rem",
                                                                                    height: "1.5rem",
                                                                                    marginLeft: "0.5rem",
                                                                                    borderRadius: 5,
                                                                                    backgroundColor:
                                                                                        Theme.palette.primary.main,
                                                                                    color: "#ffffff",
                                                                                }}
                                                                            >
                                                                                <Create
                                                                                    style={{
                                                                                        height: "1.2rem",
                                                                                        width: "1.2rem",
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    setConfirmDialog({
                                                                                        title: `Cambiar a estado ${item?.estado ? "INACTIVO" : "ACTIVO"} a Chofer ${item?.nombre.toUpperCase()}`,
                                                                                        isOpen: true,
                                                                                        onConfirm: () => {
                                                                                            cambiarEstadoChofer(item);
                                                                                        },
                                                                                    })
                                                                                }
                                                                                style={{
                                                                                    width: "1.5rem",
                                                                                    height: "1.5rem",
                                                                                    marginLeft: "0.5rem",
                                                                                    borderRadius: 5,
                                                                                    backgroundColor:
                                                                                        Theme.palette.primary.main,
                                                                                    color: "#ffffff",
                                                                                }}
                                                                            >
                                                                                <LockOpenOutlinedIcon
                                                                                    style={{
                                                                                        height: "1.2rem",
                                                                                        width: "1.2rem",
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        )}
                                                    </TableGeneric>
                                                    <Grid
                                                        container
                                                        item
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignContent="center"
                                                        alignItems="center"
                                                        style={{ margin: "0.5rem" }}
                                                    >
                                                        <Pagination
                                                            count={total}
                                                            color="primary"
                                                            onChange={(event, value) => setPage(value)}
                                                            page={filtro?.page}
                                                        />
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                    style={{ margin: "0.5rem" }}
                                                >
                                                    <Typography align="center" variant="subtitle1">
                                                        {`No se encontraron Choferes ${searchText && "por Nombre, DNI o Empresa buscado"}`}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </>)}
                                    </Card>
                                </Grid>
                            </>
                        )}
                    </>
                )}
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                />
                <CustomSnackbar
                    message={snackState.message}
                    open={snackState.open}
                    severity={snackState.severity}
                    autoHideDuration={snackState.autoHideDuration}
                    onClose={onClose}
                />
                {modalCreateEditMaterialVisibility &&
                    <CrearEditarChoferModal
                        choferSeleccionado={materialSeleccionado}
                        onClose={handleCloseModalCreateEditMaterial}
                        open={modalCreateEditMaterialVisibility}
                        onError={onErrorCreateEditMaterial}
                        onSuccess={onSuccessCreateEditMaterial}
                    />
                }
            </RootContainer>
        </Protected>
    )
}

export default ChoferesAutorizados