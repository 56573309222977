import { React } from "react";
import Protected from "protected";
import { Grid, Typography } from "@material-ui/core";
import RootContainer from "layout/rootContainer";

const ErrorVerificacion = () => {
  return (
    <Protected>
    <RootContainer>
      <div
        style={{
          addingRight: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: window.innerHeight,
        }}
      >
        <Grid
          container
          item
          xl={3}
          lg={4}
          md={6}
          sm={8}
          xs={12}
          style={{
            borderRadius: 10,
            backgroundColor: "#ffffff",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            marginTop: "1rem",
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            style={{ textAlign: "center", padding: "2rem" }}
          >
            <Typography
              color="primary"

              style={{ fontWeight: "bold" }}
            >
              ¡ERROR!
            </Typography>
            <Typography

              style={{
                fontSize: "1.4rem",
                fontWeight: "normal",
                marginTop: "1rem",
              }}
            >
              El usuario ya está verificado.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </RootContainer>
    </Protected>
  );
};
export default ErrorVerificacion;
