/* eslint-disable react-hooks/exhaustive-deps */
//REACT
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

//MATERIAL UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Select from "@mui/material/Select";
import Link from "@material-ui/core/Link";

//COMPONENTS
import SearchBar from "components/Layout/searchBar/SearchBar";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ConfirmDialog from "components/ConfirmDialog";
import RootContainer from "layout/rootContainer";
import VisualizarFactura from "./visualizar";

//ICONS
import VisibilityIcon from "@material-ui/icons/Visibility";
import Create from "@material-ui/icons/Create";
import { Delete } from "@material-ui/icons";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ExcelIcon from "icons/excel";

//SERVICES
import facturacionServices from "services/facturacion.services";
import usuarioService from "services/usuario.service";

//VARIOS
import Theme from "theme";
import useStyles from "./FacturaStyles";
import moment from "moment";
import { LogIn } from "context/LogInContext";
import Protected from "protected";
import fileDownload from "js-file-download";
import { NotificationContext } from "context/notification/NotificationContext";
import mostrarErrorEP from "constants/funciones/mostrarError";
import { namesFacturacion } from "./constants/const";
import StyledIconButton from "components/StyledIconButton";
import { useDebounce } from "hook/useDebounce";
import { shallow } from "zustand/shallow";
import {
  useFacturaActions,
  useFacturasStore,
  useNoteTipeSelected,
  useOrderBy,
  useSearchNotesOrClients,
  useRef,
} from "./store";
import Pagination from "@material-ui/lab/Pagination";

//constants
const SORT_TYPE = Object.freeze({
  SORT_DATE: Symbol(),
  SORT_NUMFAC: Symbol(),
  SORT_MONTO: Symbol(),
  SORT_COND: Symbol(),
});

const Facturacion = () => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const history = useHistory();
  const { logged } = useContext(LogIn);
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const [total, setTotal] = useState();
  const [actual, setActual] = useState();
  //ESTADOS PARA CONTROLAR ACCESO A MODULO
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);
  const [facturaId, setFacturaId] = useState();

  //ESTADOS PARA CONTROLAR ACCIONES
  const [openVisualizarFactura, setOpenVisualizarFactura] = useState(false);

  // state manager with zustand
  const filtro = useFacturasStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      tipo: state.tipo,
      ordfecha: state.ordfecha,
      ordnumfac: state.ordnumfac,
      ordmonto: state.ordmonto,
      ordcondi: state.ordcondi,
      page: state.page,
      dias: state.dias,
      condicion: state.condicion,
    }),
    shallow,
  );
  const selectTexto = useNoteTipeSelected();
  const searchText = useSearchNotesOrClients();
  const orderBy = useOrderBy();
  const ref = useRef();

  //Actions with zustand
  const {
    setFechaDesde,
    setFechaHasta,
    setSelectTexto,
    setSearch,
    setTipo,
    setDias,
    setPage,
    setTodo,
    setCondicion,
    setOrdFecha,
    setOrdNumFac,
    setOrdMonto,
    setOrdCondi,
    toggleSort,
    resetState,
    setRef,
  } = useFacturaActions();

  // useDebounce hook for input text
  const debouncedValue = useDebounce(searchText, 300);

  // FUNCION DE BUSCADOR POR CLIENTE O FACTURA
  const handleSearch = async (text) => {
    setSearch(text);
  };

  // ESTADO PARA SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  // ESTADO PARA CERRAR SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  //===========================ESTADOS PARA LOS BOTONES DE ACCIONES=============
  const verFactura = (factura) => {
    if (!factura.presupuesto) {
      setOpenVisualizarFactura(true);
      setFacturaId(factura.id);
    } else {
      history.push(`/recibos/visualizar-recibo/${factura.id}/`);
    }
  };

  // ESTADO PARA ABRIR MODAL DE ELIMINAR FACTURA
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  const eliminarFactura = async (factura) => {
    if (!factura.presupuesto) {
      try {
        const res = await facturacionServices.deleteFactura(factura.id);
        if (res.status > 199 && res.status < 300) {
          setSnackState({
            open: true,
            severity: "success",
            message: "Factura eliminada exitosamente",
          });
          queryClient.invalidateQueries({ queryKey: ["Facturas"] });
        } else {
          setSnackState({
            open: "true",
            severity: "error",
            message: "Error al eliminar factura",
          });
        }
      } catch (error) {
        setSnackState({
          open: true,
          severity: "warning",
          message: "Error de servidor al borrar",
        });
      }
    } else {
      try {
        const res = await facturacionServices.deleteRecibo(factura.id);
        if (res.status > 199 && res.status < 300) {
          setSnackState({
            open: true,
            severity: "success",
            message: "Recibo eliminado exitosamente",
          });
          queryClient.invalidateQueries({ queryKey: ["Facturas"] });
        } else {
          setSnackState({
            open: "true",
            severity: "error",
            message: "Error al eliminar recibo",
          });
        }
      } catch (error) {
        setSnackState({
          open: true,
          severity: "warning",
          message: "Error de servidor al borrar",
        });
      }
    }
  };
  //=============================== FIN ACCIONES ==============================================

  //=============================== DESCARGAR EXCEL ==============================================
  const descargarExcel = async () => {
    try {
      const { data, status } = isNotificationsEnabled
        ? await facturacionServices.descargarExcel({
            fecha_desde: filtro?.fecha_desde,
            fecha_hasta: filtro?.fecha_hasta,
          })
        : await facturacionServices.descargarExcelRecibo({
            fecha_desde: filtro?.fecha_desde,
            fecha_hasta: filtro?.fecha_hasta,
          });

      if (status > 199 && status < 300) {
        fileDownload(
          data,
          `facturas_${filtro?.fecha_desde}_${filtro?.fecha_hasta}.xlsx`,
        );
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };
  //=============================== FIN DESCARGAR EXCEL ==============================================

  //===============================  FILTROS ==============================================

  const mostrarPor = async (search) => {
    switch (search) {
      case namesFacturacion?.todas:
        setTodo("Todas");
        break;
      case namesFacturacion?.aCobrar:
        setCondicion(namesFacturacion?.aCobrar);
        break;
      case namesFacturacion?.pagado:
        setCondicion(namesFacturacion?.pagado);
        break;
      case "Vencidas":
        setDias(moment().format("YYYY-MM-DD"));
        break;
      default:
        setTipo(namesFacturacion?.todas);
    }
  };
  //=============================== FIN FILTROS ==============================================

  //=============================== ORDENAR POR ==============================================

  // const sortBy = (campo) => {
  //   // sortType = -1 para desc y 1 para asc
  //   const sortType = orderBy ? "1" : "-1";
  //   setSort(`${campo}:${sortType}`);
  //   toggleSort();
  // };

  const sortBy = (criteria) => {
    const sortType = orderBy ? "asc" : "desc";
    switch (criteria) {
      case SORT_TYPE.SORT_DATE:
        setOrdFecha(sortType);
        break;
      case SORT_TYPE.SORT_NUMFAC:
        setOrdNumFac(sortType);
        break;
      case SORT_TYPE.SORT_MONTO:
        setOrdMonto(sortType);
        break;
      case SORT_TYPE.SORT_COND:
        setOrdCondi(sortType);
        break;
      default:
        break;
    }
    toggleSort();
  };

  //=============================== FIN ORDENAR POR ==============================================
  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  //=============================== VERIFICAR ACCESO A MODULO =================================
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await usuarioService.checkAccess({ modulo: "facturacion" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setVerificando(false);
        setAcceso(false);
        history.push("/error-permiso", { modulo: "facturacion" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "facturacion" });
    }
  };
  //=============================== FIN VERIFICAR ==============================================

  //=============================== OBTENER FACTURAS ==============================================
  const obtenerFacturas = async () => {
    const { data } = isNotificationsEnabled
      ? await facturacionServices.listarFacturas({
          ...filtro,
          search: debouncedValue,
        })
      : await facturacionServices.listarRecibos({
          ...filtro,
          search: debouncedValue,
        });
    return data;
  };

  // QUERY PARA OBTENER FACTURAS DE CLIENTES
  // eslint-disable-next-line no-unused-vars
  const { data: facturas, isLoading } = useQuery(
    ["Facturas", filtro, isNotificationsEnabled, debouncedValue],
    obtenerFacturas,
    {
      onError: (error) => mostrarErrorEP(error, setSnackState),
      onSuccess: (dataFactura) => {
        setTotal(dataFactura?.total_paginas);
        setActual(dataFactura?.actual);
      },
      refetchInterval: false, //si estamos mucho tiempo en un mismo sitio, refresca la pagina cada cierto tiempo por si hay cambios nuevos
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false, //para que no se actualice la pagina automaticamente al hacer foco en ella
    },
  );

  const VerResumen = async (res) => {
    let nro_resumen = res.resumen;
    if (res.presupuesto) {
      try {
        const res = await facturacionServices.planillaLiquidacion(nro_resumen);
        if (res.status > 199 && res.status < 300) {
          history.push(
            `/remitos/remitos-por-cliente/resumen-liquidacion/${nro_resumen}`,
          );
        }
      } catch (error) {
        return error;
      }
    } else {
      try {
        const res = await facturacionServices.planillaResumen(nro_resumen);
        if (res.status > 199 && res.status < 300) {
          history.push(`/remitos/remitos-por-cliente/resumen/${nro_resumen}`);
        }
      } catch (error) {
        return error;
      }
    }
  };

  //=============================== FIN OBTENER FACTURAS ==============================================

  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
  }, []);

  useEffect(() => {
    console.log("ref", ref);
    if (ref !== "listado-facturas") {
      resetState();
      setTimeout(() => {
        setRef("listado-facturas");
      }, 200);
      setSearch("");
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{ marginTop: "15rem" }}
          >
            <CircularProgress
              style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            {acceso &&
              (!openVisualizarFactura ? (
                <>
                  <Grid
                    style={{
                      paddingTop: "1rem",
                      width: "95%",
                      height: "100%",
                    }}
                  >
                    <Grid
                      container
                      item
                      style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    >
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ maxHeight: "2rem" }}
                          onClick={() => descargarExcel()}
                          startIcon={<ExcelIcon />}
                        >
                          Descargar Excel
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      item
                      direction="row"
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <Grid
                        xl={6}
                        lg={6}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ marginTop: "0.5rem" }}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        item
                      >
                        <Grid item>
                          <TextField
                            size="small"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            label="Fecha Desde"
                            focused
                            defaultValue={filtro.fecha_desde}
                            onChange={(e) =>
                              setFechaDesde(
                                moment(e.target.value).format("YYYY-MM-DD"),
                              )
                            }
                            inputProps={{
                              max: filtro.fecha_hasta,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            size="small"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            label="Fecha Hasta"
                            focused
                            defaultValue={filtro.fecha_hasta}
                            onChange={(e) =>
                              setFechaHasta(
                                moment(e.target.value).format("YYYY-MM-DD"),
                              )
                            }
                            inputProps={{
                              min: filtro.fecha_desde,
                              max: moment().format("YYYY-MM-DD"),
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        xl={6}
                        lg={6}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        justifyContent={"flex-end"}
                        alignContent="flex-start"
                        alignItems="center"
                        style={{ marginTop: "0.5rem" }}
                        spacing={1}
                        item
                      >
                        <Grid item>
                          <Box>
                            <FormControl style={{ width: "190px" }}>
                              <Select
                                size="small"
                                variant="outlined"
                                value={selectTexto}
                                displayEmpty
                                renderValue={
                                  selectTexto !== ""
                                    ? undefined
                                    : () => (
                                        <Placeholder>Mostrar por:</Placeholder>
                                      )
                                }
                                onChange={(e) => (
                                  // eslint-disable-next-line no-sequences
                                  mostrarPor(e.target.value),
                                  setSelectTexto(e.target.value)
                                )}
                              >
                                <MenuItem value={"Todas"}>Todas</MenuItem>
                                <MenuItem value={"Vencidas"}>
                                  Vencidas +30 días
                                </MenuItem>
                                <MenuItem value={namesFacturacion?.aCobrar}>
                                  A Cobrar
                                </MenuItem>
                                <MenuItem value={namesFacturacion?.pagado}>
                                  Pagado
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item>
                          <SearchBar
                            placeholder="Buscar por N° Nota o Cliente"
                            defaultValue={searchText}
                            value={searchText}
                            onChange={(e) => {
                              handleSearch(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    style={{
                      maxWidth: "95%",
                      marginTop: "0.5rem",
                      marginBottom: "5rem",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      className={classes.headerTable}
                    >
                      <Typography
                        variant="h5"
                        className={classes.headerTableTittle}
                      >
                        Lista de Facturas
                      </Typography>
                    </Grid>
                    {isLoading ? (
                      <Grid container justifyContent="center">
                        <Grid>
                          <CircularProgress
                            size="1.5rem"
                            style={{ margin: "2rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <TableContainer
                          component={Paper}
                          className={classes.tableContainer}
                        >
                          <Table size="small" stickyHeader>
                            <TableHead>
                              <TableRow className="tableRow">
                                <TableCell
                                  className={classes.tableCell2}
                                  style={{ minWidth: "10rem" }}
                                >
                                  N° Factura
                                  <IconButton
                                    onClick={() =>
                                      sortBy(SORT_TYPE.SORT_NUMFAC)
                                    }
                                  >
                                    <CompareArrowsIcon
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  className={classes.tableCell2}
                                  style={{ minWidth: "11rem", padding: 0 }}
                                >
                                  Fecha de Emisión
                                  <IconButton
                                    onClick={() => sortBy(SORT_TYPE.SORT_DATE)}
                                  >
                                    <CompareArrowsIcon
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell className={classes.tableCellHeaders}>
                                  Cliente
                                </TableCell>

                                <TableCell
                                  className={classes.tableCellHeaders}
                                  style={{ minWidth: "10rem" }}
                                >
                                  Orden / Resumen
                                </TableCell>

                                <TableCell
                                  className={classes.tableCell2}
                                  style={{
                                    minWidth: "9rem",
                                    padding: 0,
                                  }}
                                >
                                  Monto
                                  <IconButton
                                    onClick={() => sortBy(SORT_TYPE.SORT_MONTO)}
                                  >
                                    <CompareArrowsIcon
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  className={classes.tableCell2}
                                  style={{
                                    minWidth: "8rem",
                                    padding: 0,
                                  }}
                                >
                                  Condición
                                  <IconButton
                                    onClick={() => sortBy(SORT_TYPE.SORT_COND)}
                                  >
                                    <CompareArrowsIcon
                                      style={{
                                        width: "1rem",
                                        transform: "rotate(90deg)",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={classes.tableCellHeaders}
                                >
                                  Acciones
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            {facturas?.results?.map((fac) => (
                              <TableBody key={fac?.id}>
                                <TableRow
                                  hover
                                  style={{
                                    backgroundColor: fac?.presupuesto
                                      ? "#E26E6E"
                                      : "",
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      minWidth: "10rem",
                                    }}
                                  >
                                    {fac?.num_factura ?? "-"}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {moment(fac?.fecha_emision).format(
                                      "DD-MM-YYYY",
                                    ) ?? "-"}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tablecell3}
                                    style={{
                                      paddingLeft: 0,
                                    }}
                                  >
                                    {fac?.cliente?.nombre.toUpperCase() ?? "-"}
                                  </TableCell>
                                  <TableCell className={classes.tablecell3}>
                                    {fac?.orden_compra?.num_orden ||
                                    fac?.orden_compra?.id ? (
                                      <Link
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "17px",
                                          color:
                                            (fac?.orden_compra?.num_orden ||
                                              fac?.orden_compra?.id) &&
                                            "#1C3AA9",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                        }}
                                        onClick={() =>
                                          history.push(
                                            fac?.presupuesto
                                              ? `/ordenes/ver-orden-negro/${
                                                  fac?.orden_compra?.id ?? ""
                                                }/`
                                              : `/ordenes/ver-orden/${
                                                  fac?.orden_compra?.id ?? ""
                                                }/`,
                                          )
                                        }
                                      >
                                        {fac?.orden_compra?.num_orden ||
                                          fac?.orden_compra?.id}
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                    {" / "}
                                    {fac?.resumen ? (
                                      <Link
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "17px",
                                          color: fac?.resumen && "#1C3AA9",
                                          cursor: "pointer",
                                          textDecoration: "none",
                                        }}
                                        onClick={() => VerResumen(fac)}
                                      >
                                        {fac?.resumen}
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tablecell3}
                                    style={{
                                      paddingLeft: 0,
                                    }}
                                  >
                                    {fac?.total?.toLocaleString("es-AR", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                      style: "currency",
                                      currency: "ARS",
                                    }) ?? "-"}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {fac?.condicion ?? "-"}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Grid
                                      container
                                      item
                                      direction="row"
                                      justifyContent="center"
                                      style={{
                                        whiteSpace: "nowrap",
                                        minWidth: "8rem",
                                      }}
                                    >
                                      <StyledIconButton
                                        size="small"
                                        onClick={() => verFactura(fac)}
                                      >
                                        <VisibilityIcon
                                          className={classes.icon}
                                        />
                                      </StyledIconButton>

                                      <StyledIconButton
                                        size="small"
                                        disabled={fac?.orden_facturada}
                                        onClick={() =>
                                          setConfirmDialog({
                                            title: fac?.presupuesto
                                              ? `¿Está seguro de eliminar el recibo ${fac?.num_factura}?`
                                              : `¿Está seguro de eliminar la factura ${fac?.num_factura}?`,
                                            isOpen: true,
                                            onConfirm: () => {
                                              eliminarFactura(fac);
                                            },
                                          })
                                        }
                                      >
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            <Typography
                                              variant="body2"
                                              align="center"
                                            >
                                              No se puede eliminar esta factura
                                              porque tiene una orden facturada
                                            </Typography>
                                          }
                                          disableFocusListener={
                                            !fac?.orden_facturada
                                          }
                                          disableHoverListener={
                                            !fac?.orden_facturada
                                          }
                                          disableTouchListener={
                                            !fac?.orden_facturada
                                          }
                                        >
                                          <Delete className={classes.icon} />
                                        </Tooltip>
                                      </StyledIconButton>

                                      <StyledIconButton
                                        size="small"
                                        disabled={
                                          fac?.presupuesto &&
                                          fac?.condicion === "Pagado"
                                        }
                                        onClick={() =>
                                          history.push(
                                            fac?.presupuesto
                                              ? `/recibo/editar-recibo/${fac?.id}/`
                                              : `/facturacion/editar-factura/${fac?.id}/`,
                                          )
                                        }
                                      >
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            <Typography
                                              variant="body2"
                                              align="center"
                                            >
                                              No se puede editar este recibo
                                              porque tiene orden de compra
                                              pagado
                                            </Typography>
                                          }
                                          disableFocusListener={
                                            !fac?.presupuesto &&
                                            fac?.condicion === "Pagado"
                                          }
                                          disableHoverListener={
                                            !fac?.presupuesto &&
                                            fac?.condicion === "Pagado"
                                          }
                                          disableTouchListener={
                                            !fac?.presupuesto &&
                                            fac?.condicion === "Pagado"
                                          }
                                        >
                                          <Create className={classes.icon} />
                                        </Tooltip>
                                      </StyledIconButton>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                          </Table>
                        </TableContainer>
                        {facturas && (
                          <Grid container justifyContent="center">
                            <Grid style={{ marginTop: "1rem" }}>
                              <Pagination
                                count={total}
                                color="primary"
                                onChange={(event, value) => setPage(value)}
                                page={filtro?.page}
                              />
                            </Grid>
                          </Grid>
                        )}

                        {facturas?.count === 0 && (
                          <Grid
                            container
                            justifyContent="center"
                            style={{ margin: "0.5rem" }}
                          >
                            <Typography variant="subtitle1">
                              No se encontraron facturas con estas
                              características
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <VisualizarFactura
                  facturaId={facturaId}
                  setOpenVisualizarFactura={setOpenVisualizarFactura}
                />
              ))}
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </>
        )}
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};

export default Facturacion;
