import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

const InputField = ({
  name,
  label,
  defaultValue,
  type,
  rules,
  control,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <TextField
        {...field}
        size="small"
        fullWidth
        type={type}
        id={label}
        label={label}
        variant="outlined"
        error={!!error}
        helperText={error ? error.message : null}
        {...props}
      />
    )}
  />
);

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
};
InputField.defaultProps = {
  defaultValue: "",
  type: "text",
};
export default InputField;
