import { TableCell, TableRow } from "@material-ui/core";
import { toContable } from "constants/funciones/toContable";

export default function TableRowCrearFacturaOrden({
  linea,
  lineas,
  setLineas,
  watch,
  control,
}) {
  const facturaA = watch("tipo_factura") === "A";

  const calcularPrecio = () => {
    if (!facturaA) {
      linea.precio_unitario = linea?.precio_actual;

      return linea?.precio_actual;
    }

    const precioSinIva = Number((linea?.precio_actual / 1.21).toFixed(2));

    linea.precio_unitario = precioSinIva;

    return precioSinIva;
  };

  const calcularImporte = () => {
    const importe = Number((linea?.cant_metros * calcularPrecio()).toFixed(2));

    linea.importe = importe;

    return importe;
  };

  return (
    <TableRow>
      <TableCell style={{ border: 0 }}>{linea?.tipo}</TableCell>

      <TableCell style={{ border: 0 }}>{linea?.cant_metros}</TableCell>

      <TableCell style={{ border: 0 }}>
        {toContable(calcularPrecio())}
      </TableCell>

      <TableCell style={{ border: 0 }}>{toContable(linea?.bonif)}</TableCell>

      <TableCell style={{ border: 0 }}>
        {toContable(calcularImporte())}
      </TableCell>

      {facturaA && (
        <TableCell style={{ border: 0, width: "9rem" }}>
          {linea?.iva + "%"}
        </TableCell>
      )}

      <TableCell style={{ border: 0 }}>{toContable(linea?.subtotal)}</TableCell>
    </TableRow>
  );
}
