import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { toContable } from "constants/funciones/toContable";
import useDayjs from "hook/useDayjs";
import useStyles from "screens/facturacion/seleccionar-cliente/styles";
import Logo from "../../../assets/img/LogoAyupi.png";
import { namesCtaCte } from "../const";

export default function ImprimirInforme({
  componentRef,
  cuentas,
  filtro,
  cliente,
  totalDebe,
}) {
  const classes = useStyles();
  const dayjs = useDayjs();

  return (
    <Grid ref={componentRef}>
      <style type="text/css" media="print">
        {`@page { 
          margin: 1rem 0 !important; 
          size: A4 landscape;
          }
          ::-webkit-scrollbar,
          ::-webkit-scrollbar {
            display: none;
          }
          ::-webkit-scrollbar-track,
          ::-webkit-scrollbar-thumb {
            display: none;
          }
          scrollbar-width: none;
          -ms-overflow-style: none;`}
      </style>
      <Grid
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid xl={4} lg={4} md={4} sm={4} xs={4}>
          <Grid item>
            <Box
              component="img"
              sx={{
                height: 100,
                marginLeft: "2.5rem",
                marginTop: "1rem",
              }}
              alt="Logo"
              src={Logo}
            />
          </Grid>
        </Grid>
        <Grid
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={8}
          style={{
            marginTop: "3.5rem",
          }}
        >
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            INFORME CUENTA CORRIENTE
          </Typography>
        </Grid>
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ padding: "2rem 0 0 3rem" }}
        >
          <Typography
            style={{
              fontSize: "16px",
              lineHeight: "25px",
              fontWeight: 700,
            }}
          >
            {`El dia ${dayjs(filtro?.[namesCtaCte?.fechaHasta]).format(
              "DD [de] MMMM [del] YYYY",
            )}. Se informa el estado de la cuenta corriente de:`}
          </Typography>
        </Grid>
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ marginTop: "1rem" }}
        >
          <Grid
            container
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{ paddingLeft: "3rem" }}
          >
            <Grid container direction="row" justifyContent="flex-start">
              <Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontWeight: 700,
                }}
              >
                Nombre y Apellido:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  marginLeft: "0.5rem",
                }}
              >
                {cliente?.[namesCtaCte?.clienteNombre]}
              </Typography>
            </Grid>

            <Grid container direction="row" justifyContent="flex-start">
              <Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontWeight: 700,
                }}
              >
                DNI:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  marginLeft: "0.5rem",
                }}
              >
                {cliente?.[namesCtaCte?.clienteCuil]}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{ paddingLeft: "1rem" }}
          >
            <Grid container direction="row" justifyContent="flex-start">
              <Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontWeight: 700,
                }}
              >
                Dirección:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  marginLeft: "0.5rem",
                }}
              >
                {cliente?.[namesCtaCte?.clienteDireccion]}
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start">
              <Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontWeight: 700,
                }}
              >
                Teléfono:
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  marginLeft: "0.5rem",
                }}
              >
                {cliente?.[namesCtaCte?.clienteTelefono]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ marginTop: "1rem", paddingLeft: "3rem" }}
        >
          <Grid container direction="row" justifyContent="flex-start">
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
              }}
            >
              El total deudor del cliente es de:
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                marginLeft: "0.5rem",
                fontWeight: 700,
              }}
            >
              {toContable(totalDebe)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ marginTop: "1rem", paddingLeft: "3rem" }}
        >
          <Grid container direction="row" justifyContent="flex-start">
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: 700,
              }}
            >
              Descripción de Deuda:
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                marginLeft: "0.5rem",
              }}
            >
              Desde la Fecha
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: 700,
                marginLeft: "0.5rem",
              }}
            >
              {dayjs(filtro?.[namesCtaCte?.fechaDesde]).format(
                "DD [de] MMMM [del] YYYY",
              )}
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                marginLeft: "0.5rem",
              }}
            >
              Hasta el
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: 700,
                marginLeft: "0.5rem",
              }}
            >
              {dayjs(filtro?.[namesCtaCte?.fechaHasta]).format(
                "DD [de] MMMM [del] YYYY",
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.generalContainer}
          style={{ padding: "1rem 1rem 0 1rem" }}
        >
          <TableContainer className={classes.TableContainer}>
            <Table style={{ margin: "0 auto" }} size="small">
              <TableHead>
                <TableRow
                  className={classes.tableRow}
                  style={{
                    background: "#CCCFE4",
                    height: "3rem",
                  }}
                >
                  <TableCell className={classes.tableCellHeaders}>
                    Fecha Emisión
                  </TableCell>
                  <TableCell className={classes.tableCellHeaders}>
                    Tipo
                  </TableCell>
                  <TableCell className={classes.tableCellHeaders}>
                    N° Comprobante
                  </TableCell>
                  <TableCell className={classes.tableCellHeaders}>
                    N° Ord. Com.
                  </TableCell>
                  <TableCell className={classes.tableCellHeaders}>
                    N° Resumen
                  </TableCell>
                  <TableCell className={classes.tableCellHeaders}>
                    Monto Factura
                  </TableCell>
                  <TableCell className={classes.tableCellHeaders}>
                    Pagado
                  </TableCell>
                  <TableCell className={classes.tableCellHeaders}>
                    Saldo
                  </TableCell>
                  <TableCell className={classes.tableCellHeaders}>
                    Acumulado
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cuentas?.length > 0 ? (
                  cuentas?.map((item) => (
                    <TableRow
                      hover
                      key={item?.id}
                      style={{
                        height: "3rem",
                      }}
                    >
                      <TableCell>
                        {dayjs(item?.[namesCtaCte?.fechaEmision]).format(
                          "DD-MM-YYYY",
                        )}
                      </TableCell>
                      <TableCell>{item?.[namesCtaCte?.tipo]}</TableCell>                      
                      <TableCell>{item?.[namesCtaCte?.num_comprobante]}</TableCell>
                      <TableCell>
                        {item?.[namesCtaCte?.orden]?.num_orden ??
                          item?.[namesCtaCte?.orden]?.id ??
                          "-"}
                      </TableCell>                     
                      <TableCell>{item?.[namesCtaCte?.resumen]}</TableCell>
                      <TableCell>
                        {toContable(item?.[namesCtaCte?.montoFactura])}
                      </TableCell>
                      <TableCell>
                        {toContable(item?.[namesCtaCte?.pagado])}
                      </TableCell>
                      <TableCell>
                        {toContable(item?.[namesCtaCte?.saldo])}
                      </TableCell>
                      <TableCell>
                        {toContable(item?.[namesCtaCte?.acumulado])}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Grid item style={{ margin: "0.5rem" }}>
                    <Typography>No se encontraron cuentas</Typography>
                  </Grid>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}
