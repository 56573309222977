// archivo de prueba para reutilizar funciones

const listaId = (listaObjetos) => {
  const listaId = [];

  listaObjetos.forEach((element) => {
    listaId.push(Number(element?.id));
  });

  return listaId;
};

const mayorListaId = (listaObjetos) => {
  let mayorListaId = 0;

  listaId(listaObjetos).forEach((element) => {
    if (element > mayorListaId) {
      mayorListaId = element;
    }
  });

  return mayorListaId;
};

const eliminarElemento = ({ lista, index }) => lista.splice(index, 1);

const modificarElemento = ({ lista, index, elemento }) =>
  lista.splice(index, 1, elemento);

const ejecutarFuncionAlCompararId = (funcion) => {};

export {
  listaId,
  mayorListaId,
  eliminarElemento,
  modificarElemento,
  ejecutarFuncionAlCompararId,
};
