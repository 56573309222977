import { React } from "react";
import Protected from "protected";
import { Grid, Typography } from "@material-ui/core";
import Theme from "theme";

import RootContainer from "layout/rootContainer";

import ContainerCustom from "components/comtainerCustom";
const ErrorRedireccion = () => {
  return (
    <Protected>
    <RootContainer>
      <ContainerCustom>
        <Grid
          container
          item
          xl={3}
          lg={4}
          md={6}
          sm={8}
          xs={12}
          style={{
            borderRadius: 10,
            backgroundColor: "#ffffff",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            marginTop: "1rem",
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            alignContent="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              width: "100%",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                color: "#ffffff",
              }}
            >
              Error de redirección
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="column"
            style={{
              padding: "2rem",
            }}
          >
            <Typography style={{ textAlign: "center", fontSize: "1.2rem" }}>
              La dirección a la cual se quiere dirigir, no se encuentra
              disponible en este momento. Por favor inténtelo nuevamente en unos
              momentos. De persistir el comportamiento, comuníquese con el
              administrador al siguiente email:
            </Typography>

            <Typography
              color="primary"

              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                marginBottom: "1.5rem",
              }}
            >
              vivian_gauna@hotmail.com
            </Typography>
          </Grid>
        </Grid>
      </ContainerCustom>
    </RootContainer>
    </Protected>
  );
};
export default ErrorRedireccion;
