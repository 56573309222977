import React, { useContext, useMemo, useState, useEffect } from "react";
import Drawer from "./Drawer";
import { useHotkeys } from "react-hotkeys-hook";
import { NotificationContext } from "context/notification/NotificationContext";
import { useIdleTimer } from "react-idle-timer";

const LayoutHome = (props) => {
  const { routes } = props;

  const { isNotificationsEnabled, setIsNotificationsEnabled } =
    useContext(NotificationContext);

  // negros en disabled
  const timeout = 60000; // 1 min
  const [remaining, setRemaining] = useState(timeout);
  const { getRemainingTime } = useIdleTimer({
    timeout,
  });

  function ExampleComponentShortcuts() {
    useHotkeys("ctrl + alt + k", () =>
      setIsNotificationsEnabled(!isNotificationsEnabled)
    );
    return <></>;
  }

  useEffect(() => {
    if (!isNotificationsEnabled) {
      setInterval(() => {
        setRemaining(getRemainingTime());
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationsEnabled]);

  if (remaining === 0 && !isNotificationsEnabled) {
    //alert(`Notifications ${isNotificationsEnabled ? "Enabled" : "Disabled"}`)
    setIsNotificationsEnabled(true);
  }

  const element = useMemo(() => <Drawer routes={routes} />, [routes]);

  return (
    <div>
      <ExampleComponentShortcuts />
      {element}
    </div>
  );
};

export default React.memo(LayoutHome);
