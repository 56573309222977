import { styled } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";

const StyledTable = styled(Table)(() => ({
  "&.MuiTable-root": {
    backgroundColor: "var(--backgroundColor)",
  },
}));

export default StyledTable;
