/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const usePrint = () => {
  const componentRef = useRef();
  const onBeforeGetContentResolve = useRef(null);

  // Función para identificar los componentes a imprimir
  const handleOnBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      resolve();
    });
  }, []);

  // Función para obtener el contenido a imprimir
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  // Para ejecutar la acción imprimir
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  return { componentRef, handlePrint };
};

export default usePrint;
