/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import { useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

// MATERIAL UI
import { Button, Grid, Typography } from "@material-ui/core";

// COMPONENTS
import CustomSnackbar from "components/Layout/CustomSnackbars";
import RootContainer from "layout/rootContainer";
import VerificarAcceso from "components/verificarAcceso";
import SearchBar from "components/Layout/searchBar/SearchBar";
import Card from "components/card";
import ConfirmDialog from "components/ConfirmDialog";

// ICONS
import ExcelIcon from "icons/excel";

// SERVICES
import ordenesService from "services/ordenes.service";

// VARIOS
import { LogIn } from "context/LogInContext";
import Protected from "protected";
import moment from "moment";
import mostrarErrorEP from "constants/funciones/mostrarError";
import fileDownload from "js-file-download";
import { NotificationContext } from "context/notification/NotificationContext";
import InputDate from "components/inputs/newInputDate";
import { useForm } from "react-hook-form";
import LoadingText from "components/loadingText";
import { useEffect } from "react";
import TableCustomizable from "components/tableCustomizable";
import TableRowOrdenes from "./components/tableRowOrdenes";
import { useDebounce } from "hook/useDebounce";
import { shallow } from "zustand/shallow";
import {
  useObra,
  useRef,
  useRemitoActions,
  useRemitoStore,
  useTextSelected,
} from "../remitos/store";
import Pagination from "@material-ui/lab/Pagination";

const Ordenes = () => {
  // ESTADOS VARIOS
  const history = useHistory();

  // ESTADOS PARA VERIFICAR ACCESO DE UN USUARIO AL MODULO
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const { logged } = useContext(LogIn);
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);

  const { control } = useForm();

  const headers = [
    { name: "Cliente", orderBy: "cliente" },
    {
      name: "Nº de Orden de Compra",
      orderBy: "orden",
      style: { minWidth: "14rem" },
    },
    { name: "Fecha", orderBy: "fecha", style: { minWidth: "8rem" } },
    { name: "Anticipado" },
    { name: "Resúmenes" },
    { name: "Factura de Orden" },
    { name: "Condición" },
    { name: "Acciones" },
  ];
  // ESTADOS PARA NAVEGACION ENTRE PAGINAS
  // const [siguiente, setSiguiente] = useState(null);
  // const [anterior, setAnterior] = useState(null);
  // const [actual, setActual] = useState();
  const [total, setTotal] = useState();

  const [dataPagination, setDataPagination] = useState(null);

  // state manager with zustand
  const filtro = useRemitoStore(
    (state) => ({
      fecha_desde: state.fecha_desde,
      fecha_hasta: state.fecha_hasta,
      todo: state.todo,
      estado: state.estado,
      condicion: state.condicion,
      dias: state.dias,
      formula: state.formula,
      ordremi: state.ordremi,
      ordfecha: state.ordfecha,
      ordmts: state.ordmts,
      ordobra: state.ordobra,
      page: state.page,
    }),
    shallow,
  );
  // const selectTexto = useTextSelected();
  const searchText = useObra();
  const ref = useRef();
  const minDate = filtro?.fecha_desde;
  const maxDate = filtro?.fecha_hasta;

  //Actions with zustand
  const {
    setFechaDesde,
    setFechaHasta,
    setObras,
    increasePage,
    decreasePage,
    resetPage,
    setPage,
    reset,
    setRef,
  } = useRemitoActions();

  //ESTADO DE CONFIRM DIALOG
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });

  // FUNCION PARA IR PAGINA SIGUIENTE
  const nextPage = async () => {
    if (filtro.page >= total) return;
    increasePage();
  };

  // FUNCION PARA IR PAGINA ANTERIOR
  const previousPage = () => {
    if (filtro.page <= 1) return;
    decreasePage();
  };

  const filterByDate = () => {
    setFechaDesde(minDate);
    setFechaHasta(maxDate);
    resetPage();
  };

  // FUNCION DE BUSCADOR POR OBRA
  const handleSearch = async (text) => {
    setObras(text);
    //!check??
    resetPage();
  };

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // useDebounce hook for input text
  const debouncedValue = useDebounce(searchText, 200);

  // ESTADO QUE CONTIENE TODOS LOS FILTROS A USARSE
  const [filtro2, setFiltro2] = useState({
    ordCliente: "",
    ordOrden: "",
    ordFecha: "desc",
  });

  // ESTADO PARA MANEJAR EL ORDEN DE LAS COLUMNAS
  const [asc, setAsc] = useState(true);

  // FUNCION ENCARGADA DE SETEAR EL FILTRO PARA ORDENAR LA TABLA SEGUN LAS OPCIONES ELEGIDAS
  const ordenarPor = (criterio) => {
    let orden = asc ? "asc" : "desc";

    switch (criterio) {
      case "cliente":
        setFiltro2({
          ...filtro2,
          ordCliente: orden,
          ordOrden: "",
          ordFecha: "",
        });
        setAsc(!asc);
        break;

      case "orden":
        setFiltro2({
          ...filtro2,
          ordCliente: "",
          ordOrden: orden,
          ordFecha: "",
        });
        setAsc(!asc);
        break;

      case "fecha":
        setFiltro2({
          ...filtro2,
          ordCliente: "",
          ordOrden: "",
          ordFecha: orden,
        });
        setAsc(!asc);
        break;
      default:
        break;
    }
  };

  const [listaOrdenes, setListaOrdenes] = useState([]);
  const [cargandoOrdenes, setCargandoOrdenes] = useState(false);

  const listarOrdenes = async () => {
    const sendFilter = {
      fecha_desde: filtro?.fecha_desde,
      fecha_hasta: filtro?.fecha_hasta,
      page: filtro?.page,
      search: debouncedValue,
      ...filtro2,
    };
    setCargandoOrdenes(true);
    try {
      const { data, status } = isNotificationsEnabled
        ? await ordenesService.listarOrdenes(sendFilter)
        : await ordenesService.listarOrdenesNegro(sendFilter);

      if (status > 199 && status < 300) {
        setDataPagination(data);
        setTotal(data?.total_paginas);
        setListaOrdenes(data?.results);
        setCargandoOrdenes(false);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      setCargandoOrdenes(false);
      return error;
    }
  };

  const descargarExcel = async () => {
    try {
      const { data, status } = isNotificationsEnabled
        ? await ordenesService.descargarExcel({
            fecha_desde: minDate,
            fecha_hasta: maxDate,
          })
        : await ordenesService.descargarExcelNegro({
            fecha_desde: minDate,
            fecha_hasta: maxDate,
          });

      if (status > 199 && status < 300) {
        fileDownload(data, `ordenes_${minDate}_${maxDate}.xlsx`);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };

  useEffect(() => {
    listarOrdenes();
  }, [filtro, isNotificationsEnabled, filtro2, debouncedValue]);

  useEffect(() => {
    console.log("ref", ref);
    if (ref !== "listado-ordenes") {
      reset();
      setTimeout(() => {
        setRef("listado-ordenes");
      }, 200);
      setObras("");
    }
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <VerificarAcceso
            setVerificando={setVerificando}
            setAcceso={setAcceso}
            modulo="orden-compra"
            history={history}
            logged={logged}
          />
        ) : (
          acceso && (
            <Grid
              container
              style={{
                margin: "2rem 2rem 4rem 2rem",
                overflow: "auto",
              }}
            >
              <Grid container>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{
                    margin: "0.5rem 1rem 0.5rem 0",
                    textTransform: "none",
                  }}
                  onClick={() => history.push("/ordenes/crear-orden")}
                >
                  Crear Orden
                </Button>

                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  startIcon={<ExcelIcon />}
                  style={{ margin: "0.5rem 0", textTransform: "none" }}
                  onClick={() => descargarExcel()}
                >
                  Descargar Excel
                </Button>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid container alignItems="center" style={{ width: "25rem" }}>
                  <Grid item style={{ margin: "0.5rem 0.5rem 0.5rem 0" }}>
                    <InputDate
                      name="fecha_desde"
                      label="Fecha Desde"
                      focused
                      control={control}
                      defaultValue={minDate && minDate}
                      onChange={(e) => {
                        setFechaDesde(
                          moment(e.target.value).format("YYYY-MM-DD"),
                        );
                      }}
                      inputProps={{
                        max: maxDate,
                      }}
                    />
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 0.5rem 0.5rem 0" }}>
                    <InputDate
                      name="fecha_hasta"
                      label="Fecha Hasta"
                      focused
                      control={control}
                      defaultValue={maxDate && maxDate}
                      onChange={(e) => {
                        setFechaHasta(
                          moment(e.target.value).format("YYYY-MM-DD"),
                        );
                      }}
                      inputProps={{
                        min: filtro?.fecha_desde,
                        max: moment().format("YYYY-MM-DD"),
                      }}
                    />
                  </Grid>

                  <Grid item style={{ margin: "0.5rem 0" }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => filterByDate()}
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      Filtrar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item style={{ margin: "0.5rem 0" }}>
                  <SearchBar
                    placeholder="Buscar por Cliente o N° de Orden"
                    defaultValue={searchText}
                    value={searchText}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>

              <Card title="Ordenes de Compra" justifyContent="center">
                {cargandoOrdenes ? (
                  <LoadingText title="Cargando Ordenes" />
                ) : (
                  <TableCustomizable
                    size="small"
                    stickyHeader
                    headers={headers}
                    orderBy={ordenarPor}
                  >
                    {listaOrdenes?.map((item) => (
                      <TableRowOrdenes
                        key={item?.id}
                        item={item}
                        setConfirmDialog={setConfirmDialog}
                        setSnackState={setSnackState}
                        resetSearch={listarOrdenes}
                      />
                    ))}
                  </TableCustomizable>
                )}
              </Card>

              {dataPagination ? (
                <Grid container justifyContent="center">
                  <Grid style={{ marginTop: "0.5rem" }}>
                    <Pagination
                      count={total}
                      color="primary"
                      onChange={(event, value) => setPage(value)}
                      page={filtro?.page}
                    />
                  </Grid>
                </Grid>
              ) : !cargandoOrdenes && (
                <Grid
                  container
                  justifyContent="center"
                  style={{ margin: "0.5rem" }}
                >
                  <Typography variant="subtitle1">
                    No se encontraron ordenes de compra con estas
                    caracteristicas
                  </Typography>
                </Grid>
              )}
            </Grid>
          )
        )}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          autoHideDuration={snackState.autoHideDuration}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};

export default Ordenes;
