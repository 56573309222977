import { React, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import { useHistory } from "react-router-dom";
import Theme from "theme";
import {
  useRemitoActions,
  useRemitoStore
} from "../../store";

// Styles
import useStyles from "../styles";

// Components
import ConfirmDialog from "components/ConfirmDialog";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ButtonLoading from "components/buttonLoading/ButtonLoading";

// Icons
import moment from "moment";
import remitosService from "services/remitos.service";
import { useContext } from "react";
import { NotificationContext } from "context/notification/NotificationContext";
import mostrarErrorEP from "constants/funciones/mostrarError";
import ErrorRemitos from "./errorRemitos";
import { useGetPreviewsummary } from "screens/remitos/hook";
import { CircularProgress } from "@mui/material";

const VisualizarResumen = () => {
  const history = useHistory();
  const classes = useStyles();
  const { isNotificationsEnabled } = useContext(NotificationContext);

  // Si viene Repito remitos para el resumen los quito del Preview
  const filtrarObjetosRepetidos = (arr) => {
    return arr.filter((obj, index) => {
      // Devuelve true si el índice del objeto actual es igual al índice de la primera ocurrencia en el array
      return index === arr.findIndex((item) => (
        item?.remito_id === obj?.remito_id // Aquí asumimos que tienes una propiedad "id" en cada objeto para comparar la unicidad
        // Puedes cambiar "id" por otra propiedad si la comparación se debe hacer en función de otra clave
      ));
    });
  }
  // Tomo los datos que viene de Remitos por Cliente
  const { dataCliente, checkTodosLosRemitos, filtro } = history?.location?.state;
  const [arregloRemitos, setArregloRemitos] = useState(
    checkTodosLosRemitos ? 
    isNotificationsEnabled ? [] : filtrarObjetosRepetidos(history?.location?.state?.dataRemitos) :
    filtrarObjetosRepetidos(history?.location?.state?.dataRemitos)
  );

  const remitosIdsAExcluir = useRemitoStore((state) => state.remitosIdsAExcluir);

  console.log("** crear resumen checkTodos", checkTodosLosRemitos);
  console.log("*** arreglos remitos", arregloRemitos, isNotificationsEnabled, remitosIdsAExcluir);

  // QUERY PARA OBTENER LISTADO DE REMITOS PARA VISUALIZAR RESUMEN
  /**
   * ! Resolver Scrolling, ya que no tiene paginado vista previa de resumen
   */
  const {
    data: listaRemitos,
    isLoading,
  } = useGetPreviewsummary({
    idClient: dataCliente?.id,
    filtro: filtro,
    // Array de IDs que quiero excluir
    excludedIds: remitosIdsAExcluir.filter((element) => element !== undefined),
    // enbaled query checkTodosLosRemitos es false
    checkTodosLosRemitos,
    // remitos-b activo
    isNotificationsEnabled
  });

  // Declaración de estado de Snackbar
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  // Función para cerrar Snack Bar
  function onClose() {
    setSnackState({ ...snackState, open: false });
  }

  // Estados para componente ConfirmDialog
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  const [errorRemitos, setErrorRemitos] = useState(false);
  const [remitosEliminar, setRemitosEliminar] = useState([]);

  // Estado para componente ButtonLoading
  const [generandoResumen, setGenerandoResumen] = useState(false);

  //Actions Zustand de remito store
  const {
    setRemitosIdsAExcluir,
    setRemCheck,
    reset
  } = useRemitoActions();

  const remCheck = useRemitoStore((state) => state.remCheck);
   console.log("***** arregloRemitos", arregloRemitos);
  // Función para enviar datos al back
  const generarResumen = async () => {
    setGenerandoResumen(true);
    let res = "";
    let endpoint = "";
    const sendData = arregloRemitos.map(item => item?.remito_id);
    const body = checkTodosLosRemitos
      ? {
        remito: sendData,
        cliente_id: dataCliente?.id,
        obra: filtro?.obra,
        fecha_desde: filtro?.fecha_desde,
        fecha_hasta: filtro?.fecha_hasta,
        remitos_excluidos: arregloRemitos,
      }
      : {
        remito: sendData,
      };    
    try {
      if (isNotificationsEnabled) {
        endpoint = checkTodosLosRemitos ? 'generarResumenMasivo' : 'generarResumen';
        res = await remitosService[endpoint](body);
        if (res.status > 199 && res.status < 300) {
          // Al guaradar resumen seteo store de remitos
          reset();
          setSnackState({
            open: true,
            severity: "success",
            message: `Resumen Generado Exitosamente`,
          });
          let nro_resumen = res.data.id;
          setTimeout(() => {
            history.push(
              `/remitos/remitos-por-cliente/resumen/${nro_resumen}/`,
            );
            setGenerandoResumen(false);
          }, 2000);
        }
      } else {
        endpoint = checkTodosLosRemitos ? 'generarResumenMasivoB' : 'generarResumenLiqui';
        res = await remitosService[endpoint](body);
        if (res.status > 199 && res.status < 300) {
          // Al guaradar resumen seteo store de remitos
          reset();
          setSnackState({
            open: true,
            severity: "success",
            message: `Liquidación Generada Exitosamente`,
          });
          let nro_resumen = res.data.id;
          setTimeout(() => {
            history.push(
              `/remitos/remitos-por-cliente/resumen-liquidacion/${nro_resumen}/`,
            );
            setGenerandoResumen(false);
          }, 2000);
        }
      }
    } catch (error) {
      const errorMessage =
        "Se seleccionaron remitos que existen en otros resumenes";

      if (error?.response?.data?.Error === errorMessage) {
        setErrorRemitos(true);
        setRemitosEliminar(error?.response?.data?.remitos_repetidos);
      } else {
        mostrarErrorEP(error, setSnackState);
      }
      setGenerandoResumen(false);
      return error;
    }
  };

  return (
    <Protected>
      <RootContainer>
        <Grid
          style={{
            paddingTop: "1rem",
            width: "95%",
            height: "100%",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            item
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            {/* Cabecera con los datos del cliente */}
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Grid>
                <Typography className={classes.remitoHeaderItemTitle}>
                  CUIL-CUIT :{" "}
                  <span className={classes.remitoHeaderItem}>
                    {dataCliente?.cuil_dni ?? "-"}
                  </span>
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.remitoHeaderItemTitle}>
                  Nombre:{" "}
                  <span className={classes.remitoHeaderItem}>
                    {dataCliente?.nombre ?? "-"}
                  </span>
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.remitoHeaderItemTitle}>
                  Dirección:{" "}
                  <span className={classes.remitoHeaderItem}>
                    {dataCliente
                      ? `${dataCliente.direccion} ${dataCliente.ciudad} ${dataCliente.provincia}`
                      : "-"}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  margin: "0.5rem 0.5rem 0.5rem 0",
                }}
                onClick={() => {
                  // Modificación, en vez de limpiar vista previa, vuelvo atrás y sigo trabajando con los remitos seleccionados del store
                  history.goBack();
                }}
              >
                Volver
              </Button>
              <ButtonLoading
                isLoading={generandoResumen}
                disabled={generandoResumen}
                onClick={generarResumen}
                children={
                  !isNotificationsEnabled
                    ? "Generar Liquidación"
                    : "Generar Resumen"
                }
              />
            </Grid>
          </Grid>

          {remitosEliminar?.length > 0 && !errorRemitos && (
            <Grid container>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Lista de Nº de Remito repetidos:
                {remitosEliminar?.map((remito, index) => {
                  if (remitosEliminar?.length - 1 === index) {
                    return " " + remito?.nro_remito;
                  } else {
                    return " " + remito?.nro_remito + " -";
                  }
                })}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          style={{ maxWidth: "95%", marginTop: "1rem", marginBottom: "5rem" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            className={classes.headerTable}
          >
            <Typography variant="h5" className={classes.headerTableTittle}>
              {isNotificationsEnabled
                ? "Generar Resumen - Revisión"
                : "Generar Liquidación - Revisión"}
            </Typography>
          </Grid>
          {isLoading ? (
            <Grid container justifyContent="center">
              <Grid>
                <CircularProgress
                  size="1.5rem"
                  style={{ margin: "2rem" }}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow className="tableRow">
                      <TableCell
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "16px",
                          color: "rgba(67, 59, 59, 0.87)",
                          minWidth: "7rem",
                        }}
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "16px",
                          color: "rgba(67, 59, 59, 0.87)",
                          minWidth: "9rem",
                          padding: 0,
                        }}
                      >
                        N° de Remito
                      </TableCell>
                      <TableCell
                        style={{ paddingLeft: "0.5rem" }}
                        className={classes.tableCellHeaders}
                      >
                        Tipo H
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "16px",
                          color: "rgba(67, 59, 59, 0.87)",
                          minWidth: "8rem",
                          padding: 0,
                        }}
                      >
                        Cantidad
                      </TableCell>
                      <TableCell
                        style={{ paddingLeft: "0.5rem" }}
                        className={classes.tableCellHeaders}
                      >
                        Obra
                      </TableCell>
                      <TableCell
                        style={{ paddingLeft: "0.5rem" }}
                        className={classes.tableCellHeaders}
                      >
                        Estado
                      </TableCell>
                      <TableCell
                        style={{ paddingLeft: "0.5rem" }}
                        className={classes.tableCellHeaders}
                      >
                        Condición
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellHeaders}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    // (listaRemitos?.results?.length > 0 ? listaRemitos?.results : 
                    //   isNotificationsEnabled ? 
                    //   arregloRemitos : listaRemitos?.results )

                    ( checkTodosLosRemitos ? listaRemitos?.results :
(isNotificationsEnabled ? arregloRemitos.filter((remito) => remito.presupuesto === false) : arregloRemitos.filter((remito) => remito.presupuesto === true) )
                        )
                      ?.filter((item) => !item.Total_Formula)
                      ?.map((remito) => (
                        <TableBody>
                          <TableRow
                            hover
                            key={remito.id}
                            style={{
                              backgroundColor: remito?.presupuesto
                                ? "#E26E6E"
                                : moment(remito.fecha) <
                                  moment().subtract(90, "days") &&
                                  remito.condicion === "A Cobrar"
                                  ? "#FBEF94"
                                  : "",
                            }}
                          >
                            <TableCell
                              style={{
                                fontSize: "16px",
                                whiteSpace: "nowrap",
                                textAlign: "left",
                              }}
                            >
                              {moment(remito?.fecha).format("DD-MM-YYYY") ?? "-"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {remito?.nro_remito ?? "-"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {remito?.tipo ?? "-"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {remito?.cantidad_mc ?? "-"}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {remito?.obra?.toUpperCase() ?? "-"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {remito?.estado ?? "-"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {remito?.condicion ?? "-"}
                            </TableCell>
                            <TableCell>
                              <Grid
                                container
                                item
                                direction="row"
                                justifyContent="center"
                                style={{
                                  whiteSpace: "nowrap",
                                  minWidth: "8rem",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    // Quito de lista de "remitos seleccionados", los remitos que saco(borro) de la vista previa del resumen a generar
                                    setRemCheck(remCheck.filter((remitoStore) => remitoStore?.remito_id !== remito?.remito_id));
                                    setArregloRemitos(
                                      arregloRemitos.filter(
                                        (rem) =>
                                          rem.remito_id !== remito?.remito_id,
                                      ),
                                    );
                                    setRemitosIdsAExcluir([...remitosIdsAExcluir, remito?.id]);
                                   // setArregloRemitos([...arregloRemitos, remito?.id]);                                  
                                  }
                                  }
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginLeft: "0.5rem",

                                    borderRadius: 5,
                                    backgroundColor: Theme.palette.primary.main,
                                    color: "#ffffff",
                                  }}
                                >
                                  <Delete
                                    style={{
                                      height: "1.2rem",
                                      width: "1.2rem",
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                </Table>
                {checkTodosLosRemitos ? listaRemitos?.results < 1 : arregloRemitos.length < 1 && (
                  <Grid container justifyContent="center">
                    <Typography>No hay remitos seleccionados</Typography>
                  </Grid>
                )}
              </TableContainer>
            </>
          )}
        </Grid>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
          autoHideDuration={2000}
        />

        <ErrorRemitos
          open={errorRemitos}
          close={setErrorRemitos}
          remitosEliminar={remitosEliminar}
          setRemitosEliminar={setRemitosEliminar}
          arregloRemitos={arregloRemitos}
          setArregloRemitos={setArregloRemitos}
        />
      </RootContainer>
    </Protected>
  );
};
export default VisualizarResumen;
