import {Grid, IconButton, Typography} from "@material-ui/core";

import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";

const Pagination = ({data, filtro, setFiltro}) => {
  const {actual, total_paginas, next, previous} = data;

  return (
    total_paginas > 1 && (
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{margin: "0.5rem"}}
      >
        {previous && (
          <IconButton
            size="small"
            onClick={() => setFiltro({...filtro, page: actual - 1})}
          >
            <ArrowBack />
          </IconButton>
        )}
        <Typography>
          {actual}/{total_paginas} Páginas
        </Typography>

        {next && (
          <IconButton
            size="small"
            onClick={() => setFiltro({...filtro, page: actual + 1})}
          >
            <ArrowForward />
          </IconButton>
        )}
      </Grid>
    )
  );
};

export default Pagination;
