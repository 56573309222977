import axios from "./axios.config";

class ClientesService {

  
  /*
  Retorna lista cliente paginada 
  */
  getClientes() {
    return axios.get(`/api/clientes/listar-clientes/`);
  }

  /*
  Retorna lista cliente sin paginación 
  */
  getAllClientes() {
    return axios.get(`/api/clientes/listar-clientes-todos/`);
  }

  getNextPage(page) {	
    return axios.get(`api/clientes/listar-clientes/?page=${page}`);	
  }
  
  /** retorna lista paginada x10 */
  getClientesDiez(){
    return axios.get(`/api/clientes/listar-clientes-diez/`)
  }

  deleteCliente(id){
    return axios.delete(`/api/clientes/eliminar-cliente/${id}/`);
  }

  searchClientes(data) {
    return axios.get(`/api/clientes/buscar-clientes/?search=${data}`);
    
  }
 
  addCliente(data) {
    return axios.post(`api/clientes/crear-cliente-particular/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  addClienteEmpresa(data) {
    return axios.post(`/api/clientes/crear-cliente-empresa/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  editCliente(id, data) {
    return axios.patch(`api/clientes/modificar-cliente-particular/${id}/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  editClienteEmpresa(id, data) {
    return axios.patch(`/api/clientes/modificar-cliente-empresa/${id}/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  obtenerResponsables(idCliente) {
    /**
     * @params {idCliente id del cliente}
     * @return datos del cliente
     */
    return axios.get(`/api/clientes/listar-responsables/${idCliente}/`);
  }
  
  eliminarResponsable(id) {
    return axios.delete(`/api/clientes/eliminar-responsable/${id}/`);
  }

  actualizarResponsable(id, data) {
    return axios.patch(`/api/clientes/modificar-responsable/${id}/`, data);
  }

  agregarResponsable(data) {
    return axios.post(`/api/clientes/crear-responsable/`, data);
  }

}

export default new ClientesService();
