/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Card from "components/card";
import NewCheckbox from "components/inputs/newCheckbox";
import LoadingText from "components/loadingText";
import mostrarErrorEP from "constants/funciones/mostrarError";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import facturacionServices from "services/facturacion.services";
import Theme from "theme";
import { listaId, mayorListaId } from "../modifCondPagoLiquidacion/constants";
import Lineas from "./lineas";
import SaldoFavor from "./components/saldoFavor";
import { useContext } from "react";
import { NotificationContext } from "context/notification/NotificationContext";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import { toContable } from "constants/funciones/toContable";

export default function CondicionPagoResumen({
  open,
  setOpen,
  facturasSeleccionadas,
  setFacturasSeleccionadas,
  setConfirmDialog,
  setSnackState,
  resetCheckboxes,
  refreshQueries,
}) {
  const { handleSubmit, control, reset, watch } = useForm();

  const { isNotificationsEnabled } = useContext(NotificationContext);
  const [isSubmit, setIsSubmit] = useState(false);
  const [cargandoDatos, setCargandoDatos] = useState(true);
  const [facturas, setFacturas] = useState([]);
  const [openSaldo, setOpenSaldo] = useState(false);
  const [error, setError] = useState([]);

  const handleCloseModalPago = () => {
    setOpenSaldo(false);
  };

  useEffect(async () => {
    if (open) {
      try {
        const { data, status } = isNotificationsEnabled
          ? await facturacionServices.listarFacturasFormaPago({
              facturas: listaId(facturasSeleccionadas),
            })
          : await facturacionServices.listarRecibosFormaPago({
              facturas: listaId(facturasSeleccionadas),
            });

        if (status > 199 && status < 300) {
          data.forEach((element) => {
            const { formas_pago, ...dataFactura } = element;
            let id_linea;

            if (formas_pago?.length > 0) {
              id_linea = mayorListaId(formas_pago) + 1;
            } else {
              id_linea = 2;

              let linea = {
                id: 1,
                isNew: true,
                observacion: null,
                fecha_pago: null,
                forma_pago: null,
                nro_cheque: null,
                monto: null,
                credito: false,
              };

              formas_pago?.push(linea);
              dataFactura.formasAgregar = [linea];
            }

            facturas?.push({
              ...dataFactura,
              id_linea: id_linea,
              condicion_pago: dataFactura?.condicion,
              formasPago: formas_pago,
            });
          });

          setCargandoDatos(false);
        }
      } catch (error) {
        mostrarErrorEP(error, setSnackState);
        setCargandoDatos(false);
        return error;
      }
    }
  }, [open]);

  const eliminarFactura = (index) => {
    facturas?.splice(index, 1);
  };

  const cambiarCondicionPago = (value, index) => {
    const factura = facturas[index];

    facturas?.splice(index, 1, {
      ...factura,
      condicion_pago: value ? "Pagado" : "A Cobrar",
    });
  };

  const controlarSaldo = () => {
    const found = facturas?.find((item) => item?.saldo < 0);

    if (found) {
      setOpenSaldo(true);
    } else {
      onSubmit();
    }
  };

  const [cambiandoCondicion, setCambiandoCondicion] = useState(false);

  const onSubmit = async () => {
    setCambiandoCondicion(true);
    setIsSubmit(true);

    let dataSend = {
      agregar_linea: [],
      modificar_linea: [],
      eliminar_linea: [],
    };

    facturas?.forEach((factura) => {
      dataSend = {
        agregar_linea: [
          ...dataSend?.agregar_linea,
          {
            condicion_pago: factura?.condicion_pago,
            id_factura: factura?.id,
            formas_pago: factura?.formasAgregar,
          },
        ],
        modificar_linea: [
          ...dataSend?.modificar_linea,
          ...factura?.formasModificar,
        ],
        eliminar_linea: [
          ...dataSend?.eliminar_linea,
          ...factura?.formasEliminar,
        ],
      };
    });

    try {
      const { status } = isNotificationsEnabled
        ? await facturacionServices.cambiarCondicionPago(dataSend)
        : await facturacionServices.cambiarCondicionPagoNegro(dataSend);

      if (status > 199 && status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: isNotificationsEnabled
            ? "Factura/s Modificada/s Exitosamente"
            : "Recibo/s Modificado/s Exitosamente",
          autoHideDuration: 2000,
        });

        setTimeout(() => {
          setCambiandoCondicion(false);
          handleClose();
          handleCloseModalPago();
        }, [2000]);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      setCambiandoCondicion(false);
      setIsSubmit(false);
      return error;
    }
  };

  const handleClose = () => {
    setFacturasSeleccionadas([]);
    setFacturas([]);
    reset();
    resetCheckboxes();
    setCargandoDatos(true);
    setError([]);
    setIsSubmit(false);
    refreshQueries();
    setOpen(false);
  };

  const saldos = facturas.map((fac) => fac.saldo);

  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose();
        }
      }}
    >
      <form onSubmit={handleSubmit(controlarSaldo)}>
        <Grid
          container
          item
          xl={9}
          lg={9}
          md={9}
          sm={10}
          xs={11}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
          }}
        >
          <Card title="Agregar Forma de Pago" justifyContent="center">
            <Grid
              container
              style={{
                maxHeight: "25rem",
                overflow: "auto",
                padding: "1rem 1rem 0 1rem",
              }}
            >
              {cargandoDatos ? (
                <LoadingText title="Cargando Datos" />
              ) : (
                facturas?.length > 0 &&
                facturas?.map((factura, index) => (
                  <Grid container key={factura?.id}>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow
                            style={{
                              backgroundColor: isNotificationsEnabled
                                ? "#CCCFE4"
                                : "#E26E6E",
                              borderTop: "0.1rem solid grey",
                            }}
                          >
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 1rem",
                                borderBottom: 0,
                              }}
                            >
                              Nº Factura
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              Fecha Emisión
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              Nº Orden
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              Mts³
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              Monto
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              {`Saldo ${factura?.saldo < 0 ? "a Favor" : ""}`}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              Pagado
                            </TableCell>
                            {facturas?.length > 1 && (
                              <TableCell
                                align="left"
                                style={{
                                  padding: "0 0.5rem 0 0",
                                  borderBottom: 0,
                                }}
                              ></TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 1rem",
                                borderBottom: 0,
                              }}
                            >
                              {factura?.num_factura}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              {moment(factura?.fecha_emision).format(
                                "DD/MM/YYYY",
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              {factura?.orden_compra?.num_orden ??
                                factura?.orden_compra?.id ??
                                "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              {factura?.cantidad_mc}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              {toContable(factura?.total)}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                              }}
                            >
                              {factura?.saldo >= 0
                                ? toContable(factura?.saldo)
                                : toContable(factura?.saldo * -1)}
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "0 0.5rem 0 0",
                                borderBottom: 0,
                                width: "1rem",
                              }}
                            >
                              <NewCheckbox
                                name={"condicion_pago" + factura?.id}
                                color="primary"
                                control={control}
                                onChange={(e, value) =>
                                  cambiarCondicionPago(value, index)
                                }
                              />
                            </TableCell>
                            {facturas?.length > 1 && (
                              <TableCell
                                style={{
                                  padding: "0 0.5rem 0 0",
                                  borderBottom: 0,
                                  width: "1rem",
                                }}
                              >
                                <IconButton
                                  key={factura?.id}
                                  size="small"
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    borderRadius: 5,
                                    backgroundColor: Theme.palette.primary.main,
                                    color: "#ffffff",
                                  }}
                                  onClick={() =>
                                    setConfirmDialog({
                                      title: `ELIMINAR FACTURA N° ${factura?.num_factura}`,
                                      isOpen: true,
                                      onConfirm: () => {
                                        eliminarFactura(index);
                                      },
                                    })
                                  }
                                >
                                  <Delete
                                    style={{
                                      height: "1.2rem",
                                      width: "1.2rem",
                                    }}
                                  />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Lineas
                      control={control}
                      watch={watch}
                      factura={factura}
                      facturas={facturas}
                      setFacturas={setFacturas}
                      setConfirmDialog={setConfirmDialog}
                      isNotificationsEnabled={isNotificationsEnabled}
                      setSnackState={setSnackState}
                      error={error}
                      setError={setError}
                    />
                  </Grid>
                ))
              )}
            </Grid>

            <Grid
              container
              justifyContent="flex-end"
              style={{ margin: "1rem 1rem" }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  children="Volver"
                  style={{ marginRight: "0.5rem", textTransform: "none" }}
                  onClick={() =>
                    setConfirmDialog({
                      isOpen: true,
                      title: "Se perderán los cambios realizados",
                      onConfirm: () => handleClose(),
                    })
                  }
                />
              </Grid>
              <Grid item>
                <ButtonLoading
                  type="submit"
                  size="small"
                  children="Confirmar"
                  variant="contained"
                  color="primary"
                  disabled={error?.length > 0 || isSubmit}
                  isLoading={isSubmit}
                  style={{ textTransform: "none" }}
                />
              </Grid>
            </Grid>
            {openSaldo && (
              <SaldoFavor
                open={openSaldo}
                handleClose={handleCloseModalPago}
                onSubmit={onSubmit}
                dataFactura={facturas}
                cambiandoCondicion={cambiandoCondicion}
                saldos={saldos}
              />
            )}
          </Card>
        </Grid>
      </form>
    </Modal>
  );
}
