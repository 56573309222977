/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from "react";
import Protected from "protected";
import {
  Button,
  Container,
  Grid,
  TextField,
  IconButton,
  Typography,
} from "@material-ui/core";
import Theme from "theme";
import { useHistory } from "react-router-dom";
import { Close } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import UsuarioService from "services/usuario.service";
import { useEffect } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const EditUser = () => {
  const history = useHistory();

  // hooks de campos de usuario
  const [nombre, setNombre] = useState("-sin registro-");
  const [apellido, setApellido] = useState("-sin registro-");
  const [email, setEmail] = useState("-sin registro-");
  const [matricula, setMatricula] = useState("-sin registro-");
  const [pass, setPass] = useState();
  const [confirmPass, setConfirmPass] = useState();

  const [passVisibility, setPassVisibility] = useState(true);
  const [confirmPassVisibility, setConfirmPassVisibility] = useState(true);

  const getUserData = async () => {
    try {
      const res = await UsuarioService.getUserData(history.location.state); // recibir el id del usuario a editar
      if (res.status === 200) {
        setNombre(res.data.first_name);
        setApellido(res.data.last_name);
        setEmail(res.data.email);
        setMatricula(res.data.matricula);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const sendData = {
      password: pass,
      first_name: nombre,
      last_name: apellido,
      email: email,
      matricula: matricula,
    };

    try {
      const res = await UsuarioService.editUser(
        history.location.state,
        sendData
      ); // enviar el id del usuario a editar
      if (res.status === 200) {
        history.push("/success", { tarea: "edicion", data: res.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // hooks de errores
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorApellido, setErrorApellido] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMatricula, setErrorMatricula] = useState(false);
  const [errorPass, setErrorPass] = useState(false);
  const [errorConfirmPass, setErrorConfirmPass] = useState(false);

  const handleBack = () => {
    history.goBack();
  };

  //handle changes con verificaciones
  const handleChangeNombre = (text) => {
    setNombre(text);
    if (text && /^[a-z ñáéíóú']+$/i.test(text)) {
      setErrorNombre(false);
    } else {
      setErrorNombre(true);
    }
  };
  const handleChangeApellido = (text) => {
    setApellido(text);
    if (text && /^[a-z ñáéíóú']+$/i.test(text)) {
      setErrorApellido(false);
    } else {
      setErrorApellido(true);
    }
  };
  const handleChangeEmail = (text) => {
    setEmail(text);
    // eslint-disable-next-line no-useless-escape
    if (text && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text)) {
      setErrorEmail(false);
    } else {
      setErrorEmail(true);
    }
  };

  const handleChangeMatricula = (text) => {
    setMatricula(text);
    if (text) {
      setErrorMatricula(false);
    } else {
      setErrorMatricula(true);
    }
  };
  const handleChangePass = (text) => {
    setPass(text);
    if (text) {
      setErrorPass(false);
    } else {
      setErrorPass(true);
    }
  };
  const handleChangeConfirmPass = (text) => {
    setConfirmPass(text);
    if (text) {
      setErrorConfirmPass(false);
    } else {
      setErrorConfirmPass(true);
    }
  };

  // Function for control if the password have 8 characters length.
  const lengthRight = pass?.length > 7;
  function controlCharacters() {
    return (
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignContent="center"
      >
        {lengthRight ? (
          <Check color="primary" />
        ) : (
          // If not
          <Close color="error" />
        )}
        <Typography>Mínimo 8 caracteres</Typography>
      </Grid>
    );
  }

  // Function for control if the password have minimium 1 uppercase
  const regexUpper = /[A-Z]/;
  const upperRight = regexUpper.test(pass);
  function controlUppercase() {
    return (
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignContent="center"
      >
        {upperRight ? (
          <Check color="primary" />
        ) : (
          // If not
          <Close color="error" />
        )}
        <Typography>Al menos una mayúscula</Typography>
      </Grid>
    );
  }

  // Function for control if the password have minimium 1 number
  const regexNumber = /[0-9]|[!@#$%^&*_-]/;
  const specialRight = regexNumber.test(pass);
  function controlSpecial() {
    return (
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignContent="center"
      >
        {specialRight ? (
          <Check color="primary" />
        ) : (
          // If not
          <Close color="error" />
        )}
        <Typography>Al menos un número o caracter especial</Typography>
      </Grid>
    );
  }
  // Function for control if the password is same passwordConfirm
  const confirmRight = confirmPass && pass === confirmPass;
  function controlMatch() {
    return (
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignContent="center"
      >
        {confirmRight ? (
          <Check color="primary" />
        ) : (
          // If not
          <Close color="error" />
        )}
        <Typography>Las contraseñas deben coincidir</Typography>
      </Grid>
    );
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Protected>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        style={{
          backgroundColor: "#F0F0F0",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          padding: "0.5rem",
        }}
      >
        <Container
          maxWidth="xs"
          style={{
            marginTop: "5rem",
            borderRadius: 10,
            backgroundColor: "#ffffff",
            paddingLeft: 0,
            paddingRight: 0,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            alignContent="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              width: "100%",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                color: "#ffffff",
              }}
            >
              Edición de usuario
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justifyContent="space-between"
            style={{ padding: "2rem" }}
          >
            <TextField
              fullWidth
              variant="outlined"
              label="Nombre"
              focused={nombre}
              value={nombre && nombre}
              size="small"
              error={errorNombre}
              onChange={(e) => handleChangeNombre(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Apellido"
              focused={apellido}
              value={apellido}
              size="small"
              error={errorApellido}
              onChange={(e) => handleChangeApellido(e.target.value)}
              style={{ marginTop: "1rem" }}
            />{" "}
            <TextField
              fullWidth
              variant="outlined"
              label="E-Mail"
              focused={email}
              value={email}
              size="small"
              error={errorEmail}
              onChange={(e) => handleChangeEmail(e.target.value)}
              style={{ marginTop: "1rem" }}
            />{" "}
            <TextField
              fullWidth
              variant="outlined"
              label="Número de empleado"
              value={matricula}
              focused={matricula}
              size="small"
              error={errorMatricula}
              onChange={(e) => handleChangeMatricula(e.target.value)}
              style={{ marginTop: "1rem" }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Contraseña"
              value={pass}
              size="small"
              type={passVisibility ? "password" : "text"}
              error={errorPass}
              onChange={(e) => handleChangePass(e.target.value)}
              style={{ marginTop: "1rem" }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    style={{ height: "2rem", width: "2rem" }}
                    onClick={() => setPassVisibility(!passVisibility)}
                  >
                    {!passVisibility ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />{" "}
            <TextField
              fullWidth
              variant="outlined"
              label="Repetir contraseña"
              value={confirmPass}
              size="small"
              yarn
              install
              type={confirmPassVisibility ? "password" : "text"}
              error={errorConfirmPass}
              onChange={(e) => handleChangeConfirmPass(e.target.value)}
              style={{ marginTop: "1rem" }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    style={{ height: "2rem", width: "2rem" }}
                    onClick={() =>
                      setConfirmPassVisibility(!confirmPassVisibility)
                    }
                  >
                    {!confirmPassVisibility ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            item
            direction="column"
            style={{ paddingLeft: "2rem" }}
          >
            <Typography>Requerimientos de contraseña</Typography>
            {controlCharacters()}
            {controlUppercase()}
            {controlSpecial()}
            {controlMatch()}
          </Grid>
          <Grid container item direction="column" style={{ padding: "2rem" }}>
            <Button
              variant="contained"
              disabled={
                errorNombre ||
                errorApellido ||
                errorEmail ||
                errorMatricula ||
                errorPass ||
                // errorTipo ||
                errorConfirmPass ||
                !specialRight ||
                !upperRight ||
                !confirmRight ||
                !lengthRight
              }
              color="primary"
              onClick={() => handleSubmit()}
            >
              Confirmar
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{ border: "none" }}
              onClick={() => handleBack()}
            >
              Volver
            </Button>
          </Grid>
        </Container>
      </Grid>
    </Protected>
  );
};
export default EditUser;
