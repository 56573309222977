import axios from "./axios.config";

class DataLocalidades {
  getProvincias() {
    /**
     * @params {body con los campos de creacion del usuario}
     * @return (void) Listar las provincias de la República Argentina
     */
    //return axios.get(`https://apis.datos.gob.ar/georef/api/provincias?campos=nombre&orden=nombre`);
    return axios.get(`/api/provincia/listar-provincias/`);
  }

  getDepartamentos(id) {
    /**
     * @params {id de la provincia a buscar}
     * @return consulta para obtener el listado de Departamentos
     */
    //return axios.get(`https://apis.datos.gob.ar/georef/api/departamentos?provincia=${id}&max=100&campos=id,nombre&orden=nombre`);
    return axios.get(`/api/provincia/listar-departamentos/${id}/`);
  }

  getLocalidades(id) {
    /**
     * @params {id del departamento a buscar}
     * @return consulta para obtener el listado de localidades de un departamento
     */

    //return axios.get(`https://apis.datos.gob.ar/georef/api/localidades?departamento=${id}&campos=nombre`);
    return axios.get(`/api/provincia/listar-localidades/${id}/`);
  }
  getLocalidadesProv(id) {
    /**
     * @params {id del departamento a buscar}
     * @return consulta para obtener el listado de localidades de un departamento
     */

    //return axios.get(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${id}&campos=nombre`);
    return axios.get(
      `https://apis.datos.gob.ar/georef/api/localidades?provincia=${id}&campos=nombre`
    );
  }
}
export default new DataLocalidades();
