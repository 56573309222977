import { React, useEffect, useState, useContext } from "react";
import Protected from "protected";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import ClientesService from "services/clientes.service";
import RootContainer from "layout/rootContainer";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import UsuarioService from "services/usuario.service";
import ConfirmDialog from "components/ConfirmDialog";
import useStyles from "./styles";
import TableClientes from "components/tableClientes";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import SearchBar from "components/Layout/searchBar/SearchBar";
import NotificationService from "services/notification/notification.service";
import { NotificationContext } from "context/notification/NotificationContext";
import VerDetalleCustomModal from "components/clientes/verDetalleCustomModal";
import { LogIn } from "context/LogInContext";
import Theme from "theme";
import mostrarErrorEP from "constants/funciones/mostrarError";
import LoadingText from "components/loadingText";
import { useDebounce } from "hook/useDebounce";

const Clientes = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { logged } = useContext(LogIn);

  const [acceso, setAcceso] = useState(false);
  const [verificando, setVerificando] = useState(true);
  const [cliente, setCliente] = useState();
  const [clientes, setClientes] = useState([]);
  const [buscandoCliente, setBuscandoCliente] = useState(false);
  // Clientes en la Base A (Todos)
  const [clientesA, setClientesA] = useState([]);
  // Clientes en la Base B
  const [clientesB, setClientesB] = useState([]);
  const { isNotificationsEnabled } = useContext(NotificationContext);
  const [notification, setNotification] = useState(null);
  const [siguiente, setSiguiente] = useState(null);
  const [anterior, setAnterior] = useState(null);
  const [page, setPage] = useState(1);
  const [actual, setActual] = useState();
  const [total, setTotal] = useState();
  const [modalDetalleVisibility, setModalDetalleVisibility] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //ESTADO PARA MANEJAR SEARCH
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1500);

  // Función para abrir alert snack
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  // Función para cerrar alert snack
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // Verificar Acceso a modulo Clientes
  const verificarAcceso = async () => {
    setAcceso(true);
    try {
      const res = await UsuarioService.checkAccess({ modulo: "clientes" });
      if (res.status === 200) {
        setVerificando(false);
        setAcceso(true);
      } else {
        setAcceso(false);
        setVerificando(false);
        history.push("/error-permiso", { modulo: "clientes" });
      }
    } catch (error) {
      setAcceso(true);
      setVerificando(false);
      history.push("/error-permiso", { modulo: "clientes" });
    }
  };

  const obtenerClientes = async () => {
    setIsLoading(true);
    const { data } = await ClientesService.getClientes();
    return data;
  };

  const siguientePagina = async () => {
    setPage(page + 1);
    const res = await ClientesService.getNextPage(page + 1);
    if (res.status === 200) {
      setClientes(res.data.results);
      setSiguiente(res.data.next);
      setAnterior(res.data.previous);
      setActual(res.data.actual);
      setTotal(res.data.total_paginas);
    }
  };

  const anteriorPagina = async () => {
    setPage(page - 1);
    const res = await ClientesService.getNextPage(page - 1);
    if (res.status === 200) {
      setClientes(res.data.results);
      setSiguiente(res.data.next);
      setAnterior(res.data.previous);
      setActual(res.data.actual);
      setTotal(res.data.total_paginas);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data: ArrayClientes } = useQuery("Clientes", obtenerClientes, {
    onSuccess: (data) => {
      firtsCallNotificacions(data.results);
      setClientesA(data.results);
      setActual(data.actual);
      setTotal(data.total_paginas);
      setSiguiente(data.next);
      setAnterior(data.previous);
    },
    onError: (error) => {
      console.log("Error", error);
    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // Funcion para filtrar por DNI y Nombre Apellido
  const handleBusqueda = async (text) => {
    if (text.length > 0) {
      try {
        setBuscandoCliente(true);
        const res = await ClientesService.searchClientes(text);
        if (res.status > 199 && res.status < 300) {
          setClientes(res.data);
        }
      } catch (error) {
        setClientes(null);
      }
    } else {
      queryClient.invalidateQueries("Clientes");
      setBuscandoCliente(false);
      setPage(1);
    }
  };

  // Funcion para Borrar un Cliente
  const borrarCliente = async (id) => {
    try {
      const res = await ClientesService.deleteCliente(id);
      if (res.status > 199 && res.status < 300) {
        setSnackState({
          open: true,
          severity: "success",
          message: "Cliente borrado exitosamente",
        });
        queryClient.invalidateQueries("Clientes");
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };

  // Editar Usuario
  const editarCliente = (user) => {
    history.push("/clientes/crear-cliente", {
      cliente: user,
      editar: true,
    });
  };

  // Ver Detalle del Cliente
  const verCliente = (cliente) => {
    setModalDetalleVisibility(true);
    setCliente(cliente);
  };

  // Cerrar Modal de Detalle
  const handleCloseModalDetalle = () => {
    setModalDetalleVisibility(false);
  };

  // Filtrar clientes para ocultarlo de la lista.
  const filtrarClientes = (clientes, clientesB) => {
    let dataClientes = [];
    let clientes_filtrados = clientes.filter((cliente) => {
      let res = clientesB.find((item) => {
        // eslint-disable-next-line eqeqeq
        return item.cliente_id == cliente.id;
      });
      if (res === undefined) {
        dataClientes.push(cliente);
      }
      return res;
    });
    // clientes_filtrados posee data completa de los clientes que estan en la BaseB
    setClientesB(clientes_filtrados);
    setClientes(dataClientes);
  };

  const firtsCallNotificacions = async (data) => {
    try {
      const notificacions = await NotificationService.notificationGet();
      if (notificacions.status > 199 && notificacions.status < 300) {
        filtrarClientes(data, notificacions.data);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isNotificationsEnabled) {
      setNotification(null);
      setPage(1);
      queryClient.invalidateQueries("Clientes");
    } else {
      setClientes(clientesA);
      setNotification(clientesB);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotificationsEnabled]);

  //Effect cuando cambbia debouncedSearch llama a la función de búsqueda en api 
  useEffect(() => {
    handleBusqueda(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    if (logged) {
      verificarAcceso();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Protected>
      <RootContainer>
        {verificando ? (
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            style={{
              marginTop: "15rem",
            }}
          >
            <CircularProgress
              style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
            />
            <Typography style={{ color: "#000000" }}>
              Verificando permisos
            </Typography>
          </Grid>
        ) : (
          <>
            {acceso && (
              <div
                style={{
                  paddingRight: "1rem",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                  style={{ marginTop: "2rem" }}
                >
                  <Grid item style={{ marginLeft: 0 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        history.push("/clientes/crear-cliente", {
                          cliente: null,
                          editar: false,
                        })
                      }
                    >
                      Crear Cliente
                    </Button>
                  </Grid>
                  <Grid item>
                    <SearchBar
                      placeholder="Ingrese CUIL/CUIT o Nombre"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container item className={classes.generalContainer}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    className={classes.headerTable}
                  >
                    <Typography
                      variant="h5"
                      className={classes.headerTableTittle}
                    >
                      Lista de Clientes
                    </Typography>
                  </Grid>
                  {isLoading ? (
                    <LoadingText title="Cargando Clientes" />
                  ) : clientes?.length ? (
                    <TableClientes
                      clientes={clientes}
                      notification={notification}
                      verCliente={verCliente}
                      editarCliente={editarCliente}
                      borrarCliente={borrarCliente}
                      setConfirmDialog={setConfirmDialog}
                      history={history}
                    />
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      style={{ margin: "0.5rem" }}
                    >
                      <Typography variant="subtitle1">
                        No se encontraron resultados
                      </Typography>
                    </Grid>
                  )}
                  {clientes?.length > 0 && !buscandoCliente && (
                    <Grid
                      container
                      item
                      direction="row"
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      {anterior && (
                        <IconButton onClick={() => anteriorPagina()}>
                          <ArrowBack />
                        </IconButton>
                      )}

                      <Typography>
                        {actual}/{total} Páginas
                      </Typography>

                      {siguiente && (
                        <IconButton onClick={() => siguientePagina()}>
                          <ArrowForward />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </>
        )}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <VerDetalleCustomModal
          open={modalDetalleVisibility}
          handleClose={handleCloseModalDetalle}
          cliente={cliente}
        />
        <CustomSnackbar
          message={snackState.message}
          open={snackState.open}
          severity={snackState.severity}
          onClose={onClose}
        />
      </RootContainer>
    </Protected>
  );
};
export default Clientes;
