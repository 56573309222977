/* eslint-disable react-hooks/exhaustive-deps */
// REACT
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

// MATERIAL UI
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

// SERVICES
import facturacionServices from "services/facturacion.services";

// ICONS
import PrintIcon from "@material-ui/icons/Print";

//COMPONENTS
import DetallePagoB from "./detallePago";

// VARIOS
import Protected from "protected";
import useStyles from "../styles";
import { ReactComponent as RemintoX } from "assets/svg/remito_x.svg";
import moment from "moment";
import Logo from "../../../../assets/img/LogoAyupi.png";
import RootContainer from "layout/rootContainer";

const VisualizarRecibo = () => {
  const classes = useStyles();
  // Para identificar los componentes a imprimir
  const componentRef = useRef();
  // Trae los datos de la pantalla anterior
  const history = useHistory();
  // Trae el id sin necesidad de usar history
  const { id } = useParams();
  // Estado para ejecutar la acción de imprimir
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //======================= ESTADOS ============================
  // Estado para verificar si los recibos estan cargando
  const [loadingRecibo, setLoadingRecibo] = useState(false);
  // Estado que contiene los datos del recibo
  const [dataRecibo, setDataRecibo] = useState();
  // Estado que contiene los remitos
  const [remitos, setRemitos] = useState([]);
  // Estado para verificar si el modal esta abierto o cerrado
  const [openDetallePago, setOpenDetallePago] = useState(false);
  // Estado para poner dimensión a la pantalla
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  //======================= FIN ESTADOS =========================

  //======================== FUNCIONES ==========================
  const calcularTotal = () => {
    let total = 0;

    remitos.forEach((remito) => {
      if (remito?.subtotal !== undefined) {
        total += remito?.subtotal;
      }
    });

    if (total === 0) {
      return null;
    }

    return total;
  };
  //**************** END POINTS *************************
  const obtenerRecibo = async () => {
    setLoadingRecibo(true);
    try {
      const { data, status } = await facturacionServices.verRecibo(id);
      if (status > 199 && status < 300) {
        setDataRecibo(data);
        setRemitos(data.linea_factura);
      }
      setLoadingRecibo(false);
    } catch (error) {
      setLoadingRecibo(false);
      return error;
    }
  };

  const VerLiquidacion = async (resumen) => {
    try {
      const res = await facturacionServices.planillaLiquidacion(resumen);
      if (res.status > 199 && res.status < 300) {
        history.push(
          `/remitos/remitos-por-cliente/resumen-liquidacion/${resumen}`,
        );
      }
    } catch (error) {
      return error;
    }
  };
  //**************** FIN END POINTS *********************
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };
  //======================== FIN FUNCIONES ==========================

  //========================= USEEFFECT =============================
  useEffect(() => {
    obtenerRecibo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);
  //========================= FIN USEEFFECT =========================

  return (
    <Protected>
      <RootContainer>
        <>
          {!loadingRecibo && !!id ? (
            <Grid
              style={{
                paddingTop: "1rem",
                width: "100%",
                height: "100%",
                paddingLeft: "2rem",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                item
              >
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "18px",
                      lineHeight: "24px",
                      color: "#000000",
                      paddingLeft: "1rem",
                    }}
                  >
                    Visualizar Recibo
                  </Typography>
                </Grid>
                <Grid item style={{ marginRight: "5rem" }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      margin: "0.5rem 0.5rem 0.5rem 0",
                    }}
                    onClick={() => history.goBack()}
                  >
                    Volver
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      textTransform: "capitalize",
                      margin: "0.5rem 0",
                    }}
                    startIcon={<PrintIcon />}
                    onClick={handlePrint}
                  >
                    Imprimir
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      marginLeft: "0.5rem",
                    }}
                    onClick={() => setOpenDetallePago(true)}
                  >
                    Detalle de Pago
                  </Button>
                </Grid>
              </Grid>
              <Grid
                style={{
                  maxWidth: "95%",
                  backgroundColor: "#ffffff",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  marginBottom: "6rem",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  className={classes.headerTable}
                >
                  <Typography
                    variant="h5"
                    className={classes.headerTableTittle}
                  >
                    Recibo N° {dataRecibo?.num_factura ?? "-"}
                  </Typography>
                </Grid>
                <Grid ref={componentRef}>
                  <style type="text/css" media="print">
                    {`@page { 
                      margin: 1rem 0 !important; 
                      size: A4 landscape;
                      }
                      ::-webkit-scrollbar,
                      ::-webkit-scrollbar {
                        display: none;
                      }
                      ::-webkit-scrollbar-track,
                      ::-webkit-scrollbar-thumb {
                        display: none;
                      }
                      scrollbar-width: none;
                      -ms-overflow-style: none;`}
                  </style>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Grid item>
                        <Box
                          component="img"
                          sx={{
                            height: 80,
                            marginLeft: "2.5rem",
                            marginTop: "1rem",
                          }}
                          alt="Logo"
                          src={Logo}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      xl={8}
                      lg={8}
                      md={8}
                      sm={8}
                      xs={8}
                      style={{
                        maxWidth: "50%",
                        marginTop: "1rem",
                      }}
                    >
                      <RemintoX />
                    </Grid>
                  </Grid>

                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid
                      container
                      xl={7}
                      lg={7}
                      md={7}
                      sm={7}
                      xs={7}
                      style={{ paddingLeft: "2.5rem" }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Razón Social:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.54)",
                            marginLeft: "0.5rem",
                          }}
                        >
                          Ayupí Hormigones S.R.L
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Domicilio:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.54)",
                            marginLeft: "0.5rem",
                          }}
                        >
                          Ruta Nac N°16 Km 18,5 Resistencia Chaco
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Punto de Venta:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.54)",
                            marginLeft: "0.5rem",
                          }}
                        >
                          {dataRecibo?.punto_venta ?? "-"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      xl={5}
                      lg={5}
                      md={5}
                      sm={5}
                      xs={5}
                      style={{
                        paddingLeft: "5rem",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          N° de Recibo:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.54)",
                            marginLeft: "0.5rem",
                          }}
                        >
                          {dataRecibo?.num_factura ?? "-"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Fecha Emisión:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.54)",
                            marginLeft: "0.5rem",
                          }}
                        >
                          {moment(dataRecibo?.fecha_emision).format(
                            "DD/MM/YYYY",
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Fecha Vto. Pago:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.54)",
                            marginLeft: "0.5rem",
                          }}
                        >
                          {moment(dataRecibo?.fecha_venc_pago).format(
                            "DD/MM/YYYY",
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ marginTop: "3rem" }}
                  >
                    <Grid
                      xl={7}
                      lg={7}
                      md={7}
                      sm={7}
                      xs={7}
                      style={{ paddingLeft: "2.5rem" }}
                    >
                      <Grid container>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Cliente:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.54)",
                            marginLeft: "0.5rem",
                          }}
                        >
                          {dataRecibo?.cliente?.nombre ?? "-"}
                        </Typography>
                      </Grid>

                      <Grid container>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Domicilio:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            fontWeight: 500,
                            color: "rgba(0, 0, 0, 0.54)",
                            marginLeft: "0.5rem",
                          }}
                        >
                          {dataRecibo
                            ? `${dataRecibo?.cliente?.direccion} ${dataRecibo?.cliente?.ciudad} ${dataRecibo?.cliente?.provincia}`
                            : "-"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid xl={5} lg={5} md={5} sm={5} xs={5}>
                      <Grid container alignItems="center">
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Liquidación:
                        </Typography>
                        {dataRecibo?.resumen ? (
                          <Link
                            style={{
                              fontWeight: 700,
                              marginLeft: "0.5rem",
                              fontSize: "17px",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            onClick={() => VerLiquidacion(dataRecibo?.resumen)}
                          >
                            {dataRecibo?.resumen ?? "-"}
                          </Link>
                        ) : (
                          "-"
                        )}
                      </Grid>

                      <Grid container alignItems="center">
                        <Typography
                          style={{
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "rgba(67, 59, 59, 0.87)",
                            fontWeight: 700,
                          }}
                        >
                          Orden de Compra:
                          {!dataRecibo?.orden_compra?.id && " -"}
                        </Typography>
                        {dataRecibo?.orden_compra?.id && (
                          <Link
                            style={{
                              fontWeight: 700,
                              marginLeft: "0.5rem",
                              fontSize: "17px",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            onClick={() =>
                              history.push(
                                `/ordenes/ver-orden-negro/${dataRecibo?.orden_compra?.id}/`,
                              )
                            }
                          >
                            {dataRecibo?.orden_compra?.num_orden}
                          </Link>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="center"
                    style={{ padding: "1.5rem 2rem 0rem 2rem" }}
                    className={classes.generalContainer}
                  >
                    <TableContainer className={classes.tableContainer}>
                      <Table className={classes.table} size="small">
                        <TableHead>
                          <TableRow
                            className={classes.tableRow}
                            style={{
                              background: "#E26E6E",
                              height: "2.5rem",
                            }}
                          >
                            <TableCell
                              width="15%"
                              className={classes.tableCellHeaders}
                            >
                              Producto/Servicio
                            </TableCell>

                            <TableCell
                              width="15%"
                              className={classes.tableCellHeaders}
                            >
                              Cantidad M³
                            </TableCell>

                            <TableCell
                              width="15%"
                              className={classes.tableCellHeaders}
                            >
                              Precio Unitario
                            </TableCell>

                            <TableCell
                              width="15%"
                              className={classes.tableCellHeaders}
                            >
                              Bonificación
                            </TableCell>

                            <TableCell
                              width="15%"
                              className={classes.tableCellHeaders}
                            >
                              Importe
                            </TableCell>

                            <TableCell
                              width="15%"
                              className={classes.tableCellHeaders}
                            >
                              SubTotal
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataRecibo?.linea_factura.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell className={classes.tableCell}>
                                {item?.tipo ?? "-"}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {item?.cant_metros?.toLocaleString("es-AR") ??
                                  "-"}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {item?.precio_unitario?.toLocaleString(
                                  "es-AR",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "ARS",
                                  },
                                ) ?? "-"}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {item?.bonif?.toLocaleString("es-AR", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "ARS",
                                }) ?? "-"}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {item?.importe?.toLocaleString("es-AR", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "ARS",
                                }) ?? "-"}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {item?.subtotal?.toLocaleString("es-AR", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "ARS",
                                }) ?? "-"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid
                      container
                      justifyContent="flex-end"
                      style={{ marginTop: "1rem", paddingBottom: "2rem" }}
                    >
                      <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        {`Total $ ${
                          calcularTotal()?.toLocaleString("es-AR", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }) ?? "-"
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <DetallePagoB
                open={openDetallePago}
                setOpen={setOpenDetallePago}
                recibo={dataRecibo}
              />
            </Grid>
          ) : (
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                paddingTop: "14rem",
              }}
            >
              <CircularProgress />
            </Grid>
          )}
        </>
      </RootContainer>
    </Protected>
  );
};

export default VisualizarRecibo;
