import axios from "./axios.config";

class MaterialesService {
  /***
    ENDPOINT DE MATERIALES
  ***/

  /*
    Retorna lista de Materiales
  */
  getMateriales() {
    return axios.get("/api/proveedores/materiales/");
  }

  /*
    Retorna lista de Materiales Sin Paginar
  */
  getMaterialesSinPaginar() {
    return axios.get("api/proveedores/materiales-sin-paginacion/tipos/");
  }

  /*
    Crear Material
  */
  createMaterial(data) {
    return axios.post(`/api/formulas/crear-material-lista/`, data);
  }

  /*
    Edita Material por id
    params: id del material y data a modificar
  */
  editMaterial(id, data) {
    return axios.patch(`/api/formulas/modificar-material-lista/${id}/`, data);
  }

  /*
    Elimina Material por id
    params: id del material a eliminar
  */
  deleteMaterial(id) {
    return axios.delete(`/api/formulas/eliminar-material-lista/${id}/`);
  }
}

export default new MaterialesService();
