import { React } from "react";
import Protected from "protected";
import {
    Container,
    Typography,
  } from "@material-ui/core";

const Tracking = () => {

return (
    <Protected>
    <Container
        maxWidth="lg"
        style={{
            marginTop: "7rem",
            borderRadius: 10,
        }}
    >
        <Typography
            variant="h5"
            style={{
                marginTop: "8rem",
                height: "3rem",
                textAlign: "center",
            }}
        >
                        Modulo Admin - Tracking de usuarios

        </Typography>
    </Container>
    </Protected>
      );
};
export default Tracking;