import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import routes from "./config/Routes";
import "./App.css";
import Theme from "./theme";

import { ToggleDrawerProvider } from "context/ToggleDrawerContext";
import { UserProvider } from "context/UserContext";
import { UserManageProvider } from "context/UserManage";
import { LogInProvider } from "context/LogInContext";
import { NotificationProvider } from "context/notification/NotificationContext";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 1 } },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <ToggleDrawerProvider>
          <UserProvider>
            <UserManageProvider>
              <LogInProvider>
                <NotificationProvider>
                  <QueryClientProvider client={queryClient}>
                    <Switch>
                      {routes.map((route, index) => (
                        <RouterWithSubRoutes key={index} {...route} />
                      ))}
                    </Switch>
                  </QueryClientProvider>
                </NotificationProvider>
              </LogInProvider>
            </UserManageProvider>
          </UserProvider>
        </ToggleDrawerProvider>
      </ThemeProvider>
    </Router>
  );
}

function RouterWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component routes={route.routes} {...props} />}
    />
  );
}

export default App;
