// recivie a object and return a new url
// ex: obj = {
//              pageNum = 3,
//              perPageNum = 10,
//              sort = "",
//              search=null
//            }
// return "pageNum=3&perPageNum=10"
export function createNewUrl(parameters) {
  //remove all falsy element
  const url = Object.entries(
    Object.fromEntries(
      Object.entries(parameters).filter(([_, value]) => value),
    ),
  ) // [ [ 'pageNum', 3 ], [ 'perPageNum', 10 ], [ 'category', 'food' ] ]
    .map(([key, value]) => `${key}=${value}`) // [ 'pageNum=3', 'perPageNum=10', 'category=food' ]
    .join("&");

  return url ? `?${url}` : "";
}
