/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Protected from "protected";

import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { useQueryClient } from "react-query";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useLocation } from "hook/useLocation";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import rules from "constants/rules";
import Theme from "theme";

// Components
import RootContainer from "layout/rootContainer";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import ResponsableCustomModal from "components/responsableCustomModal/responsableCustomModal";
import RadioCustom from "components/Layout/radio/radioCustom";
import InputField from "components/Layout/inputCustom/inputField";
import ConfirmDialog from "components/ConfirmDialog";
import CheckboxCustom from "components/Layout/checkbox/checkboxCustom";
import CustomSnackbar from "components/Layout/CustomSnackbars";

// Services
import ClientesService from "services/clientes.service";
import NotificationService from "services/notification/notification.service";

const styles = makeStyles({
  inputDisabled: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black",
    },
  },
});

const CrearCliente = () => {
  // styles
  const classes = styles();
  // Uso History y Params para tomar datos del cliente y Id
  const history = useHistory();
  const queryClient = useQueryClient();

  // ESTADOS
  const [datosCliente] = useState(history?.location?.state?.cliente);
  const [editar] = useState(history?.location?.state?.editar);
  const [deshabilitarIVA, setDeshabilitarIVA] = useState(
    editar && datosCliente.tipo_de_cliente === "EMPRESA" ? false : true,
  );
  const [cargando, setCargando] = useState(false);
  const [checkedEstado, setCheckedEstado] = useState(datosCliente?.estado);
  const [responsables, setResponsables] = useState([]);
  // Estados para el manejo de validaciones
  const [errorDNI, setErrorDNI] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [errorIva, setErrorIva] = useState(false);
  const [errorIvaMessage, setErrorIvaMessage] = useState("");
  const [errorProvincia, setErrorProvincia] = useState(false);
  const [errorProvinciaMessage, setErrorProvinciaMessage] = useState("");

  // INICIO DECLARACIONES DE LIBRERIA ENCARGADA DE VALIDACIONES
  const { handleSubmit, control, setValue, setError, clearErrors, watch } =
    useForm({
      defaultValues: {
        cuil_dni: datosCliente?.cuil_dni && datosCliente?.cuil_dni,
        nombre: datosCliente?.nombre ? datosCliente?.nombre : "",
        provincia: datosCliente?.provincia ? datosCliente?.provincia : "",
        localidad: datosCliente?.ciudad ? datosCliente?.ciudad : "",
        condicion_iva: datosCliente?.condicion_iva
          ? datosCliente?.condicion_iva
          : null,
        estado: datosCliente?.estado ? datosCliente?.estado : "",
        direccion: datosCliente?.direccion ? datosCliente?.direccion : "",
        email: datosCliente?.email ? datosCliente?.email : "",
        num_telefono: datosCliente?.num_telefono
          ? datosCliente?.num_telefono
          : "",
        ciudad: datosCliente?.ciudad ? datosCliente?.ciudad : "",
      },
    });

  // watch de useForm para actualizar variables
  const ivaseleccionado = watch("condicion_iva");
  const dniCuil = watch("cuil_dni");
  const provincia = watch("provincia");

  // DECLARACION DE ESTADO DE SNACKBAR
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });

  // Modal de Responsables
  const [modalResponsableVisibility, setModalResponsableVisibility] =
    useState(false);

  const handleCloseModalResponsable = () => {
    setModalResponsableVisibility(false);
  };

  const asignarResponsables = () => {
    setModalResponsableVisibility(true);
  };

  // VARIABLES NECESARIAS PARA EL CUSTOM HOOK DE PROV, LOC Y DEPTOS
  let prov = datosCliente?.provincia;

  // CUSTOM HOOK PARA TRAER LAS PROVINCIAS, LOCALIDADES Y DEPTOS
  const { provincias } = useLocation({ prov, setValue });

  // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  // FUNCION QUE REDIRIGE A LA PANTALLA LISTA DE CLIENTES
  const Volver = () => {
    history.push(`/clientes`);
  };

  //FUNCION CARGA DNICUIL
  const handleChangeDni = (e) => {
    const regCUIT = RegExp(/^[0-9]{11}$/i);
    const regDNI = RegExp(/^[0-9]{6,8}$/i);
    if (regDNI.test(e)) {
      setErrorDNI(false);
      setMessageError("");
      setValue("tipo", "Particular");
      clearErrors("tipo");
      setDeshabilitarIVA(true);
      setValue("cuil_dni", e);
    } else if (regCUIT.test(e)) {
      setErrorDNI(false);
      setMessageError("");
      setValue("tipo", "Empresa");
      clearErrors("tipo");
      setDeshabilitarIVA(false);
      setValue("cuil_dni", e);
    } else {
      setErrorDNI(true);
      setMessageError("Número Inválido");
      setValue("tipo", "");
      setError("tipo");
    }
  };

  // Función Validar Campos independientes del useForm
  const validarCampos = () => {
    if (!ivaseleccionado) {
      setErrorIva(true);
      setErrorIvaMessage("Este campo es requerido");
    } else {
      setErrorIva(false);
      setErrorIvaMessage("");
    }
    if (!dniCuil) {
      setErrorDNI(true);
      setMessageError("Este campo es requerido");
    } else {
      setErrorDNI(false);
      setMessageError("");
    }
    if (!provincia) {
      setErrorProvincia(true);
      setErrorProvinciaMessage("Este campo es requerido");
    } else {
      setErrorProvincia(false);
      setErrorProvinciaMessage("");
    }
    if (!ivaseleccionado || !dniCuil || !provincia) {
      return true;
    } else {
      return false;
    }
  };

  // FUNCION ONSUBMIT ENVIAR DATOS AL BACK
  const onSubmit = async (data) => {
    //verifico que no haya errores
    if (!validarCampos()) {
      // ARMO OBJETO PARA ENVIAR AL BACK
      let arrayResponsables = { dataResponsables: responsables };
      const dataSend = new FormData();
      dataSend.append("tipo_de_cliente", data?.tipo ? data.tipo : "");
      dataSend.append("nombre", data?.nombre ? data.nombre.toUpperCase() : "");
      dataSend.append("email", data?.email ? data.email : "");
      dataSend.append(
        "num_telefono",
        data?.num_telefono ? data.num_telefono : "",
      );
      dataSend.append("cuil_dni", data?.cuil_dni && data.cuil_dni);
      dataSend.append(
        "notificacion",
        data?.notificaciones ? data.notificaciones : false,
      );

      dataSend.append(
        "condicion_iva",
        data?.condicion_iva ? data?.condicion_iva : "",
      );
      dataSend.append("estado", data?.estado ? data.estado : "activo");
      dataSend.append(
        "direccion",
        data?.direccion ? data.direccion.toUpperCase() : "",
      );
      dataSend.append(
        "provincia",
        data?.provincia ? data.provincia.toUpperCase() : "",
      );

      dataSend.append(
        "ciudad",
        data?.localidad ? data.localidad.toUpperCase() : "",
      );

      dataSend.append(
        "responsables",
        arrayResponsables?.dataResponsables
          ? JSON.stringify(arrayResponsables)
          : "",
      );

      let back = data?.tipo;
      let endpoint = "";
      let type = "";

      if (editar === true) {
        type = "editado";
        if (back === "Particular") {
          endpoint = ClientesService.editCliente(datosCliente?.id, dataSend);
        } else {
          endpoint = ClientesService.editClienteEmpresa(
            datosCliente?.id,
            dataSend,
          );
        }
      } else {
        type = "creado";
        if (back === "Particular") {
          endpoint = ClientesService.addCliente(dataSend);
        } else {
          endpoint = ClientesService.addClienteEmpresa(dataSend);
        }
      }

      try {
        setCargando(true);
        const res = await endpoint;
        if (res.status > 199 && res.status < 300) {
          //add y editar los clientes en la segunda base N
          if (data?.notificaciones) {
            let user_id = {
              cliente_id: editar ? datosCliente?.id : res.data.id,
            };
            // eslint-disable-next-line no-unused-vars
            const resp = await NotificationService.notificationPost(user_id);
          }
          //eliminar de la segunda base si el ya estaba en N
          else if (data?.notificaciones === false && editar) {
            const resp =
              (await datosCliente?.id) &&
              NotificationService.notificationDelete(datosCliente?.id);
          }
          setSnackState({
            open: true,
            severity: "success",
            message: `Cliente ${type} correctamente`,
          });
          setTimeout(() => {
            Volver();
          }, 1000);
          queryClient.invalidateQueries("Clientes");
        } else {
          setCargando(false);
          setSnackState({
            open: true,
            severity: "error",
            message: `Error al agregar cliente`,
          });
        }
      } catch (error) {
        setCargando(false);
        if (error?.response?.data?.cuil_dni) {
          setErrorDNI("true");
          setSnackState({
            open: true,
            severity: "error",
            message: error?.response?.data?.cuil_dni,
          });
        } else {
          setSnackState({
            open: true,
            severity: "error",
            message: "Error Servidor",
          });
        }
      }
    }
  };

  const condicionesIVA = [
    { nombre: "Régimen Simplificado", value: "REGIMEN SIMPLIFICADO" },
    { nombre: "Responsable Inscripto", value: "RESPONSABLE INSCRIPTO" },
    { nombre: "Exento", value: "EXENTO" },
    { nombre: "Monotributista", value: "MONOTRIBUTISTA" },
    { nombre: "Consumidor Final", value: "CONSUMIDOR FINAL" },
  ];
  const [condicionIVA, setCondicionIVA] = useState(condicionesIVA);

  // Funcion busqueda de Condicion
  const buscarCondicion = (e) => {
    let text = e.toUpperCase();
    let condicion = condicionesIVA.filter((item) => item.value.includes(text));
    setCondicionIVA(condicion);
  };

  const handleChangeCondicionIva = (condicion) => {
    setValue("condicion_iva", condicion.value);
    setErrorIva(false);
    setErrorIvaMessage("");
  };

  const handleChangeProvincia = (provincia) => {
    setValue("provincia", provincia.nombre);
    setErrorProvincia(false);
    setErrorProvinciaMessage("");
  };

  return (
    <Protected>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RootContainer>
          <Grid
            container
            item
            style={{
              paddingLeft: "2vw",
              paddingRight: "2vw",
              height: "2.5rem",
              marginBottom: "2rem",
              marginTop: "2rem",
            }}
          >
            {/* TITULO DE LA VISTA  */}
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              item
              style={{
                height: "3rem",
                backgroundColor: Theme.palette.primary.main,
                borderRadius: 5,
                paddingLeft: "3vw",
              }}
            >
              {editar ? (
                <Typography variant="h5" style={{ color: "#ffffff" }}>
                  Modificar Cliente
                </Typography>
              ) : (
                <Typography variant="h5" style={{ color: "#ffffff" }}>
                  Crear Cliente
                </Typography>
              )}
            </Grid>
            <Grid
              container
              item
              alignItems="flex-start"
              style={{
                minHeight: "10vh",
                paddingTop: "2rem",
                backgroundColor: "white",
              }}
            >
              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-around"
                style={{ backgroundColor: "white" }}
              >
                {/** COLUMNA DERECHA */}
                <Grid
                  container
                  direction="column"
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: "0.5rem 1rem" }}
                >
                  {/** cuil */}
                  <TextField
                    item
                    fullWidth
                    name="cuil"
                    label="CUIT o DNI"
                    variant="outlined"
                    placeholder="Ingrese CUIT o DNI sin puntos ni guiones"
                    size="small"
                    type="text"
                    autoFocus
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    error={errorDNI}
                    helperText={messageError}
                    defaultValue={datosCliente?.cuil_dni}
                    style={{ marginBottom: "1rem" }}
                    onChange={(e) => handleChangeDni(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                  />
                  {/** tipo empresa */}
                  <InputField
                    item
                    fullWidth
                    name="tipo"
                    label="Tipo de Cliente"
                    variant="outlined"
                    disabled
                    size="small"
                    type="text"
                    defaultValue={datosCliente?.tipo_de_cliente}
                    className={classes.inputDisabled}
                    control={control}
                    rules={rules.generic}
                    style={{ marginBottom: "1rem" }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                  />
                  {/** IVA */}
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={condicionIVA}
                    disableClearable
                    getOptionLabel={(option) => option.nombre}
                    filterOptions={(options) => options}
                    freeSolo
                    defaultValue={{ nombre: datosCliente?.condicion_iva }}
                    style={{ marginBottom: "1rem" }}
                    onChange={(e, o) => handleChangeCondicionIva(o)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        type={"text"}
                        label="Condición IVA"
                        error={errorIva}
                        helperText={errorIvaMessage}
                        placeholder={
                          datosCliente?.condicion_iva ?? "Condición IVA "
                        }
                        variant="outlined"
                        InputLabelProps={{ shrink: true, required: true }}
                        onChange={(e) => buscarCondicion(e.target.value)}
                      />
                    )}
                  />
                  {/** nombre */}
                  <InputField
                    item
                    fullWidth
                    name="nombre"
                    label="Nombre*"
                    variant="outlined"
                    size="small"
                    type="text"
                    autoComplete="off"
                    rules={rules.nombre}
                    control={control}
                    style={{ marginBottom: "1rem" }}
                  />
                  {/** EMAIL */}
                  <InputField
                    item
                    fullWidth
                    name="email"
                    label="Email"
                    variant="outlined"
                    size="small"
                    type="text"
                    inputProps={{
                      autocomplete: "new-password",
                    }}
                    rules={rules.emailNoRequired}
                    control={control}
                    style={{ marginBottom: "1rem" }}
                  />
                  {/** TELEFONO */}
                  <InputField
                    item
                    fullWidth
                    name="num_telefono"
                    label="N° de Teléfono*"
                    variant="outlined"
                    size="small"
                    type="text"
                    inputProps={{
                      autocomplete: "new-password",
                    }}
                    rules={rules.generic}
                    control={control}
                    style={{ marginBottom: "1rem" }}
                  />
                </Grid>
                {/* COLUMNA IZQUIERDAAAAAAAAAAAAA*/}
                <Grid
                  container
                  direction="column"
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: "0.5rem 1rem" }}
                >
                  {/** provincia */}
                  <Autocomplete
                    item
                    fullWidth
                    size="small"
                    disableClearable
                    freeSolo
                    style={{ marginBottom: "1rem" }}
                    options={provincias}
                    defaultValue={{ nombre: datosCliente?.provincia }}
                    getOptionLabel={(options) => options.nombre}
                    onChange={(e, o) => handleChangeProvincia(o)}
                    renderInput={(params) => (
                      <TextField
                        type="text"
                        label="Provincia"
                        {...params}
                        variant="outlined"
                        error={errorProvincia}
                        helperText={errorProvinciaMessage}
                        placeholder={datosCliente?.provincia ?? "Provincia"}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                  {/** LOCALIDAD */}
                  <InputField
                    item
                    fullWidth
                    name="localidad"
                    label="Ciudad*"
                    variant="outlined"
                    size="small"
                    type="text"
                    inputProps={{
                      autocomplete: "new-password",
                    }}
                    rules={rules.generic}
                    control={control}
                    style={{ marginBottom: "1rem" }}
                  />
                  {/** direccion */}
                  <InputField
                    item
                    fullWidth
                    name="direccion"
                    label="Dirección*"
                    variant="outlined"
                    size="small"
                    type="text"
                    inputProps={{
                      autocomplete: "new-password",
                    }}
                    rules={rules.generic}
                    control={control}
                    style={{ marginBottom: "1rem" }}
                  />
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid
                      item
                      direction="column"
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                    >
                      <CheckboxCustom
                        item
                        name="notificaciones"
                        control={control}
                        color="primary"
                        label="¿Recibir Notificaciones?"
                        labelPlacement="start"
                        defaultChecked={datosCliente?.notificacion}
                        style={{ marginBottom: "1rem" }}
                      />
                      <CheckboxCustom
                        item
                        name="promociones"
                        color="primary"
                        control={control}
                        label="¿Recibir Promociones?"
                        labelPlacement="start"
                        style={{ marginBottom: "1rem" }}
                      />
                      <Grid>
                        {(!deshabilitarIVA ||
                          datosCliente?.tipo_de_cliente === "Empresa") && (
                          <Button
                            item
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginTop: "1rem" }}
                            onClick={() => asignarResponsables()}
                          >
                            {datosCliente?.id
                              ? "Editar Responsables"
                              : "Asignar Responsables"}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    {editar ? (
                      <Grid
                        item
                        container
                        direction="column"
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                      >
                        <Grid item>
                          {datosCliente?.tipo_de_cliente === "PARTICULAR" ? (
                            <Typography variant="subtitle2">
                              ¿Cliente Activo?
                            </Typography>
                          ) : (
                            <Typography variant="subtitle2">
                              ¿Empresa Activa?
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <RadioCustom
                            name="estado"
                            control={control}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "40%",
                            }}
                          >
                            <FormControlLabel
                              value="activo"
                              control={<Radio />}
                              label="Si"
                              checked={checkedEstado === "activo"}
                              onChange={() => setCheckedEstado("activo")}
                            />
                            <FormControlLabel
                              value="inactivo"
                              control={<Radio />}
                              label="No"
                              checked={checkedEstado === "inactivo"}
                              onChange={() => setCheckedEstado("inactivo")}
                            />
                          </RadioCustom>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              {/* CONTENEDOR BOTONES VOLVER Y CONFIRMAR */}
              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                direction="row"
                justifyContent="flex-end"
                alignContent="left"
                style={{
                  padding: "1rem",
                  marginTop: "5rem",
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ marginRight: "0.5rem" }}
                  onClick={() =>
                    setConfirmDialog({
                      title: `No se guardarán los datos cargados del cliente`,
                      isOpen: true,
                      onConfirm: () => {
                        Volver();
                      },
                    })
                  }
                >
                  Volver
                </Button>
                <ButtonLoading
                  type="submit"
                  onClick={() => validarCampos()}
                  disabled={cargando ? true : false}
                  isLoading={cargando}
                >
                  Confirmar
                </ButtonLoading>
              </Grid>
            </Grid>
            <ResponsableCustomModal
              open={modalResponsableVisibility}
              handleClose={handleCloseModalResponsable}
              arrayResponsables={setResponsables}
              arrayResponsablesCargados={responsables}
              idCliente={datosCliente?.id}
              setSnackBar={setSnackState}
            />
          </Grid>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <CustomSnackbar
            message={snackState.message}
            open={snackState.open}
            severity={snackState.severity}
            onClose={onClose}
          />
        </RootContainer>
      </form>
    </Protected>
  );
};

export default CrearCliente;
