// MUI && REACT 6& LIBRARIES
import { Grid, Typography } from "@material-ui/core";
import { useState } from "react";


// COMPONENTS
import AgregarArchivo from "screens/facturacion/agregarArchivo";


//STYLES
import LinesFacturaCompraAnticipada from "../components/linesFacturaCompraAnticipada";
import HeaderFacturaCompraAnticipada from "../components/headerFacturaCompraAnticipada";
import { useForm } from "react-hook-form";


const CrearFacturaCompraAnticipada = ({ clienteSeleccionado, handleClickAgregarPagos, dataSend, fields, control, calcularTotal, setSnackState, watchTestLine, actualizar, importandoDatos, archivoSeleccionado, setArchivoSeleccionado, importarDatosPDF }) => {
   // Estados
   const [modalAgregarArchivo, setModalAgregarArchivo] = useState(false);
   const [URLArchivo, setURLArchivo] = useState(null);
   
   return (
       <>
           <Grid
               container
               style={{
                   padding: "1rem 4rem",
               }}
           >
               <HeaderFacturaCompraAnticipada
                   control={control}
                   setModalAgregarArchivo={setModalAgregarArchivo}
                   archivoSeleccionado={archivoSeleccionado}
                   handleClickAgregarPagos={handleClickAgregarPagos}
                   URLArchivo={URLArchivo}
                   clienteSeleccionado={clienteSeleccionado}
                   dataSend={dataSend}
                   importandoDatos={importandoDatos}
                   importarDatosPDF={importarDatosPDF}
               />
               <LinesFacturaCompraAnticipada
                   fields={fields}
                   control={control}
                   actualizar={actualizar}
                   watchTestLine={watchTestLine}
               />
               {/* TOTAL */}
               <Grid
                   container
                   justifyContent="flex-end"
                   style={{ marginTop: "1rem" }}
               >
                   <Typography
                       variant="body1"
                       style={{ fontWeight: "bold" }}
                   >
                       {"Total " +
                           calcularTotal()?.toLocaleString("es-AR", {
                               maximumFractionDigits: 2,
                               minimumFractionDigits: 2,
                               style: "currency",
                               currency: "ARS",
                           }) ?? "-"
                       }
                   </Typography>
               </Grid>
           </Grid>
           {modalAgregarArchivo &&
               <AgregarArchivo
                   open={modalAgregarArchivo}
                   handleClose={setModalAgregarArchivo}
                   setArchivoSeleccionado={setArchivoSeleccionado}
                   setURLArchivo={setURLArchivo}
                   setSnackState={setSnackState}
               />
           }
          
       </>
   )
}


export default CrearFacturaCompraAnticipada

