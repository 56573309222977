// MUI && REACT 6& LIBRARIES
import { Button, Checkbox, FormControlLabel, Grid, Table, Paper, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, TableBody, withStyles, MenuItem, CircularProgress, Collapse, } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import Theme from "theme";
import moment from "moment";
import { Alert, Stack } from "@mui/material";


// HOOK
import { useOrdenesById } from "screens/ordenes/hook";

// SERVICES
import facturacionServices from "services/facturacion.services";
import remitosService from "services/remitos.service";

// COMPONENTS
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import Card from "components/card";
import ConfirmDialog from "components/ConfirmDialog";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import InputNumber from "components/inputs/newInputNumber";
import InputText from "components/inputs/newInputText";
import InputField from "components/Layout/inputCustom/inputField";
import SelectCustom from "components/Layout/select/selectCustom";
import ModalAsociarTurno from "components/remitos/modalAsociarTurno";
import mostrarErrorEP from "constants/funciones/mostrarError";
import rules from "constants/rules";

// ICONS
import AddIcon from "@material-ui/icons/Add";

// STYLES
const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "#000000"
        }
    }
})(TextField);

const CargarRemitoAnticipado = () => {
    const history = useHistory();
    // Desestructuring history state datosOrden: datos de orden 
    const { datosOrden } = history.location?.state;

    // ESTADO DE CREACION DE REMITO
    const [creandoRemito, setCreandoRemito] = useState(false);

    // FORMULAS CON DISPONIBLES DE HORMIGON
    const [formulasConDisponibles, setFormulasConDisponibles] = useState([]);

    // ESTADO APERTURA/CIERRE MODAL ASOCIAR TURNO
    const [modalAsociarTurnoVisibility, setModalAsociarTurnoVisibility] = useState(false);

    const [turnoSeleccionado, setTurnoSeleccionado] = useState([]);

    // FUNCION PARA ABRIR MODAL ASOCIAR TURNO
    const asociarTurno = () => {
        setModalAsociarTurnoVisibility(true);
    };

    // FUNCION DE CIERRE MODAL DE ASOCIAR TURNO
    const handleCloseModalAsociarTurno = () => {
        setModalAsociarTurnoVisibility(false);
    };

    //ESTADO DE CONFIRM DIALOG
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        onConfirm: null,
    });

    // ESTADO DE SNACKBAR
    const [snackState, setSnackState] = useState({
        message: "no message",
        open: false,
        autoHideDuration: 2000,
    });


    // ESTADO PARA MANEJAR EL ALERT
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("default");

    // FUNCION ENCARGADA DE CERRAR EL SNACKBAR
    function onClose() {
        setSnackState({ ...snackState, open: false });
    }

    // Use Form Remito
    const remito = useForm({
        defaultValues: {
            agenda_id: null,
            fecha: moment(datosOrden?.fecha_carga, "DD-MM-YYYY").format("YYYY-MM-DD"),
            direccion_entrega: "",
            planta: "",
            observaciones_remito: "",
            observacion_formula: "",
            ciudad: "",
            patente: "",
            obra: "",
            apynom_chofer: "",
            cantidad_mc: "",
            condicion: "",
            cliente_id: Number(datosOrden?.cliente_id),
            nro_remito: "",
            nro_factura: datosOrden?.num_factura_orden ?? "",
            formula_id: "",
            presupuesto: false,
            cambiar: null,
            factura_id: "",
            resumen_id: datosOrden?.resumen_asociado[0],
            orden_id: datosOrden?.id,
        }
    });

    // Desestructuring Form
    const { handleSubmit, control, setValue, watch, setError } = remito;

    //LO MOVI DEL MODAL, HAY QUE SEGUIR
    const [turnos, setTurnos] = useState([]);

    const obtenerTurnos = async () => {
        let dataSend = {
            cliente_id: watch("cliente_id"),
            fecha: watch("fecha"),
        };
        try {
            const res = await remitosService.getTurnoCliente(dataSend);
            if (res.status === 200) {
                setTurnos(res.data);
            }
        } catch (error) {
            return error;
        }
    };    

    // Función onSubmit
    const onSubmit = async (data) => {
        const dataSend = {
            agenda_id: turnoSeleccionado?.fecha_turno ===
                data.fecha
                ? turnoSeleccionado?.id
                : null,
            fecha: data.fecha,
            direccion_entrega: data.direccion_entrega,
            planta: data.planta,
            cliente_id: data.cliente_id,
            observaciones_remito: data.observaciones_remito,
            observacion_formula: data.observacion_formula,
            ciudad: data.ciudad,
            patente: data.patente,
            obra: data.obra,
            apynom_chofer: data.apynom_chofer,
            cantidad_mc: data.cantidad_mc,
            condicion: data.condicion,
            nro_remito: Number(data.nro_remito),
            nro_factura: Number(data.nro_factura),
            presupuesto: data.presupuesto,
            cambiar: data.cambiar,
            factura_id: data.factura_id,
            resumen_id: data.resumen_id,
            orden_id: data.orden_id,
            formula_id: data.formula_id
        }

        try {
            setCreandoRemito(true);
            const res = await facturacionServices.crearAgregarRemitoAResumenAnticipado(dataSend);
            if (res.status > 199 && res.status < 300) {
                if (res?.data?.remito_e === null) {
                    setSnackState({
                        ...setSnackState,
                        open: true,
                        severity: "success",
                        message: "Remito Creado Exitosamente",
                    });
                    setTimeout(() => {
                        setCreandoRemito(false);
                        setAlertMessage("");
                        setOpenAlert(false);
                        history.push(`/ordenes/`);
                    }, [2000]);

                } else {
                    setSnackState({
                        ...setSnackState,
                        open: true,
                        severity: "success",
                        message: `Remito Creado Exitosamente, por el excedente se genera Remito ${res?.data?.remito_e?.nro_remito}`,
                    });
                    setTimeout(() => {
                        setCreandoRemito(false);
                        setAlertMessage("");
                        setOpenAlert(false);
                        history.push(`/ordenes/`);
                    }, [3000]);
                }
            }
        } catch (error) {
            setCreandoRemito(false);
            if (error?.response?.data?.nro_remito) {
                setError("nro_remito", { message: `${error?.response?.data?.nro_remito[0] ?? ''}` });
            }
            mostrarErrorEP(error, setSnackState);
            return error;
        }
    };

    // ==========================================================================

    const onSuccess = (data) => {
        // Seteo los datos   
        setValue("factura_id", data?.factura_de_orden?.id_factura);
        setValue("condicion", data?.condicion);
        setValue("cliente_id", Number(data?.cliente_id));
        // Turno de Remito

        // Formulas con disponibles para mostrar en Selec de linea formula
        setFormulasConDisponibles(data?.detalle_orden?.filter((unaFormula) => ((unaFormula?.cant_restante !== 0) && (unaFormula?.concepto === null))));
    };

    // Query para traer datos de la orden por Id y cargar por default datos en remito
    const { data: dataOrden, isLoading: loadingDataOrden } = useOrdenesById({ idOrden: datosOrden?.id, onSuccess });

    useEffect(() => {
        obtenerTurnos();
    }, [watch("cliente_id"), watch("fecha")]);

    // Effect cuando selecciona fórmula muestra disponible en orden
    useEffect(() => {
        if (formulasConDisponibles?.length) {
            let filtrado = formulasConDisponibles?.filter(
                (formula) => formula.formula === watch("formula_id"),
            );
            setAlertMessage(
                filtrado[0]?.cant_restante < 0
                    ? `La Orden posee un excedente de ${Math.abs(
                        filtrado[0]?.cant_restante,
                    )} mts3 de ${filtrado[0]?.formula__nombre}`
                    : `La Orden posee un restante de ${Math.abs(
                        filtrado[0]?.cant_restante,
                    )} mts3 de ${filtrado[0]?.formula__nombre}`,
            );
            setOpenAlert(true);
        }
    }, [watch("formula_id")]);

    return (
        <Protected>
            <form onSubmit={handleSubmit(onSubmit)}>
                <RootContainer>
                    {loadingDataOrden ? <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            marginTop: "15rem",
                        }}
                    >
                        <CircularProgress
                            style={{ color: Theme.palette.primary.main, marginRight: "1rem" }}
                        />
                        <Typography style={{ color: "#000000" }}>Cargando Crear Remito</Typography>
                    </Grid>
                        :
                        <Grid
                            container
                            style={{
                                padding: "2rem 1rem 2rem 1rem",
                                width: "95%",
                                marginBottom: "3rem",
                            }}
                        >
                            <Card title="Cargar Remito - Compra Anticipada" justifyContent="center">
                                <Grid
                                    container
                                    style={{ margin: "0 2rem", marginTop: "2rem" }}
                                    justifyContent="flex-start"

                                >
                                    <Grid
                                        container
                                        item
                                        lg={6}
                                        xs={12}
                                    >
                                        <Grid
                                            item
                                            lg={6}
                                            xs={12}
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                        >
                                            <Grid item>
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    Cliente:*
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    {dataOrden?.cliente ?? "-"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            lg={6}
                                            xs={12}
                                        >
                                            <Grid item>
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    {"CUIT/CUIL"}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    {dataOrden?.cliente_cuil ?? "Sin Datos"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        item
                                        lg={6}
                                        xs={12}
                                    >
                                        <Grid
                                            item
                                            sm={4}
                                            xs={12}
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                        >
                                            <Grid item>
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    N° Remito:*
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <InputField
                                                    fullWidth
                                                    name="nro_remito"
                                                    label="Nro Remito*"
                                                    variant="outlined"
                                                    size="small"
                                                    type="text"
                                                    control={control}
                                                    rules={rules.generic}
                                                    InputProps={{
                                                        autoComplete: "off",
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            sm={4}
                                            xs={12}
                                        >
                                            <Grid item>
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    Planta:*
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <SelectCustom
                                                    control={control}
                                                    rules={rules.generic}
                                                    defaultValue={""}
                                                    name="planta"
                                                    label="Planta*"
                                                    size="small"
                                                >
                                                    <MenuItem value={1}>Planta 1</MenuItem>
                                                    <MenuItem value={2}>Planta 2</MenuItem>
                                                </SelectCustom>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            sm={4}
                                            xs={12}
                                        >
                                            <Grid item>
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    Fecha:*
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <InputField
                                                    name="fecha"
                                                    label="Fecha"
                                                    control={control}
                                                    rules={rules.generic}
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{
                                                        max: moment().format("YYYY-MM-DD"),
                                                    }}
                                                    type="date"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    style={{ margin: "0 2rem", marginTop: "1rem" }}
                                    justifyContent="flex-start"

                                >
                                    <Grid
                                        container
                                        item
                                        lg={6}
                                        xs={12}
                                    >
                                        <Grid
                                            item
                                            lg={6}
                                            xs={12}
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                        >
                                            <Typography style={{ marginBottom: "0.5rem" }}>
                                                N° Factura:*
                                            </Typography>
                                            <Grid item>
                                                <DarkerDisabledTextField
                                                    disabled
                                                    size="small"
                                                    defaultValue={dataOrden?.num_factura_orden ?? "Sin Datos"}
                                                    name="nro_factura"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            lg={6}
                                            xs={12}
                                        >
                                            <Grid item >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="SomeName"
                                                            value="SomeValue"
                                                            checked={watch("presupuesto")}
                                                            disabled

                                                        />
                                                    }
                                                    label="Pagado"
                                                    labelPlacement="start" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    style={{ margin: "0 2rem", marginTop: "1rem" }}
                                    justifyContent="flex-start"

                                >

                                    <Grid
                                        item
                                        lg={4}
                                        xs={12}
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                    >
                                        <Typography style={{ marginBottom: "0.5rem" }}>
                                            Obra:*
                                        </Typography>
                                        <Grid item>
                                            <InputField
                                                fullWidth
                                                name="obra"
                                                label="Ingrese Nombre Obra"
                                                variant="outlined"
                                                size="small"
                                                type="text"
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                control={control}
                                                rules={rules.generic}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={4}
                                        xs={12}
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                    >
                                        <Typography style={{ marginBottom: "0.5rem" }}>
                                            Dirección de Entrega:*
                                        </Typography>
                                        <Grid item>
                                            <InputField
                                                fullWidth
                                                name="direccion_entrega"
                                                label="Ingrese Calle y Número"
                                                variant="outlined"
                                                size="small"
                                                type="text"
                                                autoComplete="new-password"
                                                control={control}
                                                rules={rules.generic}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={4}
                                        xs={12}
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                    >
                                        <Typography style={{ marginBottom: "0.5rem" }}>
                                            Ciudad:*
                                        </Typography>
                                        <Grid item>
                                            <InputField
                                                fullWidth
                                                name="ciudad"
                                                label="Ciudad"
                                                variant="outlined"
                                                size="small"
                                                type="text"
                                                autoComplete="new-password"
                                                defaultValue={""}
                                                control={control}
                                                rules={rules.generic}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    style={{ margin: "0 2rem", marginTop: "1rem" }}
                                    justifyContent="flex-start"
                                >
                                    <Grid
                                        item
                                        lg={6}
                                        xs={12}
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                    >
                                        <Typography style={{ marginBottom: "0.5rem" }}>
                                            Observaciones:
                                        </Typography>
                                        <Grid item>
                                            <InputField
                                                fullWidth
                                                name="observaciones_remito"
                                                label="Ingrese una Observación"
                                                variant="outlined"
                                                size="small"
                                                type="text"
                                                control={control}
                                                rules={rules.observacion}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={2}
                                        xs={12}
                                        container
                                        direction="column"
                                        alignContent="flex-start"
                                    >
                                        <Typography style={{ marginBottom: "0.5rem" }}>
                                            Asociar Turno:
                                        </Typography>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<AddIcon />}
                                                onClick={() => asociarTurno()}
                                            >
                                                Asociar Turno
                                            </Button>
                                        </Grid>

                                    </Grid>
                                    {turnoSeleccionado?.fecha_turno ===
                                        watch("fecha") && (
                                            <Grid
                                                container
                                                direction="column"
                                                item
                                                lg={4}
                                                xs={12}
                                                justifyContent="flex-start"
                                                style={{ padding: "0.5rem", marginTop: "0.5rem", backgroundColor: "#F3F3F3" }}
                                                alignItems="flex-start"
                                            >
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    Turno Seleccionado
                                                </Typography>
                                                <Grid
                                                    item
                                                    container
                                                    direction="column"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Grid item container direction="row">
                                                        <Typography
                                                            style={{
                                                                fontSize: "15px",
                                                                fontWeight: 700,
                                                                marginRight: "0.5rem",
                                                            }}
                                                        >
                                                            Día:{" "}
                                                        </Typography>
                                                        <Typography>
                                                            {moment(turnoSeleccionado?.fecha_turno).format(
                                                                "DD-MM-YYYY",
                                                            ) ?? "-"}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: "15px",
                                                                fontWeight: 700,
                                                                marginLeft: "1rem",
                                                                marginRight: "0.5rem",
                                                            }}
                                                        >
                                                            {" "}
                                                            Hora:{" "}
                                                        </Typography>
                                                        <Typography>
                                                            {turnoSeleccionado?.hora_salida
                                                                ? moment(
                                                                    turnoSeleccionado?.hora_salida,
                                                                    "HH:mm:ss",
                                                                ).format("HH:mm")
                                                                : "-"}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item container direction="row">
                                                        <Typography
                                                            style={{
                                                                fontSize: "15px",
                                                                fontWeight: 700,
                                                                marginRight: "0.5rem",
                                                            }}
                                                        >
                                                            Tipo de Hormigón:{" "}
                                                        </Typography>
                                                        <Typography>
                                                            {turnoSeleccionado?.tipo_formula}(
                                                            {turnoSeleccionado?.cantidad_volumen}Mts3)
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                </Grid>
                                <Grid
                                    container
                                    style={{ margin: "0 2rem", marginTop: "1rem" }}
                                    justifyContent="flex-start"

                                >
                                    <Grid
                                        container
                                        spacing={1}
                                        item
                                        lg={6}
                                        xs={12}
                                    >
                                        <Grid
                                            item
                                            lg={6}
                                            xs={12}
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                        >
                                            <Typography style={{ marginBottom: "0.5rem" }}>
                                                Nombre y Apellido del Chofer:*
                                            </Typography>
                                            <Grid item>
                                                <InputField
                                                    fullWidth
                                                    name="apynom_chofer"
                                                    label="Nombre y Apellido del Chofer"
                                                    variant="outlined"
                                                    size="small"
                                                    type="text"
                                                    autoComplete="new-password"
                                                    control={control}
                                                    rules={rules.nombre}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            lg={6}
                                            xs={12}
                                        >
                                            <Grid item>
                                                <Typography style={{ marginBottom: "0.5rem" }}>
                                                    Patente:*
                                                </Typography>
                                            </Grid>
                                            <Grid item >
                                                <InputField
                                                    fullWidth
                                                    name="patente"
                                                    label="Ingrese Patente"
                                                    variant="outlined"
                                                    size="small"
                                                    type="text"
                                                    InputProps={{
                                                        autoComplete: "new-password",
                                                    }}
                                                    control={control}
                                                    rules={rules.patente}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0.5rem" }}>
                                            <Collapse in={openAlert}>
                                                <Stack sx={{ width: "100%" }} spacing={2}>
                                                    <Alert
                                                        variant="outlined"
                                                        severity="warning"
                                                        size="small"
                                                    >
                                                        {`${alertMessage}`}
                                                    </Alert>
                                                </Stack>
                                            </Collapse>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    style={{ margin: "0 2rem", marginTop: "1rem" }}
                                    justifyContent="flex-start"
                                >
                                    {/* tabla de formulas */}

                                    <TableContainer component={Paper} style={{ maxHeight: 180 }}>
                                        <Table stickyHeader size="small">
                                            <TableHead style={{ backgroundColor: "#F3F3F3" }}>
                                                <TableRow>
                                                    <TableCell align="left" >Tipo Hormigón</TableCell>
                                                    <TableCell align="left" >Observación</TableCell>
                                                    <TableCell align="left" >Cantidad Mts³</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {formulasConDisponibles?.length > 0 ?
                                                    <TableRow >
                                                        <TableCell align="left">
                                                            <SelectCustom
                                                                name="formula_id"
                                                                control={control}
                                                                size="small"
                                                                rules={rules.generic}
                                                                funcionAdicional={(e, value) => {
                                                                    setValue("formula_id", value);
                                                                }}
                                                            >
                                                                {formulasConDisponibles?.map(item => (
                                                                    <MenuItem value={item.formula} key={item.id}>{item.formula__nombre}</MenuItem>
                                                                ))}

                                                            </SelectCustom>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <InputText
                                                                name={`observacion_formula`}
                                                                control={control}
                                                                rules={rules.observacion}
                                                                onChange={(e, value) => {
                                                                    setValue("observacion_formula", value);
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <InputNumber
                                                                name={`cantidad_mc`}
                                                                //placeholder={"no tiene disponibles en orden"}
                                                                control={control}
                                                                rules={rules.generic}
                                                                onChange={(e, value) => {
                                                                    setValue("cantidad_mc", value);
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                    <Typography variant="body1">
                                                        No existen fórmulas con cantidad restante para agregar a factura Nro {dataOrden?.factura_de_orden?.nro_factura}
                                                    </Typography>
                                                }


                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {/* BOTONES */}
                                <Grid
                                    container
                                    spacing={1}
                                    style={{ margin: "2rem 2rem" }}
                                    justifyContent="flex-end"

                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        children="Volver"
                                        style={{ marginRight: "1rem", textTransform: "none" }}
                                        onClick={() => history.goBack()}
                                    />
                                    <ButtonLoading
                                        disabled={creandoRemito || !formulasConDisponibles?.length > 0}
                                        isLoading={creandoRemito}
                                        type="submit"
                                        children="Guardar"
                                        style={{ textTransform: "none" }}
                                    />

                                </Grid>
                            </Card>
                        </Grid>}

                    {modalAsociarTurnoVisibility &&
                        <ModalAsociarTurno
                            open={modalAsociarTurnoVisibility}
                            handleClose={handleCloseModalAsociarTurno}
                            datosSeleccionadosRemito={{ ...datosOrden, fecha_carga: watch("fecha") }}
                            turnoSeleccionado={turnoSeleccionado}
                            setTurnoSeleccionado={setTurnoSeleccionado}
                            turnos={turnos}
                        />
                    }

                    <ConfirmDialog
                        confirmDialog={confirmDialog}
                        setConfirmDialog={setConfirmDialog}
                    />
                    <CustomSnackbar
                        message={snackState.message}
                        open={snackState.open}
                        severity={snackState.severity}
                        autoHideDuration={snackState.autoHideDuration}
                        onClose={onClose}
                    />
                </RootContainer>
            </form>
        </Protected>
    )
}
export default CargarRemitoAnticipado;