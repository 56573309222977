import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

export default function ControlledSelect({
  name,
  label,
  defaultValue,
  rules,
  control,
  onChange,
  options,
  option,
  optionValue,
  disabled,
  focused,
  shrink,
  ...props
}) {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "30vh",
      },
    },
  };

  const labelId = `${name}-label`;

  const onChangeFunction = (e, value) => {
    if (onChange) {
      onChange(e, value);
    }
  };

  const findOption = (value) => {
    if (value) {
      const found = options?.find(
        (item) => item?.[optionValue] === value || item === value,
      );

      return found?.[optionValue] ?? found;
    }
  };

  return (
    <FormControl
      focused={focused}
      fullWidth
      variant="outlined"
      size="small"
      disabled={disabled}
    >
      <InputLabel id={labelId} htmlFor={labelId} shrink={shrink}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              size="small"
              name={name}
              id={name}
              labelId={labelId}
              label={label}
              error={!!error}
              value={findOption(value) ?? ""}
              onChange={(e, value) => {
                onChangeFunction(e, value);
                onChange(e?.target?.value);
              }}
              MenuProps={MenuProps}
              disabled={disabled}
              {...props}
            >
              {options?.length > 0 ? (
                options?.map((item, index) =>
                  typeof item === "object" ? (
                    <MenuItem value={item?.[optionValue]} key={index} {...item}>
                      {item?.[option]}
                    </MenuItem>
                  ) : (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ),
                )
              ) : (
                <MenuItem disabled>No se encontraron resultados</MenuItem>
              )}
            </Select>
            <FormHelperText error>
              {error ? error.message : null}
            </FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
}
