import { makeStyles } from "@material-ui/core/styles";
import Theme from "theme";

const useStyles = makeStyles({
    formControl: {
        //    margin: theme.spacing(1),
        minWidth: 150
    },
    selectEmpty: {
        marginTop: Theme.spacing(2)
    },
    menuPaper: {
        maxHeight: 160,
        maxWidth: 100,
    },
    container: {
        padding: "4rem"
    },
    cardContainerScrollable: {
        maxHeight: "65vh", overflow: "scroll"
    },
    disabledInput: {
        "&.Mui-disabled":{
            color: "#000000"
        },
    },
    inputLabel: {
        color: "#d3d3d3",
        "&.Mui-focused": {
          color: "#23A5EB"
        },
        "&.MuiInputLabel-shrink": {
            color: "#000000",
            fontWeight: 700,
            textTransform: "uppercase",
            backgroundColor: "#F2F2F2",
            padding: "0.5rem",
            top: "-10px"
        },

       
        
        cssFocused: {},
        "&.focused": {
          color: "green"
        },
        // "&.shrink": {
        //   backgroundColor: "#E0E0E0"
        // }
      },
    
});
export default useStyles;
