
export const CondicionesIVA = [
    { nombre: "Responsable Inscripto", value: "Responsable Inscripto" },
    { nombre: "Monotributista", value: "Monotributista" },
    { nombre: "IVA Responsable Inscripto", value: "IVA Responsable Inscripto" },
    { nombre: "IVA Responsable no Inscripto", value: "IVA Responsable no Inscripto" },
    { nombre: "IVA no Responsable", value: "IVA no Responsable" },
    { nombre: "IVA Sujeto Exento", value: "IVA Sujeto Exento" },
    { nombre: "Consumidor Final", value: "Consumidor Final" },
    { nombre: "Responsable Monotributo", value: "Responsable Monotributo" },
    { nombre: "Sujeto no Categorizado", value: "Sujeto no Categorizado" },
    { nombre: "Proveedor del Exterior", value: "Proveedor del Exterior" },
    { nombre: "Cliente del Exterior", value: "Cliente del Exterior" },
    { nombre: "IVA Liberado – Ley N° 19.640", value: "IVA Liberado Ley N° 19.640" },
    { nombre: "IVA Responsable Inscripto – Agente de Percepción", value: "IVA Responsable Inscripto Agente de Percepción" },
    { nombre: "Pequeño Contribuyente Eventual", value: "Pequeño Contribuyente Eventual"},
    { nombre: "Monotributista Social", value: "Monotributista Social" },
    { nombre: "Pequeño Contribuyente Eventual Social", value: "Pequeño Contribuyente Eventual Social" }
  ];
  