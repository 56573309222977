import { useState, useEffect, useRef } from "react";
import apiServices from "services/dataLocalidades.service";

export function useLocation({ prov, dpto, setValue, modal = false }) {
  const [provincias, setProvincias] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [localidades, setLocalidades] = useState([]);

  const mount = useRef(true);
  const firstUpdateDpto = useRef(true);
  const firstUpdateLoc = useRef(true);

  // Funcion para traer Listado de Provincias
  const cargarProvincias = async () => {
    try {
      const res = await apiServices.getProvincias();
      if (res.status > 199 && res.status < 299) {
        setProvincias(res.data);
      }
    } catch (error) {
      return error;
    }
  };

  // Funcion para traer Listado de Departamentos
  const getDepartamentos = async (e) => {
    //setear a blanco los campos departamento y localidad cada vez que se cambia de provincia
    // y no se trata de una valor por default
    if (!firstUpdateDpto.current || modal) {
      setLocalidades([]);
      setDepartamentos([]);
      setValue("localidad", "");
      setValue("departamento", "");
    }

    let id_prov = provincias?.find((element) => element.nombre === e)?.id;
    try {
      const res = await apiServices.getDepartamentos(id_prov);
      if (res.status > 199 && res.status < 300) {
        setDepartamentos(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Funcion para traer Listado Localidades
  const getLocalidades = async (e) => {
    //setear a blanco los campos localidad cada vez que se cambia de departamento
    // y no se trata de una valor por default
    if (!firstUpdateLoc.current || modal) {
      setValue("localidad", "");
      setLocalidades([]);
    }
    let id_departamento = departamentos?.find(
      (element) => element.nombre === e
    )?.id;
    try {
      const res = await apiServices.getLocalidades(id_departamento);
      if (res.status > 199 && res.status < 300) {
        setLocalidades(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (mount.current) {
      cargarProvincias();
      mount.current = false;
    }
    if (provincias.length > 0 && prov && firstUpdateDpto.current) {
      getDepartamentos(prov);
      firstUpdateDpto.current = false;
    }
    if (departamentos.length > 0 && dpto && firstUpdateLoc.current) {
      getLocalidades(dpto);
      firstUpdateLoc.current = false;
    }
  }, [provincias, departamentos]);

  return {
    provincias,
    departamentos,
    localidades,
    getDepartamentos,
    getLocalidades,
  };
}
