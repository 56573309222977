import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  tableContainer: {
    scrollbarWidth: "0px",
    width: "100%",
    height: window.innerHeight - 300,
  },
  table: {
    width: "100%",
  },
});
export default useStyles;
