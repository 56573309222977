export function toContable(number) {
  return (
    number?.toLocaleString("es-AR", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "currency",
      currency: "ARS",
    }) ?? "-"
  );
}
