import axios from "./axios.config";
import { namesCtaCte } from "../screens/cuenta-corriente/const";

class CuentaCorrienteService {
  listarClientes(filtro) {
    /**
     * Lista todos los clientes en blanco en el módulo Cta. Cte.
     */
    return axios.get(
      `api/listar-clientes/?page=${filtro.page}&search=${filtro?.search}`,
    );
  }

  listarClientesB(filtro) {
    /**
     * Lista todos los clientes en negro en el módulo Cta. Cte.
     */
    return axios.get(
      `api/listar-clientes-b/?page=${filtro.page}&search=${filtro?.search}`,
    );
  }

  getFacturasCtaCte(id, filtro) {
    /**
     * Lista todas las facturas en blanco en Cta. Cte.
     */
    console.log("filtros", filtro);
    return axios.get(
      `api/cta-corriente/listar-facturas-cliente/${id}/?page=${filtro?.page}&search=${filtro?.search}&fecha=${filtro?.fecha_desde}|${filtro?.fecha_hasta}&condicion=${filtro?.condicion}&dias=${filtro?.dias}&ordfechem=${filtro?.ordfecha}`,
    );
  }

  getFacturasCtaCteB(id, filtro) {
    /**
     * Lista todas las facturas en negro en Cta. Cte.
     */
    return axios.get(
      `api/cta-corriente-b/listar-facturas-cliente/${id}/?page=${filtro?.page}&search=${filtro?.search}&fecha=${filtro?.fecha_desde}|${filtro?.fecha_hasta}&condicion=${filtro?.condicion}&dias=${filtro?.dias}&ordfechem=${filtro?.ordfecha}`,
    );
  }

  cargarPagoFacturas(data) {
    /**
     * Cargar 1 pago a varias facturas en blanco
     */
    return axios.post(`api/cta-corriente/varios-pagos/`, data);
  }

  cargarPagoFacturasB(data) {
    /**
     * Cargar 1 pago a varias facturas en negro
     */
    return axios.post(`api/cta-corriente-b/varios-pagos/`, data);
  }

  cambiarCondPago(body) {
    /**
     * Cambiar Condicion de Pago a Facturas en blanco en Cta. Cte.
     */
    return axios.post(`/api/cuenta-corriente/cambiar-condicion/`, body);
  }

  cambiarCondPagoB(body) {
    /**
     * Cambiar Condicion de Pago a Facturas en negro en Cta. Cte.
     */
    return axios.post(`/api/cuenta-corriente-b/cambiar-condicion/`, body);
  }

  listarFacturasFormaPago(body) {
    /**
     * Lista las facturas seleccionadas en modal Agregar Forma de Pago
     */
    return axios.post(
      `api/cuenta-corriente/listar-facturas-cliente-formapago/`,
      body,
    );
  }

  listarRecibosFormaPago(body) {
    /**
     * Lista los recibos seleccionados en modal Agregar Forma de Pago
     */
    return axios.post(
      `api/cuenta-corriente-b/listar-facturas-cliente-formapago/`,
      body,
    );
  }

  obtenerHaberCtaCte(id) {
    return axios.get(`api/cta-corriente/total-haber/${id}/`);
  }

  getEstadoCuentaClienteById(id) {
    /*
     * @return
     */
    return axios.get(`api/cta-corriente/${id}/`);
  }
}

export default new CuentaCorrienteService();
