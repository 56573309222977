import React, { useState } from "react";
import { Typography, Modal, Grid, Button } from "@material-ui/core";
import Theme from "theme";

import CustomSnackbar from "components/Layout/CustomSnackbars";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "auto",
    height: "auto",
    overflow: "auto",
  },
}));

const AsignarFacturaModal = ({ open, handleClose, dataFormula }) => {
  const classes = useStyles();
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
  });

  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };

  return (
    <>
      <Modal open={open} className={classes.paper}>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignContent="center"
          alignItems="flex-end"
          spacing={2}
          xl={5}
          lg={5}
          md={6}
          sm={6}
          xs={11}
          style={{
            backgroundColor: "#ffffff",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
            minHeight: "auto",
            overflow: "auto",
          }}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: Theme.palette.primary.main,
              height: "3rem",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <Typography variant="h5" style={{ color: "#ffffff" }}>
              Asignar Remitos a Factura
            </Typography>
          </Grid>
          <Grid
            item
            style={{ margin: "1rem" }}
            direction="column"
            container
            justifyContent="center"
            alignContent="center"
          >
            <Typography variant="subtitle2" align="center">
              Para asignar los remitos seleccionados a una factura, busque por
              número de factura, seleccione de la lista.
            </Typography>
          </Grid>
          <Grid
            item
            style={{ margin: "1rem" }}
            direction="row"
            container
            justifyContent="center"
          >
            <Typography
              variant="h6"
              align="center"
              style={{ color: Theme.palette.error.main }}
            >
              ¡ÉSTA SECCIÓN SE ENCUENTRA EN CONSTRUCCIÓN!
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ padding: "1rem" }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="small"
              style={{ marginRight: "0.5rem" }}
              onClick={() => handleClose()}
            >
              Volver
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <CustomSnackbar
        message={snackState.message}
        open={snackState.open}
        severity={snackState.severity}
        onClose={onClose}
      />
    </>
  );
};

export default AsignarFacturaModal;
