//REACT
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useCallback } from "react";
import { useEffect } from "react";

//MATERIAL-UI
import {
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

//ICONS
import PrintIcon from "@material-ui/icons/Print";

//COMPONENTS
import Card from "components/card";
import ImprimirX from "../components/imprimirX";

//VARIOS
import moment from "moment";

export default function DetallePago({ open, setOpen, recibo }) {
  const componentRef = useRef();
  const onBeforeGetContentResolve = useRef(null);

  //======================= FUNCIONES ============================
  // Función para cerrar modal
  const handleClose = () => {
    setOpen(false);
  };

  // Función para identificar los componentes a imprimir
  const handleOnBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      resolve();
    });
  }, []);

  // Función para obtener el contenido a imprimir
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  // Función para calcular el saldo restante
  const calcularSaldo = () => {
    const resultado = recibo?.formas_pago?.reduce(
      (acumulador, itemActual) => acumulador - itemActual?.monto,
      recibo?.total,
    );

    return Number(resultado?.toFixed(2));
  };
  //====================== FIN FUNCIONES =========================

  // Para ejecutar la acción imprimir
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });

  //=========================== USEEFFECT =========================
  // Función que se ejecutará cada vez que nuestro componente se renderice
  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBeforeGetContentResolve.current]);
  //========================== FIN USEEFFECT ======================

  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose();
        }
      }}
    >
      <Grid
        container
        item
        xl={8}
        lg={8}
        md={9}
        sm={10}
        xs={11}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 10,
        }}
      >
        <Card title="Detalle de Pago" justifyContent="center">
          <Grid container style={{ padding: "1rem" }}>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
            >
              <Grid style={{ paddingLeft: "2rem" }}>
                <Grid container direction="row" justifyContent="flex-start">
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginRight: "0.5rem" }}
                  >
                    N° Recibo:
                  </Typography>
                  <Typography variant="body2" style={{ marginRight: "1rem" }}>
                    {recibo?.num_factura}
                  </Typography>
                </Grid>
              </Grid>

              <Grid style={{ paddingLeft: "2rem" }}>
                <Grid container direction="row" justifyContent="flex-start">
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginRight: "0.5rem" }}
                  >
                    Fecha Emisión:
                  </Typography>
                  <Typography variant="body2" style={{ marginRight: "1rem" }}>
                    {moment(recibo?.fecha_emision).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid style={{ paddingLeft: "2rem" }}>
                <Grid container direction="row" justifyContent="flex-start">
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginRight: "0.5rem" }}
                  >
                    Monto:
                  </Typography>
                  <Typography variant="body2" style={{ marginRight: "1rem" }}>
                    {recibo?.total?.toLocaleString("es-AR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "ARS",
                    })}
                  </Typography>
                </Grid>
              </Grid>

              <Grid style={{ paddingLeft: "2rem" }}>
                <Grid container direction="row" justifyContent="flex-start">
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", marginRight: "0.5rem" }}
                  >
                    Saldo:
                  </Typography>
                  <Typography variant="body2" style={{ marginRight: "1rem" }}>
                    {calcularSaldo()?.toLocaleString("es-AR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "ARS",
                    })}
                  </Typography>
                </Grid>
              </Grid>

              <Grid justifyContent="flex-end" style={{ paddingLeft: "2rem" }}>
                <Grid direction="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    children="Imprimir"
                    style={{
                      textTransform: "capitalize",
                    }}
                    startIcon={<PrintIcon />}
                    onClick={handlePrint}
                  />
                </Grid>
              </Grid>

              {recibo?.formas_pago?.length > 0 ? (
                <TableContainer style={{ marginTop: "0.5rem" }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        style={{
                          background: "#E26E6E",
                          height: "2rem",
                        }}
                      >
                        <TableCell
                          align="left"
                          style={{
                            padding: "0 0.5rem 0 1.5rem",
                            borderBottom: 0,
                          }}
                        >
                          Fecha de Pago
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            padding: "0 1.5rem 0 0",
                            borderBottom: 0,
                          }}
                        >
                          Monto
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            padding: "0 0.5rem 0 0",
                            borderBottom: 0,
                          }}
                        >
                          Forma de Pago
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            padding: "0 0.5rem 0 0",
                            borderBottom: 0,
                          }}
                        >
                          N° Cheque/E-Cheq
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            padding: "0 0.5rem 0 0",
                            borderBottom: 0,
                          }}
                        >
                          Fecha Vto.
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            padding: "0 0.5rem 0 0",
                            borderBottom: 0,
                          }}
                        >
                          Observaciones
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recibo?.formas_pago?.map((forma) => (
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0 0.5rem 0 1.5rem",
                              borderBottom: 0,
                            }}
                          >
                            {forma?.fecha_pago
                              ? moment(forma?.fecha_pago).format("DD/MM/YYYY")
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0 0.5rem 0 0",
                              borderBottom: 0,
                            }}
                          >
                            {forma?.monto?.toLocaleString("es-AR", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "ARS",
                            })}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: "0 0.5rem 0 0",
                              borderBottom: 0,
                            }}
                          >
                            {forma?.forma_pago}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              padding: "0 0.5rem 0 0",
                              borderBottom: 0,
                            }}
                          >
                            {forma?.nro_cheque ?? "-"}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              padding: "0 0.5rem 0 0",
                              borderBottom: 0,
                            }}
                          >
                            {forma?.fecha_vencimiento
                              ? moment(forma?.fecha_vencimiento).format(
                                  "DD/MM/YYYY",
                                )
                              : "-"}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              padding: "0 0.5rem 0 0",
                              borderBottom: 0,
                            }}
                          >
                            {forma?.observacion ?? "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "bold", marginRight: "0.5rem" }}
                  >
                    No hay Formas de Pago Cargadas
                  </Typography>
                </Grid>
              )}

              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: "0.5rem" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  children="Volver"
                  style={{ textTransform: "none" }}
                  onClick={() => handleClose()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Grid hidden>
          <ImprimirX
            componentRef={componentRef}
            recibo={recibo}
            saldo={calcularSaldo}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}
