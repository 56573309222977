import axios from "./axios.config";

class AgendaService {

  // endpoint para traer todos los turnos de una fecha
  getTurnos(fecha) {
    return axios.get(`/api/agenda/listar-turno-fecha/${fecha}/`);
  }
  //endpoint para crear turno
  createTurno(payload) {
    return axios.post(`api/agenda/crear-turno-agenda/`, payload)
  }
  /**
   * createTurnoB
   * @param {object} payload
   * @returns
   */
  createTurnoB(payload) {
    return axios.post(`/api/agenda-b/crear-turno-agenda/`, payload)
  }
  //crearTurnoyCliente
  cargarTurnoyCliente(payload){
    return axios.post(`/api/agenda/crear-cliente-turno/`, payload)
  }
  // modificar turno 
  editTurno(id, body) {
    return axios.patch(`/api/agenda/modificar-turno-agenda/${id}/`, body)
  }
  //reasignar turno
  reasignarTurnoAgenda(payload){
    return axios.post(`/api/agenda/reasignar-turno-agenda/`, payload)
  }
  // endpoint para modificar estado 
  putState(id, payload) {
    return axios.patch(`api/agenda/actualizar-estado/${id}/`, payload)
  }
  // eliminar / cancelar turno
  borrarTurno(id) {
    return axios.delete(`api/agenda/eliminar-turno-agenda/${id}/`)
  }
}

export default new AgendaService();