import axios from "./axios.config";

class TokenLessService {
  createUser(data) {
    /**
     * @params {body con los campos de creacion del usuario}
     * @return (void) mensaje de confirmacion de creacion de usuario
     */
    return axios.post("/api/usuarios/registro/", data);
  }

  loginUser(data) {
    return axios.post("/api/login/", data);
  }

  changePasswordRequest(data) {
    /**
     * @params { data = email del solicitante}
     * @return (void) info del usuario editado
     */
    return axios.post("/api/usuarios/recuperar-contraseña/", data);
  }

  changePassword(hash, data) {
    /**
     * @params { data = email del solicitante}
     * @return (void) info del usuario editado
     */
    return axios.post(`/api/usuarios/change-password/${hash}`, data);
  }
}
export default new TokenLessService();
