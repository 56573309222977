// MUI
import { Button, Grid, Link, Typography } from "@material-ui/core";

// ICONS
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// COMPONENTS
import NewSelect from "components/inputs/newSelect";
import InputText from "components/inputs/newInputText";
import InputDate from "components/inputs/newInputDate";

// CONSTANTS
import rules from "constants/rules";

//STYLES
import useStyles from "../../ordenes/styles";
import NewCheckbox from "components/inputs/newCheckbox";
import ButtonLoading from "components/buttonLoading/ButtonLoading";

const HeaderFacturaCompraAnticipada = ({ control, setModalAgregarArchivo, archivoSeleccionado, handleClickAgregarPagos, URLArchivo, clienteSeleccionado, dataSend, importandoDatos, importarDatosPDF }) => {
    const classes = useStyles();
    return (
        <>
            <Grid
                container
                alignItems="center"
            >
                <Grid
                    item
                    sm={6}
                    xs={12}
                    style={{ padding: "0.5rem 0.5rem 0 0" }}
                >
                    <Grid>
                        <Typography className={classes.compraAnticipadaHeaderItemTitle}>
                            Cliente:{" "}
                            <span className={classes.compraAnticipadaHeaderItem}>
                                {clienteSeleccionado?.nombre ?? "-"}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography className={classes.compraAnticipadaHeaderItemTitle}>
                            Condición IVA:{" "}
                            <span className={classes.compraAnticipadaHeaderItem}>
                                {clienteSeleccionado?.condicion_iva ?? "-"}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography className={classes.compraAnticipadaHeaderItemTitle}>
                            Fecha de Emisión:{" "}
                            <span className={classes.compraAnticipadaHeaderItem}>
                                {dataSend?.fecha_carga
                                    ?? "-"}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                    style={{ padding: "0.5rem 0.5rem 0 0" }}
                >
                    <Grid>
                        <Typography className={classes.compraAnticipadaHeaderItemTitle}>
                            CUIL-CUIT :{" "}
                            <span className={classes.compraAnticipadaHeaderItem}>
                                {clienteSeleccionado?.cuil_dni ?? "-"}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography className={classes.compraAnticipadaHeaderItemTitle}>
                            Dirección:{" "}
                            <span className={classes.compraAnticipadaHeaderItem}>
                                {clienteSeleccionado?.direccion
                                    ?? "-"}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography className={classes.compraAnticipadaHeaderItemTitle}>
                            Orden de Compra:{" "}
                            <span className={classes.compraAnticipadaHeaderItem}>
                                {dataSend?.num_orden
                                    ?? "-"}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: "1rem" }}>
                <Grid item xs={3}>
                    <InputText
                        name={"numFact"}
                        label="Número de Factura:"
                        InputLabelProps={{ required: true }}
                        control={control}
                        rules={rules.numberRequered}
                    />
                </Grid>
                <Grid item xs={3}>
                    <NewSelect
                        control={control}
                        rules={rules.generic}
                        name={"tipoFactura"}
                        label="Tipo de Factura:*"
                        options={["A", "B", "C"]}
                    />
                </Grid>
                <Grid item xs={3}>
                    <NewSelect
                        control={control}
                        rules={rules.generic}
                        name={"puntoVenta"}
                        label="Punto de Venta:*"
                        options={["00001", "00002"]}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputDate
                        name={"fechaVencPago"}
                        label="Fecha Vencimiento Pago:"
                        // inputProps={{ min: fecha_emision }}
                        control={control}
                        rules={rules.generic}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="flex-start"
                style={{ paddingTop: "1rem" }}
            >
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        //   disabled={!pagado && !compraAnticipada}
                        children="Agregar Formas de Pago"
                        // startIcon={<AddIcon />}
                        onClick={handleClickAgregarPagos}
                        style={{ textTransform: "none" }}
                    />
                </Grid>
                <Grid item>
                    <NewCheckbox
                        name="condicion"
                        control={control}
                        label="Pagado"
                        color="primary"
                    //   onChange={() => { compraAnticipada && setValue("compra_anticipada", false) }}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                style={{ paddingTop: "1rem" }}
            >
                <Grid container item xs={12} md={4}>
                    <Typography
                        variant="body1"
                        style={{ fontWeight: "bold", paddingRight: "1rem" }}
                    >
                        Adjuntar Factura Electrónica AFIP:
                    </Typography>
                </Grid>

            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                style={{ paddingTop: "1rem" }}
            >
                <Grid item>
                    {archivoSeleccionado ?
                        <CheckIcon fontSize="small" color="success" />
                        :
                        <CloseIcon fontSize="small" color="error" />
                    }
                </Grid>
                <Grid item >
                    {archivoSeleccionado ? (
                        <>
                            <Link
                                href={URLArchivo}
                                variant="body2"
                                underline="hover"
                                target="_blank"
                                rel="noopener"
                                children={archivoSeleccionado?.name}
                            />
                        </>
                    ) : (
                        <>
                            <Typography
                                variant="body1"
                            //style={{ paddingLeft: "0.5rem" }}
                            >
                                No Hay Archivo Seleccionado
                            </Typography>
                        </>
                    )}
                </Grid>

                <Grid item xs={12} md={3} >
                    <Button
                        // fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        children="Cargar PDF"
                        style={{ textTransform: "none" }}
                        startIcon={<CloudUploadIcon />}
                        onClick={() => setModalAgregarArchivo(true)}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                style={{ paddingTop: "1rem" }}
            >


                <Grid item xs={12} md={5}>
                    <ButtonLoading
                        isLoading={importandoDatos}
                        disabled={!archivoSeleccionado || importandoDatos}
                        variant="contained"
                        color="primary"
                        size="small"
                        children="Importar Encabezado"
                        style={{ textTransform: "none" }}
                        startIcon={<CloudUploadIcon />}
                        onClick={() => importarDatosPDF()}
                    />
                </Grid>
            </Grid>

        </>
    )
}


export default HeaderFacturaCompraAnticipada

