
import { useQuery } from "react-query";
import cuentaCorrienteService from "services/cuentaCorriente.service";

 //--- FUNCION PARA TRAER TOODOS LAS ORDENES FACTURADAS PENDIENTES ENTREGA ---//
 const obtenerEstadoCuentaDeCliente= async ({idCliente}) => {
    const { data } = await cuentaCorrienteService.getEstadoCuentaClienteById(idCliente);
    return data;
  };


export function useEstadoCuenta({idCliente}) {
    return useQuery(["obtenerEstadoCuenta", idCliente], () => obtenerEstadoCuentaDeCliente({idCliente}), {
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    });
  }
