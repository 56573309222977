import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

function Alert(props) {
  return <MuiAlert elevation={4} variant="filled" {...props} />;
}

const vertical = `bottom`;
const horizontal = `right`;

export default function CustomSnackbar({
  open,
  onClose,
  severity,
  autoHideDuration,
  message,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
// Especifica los valores por defecto de props:
CustomSnackbar.defaultProps = {
  autoHideDuration: 1500,
};

CustomSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
};
