// REACT
import { useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";


// MATERIAL UI
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";


// ICONS
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@material-ui/icons";


// COMPONENTS
import VerificarAcceso from "components/verificarAcceso";
import Card from "components/card";
import ConfirmDialog from "components/ConfirmDialog";


// SERVICES
import clientesService from "services/clientes.service";
import ordenesService from "services/ordenes.service";


// VARIOS
import RootContainer from "layout/rootContainer";
import Protected from "protected";
import { LogIn } from "context/LogInContext";
import rules from "constants/rules";
import Theme from "theme";
import formulaService from "services/formula.service";
import CustomSnackbar from "components/Layout/CustomSnackbars";
import ButtonLoading from "components/buttonLoading/ButtonLoading";
import mostrarErrorEP from "constants/funciones/mostrarError";
import NewAutocomplete from "components/inputs/newAutocomplete";
import { useEffect } from "react";
import InputDate from "components/inputs/newInputDate";
import InputText from "components/inputs/newInputText";
import NewSelect from "components/inputs/newSelect";
import NewCheckbox from "components/inputs/newCheckbox";
import InputNumber from "components/inputs/newInputNumber";
import MediosPago from "./components/mediosPago";
import { useToggle } from "hook/useToogle";
import dayjs from "dayjs";


const CrearOrden = () => {
  // HOOK HISTORY
  const history = useHistory();
  // Tomo datos State, cuando crea remito anticipado con mts3 mayor al disponible en OCA
  const cliente = history.location?.state && history.location?.state?.cliente;
  const linea_orden = history.location?.state && history.location?.state?.linea_orden;

  // HOOK FORM
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      // Si esta creando Orden a partir de remito anticipado Excedente, precargo el Cliente y el número de Orden
      clienteOrden: cliente?.id ? { id: cliente?.id, cuil_dni: cliente?.cuil_dni, direccion: cliente?.direccion } : null,
      num_orden: linea_orden && linea_orden?.num_orden,
      condicion: false,
      compra_anticipada: false,
    }
  });


  // HOOK CONTEXT
  const { logged } = useContext(LogIn);


  // CONSTANTES
  // ==========================================================================
  const clienteSeleccionado = watch("clienteOrden");
  const presupuesto = watch("presupuesto");
  const pagado = watch("condicion");
  const compraAnticipada = watch("compra_anticipada");
  // ==========================================================================


  // ESTADOS
  // ==========================================================================
  // Para verificar acceso de un usuario al modulo
  const [verificando, setVerificando] = useState(true);
  const [acceso, setAcceso] = useState(false);


  // Para CustomSnackbar
  const [snackState, setSnackState] = useState({
    message: "no message",
    open: false,
    autoHideDuration: 2000,
  });


  // Para ConfirmDialog
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: null,
  });


  // Para capturar datos de endpoints
  const [clientes, setClientes] = useState([]);
  const [formulas, setFormulas] = useState([]);


  const [openMediosPago, setOpenMediosPago] = useToggle();
  const [mediosPago, setMediosPago] = useState([]);


  const handleClickAgregarPagos = () => {
    setOpenMediosPago();
  };

  // Para spinners
  const [cargandoClientes, setCargandoClientes] = useState(false);
  const [creandoOrden, setCreandoOrden] = useState(false);


  // Para el manejo de las lineas de inputs
  const [idLinea, setIdLinea] = useState(2);

  // Prefija primer linea, carga valores por default si es OC a partir de Remito Ant. Exc.
  const [lineas, setLineas] = useState([
    {
      id: 1,
      formula_nombre: linea_orden ? linea_orden?.formula_nombre : null,
      formula: linea_orden ? linea_orden?.formula : null,
      cantidad_m3: linea_orden ? linea_orden?.cantidad_m3 : 0,
      precio_original: linea_orden ? linea_orden?.precio_original : 0,
      fecha_vigencia_precio: dayjs().format("YYYY-MM-DD"),
    },
  ]);
  // ==========================================================================


  // FUNCIONES FLECHA
  // ==========================================================================
  // Para cerrar CustomSnackbar
  const onClose = () => {
    setSnackState({ ...snackState, open: false });
  };


  // Para filtrar clientes por dni o nombre en autocomplete
  const filtrarClientes = (options, option) => {
    let resultados = options.filter(
      (cliente) =>
        cliente?.nombre.includes(option?.inputValue?.toUpperCase()) ||
        cliente?.cuil_dni.includes(option?.inputValue?.toUpperCase()),
    );


    return resultados;
  };


  // Para agregar una linea de inputs con select de formulas
  const agregarLinea = () => {
    let lineaExtra = {
      id: idLinea,
      formula_nombre: null,
      cantidad_m3: 0,
      precio_original: 0,
      fecha_vigencia_precio: dayjs().format("YYYY-MM-DD"),
    };


    setIdLinea(idLinea + 1);
    setLineas([...lineas, lineaExtra]);
  };


  // Para agregar una linea de inputs con input de concepto
  const agregarConcepto = () => {
    let lineaExtra = {
      id: idLinea,
      formula: null,
      cantidad_m3: 0,
      precio_original: 0,
      fecha_vigencia_precio: dayjs().format("YYYY-MM-DD"),
      isConcepto: true,
    };


    setIdLinea(idLinea + 1);
    setLineas([...lineas, lineaExtra]);
  };


  // Para eliminar una linea
  const eliminarLinea = (index) => {
    lineas.splice(index, 1);
  };


  // Para modificar una linea
  const modificarLinea = (index) => {
    const linea = lineas[index];


    lineas.splice(index, 1, linea);
  };

  console.log("*** las lineas", lineas, lineas.lastIndexOf().id, lineas[lineas.length - 1].id);

  // ENDPOINTS
  // ==========================================================================
  // Para obtener clientes
  const obtenerClientes = async () => {
    setCargandoClientes(true);
    try {
      const { data, status } = await clientesService.getAllClientes();
      if (status > 199 && status < 300) {
        data?.forEach((cliente) => {
          cliente.nombre = cliente?.nombre?.toUpperCase();
        });
        const filtrado = data?.filter(
          (cliente) => cliente?.notificacion === false,
        );
        setClientes(filtrado);
        setCargandoClientes(false);
      }
    } catch (error) {
      setCargandoClientes(false);
      return error;
    }
  };


  // Para obtener formulas
  const obtenerFormulas = async () => {
    try {
      const { data, status } = await formulaService.getAllFormulas();
      if (status > 199 && status < 300) {
        setFormulas(data);
      }
    } catch (error) {
      mostrarErrorEP(error, setSnackState);
      return error;
    }
  };


  const calcularTotalOrden = () => {
    const sumatoria = lineas?.reduce(
      (acumulador, item) =>
        acumulador + item?.precio_original * item?.cantidad_m3,
      0,
    );


    return Number(sumatoria.toFixed(2));
  };


  const validarMediosPago = (data) => {
    //if (mediosPago?.length === 0) return onSubmit(data);

    if (pagado) {
      const totalMonto = mediosPago?.reduce(
        (acumulador, item) => acumulador + item?.monto,
        0,
      );


      if (calcularTotalOrden() - totalMonto !== 0) {
        return setConfirmDialog({
          isOpen: true,
          title: `Revise las formas de pago, el "Saldo" no es igual a $0,00`,
          severity: "error",
        });
      }


      return onSubmit(data);
    } else {
      return onSubmit(data)
    }
  };


  // Para crear la orden
  const onSubmit = async (data) => {
    setCreandoOrden(true);


    const dataSend = {
      fecha_carga: data?.fecha_carga,
      cliente: data?.clienteOrden?.id,
      num_orden: data?.num_orden,
      presupuesto: data?.presupuesto,
      compra_anticipada: data?.compra_anticipada ? 1 : 0,
      condicion: data?.condicion ? "Pagado" : "A Cobrar",
      datosFormula: lineas,
      lineas_pago: pagado ? mediosPago : [],
    };


    if (compraAnticipada) {
      history.push(`/ordenes/cargar-orden-compra-anticipada/`, { dataSend, clienteSeleccionado });
    } else {
      try {
        const res = await ordenesService.crearOrden(dataSend);
        if (res.status > 199 && res.status < 300) {
          setSnackState({
            ...snackState,
            open: true,
            severity: "success",
            message: `Orden Creada Exitosamente`,
          });
          setTimeout(() => {
            history.push(`/ordenes/`);
            setCreandoOrden(false);
          }, [2000]);
        }
      } catch (error) {
        mostrarErrorEP(error, setSnackState);
        setCreandoOrden(false);
        return error;
      }
    }


  };


  // FUNCIONES EFFECT
  // ==========================================================================
  useEffect(() => {
    obtenerClientes();
    obtenerFormulas();
  }, []);
  // ==========================================================================
  return (
    <Protected>
      <form onSubmit={handleSubmit(validarMediosPago)}>
        <RootContainer>
          {verificando ? (
            <VerificarAcceso
              setVerificando={setVerificando}
              setAcceso={setAcceso}
              modulo="orden-compra"
              history={history}
              logged={logged}
            />
          ) : (
            acceso && (
              <Grid
                container
                style={{
                  padding: "1rem 0 5rem 2rem",
                  width: "95%",
                }}
              >
                <Card title="Crear Orden de Compra" justifyContent="center">
                  <Grid
                    container
                    style={{
                      padding: "1rem 4rem",
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      style={{ paddingTop: "1rem" }}
                    >
                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0 0" }}
                      >
                        {/* Si es OC a partir de Remito Ant. Exc. prefija Cliente y no es posible cambiarlo */}
                        {linea_orden ?
                          <TextField
                            size="small"
                            fullWidth
                            type="text"
                            label="Cliente:"
                            variant="outlined"
                            disabled
                            value={cliente?.nombre}
                          />
                          :
                          <NewAutocomplete
                            control={control}
                            rules={rules.generic}
                            name="clienteOrden"
                            label="Cliente:"
                            placeholder="Nombre o CUIT"
                            size="small"
                            freeSolo
                            clearOnBlur
                            options={clientes}
                            loadingText="Cargando..."
                            loading={cargandoClientes}
                            noOptionsText="No Hay Clientes"
                            getOptionLabel={(option) => option.nombre}
                            filterOptions={(options, option) =>
                              filtrarClientes(options, option)
                            }
                            InputLabelProps={{ required: true }}
                          />
                        }
                      </Grid>


                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0 0" }}
                      >
                        <InputDate
                          name="fecha_carga"
                          label="Fecha Carga:"
                          InputLabelProps={{ required: true, shrink: true }}
                          control={control}
                          rules={rules.generic}
                          defaultValue={dayjs().format("YYYY-MM-DD")}
                          inputProps={{
                            max: dayjs().format("YYYY-MM-DD"),
                          }}
                        />
                      </Grid>


                      <Grid
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{ padding: "0.5rem 0.5rem 0 0" }}
                      >
                        <InputText
                          name="num_orden"
                          label="Nº Orden de Compra:"
                          InputLabelProps={{ required: true }}
                          control={control}
                          rules={
                            presupuesto
                              ? rules.genericNoRequered
                              : rules.numberRequered
                          }
                        />
                      </Grid>




                    </Grid>


                    {clienteSeleccionado && (
                      <Grid container style={{ paddingTop: "0.5rem" }}>
                        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "bold",
                              paddingRight: "0.5rem",
                            }}
                          >
                            CUIT/CUIL:
                          </Typography>
                          <Typography variant="body2">
                            {clienteSeleccionado?.cuil_dni}
                          </Typography>
                        </Grid>


                        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "bold",
                              paddingRight: "0.5rem",
                            }}
                          >
                            Domicilio:
                          </Typography>
                          <Typography variant="body2">
                            {clienteSeleccionado?.direccion}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}


                    <Grid container>
                      <Grid item style={{ margin: "0.5rem 0.5rem 0.5rem 0" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          children="Agregar Línea"
                          startIcon={<AddIcon />}
                          style={{ textTransform: "none" }}
                          onClick={() => agregarLinea()}
                        />
                      </Grid>


                      <Grid item style={{ margin: "0.5rem 0.5rem 0.5rem 0" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          children="Agregar Concepto"
                          startIcon={<AddIcon />}
                          style={{ textTransform: "none" }}
                          onClick={() => agregarConcepto()}
                        />
                      </Grid>


                      <Grid item style={{ margin: "0.5rem 0.5rem 0.5rem 0" }}>
                        <Tooltip
                          title={
                            <Typography variant="body2">
                              No se pueden agregar formas de pago porque
                              "Pagado" no está seleccionado
                            </Typography>
                          }
                          placement="top"
                          arrow
                          disableFocusListener={pagado}
                          disableHoverListener={pagado}
                          disableTouchListener={pagado}
                        >
                          <span>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              disabled={!pagado || compraAnticipada}
                              children="Agregar Formas de Pago"
                              startIcon={<AddIcon />}
                              onClick={handleClickAgregarPagos}
                              style={{ textTransform: "none" }}
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <NewCheckbox
                          name="presupuesto"
                          control={control}
                          label="Presupuesto"
                          color="primary"
                          defaultValue={false}
                          disable={compraAnticipada}
                          onChange={() => { compraAnticipada && setValue("compra_anticipada", false) }}
                        />
                      </Grid>


                      <Grid item>
                        <NewCheckbox
                          name="condicion"
                          control={control}
                          label="Pagado"
                          color="primary"
                          onChange={() => { compraAnticipada && setValue("compra_anticipada", false) }}
                        />
                      </Grid>


                      <Grid item>
                        <NewCheckbox
                          name="compra_anticipada"
                          control={control}
                          label="Compra Anticipada"
                          color="primary"
                          onChange={() => {
                            presupuesto && setValue("presupuesto", false)
                            setValue("condicion", false)
                          }}


                        />
                      </Grid>


                    </Grid>


                    <TableContainer style={{ paddingTop: "1rem" }}>
                      <Table size="small">
                        <TableBody>
                          {lineas?.length > 0 &&
                            lineas
                              .map((linea, index) => (
                                <TableRow key={linea.id}>
                                  {lineas?.length > 1 && (
                                    <TableCell
                                      align="center"
                                      style={{
                                        padding: "0.5rem 0.5rem 0.7rem 0",
                                        borderBottom: 0,
                                        width: "1rem",
                                      }}
                                    >
                                      {/*  */}
                                      <IconButton
                                        size="small"
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          borderRadius: 5,
                                          backgroundColor:
                                            Theme.palette.primary.main,
                                          color: "#ffffff",
                                        }}
                                        onClick={() =>
                                          setConfirmDialog({
                                            title: `ELIMINAR ${linea?.isConcepto
                                              ? "CONCEPTO"
                                              : linea?.formula_nombre ?
                                                "HORMIGÓN" : "LINEA"
                                              } ${linea?.formula_nombre ??
                                              linea?.concepto ??
                                              ""
                                              }`,
                                            isOpen: true,
                                            onConfirm: () => {
                                              eliminarLinea(index);
                                            },
                                          })
                                        }
                                      >
                                        <Delete
                                          style={{
                                            height: "1.2rem",
                                            width: "1.2rem",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  )}
                                  <TableCell
                                    align="left"
                                    style={{
                                      padding: linea?.isConcepto
                                        ? "0.5rem 0.5rem 0.7rem 0"
                                        : "0.5rem 0.5rem 0.5rem 0",
                                      borderBottom: 0,
                                      width: "24.5%",
                                    }}
                                  >
                                    {linea?.isConcepto ? (
                                      <InputText
                                        name={"concepto" + linea.id}
                                        label="Concepto:"
                                        control={control}
                                        rules={rules.generic}
                                        onChange={(e, value) => {
                                          linea.concepto = value;
                                          modificarLinea(index);
                                        }}
                                      />
                                    ) : 
                                    /* Prefija primer Linea si es OC a partir de Remito Exc. Ant. */                                    
                                      (linea?.id === 1 && linea_orden?.formula) ? (
                                        <TextField
                                          fullWidth
                                          disabled
                                          name={"formulaDisp_nombre"}
                                          size="small"
                                          variant="outlined"
                                          type="text"
                                          defaultValue={linea?.formula_nombre ? linea?.formula_nombre : linea_orden?.formula_nombre}
                                          label="Tipo Hormigón:"
                                        />
                                      ) :
                                        (
                                          <NewSelect
                                            name={"formula_nombre" + linea.id}
                                            label="Tipo Hormigón:"
                                            control={control}
                                            rules={rules.generic}
                                            options={formulas}
                                            option="nombre"
                                            optionValue="nombre"
                                            onChange={(e, value) => {
                                              const formula = formulas?.find(
                                                (formula) =>
                                                  formula?.nombre === value,
                                              );


                                              linea.formula_nombre = value;
                                              linea.formula = formula?.id;


                                              modificarLinea(index);
                                            }}
                                          />
                                        )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      padding: "0.5rem 0.5rem 0.7rem 0",
                                      borderBottom: 0,
                                      width: "24.5%",
                                    }}
                                  >
                                    <InputNumber
                                      name={"cantidad_m3" + linea.id}
                                      label={
                                        linea?.isConcepto
                                          ? "Cantidad"
                                          : "Cantidad M³:"
                                      }
                                      control={control}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Este campo es requerido",
                                        },
                                        pattern: {
                                          value:
                                            /^[0-9]+([.|,][0-9]|[.|,][0-9][0-9])?$/g,
                                          message:
                                            "Este campo acepta dos decimales",
                                        },
                                        min: {
                                          value: 1,
                                          message:
                                            "Ingrese un numero mayor o igual a 1",
                                        },
                                      }}
                                      defaultValue={linea?.cantidad_m3 ?? 0}
                                      onChange={(e, value) => {
                                        linea.cantidad_m3 = Number(
                                          value?.toFixed(2),
                                        );


                                        modificarLinea(index);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      padding: "0.5rem 0.5rem 0.7rem 0",
                                      borderBottom: 0,
                                      width: "24.5%",
                                    }}
                                  >
                                    <InputNumber
                                      name={"precio_original" + linea.id}
                                      label="Precio:"
                                      control={control}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Este campo es requerido",
                                        },
                                        pattern: {
                                          value:
                                            /^[0-9]+([.|,][0-9]|[.|,][0-9][0-9])?$/g,
                                          message:
                                            "Este campo acepta dos decimales",
                                        },
                                        min: {
                                          value: 0,
                                          message:
                                            "Ingrese un numero mayor o igual a 0",
                                        },
                                      }}
                                      defaultValue={linea?.precio_original ?? 0}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        ),
                                      }}
                                      onChange={(e, value) => {
                                        linea.precio_original = Number(
                                          value?.toFixed(2),
                                        );


                                        modificarLinea(index);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{
                                      padding: "0.5rem 0.5rem 0.7rem 0",
                                      borderBottom: 0,
                                      width: "24.5%",
                                    }}
                                  >
                                    <InputDate
                                      name={"fecha_vigencia_precio" + linea.id}
                                      label="Fecha Vigente:"
                                      control={control}
                                      rules={rules.observacion}
                                      defaultValue={linea.fecha_vigencia_precio}
                                      onChange={(e, value) => {
                                        linea.fecha_vigencia_precio = value;


                                        modificarLinea(index);
                                      }}
                                    />
                                  </TableCell>
                                  {/* } */}
                                </TableRow>
                              ))
                              .reverse()}
                        </TableBody>
                      </Table>
                    </TableContainer>


                    <Grid
                      container
                      justifyContent="flex-end"
                      style={{ marginTop: "1rem" }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        children="Volver"
                        style={{ marginRight: "1rem", textTransform: "none" }}
                        onClick={() => history.goBack()}
                      />
                      <ButtonLoading
                        isLoading={creandoOrden}
                        disabled={creandoOrden}
                        type="submit"
                        children="Confirmar"
                        style={{ textTransform: "none" }}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                />
                <CustomSnackbar
                  message={snackState.message}
                  open={snackState.open}
                  severity={snackState.severity}
                  autoHideDuration={snackState.autoHideDuration}
                  onClose={onClose}
                />
              </Grid>
            )
          )}
        </RootContainer>
      </form>


      <MediosPago
        open={openMediosPago}
        handleClose={setOpenMediosPago}
        totalOrden={calcularTotalOrden}
        setConfirmDialog={setConfirmDialog}
        setMediosPago={setMediosPago}
      />
    </Protected>
  );
};


export default CrearOrden;