import {SvgIcon} from "@material-ui/core";

const ExcelIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path
        d="M0.243774 1.44302V10.368L6.8093 11.5344V0.276611L0.243774 1.44302ZM4.01681 7.7846L3.46559 6.33844L2.925 7.70873H2.17238L3.0522 5.7968L2.23599 3.92283H3.00982L3.50803 5.2173L4.10163 3.8145L4.84366 3.77658L3.91615 5.7806L4.88079 7.84952L4.01681 7.7846Z"
        fill="white"
      />
      <path
        d="M11.4675 1.43286H7.0316V2.08468H8.7015V3.26007H7.0316V3.61353H8.7015V4.78892H7.0316V5.14241H8.7015V6.3178H7.0316V6.67124H8.7015V7.84665H7.0316V8.20747H8.7015V9.38284H7.0316V10.1371H11.4675C11.5931 10.1371 11.6958 10.0313 11.6958 9.90193V1.66806C11.6958 1.53868 11.5931 1.43286 11.4675 1.43286ZM10.9269 9.38284H9.04556V8.20747H10.9269V9.38284ZM10.9269 7.84665H9.04556V6.67124H10.9269V7.84665ZM10.9269 6.3178H9.04556V5.14241H10.9269V6.3178ZM10.9269 4.78892H9.04556V3.61353H10.9269V4.78892ZM10.9269 3.26007H9.04556V2.08468H10.9269V3.26007Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default ExcelIcon;
